import React, { useState } from 'react'
import {
  SignalModalContainer,
  InputContainer,
  Title,
  ButtonContainer,
  MinimunRangeLabel,
  Subtitle,
  OptionLabels,
  OptionsContainer,
  Options
} from './SignalStrengthModal.styles'
import { Badge } from 'Containers/AccessPoints/AcessPoints.styles'
import { Button, Slider } from '@scuf/common';

const SignalStrengthModal = ({
  setSignalRanges,
  t,
  closeModal,
  goodRanges,
}) => {

  const [goodRange, setGoodRange] = useState(goodRanges)

  const changeRanges = () => {
    const params = {
      excellenteRange: [0, goodRange[1] + 1],
      goodRange: goodRange,
      poorRange: [goodRange[0] - 1, -120],
    }
    setSignalRanges(params)
    closeModal()
  }

  return (
    <SignalModalContainer>
      <Title>{t('AccessPoints_SignalRangeTitle')}</Title>
      <Subtitle>{t('AccessPoints_SignalRangeSubTitle')}</Subtitle>
      <InputContainer>
        <MinimunRangeLabel>-120</MinimunRangeLabel>
        <Slider useRange={true} fluid={true} onChange={setGoodRange} min={-120} max={1} from={goodRange[0]} to={goodRange[1]} />
      </InputContainer>
      <OptionsContainer>
        <Options>
          <OptionLabels><Badge color={'#ff6000'} /> <label>{t('AccessPoints_SignalRangePoor')} (-120 to {`${goodRange[0] - 1}`} dB)</label></OptionLabels>
        </Options>
        <Options>
          <OptionLabels><Badge color={'#6c6967'} /> <label>{t('AccessPoints_SignalRangeGood')} ({goodRange[0]} to {goodRange[1]} dB)</label></OptionLabels>
        </Options>
        <Options>
          <OptionLabels><Badge color={'#000000'} /> <label>{t('AccessPoints_SignalRangeExcellent')} ({goodRange[1] + 1} to 1 dB)</label></OptionLabels>
        </Options>
      </OptionsContainer>
      <ButtonContainer>
        <Button type="secondary" content={t('AccessPoints_SignalRangeCancel')} onClick={closeModal} />
        <Button type="primary" content={t('AccessPoints_SignalRangeApply')} onClick={changeRanges} />
      </ButtonContainer>
    </SignalModalContainer>
  )
}

export default SignalStrengthModal