import { all, fork } from 'redux-saga/effects'

/* ------------- API Services ------------- */
import {
  OrganizationsApi,
  SitesApi,
  TagsApi,
  RolesApi,
  PermissionsApi
} from '../Services'

/* ------------- Sagas ------------- */
import { organizationsSaga } from '../features/organization-management/store'
import { sitesSaga } from '../features/site-management/store'
import { checkInOutSaga } from './check-in-out'
import { taggingSaga } from './tagging'
import { accessPointsSagas } from '../features/access-points'
import { rolesSaga } from './roles/saga'
import { networkRangesSagas } from '../features/network-ranges'
import { permissionsSaga } from './permissions'
import { ltlSaga } from '../features/ltl-trip-management/store'

export default function * () {
  yield all([
    fork(organizationsSaga, OrganizationsApi),
    fork(sitesSaga, SitesApi),
    fork(checkInOutSaga),
    fork(accessPointsSagas),
    fork(networkRangesSagas),
    fork(taggingSaga, TagsApi),
    fork(rolesSaga, RolesApi),
    fork(permissionsSaga, PermissionsApi),
    fork(ltlSaga)
  ])
}
