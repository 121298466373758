import React, { Fragment, useEffect } from 'react'
import { Field } from 'redux-form'
import { get } from 'lodash'
import styled from 'styled-components/macro'
import { useTranslate } from 'react-translate'
import { InputLabel } from '@scuf/common'
import { FormInput } from 'Components/FormComponents/FormInput'
import  UpdateExecution from './update-execution-method'
import FormDatePicker from 'Components/FormComponents/FormDatePicker'
import { maxLength, noSpecialCharacters } from '../../../../Forms/Validators'
import DateLocationFormat from '../../../DateLocationFormat'
import { DRIVER_TYPES, LABEL_TYPES } from '../../../ScheduleUpdateModal/schedule-updates.constants'
import { DescriptionContainer, InfoContainer, InfoTitle, InfoContainerModal} from '../../schedule-modal.styles'
import SelectWrapper from '../SelectWrapper'
import { defaultFlags } from 'Utils/launch-darkly-flags'

const InfoText = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
`

const maxLength5 = maxLength(5, 'Label5')
const maxValue16 = maxLength(16, 'Label')
const maxValue1 = maxLength(1, 'Short')
const maxValue19 = maxLength(19, 'Description')

const required = value => value ? undefined : 'Required'

export function UpdateInfo(a) {
  const t = useTranslate('AssetManagement')
  return (
    <Fragment>
      <InfoContainer>
        <InfoTitle>{t('Version')}</InfoTitle>
        <InfoText>{a.version}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('File size')}</InfoTitle>
        <InfoText>{a.fileSize || t('NotAvailable')}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('Release Date')}</InfoTitle>
        <InfoText>
          <DateLocationFormat
            field='releaseDate'
            rowData={a}
          />
        </InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('Type')}</InfoTitle>
        <InfoText>{a.typeFormatted || t('NotAvailable')}</InfoText>
      </InfoContainer>
    </Fragment>
  )
}
const AssetInfo = ({
  assetsList,
  formValues,
  style,
  t,
  isValid,
  flags,
  editionDisabled = false,
  ...rest }) => {

    useEffect(() => {
      isValid(rest.valid)
    }, [isValid, rest.valid])

    return (
      assetsList.map(a => {
        return (
          <div key={a.id}>
            <DescriptionContainer style={{ ...style }}>
              <UpdateInfo {...a} />
              {a.fileType === 'font' &&
                <Fragment>
                  <Field
                    name={`${a.id}.drive`}
                    placeholder={t('Select drive')}
                    options={DRIVER_TYPES}
                    label={t('Drive')}
                    component={SelectWrapper}
                    validate={[required]}
                    disabled={editionDisabled}
                  />
                  <Field
                    name={`${a.id}.fontId`}
                    placeholder={t('Select font id')}
                    options={a.fontsDrive}
                    label={t('Font id')}
                    component={SelectWrapper}
                    validate={[required]}
                    disabled={editionDisabled}
                  />
                </Fragment>}
              {a.fileType === 'image' &&
                <Fragment>
                  <InfoContainerModal>
                    <Field
                      name={`${a.id}.drive`}
                      placeholder={t('Select drive')}
                      options={DRIVER_TYPES}
                      label={t('Drive')}
                      component={SelectWrapper}
                      validate={[required]}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                  <InfoContainerModal>
                    <Field
                      component={FormInput}
                      fluid
                      label={t('Label')}
                      name={`${a.id}.label`}
                      placeholder={t('Select label')}
                      validate={[
                        required,
                        noSpecialCharacters,
                        maxValue16
                      ]}
                      t={t}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                </Fragment>}
              {a.type === 'DEVCONFIG-font/ttf' &&
                <InfoContainerModal>
                  <Field
                    component={FormInput}
                    fluid
                    label={t('Label')}
                    name={`${a.id}.label`}
                    placeholder={t('Select label')}
                    validate={[
                      required,
                      noSpecialCharacters,
                      maxValue16
                    ]}
                    t={t}
                    disabled={editionDisabled}
                  />
                </InfoContainerModal>}
            </DescriptionContainer>
            {a.type === 'DEVCONFIG-application/x-hon-storedlbl' &&
              <DescriptionContainer style={{ display: "flex", flexWrap: "wrap" }}>
                <Fragment>
                  <InfoContainerModal style={{ width : "12rem" }}>
                    <Field
                      component={FormInput}
                      fluid
                      label={t('Update Title')}
                      name={`${a.id}.title`}
                      placeholder={t('Update title')}
                      validate={[
                        noSpecialCharacters,
                        required
                      ]}
                      t={t}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                  <InfoContainerModal>
                    <Field
                      name={`${a.id}.type`}
                      placeholder={t('Select a label type')}
                      options={LABEL_TYPES}
                      label={t('Stored Label Type')}
                      component={SelectWrapper}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                  <InfoContainerModal>
                    <Field
                      name={`${a.id}.drive`}
                      placeholder={t('Select drive')}
                      options={DRIVER_TYPES}
                      label={t('Drive')}
                      component={SelectWrapper}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>

                </Fragment>
              </DescriptionContainer>
            }
            {get(formValues, [a.id, 'type'], null) === 'ez-print' ?
              <div>
                <DescriptionContainer style={{ display: "flex", flexWrap: "wrap" }}>
                  <Fragment>
                    <InfoContainerModal style={{ width : "12rem" }}>
                      <Field
                        component={FormInput}
                        fluid
                        label={t('Label')}
                        name={`${a.id}.name`}
                        placeholder={t('Update Label title')}
                        validate={[
                          noSpecialCharacters,
                          required,
                          maxLength5
                        ]}
                        t={t}
                        disabled={editionDisabled}
                      />
                    </InfoContainerModal>
                    <InfoContainerModal style={{ width : "12rem" }}>
                      <Field
                        component={FormInput}
                        fluid
                        label={t('Shortname')}
                        name={`${a.id}.shortname`}
                        placeholder={t('Shortname')}
                        validate={[
                          noSpecialCharacters,
                          required,
                          maxValue1
                        ]}
                        t={t}
                        disabled={editionDisabled}
                      />
                    </InfoContainerModal>
                    <InfoContainerModal style={{ width : "12rem" }}>
                      <Field
                        component={FormInput}
                        fluid
                        label={t('Version')}
                        name={`${a.id}.version`}
                        placeholder={t('Version')}
                        validate={[
                          noSpecialCharacters,
                          required
                        ]}
                        t={t}
                        disabled={editionDisabled}
                      />
                    </InfoContainerModal>
                  </Fragment>
                </DescriptionContainer>
                <DescriptionContainer style={{ display: "flex", flexWrap: "wrap" }} >
                  <InfoContainerModal>
                    <Field
                      component={FormDatePicker}
                      fluid
                      label={t('Date')}
                      name={`${a.id}.date`}
                      validate={[required]}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                  <InfoContainerModal style={{ width : "12rem" }}>
                    <Field
                      component={FormInput}
                      fluid
                      label={t('Description')}
                      name={`${a.id}.description`}
                      placeholder={t('Description')}
                      validate={[
                        noSpecialCharacters,
                        required,
                        maxValue19
                      ]}
                      t={t}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                </DescriptionContainer>
              </div>
              : null
            }
            {
              get(formValues, [a.id, 'type'], null) === 'dpl'
                ?
                <DescriptionContainer style={{ display: "flex", flexWrap: "wrap" }}>
                  <InfoContainerModal style={{ width : "12rem" }}>
                    <Field
                      component={FormInput}
                      fluid
                      label={t('Label')}
                      name={`${a.id}.name`}
                      placeholder={t('Update Label title')}
                      validate={[
                        noSpecialCharacters,
                        required,
                        maxValue16
                      ]}
                      t={t}
                      disabled={editionDisabled}
                    />
                  </InfoContainerModal>
                </DescriptionContainer>

                : null
            }
            {flags.overrideSwUpdateNetwokPrefrerences && <UpdateExecution editionDisabled={editionDisabled} />}
          </div>
      )
    })
  )
}

AssetInfo.defaultProps = {
  flags: defaultFlags,
  t: l => l
}

export default AssetInfo
