import { connect } from 'react-redux'

import Modal from './modal'
import ModalActions from '../../Redux/ModalRedux'
import FilterActions from '../../Redux/FilterRedux'

export const mapStateToProps = state => ({
  isOpen: state.modal.isOpen,
  type: state.modal.type,
  modalProps: state.modal.modalProps,
  theme: state.user.theme
})

export const mapDispatchToProps = dispatch => ({
  modalClose: () => dispatch(ModalActions.modalClose()),
  clearFilter: () => dispatch(FilterActions.filterSetGlobalFilter(''))
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
