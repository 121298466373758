/*
 * Patch SCUF Icon to use styled-components v5
 */

import * as React from 'react'
import styled from 'styled-components'

import * as StyledIcon from '@scuf/common/dist/components/StyledIcon/StyledIcon'
import { IIconProps, IStyledIconProps } from '@scuf/common/dist/components/StyledIcon/IStyledIconProps'

import colorLookup from '@scuf/common/dist/utils/ColorLookup'
import icons from '@scuf/common/dist/components/StyledIcon/icon-names'

const StIcon = styled.i<IStyledIconProps>`
  font-size: ${({ size }) => size};
  ${({ color }) => color != null ? `color: ${color}` : ''};
  &:before {
      content: '${({ iconContent }) => iconContent}';
  }
`

const PatchedStyledIcon: React.FC<IIconProps> = ({
  rotated,
  loading,
  padded,
  flipped,
  size,
  className,
  root,
  description,
  exactSize,
  color,
  name,
  ...rest
}) => {
  const iconContent = React.useMemo(() => {
    const icoData = (icons as Record<string, string | undefined>)[name] ?? 'unknown'
    return `\\${icoData}`
  }, [name])

  const iconClass = React.useMemo(() => {
    let classString = 'h-icon common'
    if (className != null) {
      classString += ' ' + className
    }
    return classString
  }, [className])

  const iconSize = React.useMemo(() => {
    if (size != null) {
      if (size === 'small') {
        return '1rem!important'
      } else if (size === 'medium') {
        return '1.5rem!important'
      } else if (size === 'large') {
        return '2rem!important'
      } else if (size === 'xlarge') {
        return '4rem!important'
      } else {
        return `${size}!important`
      }
    } else {
      return undefined
    }
  }, [size])

  const iconColor = React.useMemo(() => color != null ? colorLookup(color) : '', [color])

  return (
      <StIcon
        className={iconClass}
        title={description ?? ''}
        aria-label={description ?? ''}
        iconContent={iconContent}
        size={iconSize}
        color={iconColor}
        {...rest}
      />
  )
}

// @ts-ignore
// eslint-disable-next-line no-import-assign
Object.defineProperty(StyledIcon, 'default', {
  value: PatchedStyledIcon
})
