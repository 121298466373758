import React, { useEffect, useState, memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { OperationsApi } from 'Services'
import { StyledHeader } from './header-logo.styles'

function Header({
  children,
  getAlerts = () => { },
  menu = false,
  onMenuToggle = false,
  title = ""
}) {
  const [logo, setLogo] = useState(undefined)
  const organizationid = useSelector(({ user }) => user?.selectedOrganizationId)
  const cancellationToken = useRef()

  useEffect(() => {
    getLogo(organizationid)
    return function cleanUp() {
      cancellationToken.current?.cancel()
    }
  }, [organizationid])

  useEffect(() => {
    getAlerts()
  }, [])

  async function getLogo(orgId) {
    const cancelToken = axios.CancelToken.source()
    cancellationToken.current = cancelToken
    const response = await OperationsApi.getLogo(orgId, cancelToken.token)
    if (response.ok) {
      setLogo(response.data?.data)
    } else {
      setLogo(null)
    }
  }
  return (
    <StyledHeader
      imageurl={logo}
      title={title}
      menu={menu}
      onMenuToggle={onMenuToggle}
    >
      {children}
    </StyledHeader>
  )
}

export default memo(Header)
