import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import DeviceUsageModal from './device-usage-net-modal'
import deviceUsageModalSelector from './device-usage-net-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    deviceUsageModalSelector,
    mapDispatchToProps
  )
)(DeviceUsageModal)
