import apiClient from 'Services/ApiSauceInstance'
import { ApiResponse } from '../../../Services/api-response'

export interface CreateManufacturer {
  organizationId: string;
  manufacturerName: string;
  companyName?: string;
  email?: string;
  address?: string;
  country?: string;
}

export interface Manufacturer extends CreateManufacturer {
  id: string;
}

export const getManufacturers = (organizationId: string) => apiClient
  .get<ApiResponse<Manufacturer[]>>('manufacturers')

export const createManufacturer = (manufacturer: CreateManufacturer) => apiClient
  .post<ApiResponse<undefined>>('manufacturers', manufacturer)
