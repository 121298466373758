import { pipe } from 'ramda'
import BulkCloseModal from './bulk-close-modal'
import ModalActions from '../../../../../Redux/ModalRedux'
import { translate } from 'react-translate'
import BulkCloseModalSelector from './bulk-close-modal.selector'
import { connect } from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal, props) => dispatch(ModalActions.modalOpen(modal, props))
  }
}
export default pipe(
  translate('BulkEditModal'),
  connect(
    BulkCloseModalSelector,
    mapDispatchToProps
  )
)(BulkCloseModal)
