import styled, { css } from 'styled-components/macro'
import { Select } from '@scuf/common'

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

export const FormContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 1rem;
  justify-content: flex-end;

  & + & {
    margin-top: 1rem;
  }
`

export const Row = styled.div`
  & + & {
    margin-top: 1rem;
  }
`

export const TableContainer = styled(Row)`
  flex: 1;
`

export const StyledSelect = styled(Select)`
  width: auto !important;
  max-width: 50%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 100%;
`

export const StyledLink = styled.div<{disabled: boolean}>`
  text-transform: uppercase;
  color: ${p => p.disabled ? '#A0A0A0;' : '#10659F'};
  font-weight: 600;
  cursor: pointer;
  pointer-events: ${p => p.disabled && 'none'};
 `

export const LeftContainerWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const RightContainerWrapper = styled.div`
  display: flex;
  flex-growth: 0;
`