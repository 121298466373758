import { connect } from 'react-redux'
import ModalActions from '../../Redux/ModalRedux'
import CustomTimeDateModal from './custom-time-date-modal'
import CustomTimeDateModalSelector from './custom-time-date-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose()),
    closeAndUpdate: (props) => dispatch(ModalActions.modalCloseUpdate(props))
  }
}

export default pipe(
  translate('CustomTimeDateModal'),
  connect(
    CustomTimeDateModalSelector,
    mapDispatchToProps
  )
)(CustomTimeDateModal)
