import styled from 'styled-components'

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 1rem;
  text-align: justify;
  text-justify: auto;
  line-height: 1.2rem;
`

export const TableContainer = styled.div`
  height: 63vh;
  pointer-events: ${(props) => props.disabled && 'none'};
  margin-bottom: 2.5rem;
`