import styled from 'styled-components/macro'

const Flex = styled.div`
  display: flex;
`
const ActionsRow = styled(Flex)`
  flex-direction: row;
  padding-right: 4rem !important;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  .Icon{
    color: ${props => props.disabled ? 'grey' : 'black'};
  }

`
export {
  ActionsRow
}
