import { connect } from 'react-redux'
import OrganizationChange from './organization-change'
import OrganizationChangeSelector from './organization-change.selector'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

export default pipe(
  connect(OrganizationChangeSelector),
  translate('OrganizationChange')
)(OrganizationChange)
