import { createSelector, createStructuredSelector } from 'reselect'
import Immutable from 'seamless-immutable'
import { ENTERPRISE_DATA_RESET, FULL_FACTORY_RESET, REBOOT, ENTERPRISE_DATA_RESET_COMMAND, FULL_FACTORY_RESET_COMMAND, REBOOT_COMMAND,
  ON_DEMAND_TELEMETRY, ON_DEMAND_TELEMETRY_COMMAND, PRINT_TEST_PAGE, PRINT_TEST_PAGE_COMMAND, PING, INV_SCAN, PULL_CONFIG, START_APP,GET_IMAGE_BUFFER_COMMAND, START_ADVANCED_LOGGING, STOP_ADVANCED_LOGGING, START_ADVANCED_LOGGING_COMMAND, STOP_ADVANCED_LOGGING_COMMAND } from '../device-actions.constants'

export const currentAction = state => {
  return state.deviceActions.currentAction
}

export const actionCommand = createSelector([currentAction], currentAction => {
  let actionCommand = null
  switch (currentAction) {
    case ENTERPRISE_DATA_RESET:
      actionCommand = ENTERPRISE_DATA_RESET_COMMAND
      break
    case FULL_FACTORY_RESET:
      actionCommand = FULL_FACTORY_RESET_COMMAND
      break
    case REBOOT:
      actionCommand = REBOOT_COMMAND
      break
    case ON_DEMAND_TELEMETRY:
      actionCommand = ON_DEMAND_TELEMETRY_COMMAND
      break
    case PRINT_TEST_PAGE:
      actionCommand = PRINT_TEST_PAGE_COMMAND
      break
    case PING:
      actionCommand = PING
      break
    case INV_SCAN:
      actionCommand = INV_SCAN
      break
    case PULL_CONFIG:
        actionCommand = PULL_CONFIG
        break
    case START_APP:
        actionCommand = START_APP
        break
    case GET_IMAGE_BUFFER_COMMAND:
      actionCommand=GET_IMAGE_BUFFER_COMMAND
        break
    case START_ADVANCED_LOGGING:
      actionCommand=START_ADVANCED_LOGGING_COMMAND
        break
    case STOP_ADVANCED_LOGGING:
      actionCommand=STOP_ADVANCED_LOGGING_COMMAND
        break
    default: actionCommand = null
  }
  return actionCommand
}
)
export const currentCulture = state => state.user.language

export const devicesObj = ({ devices }) => devices.getIn(['devices'], {})
export const current = ({ devices }) => devices.getIn(['currentDevice'], '')
export const currentDevice = createSelector([current, devicesObj], (id, devs) => devs.getIn([id], Immutable({})))
export const serial = createSelector([currentDevice, currentCulture],
  (currentDevice, currentCulture) => currentDevice.getIn(['serialNumber'], ''))

export const displayModel = createSelector([currentDevice, currentCulture],
    (currentDevice, currentCulture) => currentDevice.getIn(['displayModel'], ''))

export const deviceType  = createSelector([currentDevice, currentCulture],
    (currentDevice, currentCulture) => currentDevice.getIn(['deviceType'], ''))

export const deviceFamily = createSelector([currentDevice],
    (currentDevice) => {
      const familyType=currentDevice.getIn(['originalObject', 'deviceDetail', 'deviceFamily'], '')
      if(familyType == 'fiji'){
        return 'fiji';
      } else {
        return 'cee'
      }
    })
export default createStructuredSelector({
  currentAction,
  actionCommand,
  serial,
  displayModel,
  deviceType,
  currentCulture,
  deviceFamily
})
