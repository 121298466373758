import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ModalActions from 'Redux/ModalRedux'
import { releaseNotesOpenModal, fetchReleaseNotes } from 'Store/release-notes'
import { helpMenuSelector } from './release-notes-modal/release-notes.selector'

export function useHelpMenu () {
  const dispatch = useDispatch()
  const { hasUnreadeReleaseNotes, accessToken } = useSelector(helpMenuSelector)
  useEffect(() => {
    dispatch(fetchReleaseNotes())
  }, [])

  return useMemo(() => ({
    openOssTerms: () => dispatch(ModalActions.modalOpen('ossTerms')),
    openReleaseModal: () => dispatch(releaseNotesOpenModal()),
    hasUnreadeReleaseNotes
  }), [dispatch, hasUnreadeReleaseNotes])
}
