import { connect } from 'react-redux'

import LoginActions from '../../Redux/LoginRedux'
import DeviceActions from '../../Redux/DeviceRedux'
import Session from './session'

const mapStateToProps = state => {
  return {
    userRole: state.user.userRole,
    error: state.login.error,
    loggedIn: state.login.loggedIn,
    accessToken: state.login.accessToken
  }
}

const mapDisptachToProps = dispatch => ({
  onToken: (token, headerToken, firstTimeLogin) => dispatch(LoginActions.loginSignInRequest(token, headerToken, undefined, firstTimeLogin)),
  deviceSendRaCommand: (deviceId, cmdStr) => dispatch(DeviceActions.deviceSendRaCommandAction(deviceId, cmdStr))
})

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(Session)
