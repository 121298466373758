const create = (api) => {
  const listAutomations = (pageSize = 100, nextPageToken) => {
    if (nextPageToken) {
      return api.get(`/v1/automations?pageToken=${nextPageToken}`)
    }
    return api.get(`/v1/automations?pageSize=${pageSize}`)
  }

  const deleteAutomation = ({
    automationId
  }) => api.delete(`/v1/automations/${automationId}`)

  const getAutomation = (automationId) => {
    return api.get(`/v1/automations/${automationId}`)
  }

  const getRuleActions = (translation, streamName) => api.get(`/v1/automations/actions?stream_name=${streamName}&t=${translation}`)

  const getRuleTriggers = (translation) => api.get(`/v1/automations/triggers?t=${translation}`)

  const createAutomation = (automation) => api.post('/v1/automations', automation)

  const updateAutomation = (automation, id) => api.put(`v1/automations/${id}`, automation)

  const patchAutomation = (id, mask, fields) => api.put(`/v1/automations/${id}?mask=${mask}`, fields)

  const getDataPoints = (streamName, translation) => api.get(`v1/automations/data_points?stream_name=${streamName}&t=${translation}`)

  return {
    listAutomations,
    deleteAutomation,
    getAutomation,
    getRuleActions,
    getRuleTriggers,
    createAutomation,
    patchAutomation,
    updateAutomation,
    getDataPoints
  }
}

export default {
  create
}
