import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popup, VerticalMenu } from '@scuf/common'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'
import styled from 'styled-components'
import { defaultFlags, flagsPropTypes } from '../Utils/launch-darkly-flags'
import UserActions from '../Redux/UserRedux'

const StyledVerticalMenu = styled(VerticalMenu)`
background: ${props => props.theme.background};
& .content-wrap {
  color: ${props => props.theme.h1color};
}

`

export class TranslationsSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { setLanguage, lang, flags } = this.props

    if (!flags.multilanguage) return null

    return (
      <Popup element={<span>{lang}</span>} on='click' position='bottom left'>
        <StyledVerticalMenu>
          {flags.multilanguageEn && <VerticalMenu.Item onClick={() => setLanguage('en-US')}>en-US</VerticalMenu.Item>}
          {flags.multilanguageDe && <VerticalMenu.Item onClick={() => setLanguage('de-DE')}>de-DE</VerticalMenu.Item>}
          {flags.multilanguageEs && <VerticalMenu.Item onClick={() => setLanguage('es-MX')}>es-MX</VerticalMenu.Item>}
          {flags.multilanguageFr && <VerticalMenu.Item onClick={() => setLanguage('fr-FR')}>fr-FR</VerticalMenu.Item>}
          {flags.multilanguageZh && <VerticalMenu.Item onClick={() => setLanguage('zh-CN')}>zh-CN</VerticalMenu.Item>}
          {flags.multilanguagePt && <VerticalMenu.Item onClick={() => setLanguage('pt-BR')}>pt-BR</VerticalMenu.Item>}
          {flags.multilanguageJa && <VerticalMenu.Item onClick={() => setLanguage('ja-JA')}>ja-JA</VerticalMenu.Item>}
          {flags.multilanguageKo && <VerticalMenu.Item onClick={() => setLanguage('ko-KR')}>ko-KR</VerticalMenu.Item>}
          {flags.multilanguageTh && <VerticalMenu.Item onClick={() => setLanguage('th-TH')}>th-TH</VerticalMenu.Item>}
        </StyledVerticalMenu>
      </Popup>
    )
  }
}

TranslationsSwitch.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  flags: flagsPropTypes
}

TranslationsSwitch.defaultProps = {
  flags: defaultFlags,
  t: () => null
}
const mapStateToProps = state => {
  return {
    lang: state.user.language ? state.user.language : 'en-US'
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (lang) => dispatch(UserActions.userSetLanguage(lang))
  }
}

export default pipe(
  withLDConsumer(),
  connect(mapStateToProps, mapDispatchToProps)
)(TranslationsSwitch)
