import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import UpdatesActions from '../../../../Redux/UpdatesRedux'
import { isValidTime, onSchedule, updatesFontsList } from '../../schedule-updates.selector'
import FontsTable from './fonts-table'

const validate = ({ deviceList }, { data }) => {
  if (deviceList) {
    const list = Object.keys(deviceList).reduce((errors, asset) => {
      if (!deviceList[asset].selected) {
        return errors
      }

      const assetIdx = data.findIndex(i => i.id === asset)
      const labelOptional = data[assetIdx].type !== 'DEVCONFIG-font/ttf'
      return {
        ...errors,
        [asset]: {
          drive: deviceList[asset].drive ? '' : 'error',
          fontId: deviceList[asset].fontId ? '' : 'error',
          label: labelOptional || deviceList[asset].label ? '' : 'error'
        }
      }
    }, {})
    return { deviceList: list }
  }
}

const mapStateToProps = state => {
  return {
    data: updatesFontsList(state),
    onSchedule: onSchedule(state),
    isValidTime: isValidTime(state),
    initialValues: {
      requestPriority: 'Optional',
      deviceList: {}
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatesSelectAssets: updates => {
      const updatesId = updates.map(u => u.id)
      dispatch(UpdatesActions.updatesSelectAssets(updatesId))
    }
  }
}

export default pipe(
  reduxForm({
    form: 'fontPrinterForm',
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FontsTable)
