import { createStructuredSelector, createSelector } from 'reselect'
import moment from 'moment'
import { orderBy } from 'lodash'
import { fedexData } from '../../Utils/constants'

export const mockedData = fedexData

const endDate = moment().format('YYYY-MM-DD').toString()

export const AssetsReports = ({ dashboard }) =>
  dashboard.getIn(['assetsReports'], {})

export const data = createSelector(
  [AssetsReports],
  data => orderBy(data.getIn(['values'], mockedData), ['checkoutDate', 'tag'], ['desc', 'asc'])
)

export const devicesLoading = createSelector(
  [AssetsReports],
  loading => loading.getIn(['loading'], true)
)

export const totalDevices = createSelector(
  [data],
  data => data.length
)

export const users = createSelector(
  [data],
  data => data.map(obj => {
    return {
      text: obj.user,
      value: obj.user
    }
  }).filter(user => user.text !== '')
)

export const totalCheckouts = createSelector(
  [data],
  (data) => data.reduce((prevVal, currentVal) => prevVal + (currentVal.status === 'CHECK OUT' ? 1 : 0), 0)
)

export const checkOutToday = createSelector(
  [data],
  [endDate],
  (data, endDate) => {
    return data.reduce((prevVal, currentVal) =>
      prevVal + (currentVal.status === 'CHECK OUT' && moment(currentVal.checkoutDate).isSame(endDate, 'day') ? 1 : 0), 0)
  })

export const totalNotReturned = createSelector(
  [data],
  [endDate],
  (data, endDate) => data.filter(obj =>
    obj.checkinDate === '' && moment(endDate).isAfter(obj.dueDate) && !moment(endDate).isSame(obj.dueDate, 'day')
  ).length
)

export const totalOverdues = createSelector(
  [data],
  (data) => data.filter(obj => (moment(obj.checkinDate).isAfter(obj.dueDate)) ||
  (obj.checkinDate === '' && moment(endDate).isAfter(obj.dueDate) && !moment(endDate).isSame(obj.dueDate, 'day'))
  ).length
)

export const retiredYTD = createSelector(
  [data],
  (data) => data.filter(obj => obj.status === 'RETIRED' && moment(endDate).isSame(obj.checkinDate, 'year')
  ).length
)

export const lostThisMonth = createSelector(
  [data],
  (data) => data.filter(obj => obj.status === 'LOST' && moment(endDate).isSame(obj.checkoutDate, 'month')
  ).length
)

export const lostYTD = createSelector(
  [data],
  (data) => data.filter(obj => obj.status === 'LOST' && moment(endDate).isSame(obj.checkoutDate, 'year')
  ).length
)

export const totalCheckinsToday = createSelector(
  [data],
  (data) => data.filter(obj => moment(endDate).isSame(obj.checkinDate, 'day')
  ).length
)

export const totalCheckoutsToday = createSelector(
  [data],
  (data) => data.filter(obj => moment(endDate).isSame(obj.checkoutDate, 'day') && obj.status === 'CHECK OUT'
  ).length
)

export default createStructuredSelector({
  data,
  devicesLoading,
  totalDevices,
  users,
  totalCheckouts,
  checkOutToday,
  totalNotReturned,
  totalOverdues,
  retiredYTD,
  lostThisMonth,
  lostYTD,
  totalCheckinsToday,
  totalCheckoutsToday
})
