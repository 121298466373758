import React, { memo } from 'react'
import { DataTableContext } from '../../data-table'
import { ToolsItem, ToolsTotalCount, ToolsIcon } from './actionbar.styles'

const ActionBarItem = memo(({ onClick, disabled, icon, iconRoot, content }) => {
  return (
    <DataTableContext.Consumer>
      {selectedItems =>
        <ToolsItem onClick={() => disabled ? null : onClick(selectedItems)} disabled={disabled} className='tools-item'>
          <ToolsIcon
            disabled={disabled}
            root={iconRoot}
            name={icon}
            size='medium'
          />
          <ToolsTotalCount disabled={disabled}>{content}</ToolsTotalCount>
        </ToolsItem>
      }
    </DataTableContext.Consumer>
  )
})

export default ActionBarItem
