import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  addComparingSite: ['siteId', 'level', 'siteName'],
  deleteComparingSite: ['siteId'],
  addRankingSite: ['siteId', 'level', 'siteName'],
  siteRankingsRequest: ['params'],
  siteRankingsResponse: ['data', 'actionType'],
  siteComparisonRequest: ['params'],
  siteComparisonResponse: ['data', 'actionType']
})

export const SiteComparisonTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  siteComparison: {
    sites: [],
    data: {},
    organizationData: [],
    organizationAverage: 0
  },
  siteRanking: {
    selectedSite: {},
    data: {}
  }
})

export const siteExists = (sites, siteId) =>
  sites.filter(site => site.siteId === siteId).length

/* ------------- Reducers ------------- */
export const addSite = (state, { siteId, level, siteName }) => siteExists(state.getIn(['siteComparison', 'sites']), siteId)
  ? state
  : state.updateIn(['siteComparison', 'sites'], (sites) => [...sites, { siteId, level, siteName }])

export const addRankingSite = (state, { siteId, level, siteName }) => state.updateIn(['siteRanking', 'selectedSite'], () => ({ siteId, level, siteName }))

export const deleteSite = (state, { siteId }) => state.updateIn(['siteComparison', 'sites'], (sites) => sites.filter(site => site.siteId !== siteId))

export const rankingSitesRequest = (state) => state

export const siteRankingResponse = (state, { data, actionType }) => state.updateIn(['siteRanking', 'data', actionType], () => data)

export const sitesComparisonRequest = (state) => state

export const sitesComparisonResponse = (state, { data, actionType }) => state.updateIn(['siteComparison', 'data', actionType], () => data)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_COMPARING_SITE]: addSite,
  [Types.DELETE_COMPARING_SITE]: deleteSite,
  [Types.ADD_RANKING_SITE]: addRankingSite,
  [Types.SITE_RANKINGS_REQUEST]: rankingSitesRequest,
  [Types.SITE_RANKINGS_RESPONSE]: siteRankingResponse,
  [Types.SITE_COMPARISON_REQUEST]: sitesComparisonRequest,
  [Types.SITE_COMPARISON_RESPONSE]: sitesComparisonResponse
})
