import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BreadcrumbActions from '../Redux/BreadcrumbRedux'
import config from '../Config/AppConfig'

export class EpReportPage extends Component {
  redirectToEp (loggedIn, token) {
    const url = `${config.api.epUrl}?token=${token}`
    if(loggedIn){
      const _component = document.createElement('a')

      _component.href = url
      _component.target='_blank'
      _component.click()

      this.props.history.goBack()
    }
  }

  render () {
    const { token, loggedIn } = this.props
    this.redirectToEp(loggedIn, token)
    return (
      <div />
    )
  }
}

EpReportPage.propTypes = {
  loggedIn: PropTypes.bool,
  token: PropTypes.string
}

EpReportPage.defaultProps = {
  loggedIn: false,
  token: ''
}

const mapDispatchToProps = (dispatch) => {
    return {
      setBreadcrumb: (items) => dispatch(BreadcrumbActions.breadcrumbSetItems(items))
    }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.login.loggedIn,
    token: state.login.accessToken
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpReportPage)