import { Modal, Stepper, Checkbox, Icon  } from '@scuf/common'
import { Form } from 'redux-form'
import styled from 'styled-components/macro'

export const StyledStepperItem = styled(Stepper.Item)`
  & > .content {
    background: transparent !important;
  }
`

export const ScrollableForm = styled(Form)`
  max-height: 100vh;
  overflow-y: auto;
`

export const Title = styled.h3`
  color: #404040;
  font-size: 18px;
`

export const ModalContent = styled(Modal.Content)`
  max-height: 100vh;
  overflow: auto !important;
`

export const ControlContainer = styled.div`
  display: flex;
  flex: .133;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5em;
`

export const SoftwareTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: bolder;
  padding: 1em;
`

export const SoftwareJobContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`

export const JobTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bolder;
  font-family: inherit;
  padding: 1em 0.3em 1em 1em;
`
export const EditJobNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const JobName = styled.div`
  font-size: 1.25rem;
  font-weight: bolder;
  font-family: inherit;
  max-width: 330px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const JobNameInput = styled.input`
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: bolder;
  color: #303030;
  border-color: transparent;
  width: ${(props) => props.edited && '330px'};
  height: 34px;
  margin-right: 0.75rem;
  &:disabled {
    color: #303030;
    background: none;
  }
  &:focus {
    font-weight: normal;
    outline: thin solid #A0A0A0;
  }
`

export const StyledEditIcon = styled(Icon)`
  cursor: pointer;
  color: #1274B7;
  margin-bottom: 0.25rem;
  && {
    -webkit-text-stroke: 0.6px white;
  }
`

export const StyledCheckIcon = styled(Icon)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
  color: white;
  background-color: ${(props) => props.disabled ? '#A0A0A0' : '#1274B7'};
  border: thin solid ${(props) => props.disabled ? '#A0A0A0' : '#1274B7'};
  margin-right: 0.75rem;
  pointer-events: ${(props) => props.disabled && 'none'};
  && {
    -webkit-text-stroke: ${(props) => props.disabled ? '0.3px #A0A0A0' : '0.3px #1274B7'};
  }
`

export const StyledCancelIcon = styled(Icon)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
  color: #1274B7;
  background-color: white;
  border: thin solid #1274B7;
  && {
    -webkit-text-stroke: 0.3px white;

  }
`

export const DeviceCountTitle = styled.div`
  color: #808080;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 2rem 1.5rem 0rem;
  justify-content: space-between;
`

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: -webkit-fill-available;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align};
  margin-top:-30px;
`

export const InfoContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align};
  margin-top:-25px;
  margin: 0 1rem 0 5rem;
  min-width:7rem;
`

export const InfoTitle = styled.span`
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: flex-end;
`

export const AlertContainer = styled.div`
  display: flex;
  flex: 2;
  align-self: center;
`
export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 1.25em;
`
export const TimeErrorContainer = styled.div`
  color:#BE271C
`
export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`
export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  padding-right: 1rem;
`
export const Paragraph = styled.div`
  font-size: 0.875rem;
  font-style: italic;
  color: #707070;
  line-height: 1rem;
  margin-top:-0.55rem;
  margin-bottom: 0.75rem;
`
