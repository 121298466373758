import React from 'react'
import { Modal, Icon } from '@scuf/common'
import styled from 'styled-components'
import { ModalWrap, ModalTitle } from './version-support-modal.styles'
import config from '../../Config/AppConfig'
import PropTypes from 'prop-types'

export const Container = styled.div`
  height: calc(95vh - 5vh);
  overflow-y: auto;
  overflow-x: hidden;
`

const VersionSupportModal = ({ closeModal, agentVersion, t }) => {
  const AGENT_VERSION_MIN = config.agentMinVersion
  return (
    <ModalWrap>
      <ModalTitle>
        <Icon color='yellow' name='badge-warning' size='large' /> {t('Version_Upgrade')}
      </ModalTitle>
      <Modal.Content>
        {t('AgentVersion_Validation', { agentVersion, AGENT_VERSION_MIN })}
      </Modal.Content>
    </ModalWrap>
  )
}

VersionSupportModal.defaultProps = {
  closeModal: () => null
}

VersionSupportModal.propTypes = {
  closeModal: PropTypes.func,
  agentVersion: PropTypes.string,
  t: PropTypes.func
}

export default VersionSupportModal
