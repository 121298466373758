import { connect } from 'react-redux'
import WeekDaySelector from './WeekDaySelector'

const mapStateToProps = state => {
  return {
    theme: state.user.theme
  }
}
export default connect(
  mapStateToProps
)(WeekDaySelector)
