/* Honeywell Raw Colors from HDLS */

// Blues
export const hwColorBlue = '#1792e5'
export const hwColorBlue50k = '#0B4872'
export const hwColorBlue40k = '#0D5789'
export const hwColorBlue30k = '#10659F'
export const hwColorBlue20k = '#1274B7'
export const hwColorBlue80 = '#45A7EA'
export const hwColorBlue70 = '#5DB2EC'
export const hwColorBlue50 = '#8BC8F2'
export const hwColorBlue20 = '#D0E9F9'
export const hwColorBlue21 = '#2376e3'
export const hwColorBlue22 = '#005EAC'
export const hwColorBlue23 = '#D9e7f2'

// Greens
export const hwColorGreen = '#7eb338'
export const hwColorGreen50k = '#3E591B'
export const hwColorGreen20k = '#648F2C'
export const hwColorGreen80 = '#97C25F'
export const hwColorGreen50 = '#BED99B'
export const hwColorGreen20 = '#E5EFD7'
export const hwColorGreen21 = '#008813'

// Yellow
export const hwColorYellow = '#ffC627'
export const hwColorYellow50k = '#7F6213'
export const hwColorYellow20k = '#CC9E1F'
export const hwColorYellow80 = '#FFD152'
export const hwColorYellow50 = '#FFE293'
export const hwColorYellow20 = '#FFF3D3'

// Red
export const hwColorRed = '#EE3124'
export const hwColorRed50k = '#761811'
export const hwColorRed20k = '#BE271C'
export const hwColorRed80 = '#F15A4F'
export const hwColorRed50 = '#F69891'
export const hwColorRed20 = '#FBD5D3'
export const hwColorRed21 = '#D60B13'

// Purple
export const hwColorPurple = '#9B59B6'
export const hwColorPurple50k = '#4D2C5A'
export const hwColorPurple20k = '#7C4791'
export const hwColorPurple80 = '#AF7AC4'
export const hwColorPurple50 = '#CDACDA'
export const hwColorPurple20 = '#EBDDF0'
export const hwColorPurple30 = '#6D6FCC'

// Orange
export const hwColorOrange = '#F37021'
export const hwColorOrange50k = '#793710'
export const hwColorOrange20k = '#C2591A'
export const hwColorOrange80 = '#F58C4D'
export const hwColorOrange50 = '#F9B790'
export const hwColorOrange20 = '#FCE2D2'
export const hwColorOrange21 = '#D1670B'

// White
export const hwColorWhite = '#FFFFFF'
export const hwColorWhiteRich = '#F7F7F7'
export const hwColorWhiteLyric = '#F0F0F0'
export const hwColorShadow = '##0000001A'

// Grey
export const hwColorGrey10 = '#E0E0E0'
export const hwColorGrey20 = '#D0D0D0'
export const hwColorGrey25 = '#C0C0C0'
export const hwColorGrey30 = '#B0B0B0'
export const hwColorGrey40 = '#A0A0A0'
export const hwColorGrey50 = '#808080'
export const hwColorGrey60 = '#707070'
export const hwColorGrey70 = '#606060'
export const hwColorGrey75 = '#505050'
export const hwColorGrey80 = '#404040'
export const hwColorGrey85 = '#303030'
export const hwColorGrey90 = '#101010'
export const hwColorGrey91 = '#D8D8D8'
export const hwColorBlack = '#000000'
export const hwContentBlack = '#202020'

// Black
export const hwColorBlackChrome = '#303030' // Chrome Black per HDLS
export const hwColorBlackContent = '#252525' // Chrome Black per HDLS
export const hwColorShadow2 = '#00000080'
export const darkThemeContent = '#252525'

//Brown
export const hwColorBrown1 = '#897826'

// Pink
export const hwColorPink1 = '#E1287C'
