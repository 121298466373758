import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@scuf/common'
import { translate } from "react-translate"
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { useHistory, useLocation } from 'react-router-dom'
import config from '../Config/AppConfig'
import { getHashVariables } from 'Utils/Transforms'
import LoginActions from 'Redux/LoginRedux'
import { withPermissions } from 'Utils/permissions'

function Callback (props) {
  const {
    t,
    loggedIn,
    onToken,
    permissionsCount,
    error
  } = props
  const history = useHistory()
  const location = useLocation()

  function _onToken (hash) {
    const auth = getHashVariables(hash)
    if (auth.id_token && !loggedIn) {
      onToken(auth.id_token, auth['#access_token'], true)
    }
  }

  useEffect(() => {
    if (props.loggedIn && permissionsCount > 0) {
      const roles = ['isToolsViewer', 'isTCOViewer', 'isHaaSViewer', 'isHaaSAdmin']
      const deviceUserRole = ['isDeviceViewUser']

      if (roles.includes(props.userRole)) {
        history.push('/tools')
      } else if (deviceUserRole.includes(props.userRole)) {
        history.push('/assets/management/all')
      } else {
        history.push(config.site.landing)
      }
    } else if(error) {
      console.error(error)
      history.push('/login')
    }
  }, [props.loggedIn, history, props.userRole, permissionsCount, error])

  useEffect(() => {
    if (window.parent && location.hash) {
      window.parent.postMessage(location.hash, config.site.safeUrl)
      _onToken(location.hash)
    } else if (!props.accessToken) {
      window.parent.postMessage('request_login=true', config.site.safeUrl)
      history.push('/login')
    }
  }, [])

  return (
    <Loader text={t('App_Loading')}>
      <div className='placeholder' />
    </Loader>
  )
}

Callback.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func
}

Callback.defaultProps = {
  location: {},
  t: () => null
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  userRole: state.user.userRole,
  loggedIn: state.login.loggedIn,
  error: state.login.error
})
const mapDisptachToProps = dispatch => ({
  onToken: (token, headerToken, firstTimeLogin) => dispatch(LoginActions.loginSignInRequest(token, headerToken, undefined, firstTimeLogin))
})

export default pipe(
  withPermissions(),
  translate('App'),
  connect(
    mapStateToProps,
    mapDisptachToProps
  )
)(Callback)
