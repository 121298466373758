import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@scuf/common'

import {
  OnboardPromptWrapper, ItemContainer, OnboardTitle, OnboardSubtitle, OnboardButtonHolder, StyledButton
} from './prompt-error.styles'

import { PROMPT_TYPES } from './prompt-error.constants'

const PromptError = ({ type, t, iconProps, buttonProps, action }) => {
  const {
    title,
    subtitle,
    icon,
    buttonContent,
    buttonType } = PROMPT_TYPES[type]
  const noOnboard = ['accessPoints', 'softwareUpdates', 'other']

  return (
    <OnboardPromptWrapper>
      <ItemContainer>
        <Icon {...icon} {...iconProps} />
        <OnboardTitle>{t(title)}</OnboardTitle>
        <OnboardSubtitle>{t(subtitle)}</OnboardSubtitle>
        <OnboardButtonHolder>
          {!noOnboard.includes(type) &&
            <StyledButton
              content={t(buttonContent)}
              onClick={() => action(type)}
              type={buttonType}
              {...buttonProps}
            />}
        </OnboardButtonHolder>
      </ItemContainer>
    </OnboardPromptWrapper>
  )
}

PromptError.propTypes = {
  action: PropTypes.func,
  type: PropTypes.string.isRequired,
  t: PropTypes.func,
  iconProps: PropTypes.object,
  buttonProps: PropTypes.object
}

PromptError.defaultProps = {
  action: () => null,
  buttonProps: {},
  iconProps: {},
  t: label => label,
  type: 'dashboard'
}

export default PromptError
