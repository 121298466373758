import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Tag as StyledTag
} from '../../tags.styles'

const Tag = ({
  name,
  value,
  ...props
}) => {
  return (
    <StyledTag
      {...props}
    >
      {name} : <b>{value}</b>
    </StyledTag>
  )
}

export default Tag
