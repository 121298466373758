import { Modal, Tab } from '@scuf/common'
import React, { Fragment, useEffect, useRef } from 'react'
import ContentLoader from 'react-content-loader'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'

import TimeSelector from 'Components/TimeSelector'
import UpdateExecution from '../ScheduleModal/components/AssetInfo/update-execution-method'
import { defaultFlags } from 'Utils/launch-darkly-flags'

import { FormInputContainer, Title } from '../../Themes/ScufStyledComponents'
import FontsTable from './components/FontsTable'
import GeneralTable from './components/GeneralTable'
import ImagesTable from './components/ImagesTable'

const DataTableContainer = styled.div`
  height: calc(100% - 17rem);
  width: 100%;
  margin-top: 0.5rem;
  overflow-y: unset !important;
`
const PreferencesContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
const DateTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-top: 1rem;
`

export const TabContainer = styled.div`
  padding-bottom: 0.5rem;
`

const ScrollContainer = styled(Fragment)`
  max-height: 100vh;
  overflow-y: scroll;
`

const ScheduelUpdatesModal = ({
  t,
  deviceType,
  updatesPrinterTabSelection,
  tab,
  loading,
  schedule,
  flags,
  scheduleDetails,
  onSubmit,
  updatePreferences
}) => {
  const scheduleRef = useRef(schedule)
  const scheduleDetailsRef = useRef(scheduleDetails)
  const updatePreferencesRef = useRef(updatePreferences)
  useEffect(() => {
    updatesPrinterTabSelection(0)
  }, [])

  useEffect(() => {
    scheduleRef.current = schedule
    scheduleDetailsRef.current = scheduleDetails
    updatePreferencesRef.current = updatePreferences
  }, [schedule, scheduleDetails, updatePreferences])

  const submitForm = assets => {
    onSubmit({
      assetList: assets,
      scheduleTime: scheduleRef.current,
      details: scheduleDetailsRef.current,
      updatePreferences: updatePreferencesRef.current
    })
  }

  return loading
    ? <ContentLoader
      height={400}
      style={{ height: '100%', width: '100%' }}
    >
      <rect x='0' y='0' width='200' height='24' />
      <rect x='0' y='48' width='400' height='300' />
      <rect x='0' y='372' width='200' height='24' />
    </ContentLoader>
    : <ScrollContainer>
      <Modal.Header>
        <Title>
          {t('Select assets')}
        </Title>
      </Modal.Header>
      <DataTableContainer>
        {deviceType === 'printer'
          ? <TabContainer>
            <Tab onTabChange={updatesPrinterTabSelection}>
              <Tab.Pane title={t('General')} />
              <Tab.Pane title={t('Fonts')} />
              <Tab.Pane title={t('Images')} />
            </Tab>
          </TabContainer>
          : null
        }
        {tab === 0 && <GeneralTable compact={deviceType === 'printer'} onSubmit={submitForm} t={t} />}
        {tab === 1 && <FontsTable compact={deviceType === 'printer'} onSubmit={submitForm} t={t}/>}
        {tab === 2 && <ImagesTable compact={deviceType === 'printer'} onSubmit={submitForm} t={t}/>}
      </DataTableContainer>
      {
        flags.overrideSwUpdateNetwokPrefrerences &&
        <PreferencesContainer>
          <UpdateExecution/>
        </PreferencesContainer>
      }
      <Fragment>
        <Field
          style={{ 'height': '100%' }}
          component={TimeSelector}
          t={t}
          flags={flags}
          name='ScheduleTime'
        />
      </Fragment>
    </ScrollContainer>
}

ScheduelUpdatesModal.defaultProps = {
  flags: defaultFlags,
  t: l => l,
  updatesPrinterTabSelection: () => null
}

export default ScheduelUpdatesModal
