import { createStructuredSelector, createSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import { msToTime } from 'Utils/ConvertTime'
import { formatAppName } from 'Utils/format-app-names'
import { formatBytes } from 'Utils/BytesConverter'


export const deviceId = ({ devices }) => devices.getIn(['currentDevice'], '')

export const appData = ({ dashboard }) => dashboard.getIn(['appDataCustom'], [])

export const loading = ({ dashboard }) => dashboard.getIn(['appDataCustom', 'loading'], [])

const filteredData = createSelector(
  [appData],
  apps => Object.keys(apps).map(app => apps[app]).filter(f => f && get(f, 'packagename', false))
)

export const data = createSelector(
  [filteredData],
  apps => apps.map(app => {
    return ({
      appName: formatAppName(app.packagename),
      pkgName: app.packagename,
      version: app.versionname,
      useTime: app.appusagetime ? msToTime(app.appusagetime) : '00h 00m',
      cpuUse: isNaN(parseInt(app['cpu.total'])) ? '0' : `${(parseInt(app['cpu.total']) / (1000 * 60)).toFixed(2)}` === '0.00' ? 0 : `${(parseInt(app['cpu.total']) / (1000 * 60)).toFixed(2)}`,
      netIn: app['network.received'] ? formatBytes(parseInt(app['network.received'])) : '0 KB',
      netOut: app['network.sent'] ? formatBytes(parseInt(app['network.sent'])) : '0 KB'
    })
  }
  )
)

const finalData = createSelector(
  [data],
  apps => apps.filter(f => !(f.useTime <= 0 && f.netIn <= 0 && f.netOut <= 0))
)

export const defaultRangeValue = () => {
  const endDate = moment()
  const startDate = moment().subtract(1, 'days')
  return {
    from: startDate, to: endDate
  }
}

export default createStructuredSelector({
  appData,
  data: finalData,
  loading,
  deviceId,
  defaultRangeValue
})
