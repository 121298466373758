import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    border-bottom: 1px solid #c8c8c8;
    background-color:transparent !important;
  }

  & .p-datatable-scrollable-body {
    max-height: 39.5vh !important;
  }
  & .ui.single-input .search-icon {
    height: 2.5rem;
  }

  & .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }
`

const ModalTitle = styled(Modal.Header)`
  font-size: 1.75rem;
`
const DataTableContainer = styled.div`
  & .ui.forge-datatable-wrap > div.p-datatable{
    min-height: 35.5vh
  }
`
const DatePickerContainer = styled.div`
  display: flex;
  flex: 1;
  margin-right: 0.5em;

  & .ui.date-picker:last-child {
    margin-bottom: 0 !important;
  }
  & .ui.single-input.has-icon.align-right .input-box {
    margin-bottom: 0;
  }
  & .ui.date-picker-input.fluid {
    width: 100%;
  }
  & .below-text{
    min-height: 0rem !important;
    margin: 0 !important;
  }
  & .date-picker-footer{
    display: none !important
  }
`

export { ModalWrap, ModalTitle, DataTableContainer, DatePickerContainer }
