import { createSelector } from 'reselect'

export const getPermissions = fn => createSelector(
  (...props) => typeof fn === 'function' ? fn(...props) : null,
  ({ permissions }) => permissions?.allPermissions ?? {},
  ({ permissions }) => permissions?.permissionsPerSites ?? {},
  (site, permissions, sites) => {
    if (site) {
      return (sites?.[site] ?? [])
        .reduce((permissionsObj, permission) => ({
          ...permissionsObj,
          [permission]: true
        }), {})
    } else {
      return permissions
    }
  }
)
