import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-translate'
import { pipe } from 'ramda'


const LinkLi = styled.li`
  cursor: pointer;
  /* font-weight: 700; */
`
const BreadcrumbContainer = styled.div`
  align-content: center;
  height: 2rem;
  padding-top: 0px;
  padding-left: 0px;
  @media (max-width: 768px) {
    display: ${props => props.display ? 'flex' : 'none'};
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: #f7f7f7;
  }
`

class Breadcrumb extends PureComponent {
  render() {
    // const { crumbs } = this.state
    const { crumbs, t, userRole, history } = this.props
    const display = crumbs.length > 0


    const breadcrumbActions = (action, iterator) => {
      if (action.goTo) {
         return <LinkLi key={`${action.name}${iterator}`} onClick={() => history.push(action.goTo)}>{t(action.name)}</LinkLi>
      } 
       else if (action.goToFilters) {
          return <LinkLi key={`${action.name}${iterator}`} onClick={() => action.goToFilters()}>{t(action.name)}</LinkLi>
       } 
      else {
         return <li key={`${action.name}${iterator}`}>{action.name.startsWith('Breadcrumb_') ? t(action.name) : action.name}</li>
      }
    }

    return (
      <BreadcrumbContainer display={display}>
        <ul className='Breadcrumb-List'>
          {userRole === 'isToolsViewer' ||
            userRole === 'isTCOViewer' ||
            userRole === 'isHaaSViewer' ||
            userRole === 'isHaaSAdmin'
            ? <LinkLi onClick={() => history.push('/reports/tools')}>{t('Breadcrumb_Home')}</LinkLi>
            : null
          }
          {
            crumbs.map((c, i) => breadcrumbActions(c, i))
          }
        </ul>
        {/* eslint-ignore-next-line */}
      </BreadcrumbContainer>
    )
  }
}

Breadcrumb.defaultProps = {
  crumbs: [],
  t: () => null
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.array,
  t: PropTypes.func,
  userRole: PropTypes.string
}

const mapStateToProps = state => {
  return {
    crumbs: state.breadcrumb.crumbs,
    userRole: state.user.userRole
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default pipe(
  translate('Breadcrumb'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Breadcrumb)
