import * as Yup from 'yup'

export default ({ t }) => Yup.object().shape({
  name: Yup.string()
    .required(t('Required')),
  ssid: Yup.string()
    .required(t('Required')),
  bssid: Yup.string()
    .required(t('Required')),
  floor: Yup.string()
    .required(t('Required')),
  building: Yup.string()
    .required(t('Required'))
})
