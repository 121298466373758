import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import PrintersOutOfRibbon from './printers-out-of-ribbon-modal'
import PrintersOutOfRibbonSelector from './printers-out-of-ribbon-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    PrintersOutOfRibbonSelector,
    mapDispatchToProps
  )
)(PrintersOutOfRibbon)
