import PreferencesAlerts from './PreferencesAlerts'
import { translate } from 'react-translate'
import PreferencesSelector from './PreferencesAlerts.selector'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

export default pipe(
  translate('PreferencesAlerts'),
  connect(PreferencesSelector)
)(PreferencesAlerts)
