import { createSelector, createStructuredSelector } from 'reselect'
import Immutable from 'seamless-immutable'
import { legacyTranslate } from '../i18n/translate.helpers'
import { offerSubscription } from '../Selectors/OfferSubscriptionSelector'

export const telemetryData = ({ devices }) => devices.getIn(['telemetryData'], {})
export const theme = ({ user }) => user.getIn(['theme'], {})
export const loading = ({ devices }) => devices.getIn(['loading'], false)
export const devicesObj = ({ devices }) => devices.getIn(['devices'], {})
export const current = ({ devices }) => devices.getIn(['currentDevice'], '')
export const currentCulture = state => state.user.language
export const currentDevice = createSelector([current, devicesObj], (id, devs) => devs.getIn([id], Immutable({})))
export const photoUrl = createSelector([currentDevice],
  currentDevice => currentDevice.getIn(['photoUrl'], 'Not Available'))
export const serialNumber = createSelector([currentDevice, currentCulture],
    (currentDevice, currentCulture) => currentDevice.getIn(['serialNumber'], legacyTranslate(`${currentCulture}.Not_available`)))

export default createStructuredSelector({
  telemetryData,
  loading,
  photoUrl,
  serialNumber,
  theme,
  offerSubscription
})
