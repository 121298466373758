import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from './../../../../../Components/AssetManagementTable'
import { ModalWrap, ModalTitle } from './offline-devices-by-site-modal.styles'

export const columns = [
  { field: 'key', translation: 'AssetManagementTable_deviceSite', render: null },
  { field: 'value', translation: 'AssetManagementTable_modelCount', render: null },
  { field: 'percentage', translation: 'AssetManagementTable_percentage', render: ({ value }) => <p>{value} %</p> }
]

const OfflineDevicesBySiteModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('OfflineDevicesBySite_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns}
        disableRowClick
        fileName={t('OfflineDevicesBySite_title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default OfflineDevicesBySiteModal
