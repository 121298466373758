import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { findIndex } from 'lodash'
import { update } from 'ramda'

const { Types, Creators } = createActions({
  preferencesEmailRequest: ['form'],
  preferencesEmailRequestSuccess: ['data'],
  preferencesEmailRequestFailure: ['error'],
  preferencesEmailDeleteRequest: ['settingsId'],
  preferencesEmailDeleteRequestSuccess: ['settingsId'],
  preferencesEmailDeleteRequestFailure: ['settingsId'],
  preferencesEmailGetRequest: ['userId'],
  preferencesEmailGetRequestSuccess: ['preferences'],
  preferencesEmailGetRequestFailure: null,
  preferencesSetSelectedReport: ['id'],
  preferencesEmailEditRequest: ['form'],
  preferencesEmailEditRequestSuccess: ['data'],
  preferencesEmailEditRequestFailure: ['error'],
  preferencesEmailGetTimeZones: [],
  preferencesEmailGetTimeZonesSuccess: ['data'],
  preferencesEmailGetTimeZonesError: ['error'],
  preferencesAlertsDataRequest: ['organizationId'],
  preferencesEmailNotificationDataRequest: ['data'],
  preferencesEmailReportDataRequest: ['data'],
  preferencesGenericSuccess: ['data'],
  preferencesGenericError: ['error'],
  preferencesChangeAlertValueRequest: ['alertsList'],
  preferencesChangeAlertValueResponse: ['alerts'],
  preferencesSitesUpdate: ['sites'],
  toggleEnabled: ['enabled'],
  preferencesEmailNewReportOpen: null,
  preferencesEmailNewReportClose: null
})

export const PreferencesTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  scheduledReports: [],
  selectedReportId: null,
  timeZones: [],
  error: false,
  editing: false,
  alertsData: {},
  newReport: {
    isOpen: false
  }
})

export const preferencesEmailRequest = state => state.merge({ loading: true })

export const preferencesEmailRequestSuccess = state => state.merge({ loading: false })

export const preferencesEmailRequestFailure = (state, { error }) => state.merge({ loading: false, editing: false, error })

export const preferencesEmailEditRequest = state => state.merge({ loading: true })

export const preferencesEmailEditRequestSuccess = state => state.merge({ loading: false, editing: false })

export const preferencesEmailEditRequestFailure = (state, { error }) => state.merge({ loading: false, editing: false, error })

export const preferencesEmailDeleteRequest = state => state.merge({ loading: true })

export const preferencesEmailDeleteRequestSuccess = (state, { settingsId }) => {
  return state.merge({
    scheduledReports: state.scheduledReports.filter(report => report.id !== settingsId),
    loading: false,
    editing: false
  })
}

export const preferencesEmailDeleteRequestFailure = state => state.merge({ loading: false, editing: false, error: true })

export const preferencesEmailGetRequest = state =>
  state.merge({
    loading: true,
    error: false
  })

export const preferencesEmailGetRequestSuccess = (state, { preferences }) =>
  state.merge({
    loading: false,
    error: false,
    scheduledReports: preferences
  })

export const preferencesEmailGetRequestFailure = state =>
  state.merge({
    error: true,
    loading: false
  })

export const setSelectedReport = (state, { id }) => state.merge({ selectedReportId: id, editing: !!id })

export const preferencesEmailGetTimeZones = state => state.merge({ loading: true, error: false })

export const preferencesEmailGetTimeZonesSuccess = (state, { data }) =>
  state.merge({
    error: false,
    loading: false,
    timeZones: data
  })

export const preferencesEmailGetTimeZonesError = state =>
  state.merge({
    error: true,
    loading: false,
    timeZones: []
  })

export const genericRequest = state => state

export const genericSuccess = (state, { data }) => state.updateIn(['alertsData'], () => data)

export const changeAlertValueResponse = (state, { alerts }) => {
  let prevAlertsList = state.getIn(['alertsData', 'alerts'], [])
  alerts.forEach(alert => {
    const index = findIndex(prevAlertsList, (value) => value.id === alert.ruleId)
    prevAlertsList = update(
      index,
      {
        ...prevAlertsList[index],
        enabled: alert.enabled,
        notificationEmail: alert.notificationEmail
      },
      prevAlertsList
    )
  })
  return state.setIn(['alertsData', 'alerts'], prevAlertsList)
}

export const genericFailure = state => state

export const preferencesSitesUpdate = (state, { sites }) => state.updateIn(['alertsData', 'sites'], () => sites)

export const toggleEnabled = (state, { enabled }) => state.updateIn(['alertsData', 'enabled'], () => enabled)

export const preferencesEmailNewReportOpen = state => state.updateIn(['newReport'], () => ({
  isOpen: true
}))

export const preferencesEmailNewReportClose = state => state.updateIn(['newReport'], () => ({
  isOpen: false
}))

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PREFERENCES_EMAIL_REQUEST]: preferencesEmailRequest,
  [Types.PREFERENCES_EMAIL_REQUEST_SUCCESS]: preferencesEmailRequestSuccess,
  [Types.PREFERENCES_EMAIL_REQUEST_FAILURE]: preferencesEmailRequestFailure,
  [Types.PREFERENCES_EMAIL_EDIT_REQUEST]: preferencesEmailEditRequest,
  [Types.PREFERENCES_EMAIL_EDIT_REQUEST_SUCCESS]: preferencesEmailEditRequestSuccess,
  [Types.PREFERENCES_EMAIL_EDIT_REQUEST_FAILURE]: preferencesEmailEditRequestFailure,
  [Types.PREFERENCES_EMAIL_DELETE_REQUEST]: preferencesEmailDeleteRequest,
  [Types.PREFERENCES_EMAIL_DELETE_REQUEST_SUCCESS]: preferencesEmailDeleteRequestSuccess,
  [Types.PREFERENCES_EMAIL_DELETE_REQUEST_FAILURE]: preferencesEmailDeleteRequestFailure,
  [Types.PREFERENCES_EMAIL_GET_REQUEST]: preferencesEmailGetRequest,
  [Types.PREFERENCES_EMAIL_GET_REQUEST_SUCCESS]: preferencesEmailGetRequestSuccess,
  [Types.PREFERENCES_EMAIL_GET_REQUEST_FAILURE]: preferencesEmailGetRequestFailure,
  [Types.PREFERENCES_SET_SELECTED_REPORT]: setSelectedReport,
  [Types.PREFERENCES_EMAIL_GET_TIME_ZONES]: preferencesEmailGetTimeZones,
  [Types.PREFERENCES_EMAIL_GET_TIME_ZONES]: preferencesEmailGetTimeZonesSuccess,
  [Types.PREFERENCES_EMAIL_DELETE_REQUEST_FAILURE]: preferencesEmailDeleteRequestFailure,
  [Types.PREFERENCES_ALERTS_DATA_REQUEST]: genericRequest,
  [Types.PREFERENCES_EMAIL_NOTIFICATION_DATA_REQUEST]: genericRequest,
  [Types.PREFERENCES_EMAIL_REPORT_DATA_REQUEST]: genericRequest,
  [Types.PREFERENCES_GENERIC_SUCCESS]: genericSuccess,
  [Types.PREFERENCES_GENERIC_ERROR]: genericFailure,
  [Types.PREFERENCES_CHANGE_ALERT_VALUE_REQUEST]: genericRequest,
  [Types.PREFERENCES_CHANGE_ALERT_VALUE_RESPONSE]: changeAlertValueResponse,
  [Types.PREFERENCES_SITES_UPDATE]: preferencesSitesUpdate,
  [Types.TOGGLE_ENABLED]: toggleEnabled,
  [Types.PREFERENCES_EMAIL_NEW_REPORT_OPEN]: preferencesEmailNewReportOpen,
  [Types.PREFERENCES_EMAIL_NEW_REPORT_CLOSE]: preferencesEmailNewReportClose
})
