import * as React from 'react'
import { useTranslate } from 'react-translate'

import CsvFileDrop from 'Components/CsvFileDrop'
import { AssetImport } from '../../apis'
import { SpreadsheetHeaders, nameSeparator } from './import-assets-hooks'

export interface FileUploaderProps {
  onFileLoaded: (data: AssetImport[]) => any;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileLoaded
}) => {
  const translate = useTranslate('OtherEquipmentForm')

  return (
    <CsvFileDrop
      placeholder={translate('UploadPlaceholder')}
      acceptedTypes={['.csv', '.xlsx']}
      disabled={false}
      onLoadFileSuccess={(data: ImportData[]) => {
        onFileLoaded(processRawImportData(data))
      }}
      onReset={() => {}}
      height='30vh'
    />
  )
}

interface ImportData {
  [SpreadsheetHeaders.Model]?: string | any;
  [SpreadsheetHeaders.SerialNumber]?: string | any;
  [SpreadsheetHeaders.Alias]?: string | any;
}

function getImportDataField (data: ImportData, field: keyof ImportData): string {
  if (typeof data[field] === 'string') {
    return data[field]
  } else if (data[field]?.text) {
    return data[field].text as string
  }

  return ''
}

function processRawImportData (data: ImportData[]): AssetImport[] {
  return data.map(r => {
    const modelParts = getImportDataField(r, SpreadsheetHeaders.Model).split(nameSeparator)
    const manufacturerName = modelParts[0] ?? undefined
    const assetTypeName = modelParts[1] ?? undefined
    const assetModelName = modelParts[2] ?? undefined

    return {
      manufacturerName,
      assetTypeName,
      assetModelName,
      serialNumber: getImportDataField(r, SpreadsheetHeaders.SerialNumber),
      alias: getImportDataField(r, SpreadsheetHeaders.Alias)
    }
  })
}
