import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  devicesTagsRequest: ['assets'],
  devicesTagsSuccess: ['tags'],
  devicesTagsFailure: ['error'],
  toggleSelection: ['id'],
  devicesCreateTagRequest: ['tag'],
  tagsAllKeysRequest: null,
  tagsAllKeysSuccess: ['values'],
  tagUpdateValue: ['key', 'newValue'],
  updateTagsRequest: ['tags'],
  updateTagsSuccess: null
}, {
  prefix: '@TAGGING/'
})

export const TaggingActionTypes = Types
export const TaggingActionCreators = Creators
