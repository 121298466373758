import { useEffect, Fragment } from 'react';
import EndUserLicenseContent from './Components/EndUserLicenseContent'
import { defaultFlags } from 'Utils/launch-darkly-flags'

export const EndUserLicense = ({ accepted = false, loading = false, children }) => {
  return loading ? <Fragment /> : accepted ? children : <EndUserLicenseContent />
}

export default ({
  flags = defaultFlags,
  getEulaValidation = () => { },
  history = {},
  loading = false,
  accepted = false,
  loggedIn = false,
  children
}) => {

  useEffect(() => {
    if (loggedIn) {
      getEulaValidation()
    }
  }, [loggedIn])

  return flags.eulaVerification & loggedIn ? <EndUserLicense accepted={accepted} loading={loading}>{children}</EndUserLicense> : children
};



