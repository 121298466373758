import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  modalOpen: ['modal', 'modalProps'],
  modalClose: null,
  modalSetProps: ['props'],
  modalCloseUpdate: ['modalProps'],
  openDrillComponent: null,
  closeDrillComponent: null
})

export const ModalTypes = Types
export default Creators

const DEFAULT_MODAL_PROPS = {
  closeIcon: true,
  size: 'large',
  className: 'modal-height modal-center',
  closeOnDimmerClick: false
}

export const INITIAL_STATE = Immutable({
  isOpen: false,
  type: '',
  modalProps: DEFAULT_MODAL_PROPS,
  custom: '',
  drilldown: false
})

/* ------------- Reducers ------------- */

const open = (state, { modal, modalProps = DEFAULT_MODAL_PROPS }) =>
  state.merge({ type: modal, modalProps, isOpen: true })

const close = state => INITIAL_STATE

const closeUpdate = (state, { modalProps }) =>
  state.merge({ custom: modalProps })

// NOTE: Props for the scuf modal component, such as className, icon, etc.
const setProps = (state, { props }) => state.merge({ modalProps: props })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MODAL_OPEN]: open,
  [Types.MODAL_CLOSE]: close,
  [Types.MODAL_CLOSE_UPDATE]: closeUpdate,
  [Types.MODAL_SET_PROPS]: setProps
})
