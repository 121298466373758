import React from 'react'
import { Modal } from '@scuf/common'
import { ModalWrap, ModalTitle } from './deep-sleep-modal.styles'
import AssetManagementTable from '../../AssetManagementTable'
import PropTypes from 'prop-types'

export const columns = [
  { field: 'timestamp', translation: 'AssetManagementTable_Timestamp', render: ({ value }) => <p>{value}</p> },
  { field: 'deepSleepIntervalList', translation: 'AssetManagementTable_Intervals', render: ({ value }) => <p>{value}</p> },
  { field: 'count', translation: 'AssetManagementTable_Count', render: ({ value }) => <p>{value}</p> }
]

const DeepSleepModel = ({ data, closeModal, t }) => {
  return (
    <ModalWrap>
      <ModalTitle>{t('NetChart_Title')}</ModalTitle>
      <Modal.Content>
        <AssetManagementTable
          columns={columns}
          data={data}
          disableRowClick={true}
          fileName='Deep Sleep'
          selectionMode='none'
        />
      </Modal.Content>
    </ModalWrap>
  )
}
DeepSleepModel.defaultProps = {
  closeModal: () => null,
  data: []
}

DeepSleepModel.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.array,
  t: PropTypes.func
}

export default DeepSleepModel
