import { createStructuredSelector, createSelector } from 'reselect'
import { get, find } from 'lodash'

export const printersFaultyDotsData = ({ dashboard }) =>
  dashboard.getIn(['printersFaultyDots', 'values'], [])
export const sites = ({ siteManager }) =>
  siteManager.getIn(['rootSitesById'], [])
export const data = createSelector(
  [printersFaultyDotsData, sites],
  (data, sites) => data.map((row, index) => ({
    key: row.value,
    aliasName: row.aliasName,
    value: row.serialNumber,
    deviceSite: get(find(sites, { value: row.siteId }), 'text', ' '),
  }))
)

export default createStructuredSelector({
  data
})
