import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tab } from '@scuf/common'
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'

import uploadCsvSelector from '../Selectors/UploadCsvSectionSelector'
import FormDevices from './Upload/components/form-devices'
import SiteBulkUpload from './Upload/components/sites-bulk-edit'
import { Title } from '../Themes/ScufStyledComponents'
import { defaultFlags, flagsPropTypes } from '../Utils/launch-darkly-flags'

export class UploadCsvSection extends Component {
  constructor (props) {
    super(props)
    this.state = { currentIndex: 0 }
  }

  render () {
    const { flags, isVisible, t } = this.props
    const { currentIndex } = this.state
    const tabs = [
      {
        title: 'UploadCsv_Provisioning',
        component: <FormDevices />,
        isVisible: true
      }
      // {
      //   title: 'UploadCsv_Devices',
      //   component: <SiteBulkUpload />,
      //   isVisible: flags.bulkEditDevices
      // }
    ]
    return (
      <div>
        <Modal.Header style={{ justifyContent: 'space-between', paddingBottom: '0px' }}>
          <Title>{t('UploadCsv_Title')}</Title>
        </Modal.Header>

        <Tab
          activeIndex={currentIndex}
          onTabChange={(i) => {
            this.setState({ currentIndex: i })
          }}
        >
          {
            tabs
              .filter(tab => tab.isVisible)
              .map(tab =>
                <Tab.Pane title={t(tab.title)} key={tab.title}>
                  {tab.component}
                </Tab.Pane>
              )
          }
        </Tab>
      </div>
    )
  }
}

UploadCsvSection.propTypes = {
  flags: flagsPropTypes,
  isVisible: PropTypes.bool,
  t: PropTypes.func
}

UploadCsvSection.defaultProps = {
  flags: defaultFlags,
  isVisible: false,
  t: () => null
}

export default pipe(
  translate('UploadCsv'),
  withLDConsumer(),
  connect(uploadCsvSelector, null)
)(UploadCsvSection)
