import React from 'react'
import PropTypes from 'prop-types'
import { Input as ScufInput } from '@scuf/common'
import { useField, useFormikContext } from 'formik'

const Input = ({
  name,
  setMask,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  const { submitCount } = useFormikContext()

  const handleChange = (meta, newValue, fieldName) => {
    if (typeof setMask !== 'function') {
      return
    }
    if (meta.initialValue !== newValue) {
      setMask(oldMask => !oldMask.includes(fieldName)
        ? [...oldMask, fieldName]
        : [...oldMask])
    } else if (meta.initialValue === newValue) {
      setMask(oldMask => oldMask.filter(p => p !== fieldName))
    }
  }
  return (
    <ScufInput
      error={(meta.touched || submitCount > 0) && meta.error}
      id={name}
      onBlur={() => helpers.setTouched(true)}
      onChange={(value) => {
        handleChange(meta, value, name)
        helpers.setValue(value)
      }}
      value={field.value}
      {...rest}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired
}

export default Input
