import get from 'lodash/get'
import CronBuilder from 'Components/AlertsManagementModal/cron-builder'

export class MaintenanceService {
  constructor (api) {
    this.api = api
    this.cronBuilder = new CronBuilder()
  }

  createEvent = event => {
    return this.api.post('/devices/events', [{
      ...event,
      SourceType: 'devices',
      Category: 'Maintenance'
    }])
  }

  scheduleAlert = (sites, cronExpression) => {
    return this.api.post('/schedules', {
      requestPriority: 'Optional',
      scheduleTime: (new Date()).toISOString(),
      CommandType: 'Maintenance',
      cronExpression,
      CommandParameters: {
        Type: 'ROUTINE_CHECK',
        SubType: 'Sanitize'
      },
      ApplicableSites: sites.map(s => ({ id: s, name: '/ foo' }))
    })
  }

  createRule = ({
    name,
    scheduleTime,
    cron,
    priority,
    site,
    checkedDevices,
    fromHour,
    toHour,
    repeat,
    scheduleTimeZoneId,
    uiScheduleTimeZoneId
  }) => {
    return this.api.post('/rules', {
      name,
      Description: 'Maintenance Cleaning Rule',
      Trigger: {
        Name: 'schedule',
        Parameters: {
          scheduleTime,
          scheduleTimeZoneId,
          cron,
          uiFromHour: fromHour,
          uiToHour: toHour,
          uiRepeatInterval: repeat,
          uiScheduleTimeZoneId
        }
      },
      Action: [{
        Name: 'alertNotification',
        Parameters: {
          priority,
          EventType: 'Maintenance',
          deviceCheckStatus: checkedDevices ? 'checkedout' : undefined,
          EventSubtype: 'Sanitize'
        }
      }],
      site
    }
    )
  }

  getRules = async site => {
    const response = await this.api.get('/rules', {
      filter: JSON.stringify({
        site
      })
    })

    if (!response.ok) {
      return response
    }

    const data = get(response, 'data.data', [])
      .map(rule => {
        const fromHour = get(rule, 'trigger.parameter.uifromhour')
        const toHour = get(rule, 'trigger.parameter.uitohour')
        const repeat = get(rule, 'trigger.parameter.uirepeatinterval')
        const scheduletime = get(rule, 'trigger.parameter.scheduletime')
        const checkedDevices = get(rule, 'action.0.parameter.devicecheckstatus', false)
        const scheduleTimeZoneId = get(rule,
          'trigger.parameter.uischeduletimezoneid'
        )
        try {
          const cron = get(rule, 'trigger.parameter.cron', '')
            .split(' ')
          cron.splice(-1, 1)
          const [_, ...cleaned] = cron
          const cronBuilder = new CronBuilder(cleaned.join(' '))
          const days = cronBuilder.get('dayOfTheWeek')
            .split(',')

          return {
            ...rule,
            fromHour: parseInt(fromHour),
            toHour: parseInt(toHour),
            repeat: parseInt(repeat),
            repeated: repeat > 0,
            scheduleTime: scheduletime && (new Date(scheduletime)),
            checkedDevices,
            days,
            scheduleTimeZoneId
          }
        } catch (error) {
          return rule
        }
      })
    return {
      ...response,
      data: { data }
    }
  }

  removeRule = site => this.api.delete(`/rules/${site}`)
}
