import React from 'react'
import styled from 'styled-components'
import { useTranslate } from 'react-translate'

export const Paragraph = styled.p`
  padding-top: 2rem;
  color: ${({ theme }) => theme.colors.textChrome};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`

export default function SubscribeRelease () {
  const t = useTranslate('ReleaseNotes')
  return (
    <Paragraph>
      {t('SubscribeMsg')}
    </Paragraph>
  )
}
