import { normalize } from 'normalizr'
import { rolesSchema } from './entity'

class RolesService {
  constructor (api) {
    this.api = api
  }

  createCustomRole = async role => {
    return this.api.post('/roles', role)
  }

  editCustomRole = async (id, role) => {
    return this.api.put(`/roles/${id}`, role)
  }

  getRole = async (id) => {
    return this.api.get(`/roles/${id}`)
  }

  deleteCustomRole = async (id) => {
    return this.api.delete(`/roles/${id}`)
  }

  getRoles = async (params) => {
    const response = await this.api.get(`/UserManagement/users/roles`, params)

    if (!response.ok) {
      return response
    }

    return {
      ...response,
      data: normalize(response.data, rolesSchema)
    }
  }

  listInternalRoleUsers = async (roleId) => {
    return this.api.get(`/roles/internal/${roleId}`)
  }

  addUserToInternalRole = async (ids, roleId) => {
    const bodyObj = { ids, roleId }
    return this.api.post(`/roles/internal`, bodyObj)
  }

  removeUserFromInternalRole = async (ids, roleId) => {
    const bodyObj = { ids, roleId }
    return this.api.delete(`/roles/internal`, null, { data: bodyObj })
  }

}

export default RolesService
