import { connect } from 'react-redux'
import get from 'lodash/get'

import LocationNode from './location-node'
import LocationActions from '../../../../Redux/LocationRedux'

export const currentLocation = state => get(state, 'locations.currentLocation.1', '')

const locationProps = (state, { id, schema }) => {
  const location = get(state, `locations.entities.${schema}.${id}`, {})
  const open = state.locations.selectedLocation.indexOf(location.locationGuid) !== -1
  return {
    ...location,
    open
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentLocation: currentLocation(state),
    ...locationProps(state, props)
  }
}

const mapDispatchToProps = dispatch => ({
  onClick: e => dispatch(LocationActions.locationExpand(e))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationNode)
