import React from 'react'
import styled from 'styled-components/macro'
import {
  Select
} from '@scuf/common'
import { translate } from 'react-translate'

const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-right: ${props => props.theme.padding};
  padding: ${props => props.padding};

  .delete.icon {
    z-index: 200;
  }

  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }

  & .ui .label {
    word-break: keep-all !important;
  }

  & div[role=combobox] {
    flex-wrap: wrap;
  }

`

const SelectWrapper = ({
  input: { onChange, value },
  meta: { error, visited, submitFailed },
  reserveSpace,
  t,
  ...rest
}) => {
  var errorTranslation = t(error)
  return (
    <StyledSelect
      onChange={onChange}
      value={value}
      error={(visited || submitFailed) && error ? errorTranslation : null}
      {...rest}
      reserveSpace={reserveSpace}
    />
  )
}

export default translate('FormInput')(SelectWrapper)