import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { WeekDaysContainer, WeekDay } from './WeekDaySelector.styles'
import { ThemeProvider } from 'styled-components/macro'

const WeekDaySelector = ({
  onChange,
  value,
  theme
}) => {
  useEffect(() => {
    if (!value) {
      onChange(1)
    }
  }, [value, onChange])

  return (
    <ThemeProvider theme={theme}>
      <WeekDaysContainer>
        <WeekDay selected={value === 1} onClick={() => onChange(1)}>
          Mo
        </WeekDay>
        <WeekDay selected={value === 2} onClick={() => onChange(2)}>
          Tu
        </WeekDay>
        <WeekDay selected={value === 3} onClick={() => onChange(3)}>
          We
        </WeekDay>
        <WeekDay selected={value === 4} onClick={() => onChange(4)}>
          Th
        </WeekDay>
        <WeekDay selected={value === 5} onClick={() => onChange(5)}>
          Fr
        </WeekDay>
        <WeekDay selected={value === 6} onClick={() => onChange(6)}>
          Sa
        </WeekDay>
        <WeekDay selected={value === 7} onClick={() => onChange(7)}>
          Su
        </WeekDay>
      </WeekDaysContainer>
    </ThemeProvider>
  )
}
WeekDaySelector.displayName = 'WeekDaySelector'
WeekDaySelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  theme: PropTypes.any
}
WeekDaySelector.defaultProps = {
  onChange: () => { },
  theme: {}
}

export default WeekDaySelector
