import styled from 'styled-components/macro'
import { Select } from '@scuf/common'

export const QrContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export const Column = styled.div`
  height: 100%;
  display: flex;
  flex: ${props => props.flex ?? 1};
  flex-direction: column;
  margin: 0 0.5rem;
  padding-right: 0.5rem
`

export const CenterCard = styled.div`
  margin: ${props => props.margin || '0px'};
  padding: ${props => props.padding || '0px'};
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const QRImageContainer = styled.div`
  position: relative;
  min-height: 30vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const QRImage = styled.img`
  object-fit: contain;
  height: 30vh;
  width: 30vh;
  min-height: 450px;
  min-width: 450px;
  border-style: ${props => props.qrValue && 'solid'};
  border-color: ${props => props.qrValue && 'white'};
  border-width: ${props => props.qrValue && '10px'};
`

export const QrSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

export const QrFooterContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 0 1rem 1rem 1rem;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
`

export const QrSiteSelect = styled(Select)`
  width: 100%;

  & .ui.selection.dropdown {
    justify-content: space-between;
  }

  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    max-height: 20vh;
  }

  @media screen (max-width: 720px) {
    & .ui.selection.dropdown {
      margin-bottom: 1rem !important;
      font-size: 14px;
    }

    & span.text {
      font-size: 14px !important;
    }

    & div.text {
      padding-top: 0;
      padding-bottom: 0;
    }

    & div {
      padding: 0.5rem;
      font-size: 14px !important;
    }
  }
`
export const QrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & .header {
    padding: 1rem;
  }
`

export const QRsNavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const QRsWarningDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 0;
  width: 100%;
`

export const QRsNavigationColumnLeft = styled.div`
  color: #606060;
  font-family: "Honeywell Sans Web";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  width: 33%;
  text-align: left;
`

export const QRsNavigationColumnCenter = styled.div`
  color: #606060;
  font-family: "Honeywell Sans Web";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  width: 33%;
  text-align: center;
`

export const QRsNavigationColumnRight = styled.div`
  width: 33%;
  text-align: right;
`