import styled from 'styled-components/macro'
import { Icon, Modal, Notification } from '@scuf/common'
import DataTable from 'Components/DataTable'
import { CSVLink } from 'react-csv'

export const AccessContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`
export const ActionBarContainer = styled.div`
  display: flex;
  background: transparent;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
`
export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};

  & .below-text {
    display: none;
  }
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & + & {
    margin-left: 1.5rem;
  }
`
export const ActionText = styled.div`
  font-size: 14px;
  color: #303030;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`
export const ActionTextDisabled = styled.div`
  font-size: 14px;
  color: grey;
  font-weight: bold;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`
export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1rem !important;
  margin-top: 0.15rem;
`
export const StyledIconDisabled = styled(Icon)`
  color: grey;
  font-size: 1rem !important;
  margin-top: 0.15rem;
`
export const TableContainer = styled.div`
  padding-top: .45rem;
  height: calc(100% - 3rem);
`
export const StyledCSVLink = styled(CSVLink)`
  display:flex;
  justify-content: flex-start;
  align-self: center;
`
export const StyledActionText = styled.div`
display:flex;
  justify-content: flex-start;
  align-self: center;
  color: #1274b7;
  cursor: pointer;
`

export const StyledCSVLinkDisabled = styled.div`
  display:flex;
  justify-content: flex-start;
  align-self: center;
  color:gray !important;
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`
export const FooterContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`
export const StyledNotification = styled(Notification)`
  & .title {
    color: ${props => props.theme.h1color};
  }
`
export const StyledDataTable = styled(DataTable)`
  & .ui.fitted.checkbox.select-all-checkbox {
    display:none;
    pointer-events:none;
  }
  @media (min-height: 440px){
    min-height: 35vh;
  }
  @media (min-height: 640px){
    min-height: 45vh;
  }
  @media (min-height: 780px){
    min-height: 50vh;
  }
  @media (min-height: 920px){
    min-height: 55vh;
  }
  @media (min-height: 1013px){
    min-height: 59vh;
  }
  @media (min-height: 1800px){
    min-height: 68vh;
  }
`
export const StyledModal = styled(Modal)`
  background: ${props => props.theme.background};
  & .content {
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
`
