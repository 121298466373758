import { Select } from '@scuf/common'
import styled from 'styled-components'

import DataTable from 'Components/DataTable'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1em;
  width: '45vw';
`

export const Tag = styled.div`
  display: block;
  display: flex;
  background-color: ${({ selected }) => selected
    ? '#5DB2EC'
    : '#E4E4E6'};
  margin: 0.3rem 0.3rem;
  max-width: 70%;
  padding: 0.25rem 1rem;
  min-height: 1.5rem !important;
  max-height: 1.5rem !important;
  color: ${({ selected }) => selected
    ? '#FFFFFF'
    : '#303030'};
  text-transform: uppercase;
  user-select: none;
  align-items: center;
  cursor: pointer;
`

export const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  .delete.icon {
    z-index: 200;
  }
  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
  .ui.fluid.dropdown {
    display: block !important;
  }
`

export const StyledDataTable = styled(DataTable)`
  .p-datatable-thead p-datatable-row {
    height: 24px !important;
  }
  .p-datatable-header {
    height: auto !important;
    max-width: min-content;
    max-inline-size: min-content;
  }
  .ReactVirtualized__Table__rowColumn:first-of-type{
    overflow: visible !important;
    margin-right: 1rem;
  }
  .ReactVirtualized__Table__headerColumn:first-of-type{
   margin-right: 1rem;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const RadioContainer = styled.div`
  margin-bottom: 0.25rem;
`
