import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeviceForm from './device-form'
import DeviceActions from '../../../../Redux/DeviceRedux'
import ModalActions from '../../../../Redux/ModalRedux'
import DeviceFormSelector from './device-form.selector'

const mapDispatchToProps = dispatch => ({
  changeDeviceSite: (siteId) => dispatch(DeviceActions.deviceSelectSite(siteId)),
  closeModal: () => dispatch(ModalActions.modalClose()),
  updateDevice: (data) => dispatch(DeviceActions.deviceUpdateRequest(data))
})

export default translate('DeviceForm')(connect(
  DeviceFormSelector,
  mapDispatchToProps
)(DeviceForm))
