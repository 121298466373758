import styled from 'styled-components';
import { Popup, Accordion } from '@scuf/common';

export const HelpMenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
`;

export const ImagePrivacy = styled.img`
  margin-left: 5px;
`
export const PrivacyInfo = styled.span`
  display: flex;
  align-items: center;
`
export const VerticalMenuText = styled.span`
  font-size: 12px;
`;
export const StyledPopup = styled(Popup)`
  & div {
    background: ${(props) => props.theme.inputback};
    color: ${(props) => props.theme.bodyfontcolor};
  }
  & .ui.menu.vertical-menu .item:not(.disabled):hover,
  .ui.menu.vertical-menu .item:not(.disabled).active,
  .ui.menu.vertical-menu > .ui.menu.vertical-menu .item:not(.disabled):hover,
  .ui.menu.vertical-menu > .ui.menu.vertical-menu .item:not(.disabled).active {
    background: ${(props) => props.theme.headerhover};
  }
  & div a {
    color: ${(props) => props.theme.bodyfontcolor};
  }
  & div > div:before {
    display: none !important;
  }
  & .ui.menu.vertical-menu > .ui.menu.vertical-menu {
    border-bottom: none !important;
  }
`;
export const StyledAccordion = styled(Accordion)`
  border: none !important & .title {
    font-size: 0.875rem !important;
    padding: 0.5rem 0.5rem 0.25rem 0.75rem !important;
    font-weight: 500 !important;
  }

  & .content {
    padding: 0 !important;
  }
  & .content-wrap {
    padding-left: 1.25rem !important;
  }
`;

