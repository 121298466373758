import React, { useEffect, useState } from  'react'

import { Icon, Modal, Tab } from '@scuf/common'
import { ButtonSection, ExportSection, FooterNote, ModalContent, ModalFooter, RefreshSection, Title } from './ScheduledDevicesWrapper.styles'
import { useJobDevices } from './ScheduledDevicesWrapper.hooks'

import JobIdentifier from 'features/software-update/components/job-identifier'
import ReachedDevices from './ReachedDevices'
import UnreachedDevices from './UnreachedDevices'

const ScheduledDevicesWrapper = ({
    loading = false,
    pageSize = 0,
    devices = [],
    totalCount = 0,
    scheduleId = '',
    isDark = false,
    exportDevices = () => {},
    handleOnTabChange = () => {},
    refreshDevices = () => {},
    setPageNumber = () => {},
    setSearchTerm = () => {},
    t = () => {}
}) => {
    return(<>
        <Modal.Header>
            <Title>{t('Title')}</Title>
            {loading &&
              <div>
                <Icon loading={true} name='refresh' size='small' />
              </div>
            }
        </Modal.Header>
        <ButtonSection>
                <ExportSection id='export' disabled={!totalCount > 0} isDark={isDark} onClick={() => exportDevices()} >
                    <Icon name='exit' root='common' size='small' />
                    <p>{t('Export')}</p>
                </ExportSection>
                <RefreshSection id='refresh'  disabled={false} isDark={isDark} onClick={() => refreshDevices()} >
                    <Icon name='refresh' root='common' size='small' />
                    <p>{t('Refresh')}</p>
                </RefreshSection>      
        </ButtonSection>
        <ModalContent>
            <Tab id='devicesTab' onTabChange={(activeIndex) => handleOnTabChange(activeIndex)} >
                <Tab.Pane title={t('Reached')} disabled={loading} >
                    <ReachedDevices
                        data={devices}
                        total={totalCount}
                        loading={loading}
                        pageSize={pageSize}
                        setPageNumber={setPageNumber}
                        setSearchTerm={setSearchTerm}
                        t={t}
                    />
                </Tab.Pane>
                <Tab.Pane title={t('Unreached')} disabled={loading} >
                    <UnreachedDevices
                        data={devices}
                        total={totalCount}
                        loading={loading}
                        pageSize={pageSize}
                        setPageNumber={setPageNumber}
                        setSearchTerm={setSearchTerm}
                        t={t} 
                    />
                </Tab.Pane>
            </Tab>
        </ModalContent>
        <ModalFooter>
            <FooterNote>{t('Note')}</FooterNote>
            <JobIdentifier masterGuid={scheduleId} />
        </ModalFooter>      
    </>)
}

export default ({
    scheduleId = '',
    language = '',
    isDark = false,
    t = () => {}
}) => {

    const [loading, setLoading] = useState(false)
    const [isUnreachedTab, setIsUnreachedTab] = useState(false)
    const {
        devicesLoading,
        pageSize,
        devices,
        totalCount,
        exportDevices,
        refreshDevices,
        setPageNumber,
        setSearchTerm,
    } = useJobDevices(scheduleId, language, isUnreachedTab, t)

    const activeTab = {
        Reached: 0,
        Unreached: 1
    }

    const handleOnTabChange = (activeIndex) => {
        setIsUnreachedTab(activeIndex === activeTab.Unreached)
    }

    useEffect(() =>{
        setLoading(devicesLoading) 
    }, [devicesLoading])

    const props = {
        loading,
        pageSize,
        devices,
        totalCount,
        scheduleId,
        isDark,
        exportDevices,
        handleOnTabChange,
        refreshDevices,
        setPageNumber,
        setSearchTerm,
        t
    }

    return(<ScheduledDevicesWrapper {...props} />)
}