import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { currentUserReleases } from './release-notes.selector'

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 1.5rem;
`

const Title = styled.h4`
  padding-right: 1rem;
  color: ${({ theme }) => theme.colors.textBase};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.58px;
  line-height: 17px;
`

export const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.colors.textHighContrast};
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.67px;
  line-height: 20px;
`

const Link = styled.a`
  color: #1792E5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 17px;
  cursor: pointer;
  height: 15px;
`

const Description = styled.p`
  color: ${({ theme }) => theme.colors.textChrome};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 17px;
`

const ReleaseNoteContainer = styled.div`
  padding-bottom: 0.25rem; 
`

const Badge = styled.span`
  color: #BE271C;
`

function useIsReleaseNoteReaded (id) {
  const userReleases = useSelector(currentUserReleases)
  return useMemo(() => userReleases[id] || false, [userReleases, id])
}

export function ReleaseNote ({
  title,
  description,
  url,
  onClick,
  id
}) {
  const isNoteRead = useIsReleaseNoteReaded(id)
  return (
    <ReleaseNoteContainer>
      <TitleContainer>
        <Title>
          {!isNoteRead && <Badge>●&nbsp;</Badge>}
          {title}
        </Title>
        <Link href={url} target='_blank' rel='noreferrer' onClick={() => onClick(id)}>Details</Link>
      </TitleContainer>
      {description && <Description>{description}</Description>}
    </ReleaseNoteContainer>
  )
}
