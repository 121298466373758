import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { translate } from 'react-translate'

import QrForm from './qr-form'
import DeviceActions from '../../../../Redux/DeviceRedux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const mapStateToProps = state => ({
  initialValues: state.devices.qrData, // To initialize form itself
  data: state.devices.qrData, // To actually use the data
  isHoneywellAdmin: state.user.isHoneywellAdmin,
  sites: state.siteManager.rootSitesById
})

const mapDispatchToProps = dispatch => ({
  onChange: (name, value) => dispatch(DeviceActions.deviceSetQrData(name, value)),
  onSubmit: (data) => dispatch(DeviceActions.deviceQrRequest(data))
})

export default pipe(
  reduxForm({
    form: 'qrForm',
    enableReinitialize: true
  }),
  translate('QrForm'),
  withLDConsumer(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QrForm)
