import { useEffect, useState } from 'react'
import { SitesManagerApi } from 'Services'
import get from 'lodash/get'

export function useSites () {
  const [sites, setSites] = useState([])
  const getSites = async () => {
    const response = await SitesManagerApi.getSites()
    if (response.ok) {
      const data = get(response, 'data', [])
        .map(site => {
          return {
            value: site.organizationalUnitIdentifier.organizationalUnitGuid,
            text: site.organizationalUnitHierarchy
          }
        })
      setSites(data)
    }
  }

  useEffect(() => {
    getSites()
  }, [])

  return sites
}
