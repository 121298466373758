import { orderBy } from 'lodash'
import Immutable from 'seamless-immutable'
/**
 * Slice a series of key,value objects top a specified length and groups
 *  the rest of the objects in a object with key 'other'
 * @param { Array<{key: <string> , value:<string>} > } data
 * ArrayList of object with a key and a value
 * @returns {Array<object>}
 */
export const getTopSeriesByKey = (data, top, lang) => {
  let others = []
  const sortedSeries = orderBy(
    data,
    ['value'],
    ['desc']
  )
  if (sortedSeries.length > top) {

    const othersValue = sortedSeries
      .slice(top)
      .reduce((sum, current) => sum + current.value, 0)
    others = [{ key: 'others', value: othersValue }]
  }
   return [...sortedSeries.slice(0, top), ...others]
}
