import { createStructuredSelector, createSelector } from 'reselect'

export const devices = state => state.devices

export const login = state => state.login

export const devicesSelector = createSelector(
  [devices],
  (devices) => devices.getIn(['selectedRemoteAccessDevice'], '')
)

export const remoteAccessLoading = createSelector(
  [devices],
  (devices) => devices.getIn(['selectedRemoteAccessDeviceLoading'], '')
)


export const accessTokenSelector = createSelector(
  [login],
  (login) => login.getIn(['accessToken'], '')
)

export default createStructuredSelector({
  remoteAccessDeviceId: devicesSelector,
  remoteAccessLoading,
  accessToken: accessTokenSelector
})
