import { createStructuredSelector } from 'reselect'

const initialState = {
  tableParams: {},
  applicationName: '',
  isModalOpen: false,
  selectedDisruptions: [],
  selectedRow: [],
  auxiliarSelectedRow: null,
  hideActionBar: false
}

export const applicationDisruptionsByAppDetailsReducer = (state = initialState, { payload, type } = {}) => {
  switch (type) {
    case setTableParams.type:
      return {
        ...state,
        tableParams: payload
      }
    case setApplicationName.type:
      return {
        ...state,
        applicationName: payload
      }
    case setModalIsOpen.type:
      return {
        ...state,
        isModalOpen: payload
      }
    case setSelectedDisruptions.type:
      return {
        ...state,
        selectedDisruptions: payload
      }
    case setSelectedRow.type:
      return {
        ...state,
        selectedRow: payload
      }
    case setHideActionBar.type:
      return {
        ...state,
        hideActionBar: payload
      }
    case setAuxiliarSelectedRow.type:
      return {
        ...state,
        auxiliarSelectedRow: payload
      }
    case resetTableSelection.type:
      return {
        ...state,
        isModalOpen: false,
        selectedRow: [],
        auxiliarSelectedRow: [],
        hideActionBar: true
      }
    default:
      return state
  }
}
applicationDisruptionsByAppDetailsReducer.slice = 'application-disruptions-app-details'

export const setTableParams = (tableParams = {}) => ({ type: setTableParams.type, payload: tableParams })
setTableParams.type = 'application-disruptions-app-details/setTableParams'

export const setApplicationName = (tableParams = {}) => ({ type: setApplicationName.type, payload: tableParams })
setApplicationName.type = 'application-disruptions-app-details/setApplicationName'

export const setModalIsOpen = (isModalOpen = false) => ({ type: setModalIsOpen.type, payload: isModalOpen })
setModalIsOpen.type = 'application-disruptions-app-details/setModalIsOpen'

export const setSelectedDisruptions = (selectedDisruptions = []) => ({ type: setSelectedDisruptions.type, payload: selectedDisruptions })
setSelectedDisruptions.type = 'application-disruptions-app-details/setSelectedDisruptions'

export const setSelectedRow = (selectedRow = []) => ({ type: setSelectedRow.type, payload: selectedRow })
setSelectedRow.type = 'application-disruptions-app-details/setSelectedRow'

export const setAuxiliarSelectedRow = (auxiliarSelectedRow = []) => ({ type: setAuxiliarSelectedRow.type, payload: auxiliarSelectedRow })
setAuxiliarSelectedRow.type = 'application-disruptions-app-details/setAuxiliarSelectedRow'

export const setHideActionBar = (hideActionBar = false) => ({ type: setHideActionBar.type, payload: hideActionBar })
setHideActionBar.type = 'application-disruptions-app-details/setHideActionBar'

export const resetTableSelection = () => ({ type: resetTableSelection.type })
resetTableSelection.type = 'application-disruptions-app-details/resetTableSelection'

export const getTableParams = state => state[applicationDisruptionsByAppDetailsReducer.slice].tableParams
export const getApplicationName = state => state[applicationDisruptionsByAppDetailsReducer.slice].applicationName
export const getModalIsOpen = state => state[applicationDisruptionsByAppDetailsReducer.slice].isModalOpen
export const getSelectedDisruptions = state => state[applicationDisruptionsByAppDetailsReducer.slice].selectedDisruptions
export const getSelectedRow = state => state[applicationDisruptionsByAppDetailsReducer.slice].selectedRow
export const getAuxiliarSelectedRow = state => state[applicationDisruptionsByAppDetailsReducer.slice].auxiliarSelectedRow
export const getHideActionBar = state => state[applicationDisruptionsByAppDetailsReducer.slice].hideActionBar

export const getDetailsDataTableSelectors = createStructuredSelector({
  tableParams: getTableParams,
  applicationName: getApplicationName,
  isModalOpen: getModalIsOpen,
  selectedDisruptions: getSelectedDisruptions,
  selectedRow: getSelectedRow,
  auxiliarSelectedRow: getAuxiliarSelectedRow,
  hideActionBar: getHideActionBar
})
