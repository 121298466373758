import React, { Fragment } from 'react'
import {
  Button
} from '@scuf/common'
import { useTranslate } from 'react-translate'
import { useField, useFormikContext } from 'formik'
import get from 'lodash/get'

const DaysPicker = ({
  name,
  disabled
}) => {
  const t = useTranslate('DaysPicker');
  const [field, meta, helpers] = useField(name)
  const { submitCount } = useFormikContext()
  const days = get(field, 'value', [])
  const setDays = helpers.setValue
  const daysButtons = [{
    day: t('Sun'),
    value: '1'
  }, {
    day: t('M'),
    value: '2'
  }, {
    day: t('Tue'),
    value: '3'
  }, {
    day: t('W'),
    value: '4'
  }, {
    day: t('Thu'),
    value: '5'
  }, {
    day: t('F'),
    value: '6'
  }, {
    day: t('Sat'),
    value: '7'
  }]
  return (
    <Fragment>
      <Button.Group id={name}>
        {daysButtons.map(({ day, value }) => (
          <Button key={value}
            content={day}
            disabled={disabled}
            type={days.includes(value) ? 'primary' : disabled ? 'ss' : 'secondary'}
            onClick={() => {
              days.includes(value)
                ? setDays(days.filter(d => d !== value))
                : setDays([...days, value])
            }}
            minWidth='2rem'
            size='small'
          />
        ))}
      </Button.Group>
      <div className='below-text'>
        <span className='ui error-message'>
          {(meta.touched || submitCount > 0) && meta.error}
        </span>
      </div>
    </Fragment>
  )
}

export default DaysPicker
