import styled from 'styled-components/macro'

const NotificationsWrapper = styled.div`
position: absolute;
top: 37px;
right: 0;
background-color: ${({theme}) => theme.black ? '#303030' : '#FFFFFF' };
box-shadow: 2px 2px 8px rgba(0,0,0,.2);
`
const NotificationLine = styled.div`
display: flex;
flex: 1;
flex-direction:row;
width: 31.25rem;
max-width: 31.25rem;
align-items: center;
border-bottom: 1px solid #c0c0c0;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
padding-left: 1rem;
`
const NotificationText = styled.div`
overflow: hidden;
white-space: nowrap;
width: 31.25rem;
text-overflow: ellipsis;
padding-right: 1rem;
`

const HeaderNotificationsWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0.75rem 1.5rem !important;
  }
`

const BellWrapper = styled.div`
position: relative;
background-color: ${({ background }) => background || ''};
color: ${({ color }) => color || ''};
z-index: 100;
cursor: pointer;
margin-right: 1rem;
@media (max-width: 768px) {
  border-bottom: none;
}
& .ui.label.badge.red{
  @media (max-width: 768px) {
    margin-top: -10px;
  }
}
`
const BellBadge = styled.div`
position: absolute;
top: -5px;
left: -10px;
`
const NotificationHeader = styled.div`
display: flex;
flex: 1;
flex-direction: row;
justify-content: space-between;
font-weight: bold;
`
const NotificationRow = styled.div`
display: flex;
flex: 1;
flex-direction: row;
`
const NotificationColumn = styled.div`
display: flex;
flex: 1;
flex-direction: column;
max-width: 450px;
padding: 0 1rem;
`
const NotificationHeaderText = styled.div`
`

const NotificationHeaderTitle = styled.div`
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
width: 70%;
`

const ZeroNotifications = styled(NotificationLine)`

`
const Alerts = styled.div`
font-family: "Honeywell Sans Web";
font-weight: 500;
margin-left: -7px;
`
const StyledBadge = styled.div`
background-color: ${({color}) => color === 'red' ? '#be271c' : color};
font-size: .65rem;
font-weight: 600;
line-height: .65rem;
color: #ffffff;
padding: .2rem .25rem;
border-radius: .5rem;
min-width:17px;
display:flex;
justify-content: center;
align-items: center;

`

export {
  NotificationsWrapper,
  NotificationLine,
  NotificationText,
  HeaderNotificationsWrapper,
  BellWrapper,
  BellBadge,
  NotificationHeader,
  NotificationColumn,
  NotificationRow,
  NotificationHeaderText,
  NotificationHeaderTitle,
  ZeroNotifications,
  Alerts,
  StyledBadge
}
