const create = (api) => {
  const activityLogReport = params => api.get('/logging/logs', params)

  return {
    activityLogReport
  }
}

export default {
  create
}
