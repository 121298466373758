import * as R from 'ramda'
import { normalize } from 'normalizr'
import { ApisauceInstance } from 'apisauce'
import { CancelToken } from 'axios'

import {
  CreateSite,
  GetAllResult, GetSiteUsersResult,
  ISitesService,
  NormalizedSiteEntities,
  NormalizedSitesResult,
  Site, SiteUser, UserRole
} from './interfaces'
import {
  uglifyBuilding,
  uglifyFloor,
  uglifySite,
  uglifyZone,
  siteEntity
} from './site-entities'
import { ApiResponse } from 'Services/api-response'

export class SitesService implements ISitesService {
  private client: ApisauceInstance

  constructor (client: ApisauceInstance) {
    this.client = client
  }

  public async getAll (cancelToken?: CancelToken): Promise<GetAllResult> {
    const response = await this.client.get('/groupmgmt/OrgUnits/Hierarchy')
    if (response.problem === 'CANCEL_ERROR') {
      throw response.originalError
    }

    if (response.ok) {
      try {
        const data = normalize<any, NormalizedSiteEntities, NormalizedSitesResult>(
          response.data,
          [siteEntity]
        )

        return {
          sites: data.entities?.sites || {},
          rootSiteIds: data.result
        }
      } catch (e) {
        throw new Error('An error occurred while loading locations.')
      }
    } else {
      throw Error('Could not fetch locations.')
    }
  }

  public async getSiteUsers (siteId: string): Promise<GetSiteUsersResult> {
    const response = await this.client.get<BackendSiteUser[]>(`/groupmgmt/OrgUnits/${siteId}/relations/OwnedByUser`, {
      pageNumber: 1,
      pageSize: 1000
    })

    if (response.ok && response.data) {
      try {
        return R.groupBy<SiteUser>(user => user.role)(response.data.map(prettifySiteUser))
      } catch (e) {
        throw new Error('An error occurred while loading site users.')
      }
    } else {
      throw new Error('Could not fetch site users.')
    }
  }

  public async createSite (site: CreateSite): Promise<Site> {
    let requestData
    try {
      requestData = [uglifySite(site)]
    } catch (e) {
      throw new Error('An error occurred while creating the site.')
    }

    const response = await this.client.post<{ id: string[] }>('/groupmgmt/OrgUnits', requestData)

    if (response.ok && response.data) {
      return {
        ...site,
        id: response.data.id[0]
      }
    }
    else if (response.status === 424) {
      throw new Error('A site with the specified name already exists under the parent site. Please specify a unique name.')
    }
    else {
      throw new Error('Could not create site.')
    }
  }

  public async updateSite (site: Site): Promise<Site> {
    let requestData
    try {
      requestData = uglifySite({ ...site, parentId: undefined })
    } catch (e) {
      throw new Error('An error occurred while updating the site.')
    }

    const response = await this.client.put<ApiResponse<undefined>>(`/groupmgmt/OrgUnits/${site.id}`, requestData)

    if (response.ok) {
      return site
    } else {
      throw new Error(response.data?.errors?.[0]?.detail ?? 'Could not update site.')
    }
  }

  public async createBuilding (building: CreateSite): Promise<Site> {
    let requestData
    try {
      requestData = uglifyBuilding(building)
    } catch (e) {
      throw new Error('An error occurred while creating the building.')
    }

    const response = await this.client.post<{ resourceId: string[] }>('/groupmgmt/OrgUnits/Sites', requestData)

    if (response.ok && response.data) {
      return {
        ...building,
        id: response.data.resourceId[0]
      }
    } else {
      throw new Error('Could not create building.')
    }
  }

  public async updateBuilding (building: Site): Promise<Site> {
    let requestData
    try {
      requestData = uglifyBuilding({ ...building, parentId: undefined })
    } catch (e) {
      throw new Error('An error occurred while updating the building.')
    }

    const response = await this.client.put<ApiResponse<undefined>>('/groupmgmt/OrgUnits/Sites', requestData)

    if (response.ok) {
      return building
    } else {
      throw new Error(response.data?.errors?.[0]?.detail ?? 'Could not update building.')
    }
  }

  public async createPackageCar (packgeCar: CreateSite): Promise<Site> {
    let requestData
    try {
      requestData = uglifyBuilding(packgeCar)
    } catch (e) {
      throw new Error('An error occurred while creating the package car.')
    }

    const response = await this.client.post<{ resourceId: string[] }>('/groupmgmt/OrgUnits/PackageCars', requestData)

    if (response.ok && response.data) {
      return {
        ...packgeCar,
        id: response.data.resourceId[0]
      }
    } else {
      throw new Error('Could not create packgeCar.')
    }
  }

  public async createFloor (siteId: string, buildingId: string, floor: CreateSite): Promise<Site> {
    let requestData
    try {
      requestData = [uglifyFloor(floor)]
    } catch (e) {
      throw new Error('An error occurred while creating the floor.')
    }
    const response = await this.client.post<{ resourceId: string [] }>(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, requestData)

    if (response.ok && response.data) {
      return {
        ...floor,
        id: response.data.resourceId[0]
      }
    } else {
      throw new Error('Could not create floor.')
    }
  }

  public async updateFloor (siteId: string, buildingId: string, floor: Site): Promise<Site> {
    let requestData
    try {
      requestData = uglifyFloor({ ...floor, parentId: undefined })
    } catch (e) {
      throw new Error('An error occurred while updating the floor.')
    }
    const response = await this.client.put<ApiResponse<undefined>>(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, requestData)

    if (response.ok) {
      return floor
    } else {
      throw new Error(response.data?.errors?.[0]?.detail ?? 'Could not update floor.')
    }
  }

  public async createZone (siteId: string, buildingId: string, floorId: string, zone: CreateSite): Promise<Site> {
    let requestData
    try {
      requestData = [uglifyZone(zone)]
    } catch (e) {
      throw new Error('An error occurred while creating the zone.')
    }

    const response = await this.client.post<{ resourceId: string[] }>(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, requestData)

    if (response.ok && response.data) {
      return {
        ...zone,
        id: response.data.resourceId[0]
      }
    } else {
      throw new Error('Could not create zone.')
    }
  }

  public async updateZone (siteId: string, buildingId: string, floorId: string, zone: Site): Promise<Site> {
    let requestData
    try {
      requestData = uglifyZone({ ...zone, parentId: undefined })
    } catch (e) {
      throw new Error('An error occurred while updating the zone.')
    }

    const response = await this.client.put<ApiResponse<undefined>>(`groupmgmt/OrgUnits/${siteId}/Sites/${buildingId}/zones `, requestData)

    if (response.ok) {
      return zone
    } else {
      throw new Error(response.data?.errors?.[0]?.detail ?? 'Could not update zone.')
    }
  }

  public async deleteOrgUnit (site: Site): Promise<void> {
    const response = await this.client.delete(`/groupmgmt/OrgUnits/${site.id}`)

    if (!response.ok) {
      throw new Error('Could not delete site.')
    }
  }
}

interface BackendSiteUser {
  userID: string
  fullName: string
  role: string | null
}

function prettifySiteUser (user: BackendSiteUser): SiteUser {
  return {
    id: user.userID,
    name: user.fullName,
    role: user.role ?? UserRole.USER
  }
}
