import React, { Component } from 'react'
import { Modal, Button, Tree } from '@scuf/common'
import { ModalWrap, ModalTitle, TreeContainer, EmptyMessage } from './site-filter-modal.styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { history } from 'Components/Navigation'

const { Item } = Tree.Content

/**
 * Sites displayed on a Tree structure for filtering purposes
 */
class SiteFilterModal extends Component {
  constructor(props) {
    super()
    this.state = {
      selectedSite: props.lastSelectedSiteId,
      level: props.lastSelectedSiteLevel,
      siteName: props.lastSelectedSiteName
    }
    this.renderTree = this.renderTree.bind(this)
  }

  expand(rootSite, siteTree, selectedSite) {
    const site = siteTree[rootSite] ?? {}
    let children = site?.children ?? []
    children = children.filter(id => siteTree[id] !== undefined)
    if (selectedSite === rootSite) {
      return true
    } else {
      return children.filter(child => this.expand(child, siteTree, selectedSite)).length > 0
    }
  }

  /**
   * Render Tree roots and leaves
   */
  renderTree(rootSite, siteTree, level) {
    const site = siteTree[rootSite] ?? {}
    let children = site?.children ?? []
    children = children.filter(id => siteTree[id] !== undefined)

    const { selectedSite } = this.state
    const isSelected = selectedSite ? rootSite === selectedSite : rootSite === this.props.siteId
    const isExpanded = selectedSite ? children.filter(child => this.expand(child, siteTree, selectedSite)).length > 0 : false
    return (
      <Item
        expanded={isExpanded || isSelected}
        active={isSelected}
        icon={children.length > 0 ? 'globe' : 'location'}
        iconRoot={children.length > 0 ? 'common' : 'global'}
        key={rootSite}
        onClick={() => {
          this.setState({ selectedSite: rootSite })
          this.setState({ level })
          this.setState({ siteName: siteTree[rootSite]?.name })
        }}
        onTitleClick={() => {
          this.setState({ selectedSite: rootSite })
          this.setState({ level })
          this.setState({ siteName: siteTree[rootSite]?.name })
        }}
        title={site?.name ?? ''}
      >
        {children.map(child => this.renderTree(child, siteTree, level + 1))}
      </Item>
    )
  }

  render() {
    const {
      closeModal,
      rootSites,
      siteTree,
      setSiteId,
      user,
      siteComparison,
      siteRanking,
      addSites,
      addSite,
      t,
      siteCounter,
      counterSites,
      siteId,
      sitePreferences,
      addSiteToPreferences,
      alertsReportForm,
      addSiteFromReportForm,
      theme
    } = this.props

    const {
      selectedSite,
      level,
      siteName
    } = this.state
    return <ModalWrap>
      <ModalTitle>{t('filterSite')}</ModalTitle>
      <Modal.Content>
        <TreeContainer theme={theme}>
          <Tree>
            <Tree.Content>
              {
                rootSites.length > 0
                  ? rootSites.map(rootSite => this.renderTree(rootSite, siteTree, 0))
                  : <EmptyMessage> <h5> {t('Not_available')} </h5></EmptyMessage>
              }
            </Tree.Content>
          </Tree>
        </TreeContainer>
      </Modal.Content>
      <Modal.Footer>
        <Button content={t('cancel')} onClick={closeModal} type='secondary' />
        <Button content={t('select')} onClick={() => {
          if (siteComparison) {
            addSites(selectedSite, level, siteName)
          } else if (siteRanking) {
            addSite(selectedSite, level, siteName)
          } else if (siteCounter) {
            counterSites(selectedSite, level, siteName)
            history.push(`/counter/${selectedSite}`)
          } else if (sitePreferences) {
            addSiteToPreferences(selectedSite)
          } else if (alertsReportForm) {
            addSiteFromReportForm(selectedSite)
          } else {
            setSiteId(user, selectedSite)
          }
          closeModal()
        }} />
      </Modal.Footer>
    </ModalWrap>
  }
}

SiteFilterModal.displayName = 'SiteFilerModal'

SiteFilterModal.defaultProps = {
  closeModal: () => null,
  rootSite: [],
  setSiteId: () => null,
  siteTree: {},
  t: () => null
}

SiteFilterModal.propTypes = {
  closeModal: PropTypes.func,
  rootSites: PropTypes.array,
  siteTree: PropTypes.object,
  setSiteId: PropTypes.func,
  siteId: PropTypes.string,
  t: PropTypes.func,
  user: PropTypes.string
}

export default SiteFilterModal
