import { HeaderText } from 'Components/DataTable/data-table.styles';

const headerLabelRenderer = ({
  label = "",
}) => {

  const children = [
    <HeaderText>
      {label.toUpperCase()}
    </HeaderText>,
  ];

  return children
}

export default headerLabelRenderer
