import React, { Component, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import {
  Button
} from '@scuf/common'
import { translate } from 'react-translate'

const FormZone = lazy(() => import('./components/form-zone'))
const FormSubZone = lazy(() => import('./components/form-subzone'))
const FormBuilding = lazy(() => import('./components/form-building'))

const MenuFormContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
`
const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 1rem;
  border: 1px solid black;
`

class Form extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tab: null
    }
  }

  componentDidMount() {
    const { locationType } = this.props
    switch (locationType) {
      case 'Building':
        this.setState({ tab: 1 })
        break;
      case 'zone':
      case 'subzone':
        this.setState({ tab: 2 })
        break;
      default:
        this.setState({ tab: 0 })
        break;
    }
  }

  componentDidUpdate (prevProps) {
    const { locationType } = this.props
    if (prevProps.locationType !== locationType) {      
      switch (locationType) {
        case 'Building':
          this.setState({ tab: 1 })
          break;
        case 'zone':
        case 'subzone':
          this.setState({ tab: 2 })
          break;
        default:
          this.setState({ tab: 0 })
          break;
      }
    }
  }

  render() {
    const { tab } = this.state
    const { locationType, locationGuid, t } = this.props
    return (
      <MenuFormContainer>
        <Button.Group className='Location-Button-Group'>
          <Button
            type={tab === 0 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_Buildings')}
            onClick={() => this.setState({ tab: 0 })}
          />
          <Button
            type={tab === 1 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_FloorPlans')}
            disabled={!locationType}
            onClick={() => this.setState({ tab: 1 })}
          />
          <Button
            type={tab === 2 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_Zones')}
            disabled={!locationType || locationType === 'Building'}
            onClick={() => this.setState({ tab: 2 })}
          />
        </Button.Group>
        {tab === 0 && <Suspense
        fallback={
          <FormContainer>{t('LocationForm_Loading')}</FormContainer>
        }>
          <FormBuilding onChange={this._onChange} />
        </Suspense>
        }
        {tab === 1 &&<Suspense fallback={
          <FormContainer>{t('LocationForm_Loading')}</FormContainer>
        }>
          <FormZone refrenceId={locationGuid} onChange={this._onChange} />
        </Suspense>}
        {tab === 2 && <Suspense fallback={
          <FormContainer>{t('LocationForm_Loading')}</FormContainer>
        }>
          <FormSubZone onChange={this._onChange} />
        </Suspense>}
      </MenuFormContainer>
    )
  }
}

export default Form
