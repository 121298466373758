import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  addSite: ['siteId', 'level', 'siteName'],
  requestCounterInfo: [null],
  responseCounterInfo: ['data'],
  getTotalCountRequest: ['siteId'],
  getTotalCountResponse: ['data'],
  updateLimit: ['siteId', 'limit'],
  changeCurrentCount: ['siteId', 'personsCount']
})

export const CounterTypes = Types

export default Creators

export const INITIAL_STATE = Immutable({
	selectedSite: {},
	siteData: {
		totalCostumers: 0,
		maxCostumers: 0,
		currentCostumers: 0
	}
})

export const siteExists = (sites, siteId) =>
  sites.filter(site => site.siteId === siteId).length

/* ------------- Reducers ------------- */

export const addSite = (state, { siteId, level, siteName }) => state.updateIn(['selectedSite'], () => ({ siteId, level, siteName }))

export const getDataSite = (state) => state

export const responseDataSite = (state) => state

export const getTotalCountRequest = (state) => state

export const updateLimit = (state) => state

export const changeCurrentCount = (state) => state

export const getTotalCountResponse = (state, { data }) => state.merge({ siteData: data })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_SITE]: addSite,
  [Types.REQUEST_COUNTER_INFO]: getDataSite,
  [Types.RESPONSE_COUNTER_INFO]: responseDataSite,
  [Types.GET_TOTAL_COUNT_REQUEST]: getTotalCountRequest,
  [Types.GET_TOTAL_COUNT_RESPONSE]: getTotalCountResponse,
  [Types.UPDATE_LIMIT]: updateLimit,
  [Types.CHANGE_CURRENT_COUNT]: changeCurrentCount
})
