import { connect } from 'react-redux'

import NewReportForm from './NewReportForm'
import NewReportFormSelector from './NewReportForm.selector'
import PreferencesEmailActions from 'Redux/PreferencesRedux'
import ModalActions from 'Redux/ModalRedux'
import { translate } from 'react-translate'

const mapDispatchToProps = dispatch => ({
  closeNewReport: () => dispatch(PreferencesEmailActions.preferencesEmailNewReportClose()),
  openModal: (modal, modalProps) => dispatch(ModalActions.modalOpen(modal, modalProps)),
  saveReport: (form) => dispatch(PreferencesEmailActions.preferencesEmailRequest(form)),
  editReport: (form) => dispatch(PreferencesEmailActions.preferencesEmailEditRequest(form))
})

export default translate('PreferencesEmail')(
  connect(
    NewReportFormSelector,
    mapDispatchToProps
  )(NewReportForm))
