import React from 'react'
import { translate } from 'react-translate'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import BaseUserForm from './user-form'
import UserManagerActions from 'Redux/UserManagerRedux'
import { RolesActions } from 'Store/roles/actions'
import SiteManagerActions from 'Redux/SiteManagerRedux'
import ModalActions from 'Redux/ModalRedux'
import { UserFormSelector } from 'Selectors/UserSelector'
import { getSitesRequest } from 'features/site-management/store'

const mapDispatchToProps = dispatch => ({
  createUser: (userData) => dispatch(UserManagerActions.userManagerCreateRequest(userData)),
  updateUser: (data) => dispatch(UserManagerActions.userManagerUpdateRequest(data)),
  getRoles: () => dispatch(RolesActions.rolesRequest()),
  getUsers: () => dispatch(UserManagerActions.userManagerRequest(false)),
  setCurrentUser: (id) => dispatch(UserManagerActions.userManagerSetCurrent(id)),
  getRootSites: () => dispatch(SiteManagerActions.siteManagerRootSitesRequest()),
  getRegistrationRole: () => dispatch(UserManagerActions.userManagerRegistrationRoleRequest()),
  closeModal: () => dispatch(ModalActions.modalClose()),
  getUiRoles: () => dispatch(UserManagerActions.userManagerRolesUiRequest()),
  getSiteHierarchy: () => dispatch(getSitesRequest())
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const initialValues = ownProps.edit ? stateProps.initialValues : {}
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    initialValues
  }
}

const UserForm = pipe(
  translate('UserForm'),
  reduxForm({
    form: 'userForm',
    enableReinitialize: true
  }),
  connect(
    UserFormSelector,
    mapDispatchToProps,
    mergeProps
  )
)(BaseUserForm)

export const UserFormEdit = () => (
  <UserForm edit />
)

UserFormEdit.propTypes = BaseUserForm.propTypes
UserFormEdit.defaultProps = BaseUserForm.defaultProps

export default UserForm
