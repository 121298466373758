import get from 'lodash/get'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { MIME_TYPE } from '../UploadAssetModal/upload-asset-modal.constants'
import {
  FILE_TYPES,
  FONT_ASSETS,
  FONT_ID,
  FONT_ID_A_LOWERS_RANGE,
  FONT_ID_A_UPPERS_RANGE,
  FONT_ID_SHORT_RANGE,
  GENERAL_ASSETS,
  IMAGES_ASSETS } from './schedule-updates.constants'

export const updateList = createSelector(
  ({ updates }) => updates.getIn(['updates', 'entities', 'distribution'], {}),
  distribution => {
    return Object.keys(distribution).map(d => distribution[d])
  }
)

export const printersDriverRange = fileType => {
  switch (fileType) {
    case MIME_TYPE[12]:
      return [
        ...FONT_ID_SHORT_RANGE,
        ...FONT_ID_A_LOWERS_RANGE,
        ...FONT_ID_A_UPPERS_RANGE
      ]
    case MIME_TYPE[20]:
      return [
        { value: '0', text: 'Default' }
      ]
    default:
      return FONT_ID
  }
}

export const devices = createSelector(
  ({ updates }) => updates.getIn(['devices'], []),
  ({ devices }) => devices.getIn(['devices'], {}),
  (devicesId, devices) => {
    return devicesId.map(device => {
      const d = devices[device]
      return {
        DeviceSerialNumber: d.serialNumber,
        SystemGuid: d.systemGuid || '',
        OperationType: '',
        CurrentVersion: d.firmwareVersion,
        deviceConfiguration: d.deviceConfiguration,
        firmwareVersion: d.firmwareVersion
      }
    })
  }
)

export const assetCurrentVersion = (d, f) => {
  switch (f.assetCategory) {
    case 'OS Distribution':
      return get(d, 'deviceConfiguration.platform.operatingSystem', '')
    case 'Enabling Solutions For Mobility Edge':
      return get(d, 'deviceConfiguration.softwareDetails.softwareName', '')
    case 'Apps':
      return get(d, 'deviceConfiguration.appsDetails[0]', '')
    default:
      return d.firmwareVersion || ''
  }
}

export const deviceTypeReducer = createSelector(
  ({ devices }) => devices.getIn(['currentDevice'], ''),
  ({ devices }) => devices.getIn(['devices'], {}),
  (deviceId, devices) => {
    return devices.getIn([deviceId, 'deviceType'], '')
  }
)

export const updatesFilterdList = createSelector(
  updateList,
  ({ updates }) => updates.getIn(['printerTab'], GENERAL_ASSETS),
  (list, id) => {
    return list.reduce((assetsEntities, asset) => {
      switch (asset.fileType) {
        case FILE_TYPES[FONT_ASSETS]:
          assetsEntities[FONT_ASSETS] = [
            ...assetsEntities[FONT_ASSETS],
            asset
          ]
          break
        case FILE_TYPES[IMAGES_ASSETS]:
          assetsEntities[IMAGES_ASSETS] = [
            ...assetsEntities[IMAGES_ASSETS],
            asset
          ]
          break
        default:
          assetsEntities[GENERAL_ASSETS] = [
            ...assetsEntities[GENERAL_ASSETS],
            asset
          ]
          break
      }
      return assetsEntities
    }, {
      [GENERAL_ASSETS]: [],
      [FONT_ASSETS]: [],
      [IMAGES_ASSETS]: []
    })
  }
)

export const updatesFontsList = createSelector(
  updatesFilterdList,
  ({ updates }) => updates.getIn(['selectedUpdates'], []),
  (list, selectedList) => {
    return list[FONT_ASSETS].map(asset => {
      return {
        ...asset,
        selected: selectedList.includes(asset.id),
        fontsDrive: printersDriverRange(asset.type),
        type: asset.type
      }
    })
  }
)

export const updatesGeneralList = createSelector(
  updatesFilterdList,
  ({ updates }) => updates.getIn(['selectedUpdates'], []),
  (list, selectedList) => {
    return list[GENERAL_ASSETS].map(asset => {
      return {
        ...asset,
        selected: selectedList.includes(asset.id)
      }
    })
  }
)

export const updatesImagesList = createSelector(
  updatesFilterdList,
  ({ updates }) => updates.getIn(['selectedUpdates'], []),
  (list, selectedList) => {
    return list[IMAGES_ASSETS].map(asset => {
      return {
        ...asset,
        selected: selectedList.includes(asset.id)
      }
    })
  }
)

export const scheduledTime = createSelector(
  state => getFormValues('scheduleUpdatesModalForm')(state),
  form => {
    return get(form, 'ScheduleTime.DateTime', {})
  }
)

export const scheduleDetails = createSelector(
  state => getFormValues('scheduleUpdatesModalForm')(state),
  form => {
    return {
      startTime: get(form, 'ScheduleTime.FinalDateTime', {}),
      endTime: get(form, 'ScheduleTime.FinalEndTime', {}),
      timeZone: get(form, 'ScheduleTime.TimeZone', {}),
      cronExpression: get(form, 'ScheduleTime.CronExpression', {})
    }
  }
)

export const onSchedule = createSelector(
  state => getFormValues('scheduleUpdatesModalForm')(state),
  form => {
    return get(form, 'ScheduleTime.OnSchedule')
  }
)

export const isValidTime = createSelector(
  state => getFormValues('scheduleUpdatesModalForm')(state),
  form => {
    return get(form, 'ScheduleTime.IsValid')
  }
)
export const updatePreferences = createSelector(
  getFormValues('scheduleUpdatesModalForm'),
  form => {
    return get(form, 'UpdatePreferences', {})
  }
)

export default state => {
  return {
    assetsObj: updatesFilterdList(state),
    devices: devices(state),
    error: state.firmware.error,
    deviceType: deviceTypeReducer(state),
    tab: state.updates.getIn(['printerTab'], 0),
    loading: state.updates.loading,
    schedule: scheduledTime(state),
    onSchedule: onSchedule(state),
    isValidTime: isValidTime(state),
    scheduleDetails: scheduleDetails(state),
    updatePreferences: updatePreferences(state),
  }
}
