import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  showCreateAccessPointModal: null,
  showEditAccessPointModal: ['accessPoint', 'isEdit', 'permissions'],
  createAccessPointRequest: ['siteId', 'accessPoint', 'callback'],
  createAccessPointSuccess: null,
  createAccessPointFailure: ['error'],
  editAccessPointRequest: ['id', 'accessPoint', 'callback'],
  editAccessPointSuccess: null,
  editAccessPointFailure: ['error'],
  closeModal: null,
  updateSite: ['site'],
  getAccessPointDataRequest: ['siteId', 'params'],
  getAccessPointDataResponse: ['data'],
  updateAccessRanges: ['params']
}, {
  prefix: '@ACCESS_POINTS/'
})

export const AccessPointsTypes = Types
export const AccessPointsActions = Creators
