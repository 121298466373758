export const DEVICE_TYPE = [{
  text: 'Printer',
  value: 'printer'
}, {
  text: 'Mobile Computers',
  value: 'mobilecomputer'
}, {
  text: 'Scanners',
  value: 'scanner'
}, {
  text: 'Gateway',
  value: 'gateway'
}
]

export const ASSET_TYPES = {
  mobilecomputer: [
    {
      text: 'Provisioner Configuration File',
      value: 1
    }, {
      text: 'Device Configuration File',
      value: 2
    }, {
      text: 'Operating System (Full Update)',
      value: 3
    }, {
      text: 'SSClient Application (apk file)',
      value: 5
    },
    {
      text: 'Operating System (Incremental Update)',
      value: 4
    }, {
      text: 'Application (apk file)',
      value: 11
    }
  ],
  printer: [
    {
      text: 'Device Configuration File',
      value: 6
    }, {
      text: 'Font File - Bitmap',
      value: 7
    }, {
      text: 'Font File - TTF',
      value: 12
    }, {
      text: 'Font File - Open type',
      value: 20
    }, {
      text: 'Image File - custom',
      value: 13
    }, {
      text: 'Image File - F7B',
      value: 14
    }, {
      text: 'Image File - PCX',
      value: 15
    }, {
      text: 'Image File - BMP',
      value: 16
    }, {
      text: 'Image File - PNG',
      value: 17
    }, {
      text: 'Image File - GIF',
      value: 18
    }, {
      text: 'Image File - LOGO',
      value: 19
    }, {
      text: 'Certificate File',
      value: 9
    }, {
      text: 'Firmware File',
      value: 22
    }
  ],
  scanner: [
    {
      text: 'Device Configuration File',
      value: 10
    },
    {
      text: 'Certificate File',
      value: 9
    },
    {
      text: 'Plugin File',
      value: 21
    }, {
      text: 'Firmware File',
      value: 22
    }
  ],
  robot: [
    {
      text: 'ML Model',
      value: 23
    }
  ]
}

export const MIME_TYPE = {
  1: 'DEVCONFIG-text/xml-hon-provisioner-tasks',
  2: 'DEVCONFIG-text/xml-hon-device-config',
  3: 'FOTA-application/x-hon-ota-full',
  4: 'FOTA-application/x-hon-ota-incr',
  5: 'APPOTA-application/x-hon-apk-ssclient',
  6: 'DEVCONFIG-text/x-hon-device-config',
  7: 'DEVCONFIG-font/x-bitmap',
  8: 'DEVCONFIG-image/ief',
  9: 'DEVCONFIG-application/x-hon-cert-wifi',
  10: 'DEVCONFIG-text/x-hon-device-config',
  11: 'APPOTA-application/x-hon-apk',
  12: 'DEVCONFIG-font/ttf',
  13: 'DEVCONFIG-image/img',
  14: 'DEVCONFIG-image/x-hon-f7b',
  15: 'DEVCONFIG-image/x-pcx',
  16: 'DEVCONFIG-image/bmp',
  17: 'DEVCONFIG-image/png',
  18: 'DEVCONFIG-image/gif',
  19: 'DEVCONFIG-image/x-hon-logo',
  20: 'DEVCONFIG-font/x-opentype',
  21: 'APPOTA-application/x-hon-plugin',
  22: 'FOTA-application/x-hon-ota-full',
  23: 'DEVCONFIG-application/x-hon-igs-aimodel'
}

export const APP_MODULE = {
  1: 'DEVCONFIG',
  2: 'DEVCONFIG',
  3: 'FOTA',
  4: 'FOTA',
  5: 'APPOTA',
  6: 'DEVCONFIG',
  7: 'DEVCONFIG',
  8: 'DEVCONFIG',
  9: 'DEVCONFIG',
  10: 'DEVCONFIG',
  11: 'APPOTA',
  21: 'DEVCONFIG'
}

export const FILE_TYPES = {
  FOTA: 'FOTA',
  APPOTA: 'APPOTA',
  DEVCONFIG: 'DEVCONFIG'
}

export const FILE_NAME = {
  FOTA: 'Device software package',
  APPOTA: 'Application package'
}

export const ACCEPTED_TYPES = [
  '.smoc',
  '.moc',
  '.mocf',
  '.pfx',
  '.cer',
  '.exm',
  '.bin',
  '.xml',
  '.ttf',
  '.bmp',
  '.img',
  '.pcx',
  '.cfg',
  '.apk',
  '.zip',
  '.pdf',
  '.ini',
  '.lbx',
  '.exe',
  '.prg',
  '.bin.sign',
  '.pcx',
  '.dpl',
  '.txt'
]
