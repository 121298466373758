import { connect } from 'react-redux'
import { pipe } from 'ramda'
import ExpirationModalHandler from './expiration-modal-handler'
import ExpirationModalHandlerSelector from './expiration-modal-handler.selector'
import ModalActions from 'Redux/ModalRedux'
import LoginActions from 'Redux/LoginRedux'
import { withRouter } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const mapDispatchToProps = dispatch => ({
  openExpirationModal: () => dispatch(ModalActions.modalOpen('sessionExpiration')),
  renewSession: () => dispatch(LoginActions.loginRefreshTokenRequestAuto())
})

export default pipe(
  connect(
    ExpirationModalHandlerSelector,
    mapDispatchToProps
  ),
  withLDConsumer(),
  withRouter)
  (ExpirationModalHandler)