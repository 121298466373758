import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import mapStateToProps from './tags.selector'
import Tags from './tags.component'
import { TaggingActionCreators } from 'Store/tagging'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

export const mapDispatchToProps = dispatch => {
  return {
    createTag: tag => dispatch(TaggingActionCreators.devicesCreateTagRequest(tag))
  }
}

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('Tags'),
  withLDConsumer()
)(Tags)
