export const currentLocation = ({ locations }) => {
  if (!locations.currentLocation) {
    return ''
  }

  const { locationType, locationGuid } = locations.getIn(['entities', ...locations.currentLocation], {})
  return {
    locationType,
    locationGuid
  }
}
