import { put, call, select, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { CheckInOutActionTypes, CheckInOutActionCreators } from './actions'
import { devicesSchema } from './entity'
import DeviceActions from '../../Redux/DeviceRedux'
import { TypeApi } from '../../Services'

const getDevicesInformation = devices => state => {
  return devices.map(device => {
    const info = state.devices.getIn(['devices', device], {})

    return {
      name: info.deviceName,
      id: info.serialNumber,
      type: info.deviceType,
      photo: info.photoUrl
    }
  })
}

export function * checkinQueueAddDeviceRequest ({ devices }) {
  const selector = getDevicesInformation(devices)
  const devicesInformartion = yield select(selector)
  const { entities, result } = normalize(devicesInformartion, [devicesSchema])
  yield put(CheckInOutActionCreators.checkinQueueAddDeviceSuccess(entities.devices, result))
}

export function * checkInOutSaga (apiService) {
  yield all([
    // takeLatest(CheckInOutActionTypes.CHECKIN_QUEUE_ADD_DEVICE_REQUEST, checkinQueueAddDeviceRequest)
  ])
}


