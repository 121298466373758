import { createSelector, createStructuredSelector } from 'reselect'

export const submitAction = ({ modal }) => modal.getIn(['modalProps', 'submitAction'], () => {})
export const onCancel = ({ modal }) => modal.getIn(['modalProps', 'onCancel'], () => {})
export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  submitAction,
  onCancel,
  theme
})
