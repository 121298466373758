import { createSelector, createStructuredSelector } from 'reselect'
import mapValues from 'lodash/mapValues'

export const buildingsByIdReducer = ({ locations }) => locations.getIn(['result'], []).map(l => ({id: l, schema: 'site'}))
export const buildingsReducer = ({ locations }) => locations.entities.site
export const zonesReducer = ({ locations }) => locations.entities.zone
export const subzonesReducer = ({ locations }) => locations.entities.subzone

export const mergeNodes = createSelector(
  buildingsReducer,
  zonesReducer,
  subzonesReducer,
  (buildings, zones, subzones) => {
    return {
      ...mapValues(buildings, x => ({
        text: x.locationName,
        value: x.value,
        selectorRoute: x.selectorRoute,
        isRoot: true
      })),
      ...mapValues(zones, x => ({
        text: x.locationName,
        value: x.value,
        selectorRoute: x.selectorRoute,
        isRoot: false
      })),
      ...mapValues(subzones, x => ({
        text: x.locationName,
        value: x.value,
        selectorRoute: x.selectorRoute,
        isRoot: false
      }))
    }
  }
)

export default createStructuredSelector({
  nodes: buildingsByIdReducer,
  sites: state => state.siteManager.sitesById
})
