import { createStructuredSelector, createSelector } from 'reselect'
import { orderBy, get, find  } from 'lodash'

export const scannerFirmwareCard = state => state.dashboard.scannerFirmwareCard

export const firmwareVersion = createSelector(
  [scannerFirmwareCard],
  card => card.getIn(['firmwareAssetsId'], [])
  .map(firmwareVersion => firmwareVersion.getIn(['firmware'], ''))
)

export const details = createSelector(
  [scannerFirmwareCard],
  scannerFirmwareCard => scannerFirmwareCard.getIn(['firmwareAssetsId'], [])
  .map(details => details.getIn(['details'], []))
)


export const sites = ({ siteManager }) => siteManager.getIn(['rootSitesById'], [])

export const firmwareValue = createSelector(
  [scannerFirmwareCard],
  card => card.getIn(['totals'], [])
)

export const firmwarePtc = createSelector(
  [scannerFirmwareCard],
  card => card.getIn(['percentages'], [])
)

export const data = createSelector(
  firmwareVersion,
  firmwareValue,
  firmwarePtc,
  details,
  sites,
  (firmwareVersion, firmwareValue, firmwarePtc, details, sites) => {
    const detailsWithSites = details.map(detail => detail
      .map(value => ({
        tagIds: value.assetId,
        serialNumber: value.serialNumber,
        orgUnitKey: value.orgUnitKey,
        site: get(find(sites, { value: value.orgUnitKey }), 'text', ''),
      })))

    return orderBy(firmwareVersion.map((value, index) => {
      return {
        key: value || 'Unknown',
        value: firmwareValue[index],
        percentage: firmwarePtc[index],
        details: detailsWithSites[index]
      }
    }), ['value'], ['desc'])
  }
)

export default createStructuredSelector({
  data
})
