const nullDefaultValue = (value, defaultValue) => {
  if (value === null || value === 'Unavailable') {
    return defaultValue
  } else {
    return value
  }
}

const isValidJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const notAvailableFilterCards = (data = [], notAvailable='') => {
  return data.filter(item => item.content != notAvailable)
}

const dataFinalProperties = (dataProperties=[], notAvailable='') => {
  let finalData = []
  dataProperties.map((property) => {
    notAvailableFilterCards(property,notAvailable).length ===  0 ? null : finalData.push(notAvailableFilterCards(property))})
    return finalData
}

export {
  nullDefaultValue,
  isValidJsonString,
  notAvailableFilterCards,
  dataFinalProperties
}
