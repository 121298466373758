import { createSelector } from 'reselect'
import { CustomAccordion } from './dashboard-preferences'
import ConfigureCard from '../ConfigureCard'
import { GetCardTypeByKey, getCardsCondition } from './dashboard-preferences.constants'
import { toPairs, assoc } from 'ramda'
import get from 'lodash/get'

export const dashboardPreferences = ({ userSettings }) => userSettings.dashboardPreferences

export const filterCardsWithLuanchDarkley = (flags, cardKey) =>
  get(flags, get(getCardsCondition, `${cardKey}.condition`))

export const calculateYPosition = (array, index, flags) =>
  array
    .slice(0, index)
    .reduce((height, [key, obj]) => obj.acordionIsActive
      ? height + (3 + (obj.cards.filter((card) => filterCardsWithLuanchDarkley(flags, card)).length - 1) * 2)
      : height + 1,
    0)

const CreateDictionary = (keys, comp) =>
  keys
    .reduce((obj, key) => assoc(key, { component: comp }, obj), {})

const getSectionVisibility = (layout) =>
  layout.filter((element) => element.isVisible).length > 0

export const layout = ({ flags }) => createSelector(
  [dashboardPreferences],
  dashboardPreferences =>
    toPairs(dashboardPreferences.getIn(['sections'], {}))
      .filter(([key, obj]) => obj.sectionKey === 'ltl' ? flags.logisticsAndTransportation : true)
      .map(([key, obj], index, array) => {
        return ({
          i: obj.sectionKey,
          x: 0,
          y: index > 0 ? calculateYPosition(array, index, flags) : 0,
          w: 12,
          h: obj.acordionIsActive
            ? obj.cards.length > 0
              ? 3 + (obj.cards.filter((card) => filterCardsWithLuanchDarkley(flags, card)).length - 1) * 2
              : 3
            : 1,
          minH: 1,
          static: true,
          preventCollision: true,
          explicitHeight: true,
          widgetId: obj.sectionKey,
          props: {
            layout: obj.cards.map(val => dashboardPreferences.getIn(['cards', `${val}`], {}))
              .filter((card) => filterCardsWithLuanchDarkley(flags, card.key))
              .map((obj) => {
                return ({
                  i: obj.key,
                  x: 0,
                  y: obj.position * 2,
                  w: 12,
                  h: 2,
                  minH: 2,
                  isResizable: false,
                  widgetId: obj.key,
                  props: {
                    checked: obj.isVisible,
                    type: GetCardTypeByKey(obj.key),
                    title: obj.key,
                    description: obj.key
                  }
                })
              }),
            secondLayout: obj.cards.map(val => dashboardPreferences.getIn(['cards', `${val}`], {}))
              .filter((card) => filterCardsWithLuanchDarkley(flags, card.key))
              .map((obj) => {
                return ({
                  i: obj.key,
                  x: 0,
                  y: obj.position * 2,
                  w: 12,
                  h: 2,
                  static: false,
                  widgetId: obj.key,
                  props: {
                    checked: obj.isVisible,
                    type: GetCardTypeByKey(obj.key),
                    title: obj.key,
                    description: obj.key
                  }
                })
              }),
            dictionary: CreateDictionary(obj.cards, ConfigureCard),
            title: obj.title,
            sectionKey: obj.sectionKey,
            acordionIsActive: obj.acordionIsActive,
            isSectionVisible: getSectionVisibility(obj.cards.map(val => dashboardPreferences.getIn(['cards', `${val}`], {})))
          }
        })
      })
)

export const dictionary = createSelector(
  [dashboardPreferences],
  dashboardPreferences =>
    CreateDictionary(Object.keys(dashboardPreferences.getIn(['sections'], {})), CustomAccordion)
)

export const submitAction = ({ modal }) => modal.getIn(['modalProps', 'submitAction'], () => {})

export const assignFlag = ({ modal }) => modal.getIn(['modalProps', 'assignFlag'], true)

export const roleId = ({ modal }) => modal.getIn(['modalProps', 'roleId'], () => {})

export const isMyDashboard = ({ modal }) => modal.getIn(['modalProps', 'isMyDashboard'], false)

export const selectorDashboardTitle = createSelector(
  [dashboardPreferences],
  dashboardPreferences => dashboardPreferences.getIn(['dashboardTitle'], '')
)
export const theme = ({ user }) => user.getIn(['theme'], {})

export default (state, props) => {
  return {
    layout: layout(props)(state),
    dictionary: dictionary(state),
    submitAction: submitAction(state),
    roleId: roleId(state),
    assignFlag: assignFlag(state),
    selectorDashboardTitle: selectorDashboardTitle(state),
    isMyDashboard: isMyDashboard(state),
    theme: theme(state)
  }
}