import * as React from 'react'
import Immutable from 'seamless-immutable'
import { useTranslate } from 'react-translate'
import { Icon } from '@scuf/common'
import { DataTable } from '@scuf/datatable'
import { NewEmployee } from '../../apis'
import { ErrorsCell } from './errors-cell'
import { ErrorMessage } from 'Services/api-response'
import { DeleteButton } from './employees-import-table.styles'
import { ValidationStatus } from './validation-status'

const nameof = <T, > (name: keyof T) => name

export interface EmployeesImportTableRow extends NewEmployee {
  errors?: readonly ErrorMessage[]
}

export interface EmployeesImportTableProps {
  data: Immutable.ImmutableArray<EmployeesImportTableRow>;
  validationStatus: ValidationStatus;
  submitting: boolean;
  onChange: (data: Immutable.ImmutableArray<NewEmployee>) => any;
}

export const EmployeesImportTable: React.FC<EmployeesImportTableProps> = ({
  data,
  validationStatus,
  submitting,
  onChange,
  isUsers = false
}) => {
  const translateForm = useTranslate('AddEmployeesForm')
  const translateEmployee = useTranslate('Employee')
  const translateUserForm = useTranslate('UserForm')
  const translateRole = useTranslate('Role')  

  const onEdit = React.useCallback((newValue, cellData) => {
    const newData = Immutable(data)
    // @ts-ignore
    newData.updateIn([cellData.rowIndex, cellData.field as keyof EmployeesImportTableRow], newValue)

    onChange(newData)
  }, [data, onChange])

  const onRemoveRow = React.useCallback((cellData) => {
    const newData = Immutable([
      ...data.asMutable().slice(0, cellData.rowIndex),
      ...data.asMutable().slice(cellData.rowIndex + 1, data.length)
    ])
    onChange(newData)
  }, [data, onChange])

  return (
    <DataTable
      className='employees-import-table'
      data={data as unknown as object[]}
      totalRows={data.length}
      scrollable={true}
      scrollHeight='45vh'
      onEdit={onEdit}
    >
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('firstName')}
        header={translateEmployee('FirstName')}
      />
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('middleName')}
        header={translateEmployee('MiddleNameOptional')}
      />
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('lastName')}
        header={translateEmployee('LastName')}
      />
       {isUsers && (        
        <DataTable.Column
        field={nameof<EmployeesImportTableRow>('email')}
        header={translateUserForm('UserForm_Email')}
      /> )}
      {!isUsers && ( 
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('employeeId')}
        header={translateEmployee('EmployeeId')}
      /> )}
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('siteId')}
        header={translateForm('Site')}
      />
       {isUsers && (        
        <DataTable.Column
        field={nameof<EmployeesImportTableRow>('roleId')}
        header={translateRole('Role')}
      /> )}
            
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('errors')}
        header={translateForm('Status')}
        renderer={(cellData) => (<ErrorsCell cellData={cellData} validationStatus={validationStatus} />)}
      />
      <DataTable.Column
        field={nameof<EmployeesImportTableRow>('errors')}
        header=''
        initialWidth='4rem'
        renderer={(cellData) => (
          <DeleteButton
            title={translateForm('DeleteRow')}
            type='link'
            onClick={() => onRemoveRow(cellData)}
            disabled={submitting}
          >
            <Icon name='delete' size='small' color='red' />
          </DeleteButton>
        )}
      />
    </DataTable>
  )
}
