import styled from 'styled-components/macro'
import { Select, Icon, Progress } from '@scuf/common'

const Container = styled.div`
display: flex;
flex:1;
flex-direction: column;
marginTop: 0px;
`
const Col = styled.div`
  display: flex;
flex: ${props => props.flex ? props.flex : 1};
flex-direction: column;
align-self: flex-start;
margin: 0 20px 0 0px;
`
const PaddedContainer = styled.section`
@media (max-height: 950px){
  overflow-y: auto;
  height: calc(80vh - 9.5rem);
}
`
const Row = styled.div`
  display: flex;
flex: ${props => props.flex ? props.flex : 1};
flex-direction: row;
justify-content: ${ ({ justifyContent }) => justifyContent || ''}
`
const AddIcon = styled(Icon)`
cursor: pointer;
`

const BoldError = styled.b`
color: #be271c;
`
const ProvisionProgressBar = styled(Progress)`
visibility:${props => props.isvisible ? '' : 'hidden'};
width: 100%;
max-height: 0.5rem;
padding-left: 0.7rem;
padding-right: 1.3rem;
`
const LineProgressBar = styled(Progress)`
.ui.progress:last-child{
  margin: 0px !important
}
`
const InputLabelHelper = styled.p`
font-size: 0.8rem;
color: #a0a0a0;
margin: 2px !important;
`

const StyledSelect = styled(Select)`
&.ui.scuf-dropdown-wrapper.fluid{
  z-index:99;
}
`
const TableTitle = styled.span`
  font-weight: 700;
  margin: 30px 0px 20px 0px;
`
StyledSelect.displayName = 'StyledSelect'

 const StyledSelectDropdown = styled(Select)`
  width: auto !important;
  max-width: 50%;
  margin: 30px 0px 25px 0px;
`
 const PageSubTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  margin: 20px 0px;
`

 const HeaderWrap = styled.div`
  width:100%;
`
 const StyledH2 = styled.div`
  color: #404040;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
`
 const ParagraphModal = styled.div`
  margin-top:1.8vh;
  font-size: .875rem;
  color: #404040;
  line-height: 22px;
`

export {
  Container,
  Col,
  Row,
  PaddedContainer,
  AddIcon,
  BoldError,
  ProvisionProgressBar,
  LineProgressBar,
  StyledSelect,
  InputLabelHelper,
  TableTitle,
  StyledSelectDropdown,
  PageSubTitle,
  HeaderWrap,
  StyledH2,
  ParagraphModal
}
