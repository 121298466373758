import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../AssetManagementTable'
import { ModalWrap, ModalTitle } from './new-batteries-list-modal.styles'
import { Link } from 'react-router-dom'
import { DateFormatter } from 'Utils/DateHelper'


export const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'currentDevice', translation: 'AssetManagementTable_lastKnowDevice', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_lastKnowDeviceAlias', render: null },
  { field: 'batterySite', translation: 'AssetManagementTable_deviceSite', render: null },
  { field: 'lastUpdated', translation: 'AssetManagementTable_lastReportedTimestamp', render: ({ value }) => <DateFormatter date={value} />},

]

const NewBatteriesListModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('NewBatteries_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName={t('NewBatteries_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default NewBatteriesListModal
