export const ASSET_MANAGEMENT_TOAST_CONTAINER = 'ASSET_MANAGEMENT_TOAST_CONTAINER'

export const tableFiltersSimple = [
  {
    path: 'connectionStatus',
    label: 'Connection Status',
    type: 'radio'
  },
  {
    path: 'displayModel',
    label: 'Model',
    type: 'checkbox'
  }
]

export const tableFiltersAll = [
  ...tableFiltersSimple,
  {
    path: 'deviceTypeFormatted',
    label: 'Device type',
    type: 'radio'
  }
]

export const getDefaultHiddenColumns = () => {
  const batteryLevel = 'Battery Level'
  const lastUser = 'Last User'
  const scanVolume = 'Scan Volume'
  const labelsPrinted = 'Labels Printed'
  const odometer = 'Odometer'
  const notes = 'Notes'
  const checkOutTime = 'Timestamp'
  const checkInTime = 'CheckInTime'
  const status = 'Asset Status'
  const imei = 'IMEI'
  const cellPhoneNumber = 'Phone Number'
  const agent = 'Agent'
  const mbootVersion = 'MBoot Version'
  const avrVersion = 'AVR Version'
  const mlbPartNumber = 'MLB Part Number'
  const telemetrySummary = 'IMSI'
  const msisdn = 'MSISDN'
  const networkType = 'Network Type'
  const carrier = 'Carrier'
  const deviceAwake = "Stay Awake"
  const manufacturer = "Manufacturer"
  return {
    'robot': [batteryLevel, lastUser, scanVolume, odometer, labelsPrinted, notes, checkOutTime, checkInTime, status],
    'other': [batteryLevel, lastUser, scanVolume, odometer, labelsPrinted, notes, checkOutTime, checkInTime, status, manufacturer],
    'mobilecomputer': [batteryLevel, lastUser, notes, checkOutTime, checkInTime, status, imei, cellPhoneNumber, agent, telemetrySummary, msisdn, networkType, carrier, deviceAwake, manufacturer],
    'scanner': [batteryLevel, scanVolume, notes, checkOutTime, checkInTime, status, manufacturer],
    'printer': [labelsPrinted, odometer, notes, checkOutTime, checkInTime, status, mbootVersion, avrVersion, mlbPartNumber, manufacturer],
    'all': [batteryLevel, lastUser, scanVolume, labelsPrinted, odometer, notes, checkOutTime, checkInTime, status, manufacturer],
    'rfidreader': [batteryLevel, scanVolume, notes, checkOutTime, checkInTime, status, odometer, labelsPrinted, status]
  }
}

export const getPwaHiddenColumns = () => {
  const all = ['Scan Volume',
    'Labels Printed',
    'Odometer',
    'Battery Level',
    'Last User',
    'Firmware',
    'Hierarchy',
    'Last Online',
    'State',
    'Device Name']

  const mobilecomputer = [
    'Battery Level',
    'Last User',
    'Zone',
    'Floor',
    'Building',
    'Hierarchy',
    'State',
    'Last Online',
    'Device Name',
    'IMEI',
    'Phone Number',
    'Agent',
    'IMSI',
    'MSISDN',
    'Network Type',
    'Carrier',
    'Stay Awake'
  ]
  return {
    mobilecomputer,
    'scanner': all,
    'printer': all,
    all
  }
}

export const translateHiddenColumns = (t, columns) => {
  return columns.map((column) => t(column))
}
