import { select, call, put, all } from 'redux-saga/effects'
import PreferencesActions from '../Redux/PreferencesRedux'
import React from 'react'
import { FREQUENCIES, DAYS } from 'Components/Preferences/components/preferences-email/components/modal-form/AutomatedReportModal.helpers'
import { SuccessToast, ErrorToast } from '../Themes/ScufStyledComponents'
import { toast } from 'react-toastify'
import { isoDateToms } from '../Utils/MomentHelpers'
import { get, orderBy } from 'lodash'
import moment from 'moment'
import { reset } from 'redux-form'

const mapEmailPreferencesToApiModel = form => {
  const properties = {
    name: form.confName,
    type: form.confReceiveFrequency,
    notificationHour: form.confReceiveDaysTime,
    startDate: { seconds: isoDateToms(form.confReceiveDaysStart) / 1000 },
    userId: form.userId,
    orgUnitIds: form.confSites,
    timeZone: form.confTimeZone,
    frequency: 1,
    id: form.id
  }
  if (properties.type === FREQUENCIES.WEEKLY) {
    properties.daysOfWeek = [ form.confWeekDaySelector === DAYS.SUNDAY ? 0 : form.confWeekDaySelector ]
  }
  if (properties.type === FREQUENCIES.MONTHLY) {
    properties.dayOfMonth = form.confMonthDaySelector
  }
  return properties
}

const mapApiResponseToModel = form => {
  return form.map(preference => ({
    ...preference,
    startDate: { seconds: moment(preference.startDate).valueOf() / 1000 },
    daysOfWeek: preference.daysOfWeek.map(day => day === 0 ? DAYS.SUNDAY : day)
  }))
}

export function * saveEmailPreference (api, { form }) {
  try {
    const response = yield call(api.saveEmailPreferences, form)

    if (response.ok) {
      yield all([
        put(PreferencesActions.preferencesEmailRequestSuccess()),
        put(reset('preferencesEmailForm')),
        call(toast, <SuccessToast message='Configuration saved' />),
        put(PreferencesActions.preferencesEmailGetRequest())
      ])
    } else {
      yield all([
        put(PreferencesActions.preferencesEmailRequestFailure('error')),
        call(toast, <ErrorToast> An error ocurred savind the report configuration  </ErrorToast>)
      ])
    }
  } catch (e) {
    yield all([
      put(PreferencesActions.preferencesEmailRequestFailure(e)),
      call(toast, <ErrorToast> An error ocurred savind the report configuration  </ErrorToast>)
    ])
  }
}

export function * editEmailPreferences(api, { form }) {
  try {
    const response = yield call(api.editEmailPreferences, form)
    if (response.ok) {
      yield all([
        put(PreferencesActions.preferencesEmailEditRequestSuccess([])),
        call(toast, <SuccessToast message='Configuration saved' />),
        put(PreferencesActions.preferencesEmailGetRequest())
      ])
    } else {
      yield all([
        put(PreferencesActions.preferencesEmailEditRequestFailure('error')),
        call(toast, <ErrorToast> An error ocurred savind the report configuration  </ErrorToast>)
      ])
    }
  } catch (e) {
    yield all([
      put(PreferencesActions.preferencesEmailEditRequestFailure(e)),
      call(toast, <ErrorToast> An error ocurred savind the report configuration  </ErrorToast>)
    ])
  }
}

export function * deleteEmailPreferences (api, { settingsId }) {
  try {
    const response = yield call(api.deleteEmailPreferences, settingsId)
    if (response.ok) {
      yield all([
        put(PreferencesActions.preferencesEmailDeleteRequestSuccess(settingsId)),
        call(toast, <SuccessToast message='Email preferences deleted' />)
      ])
    } else {
      yield all([
        put(PreferencesActions.preferencesEmailDeleteRequestFailure(settingsId)),
        call(toast, <ErrorToast  message='An error ocurred deleting the email preferences' />)
      ])
    }
  } catch (e) {
    yield all([
      put(PreferencesActions.preferencesEmailRequestFailure(settingsId)),
      call(toast, <ErrorToast  message='An error ocurred deleting the email preferences' />)
    ])
  }
}

export function * getEmailPreferences (api) {
  try {
    const siteId = yield select(({ user }) => user.getIn(['selectedOrganizationId'], ''))
    const response = yield call(api.getEmailPreferences, siteId)
    if (response.ok) {
      const data = mapApiResponseToModel(get(response, ['data', 'preferences'], []))
      yield put(PreferencesActions.preferencesEmailGetRequestSuccess( data || []))
    } else {
      yield put(PreferencesActions.preferencesEmailGetRequestFailure())
    }
  } catch (e) {
    yield put(PreferencesActions.preferencesEmailGetRequestFailure())
  }
}

export function * getPreferencesAlertsData (api, { organizationId }) {
  try {
    const response = yield call(api.getInstantAlertsPreferences, organizationId)
    if (response.ok) {
      const sortedAlerts = orderBy(get(response, ['data', 'settings'], []), ['id'], ['asc'])
      const data = {
        enabled: response.data.enabled,
        alerts: sortedAlerts,
        sites: response.data.listSites
      }
      yield put(PreferencesActions.preferencesGenericSuccess(data))
    } else {
      yield put(PreferencesActions.preferencesGenericError())
      call(toast, <ErrorToast message='Error loading configuration' />)
    }
  } catch (e) {
    yield put(PreferencesActions.preferencesGenericError())
    call(toast, <ErrorToast message='Error loading configuration' />)
  }
}

export function * putAlertValueRequest (api, { alertsList }) {
  try {
    const response = yield call(api.saveMultipleInstantAlertPreferences, alertsList)
    if (response.ok) {
      yield put(PreferencesActions.preferencesChangeAlertValueResponse(response.data))
    } else {
      yield put(PreferencesActions.preferencesGenericError())
      call(toast, <ErrorToast message='Error saving configuration' />)
    }
  } catch (e) {
    yield put(PreferencesActions.preferencesGenericError())
    call(toast, <ErrorToast message='Error saving configuration' />)
  }
}
