import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  changeCollapsedState: ['section', 'accordionState'],
  changeSelectedDashboardIndex: ['value']
})

export const FilterTypes = Types
export default Creators

export const DEFAULT_STATE = {
  alerts: {
    uncollapsed: true
  },
  deviceUsage: {
    uncollapsed: true
  },
  batteryHealth: {
    uncollapsed: true
  },
  mobileComputers: {
    uncollapsed: true
  },
  printers: {
    uncollapsed: true
  },
  scanning: {
    uncollapsed: true
  }
}

export const INITIAL_STATE = Immutable({
  sections: DEFAULT_STATE,
  dashboard: {
    selectedDashboardIndex: ''
  }
})

/* ------------- Reducers ------------- */
export const dashboardSectionClick = (state, { section, accordionState }) =>
  state.setIn(['sections', section, 'uncollapsed'], accordionState)

export const changeSelectedDashboardIndex = (state, { value }) =>
  state.setIn(['dashboard', 'selectedDashboardIndex'], value)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_COLLAPSED_STATE]: dashboardSectionClick,
  [Types.CHANGE_SELECTED_DASHBOARD_INDEX]: changeSelectedDashboardIndex
})
