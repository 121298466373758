import { call, put, all, select } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'
import mapValues from 'lodash/mapValues'
import React from 'react'
import { toast } from 'react-toastify'
import { reset } from 'redux-form'
import get from 'lodash/get'

import { SuccessToast, ErrorToast } from '../Themes/ScufStyledComponents'

import LocationActions from '../Redux/LocationRedux'

export const LOCATION_TOAST_CONTAINER = 'LOCATION_TOAST_CONTAINER'

const devicesEntity = new schema.Entity('device', {}, {
  idAttribute: e => e.deviceId
})

const zone = new schema.Entity('zone', {}, {
  idAttribute: e => e.zoneIdentifier.zoneGuid,
  processStrategy: (e, p) => ({
    ...e,
    ...e.zoneIdentifier,
    hierarchy: e.zoneHierarchy,
    selectorRoute: ['zone', e.zoneIdentifier.zoneGuid],
    locationGuid: e.zoneIdentifier.zoneGuid,
    locationType: 'zone',
    locationName: e.zoneIdentifier.zoneName,
    label: e.zoneIdentifier.zoneName,
    value: e.zoneIdentifier.zoneGuid,
    zone: [],
    referenceUnit: p.referenceUnit
  })
})

const subzone = new schema.Entity('subzone', {}, {
  idAttribute: e => e.zoneIdentifier.zoneGuid,
  processStrategy: (e, p) => ({
    ...e,
    ...e.zoneIdentifier,
    hierarchy: e.zoneHierarchy,
    selectorRoute: ['subzone', e.zoneIdentifier.zoneGuid],
    locationGuid: e.zoneIdentifier.zoneGuid,
    locationType: 'subzone',
    locationName: e.zoneIdentifier.zoneName,
    label: e.zoneIdentifier.zoneName,
    value: e.zoneIdentifier.zoneGuid,
    referenceUnit: p.referenceUnit
  })
})

const myArray = new schema.Array({
  zone: zone,
  subzone: subzone
}, e => e.zoneIdentifier.zoneType ? e.zoneIdentifier.zoneType.toLowerCase() : '')

const sites = new schema.Entity('site', { zone: myArray }, {
  idAttribute: e => e.siteIdentifier.siteGuid,
  processStrategy: e => ({
    ...e,
    ...e.siteIdentifier,
    hierarchy: e.siteIdentifier.siteName,
    selectorRoute: ['site', e.siteIdentifier.siteGuid],
    locationGuid: e.siteIdentifier.siteGuid,
    locationName: e.siteIdentifier.siteName,
    locationType: 'Building',
    value: e.siteIdentifier.siteGuid,
    label: e.siteIdentifier.siteName
  })
})

export function * getLocations (api, { site }) {
  const response = yield call(api.getLocations, site.guid)
  if (response.ok) {
    const data = normalize(response.data, [sites])
    yield put(LocationActions.locationSuccess(data))
  } else {
    yield all([
      put(LocationActions.locationFailure()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function buildingMapFields ({ address, referenceUnit, ...rest }) {
  const buildingPost = {
    ...rest,
    contactInformation: address === null
    ? undefined
    : {
      addresses: [address]
    },
    referenceUnit: referenceUnit.organizationalUnitGuid.map(o => ({
      organizationalUnitGuid: o
    }))
  }
  return buildingPost
}

export function * createBuilding (api, { building }) {
  try {
    const buildingPost = buildingMapFields(building)
    const response = yield call(api.createBuilding, buildingPost)
    if (response.ok) {
      const [head] = response.data.resourceId
      yield all([
        put(LocationActions.locationSuccessCreateBuilding()),
        call(toast, <SuccessToast />, { containerId: LOCATION_TOAST_CONTAINER }),
        put(LocationActions.locationRequest({guid: '', name: ''})),
        put(LocationActions.locationExpand(['site', head]))
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateBuilding()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateBuilding()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * updateBuilding (api, { building }) {
  try {
    const buildingPost = buildingMapFields(building)
    const response = yield call(api.updateBuilding, buildingPost)
    if (response.ok) {
      const [head] = response.data.resourceId
      yield all([
        put(LocationActions.locationSuccessCreateBuilding()),
        call(toast, <SuccessToast />, { containerId: LOCATION_TOAST_CONTAINER }),
        put(LocationActions.locationRequest({guid: '', name: ''}))
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateBuilding()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateBuilding()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export const currentLocation = ({ locations }) => {
  if (!locations.currentLocation) {
    return ''
  }
  const location = locations.getIn(['entities', ...locations.currentLocation], {})

  return {
    siteId: location.getIn(['referenceUnit', 0, 'organizationalUnitGuid'], ''),
    buildingId: location.getIn(['referenceZone', 'zoneGuid'], '')
  }
}

export function * createZone (api, { zone }) {
  try {
    const { siteId, buildingId } = yield select(({ locations }) =>({
      siteId: locations.getIn(['entities', 'site', zone.referenceSite.siteGuid, 'referenceUnit', 0, 'organizationalUnitGuid'], ''),
      buildingId: zone.referenceSite.siteGuid || ''
    }))
    const response = yield call(api.createZone, siteId, buildingId, [zone])
    if (response.ok) {
      const [head] = response.data.resourceId
      yield all([
        put(LocationActions.locationSuccessCreateZone()),
        put(LocationActions.locationRequest({guid: '', name: ''})),
        put(LocationActions.locationExpand(['zone', head]))
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateZone()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateZone()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * updateZone (api, { zone }) {
  try {
    const { siteId, buildingId, ...form } = zone
    const response = yield call(api.updateZone, siteId, buildingId, form)
    if (response.ok) {
      const [head] = response.data.resourceId
      yield all([
        put(LocationActions.locationSuccessCreateZone()),
        put(LocationActions.locationRequest({guid: '', name: ''}))
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateZone()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateZone()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * createSubZone (api, { zone }) {
  try {
    const { siteId, buildingId } = yield select(currentLocation)
    const response = yield call(api.createZone, siteId, buildingId, [zone])
    if (response.ok) {
      yield all([
        put(LocationActions.locationSuccessCreateZone()),
        put(LocationActions.locationRequest({guid: '', name: ''})),
        put(reset('subZoneForm'))
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateZone()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateZone()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * updateLocation (api, data) {
  const response = yield call(api.updateZone, data)
  if (response.ok) {
    yield all([
      put(LocationActions.locationSuccessUpdateLocation()),
      call(toast, <SuccessToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  } else {
    yield all([
      put(LocationActions.locationFailure()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * getDevicesByLocation (api) {
  try {
    const { filters, siteId } = yield select(({ locations }) => ({
      filters: locations.getIn(['filters'], {}),
      siteId: locations.site
    }))
    const paramsFilter = mapValues(filters, f => f && f.length ? f.join(',') : undefined)
    const response = yield call(
      api.getDeviceLocation,
      {
        ...paramsFilter,
        siteId
      }
    )
    if (response.ok) {
      const data = get(response, 'data.deviceZoneList', response.data)
      const { outOfContactDeivces, offlineDeivces } = response.data
      yield all([
        put(LocationActions.locationSuccessDevices(
          data, {
            outOfContactDeivces,
            offlineDeivces
          })
        ),
        call(toast, <SuccessToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    } else {
      yield all([
        put(LocationActions.locationFailureCreateZone()),
        call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
      ])
    }
  } catch (_) {
    yield all([
      put(LocationActions.locationFailureCreateZone()),
      call(toast, <ErrorToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * deleteLocation (api) {
  const location = yield select(({ locations }) => locations.getIn(['deleteLocation'], {}))
  const response = yield call(api.deleteBuilding, location.siteId, location.locationGuid)
  if (response.ok) {
    yield all([
      put(LocationActions.locationRequest({guid: '', name: ''})),
      put(LocationActions.locationSuccessDelete()),
      call(toast, <SuccessToast />, { containerId: LOCATION_TOAST_CONTAINER })
    ])
  }
}

export function * getDevicesById (api, { devices }) {
  // TODO: remove hardcoded device, just for dev
  const deviceId = get(devices, 'devices', [])
    .map(d => get(d, 'deviceIdentifier.serialNumber', undefined))
    .join(',')

  const response = yield call(api.getDeviceLocation, { deviceId })
  if (response.ok) {
    const deviceList = normalize(response.data.deviceZoneList, [devicesEntity])
    yield put(LocationActions.locationSuccessDevicesById(deviceList))
  } else {
    yield put(LocationActions.locationFailureDevices())
  }
}
