import { connect } from 'react-redux'
import { translate } from 'react-translate'

import ModalTabs from './modal-tabs'
import ModalActions from '../../../Redux/ModalRedux'

const mapDispatchToProps = dispatch => ({
  openModal: (modal) => dispatch(ModalActions.modalOpen(modal))
})

export default translate('MasterModal')(connect(null, mapDispatchToProps)(ModalTabs))
