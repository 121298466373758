import styled from 'styled-components/macro'

const Containter = styled.div`
  display: flex;
  width: 100%;
  height: 57vh;
  flex-direction: column;
  align-items: space-evenly;
  align-self: center;
  justify-content: flex-start;
  padding: ${props => props.padding ? props.padding : 'rem'};
`

export {
  Containter
}
