import React, { Fragment, useState, useEffect } from 'react'

import { Modal, Button } from '@scuf/common'
import { Title, InfoText } from '../../Themes/ScufStyledComponents'
import { ButtonWrapper, LeftContainerWrapper, RightContainerWrapper, ErrorMessageWrapper } from './expiration-modal.styles'
import { defaultFlags } from 'Utils/launch-darkly-flags'

const ExpirationModal = ({
  closeModal = () => null,
  logOut = () => null,
  renewSession = () => null,
  sessionExpirationTimestamp = 0,
  isRenewSessionEnabled = defaultFlags.renewSession,
  isSessionExtendedError = false,
  renewSessionStatus = false,
  setModalProps = () => null,
  t = label => label
  }) => {

  const getInMinutes = (timestamp) => Math.floor(timestamp / 60)
  const getInSeconds = (timestamp) => Math.floor(timestamp % 60)
  const isSessionExpired = (timestamp) => (getInMinutes(timestamp) <= 0 && getInSeconds(timestamp) <= 0)

  const [timer, setTimer] = useState(sessionExpirationTimestamp - (Date.now() / 1000))

  useEffect(() => {
    const abortCtrl = new AbortController()

    setModalProps({
      closeIcon: true,
      size: isRenewSessionEnabled ? 'medium' : 'small',
      closeOnDimmerClick: false,
      className: 'modal-height-small'
    })

    const interval = setInterval(() => {
      const currentTimestamp = sessionExpirationTimestamp - (Date.now() / 1000)
      setTimer(currentTimestamp)
      if(isSessionExpired(currentTimestamp)) logOut()
    }, 1000)

    return function cleanup () {
      clearInterval(interval)
      abortCtrl.abort()
    }
  }, [])

  return (
    <Fragment>
      <Modal.Header>
        <Title>{t('Title')}</Title>
      </Modal.Header>
      <Modal.Content>
      <InfoText>
        {t('InfoLogOut', { minutesForTimeout: getInMinutes(timer), secondsForTimeout: getInSeconds(timer) })}
      </InfoText>
      {isRenewSessionEnabled && (
        <InfoText>
          {t('InfoContinueSession')}
        </InfoText>
      )}
      {isSessionExtendedError && (
        <ErrorMessageWrapper>
          <InfoText>
            {t('ErrorContinueSession')}
          </InfoText>
        </ErrorMessageWrapper>
      )}
      </Modal.Content>
      <ButtonWrapper>
        <LeftContainerWrapper>
        {isRenewSessionEnabled && (
          <Button
          actionType='button'
          content={t('ContinueSession')}
          onClick={renewSession}
          type='inline-secondary'
          disabled={renewSessionStatus}
          />
        )}
        </LeftContainerWrapper>
        <RightContainerWrapper>
          <Button
            actionType='button'
            content={t('Logout')}
            onClick={() => logOut()}
            type='inline-secondary'
          />
          <Button
            actionType='submit'
            content={t('Continue')}
            onClick={closeModal}
            type='inline'
          />
        </RightContainerWrapper>
      </ButtonWrapper>
    </Fragment>
  )
}

export default ExpirationModal
