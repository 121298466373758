const create = (api) => {
  const getDeviceLogs = (pageNumber = 1, pageSize = 100, search='') => {
    return api.get(`/logging/devicelogs?searchValue=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
  }

  const getDeviceLogDownloadUrl = (fileId) => {
    return api.get(`/v2/files/${fileId}/url`)
  }

  return {
    getDeviceLogs,
    getDeviceLogDownloadUrl
  }
}

export default {
  create
}
