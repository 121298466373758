import { connect } from 'react-redux'
import { translate } from 'react-translate'

import Location from './location'
import mapStateToProps from './location.selector'
import LocationActions from '../../Redux/LocationRedux'
import SiteManagerActions from '../../Redux/SiteManagerRedux'

const mapDispatchToProps = dispatch => ({
  getLocations: (site = { guid: '', name: '' }) => dispatch(LocationActions.locationRequest(site)),
  getSites: () => dispatch(SiteManagerActions.siteManagerRequest())
})

export default translate('Location')(connect(
  mapStateToProps,
  mapDispatchToProps
)(Location))
