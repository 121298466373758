import moment from 'moment'

const DateLocationFormat = ({ field, rowData, t }) => {
  const timestamp = rowData[field] === '0' ? { isValid: () => false } : moment(rowData[field])
  if (timestamp.isValid()) {
    return moment(rowData[field])
      .locale(window.navigator.language)
      .format(
        t('Configurations_DateTimeFormat')
      )
  } else {
    return t('Configurations_DateUnavailable')
  }
}

DateLocationFormat.defaultProps = {
  t: label => label
}

export default DateLocationFormat
