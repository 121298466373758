export const ACTIVE = 'ACTIVE'
export const LOST = 'LOST'
export const RMA = 'RMA'
export const OUT_FOR_REPAIR = 'OUT_FOR_REPAIR'
export const OUT_FOR_SERVICE = 'OUT_OF_SERVICE'
export const RETIRE = 'RETIRE'

export const StateChoices = [
  ACTIVE,
  LOST,
  RMA,
  OUT_FOR_REPAIR,
  OUT_FOR_SERVICE,
  RETIRE
]
