import { createStructuredSelector, createSelector } from 'reselect'
import Immutable from 'seamless-immutable'
import { legacyTranslate } from '../../i18n/translate.helpers'

export const current = ({ devices }) => devices.getIn(['currentDevice'], '')
export const devicesObj = ({ devices }) => devices.getIn(['devices'], {})
export const currentCulture = state => state.user.language

export const currentDevice = createSelector([current, devicesObj], (id, devs) => devs.getIn([id], Immutable({})))

export const agentVersion = createSelector([currentDevice, currentCulture],
  (currentDevice, currentCulture) => currentDevice.getIn(['deviceConfiguration', 'appsDetails', 0, 'appVersion'], legacyTranslate(`${currentCulture}.Not_available`)))

export default createStructuredSelector({
  agentVersion
})
