import { pipe, get } from 'lodash/fp'
import { createStructuredSelector } from 'reselect'

export const initialState = {
  step: 1,
  ruleDetails: {},
  ruleParameters: {},
  actionParamater: {}
}


export const ruleCreationReducer = (state = initialState, { payload, type } = {}) => {
  switch (type) {
    case initNewRule.type:
      return {
        ...state,
        step: 2,
        ruleDetails: payload
      }
    case addRuleParameters.type:
      return {
        ...state,
        step: 3,
        ruleParameters: payload
      }
    case addActionToRule.type:
      return {
        ...state,
        actionParamater: payload
      }
    case goToStep.type:
      return {
        ...state,
        step: payload
      }
    case setStepParams.type:
      return {
        ...state,
        [payload.stepName]: payload.params
      }
    case resetInitialRule.type:
      return initialState
    default:
      return state
  }
}


ruleCreationReducer.slice = 'rules/create-rule'


export const initNewRule = ({
  displayName = '',
  description = '',
  cron = '',
  isEnabled = false,
  organizationId = '',
  parameters = '',
  templateId = '',
  timeZone = 'UTC'
}) => ({
  type: initNewRule.type,
  payload:{
    displayName,
    description,
    cron: cron || null,
    isEnabled,
    organizationId,
    parameters,
    templateId,
    timeZone
  }
})



initNewRule.type = 'rules/create-rule/initNewRule'
export const addRuleParameters = (paramaters = {}) => ({ type: addRuleParameters.type, payload: paramaters })
addRuleParameters.type = 'rules/create-rule/addRuleParameters'

export const resetInitialRule = () => ({ type: resetInitialRule.type })
resetInitialRule.type = 'rules/create-rule/resetInitialRule'

export const goToStep = step => ({ type: goToStep.type, payload: step })
goToStep.type = 'rules/create-rule/goToStep'

const setStepParams = (stepName='') => params => ({ type: setStepParams.type, payload: { stepName, params } })
setStepParams.type = 'rules/create-rule/setStepParams'
export const setRuleParameters = setStepParams('ruleParameters')
export const setActionParameters = setStepParams('actionParamater')

export const addActionToRule = ({
  actionIndex = 0,
  actionTemplateId = '',
  displayName = 'some random name',
  description = 'some random descriprtion',
  parameters = {}
}) => ({
  type: addActionToRule.type,
  payload: {
    actionIndex,
    actionTemplateId,
    displayName,
    description,
    parameters
  }
})
addActionToRule.type = 'rules/create-rule/addActionToRule'

export const getCreationRuleStep = state => state[ruleCreationReducer.slice].step

export const getRuleDetails = state => state[ruleCreationReducer.slice].ruleDetails
export const getRuleParameters = state => state[ruleCreationReducer.slice].ruleParameters
export const getTemplateId = get(`${ruleCreationReducer.slice}.ruleDetails.templateId`)
export const getStringifyRuleParameters = pipe(
  getRuleParameters,
  JSON.stringify
)
export const getRuleAction = state => state[ruleCreationReducer.slice].actionParamater
export const getPayloadRuleAction = state => {
  const {
    actionTemplateId,
    displayName,
    description,
    parameters
  } = getRuleAction(state)
  return {
    actionTemplateId,
    displayName,
    description,
    parameters: JSON.stringify(parameters)
  }
}

export const getCreateRulePayload = state => {
  const details = getRuleDetails(state)
  return {
    ...details,
    "isEnabled": true,
    parameters: getStringifyRuleParameters(state),
    timeZone: 'UTC',
  }
}


export const getCreateRuleSelectors = createStructuredSelector({
  step: getCreationRuleStep,
  payload: getCreateRulePayload,
  actionPayload: getPayloadRuleAction,
  templateId: getTemplateId
})


