import { OrganizationUserActions } from 'features/user-management/users-list/organization-users-reducer'
import React from 'react'
import { toast } from 'react-toastify'
import { all, call, put, select } from 'redux-saga/effects'

import { ErrorToast } from '../../../Themes/ScufStyledComponents'

export function* getUsersList(api, { tableParams: { query, sort, directionSort, pageNumber, pageSize } }) {
  const response = yield call(api.getUsers, { query, sort, directionSort, pageNumber, pageSize })
  if (response.ok) {
    yield put(OrganizationUserActions.initialUsersSuccess(response.data?.apiClientResponse || [], response.data?.total || 0))
  } else {
    yield all([
      put(OrganizationUserActions.initialUsersFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* getUsersListNextPage(api, { tableParams: { query, sort, directionSort, pageNumber, pageSize } }) {
  const response = yield call(api.getUsers, { query, sort, directionSort, pageNumber, pageSize })
  if (response.ok) {
    yield put(OrganizationUserActions.initialUsersNextPageSuccess(response.data?.apiClientResponse || []))
  } else {
    yield all([
      put(OrganizationUserActions.initialUsersNextPageFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}
