import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Button, Modal, Icon, DatePicker, Progress, Checkbox } from '@scuf/common'
import {
  ModalWrap, ModalTitle, ModalTitleName, ModalFooter, DateContainer, TimeScheduleContainer, ButtonsContainer,
  Row, DateTimeContainer, StyledFilter, TimezoneSelect, StyledText, StyledProgress, StyledResponse, OptionsDiv, OptionsInlineDiv
} from './device-actions-modal.styles'
import PropTypes from 'prop-types'
import {
  ENTERPRISE_DATA_RESET, FULL_FACTORY_RESET, FULL_FACTORY_RESET_OPTION1, FULL_FACTORY_RESET_OPTION2,
  FULL_FACTORY_RESET_OPTION3, REBOOT, ON_DEMAND_TELEMETRY, PULL_DEVICE_LOGS, GET_LATEST_TELEMETRY, GET_LAST_HEARTBEAT,
  PRINT_TEST_PAGE, PRINT_TEST_PAGE_OPTION1, PING, INV_SCAN, PULL_CONFIG, START_APP, PkgCarML_APP, UPSIF1_APP, GET_IMAGE_BUFFER_COMMAND, REQUESTFILE_COMMAND, DEVICE_COMMAND_SUCCESSFUL, START_ADVANCED_LOGGING, STOP_ADVANCED_LOGGING
} from '../device-actions.constants'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'

const StyledButton = styled(Button)`
pointer-events: ${props => props.pointerEvent || "auto"};
`
const DeviceActionsModal = ({ serial, displayModel, deviceType, currentAction, deviceFamily, actionCommand, triggerActionCommand, triggerLogActionCommand, pullDeviceLogs, resetAction, closeModal, t, getLatestTelemetry, getLastHeartbeat, currentCulture, flags = defaultFlags }) => {
  const troubleShootValues = {
    TELEMETRY: "Telemetry",
    HEARTBEAT: "Heartbeat"
  }
  let TroubleshootOptions = false;
  let troubleShootValue

  const initDateTime = useRef(moment())

  const [waitingResponse, setWaitingResponse] = useState(false)
  const [buttonClass, setButtonClass] = useState('txtNormalColor')
  const [buttonContent, setButtonContent] = useState('Ok')
  const [pointName, setPointName] = useState('')
  const [telemetryDate, setTelemetryDate] = useState('')
  const [heartbeatDate, setHeartbeatDate] = useState('')
  const [timeZone, settimeZone] = useState(null)
  const [time, setTime] = useState(() => {
    return initDateTime.current
  })
  const [from, setFrom] = useState(undefined)
  const [fromTimeError, setFromTimeError] = useState('')
  const [printerSystemSettings, setPrinterSystemSettings] = useState(true)
  const [printerNetworkSettings, setPrinterNetworkSettings] = useState(false)
  const [printerUserInstalled, setPrinterUserInstalled] = useState(false)
  const [rfIdReaderPkgCarMLApp, setRfIdReaderPkgCarMLApp] = useState(false)
  const [rfIdReaderUPSIF1App, setRfIdReaderUPSIF1App] = useState(false)
  const timeZoneOptions = moment.tz.names().map(tz => ({ value: tz, text: tz }))

  const { handleSubmit } = useForm({})

  const isGenericLastTelemetry = flags.genericLastTelemetry
  
  useEffect(() => {
    if (buttonContent !== 'Ok' && !TroubleshootOptions) {
      setTimeout(() => {
        closeModal()
      }, 3000)
    }

    switch (deviceType) {
      case "mobilecomputer":
        setPointName('mainsysinfo.systemtime')
        break
      case "printer":
        setPointName('printer.printhead.odometer')
        break
      case "scanner":
        setPointName('device.serialnumber')
        break
      default:
        setPointName('')
        break
    }
  })

  useEffect(() => {
    if (pointName != '') {
      processTroubleShootOption(troubleShootValue)
    }
  }, [pointName])

  useEffect(() => {
    let isSelectionValid = true
    if (isStartDateTimeInTheFuture()) {
      setFromTimeError('')
    } else {
      isSelectionValid = false
      setFromTimeError(
        () => from && t('ToHourInPast')
      )
    }
  }, [timeZone, time, from])

  const processForm = () => {
    const updatedDateTime = moment.tz(time, timeZone).add(1, 'hours');
    const payload = {
      "SerialNumber": serial,
      "Timezone": timeZone,
      "ExpiryDate": updatedDateTime,
      "ActivityType": deviceType === 'mobilecomputer' ? 2 : 1
    }
    pullDeviceLogs(payload, setWaitingResponse, t, closeModal)
  }

  const processActionCommand = () => {
    const updatedDateTime = moment.tz(time, timeZone).add(1, 'hours');
    const payload = {
      "SerialNumber": serial,
      "Timezone": "UTC",
      "ExpiryDate": updatedDateTime,
      "ActivityType": 2,
      "CommandParameters": GetCommandParams()
    }
    triggerLogActionCommand(payload, setButtonContent, setButtonClass, setWaitingResponse)
  }

  const processTroubleShootOption = (option) => {
    switch (option) {
      case troubleShootValues.TELEMETRY:
        setButtonContent(t('Refresh'))
        if (isGenericLastTelemetry)
          getLatestTelemetry(serial, '', setWaitingResponse, setTelemetryDate, currentCulture, t)
        else
          getLatestTelemetry(serial, pointName, setWaitingResponse, setTelemetryDate, currentCulture, t)
        break;
      case troubleShootValues.HEARTBEAT:
        setButtonContent(t('Refresh'))
        getLastHeartbeat(serial, setWaitingResponse, setHeartbeatDate, currentCulture, t)
        break;
      default:
        break;
    }
  }

  const timeRange = useMemo(() =>
    Array.from(new Array(24), (_, index) => ({
      value: moment({ hour: index }),
      text: moment({ hour: index }).format('h:mm A')
    })), [])

  function _handleFromTimeSelection(time) {
    setFrom(time)
  }

  function handleNewTimeZone(timezone) {
    settimeZone(timezone)
  }

  function isStartDateTimeInTheFuture() {
    const now = moment()
    const startDateTime = moment.tz(time, timeZone).set({ hour: from?.hour() })
    return startDateTime.isAfter(now)
  }


  function makeSetDateHandlers(setFunction) {
    return date => {
      const dateWithTimeZone = moment.tz(date, timeZone)
      setFunction(
        currentTime => dateWithTimeZone.isValid()
          ? dateWithTimeZone.set({ hour: 0, minute: 0 })
          : currentTime
      )
    }
  }

  const _handleDateSelection = makeSetDateHandlers(setTime)

  function GetPointerEvent() {
    return (buttonContent === DEVICE_COMMAND_SUCCESSFUL) ? "none" : "auto";
  }

  const GetCommandParams = () => {
    let paramToReturn = undefined
    let paramValues = []
    let cmdParams = [];
    switch (currentAction) {
      case FULL_FACTORY_RESET:
        if (printerSystemSettings)
          paramValues.push(FULL_FACTORY_RESET_OPTION1)
        if (printerNetworkSettings)
          paramValues.push(FULL_FACTORY_RESET_OPTION2)
        if (printerUserInstalled)
          paramValues.push(FULL_FACTORY_RESET_OPTION3)

        if (paramValues.length !== 0) {
          cmdParams.push({ "Apps": paramValues })
          paramToReturn = cmdParams
        }
        break

      case PRINT_TEST_PAGE:
        paramToReturn = { 'TestLabel': PRINT_TEST_PAGE_OPTION1 }
        break
      case START_APP:
        paramValues = []
        if (rfIdReaderUPSIF1App)
          paramValues.push(UPSIF1_APP)
        if (rfIdReaderPkgCarMLApp)
          paramValues.push(PkgCarML_APP)
        if (paramValues.length !== 0) {
          paramToReturn = paramValues
        }
        break
      case GET_IMAGE_BUFFER_COMMAND:
        paramToReturn = '[{ "MimeType": "DEVCONFIG-image/png"}, { "Path": "/tmp/label.png"}]'
        break
      case PULL_CONFIG:
        if (deviceFamily == 'fiji') {
          paramToReturn = '[{ "MimeType": "DEVCONFIG-text/xml-hon-device-config"}]'
        } else {
          paramToReturn = '[{ "MimeType": "DEVCONFIG-text/x-hon-device-config"}]'
        }
        break
      default:
        break
    }

    return paramToReturn
  }

  return (
    <ModalWrap>
      <ModalTitle>
        {renderModalTitle()}
      </ModalTitle>
      <Modal.Content>
        {renderModalContent(currentAction)}

      </Modal.Content>
      {(currentAction !== PULL_DEVICE_LOGS) &&
        (currentAction === PULL_CONFIG || currentAction === GET_IMAGE_BUFFER_COMMAND) ?
        <ModalFooter>
          {!TroubleshootOptions ?
            <Button
              type='primary'
              size='medium'
              content='Cancel'
              disabled={waitingResponse}
              onClick={() => {
                closeModal()
                resetAction()
              }}
            />
            : null}
          <StyledButton pointerEvent={GetPointerEvent()}
            type='secondary'
            className={waitingResponse ? 'btnDisabledColor' : buttonClass}
            size="medium"
            content={buttonContent}
            loading={TroubleshootOptions ? false : waitingResponse}
            disabled={waitingResponse}
            onClick={() => TroubleshootOptions ? processTroubleShootOption(troubleShootValue) : processActionCommand()}
          />
        </ModalFooter> : currentAction !== PULL_DEVICE_LOGS &&
        <ModalFooter>
          {!TroubleshootOptions ?
            <Button
              type='primary'
              size='medium'
              content='Cancel'
              disabled={waitingResponse}
              onClick={() => {
                closeModal()
                resetAction()
              }}
            />
            : null}
          <StyledButton pointerEvent={GetPointerEvent()}
            type='secondary'
            className={waitingResponse ? 'btnDisabledColor' : buttonClass}
            size="medium"
            content={buttonContent}
            loading={TroubleshootOptions ? false : waitingResponse}
            disabled={waitingResponse || (!printerSystemSettings && !printerNetworkSettings && !printerUserInstalled) || ((currentAction === START_APP) ? !(rfIdReaderUPSIF1App || rfIdReaderPkgCarMLApp) : false)}
            onClick={() => TroubleshootOptions ? processTroubleShootOption(troubleShootValue) : triggerActionCommand(serial, actionCommand, GetCommandParams(), setButtonContent, setButtonClass, setWaitingResponse)}
          />
        </ModalFooter>}
    </ModalWrap>
  )

  function renderModalTitle() {
    switch (currentAction) {
      case ENTERPRISE_DATA_RESET:
        return (<><Icon color='red' name='badge-warning' size='large' /><ModalTitleName>{t('Enterprise Data Reset')}</ModalTitleName></>)
      case FULL_FACTORY_RESET:
        return (<><Icon color='red' name='badge-warning' size='large' /><ModalTitleName>{t('Full Factory Reset')}</ModalTitleName></>)
      case REBOOT:
        return (<><Icon color='yellow' name='badge-warning' size='large' /><ModalTitleName>{t('Reboot')}</ModalTitleName></>)
      case ON_DEMAND_TELEMETRY:
        return (<ModalTitleName>{t('Telemetry')}</ModalTitleName>)
      case PULL_DEVICE_LOGS:
        return (<ModalTitleName>{t('Pull Device Logs')}</ModalTitleName>)
      case GET_LATEST_TELEMETRY:
        return (<ModalTitleName>{t('Get latest telemetry time stamp')}</ModalTitleName>)
      case GET_LAST_HEARTBEAT:
        return (<ModalTitleName>{t('Get last heartbeat')}</ModalTitleName>)
      case PRINT_TEST_PAGE:
        return (<ModalTitleName>{t('PrintTestPage')}</ModalTitleName>)
      case PING:
        return (<ModalTitleName>{t('Ping')}</ModalTitleName>)
      case INV_SCAN:
        return (<ModalTitleName>{t('InvScan')}</ModalTitleName>)
      case PULL_CONFIG:
        return (<ModalTitleName>{t('Pull Config')}</ModalTitleName>)
      case START_APP:
        return (<ModalTitleName>{t('Start App')}</ModalTitleName>)
      case GET_IMAGE_BUFFER_COMMAND:
        return (<ModalTitleName>{t('GetImageBuffer')}</ModalTitleName>)
      case START_ADVANCED_LOGGING:
        return (<ModalTitleName>{t('StartAdvancedLogging')}</ModalTitleName>)
      case STOP_ADVANCED_LOGGING:
        return (<ModalTitleName>{t('StopAdvancedLogging')}</ModalTitleName>)
      default: return null
    }
  }

  function renderModalContent(currentAction) {
    switch (currentAction) {
      case ENTERPRISE_DATA_RESET:
        return (<p>{t('EnterpriseDataResetBody')}</p>)
      case FULL_FACTORY_RESET:
        return deviceType === 'printer' ? FactoryResetPrinterBody() : (<p>{t('FactoryResetBody')}</p>)
      case REBOOT:
        return deviceType === 'printer' ? (<p>{t('RebootBodyPrinter')}</p>) : (deviceType === 'rfidreader' ? <p>{t('RebootBodyRfidReader')}</p> : (<p>{t('RebootBody')}</p>))
      case ON_DEMAND_TELEMETRY:
        return (<p>{t('TelemetryBody')}</p>)
      case PULL_DEVICE_LOGS:
        return PullDeviceLogsBody()
      case PING:
        return (deviceType === 'rfidreader' ? (RFReaderPingBody()) : (<p>{t('PingBody')}</p>))
      case INV_SCAN:
        return (<p>{t('InvScanBody')}</p>)
      case GET_LATEST_TELEMETRY:
        TroubleshootOptions = true
        troubleShootValue = troubleShootValues.TELEMETRY
        return (
          <StyledText>
            {t('Latest telemetry sent from this device is')}
            {waitingResponse ?
              <StyledProgress>
                <Progress circular={true} size={'small'} />
              </StyledProgress>
              : <StyledResponse> {telemetryDate}</StyledResponse>}
          </StyledText>)
      case GET_LAST_HEARTBEAT:
        TroubleshootOptions = true
        troubleShootValue = troubleShootValues.HEARTBEAT
        return (
          <StyledText>{t('Latest heartbeat sent from this device is')}
            {waitingResponse ?
              <StyledProgress>
                <Progress circular={true} size={'small'} />
              </StyledProgress>
              : <StyledResponse> {heartbeatDate}</StyledResponse>}
          </StyledText>)
      case PRINT_TEST_PAGE:
        return (<p>{t('PrintTestPageBody')}</p>)
      case PULL_CONFIG:
        return (<p>{t('PullConfigBody')}</p>)
      case START_APP:
        return deviceType === 'rfidreader' ? StartAppRFReaderBody() : (<p>{t('StartAppBody')}</p>)
      case GET_IMAGE_BUFFER_COMMAND:
        return (GetImageBufferBody())
      case START_ADVANCED_LOGGING:
        return (<p>{t('StartAdvancedLoggingBody')}</p>)
      case STOP_ADVANCED_LOGGING:
        return (<p>{t('StopAdvancedLoggingBody')}</p>)
      default: return null
    }
  }

  function PullDeviceLogsBody() {
    return (
      <TimeScheduleContainer>
        <form onSubmit={handleSubmit(processForm)}>
          <>
            <p><b>{t('PullDeviceLogsBody')}</b></p>
            <TimezoneSelect
              label={t('SelectTimezone')}
              onChange={handleNewTimeZone}
              options={timeZoneOptions}
              placeholder={'Select time zone'}
              search
              value={timeZone}
              error={''}
              fluid
            />

            <Row style={{ justifyContent: 'space-between' }} >
              <DateContainer>
                <DatePicker
                  label={t('TryTime')}
                  name='Timestamp'
                  type='date'
                  fluid
                  disablePast
                  onChange={_handleDateSelection}
                  timezone={timeZone}
                  value={time}
                />
              </DateContainer>
              <div>
                <DateTimeContainer>
                  <StyledFilter
                    label={t('Time')}
                    indicator="required"
                    options={timeRange}
                    onChange={_handleFromTimeSelection}
                    error={fromTimeError}
                    value={from}
                    placeholder={'Select time'}
                  />
                </DateTimeContainer>
              </div>
            </Row>

            <ButtonsContainer justifyContent='flex-end'>
              <Button
                type='secondary'
                size='medium'
                content='Cancel'
                disabled={waitingResponse}
                onClick={() => {
                  closeModal()
                  resetAction()
                }}
              />
              <Button actionType='submit' content={'Save'}
                loading={waitingResponse}
                disabled={waitingResponse || !time || !timeZone || !from || !isStartDateTimeInTheFuture()}
              />
            </ButtonsContainer>
          </>
        </form>
      </TimeScheduleContainer>
    )
  }

  function FactoryResetPrinterBody() {
    return (
      <div>
        <p><b>{t('FactoryResetBodyPrinter_Title')}</b></p>
        <OptionsDiv>
          <Checkbox
            label={t('FactoryResetBodyPrinter_Option1')}
            checked={printerSystemSettings === true}
            onChange={() => setPrinterSystemSettings(!printerSystemSettings)}
          />
          <Checkbox
            label={t('FactoryResetBodyPrinter_Option2')}
            checked={printerNetworkSettings === true}
            onChange={() => setPrinterNetworkSettings(!printerNetworkSettings)} />
          <Checkbox
            label={t('FactoryResetBodyPrinter_Option3')}
            checked={printerUserInstalled === true}
            onChange={() => setPrinterUserInstalled(!printerUserInstalled)} />
        </OptionsDiv>
        <br />
        <p><b>{t('FactoryResetBodyPrinter_NoteTitle')}</b>{t('FactoryResetBodyPrinter_NoteBody')}</p>
      </div>
    )
  }
  function StartAppRFReaderBody() {
    return (
      <div>
        <p><b>{t('StartAppRFReader_Title')}</b></p>
        <OptionsInlineDiv>
          <Checkbox
            label={t('StartAppRFReader_Option1')}
            checked={rfIdReaderUPSIF1App === true}
            onChange={() => setRfIdReaderUPSIF1App(!rfIdReaderUPSIF1App)}
          />
          <Checkbox
            label={t('StartAppRFReader_Option2')}
            checked={rfIdReaderPkgCarMLApp === true}
            onChange={() => setRfIdReaderPkgCarMLApp(!rfIdReaderPkgCarMLApp)} />
        </OptionsInlineDiv>
        <br />
        <p><b>{t('StartAppRFReader_NoteTitle')}</b>{t('StartAppRFReader_NoteBody')}</p>
      </div>
    )
  }
  function RFReaderPingBody() {
    return (
      <div>
        <p>{t('PingRfidReaderBody')}</p>

        <p><b>{t('StartAppRFReader_NoteTitle')}</b>{t('PingRFReader_NoteBody')}</p>
      </div>
    )
  }

  function GetImageBufferBody() {
    return (
      <div>
        <p>{t('GetImageBufferBody')}</p>

        <p><b>{t('GeneralNoteTitle')}: </b>{t('GetImageBufferNoteBody')}</p>
      </div>
    )
  }
}

DeviceActionsModal.propTypes = {
  serial: PropTypes.string.isRequired,
  currentAction: PropTypes.string.isRequired,
  actionCommand: PropTypes.string.isRequired,
  triggerActionCommand: PropTypes.func,
  triggerLogActionCommand: PropTypes.func,
  getLatestTelemetry: PropTypes.func,
  getLastHeartbeat: PropTypes.func,
  resetAction: PropTypes.func,
  closeModal: PropTypes.func,
  t: PropTypes.func,
  pullDeviceLogs: PropTypes.func
}

DeviceActionsModal.defaultProps = {
  closeModal: () => null,
  t: () => null
}

export default DeviceActionsModal
