import { createStructuredSelector } from 'reselect'

import {
  currentDevice,
  current,
  getUpdateDevicesData,
  deviceUpdateData,
  selectedDevices,
  selectedSite
} from '../../../../Selectors/DeviceSelector'

const deviceFormSelector = createStructuredSelector({
  initialValues: currentDevice,
  id: current,
  getUpdateDevicesData,
  deviceUpdateData,
  sites: state => state.siteManager.rootSitesById,
  selectedDevices,
  selectedDevicesSite: selectedSite
})

export default deviceFormSelector
