import styled from 'styled-components/macro'
import { Icon } from '@scuf/common'

export const ToolsIcon = styled(Icon)`
  color: ${props => props.disabled ? '#A0A0A0' : 'white'};
  font-weight: 600;
  font-size: 1rem !important;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  min-width:  ${props => props.close ? '48px' : '0'};
  min-height:  ${props => props.close ? '48px' : '0'};
  display: ${props => props.close ? 'flex !important' : 'block'};
  justify-content: center;
  align-items: center;
  &:hover {
    background-color:${props => props.close ? ' #10659F' : null};
  }
`

export const ToolsItem = styled.div`
  color: ${props => props.disabled ? '#A0A0A0' : 'white'};
  display: flex;
  flex-direction: row;
  height: 3rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  align-items: center;
  min-width:48px;
  height:48px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  &:hover {
    background-color: #10659F !important;
  }
`

export const ToolsTotalCount = styled.div`
  color: ${props => props.disabled ? '#A0A0A0' : 'white'};
  font-weight: 500;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height:100%;
  padding:0 .5rem;
`
