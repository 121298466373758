import { createSelector, createStructuredSelector } from 'reselect'
import get from 'lodash/get'

export const userId = ({ user }) => user.userData.id
export const alertsData = ({ preferences }) => preferences.getIn(['alertsData'], {})

export const sites = ({ siteManager }) => siteManager.getIn(['rootSitesById'], [])
  .map(orgSites => ({ value: orgSites.value, text: orgSites.text }))

export const alerts = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['alerts'], [])
)

export const categories = createSelector(
  alerts,
  (alerts) => Array.from(new Set(alerts.map(alert => alert.deviceType)))
)

export const filteredAlerts = createSelector(
  alerts,
  (alerts) => alerts.reduce((acum, currentAlert) => {
    acum[currentAlert.deviceType] = [...get(acum, `${currentAlert.deviceType}`, []), currentAlert]
    return acum
  }, {})
)

export const isSameAsAlerts = createSelector(
  alerts,
  alerts => {
    let flag = true
    alerts.forEach(alert => {
      if (alert.enabled !== alert.notificationEmail) {
        flag = false
      }
    })
    return flag
  }
)

export const selectedSites = createSelector(
  alertsData,
  data => data.getIn(['sites'], [])
)

export const isAlertsEnabled = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['enabled'], false)
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  userId,
  sites,
  alerts,
  categories,
  filteredAlerts,
  isSameAsAlerts,
  selectedSites,
  isAlertsEnabled,
  theme
})
