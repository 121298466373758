import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { compose } from 'ramda';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { withCookiesBanner } from 'CookiesPolicyBanner';
import { PersistGate } from 'redux-persist/es/integration/react';

import Navigation from './Components/Navigation';
import Session from './Components/Session';
import Translate from './Components/Translate';
import { StyledToastContainer } from './Components/toasts';
import { ConnectedThemeProvider } from './Themes/connected-theme-provider';

import { store } from './Store';
import { persistor } from './Store/persistor';
import config from './Config/AppConfig';
import { defaultFlags } from './Utils/launch-darkly-flags';
import { ServiceWorkerProvider } from './features/service-worker';
import { useIncludeScripts } from './Hooks/includeScript.hook';

import './ScufStyledIconPatch';
import '@scuf/common/honeywell-compact-combined/theme.css';
import '@scuf/datatable/honeywell-compact-combined/theme.css';
import '@scuf/dashboard/honeywell/theme.css';
import './App.scss';
import { withGraphQLProvider } from 'Store/root-graphql';

function App() {
  document.title = config.appTitle || 'Honeywell | Operational Intelligence';

  useIncludeScripts();

  return (
    <ServiceWorkerProvider>
      <PersistGate
        loading={<h1>Loading...</h1>}
        persistor={persistor}
      >
        <ConnectedThemeProvider>
          <Fragment>
            <StyledToastContainer
              closeButton={false}
              closeOnClick={true}
              enableMultiContainer={true}
              hideProgressBar={true}
              newestOnTop={true}
            />
            <Session>
              <Translate>
                <Navigation />
              </Translate>
            </Session>
          </Fragment>
        </ConnectedThemeProvider>
      </PersistGate>
    </ServiceWorkerProvider>
  );
}

const withReduxStoreProvider = Component => props => {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
}


export default compose(
  withReduxStoreProvider,
  withCookiesBanner,
  withLDProvider({
    clientSideID: config.launchDarkly.clientId,
    context: {
      kind: 'user',
      key: config.launchDarkly.clientType,
    },
    options: {
      bootstrap: defaultFlags,
    },
  }),
  withGraphQLProvider
)(App);
