import { createStructuredSelector, createSelector } from 'reselect'
import {
  sitesById
} from '../../../../Selectors/SiteSelector'
import {
  loading,
  errors
} from '../../../../Selectors/DeviceSelector'

export const families = ({ devices }) => devices.families

const deviceTypes = createSelector([families], fams => Object.keys(fams)
  .map(family => ({ value: family, key: family, text: fams[family].label })))

export const deviceModels = createSelector([families], fams => {
  let res = []
  Object.keys(fams)
    .forEach(family =>
      fams[family].models
        .forEach(model => {
          model = model.toUpperCase()
          res = [...res, { value: model, text: model, key: model, type: family }]
        }))
  return res
})

export const sites = createSelector([sitesById], sites => sites.map(s => ({
  title: s.name,
  ocupation: s.guid
})))

export const sitesList = createSelector([sitesById], sites => sites.map(s => ({
  text: s.name,
  value: s.guid
})))

export const devicesToProvision = ({ devices }) => devices.devicesToProvision

export const deviceModelsById = createSelector([deviceModels], models => {
  return models.reduce((res, { key }) => {
    res[key] = true
    return res
  }, {})
})

export const deviceIcon = () => ({
  mobilecomputer: 'smartphone',
  printer: 'print'
})

export const deviceTypesById = createSelector([deviceTypes], ts => {
  const types = {}
  ts.forEach(t => { types[t.value] = t.text })
  return types
})

export const deviceTypesByText = createSelector([deviceTypes], ts => {
  const types = {}
  ts.forEach(t => { types[t.text] = t.value })
  return types
})

export default createStructuredSelector({
  deviceModels,
  deviceTypes,
  sites,
  sitesList,
  loading,
  errors,
  devicesToProvision,
  deviceModelsById,
  deviceTypesByText,
  deviceTypesById,
  deviceIcon
})
