import get from 'lodash/get'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { printersDriverRange } from '../ScheduleUpdateModal/schedule-updates.selector'

const selectedUpdates = ({ updates }) => updates.getIn(['selectedUpdates'], [])

const assetsCount = createSelector(
  selectedUpdates,
  assets => assets.length
)

const assetsList = createSelector(
  selectedUpdates,
  ({ updates }) => updates.getIn(['updates', 'entities', 'distribution'], {}),
  (assetsIds, assets) => assetsIds.map(a => {
    const asset = assets.getIn([a], {})
    return {
      ...asset,
      fontsDrive: printersDriverRange(asset.type)
    }
  })
)

const validateForm = createSelector(
  assetsList,
  assets => {
    return assets.reduce((list, asset) => {
      if (asset.fileType === 'font') {
        list[asset.id] = {}
      }
      return list
    }, {})
  }
)

const selectedDevices = createSelector(
  state => getFormValues('softwareUpdateWizard')(state),
  form => {
    return get(form, 'AssetSelector.Devices', [])
  }
)

const selectMode = createSelector(
  state => getFormValues('softwareUpdateWizard')(state),
  form => {
    return get(form, 'AssetSelector.selectMode', [])
  }
)

const formValues = ({ form }) => get(form, ['softwareUpdateWizard', 'values'], {})

const scheduledTime = createSelector(
  state => getFormValues('softwareUpdateWizard')(state),
  form => {
    return get(form, 'Schedule', {})
  }
)

const updatePreferences = createSelector(
  getFormValues('softwareUpdateWizard'),
  form => {
    return get(form, 'UpdatePreferences', {})
  }
)

const selectEditSwUpdateNameFlag = ({ flags }) => flags.swUpdateFromFile

export default (state, props) => {
  return {
    appLoading: state.updates.loading,
    assetsCount: assetsCount(state),
    assetsList: assetsList(state),
    initialValues: validateForm(state),
    formValues: formValues(state),
    selectedDevices: selectedDevices(state),
    schedule: scheduledTime(state),
    updatePreferences: updatePreferences(state),
    selectMode: selectMode(state),
    modalCancel: state.modal.getIn(['modalProps', 'onClose'], () => {}),
    isEditJobNameEnabled: selectEditSwUpdateNameFlag(props)
  }
}
