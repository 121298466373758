import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import LoginAuthHonSSO from './login-auth-HonSSO'
import LoginActions from 'Redux/LoginRedux'

const getLogin = state => state.login

const mapStateToProps = state => ({
  currentLogin: getLogin(state).currentLogin,
  idToken: getLogin(state).idToken, 
  headerToken: getLogin(state).headerToken,
  requireConsent: getLogin(state).requireConsent,
  consentUserName: getLogin(state).consentUserName, 
  consentOpenToken: getLogin(state).consentOpenToken
})

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(LoginActions.loginLogoutSentience()),
  setConsent: (requireConsent, consentUserName, consentOpenToken)=> dispatch(LoginActions.loginSignInConsent(requireConsent, consentUserName, consentOpenToken))
})

export default pipe(
  translate('LoginAuthHonSSO'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginAuthHonSSO)
