import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    border-bottom: 1px solid #c8c8c8;
    background-color:transparent !important;
  }

  & .p-datatable-scrollable-body {
    max-height: 54.5vh !important;
  }
  & .ui.single-input .search-icon {
    height: 2.5rem;
  }

  & .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead > tr > th, .ui.forge-datatable-wrap .p-datatable .p-datatable-tfoot > tr > td{
    @media (max-width: 768px) {
      padding: 0.937rem 0.6rem !important;
    }
  }
  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead th .p-column-title{
    @media (max-width: 768px) {
    font-size: 0.8rem;
    max-width: fit-content !important;
    }
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead th .p-sortable-column-icon{
    @media (max-width: 768px) {
      display: none !important;
      }
  }
`

const ModalTitle = styled(Modal.Header)`
  font-size: 1.75rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export { ModalWrap, ModalTitle }
