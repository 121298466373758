import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from './../../../../../Components/AssetManagementTable'
import { ModalTitle, ModalWrap } from './types-of-model-modal.styles'


export const columns = ({t})=> {
  return [
    { field: 'key', translation: 'AssetManagementTable_model', render: null },
    { field: 'value', translation: 'AssetManagementTable_modelCount', render: null },
    { field: 'percentage', translation: 'AssetManagementTable_percentage', render: ({ value }) => <p>{value} %</p> }
  ]  
}


const TypesOfModelModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('DevicesByModel_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns(t)}
        disableRowClick
        fileName={t('DevicesByModel_title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default TypesOfModelModal
