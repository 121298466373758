import { createStructuredSelector, createSelector } from "reselect";
import { orderBy, get, find } from "lodash";
import Immutable from "seamless-immutable";
import { legacyTranslate } from "../../../../../i18n/translate.helpers";

export const currentCulture = (state) => state.user.language;

export const devicesSecurityPatch = ({ dashboard }) => dashboard.getIn(["devicesSecurityPatch"], {});

export const securityPatchVersion = createSelector([devicesSecurityPatch], (devicesSecurityPatch) =>
  devicesSecurityPatch
    .getIn(["secpatchAssetsId"], [])
    .map((securityPatch) => securityPatch.getIn(["securityPatch"], ""))
);

export const details = createSelector([devicesSecurityPatch], (devicesSecurityPatch) =>
  devicesSecurityPatch.getIn(["secpatchAssetsId"], []).map((details) => details.getIn(["details"], []))
);

export const sites = ({ siteManager }) => siteManager.getIn(["rootSitesById"], []);

export const securityPatchValue = createSelector([devicesSecurityPatch], (devicesSecurityPatch) =>
  devicesSecurityPatch.getIn(["totals"], [])
);

export const total = createSelector([securityPatchValue], (devicesSecurityPatch) =>
  devicesSecurityPatch.reduce((prev, curr) => prev + curr, 0)
);

export const securityPatchPtc = createSelector([devicesSecurityPatch], (devicesSecurityPatch) =>
  devicesSecurityPatch.getIn(["percentages"], [])
);

export const data = createSelector(
  securityPatchVersion,
  securityPatchValue,
  securityPatchPtc,
  details,
  sites,
  (securityPatchVersion, securityPatchValue, securityPatchPtc, details, sites) => {
    return orderBy(
      securityPatchVersion.map((value, index) => {
        const detailsWithSites = details.map((detail) =>
          detail.map((value) => ({
            tagIds: value.assetId,
            serialNumber: value.serialNumber,
            orgUnitKey: value.orgUnitKey,
            site: get(find(sites, { value: value.orgUnitKey }), "text", ""),
          }))
        );
        return {
          key: value,
          value: securityPatchValue[index],
          percentage: securityPatchPtc[index],
          details: detailsWithSites[index],
        };
      }),
      ["key"],
      ["desc"]
    );
  }
);

export const series = createSelector([data, currentCulture], (data, currentCulture) => {
  const top = 4;
  const topSeries = data.slice(0, top).map((obj) => {
    return {
      name: `${obj.key.toUpperCase() || "unknown"} (${obj.percentage}%)`,
      y: obj.value,
    };
  });

  if (data.length > top) {
    const otherCategory = data.slice(top).reduce(
      (a, b) => {
        return {
          value: a.value + b.value,
          percentage: a.percentage + b.percentage,
        };
      },
      { value: 0, percentage: 0 }
    );
    return [
      ...topSeries,
      {
        name: `${legacyTranslate(`${currentCulture}.UpperCase_others`)} (${otherCategory.percentage}%)`,
        y: otherCategory.value,
      },
    ];
  } else {
    return topSeries;
  }
});

export const loading = createSelector([devicesSecurityPatch], (devicesSecurityPatch) =>
  devicesSecurityPatch.getIn(["percentages"], [])
);

export default createStructuredSelector({
  securityPatchVersion,
  securityPatchValue,
  securityPatchPtc,
  data,
  series,
  loading,
  total,
});
