import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, InputLabel, Modal } from '@scuf/common'

import { downloadHelper } from '../../Utils'

import Error from '../Error'
import {
  FormColumn,
  FormRow,
  Title
} from '../../Themes/ScufStyledComponents'
import { QrTerms, QrPrivacy, QrForm } from './components'
import {
  QrContainer,
  Column,
  QRImageContainer,
  QRImage,
  QrFooterContainer,
  QrSectionContainer,
  QrWrapper,
  QRsNavigationHeader,
  QRsNavigationColumnLeft,
  QRsNavigationColumnCenter,
  QRsNavigationColumnRight,
  QRsWarningDiv
} from './qr-section.styles'

const isDesk = window.screen.width > 720
const smallDesk = window.screen.width > 720 &&
  window.screen.width <= 1400 &&
  window.screen.height < 1024

class QrBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: 'all',
      imageQRIndex: 0,
      newImageUrl: undefined,
      data: undefined
    }

    this._renderQRSections = this._renderQRSections.bind(this)
    this._renderQR = this._renderQR.bind(this)

    this._downloadButtonXML = createRef()
    this._downloadButtonImage = createRef()
  }

  componentDidMount () {
    this.props.getSites()
    if (this.props.isModal) {
      this.props.setModalProps({
        closeIcon: true,
        size: 'large',
        className: `${smallDesk ? 'modal-height' : 'modal-height-large'} modal-center`,
        closeOnDimmerClick: false,
        onClose: this.props.resetQr
      })
    }
  }

  _renderQRSections () {
    const { isModal, t } = this.props
    const { show } = this.state

    switch (show) {
      case 'qr':
        return this._renderQR()
      case 'terms':
        return (
          <QrTerms
            onBackClick={() => this.setState({ show: 'all' })}
            t={t}
          />
        )
      case 'privacy':
        return (
          <QrPrivacy
            onBackClick={() => this.setState({ show: 'all' })}
            t={t}
          />
        )
      case 'all':
      default:
        return (
          <>
            <Column flex={2}>
              <QrForm
                hide={show === 'qr'}
                isModal={!!isModal}
                setShow={section => this.setState({ show: section })}
                setData={data => this.setState({ data: data })}
              />
            </Column>
            <Column flex={3}>
              {this._renderQR()}
            </Column>
          </>
        )
    }
  }

  _renderQR () {
    const { show, imageQRIndex, newImageUrl} = this.state
    const { t, isModal, generateQr, options } = this.props

    const { loading, qrValue, imageUrl, error, acceptedTerms, imageUrls } = options

    const iconSize = isDesk ? 'large' : 'medium'

    if (!(show === 'qr' || (show === 'all' && isModal))) {
      return null
    }

    const numQRCodesGenerated = imageUrls ? imageUrls.length : 0;

    const SelectQRToShow = () => {
      let newImageQRIndex = imageQRIndex;
      if (imageQRIndex + 1 < numQRCodesGenerated) {
        newImageQRIndex++;
      }
      else {
        newImageQRIndex--;
      }
      this.setState({ newImageUrl: imageUrls[newImageQRIndex]})
      this.setState({ imageQRIndex: newImageQRIndex })
    }

    return (
      <>
        <QrSectionContainer>
          <InputLabel
            htmlFor='qr-code'
            label={t('Code') + ':'}
          />
          {
            numQRCodesGenerated < 2 ? null :
            <QRsNavigationHeader>
              <QRsNavigationColumnLeft>
                {numQRCodesGenerated + " " + t('CodesCreated')}
              </QRsNavigationColumnLeft>
              <QRsNavigationColumnCenter>
                { (imageQRIndex + 1) + "/" + numQRCodesGenerated}
              </QRsNavigationColumnCenter>
              <QRsNavigationColumnRight>
                <Button type="link"
                  content={(imageQRIndex === 0) ? t('ViewNextCode') : t('ViewPreviousCode')}
                  onClick={() => SelectQRToShow()} />
              </QRsNavigationColumnRight>
            </QRsNavigationHeader>
          }
          <QRImageContainer>
            {!loading && !error ? (
              <QRImage
                alt={t('fileName')}
                id='qr-code'
                src={newImageUrl ? newImageUrl: imageUrl }
                qrValue={qrValue}
              />
            ) : (
              <Error loading={loading} textProps={{ style: { fontSize: '1.5rem' } }}>
                {t(error)}
              </Error>
            )}
          </QRImageContainer>
          {
            (qrValue && (newImageUrl ? newImageUrl: imageUrl) && !error && !loading) ?
              <QRsWarningDiv>
                <Icon className='Warning-Icon' name='badge-warning' root='global' size='large' />
                <span>{t('QRCodeExpiration')}</span>
              </QRsWarningDiv>
            :null
          }
        </QrSectionContainer>
        <QrFooterContainer>
          <FormRow>
            <FormColumn>
              <FormRow>
                <Icon
                  className={`Icon ${qrValue && !error ? '' : 'disabled'}`}
                  description={t('DownloadAsImg')}
                  name='file-download'
                  onClick={() => qrValue && (newImageUrl ? newImageUrl: imageUrl) && !error
                    ? downloadHelper(t('fileName'), (newImageUrl ? newImageUrl: imageUrl), 'url', '.png')
                    : null
                  }
                  root='common'
                  size={iconSize}
                />
                <Icon
                  className={`Icon Side-Margins ${qrValue && !error ? '' : 'disabled'}`}
                  description={t('DownloadAsXML')}
                  name='save'
                  onClick={() => qrValue && !error
                    ? downloadHelper(t('fileName'), qrValue, 'xml')
                    : null
                  }
                  root='common'
                  size={iconSize}
                />
              </FormRow>
            </FormColumn>
            {isModal
              ? <Button
                actionType='submit'
                content={t('GenerateQR')}
                disabled={!(this.state.data != undefined) || !acceptedTerms}
                icon='refresh'
                iconPosition='left'
                onClick={() => {
                  this.setState({ show: 'all' })
                  this.setState({ imageQRIndex: 0 })
                  this.setState({ newImageUrl: undefined})
                  generateQr()
                }}
                type='primary'
              />
              : <Button
                content={t('Back')}
                onClick={() => this.setState({ show: 'all' })}
                type='secondary'
              />
            }
          </FormRow>
        </QrFooterContainer>
      </>
    )
  }

  render () {
    const { hideTabs, t, isModal } = this.props
    return (
      <QrWrapper isModal={isModal}>
        <Modal.Header>
          <Title>{t('Title')}</Title>
        </Modal.Header>
        <QrContainer hideTabs={hideTabs} padding='0'>
          {this._renderQRSections()}
        </QrContainer>
      </QrWrapper>
    )
  }
}

QrBase.propTypes = {
  sites: PropTypes.array,
  getSites: PropTypes.func,
  t: PropTypes.func,
  data: PropTypes.any,
  generateQr: PropTypes.func,
  isModal: PropTypes.bool,
  hideTabs: PropTypes.bool,
  setModalProps: PropTypes.func,
  resetQr: PropTypes.func,
  options: PropTypes.shape({
    loading: PropTypes.bool,
    qrValue: PropTypes.string,
    imageUrl: PropTypes.string,
    error: PropTypes.string,
    acceptedTerms: PropTypes.bool
  })
}

QrBase.defaultProps = {
  generateQr: () => null,
  getSites: () => null,
  isModal: false,
  sites: [],
  data: undefined,
  t: () => null
}

export default QrBase
