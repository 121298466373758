import { Checkbox, Icon, Search as ScufSearch } from '@scuf/common'
import { Table } from 'react-virtualized';
import Draggable from 'react-draggable'

import get from 'lodash/get'
import styled from 'styled-components/macro'

export const StyledTable = styled(Table)`
  overflow-x: auto;
  overflow-y: hidden;
`

export const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  border-top: ${props => props.showTools ? 'solid 1px #C0C0C0' : 'none'};
  border-bottom: solid 1px #C0C0C0;
  border-right: solid 1px #C0C0C0;
  height: ${props => props.height ? props.height + 'px' : '3rem'};
  width: ${props => props.width - 1 ? props.width - 1 + 'px' : '100%'};
  padding-right: 10px;
`

export const HeaderItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  text-align:left;
  color: #606060;
  font-size:12px;
  font-weight: 600;
  padding: ${props => props.isSelectionItem ? '0rem' : '0 0 0 0.5rem'};
  height: 100%;
  border-right: solid 0px transparent;
  border-left: solid 0px transparent;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  &:hover:not(:last-of-type) {
    border-right: solid .5px #C0C0C0;
    border-left: solid .5px #C0C0C0;
  }
  &:hover:last-of-type {
    border-left: solid 1px #C0C0C0;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:focus {
    outline: none;
  }
  min-width: ${props => props.isSelectionItem ? '40px' : '68.8px'};


  @media (max-width: 780px) {
    min-width: 0rem;
  }
`

export const TableRow = styled.div`
  display: flex;
  flex: 1;
  min-height: 2rem;
  flex-direction: row;
  &:nth-child(even) {
    background-color: ${props => props.selected ? props.theme.colors.chromeActive : get(props, 'theme.colors.componentBackground', '')};
  }
  &:nth-child(odd) {
    background-color: ${props => props.selected ? props.theme.colors.chromeActive : get(props, 'theme.colors.contentBackground', '')};
  }
  &:hover {
    background-color: ${props => get(props, 'theme.colors.chromeHover', '')};
  }
  &:focus {
    outline: none;
  }
  overflow: ${props => props.isEditAsset ? 'unset !important' : 'hidden'};
`

export const RowItem = styled.div`
  text-transform: ${props => props.textTransform ? props.textTransform : 'none'};
  display: flex;
  flex: 1;
  line-height: 16px;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.isSelectionItem ? '0rem' : '0 .5rem '};
  height: 100%;
  &:focus {
    outline: none;
  }
  &:only-child {
    padding: 0;
  }
  min-width: ${props => props.isSelectionItem ? '40px' : '68.8px'};
  @media (max-width: 768px) {
    min-width: fit-content;
    max-width: 9rem;
    padding: 0 0.5rem 0 0.5rem !important;
  }
`

export const ToolsContainer = styled.div`
  display: ${props => props.showTools ? 'flex' : 'none'};
  flex-direction: row;
  height: 3rem;
  width: 100%;¡
`

export const ToolsActionBarContainer = styled(ToolsContainer)`
  color: white;
  &:* {
    font-weight: 600;
    color: white;
  }
  & div {
    background: ${({ theme }) => theme?.colors?.primary ?? '#1274b7'} !important;
  }
`

export const TableHeaderBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.inputBase} !important;
  height: 3rem !important;
  & > button {
    background-color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.inputBase} !important;
    color: ${({ isActive, theme }) => isActive ? theme.colors.textInvertedBase : theme.colors.textBase};
    margin-right: 1rem;
    font-size: 16px;
  }
`

export const FilterIconContainer = styled.div`
  display: ${({ display }) => display ? 'flex' : 'none'};
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-right: 0rem;
  @media (max-width: 530px) {
    display: none;
  }
  & div {
    background: transparent !important;
  }

`

export const FilterContainer = styled.div`
  position: absolute;
  right: 0;
  top: ${props => props.top};
  height: ${props => props.height ? props.height : 'calc(100% - 64px)'};
  max-height: 512px;
  z-index: 3;
  width: ${ props => props.widthContent ? 'fit-content' : '25rem'};
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: clamp(1rem, 0rem, 1rem);
`

export const FilterColumnContainer = styled.div`
  margin: 1rem;
`

export const FilterColumnTitle = styled.div`
  color: #303030;
  font-weight: 600;
  border-bottom: solid 1px #C0C0C0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

export const FilterIcon = styled(Icon)`
position: relative;
  font-size:18px !important;
  color: #1B1D1F;
  font-weight: 600;
  width:48px;
  height:48px;
  display: flex !important;
  justify-content: center;
  align-items:center;
`

export const FilterCountBadge = styled.span`
  background-color: #1792E5;
  color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;
`

export const SelectionItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  @media (max-width: 430px) {
    display: none;
  }
`

export const SearchWrapper = styled.div`
  padding: .25rem;
  & .below-text {
    display: none;
  }
  & .ui.search-wrap .ui.search.ui-search .ui.input{
    @media (max-width: 530px) {
      min-width: 0rem;
    }
  }
`

export const Search = styled(ScufSearch)`
  .ui.input input {
    min-width: ${props => props.size * 0.66}em;
    width: auto;
  }
`

export const RowBlockItem = styled.div`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  flex: 1 !important;
  line-height: ${props => props.isAccessPoint ? '2rem' : '1rem'};
`
export const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  & label:after  {
    font-size: 10px !important;
    font-weight:800;
  }
`
export const TableContainer = styled.div`
  height: ${props => `calc(${props.height}px - 0.95rem)` || '100%'};
  width: ${props => `${props.width}px` || '100%'};
  border: solid 1px #B0B0B0;
  & * {
    outline: 0px;
  }
  & *::-webkit-scrollbar {
    background: #A0A0A0;
    height: 9px
  }
  & ${ToolsContainer} {
    background: ${props => props.theme.background ? '#202020' : '#ffffff'};
  }
  & ${HeaderRow} {
    background: ${props => props.theme.background ? '#202020' : '#ffffff'};
  }
  & ${FilterIcon}:hover {
    background: ${props => props.theme.background ? '#404040' : '#e0e0e0'}
  }
  & ${FilterContainer} {
    background: ${props => props.theme.background ? '#404040' : '#ffffff'}
  }
`
export const ResizeHandle = styled(Draggable)`
    z-index: 2;
    cursor: col-resize;
    width: 30px;
`

export const ResizeHandleIcon = styled.div`
  cursor: col-resize;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  width: 10px;
  opacity: 0;
`
export const ResizeHandleContainer = styled.div`
  flex-shrink: 0;
  height: 100%;
  & ${ResizeHandleIcon}:hover {
    opacity: 1;
    width: 35px;
  }
`

export const HeaderLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SortIconContainer = styled.div`
  height: 100%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
`

export const SortSvg = styled.img`
  height: 1rem;
  width: 1rem;
`

export const HeaderText = styled.span`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
