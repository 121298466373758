import * as React from 'react'
import { useTranslate } from 'react-translate'
import styled from 'styled-components/macro'
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces'
import { Icon, Popup, Progress } from '@scuf/common'
import { ValidationStatus } from './validation-status'
import { NonIotImportTableRow } from './non-iot-import-table'

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  .scuf-circular-wrapper {
    align-self: center;
    margin: auto;
  }
`

const ErrorsList = styled.ul`
  width: 25rem;
`

export interface ErrorsCellProps {
  cellData: ICellData,
  validationStatus: ValidationStatus
}

export const ErrorsCell: React.FC<ErrorsCellProps> = ({
  cellData,
  validationStatus
}) => {
  const t = useTranslate('OtherEquipmentForm')

  if (validationStatus === ValidationStatus.Validating) {
    return (
      <CellContainer>
        <Progress circular={true} size='xsmall' />
      </CellContainer>
    )
  } else if (validationStatus === ValidationStatus.Failed) {
    return (
      <CellContainer>
        <Icon name='badge-info' size='small' color='red' /> {t('ErrorValidating')}
      </CellContainer>
    )
  }

  const assetWithErrors = cellData.rowData as NonIotImportTableRow | undefined
  const errors = assetWithErrors?.errors ?? []

  if (errors == null || errors.length === 0) {
    return (
      <CellContainer>
        <Icon name='check' size='small' color='green' /> {t('Valid')}
      </CellContainer>
    )
  }

  return (
    <CellContainer>
      <Popup
        element={(
          <>
            <Icon name='badge-info' size='small' color='red' /> {t('Invalid')}
          </>
        )}
        on='hover'
      >
        <ErrorsList>
          {
            errors.map((err, i) => (
              <li key={i}><b>{err.detail}</b></li>
            ))
          }
        </ErrorsList>
      </Popup>
    </CellContainer>
  )
}
