import { createStructuredSelector, createSelector } from 'reselect'
import moment from 'moment'

export const printersOutOfPaperData = ({ dashboard }) =>
  dashboard.getIn(['printersOutOfPaperCard', 'values'], [])

export const data = createSelector(
  [printersOutOfPaperData],
  (data) => data.map((row, index) => {
    const hour = moment(row.timestamp).utc().local().locale(window.navigator.language).format('HH:mm:ss')
    return {
      key: row.serialNumber,
      aliasName: row.aliasName,
      value: row.serialNumber,
      timestamp: hour,
      deviceSite: row.deviceSite
    }
  })
)

export default createStructuredSelector({
  data
})
