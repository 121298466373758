import { generatePath } from 'react-router'
import { SiteType } from './service'

export interface RouteParams {
  siteId?: string
  childType?: SiteType
}

export const ROOT_ROUTE_PATTERN = '/admin/site_management'
export const siteManagementRoute = () => ROOT_ROUTE_PATTERN

export const SITE_ROUTE_PATTERN = `${ROOT_ROUTE_PATTERN}/:siteId`
export const siteRoute = (siteId: string) =>
  generatePath(SITE_ROUTE_PATTERN, { siteId })

export const SITE_CREATE_CHILD_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/create/:childType(${Object.values(SiteType).join('|')})`
export const siteCreateChildRoute = (siteId: string, childType: SiteType) =>
  generatePath(SITE_CREATE_CHILD_ROUTE_PATTERN, { siteId, childType })

export const SITE_DETAILS_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/details`
export const siteDetailsRoute = (siteId: string) =>
  generatePath(SITE_DETAILS_ROUTE_PATTERN, { siteId })

export const SITE_DETAILS_EDIT_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/details/edit`
export const siteDetailsEditRoute = (siteId: string) =>
  generatePath(SITE_DETAILS_EDIT_ROUTE_PATTERN, { siteId })

export const SITE_PERMISSIONS_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/permissions`
export const sitePermissionsRoute = (siteId: string) =>
  generatePath(SITE_PERMISSIONS_ROUTE_PATTERN, { siteId })

export const SITE_ACCESS_POINTS_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/access_points`
export const siteAccessPointsRoute = (siteId: string) =>
  generatePath(SITE_ACCESS_POINTS_ROUTE_PATTERN, { siteId })

export const SITE_NETWORK_RANGES_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/network_ranges`
export const siteNetworkRangesRoute = (siteId: string) =>
  generatePath(SITE_NETWORK_RANGES_ROUTE_PATTERN, { siteId })

export const SITE_CHECK_OUT_IN_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/check_out_in`
export const siteCheckOutInRoute = (siteId: string) =>
  generatePath(SITE_CHECK_OUT_IN_ROUTE_PATTERN, { siteId })

export const SITE_MAPS_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/maps`
export const siteMapsRoute = (siteId: string) =>
  generatePath(SITE_MAPS_ROUTE_PATTERN, { siteId })

export const SITE_MAINTENANCE_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/maintenance`
export const siteMaintenanceRoute = (siteId: string) =>
  generatePath(SITE_MAINTENANCE_ROUTE_PATTERN, { siteId })

export const SITE_COUNTER_ROUTE_PATTERN = `${SITE_ROUTE_PATTERN}/counter`
export const siteCounterRoute = (siteId: string) =>
  generatePath(SITE_COUNTER_ROUTE_PATTERN, { siteId })
