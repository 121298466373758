
import { connect } from 'react-redux'
import AlertsActions from '../../../../Redux/AlertsRedux'
import Polling from './polling'
import { totalAlerts } from 'Components/NotificationBell/notification-bell.select'

export const mapDispatchToProps = dispatch => ({
  getAlerts: (pageNumber = 1, pageSize = 5) => dispatch(AlertsActions.unreadAlertsRequest(null, pageNumber, pageSize))
})

export const mapStateToProps = (state) => ({
  duration: 300000,
  totalUnreadAlerts: totalAlerts(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Polling)
