import moment from 'moment'
import { pipe } from 'ramda'
import { connect } from 'react-redux'

import FirmwareRedux from '../../Redux/FirmwareRedux'
import UpdatesModal from './updates-modal'
import { assetCurrentVersion, devices, updateList } from './updates-modal.selector'

const mapStateToProps = state => {
  return {
    ...updateList(state),
    devices: devices(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    firmwareRequestToUpdate: (firmware, devices) => {
      const tasks = firmware.reduce((tss, f) => {
        const t = devices.map(d => {
          const currentVersion = assetCurrentVersion(d, f)
          return {
            ...d,
            ScheduledVersion: f.version || '1.0',
            AssetType: f.assetType,
            displayName: f.displayName,
            assetCategory: f.assetCategory,
            CAIDCAssetID: f.caidcAssetId,
            currentVersion,
            CommandParameters: {
              DeviceType: f.deviceType,
              VerificationCode: f.verificationCode,
              FileSize: f.fileSize,
              FileName: f.fileName,
              FileType: f.fileType,
              ChecksumType: f.encryption
            },
            deviceConfiguration: undefined,
            firmwareVersion: undefined
          }
        })
        return [
          ...tss,
          ...t
        ]
      }, [])
      dispatch(FirmwareRedux.firmwareRequestToUpdate({
        ScheduleTime: moment().add(1, 'm'),
        DeviceList: tasks,
        RequestPriority: 'Optional'
      }))
    }
  }
}

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UpdatesModal)
