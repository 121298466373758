const create = (api) => {

  const revokeToken = () => api.post('/v2/revoketoken')

  return {
    revokeToken
  }
}

export default {
  create
}
