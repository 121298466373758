import { createSelector, createStructuredSelector } from 'reselect'
import { selectedOrgId } from 'Selectors/LoaderSelector'
import get from 'lodash/get'

export const userId = ({ user }) => user.userData.id

export const alertsData = ({ preferences }) => preferences.getIn(['alertsData'], {})

export const alerts = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['alerts'], []).filter(alert => alert.isOrgSetting !== false)
)

export const categories = createSelector(
  alerts,
  (alerts) => Array.from(new Set(alerts.map(alert => alert.deviceType)))
)

export const filteredAlerts = createSelector(
  alerts,
  (alerts) => alerts.reduce((acum, currentAlert) => {
    acum[currentAlert.deviceType] = [...get(acum, `${currentAlert.deviceType}`, []), currentAlert]
    return acum
  }, {})
)

export const isAlertsEnabled = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['enabled'], false)
)

export const selectedSites = createSelector(
  alertsData,
  data => data.getIn(['sites'], [])
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  userId,
  selectedOrgId,
  alerts,
  categories,
  filteredAlerts,
  isAlertsEnabled,
  selectedSites,
  theme
})
