import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'
import ModalActions from '../../Redux/ModalRedux'
import RemoteAccessInProgressModal from './remote-access-in-progress-modal'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('RemoteAccessInProgressModal'),
  connect(
    mapDispatchToProps
  )
)(RemoteAccessInProgressModal)
