import { createReducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import config from '../../../../Config/AppConfig'

import {
  setDirectLoginInfo,
} from './actions'

export const INITIAL_STATE = { 
  accessToken: ''
}

const setDirectLoginInfoReducer = (state = INITIAL_STATE, payload) => {
  state.accessToken = payload.jwt
}

const reducer = createReducer(INITIAL_STATE, {
  [setDirectLoginInfo]: setDirectLoginInfoReducer
})

export const directLoginreducer = persistReducer({
  key: 'directLogin',
  storage,
  debug: config.debug
}, reducer)
