import get from 'lodash/get'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { isEndTimeAfterStart } from './time-selector.helpers'
import { DateTimeContainer, Line, Row, SelectionTitle, StyledDatePicker, StyledFilter } from './time-selector.styles'

function Schedule({
  hidden,
  limitNumberOfDays,
  init,
  t,
  timeZone,
  setValidScheduleTime,
  onChange
}) {
  const timeRange = useMemo(() =>
    Array.from(new Array(24), (_, index) => ({
      value: moment({ hour: index }),
      text: moment({ hour: index }).format('h:mm A')
    })), [])
  const initDateTime = useRef(
    timeZone ? moment().tz(timeZone).startOf('day') : moment().startOf('day')
  )
  const [datetime, setDatetime] = useState(() => {
    return initDateTime.current
  })
  const [endDatetime, setEndDatetime] = useState(get(init, 'EndTime', undefined))
  const [from, setFrom] = useState(() => moment.isMoment(init?.From) ? init.From : undefined)
  const [to, setTo] = useState(() => moment.isMoment(init?.To) ? init.To : undefined)
  const [errorMsgStart] = useState('')
  const [toTimeError, setToTimeError] = useState('')
  const [fromTimeError, setFromTimeError] = useState('')
  const [errorMsgEnd, setErrorToMsgEnd] = useState('')
  const [isTimeSelectionValid, setIsTimeSelectionValid] = useState(false)

  const cronExpresion = useMemo(() => {
    if (from?.isValid() && to?.isValid()) {
      const fromHour = from.hour()
      const toHour = to.hour()
      return `0 0/3 ${fromHour}-${toHour} * * ? *`
    } else {
      return ''
    }
  }, [from, to])

  function getUpdatedTime(date, ts) {
    if ((ts && date) && (ts.isValid() && date.isValid())) {
      const dateTime = moment.tz(date, timeZone).set({ hour: ts.hour(), minute: ts.minutes(), second: 0, millisecond: 0 })
      return dateTime.format()
    } else {
      return undefined
    }
  }

  const finalDateTime = useMemo(() => getUpdatedTime(datetime, from), [datetime, from])
  const finalEndTime = useMemo(() => getUpdatedTime(endDatetime, to), [endDatetime, to])

  useEffect(() => {
    const selection = {
      OnSchedule: true,
      Recurring: true,
      DateTime: datetime,
      EndTime: endDatetime,
      TimeZone: timeZone,
      From: from,
      To: to,
      FinalDateTime: finalDateTime,
      FinalEndTime: finalEndTime,
      CronExpression: cronExpresion,
      IsValid: isTimeSelectionValid && timeZone,
      Priority: 'mandatory'
    }
    onChange(selection)
  }, [datetime, endDatetime, timeZone, from, to, isTimeSelectionValid, cronExpresion, finalDateTime, finalEndTime])


  function isStartDateTimeInTheFuture() {
    const now = moment()
    const startDateTime = moment.tz(datetime, timeZone).set({ hour: from?.hour() })
    return startDateTime.isAfter(now)
  }

  function isEndTimeSelected() {
    return !!endDatetime
  }

  function isToTimeSelected() {
    return !!to
  }

  useEffect(() => {
    let isSelectionValid = true

    if (isStartDateTimeInTheFuture()) {
      setFromTimeError('')
    } else {
      isSelectionValid = false
      setFromTimeError(
        () => from && t('ToHourInPast')
      )
    }

    if (!(isEndTimeSelected() && isToTimeSelected())) {
      isSelectionValid = false
    }
    if (limitNumberOfDays && moment.utc(endDatetime).diff(datetime, 'days') > limitNumberOfDays - 1) {
      isSelectionValid = false
      setErrorToMsgEnd(t('DateRangeCannotBeOver30Days'))
    }

    setIsTimeSelectionValid(isSelectionValid)
  }, [timeZone, endDatetime, datetime, from, to])

  useEffect(() => {
    validateToTimeSelection()
  }, [datetime, endDatetime, from, to])

  const _handleTextStartChange = (value, error) => {
    if (error || value === '') {
      setErrorToMsgEnd(t('SelectStartDate'))
    } else {
      setErrorToMsgEnd('')
    }
  }

  const _handleTextChange = (value, error) => {
    if (error || value === '') {
      setErrorToMsgEnd(t('SelectFutureDate'))
    } else {
      setErrorToMsgEnd('')
    }
  }

  function makeSetDateHandlers(setFunction) {
    return date => {
      const dateWithTimeZone = moment.tz(date, timeZone)
      setFunction(
        currentTime => dateWithTimeZone.isValid()
          ? dateWithTimeZone.set({ hour: 0, minute: 0 })
          : currentTime
      )
    }
  }

  const _handleStartDateSelection = (date) => {
    makeSetDateHandlers(setDatetime)(date)
  }

  const _handleEndDateSelection = (date) => {
    makeSetDateHandlers(setEndDatetime)(date)
  }

  function _handleFromTimeSelection(time) {
    setFrom(time)
  }

  function _handleToTimeSelection(time) {
    setTo(time)
  }

  const validateToTimeSelection = (toTime = to) => {
    if (toTime === undefined || from === undefined
      || datetime === undefined || endDatetime === undefined)
      return

    const start = { startDate: datetime, startHour: from.hour() }
    const end = { endDate: endDatetime, endHour: toTime.hour() }
    if (isEndTimeAfterStart(start, end)) {
      setToTimeError(null)
      setValidScheduleTime?.(true)
    } else {
      setToTimeError(t('SelectFutureDate'))
      setIsTimeSelectionValid(false)
      setValidScheduleTime?.(false)
    }
  }

  return (
    <Row style={{ justifyContent: 'space-between' }} hidden={hidden}>
      <div>
        <SelectionTitle>
          {t('DateRange')}
        </SelectionTitle>
        <DateTimeContainer>
          <StyledDatePicker
            value={datetime}
            disablePast={true}
            error={errorMsgStart}
            timezone={timeZone}
            onChange={_handleStartDateSelection}
            onTextChange={_handleTextStartChange}
            label={t('StartingDate')}
            highlightWeekends={true}
            type='date'
          />
          <StyledDatePicker
            value={endDatetime}
            error={errorMsgEnd}
            timezone={timeZone}
            label={t('EndBy')}
            disablePast={true}
            onChange={_handleEndDateSelection}
            onTextChange={_handleTextChange}
            highlightWeekends={true}
            type='date'
          />
        </DateTimeContainer>
      </div>
      <Line />
      <div>
        <SelectionTitle>
          {t('TimeRange')}
        </SelectionTitle>
        <DateTimeContainer>
          <StyledFilter
            label={t('From')}
            options={timeRange}
            onChange={_handleFromTimeSelection}
            error={fromTimeError}
            value={from}
          />
          <StyledFilter
            label={t('To')}
            options={timeRange}
            onChange={_handleToTimeSelection}
            error={toTimeError}
            value={to}
          />
        </DateTimeContainer>
      </div>
    </Row>
  )
}

export default Schedule
