import React, { useCallback, useState } from 'react'
import Immutable from 'seamless-immutable'
import axios from 'axios'
import { EmployeesImportTableRow } from './employees-import-table'
import { bulkCreateEmployees, bulkCreateUsers, NewEmployee, NewUser } from '../../apis'
import { ValidationStatus } from './validation-status'
import Excel, { Workbook } from 'exceljs';
import { toast } from 'react-toastify';
import { ErrorToast } from 'Themes/ScufStyledComponents';
import range from 'lodash/range'
import { SitesManagerApi } from '../../../../Services';
import { useSelector } from 'react-redux'
import { getOrgs, getOrgIds, getOrgUsers, getGenericRoles, selectedUserOrgRoles, selectedValidRoles} from 'Selectors/LoaderSelector'

import { isRequired, noSpecialCharactersForNames, isMax25, isMax64, isEmail } from 'Forms/Validators'
import { useTranslate } from 'i18n/translate.helpers'

export enum CSVHeaders {
  FirstName = 'First Name',
  MiddleName = 'Middle Name (optional)',
  LastName = 'Last Name',
  EmployeeId = 'Employee ID',
  Email = 'Login Email',
  Site = 'Site',
  SiteId = 'Site ID',
  Role = 'Role',
  RoleId = 'Role ID',
}

export const nameSeparator = ' || '

export enum SpreadsheetHeaders {
  firstName = 'First Name',
  middleName = 'Middle Name (optional)',
  lastName = 'Last Name',
  employeeID = 'Employee ID',
  email = 'Login Email',
  site = 'Site',
  role = 'Role'
}

export interface SitesWithReferenceNames {
  organizationalUnitHierarchy: string;
}

export interface RolesWithReferenceNames {
  name: string;
}

export interface OrganizationsWithRolesNames {
  name: string,
  roles: RolesWithReferenceNames[]
}

export interface OrganizationsWithReferenceNames extends RolesWithReferenceNames {
  name: string,
  roles: RolesWithReferenceNames[],
  organizationalUnits?: OrganizationsWithRolesNames[]
}

export interface CurrentUserWithReferenceNames {
  firstName: string,
  middleName?: string,
  lastName: string,
  email: string,
  organization: string,
  role: string
}

export const useDownloadImportTemplate = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const downloadImportTemplate = React.useCallback(async (roles?: RolesWithReferenceNames[]) => {
    try {
      setIsProcessing(true)
      const rootSitesResponse = await SitesManagerApi.getRootSites();
      const workbook = createExcelWorkbook(rootSitesResponse.data, roles)
      await downloadExcelWorksheet(workbook)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message='There was an error getting the file.' />)
    } finally {
      setIsProcessing(false)
    }
  }, [])

  return {
    isProcessing,
    downloadImportTemplate
  }
}

export const useDownloadUsersTemplate = () => {
  const [isDownloadingUsers, setIsDownloadingUsers] = React.useState(false)
  const currentUsersData = useSelector(getOrgUsers)
  const orgs = useSelector(getOrgs)
  const roles = useSelector(selectedUserOrgRoles)
  const validRoles = useSelector(selectedValidRoles)
  const t = useTranslate('AddEmployeesForm')
  const downloadUsersTemplate = React.useCallback(() => {
    try {
      setIsDownloadingUsers(true)
      const emailUsersData = currentUsersData.filter((d: {email: string}) => d.email != null)
      const orgUsers = emailUsersData
          .flatMap((user:{
            firstName: string,
            middleName?: string,
            lastName: string,
            email: string,
            organization: OrganizationsWithReferenceNames[],
          }) => {
            return user.organization[0]?.roles?.filter((role) => validRoles.includes(role.name))
                .map((role) => {
                  return {
                    firstName: user.firstName?.trim(),
                    lastName: user.lastName?.trim(),
                    middleName: user.middleName?.trim(),
                    email: user.email,
                    organization: user.organization[0]?.name,
                    role: role?.name
                  }
                })
            })
      const childUsers = emailUsersData
        .flatMap((user:{
          firstName: string,
          middleName?: string,
          lastName: string,
          email: string,
          organization: OrganizationsWithReferenceNames[],
        }) => {
          return user.organization[0]?.organizationalUnits?.flatMap((orgUnit) => {
            return orgUnit.roles?.filter((role) => validRoles.includes(role.name))
                .map((role) => {
                  return {
                    firstName: user.firstName?.trim(),
                    lastName: user.lastName?.trim(),
                    middleName: user.middleName?.trim(),
                    email: user.email,
                    organization: orgUnit?.name,
                    role: role?.name
                  }
                })
              })
            })
      const currentEmailUsers = orgUsers.concat(childUsers)
        .sort((a: CurrentUserWithReferenceNames, b: CurrentUserWithReferenceNames) => a.firstName.localeCompare(b.firstName))
      const workbook = createExcelWorkbook(orgs, roles, currentEmailUsers)
      downloadExcelWorksheet(workbook, true)
    }
    catch(_) {
      toast(<ErrorToast message={t('ErrorDownloadingFile')} />)
    }
    finally {
      setIsDownloadingUsers(false)
    }
  }, [])

  return {
    isDownloadingUsers,
    downloadUsersTemplate
  }
}

async function downloadExcelWorksheet (workbook: Workbook, isDownloadUsersRequest?: boolean) {
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }
   )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = isDownloadUsersRequest ? 'Operational Intelligence Current Users.xlsx' : 'Operational Intelligence Import Users.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}

function createExcelWorkbook (sites: SitesWithReferenceNames[], roles?: RolesWithReferenceNames[], currentEmailUsers?: CurrentUserWithReferenceNames[]) {
  const workbook = new Excel.Workbook()
  workbook.creator = 'Operational Intelligence'
  const userInformationSheet = workbook.addWorksheet('User Information')
  userInformationSheet.columns = [
    { header: SpreadsheetHeaders.firstName, key: 'firstName', width: 25 },
    { header: SpreadsheetHeaders.middleName, key: 'middleName', width: 25 },
    { header: SpreadsheetHeaders.lastName, key: 'lastName', width: 50 },
    roles ? { header: SpreadsheetHeaders.email, key: 'email', width: 25 } : { header: SpreadsheetHeaders.employeeID, key: 'employeeID', width: 25 },
    { header: SpreadsheetHeaders.site, key: 'site', width: 50 },
    roles ? { header: SpreadsheetHeaders.role, key: 'role', width: 50 } : {}
  ]
  
  if(currentEmailUsers != null) {
  const userRows = currentEmailUsers.map(user => {
    return {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      email: user?.email,
      site: sites
        .map(site => site?.organizationalUnitHierarchy)
        .find(site => site?.includes(user?.organization)),
      role: user?.role
    }
    })
  userInformationSheet.addRows(userRows)
  }

  userInformationSheet.state = 'visible'

  range(2, 1002).forEach(i => {
    const row = userInformationSheet.getRow(i)
    row.getCell('E').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['Sites!$A:$A']
    }
    if(roles != null) {
      row.getCell('F').dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Roles!$A:$A']
      }
      row.getCell('G').numFmt = '@'
    }
    else {
      row.getCell('F').numFmt = '@'
    }
  })
  
  const sitesSheet = workbook.addWorksheet('Sites')
  sitesSheet.getColumn(1).values = sites
    .map(m => m.organizationalUnitHierarchy);

  if(roles != null) {
    const rolesSheet = workbook.addWorksheet('Roles')  
    rolesSheet.getColumn(1).values =
      roles.map(role => role.name)
  }

  return workbook
}


export function downloadImportTemplate () {
  const headers = [CSVHeaders.FirstName, CSVHeaders.MiddleName, CSVHeaders.LastName, CSVHeaders.EmployeeId]
  const blob = new Blob([
    new Uint8Array([0xEF, 0xBB, 0xBF]), // BOM to force Excel to use UTF-8
    headers.join(',') + '\n'
  ], {
    type: 'text/csv;charset=UTF-8'
  })

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'Operational Intelligence Import Users.csv'
  anchor.click()
  window.URL.revokeObjectURL(url)
}



export const getMatchedId = (data: any, s: any) => {
 var matchedItem  = data.filter( (item: { value: { name: any } }) => {
   return item.value.name.trim() === s.trim()
   })
 return matchedItem.length ? matchedItem[0].value.id : '';
}


// get match site name
export const getMatchedsiteName = (data: any, s: any) => {
  var matchedItem  = data.filter( (item: { value: { name: any , id: any} }) => {
    return item.value.id.trim() === s.trim()
    })
  return matchedItem.length ? matchedItem[0].text : '';
 }
// get match site name

export const getMatchedRoleName =(data: any, s: any) => {
  var matchedRole = data.filter((role: { guid: string, name: string }) => {
    return role.name.trim() === s.trim()
  })
  return matchedRole.length ? matchedRole[0].guid : ''
}

export const useImportForm = () => {
  const [validationStatus, setValidationStatus] = useState(ValidationStatus.Validated)
  const [isValid, setIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isBulkProcessing, setIsBulkProcessing] = useState(false)
  const [tableData, setTableData] = useState(Immutable([] as EmployeesImportTableRow[]))
  const [cancelTokenSource, setCancelTokenSource] = useState(axios.CancelToken.source())
  const [tableDataSiteIds, setTableSiteIdData] = useState(Immutable([] as EmployeesImportTableRow[]))
  const [tableDataSiteNames, setTableSiteNameData] = useState(Immutable([] as EmployeesImportTableRow[]))
  const genericRoles = useSelector(getGenericRoles)
  const validRoles = useSelector(selectedValidRoles)
  const orgs = useSelector(getOrgIds)
  const t = useTranslate('AddEmployeesForm')
  const submitForm = useCallback(async () => {
    if (!isValid) {
      throw new Error('Form is not valid.')
    }

    setIsSubmitting(true)
    const response = await bulkCreateEmployees({
      employees: tableDataSiteIds.asMutable(),
      dryRun: false,
      IsBulk: true
    })
    setIsSubmitting(false)
    if (response.ok && response.data) {
      return response.data.data
    }

    throw new Error('An unexpected error occurred while creating employees.')
  }, [isValid, tableData])

  const submitUsersForm = useCallback(async () => {
    if(!isValid) {
      throw new Error('Form is not valid.')
    }
    setIsSubmitting(true)
    const response = await bulkCreateUsers({
      bulkOnboardUser: tableDataSiteIds.asMutable(),
      dryRun: false
    })
    setIsSubmitting(false)

    if (response.ok && response.data) {
      return response.data.data
    }

    throw new Error('An unexpected error occurred while creating users.')
  }, [isValid, tableData])

  const validateAndUpdateTable = useCallback(async (data: Immutable.ImmutableArray<NewEmployee>) => {
    if(data.length > 0) setIsBulkProcessing(true)
    const getSiteRoots = await SitesManagerApi.getRootSites();
    const getSiteRootsData = getSiteRoots.data;

    const siteIdData = getSiteRootsData.map((s: { organizationalUnitIdentifier: { organizationalUnitGuid: any }; organizationalUnitHierarchy: any }) => ({
      value: { id: s.organizationalUnitIdentifier.organizationalUnitGuid, name: s.organizationalUnitHierarchy },
      text: s.organizationalUnitHierarchy
    }))
    const returnedTarget = Object.assign([], data);

    const latestSitesData  = returnedTarget.map( (d: { organizationId: string;
      firstName: string;
      middleName?: string;
      lastName: string;
      employeeId: string;
      siteId: string;}) => {
      return {
        ...d,
        siteId : getMatchedId(siteIdData, d.siteId),
        organizationId: siteIdData[0].value.id
      }
     });
     setTableSiteIdData(Immutable(latestSitesData))


    setTableData(Immutable(returnedTarget))

    if (data.length === 0) {
      setValidationStatus(ValidationStatus.Validated)
      setIsValid(false)
      setIsBulkProcessing(false)
      return
    }

    setValidationStatus(ValidationStatus.Validating)
    cancelTokenSource.cancel()
    const newCancelTokenSource = axios.CancelToken.source()
    setCancelTokenSource(newCancelTokenSource)

    const response = await bulkCreateEmployees({
      employees: latestSitesData,
      dryRun: true,
      IsBulk: true
    }, newCancelTokenSource.token)
    if (response.ok && response.data?.data) {
      setIsBulkProcessing(false)
      setIsValid(true)
      setValidationStatus(ValidationStatus.Validated)
      // get siteName from siteId

    const reposneSitesData  = response.data.data.map( d => {
      return {
        ...d.data! ,
        siteId : getMatchedsiteName(siteIdData, d.data?.siteId)
      }
     });
     setTableSiteNameData(Immutable(reposneSitesData))


      setTableData(Immutable(reposneSitesData))
    } else if (response.problem === 'CANCEL_ERROR') {
      setIsValid(false)
      setIsBulkProcessing(false)
    } else if (response.data?.data) {
      const responseData = response.data.data
      const employeesWithErrors = data.map(
        (employee: Immutable.ImmutableObject<EmployeesImportTableRow>, idx) => employee.merge({
          errors: responseData[idx]?.errors
        }))
        const employeesWithErrorsData  = employeesWithErrors.map( data => {
          let getSiteId = getMatchedId(siteIdData, data?.siteId)
              return {
                ...data,
                siteId : getSiteId ? getMatchedsiteName(siteIdData, getSiteId) : siteIdData[0].text
              }
             });
             setTableSiteNameData(Immutable(employeesWithErrorsData))
          setTableData(employeesWithErrorsData)
      setIsValid(false)
      setValidationStatus(ValidationStatus.Validated)
      setIsBulkProcessing(false)
    } else {
      if(response.status === 403) {
        toast(<ErrorToast message={'Access denied'} />, { containerId: 'MODAL_TOAST_CONTAINER' })
      } else {
      setIsValid(false)
      setIsBulkProcessing(false)
      setValidationStatus(ValidationStatus.Failed)
      throw new Error('An error occurred while validating the employees.')
      }
    }
  }, [cancelTokenSource])

  const validateAndUpdateUsersTable = useCallback(async  (data: Immutable.ImmutableArray<NewEmployee>) => {
    try {
      setIsBulkProcessing(true)
      
      if(data.length > 1000) {
        toast(<ErrorToast message={t('UsersLimitRequest')} />, { containerId: 'MODAL_TOAST_CONTAINER' })
        setValidationStatus(ValidationStatus.Validated)
        setIsValid(false)
        setIsBulkProcessing(false)
        return
      }

      const getSiteRoots = await SitesManagerApi.getRootSites();
      const getSiteRootsData = getSiteRoots.data;

      const siteIdData = getSiteRootsData.map((s: { organizationalUnitIdentifier: { organizationalUnitGuid: any }; organizationalUnitHierarchy: any }) => ({
        value: { id: s.organizationalUnitIdentifier.organizationalUnitGuid, name: s.organizationalUnitHierarchy },
        text: s.organizationalUnitHierarchy
      }))
      const returnedTarget = Object.assign([], data);
      const latestSitesData  = returnedTarget.map( (d: { organizationId: string;
        firstName: string;
        middleName?: string;
        lastName: string;
        employeeId: string;
        email?: string;      
        roleId?: string;
        siteId: string;}) => {
        return {
          ...d,
          roleId: getMatchedRoleName(genericRoles, d.roleId),
          siteId : getMatchedId(siteIdData, d.siteId),
          organizationId: siteIdData[0].value.id
        }
      });

      setTableSiteIdData(Immutable(latestSitesData))
      setTableData(Immutable(returnedTarget))
      setIsValid(true)
      setValidationStatus(ValidationStatus.Validating)
    
    if(data.length === 0) {
      setValidationStatus(ValidationStatus.Validated)
      setIsValid(false)
      setIsBulkProcessing(false)
      return
    }

    const usersData = Object.assign([], data)
    const usersDataValidated = usersData.map(
      (d:{
        organizationId: string,
        firstName: string,
        middleName?: string,
        lastName: string,
        employeeId: string,
        email?: string,      
        roleId?: string,
        siteId: string
      }) => {
        return {
          ...d,
          firstName: d.firstName.trim(),
          lastName: d.lastName.trim(),
          middleName: d.middleName?.trim(),
          errors: validateUsers(d)
        }
    })
    
    setTableSiteNameData(Immutable(usersDataValidated))
    setTableData(Immutable(usersDataValidated))  
    setValidationStatus(ValidationStatus.Validated)
    setIsBulkProcessing(false)

  } catch(_)
  {
    setIsValid(false)
      setIsBulkProcessing(false)
      setValidationStatus(ValidationStatus.Failed)
      throw new Error('An error occurred while validating the users.')
  }
  }, [])

  function validateUsers (userData: any) {
    const errors = []
    if(isRequired(userData.firstName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "FirstNameError",
          detail: "Empty Name",
          source: "FirstName",
          code: 'NotEmptyValidator'
        }
      )
    } 

    if(isMax25(userData.firstName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "FirstNameError",
          detail: "First Name Max Length",
          source: "FirstName",
          code: 'MaximumLengthValidator'
        }
      )
    } 

    if(noSpecialCharactersForNames(userData.firstName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "FirstNameError",
          detail: "First Name Only Letters",
          source: "FirstName",
          code: 'NameFormatValidator'
        }
      )
    } 

    if(isRequired(userData.lastName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "LastNameError",
          detail: "Empty Name",
          source: "LastName",
          code: 'NotEmptyValidator'
        }
      )
    }

    if(isMax25(userData.lastName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "LastNameError",
          detail: "Last Name Max Length",
          source: "LastName",
          code: 'MaximumLengthValidator'
        }
      )
    } 

    if(noSpecialCharactersForNames(userData.lastName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "LastNameError",
          detail: "Last Name Only Letters",
          source: "LastName",
          code: 'NameFormatValidator'
        }
      )
    } 

    if(userData.middleName.lenght !== 0 && isMax25(userData.middleName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "MiddleNameError",
          detail: "Middle Name Max Length",
          source: "MiddleName",
          code: 'MaximumLengthValidator'
        }
      )
    } 

    if(userData.middleName.lenght !== 0 && noSpecialCharactersForNames(userData.middleName)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "MiddleNameError",
          detail: "Middle Name Only Letters",
          source: "MiddleName",
          code: 'NameFormatValidator'
        }
      )
    } 

    if(userData.email.length === 0) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "EmailError",
          detail: "Empty Email",
          source: "Email",
          code: 'NotEmptyValidator'
        }
      )
    }

    if(isEmail(userData.email)) { 
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "EmailError",
          detail: "Invalid Email",
          source: "Email",
          code: 'InvalidEmail'
        }
      )
    }

    if(isMax64(userData.email)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "EmailError",
          detail: "Invalid Email",
          source: "Email",
          code: 'MaximumLengthValidator'
        }
      )
    } 

    if(userData.siteId.length === 0) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "SiteIdError",
          detail: "Empty Site",
          source: "Site",
          code: 'NotEmptyValidator'
        }
      )
    }
    
    if(userData.siteId.length !== 0 &&  !orgs.includes(userData.siteId)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "SiteIdError",
          detail: "Invalid Site",
          source: "Site",
          code: 'InvalidSite'
        }
      )
    }

    if(userData.roleId.length === 0) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "RoleError",
          detail: "Empty Role",
          source: "Role",
          code: 'NotEmptyValidator'
        }
      )
    }

    if(userData.roleId.length !== 0 && !validRoles.includes(userData.roleId)) {
      setIsValid(false)
      errors?.push(
        {
          status: "400",
          title: "SiteIdError",
          detail: "Invalid Role",
          source: "Site",
          code: 'InvalidRole'
        }
      )
    }

    return errors
  }

  return {
    validationStatus,
    isValid,
    isSubmitting,
    setTableData: validateAndUpdateTable,
    setUsersTableData: validateAndUpdateUsersTable,
    tableData,
    submitForm,
    submitUsersForm,
    isBulkProcessing
  }
}