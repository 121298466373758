import React from 'react'
import {
  Checkbox,
  DatePicker,
  Radio,
  Select,
  Input
} from '@scuf/common'
import styled from 'styled-components/macro'

export const DateTimeContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
margin-top: 1rem;
`
export const Row = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`

export const ScheduleCheckbox = styled(Checkbox)`
  padding: 0.5rem 0rem;
`

export const ScheduleOption = styled(Radio)`
  padding: 0.5rem 0rem;
`

export const ScheduleTime = styled.div`
  display: flex;
  flex-direction: row;
  min-width: max-content;
`

export const TimezoneSelect = Select

export const StyledDatePicker = styled(DatePicker)`
  margin-right: 2rem !important;
`

export const StyledInput = styled(Input)`
  margin-right: 2rem !important;
`

export const StyledFilter = styled(Select)`
  margin-right: 2rem;
    .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
`

export const ErrorContainer = styled.div`
  color: #BE271C;
  margin-top: -22px;
`

export const Line = styled.div`
  border-left: 1px solid #D8D8D8;
  margin-right: 2rem !important;
`

export const SoftwareTitle = styled.div`
  font-size: 1rem;
  font-weight: bolder;
  padding-bottom: 1.25rem;
`

export const SelectionTitle = styled.div`
  font-size: 1rem;
  font-weight: bolder;
`

export function SchedulerSelector ({
  updateOnSchedule,
  setUpdateOnSchedule,
  setDefaultDates,
  t
}) {
  return (
    <Row>
      <h3>{t('AskWhenMsg')}</h3>
      <span style={{ 'margin-left': '1em' }}>
        <Radio
          checked={!updateOnSchedule}
          label={t('Immediate')}
          onChange={() => {
            setUpdateOnSchedule(false)
            setDefaultDates(true)
          }}
        />
        <Radio
          checked={updateOnSchedule}
          label={t('Schedule')}
          onChange={() => {
            setUpdateOnSchedule(true)
            setDefaultDates(false)
          }}
        />
      </span>
    </Row>
  )
}
