import { CancelToken } from 'axios'

export enum SiteType {
  ORGANIZATION = 'organization',
  SITE = 'site',
  BUILDING = 'building',
  FLOOR = 'floor',
  ZONE = 'zone',
  PACKAGE_CAR = 'packagecar'
}

export interface Address {
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

export interface CreateSite {
  orgId: string
  parentId?: string
  type: SiteType
  name: string
  address?: Address
  hierarchy?:string
}

export interface Site extends CreateSite {
  id: string
}

export interface NormalizedSite extends Site {
  children: string[]
}

export interface NormalizedSiteEntities {
  sites?: Record<string, NormalizedSite>
}

export type NormalizedSitesResult = string[]

export enum UserRole {
  ADMINISTRATOR = 'Administrator',
  INSTALLER = 'Installer',
  USER = 'User'
}

export interface SiteUser {
  id: string
  role: UserRole | string
  name: string
}

export interface GetAllResult {
  sites: Record<string, NormalizedSite>
  rootSiteIds: string[]
}

export interface GetSiteUsersResult {
  [role: string]: SiteUser[]
}

export interface ISitesService {
  getAll (cancelToken?: CancelToken): Promise<GetAllResult>
  getSiteUsers (siteId: string): Promise<GetSiteUsersResult>
  createSite (site: Site): Promise<Site>
  updateSite (site: Site): Promise<Site>
  createBuilding (building: Site): Promise<Site>
  updateBuilding (building: Site): Promise<Site>
  createPackageCar (packgeCar: Site): Promise<Site>
  createFloor (siteId: string, buildingId: string, floor: Site): Promise<Site>
  updateFloor (siteId: string, buildingId: string, floor: Site): Promise<Site>
  createZone (siteId: string, buildingId: string, floorId: string, zone: Site): Promise<Site>
  updateZone (siteId: string, buildingId: string, floorId: string, zone: Site): Promise<Site>
  deleteOrgUnit (site: Site): Promise<void>
}

export interface MockSitesService extends ISitesService {
  getAll: jest.Mock<Promise<GetAllResult>>
  getSiteUsers: jest.Mock<Promise<GetSiteUsersResult>>
  createSite: jest.Mock<Promise<Site>>
  updateSite: jest.Mock<Promise<Site>>
  createBuilding: jest.Mock<Promise<Site>>
  updateBuilding: jest.Mock<Promise<Site>>
  createPackgeCar: jest.Mock<Promise<Site>>
  updatePackgeCar: jest.Mock<Promise<Site>>  
  createFloor: jest.Mock<Promise<Site>>
  updateFloor: jest.Mock<Promise<Site>>
  createZone: jest.Mock<Promise<Site>>
  updateZone: jest.Mock<Promise<Site>>
  deleteOrgUnit: jest.Mock<Promise<void>>
}
