import { connect } from 'react-redux'
import { translate } from 'react-translate'

import EditLocation from './edit-location'
import LocationActions from '../../Redux/LocationRedux'
import mapStateToProps from '../Location/location.selector'

const mapDispatchToProps = dispatch => ({
  getLocations: (site = { guid: '', name: '' }) => dispatch(LocationActions.locationRequest(site)),
  onChange: () => null
})

export default translate('Location')(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocation))
