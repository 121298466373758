import { Footer as ScufFooter } from '@scuf/common'
import config from 'Config/AppConfig';
import { useTranslate } from 'i18n/translate.helpers';
import styled from 'styled-components'

const StyledFooter = styled(ScufFooter)`
  position: absolute;
  bottom: 0;
  font-size: 16px;
  z-index: 2;
  width: 100%;
  min-height: 2.5rem;
  justify-content: center !important;
  padding: 0rem !important;
  background-color: ${(props) => props.theme.black};
  & .footer-menu a {
    color: ${(props) => props.theme.h1color};
  }
  & .page-footer.ui.menu .link:hover {
    background: ${(props) => props.theme.inputback};
  }
  @media (max-width: 768px) {
    position: fixed;
    border-top: 1px solid #d0d0d0 !important;
  }
`;

export const Footer = ({
  date = new Date(),
  version = '',
  t = l => l
}) => {
  const params = {
    year: date.getFullYear(),
    version
  }
  return <StyledFooter copyrightText={t('Copyright © 2020 Honeywell International Inc', params)} />
}

export default ({
  isFooterDisabled = false
}) => {
  const t = useTranslate('LoginAuthHonSSO')
  const props = {
    t,
    version: config.appVersion
  }
  return !isFooterDisabled && <Footer {...props} />
}