import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledSelect
} from '../tags.styles'

const Select = ({
  value,
  options,
  onChange,
  label,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState(undefined)
  return (
    <StyledSelect
      label={label}
      onChange={newValue => {
        onChange(newValue)
        if (searchValue && newValue !== searchValue.value) {
          setSearchValue(undefined)
        }
      }}
      onSearch={search => setSearchValue({
        text: search,
        value: search
      })}
      options={searchValue
        ? [
          ...options,
          searchValue
        ]
        : options
      }
      search
      value={value}
      {...rest}
    />
  )
}

Select.defaultProps = {
  onChange: () => {},
  t: label => label
}

Select.propTypes = {
  t: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string
}

export default Select
