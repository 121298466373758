const create = (api) => {
  const sendActionCommand = (deviceId, actionCommand, commandParams) => api.post(`/devices/${deviceId}/device-rv-commands?command=${actionCommand}${commandParams ? `&commandParameters=${JSON.stringify(commandParams)}`:``}`)
  
  const sendLogActionCommand = (payload) => api.post(`/logging/requestlog`, payload)
  
  const getLatestTelemetry = (params) => api.get(`/devices/telemetryPoint`, params)

  const getLastHeartbeat = (serialNumber) => api.get(`/devices/lastheartbeat/${serialNumber}`)

  const refreshConnectionStatus = (serialNumber) => api.get(`/devices/devicestatusonline/${serialNumber}`)

  const pullDeviceLogs = (payload) => api.post(`/logging/requestlog`, payload)
  return {
    sendActionCommand,
    sendLogActionCommand,
    pullDeviceLogs,
    getLatestTelemetry,
    getLastHeartbeat,
    refreshConnectionStatus
  }
}

export default {
  create
}
