import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  logRequestActivityLog: ['filters', 'pageNumber', 'pageSize'],
  logRequestAditionalActivityLog: ['filters', 'pageNumber', 'pageSize'],
  logResponseActivityLog: ['data'],
  logResponseAditionalActivityLog: ['data'],
  logGenericResponse: [null],
  logResetState: [null]
})

export const LoggingTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  logs: [],
  total: -1,
  loading: false
})

/* ------------- Reducers ------------- */
export const logRequestActivityLog = (state) => state.merge({ logs: [], loading: true })
export const logRequestAditionalActivityLog = (state) => state.merge({ loading: true })
export const logResponseActivityLog = (state, { data }) => state.merge(data)
export const logResponseAditionalActivityLog = (state, { data }) => state.update('logs', (logs) => [...logs, ...data.logs])
export const logGenericResponse = (state) => state.merge({ loading: false })
export const logResetState = (state) => state.merge(INITIAL_STATE)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOG_REQUEST_ACTIVITY_LOG]: logRequestActivityLog,
  [Types.LOG_REQUEST_ADITIONAL_ACTIVITY_LOG]: logRequestAditionalActivityLog,
  [Types.LOG_RESPONSE_ACTIVITY_LOG]: logResponseActivityLog,
  [Types.LOG_RESPONSE_ADITIONAL_ACTIVITY_LOG]: logResponseAditionalActivityLog,
  [Types.LOG_GENERIC_RESPONSE]: logGenericResponse,
  [Types.LOG_RESET_STATE]: logResetState
})
