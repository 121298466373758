export const formatAppName = (value) => {

    if (value === "") 
		return value
	
    if(!value?.split) return ""
    const filteredNamesSegments = value.split(".")
    if (filteredNamesSegments.length > 2)
    {
		return filteredNamesSegments.slice(2).join(".")
	}
    else
    {
		return value
    }
}
