import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import config from 'Config/AppConfig'
import { isValidJsonString } from 'Utils/PropertyHelper'

const { Types, Creators } = createActions({
  loginRequest: ['user', 'password'],
  loginSignInRequest: ['idToken', 'headerToken', 'orgId', 'firstTimeLogin'],
  loginSignInSuccess: ['data'],
  loginSignInError: ['error'],
  loginSetAccessToken: ['errorAccessToken'],
  loginSignInConsent: ['requireConsent', 'consentUserName', 'consentOpenToken'],
  loginLogoutRequest: null,
  loginLogoutSuccess: null,
  loginLogoutSentience: null,
  loginReset: ['loginType'],
  loginSetCurrent: ['loginType'],
  loginSetCurrentOrganization: ['orgId', 'userName'],
  loginSetTransitionOrganization: ['orgId'],
  loginSetCurrentAdditionalParams: ['currentNonce', 'currentCustomerType',
    'logoutUrl', 'appId', 'useOpentoken', 'redirectUri'],
  loginRefreshTokenRequest: null,
  loginRefreshTokenRequestAuto: null,
  loginRefreshTokenSuccess: ['data'],
  loginRefreshTokenResetStatus: null,
  loginRefreshTokenError: ['error']
})

export const OP_INTEL_KEY = 'opintel-orgid'

export const LoginTypes = Types
export const LoginActions = Creators
export default LoginActions

const { allowGoogle, allowSaml, allowForge } = config.login
export const normalLogin = !allowGoogle && !allowSaml && !allowForge

export const INITIAL_STATE = Immutable({
  loggedIn: false,
  loading: false,
  accessToken: '',
  refreshToken: '',
  errorAccessToken: '',
  errorRefreshToken: '',
  userName: '',
  idToken: '',
  headerToken: '',
  decodedTokenPayload: null,
  sessionTimeout: (new Date()).toString(),
  firstTimeLogin: false,
  error: '',
  selectedOrgId: '',
  requireConsent: false,
  consentUserName: '',
  consentOpenToken: '',
  currentLogin: normalLogin
    ? 'forge'
    : global.localStorage.getItem('current-login'),
  currentNonce: 'aaSaaSaaSa',
  currentCustomerType: 'custom',
  logoutUrl: '',
  appId: '',
  useOpentoken: false,
  redirectUri: '',
  attestation: true
})

/* ------------- Reducers ------------- */

const signInRequest = (state, { idToken, headerToken, firstTimeLogin }) =>
  state.merge({ loading: true, idToken, headerToken, firstTimeLogin, error: '' })

const request = state => state.merge({ loading: true })

const signInSuccess = (state, { data }) => state.merge({ ...data, loading: false, loggedIn: true, error: '' })

const signInError = (state, { error }) => state.merge({ error, loading: false })

const signInConsent = (state, { requireConsent, consentUserName, consentOpenToken }) => state.merge({ requireConsent, consentUserName, consentOpenToken })

const logOutSuccess = state => state.merge({ loading: false, idToken: '', error: '' })

const logOutRequest = state => {
  return state.merge({
    accessToken: '',
    refreshToken : '',
    loading: true,
    loggedIn: false
  })
}

const refreshTokenRequest = state => state.merge({ loading: true, error: '' })

const refreshTokenSuccess = (state, { data }) => state.merge({ ...data, loading: false, loggedIn: true, error: '' })

const refreshTokenError = (state, { error }) => state.merge({ errorRefreshToken: error})

const resetRefreshTokenStatus = state => state.merge({loading: false})

const setCurrentOrganization = (state, { orgId, userName }) => {
  let storage
  if (isValidJsonString(localStorage.getItem(OP_INTEL_KEY))) {
    storage = JSON.parse(localStorage.getItem(OP_INTEL_KEY)) || {}
  } else {
    storage = {}
  }

  storage[userName] = orgId
  if (orgId) {
    localStorage.setItem(OP_INTEL_KEY, JSON.stringify(storage))
  }
  return state
}

const loginLogoutSentience = state => {
  return state.merge({ loading: true })
}

const reset = (state, { loginType }) => {
  global.localStorage.setItem('current-login', loginType)
  return state.merge({ ...INITIAL_STATE, currentLogin: loginType, sessionTimeout: (new Date()).toString() })
}

const setCurrentLogin = (state, { loginType }) => {
  global.localStorage.setItem('current-login', loginType)
  return state.merge({ currentLogin: loginType })
}

const loginSetTransitionOrganization = (state, { orgId }) => state.merge({ selectedOrgId: orgId })

const setCurrentAdditionalParams = (state, { currentNonce, currentCustomerType, logoutUrl, appId, useOpentoken, redirectUri }) => {
  return state.merge({ currentNonce, currentCustomerType, logoutUrl, appId, useOpentoken, redirectUri })
}

const setAccessToken = (state, { errorAccessToken }) => state.merge({ errorAccessToken })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SIGN_IN_REQUEST]: signInRequest,
  [Types.LOGIN_SIGN_IN_SUCCESS]: signInSuccess,
  [Types.LOGIN_SIGN_IN_ERROR]: signInError,
  [Types.LOGIN_SIGN_IN_CONSENT]: signInConsent,
  [Types.LOGIN_LOGOUT_REQUEST]: logOutRequest,
  [Types.LOGIN_LOGOUT_SUCCESS]: logOutSuccess,
  [Types.LOGIN_LOGOUT_SENTIENCE]: loginLogoutSentience,
  [Types.LOGIN_RESET]: reset,
  [Types.LOGIN_SET_CURRENT]: setCurrentLogin,
  [Types.LOGIN_SET_CURRENT_ORGANIZATION]: setCurrentOrganization,
  [Types.LOGIN_SET_TRANSITION_ORGANIZATION]: loginSetTransitionOrganization,
  [Types.LOGIN_SET_CURRENT_ADDITIONAL_PARAMS]: setCurrentAdditionalParams,
  [Types.LOGIN_SET_ACCESS_TOKEN]: setAccessToken,
  [Types.LOGIN_REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.LOGIN_REFRESH_TOKEN_REQUEST_AUTO]: refreshTokenRequest,
  [Types.LOGIN_REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.LOGIN_REFRESH_TOKEN_RESET_STATUS]: resetRefreshTokenStatus,
  [Types.LOGIN_REFRESH_TOKEN_ERROR]: refreshTokenError
})
