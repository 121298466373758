export class SoftwareUpdateService {
  constructor(api) {
    this.api = api
  }

  retryScheduledTask = (masterId, payload) => {
    return this.api.put(`/schedules/${masterId}/retry-commands`, payload)
  }

  sendDownloadRequest = (scheduleId, payload) => {
    return this.api.get(`/schedules/${scheduleId}/export-tasks`, payload)
  }

  getFormatedScheulesData(responseData) {
    return responseData?.schedulerSoftwareDetailList?.map(task => ({
      ...task,
      ...task.assetDetails
    }))
  }

  getSchedules = async (userIds, lastTimestamp) => {
    const userIdsStr = userIds?.toString()
    const response = await this.api.get('/schedules', {
      userIds: userIdsStr,
      lastTime: lastTimestamp && encodeURIComponent(lastTimestamp)
    })
    if (response.ok) {
      return {
        ...response,
        data: this.getFormatedScheulesData(response.data),
        nextTimestamp: response.data?.nextTimestamp
      }
    }
    return response
  }

  getDevicesFromJob = (jobId, filters, selectExcluded) => {
    return this.api.get(`/schedules/${jobId}/tasks`, filters, selectExcluded)
  }

  getUsers = () => {
    return this.api.get('/schedules/users')
  }
}
