import React, { useEffect, useState } from 'react'

import { useDeviceModels } from 'Components/AssetManagementBase/components/filters/filters.hook'
import { FilterPanel, FilterSection } from 'Components/DataTable/components'

import { useAssetSelector } from './asset-selector.hooks'
import { StyledSelect } from './asset-selector.styles'

const Filters = ({
  assetId,
  filter,
  setFilter,
  setLoading,
  t }) => {
  const [allFirmware, setAllFirmware] = useState([])
  const [allModels, setAllModels] = useState([])
  const [allSites, setAllSites] = useState([])

  const { getSites, getFirmwareVersions } = useAssetSelector(setLoading)
  const { models } = useDeviceModels()

  useEffect(() => {
    getSites()
      .then(data => setAllSites(data.map(({ value, text }) => ({ value: value.id, text }))))
      .catch(() => null)
    getFirmwareVersions(assetId, t)
      .then(data => setAllFirmware(data))
      .catch(() => null)
  }, [])

  useEffect(() => {
    setAllModels(models)
  }, [models])

  return (
    <FilterPanel
      count={1}
      onReset={() => {
        setFilter({})
        setAllFirmware([])
        setAllModels([])
        setAllSites([])
      }}
    >
      <FilterSection label={t('Model')}>
        <StyledSelect
          fluid
          key={allModels.join(':')}
          multiple
          onChange={models => setFilter(prevState => ({ ...prevState, models }))}
          options={allModels}
          search={true}
          value={filter.models}
        />
      </FilterSection>
      <FilterSection label={t('Sites')}>
        <StyledSelect
          fluid
          key={allSites.join(':')}
          multiple
          onChange={sites => setFilter(prevState => ({ ...prevState, sites }))}
          options={allSites}
          search={true}
          value={filter.sites}
        />
      </FilterSection>
      <FilterSection label={t('Firmware')}>
        <StyledSelect
          fluid
          key={allFirmware.join(':')}
          multiple
          onChange={firmwares => setFilter(prevState => ({ ...prevState, firmwares }))}
          options={allFirmware}
          search={true}
          value={filter.firmwares}
        />
      </FilterSection>
    </FilterPanel>
  )
}

export default Filters
