import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { AssetType, CreateAssetType } from '../apis'
import {
  createAssetType,
  fetchAssetTypes,
  selectAllAssetTypes,
  selectTotalAssetTypes
} from '../store'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { useTranslate } from 'react-translate'
import { useAppDispatch } from '../../../Store'

export const useFetchAssetTypes = () => {
  const dispatch = useDispatch()
  const translate = useTranslate('AssetTypes')

  const [isLoading, setIsLoading] = useState(false)
  const assetTypes = useSelector(selectAllAssetTypes)
  const total = useSelector(selectTotalAssetTypes)

  const fetchData = useCallback(async (organizationId: string) => {
    setIsLoading(true)
    const resultAction: any = await dispatch(fetchAssetTypes(organizationId))

    if (fetchAssetTypes.fulfilled.match(resultAction)) {
      setIsLoading(false)
      return unwrapResult(resultAction)
    } else {
      setIsLoading(false)
      toast(<ErrorToast message={translate('GetError')} closeToast={() => {}} />)
      return null
    }
  }, [setIsLoading, dispatch, translate])

  return {
    isLoading,
    assetTypes,
    total,
    fetchAssetTypes: fetchData
  }
}

export const useCreateAssetType = () => {
  const dispatch = useAppDispatch()
  const [isPending, setIsPending] = useState(false)

  const submitData = useCallback(async (assetType: CreateAssetType): Promise<AssetType> => {
    setIsPending(true)
    const resultAction = await dispatch(createAssetType(assetType))
    setIsPending(false)

    if (createAssetType.fulfilled.match(resultAction)) {
      return unwrapResult(resultAction)
    } else if (resultAction.payload) {
      throw resultAction.payload.map(err => err.detail)
    } else {
      throw new Error(resultAction.error.message)
    }
  }, [setIsPending, dispatch])

  return {
    isPending,
    createAssetType: submitData
  }
}
