import { createStructuredSelector, createSelector } from 'reselect'

export const unitSystem = ({user}) => user.getIn(['unitSystem'], 'metric')

export const totalPrintersInHoursOfUse = ({ dashboard }) =>
  dashboard.getIn(['printerUsageLevels'], {})

export const heavyUtilized = createSelector(
  [totalPrintersInHoursOfUse],
  heavyUtilized => heavyUtilized.getIn(['values', 2, 'value'], [])
)

export const midUtilized = createSelector(
  [totalPrintersInHoursOfUse],
  midUtilized => midUtilized.getIn(['values', 1, 'value'], [])
)

export const notUtilized = createSelector(
  [totalPrintersInHoursOfUse],
  notUtilized => notUtilized.getIn(['values', 0, 'value'], [])
)

export const months = createSelector(
  [totalPrintersInHoursOfUse],
  months => months.getIn(['months'], [])
)

export default createStructuredSelector({
  heavyUtilized,
  midUtilized,
  notUtilized,
  months,
  unitSystem
})
