import React from 'react';
import { connect } from 'react-redux'
import SignalStrengthModal from './SignalStrengthModal'
import { AccessPointsActions } from 'features/access-points/store/actions' 
import ModalActions from 'Redux/ModalRedux'
import SignalStrengthSelector from './SignalStrengthModal.selector'

import { translate } from 'react-translate'
import { pipe } from 'ramda'

const mapDispatchToProps = (dispatch) => {
  return {
    setSignalRanges: (params) => dispatch(AccessPointsActions.updateAccessRanges(params)), //change
    closeModal: () => dispatch(ModalActions.modalClose()),
  }
}


export default pipe(
  connect(SignalStrengthSelector, mapDispatchToProps),
  translate('AccessPointsInsights')
)(SignalStrengthModal)