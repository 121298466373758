import { useCallback, useMemo, useState } from 'react'
import { getPermissions } from './selectors'
import { useSelector } from 'react-redux'
import { PermissionsApi } from 'Services'

export const usePermissions = (siteIdSelector) => {
  const permissionsSelector = useMemo(
    () => getPermissions(siteIdSelector),
    [siteIdSelector]
  )
  return useSelector(permissionsSelector)
}

export const useGetAuthorizedOrgUnits = (permission) => {
  const [orgUnits, setOrgUnits] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const refreshOrgUnits = useCallback(async () => {
    setIsLoading(true)
    const response = await PermissionsApi.getAuthorizedOrganizationalUnits(permission)
    if (response.ok) {
      setOrgUnits(response.data.data)
    } else {
      console.error(`Error loading authorized sites for permission '${permission}'`)
    }
    setIsLoading(false)
  }, [permission])

  return {
    isLoading,
    orgUnits,
    refreshOrgUnits
  }
}
