import { createStructuredSelector, createSelector } from 'reselect'

export const newBatteries = state => state.dashboard.newBatteriesLastMonth

export const data = createSelector(
  [newBatteries],
  avgBatHp => avgBatHp.getIn(['batteryList'], [])
)

export default createStructuredSelector({
  data
})
