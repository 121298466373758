import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.primary ?? '#1274B7'};
  padding: 0.75rem;
`

export const MenuTitle = styled.span`
  color: #FFFFFF;
`

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1rem;
`

export const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RowNumber = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  padding-top: 1.5rem;
`
