import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  deviceActionRequest: ['actionType', 'serial'],
  selectedActionType: ['currentAction'],
  triggerActionCommand: [
    'deviceId',
    'actionCommand',
    'commandParams',
    'setButtonContent',
    'setButtonClass',
    'setWaitingResponse',
  ],
  triggerLogActionCommand: ['payload', 'setButtonContent', 'setButtonClass', 'setWaitingResponse'],
  pullDeviceLogs: ['payload', 'setWaitingResponse', 't', 'closeModal'],
  getLatestTelemetry: ['serialNumber', 'pointName', 'setWaitingResponse', 'setTelemetryDate', 'currentCulture', 't'],
  getLastHeartbeat: ['serialNumber', 'setWaitingResponse', 'setHeartbeat', 'currentCulture', 't'],
  refreshConnectionStatus: ['serialNumber', 't'],
});

export const DeviceActionsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  currentAction: '',
});

/* ------------- Reducers ------------- */
export const updateDeviceActionType = (state, { currentAction }) => {
  return state.merge({ currentAction });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECTED_ACTION_TYPE]: updateDeviceActionType,
});
