const create = (api) => {
  const getAccessPoints = (siteId, params) => api.get(`/dashboard/v1/stats/accesspoints/${siteId}`, params)

  return {
    getAccessPoints
  }
}

export default {
  create
}
