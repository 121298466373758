import { createSelector, createStructuredSelector } from 'reselect'
import values from 'lodash/values'

export const buildingsReducer = ({ locations }) => locations.entities.site
export const zonesReducer = ({ locations }) => locations.entities.zone
export const subzonesReducer = ({ locations }) => locations.entities.subzone
export const selectDelete = ({ locations }) => locations.getIn(['deleteLocation', 'locationGuid'], null)
export const loading = ({ locations }) => locations.loading

export const mergeNodes = createSelector(
  buildingsReducer,
  zonesReducer,
  subzonesReducer,
  selectDelete,
  (buildings, zones, subzones, select) => {
    return [
      ...values(buildings).map(x => ({
        hierarchy: x.hierarchy,
        locationGuid: x.locationGuid,
        siteId: x.getIn(['referenceUnit', 0, 'organizationalUnitGuid'], ''),
        selectorRoute: x.selectorRoute,
        selected: select === x.locationGuid
      })),
      ...values(zones).map(x => ({
        hierarchy: x.hierarchy,
        locationGuid: x.locationGuid,
        siteId: x.getIn(['referenceUnit', 0, 'organizationalUnitGuid'], ''),
        selectorRoute: x.selectorRoute,
        selected: select === x.locationGuid
      })),
      ...values(subzones).map(x => ({
        hierarchy: x.hierarchy,
        locationGuid: x.locationGuid,
        siteId: x.getIn(['referenceUnit', 0, 'organizationalUnitGuid'], ''),
        selectorRoute: x.selectorRoute,
        selected: select === x.locationGuid
      }))
    ]
  }
)

export default createStructuredSelector({
  locations: mergeNodes,
  loading
})
