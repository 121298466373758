import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`
export const Content = styled.div`
& .scrolling.content {
  overflow: unset !important;
}
`
export const Rows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const SitesContainer = styled.div`
height: 500px;
width: 80%;
`

export const Padding = styled.div`
  height: 1rem;
`

export const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    border-bottom: 1px solid #c8c8c8;
    background-color:transparent !important;
  }

  & .p-datatable-scrollable-body {
    max-height: 54.5vh !important;
  }
  & .ui.single-input .search-icon {
    height: 2.5rem;
  }

  & .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }
  & .ui.modal .actions{
    padding: 3rem 0 0 !important;
  }
`

export const DateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  & .ui.date-picker-input .calendar-overlay{
    position: relative !important;
  }
`

export const TimeControls = styled.div`
  width: 49%;
`

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 10rem;
  justify-content: space-around;
`
export const Footer = styled.div`
& .actions {
  padding: 4rem 0 0 !important;
}
`
