import * as React from 'react'
import { useTranslate } from 'react-translate'
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces'
import { Icon, Popup, Progress } from '@scuf/common'
import styled from 'styled-components/macro'
import { ErrorMessage } from 'Services/api-response'
import { EmployeesImportTableRow } from './employees-import-table'
import { ValidationStatus } from './validation-status'

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  .scuf-circular-wrapper {
    align-self: center;
    margin: auto;
  }
`

const ErrorsList = styled.ul`
  width: 25rem;
`

export interface ErrorsCellProps {
  cellData: ICellData,
  validationStatus: ValidationStatus
}

export const ErrorsCell: React.FC<ErrorsCellProps> = ({
  cellData,
  validationStatus
}) => {
  const t = useTranslate('AddEmployeesForm')
  const errorTranslate = useTranslateError()

  if (validationStatus === ValidationStatus.Validating) {
    return (
      <CellContainer>
        <Progress circular={true} size='xsmall' />
      </CellContainer>
    )
  } else if (validationStatus === ValidationStatus.Failed) {
    return (
      <CellContainer>
        <Icon name='badge-info' size='small' color='red' /> {t('ErrorValidating')}
      </CellContainer>
    )
  }

  const employeeWithErrors = cellData.rowData as EmployeesImportTableRow | undefined
  const errors = employeeWithErrors?.errors ?? []

  if (errors == null || errors.length === 0) {
    return (
      <CellContainer>
        <Icon name='check' size='small' color='green' /> {t('Valid')}
      </CellContainer>
    )
  }

  return (
    <CellContainer>
      <Popup
        element={(
          <>
            <Icon name='badge-info' size='small' color='red' /> {t('Invalid')}
          </>
        )}
        on='hover'
      >
        <ErrorsList>
          {
            errors.map((err, i) => (
              <li key={i}><b>{errorTranslate(err, employeeWithErrors)}</b></li>
            ))
          }
        </ErrorsList>
      </Popup>
    </CellContainer>
  )
}

function useTranslateError () {
  const translate = useTranslate('AddEmployeesForm')

  return React.useCallback((error: ErrorMessage, employee?: EmployeesImportTableRow) => {
    if (error.code == null) return error.detail

    const property = translate(error.source ?? 'Field')
    switch (error.code) {
      case 'NotEmptyValidator':
      case 'NameFormatValidator':
      case 'InvalidEmail':
      case 'InvalidSite':
      case 'InvalidRole':
      case 'NoWhitespacePrefixSuffixValidator': return translate(error.code, {
        property
      })
      case 'MaximumLengthValidator': return translate(error.code, {
        property,
        maxLength: getMaxLengthForProperty(error.source)
      })
      case 'EmployeeAlreadyExists': return translate(error.code, {
        employeeId: employee?.employeeId ?? ''
      })
      case 'DuplicateEmployeeId': return translate(error.code)
      default: return error.detail
    }
  }, [translate])
}

function getMaxLengthForProperty (property?: string): string {
  switch (property) {
    case 'FirstName':
    case 'MiddleName':
    case 'LastName': return '25'
    case 'EmployeeId': return '50'
    case 'Email': return '64'
    default: return '∞'
  }
}
