import { connect } from 'react-redux'
import ModalActions from '../../Redux/ModalRedux'
import UserActions from '../../Redux/UserRedux'
import SettingsModal from './settings-modal'
import SettingsModalSelector from './settings-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { unitSystem } from 'Components/DashboardCards/PrinterUsageByMeters/components/PrinterUsageLevelChart/usage-level-chart.selector'


const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal) => dispatch(ModalActions.modalOpen(modal)),
    closeModal: () => dispatch(ModalActions.modalClose()),
    setLanguage: (lang) => dispatch(UserActions.userSetLanguage(lang)),
    setTheme: (theme) => dispatch(UserActions.userSetTheme(theme)),
    setUnitSystem:(unitSystem) => dispatch(UserActions.userSetUnitSystem(unitSystem))
  }
}

export default pipe(
  translate('SettingsModal'),
  connect(
    SettingsModalSelector,
    mapDispatchToProps
  ),
  withLDConsumer()
)(SettingsModal)