import apisauce from 'apisauce'
import config from 'Config/AppConfig'

const create = (baseURL = config.api.remoteAccessUrl) => {
  const api = apisauce.create({
    baseURL,
    timeout: 60000
  })

  const getRemoteAccessInProgress = (deviceId, accessToken) => {
    api.setHeader('Authorization', `Bearer ${accessToken}`)
    return api.get(`/getDeviceStatus?deviceId=${deviceId}`)
  }

  return {
    getRemoteAccessInProgress,
    axiosInstance: api.axiosInstance
  }
}

export default {
  create
}
