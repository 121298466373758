import { call, put, all } from 'redux-saga/effects'
import DeviceTrendsActions from '../Redux/DeviceTrendsRedux'

export function * getDeviceTrends (api, { deviceId, deviceType }) {
  yield put(DeviceTrendsActions.deviceTrendsSetLoaders(true))
  switch (deviceType) {
    case 'printer':
      yield all([
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'battery'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'sensor'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'devinfo'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'printhead'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'media'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'powersupply')
      ])
      break
    case 'scanner':
      yield all([
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'battery'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'wlan')
      ])
      break
    default:
      yield all([
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'battery'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'sensor'),
        call(getTelemetryByCategory, api.getDeviceFullTelemetry, deviceId, deviceType, 'devinfo')
      ])
      break
  }
  yield put(DeviceTrendsActions.deviceTrendsSetLoaders(false))
}

export function * getTelemetryByCategory (api, deviceId, deviceType, category) {
  const response = yield call(api, deviceId, deviceType, category)
  if (response.ok) {
    yield put(DeviceTrendsActions.deviceTrendsCategorySuccess(category, response.data))
  } else {
    yield put(DeviceTrendsActions.deviceTrendsCategoryFailure(category))
  }
}
