import React from 'react'
import {
  ModalWrap,
  HeaderWrap,
  StyledH2,
  ParagraphModal,
  ModalHeader,
  StyledSubmit,
  ModalFooter
} from '../bulk-edit-modal.styles'
import PropTypes from 'prop-types'

const BulkCloseModal = ({
  t,
  openModal,
  closeModal,
  theme,
  closeAll,
  Cls
}) => {
  return (
    <ModalWrap theme={theme}>
      <ModalHeader>
        <HeaderWrap>
          <StyledH2>{t('TitleClose')}</StyledH2>
          <ParagraphModal>
            {t('DescriptionClose')}
          </ParagraphModal>
        </HeaderWrap>
      </ModalHeader>
      <ModalFooter>
        <StyledSubmit
          content={t('No')} size='medium' type='secondary' onClick={closeModal}
        />
        <StyledSubmit
          content={t('Yes')} size='medium' type='primary' onClick={() => {
            closeAll()
            Cls(null)
          }}
        />
      </ModalFooter>
    </ModalWrap>)
}

BulkCloseModal.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func
}
BulkCloseModal.defaultProps = {
  closeModal: () => { },
  t: () => { }
}
export default BulkCloseModal
