import React from 'react'
import { DataTable } from '@scuf/datatable'
import { Checkbox } from '@scuf/common'
import { stringValidator } from '../../../../Utils/StringTranslatorHelper'


const deviceTable = ({
  setMultipleDevices,
  selectedDevices,
  t,
  filter,
  devices
}) => {
  return (
  <DataTable
    onRowClick={(e) => setMultipleDevices(e.data.id)}
    data={devices}
    columnResizeMode='fit'
    resizableColumns={true}
    metaKeySelection={false}
    scrollable={true}
    scrollHeight='58vh'
    globalFilter={filter}
  >
    <DataTable.Column
      initialWidth='10%'
      renderer={({ rowData }) => (
        <div style={{ textAlign: 'center' }}><Checkbox checked={selectedDevices.indexOf(rowData.id) !== -1} /></div>
      )}
    />
    <DataTable.Column
      initialWidth='40%'
      field='name'
      header={t('Devicetable_Alias')}
      renderer={({ rowData, value }) =>
        <div style={{ textAlign: 'left' }}><DataTable.Status color={rowData.connectionStatus.toLowerCase() === 'connected' ? 'green' : 'red'}/>{value}</div>
      }/>
    <DataTable.Column
      initialWidth='50%'
      field='hierarchy'
      sortable
      header={t('Devicetable_Site')}
      renderer={ ({ value }) =>
        <div style={{ textAlign: 'left' }}>{value}</div>
      }/>
  </DataTable>
)}

export default deviceTable
