import React from 'react'
import { Icon } from '@scuf/common'
import { EmptyTableContainer, EmptyText } from './organization-change.styles'

const OrganizationChange = ({
  orgName,
  t
}) => {
  return (
    <EmptyTableContainer>
      <div>
        <Icon color='#c0c0c0' root='common' name='sites' size='xlarge' />
        <EmptyText>
          {t(`SwitchOrg`, { orgName })}
        </EmptyText>
        <div>{t('PleaseWait')}</div>
      </div>
    </EmptyTableContainer>
  )
}

export default OrganizationChange
