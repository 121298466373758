import { useState } from 'react'
import { Header } from '@scuf/common'
import { EndUserLicenseContainer, Content, Title, Description, TermsConditionsContent, Actions, ButtonsSection, TermsCheckbox, AcceptenceButton, LogOutButton } from './end-user-license-content.styles'
import Footer from 'Components/footer';
import { BodyWrapper } from 'Containers/AppFrame.styles';

export const EndUserLicenseContent = ({
  eulaContent = '',
  loading = false,
  enable = false,
  t = l => l,
  setEnable = () => { },
  onDecline = () => { },
  onAccept = () => { },
}) => {
  return (
    <EndUserLicenseContainer>
      <BodyWrapper>
        <Header title={t('HeaderTitle')} menu={false} collapseAt={700} />
        <Content>
          <div>
            <Title>{t('Title')}</Title>
            <Description>{t('Description')}</Description>
            <TermsConditionsContent>{eulaContent}</TermsConditionsContent>
          </div>
          <Actions>
            <TermsCheckbox
              label={t('Aknowledge')}
              checked={enable}
              onChange={(checked) => setEnable(checked)}
            />
            <ButtonsSection>
              <LogOutButton type="inline-secondary" content={t('Decline')} onClick={onDecline} />
              <AcceptenceButton type="primary" content={t('Accept')} disabled={!enable} loading={loading} onClick={onAccept} />
            </ButtonsSection>
          </Actions>
        </Content>
        <Footer />
      </BodyWrapper>
    </EndUserLicenseContainer>
  )
}

export default ({
  eulaContent = '',
  loading = false,
  t = l => l,
  history = {},
  logOut = () => { },
  onAccept = () => { },
}) => {
  const [enable, setEnable] = useState(false)
  const onDecline = () => {
    logOut()
    history.push('/login')
  }
  const props = {
    eulaContent,
    loading,
    enable,
    t,
    setEnable,
    onDecline,
    onAccept,
  }

  return <EndUserLicenseContent {...props} />
}
