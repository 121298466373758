import styled from 'styled-components/macro'
import { FileDrop, Loader } from '@scuf/common'
import IFileDropProps from '@scuf/common/dist/components/FileDrop/IFileDropProps'

export const FileDropContainer = styled.div`
  position: relative;
  min-height: 8rem;
  width: 100%;
`
export const StyledLoader = styled(Loader)`
  border: ${({ theme }) => theme?.dimensions?.borderWidth ?? '.0625rem'} dashed ${({ theme }) => theme?.colors?.inputBorder ?? '#d0d0d0'};
`

export interface StyledFileDropProps extends IFileDropProps {
    chars?: number;
    height?: string;
}

export const StyledFileDrop = styled(FileDrop)<StyledFileDropProps>`
  &.ui.file-drop {
    .drop-zone {
      min-height: 8rem !important;
      ${props => props.height ? `height: ${props.height} !important;` : ''}

      .drop-display-content .drop-zone-msg {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        ${props => props.chars ? `max-width: ${props.chars}ch;` : ''}
      }
    }
  }

  &.ui.file-drop.error {
    .browse-files .drop-zone-msg {
      color: ${({ theme }) => theme?.colors?.error ?? '#BE271C'};

      h4 {
        font-weight: normal;
        padding-bottom: ${({ theme }) => theme?.typography?.h4.size ?? '0.875rem'};
      }
    }

    .browse-files > .drop-zone-msg:nth-child(2) {
      display: none;
    }
  }
`
export const SubText = styled.div`
  font-size: .875rem;
`

export const StyledDiv = styled.div`
margin-top: -20rem
`
