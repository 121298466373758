import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

class NameComponent extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.rowData.selected !== prevProps.rowData.selected) {
      this.props.input.onChange(this.props.rowData.selected)
    }
  }
  render () {
    this.props.input.onChange(this.props.rowData.selected)
    return this.props.cellData || null
  }
}

const DataNameField = ({ cellData, rowData }) => {
  const { id, selected } = rowData
  return (
    <Field
      name={`${id}.selected`}
      component={props => {
        props.input.onChange(props.rowData.selected)
        return props.cellData || null//*/
      }}
      rowData={rowData}
      cellData={cellData}
    />
  )
}

export default DataNameField

