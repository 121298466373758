import React, { useState } from 'react'
import { useTranslate } from 'react-translate'

import { Button } from '@scuf/common'
import { Row } from './asset-file-uploader.styles'

import CsvFileDrop from 'Components/CsvFileDrop'

import { useAssetsImport } from './asset-file-uploader.hooks'

const AssetFileUploader = ({
    setIsScheduleFromFile,
    setUploadFile,
    setFileName
    }) => {
    const t = useTranslate('ScheduleUpdateModal')
    const [isFileInvalid, setIsFileInvalid] = useState(false)
    const { getAssetImportTemplate, onFileLoaded, onFileProcessed, onFileReset } = useAssetsImport(t)

    return (
        <>  
            <Row>
                <Button
                    type='link'
                    content={t('FromFileDownloadTemplate')} onClick={() => getAssetImportTemplate(t)}
                />
            </Row>
            <CsvFileDrop
                placeholder={t('FromFileMessage')}
                acceptedTypes={['.csv', '.xlsx']}
                disabled={false}
                onLoadFile={(file) => onFileLoaded(setFileName, file)}
                onLoadFileSuccess={(data) => onFileProcessed(data, setIsFileInvalid, setIsScheduleFromFile, setUploadFile, setFileName, t)}
                onReset={() => onFileReset(setIsScheduleFromFile, setUploadFile, setFileName)}
                error={isFileInvalid && t('FromFileUploadErrorMessage')}
                height='30vh'
            />
        </>)
} 

export default AssetFileUploader