import isEqual from 'lodash/isEqual'
import React from 'react'
import { toast } from 'react-toastify'
import { all, call, put, select } from 'redux-saga/effects'

import { MODAL_TOAST_CONTAINER } from 'Utils/constants'
import SitesManagerActions from '../Redux/SiteManagerRedux'
import UserManagerActions from '../Redux/UserManagerRedux'
import { ErrorToast, SuccessToast } from '../Themes/ScufStyledComponents'

export function* getUsers(api) {
  const response = yield call(api.usersList)
  if (response.ok) {
    yield put(UserManagerActions.userManagerSuccess(response.data.apiClientResponse || []))
  } else {
    yield all([
      put(UserManagerActions.userManagerFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* updateUser(api, { userData, siteId }) {
  try {
    const originalData = yield select(state => state.userManager.users[state.userManager.currentUser])
    const userOrgs = userData.organization.organizationalUnits

    let revokedSites = []
    originalData['organization'] && originalData['organization'].map(origOrg => {
      if (origOrg.roles?.length && userOrgs) {
        origOrg.roles.map(rr => {
          const orgRole = userOrgs.find(ou =>
            ou.id === '' && ou.parent === origOrg.guid && ou.role === rr.guid)
          revokedSites = !orgRole
            ? [
              ...revokedSites,
              {
                organizationalUnitIdentifier: {
                  organizationalUnitGuid: origOrg.guid
                },
                role: rr
              }
            ]
            : revokedSites
        })
      }

      origOrg['organizationalUnits'] && origOrg['organizationalUnits'].map(site => {
        if (site.roles && site.roles.length && userOrgs) {
          site.roles.map(rr => {
            const siteRole = userOrgs.find(ou => ou.id === site.guid && ou.role === rr.guid)
            revokedSites = !siteRole
              ? [
                ...revokedSites,
                {
                  organizationalUnitIdentifier: {
                    organizationalUnitGuid: site.guid
                  },
                  role: rr
                }
              ]
              : revokedSites
          })
        }
      })
    })

    const newData = {
      person: {
        personIdentifier: userData['personIdentifier'],
        personDetails: userData['personDetails'],
        id: userData['id']
      },
      sitesWithGrantedAccess: userOrgs
        ? reduceSitesArray(userOrgs.map(userOrg => ({
          organizationalUnitIdentifier: {
            organizationalUnitGuid: userOrg['id'] ? userOrg['id'] : userOrg['parent']
          },
          role: { guid: userOrg['role'] }
        })))
        : [],
      sitesWithRevokedAccess: reduceSitesArray(revokedSites)
    }

    const response = yield call(api.updateUser, newData)
    if (response.ok) {
      yield all([
        put(UserManagerActions.userManagerGenericSuccess()),
        call(toast, <SuccessToast />, {
          containerId: MODAL_TOAST_CONTAINER
        }),
        put(UserManagerActions.userManagerRequest(true)),
        siteId && put(SitesManagerActions.siteManagerUsersRequest(siteId))
      ])
    } else {
      yield all([
        put(UserManagerActions.userManagerFailure()),
        call(toast, <ErrorToast />, {
          containerId: MODAL_TOAST_CONTAINER
        })
      ])
    }
  } catch (e) {
    yield all([
      put(UserManagerActions.userManagerFailure()),
      call(toast, <ErrorToast />, {
        containerId: MODAL_TOAST_CONTAINER
      })
    ])
  }
}

export function* createUser(api, { userData }) {
  try {
    const newData = {
      person: {
        personIdentifier: userData['personIdentifier'],
        personDetails: userData['personDetails'],
        id: userData['id']
      },
      sitesWithGrantedAccess: userData['organization']['organizationalUnits']
        ? reduceSitesArray(userData['organization']['organizationalUnits'].map((site) => ({
          organizationalUnitIdentifier: {
            organizationalUnitGuid: site['id'] ? site['id'] : site['parent']
          },
          role: {
            guid: site['role']
          }
        })))
        : []
    }

    const response = yield call(api.createUser, newData)

    if (response.ok) {
      yield all([
        put(UserManagerActions.userManagerGenericSuccess()),
        call(toast, <SuccessToast />, {
          containerId: MODAL_TOAST_CONTAINER
        }),
        put(UserManagerActions.userManagerRequest(true))
      ])
    } else {
      yield put(UserManagerActions.userManagerFailure())
      if (response.status === 409 || response.status === 424) {
        yield call(toast, <ErrorToast message='This user already exists' />, {
          containerId: MODAL_TOAST_CONTAINER
        })
      }
      if (response.status === 403) {
        yield call(toast, <ErrorToast message='Access denied' />, {
          containerId: MODAL_TOAST_CONTAINER
        })
      }
    }
  } catch (e) {
    yield all([
      put(UserManagerActions.userManagerFailure()),
      call(toast, <ErrorToast />, {
        containerId: MODAL_TOAST_CONTAINER
      })
    ])
  }
}

export function* deleteUser(api, { userId, orgId }) {
  try {
    const response = yield call(api.deleteUser, userId, [orgId])

    if (response.ok) {
      yield all([
        put(UserManagerActions.userManagerGenericSuccess()),
        call(toast, <SuccessToast />),
        put(UserManagerActions.userManagerClearCurrent()),
        put(UserManagerActions.userManagerRequest())
      ])
    } else {
      yield all([
        put(UserManagerActions.userManagerFailure()),
        call(toast, <ErrorToast />)
      ])
    }
  } catch (e) {
    yield all([
      put(UserManagerActions.userManagerFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* getRoles(api) {
  try {
    const response = yield call(api.getRoles)

    if (response.ok) {
      yield put(UserManagerActions.userManagerRolesSuccess(response.data))
    } else {
      yield put(UserManagerActions.userManagerRolesFailure())
    }
  } catch (e) {
    yield put(UserManagerActions.userManagerRolesFailure())
  }
}

export function* getRegistrationRole(api) {
  try {
    const response = yield call(api.getRegistrationRole)
    if (response.ok) {
      yield put(UserManagerActions.userManagerRegistrationRoleSuccess(response.data[0]))
    } else {
      yield put(UserManagerActions.userManagerRegistrationRoleFailure())
    }
  } catch (e) {
    yield put(UserManagerActions.userManagerRegistrationRoleFailure())
  }
}

export function* getUiRoles(api) {
  try {
    const response = yield call(api.getUiRoles)
    if (response.ok) {
      yield put(UserManagerActions.userManagerRolesUiRequestSuccess(response.data))
    } else {
      yield put(UserManagerActions.userManagerRolesUiRequestFailure())
    }
  } catch (e) {
    yield put(UserManagerActions.userManagerRolesUiRequestFailure())
  }
}

export function* getUsersByRole(api, { roleId }) {
  try {
    const response = yield call(api.getUsersByRole, roleId)
    if (response.ok) {
      yield put(UserManagerActions.userManagerUsersByRoleResponse(response.data))
    } else {
      yield put(UserManagerActions.userManagerUsersByRoleResponse([]))
    }
  } catch (e) {
    yield put(UserManagerActions.userManagerUsersByRoleResponse([]))
  }
}

function reduceSitesArray(sites) {
  return sites.reduce((acc, cur) => {
    const { organizationalUnitIdentifier } = cur
    const existingSite = acc.find(e => isEqual(e.organizationalUnitIdentifier, organizationalUnitIdentifier))
    return existingSite
      ? (() => {
        existingSite.roles.push(cur.role)
        return acc
      })()
      : [...acc, { organizationalUnitIdentifier, roles: [cur.role] }]
  }, [])
}
