import React, { memo } from 'react'
import ErrorTooltip from '../../components/error-tooltip/ErrorTooltip'
import { Input, InputLabel, Button } from '@scuf/common'
import { noSpecialCharactersWithoutSpaces } from '../../../../../../Forms/Validators'
import { AddIcon } from './SerialNumber.styles'
import { Row, Col } from '../../form-devices.styles'
import Proptypes from 'prop-types'

const SerialNumber = memo(({
  selectedDeviceModel,
  serialNumber,
  selectedDeviceType,
  onClick,
  onChange,
  onEnter,
  csvLoaded,
  devicesAddedToList,
  t
}) => (
  <div>
    <Row justifyContent='flex-end'>
      <Col flex={8}>
        <Input
          label={t('serialNumber')}
          indicator="required"
          maxLength={10}
          helperText={t('maximum')}
          onEnter={onEnter}
          id='serialNumberUploadCsv'
          placeholder={t('placeholderSerial')}
          onChange={onChange}
          value={serialNumber}
          disabled={(csvLoaded && devicesAddedToList.length > 0) || !selectedDeviceModel}
          style={{ minWidth: '250px' }}
          error={(serialNumber && noSpecialCharactersWithoutSpaces(serialNumber) && t(noSpecialCharactersWithoutSpaces(serialNumber))) || ''}
        />
      </Col>
      <Col flex={4} style={{ width: '125px', marginTop: '24px' }}>
        <Button
          name='add'
          onClick={onClick}
          type="inline-secondary"
          id='addserial'
          content={t('add')}

          disabled={(!selectedDeviceModel || !serialNumber || !selectedDeviceType) ? true : false} />
      </Col>
    </Row>
  </div>
))

SerialNumber.displayName = 'FormUploadSerialNumber'
SerialNumber.propTypes = {
  csvLoaded: Proptypes.bool,
  onChange: Proptypes.func,
  devicesAddedToList: Proptypes.array,
  serialNumber: Proptypes.string,
  onClick: Proptypes.func,
  selectedDeviceModel: Proptypes.string,
  onEnter: Proptypes.func
}
export default SerialNumber
