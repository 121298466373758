const getStarted = 'Get Started'

export const PROMPT_TYPES = {
  dashboard: {
    title: 'No Devices',
    subtitle: 'Onboard Devices',
    icon: {
      root: 'common',
      name: 'graph-bar',
      size: 'xlarge',
      color: '#C0C0C0'
    },
    buttonContent: getStarted,
    buttonType: 'primary'
  },
  other: {
    title: 'No User',
    subtitle: 'Choose user',
    icon: {
      root: 'common',
      name: 'document-history',
      size: 'xlarge',
      color: '#C0C0C0'
    },
    buttonContent: getStarted,
    buttonType: 'primary'
  },
  assets: {
    title: 'No Devices',
    subtitle: 'Onboard Devices',
    icon: {
      root: 'common',
      name: 'ip-conflict',
      size: 'xlarge',
      color: '#C0C0C0'
    },
    buttonContent: getStarted,
    buttonType: 'primary'
  },
  updates: {
    title: 'No Packages',
    subtitle: 'Add Software',
    icon: {
      root: 'common',
      name: 'ip-conflict',
      size: 'xlarge',
      color: '#C0C0C0'
    },
    buttonContent: getStarted,
    buttonType: 'primary'
  },
  accessPoints: {
    title: 'No Access Points',
    subtitle: 'Add Access Points',
    icon: {
      root: 'common',
      name: 'ip-conflict',
      size: 'xlarge',
      color: '#C0C0C0'
    }
  },
  softwareUpdates: {
    title: 'No Devices Selected',
    subtitle: 'Your current selection criteria does not include any devices',
    icon: {
      root: 'common',
      name: 'ip-conflict',
      size: 'large',
      color: '#C0C0C0'
    }
  }
}
