import React from 'react'
import { Li } from '../../../TreeNode'
import LocationTree from '../location-tree'

function LocationNode ({
  label,
  onClick,
  selectorRoute,
  open,
  zone,
  id,
  currentLocation,
  depth
}) {
  return (
    <div>
      <Li
        onClick={() => onClick(selectorRoute)}
        open={open}
        leave={!zone || !zone.length}
      >
        {currentLocation === id ? <b>{label}</b>: label}
      </Li>
      {open && zone && <LocationTree nodes={zone} depth={depth + 1}/>}
    </div>
  )
}

export default LocationNode
