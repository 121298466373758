import { createStructuredSelector } from 'reselect'

export const isEditModalVisible = ({ accessPoints }) => accessPoints.getIn(['mode'], '') === 'edit'

export const modalError = ({ accessPoints }) => accessPoints.getIn(['error'], null)

export const isCreateSuccess = ({ accessPoints }) => accessPoints.getIn(['createSuccess'], false) === true

export const isEditSuccess = ({ accessPoints }) => accessPoints.getIn(['editSuccess'], false) === true

export default createStructuredSelector({
  isEditModalVisible,
  modalError,
  isCreateSuccess,
  isEditSuccess
})
