import React from 'react'
import { Radio } from '@scuf/common'

export function RadioSelection ({
  onChange,
  options,
  disabled,
  value
}) {
  return options?.map(option => {
    return (
      <Radio
        key={option.value}
        label={option.label}
        onChange={() => onChange(option.value)}
        checked={option.value === value}
        disabled={disabled}
      />
    )
  })
}

function FormRadioSelection ({
  input: { onChange, value },
  ...props
}) {
  return <RadioSelection onChange={onChange} value={value} {...props} />
}

export default FormRadioSelection
