
import React from 'react'
import { call, put } from 'redux-saga/effects'
import ModalActions from 'Redux/ModalRedux'
import config from '../Config/AppConfig'

export function * getRemoteAccessInProgress (api, { deviceId, accessToken }) {
  const foundObj = window.childWindows && window.childWindows.find(ele => ele.name === deviceId)
  if (foundObj) openRemoteAccessWindow(deviceId)
  else {
    const response = yield call(api.getRemoteAccessInProgress, deviceId, accessToken)
    if (response.ok && response.data) {
      const { isActive } = response.data
      if (isActive) {
        yield put(ModalActions.modalOpen('remoteAccessInProgressModal', {
          closeIcon: true,
          size: 'small',
          closeOnDimmerClick: true
        }))
      } else {
        openRemoteAccessWindow(deviceId)
      }
    } else {
      openRemoteAccessWindow(deviceId)
    }
  }
}

function openRemoteAccessWindow (id) {
  const url = `${config.api.remoteAccessUrl}?deviceId=${id}`

  if (!window.childWindows) window.childWindows = []

  const index = window.childWindows.findIndex(ele => ele.name === id)
  if (index >= 0) {
    if (window.childWindows[index].loadingStatus) {
      window.childWindows[index].windowObj.focus()
      return
    } else window.childWindows.splice(index, 1)
  }
  const w = window.open(url, id)

  if (w) { window.childWindows.push({ name: id, windowObj: w, loadingStatus: false }) }
}
