import React, { memo } from 'react'
import { DataTableContext } from '../../data-table'
import { ToolsItem, ToolsTotalCount, ToolsIcon } from './headerbar.styles'

const HeaderBarItem = memo(({ onClick, disabled, icon, iconRoot, content, custom }) => {
  return (
    <DataTableContext.Consumer>
      {selectedItems =>
        <ToolsItem onClick={() => onClick?.(selectedItems)}>
          <ToolsTotalCount disabled={disabled}>{content}</ToolsTotalCount>
          {custom}
          <ToolsIcon
            disabled={disabled}
            root={iconRoot}
            name={icon}
            size='medium'
          />
        </ToolsItem>
      }
    </DataTableContext.Consumer>
  )
})

export default HeaderBarItem
