import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tabs from '../'

import config from '../../../Config/AppConfig'

const location = config.isLocationAvailable

class ModalTabs extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        add: [{
          title: props.t('MasterModal_SiteorOrg'),
          onClick: () => props.openModal('siteForm')
        }, {
          title: props.t('MasterModal_User'),
          onClick: () => props.openModal('userForm')
        }, {
          title: props.t('MasterModal_Device'),
          onClick: () => props.openModal('deviceOnBoard')
        }, location ? {
          title: props.t('MasterModal_Locations'),
          onClick: () => props.openModal('locationForm')
        } : undefined],
        edit: [{
          title: props.t('MasterModal_SiteorOrg'),
          onClick: () => props.openModal('siteFormEdit')
        }, {
          title: props.t('MasterModal_User'),
          onClick: () => props.openModal('userFormEdit')
        }, {
          title: props.t('MasterModal_Device'),
          onClick: () => props.openModal('deviceForm')
        }, location ? {
          title: props.t('MasterModal_Locations'),
          onClick: () => props.openModal('editLocation')
        }
        : undefined],
        delete: [{
          title: props.t('MasterModal_SiteorOrg'),
          onClick: () => props.openModal('deleteSite')
        }, {
          title: props.t('MasterModal_User'),
          onClick: () => props.openModal('deleteUser')
        }, {
          title: props.t('MasterModal_Device'),
          onClick: () => props.openModal('deleteDevices')
        }, location ? {
          title: props.t('MasterModal_Locations'),
          onClick: () => props.openModal('deleteLocation')
        } : undefined]
      }
    }
  }

  render () {
    const { data } = this.state
    const { type, activeIndex } = this.props
    return(
      <Tabs tabs={data[type]} activeIndex={activeIndex} />
    )
  }

}

ModalTabs.propTypes = {
  type: PropTypes.oneOf([
    'add',
    'edit',
    'delete'
  ]).isRequired
}

ModalTabs.defaultProps = {
  type: 'add'
}

export default ModalTabs