import PreferencesPage from './Preferences'
import PreferencesPageSelector from './Preferences.selector'
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import BreadcrumbActions from '../../Redux/BreadcrumbRedux'
import PreferencesActions from 'Redux/PreferencesRedux'
import ModalActions from 'Redux/ModalRedux'
import { getSitesRequest } from 'features/site-management/store'
import SiteManagerActions from 'Redux/SiteManagerRedux'

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadcrumb: (items) => dispatch(BreadcrumbActions.breadcrumbSetItems(items)),
    getAlerts: (orgId) => dispatch(PreferencesActions.preferencesAlertsDataRequest(orgId)),
    saveAlerts: (alertsList) => dispatch(PreferencesActions.preferencesChangeAlertValueRequest(alertsList)),
    updateSites: (sites) => dispatch(PreferencesActions.preferencesSitesUpdate(sites)),
    openModal: (modal, modalProps) => dispatch(ModalActions.modalOpen(modal, modalProps)),
    getSites: () => dispatch(SiteManagerActions.siteManagerRootSitesRequest()),
    hierarchyRequest: () => dispatch(getSitesRequest()),
    toggleUpdate: (checked) => dispatch(PreferencesActions.toggleEnabled(checked)),
  }
}

export default pipe(
  translate('Preferences'),
  connect(PreferencesPageSelector, mapDispatchToProps),
  withLDConsumer()
)(PreferencesPage)
