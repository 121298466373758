import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeviceFormWrapper from './device-form-wrapper'
import DeviceActions from '../../Redux/DeviceRedux'
import SiteManagerActions from '../../Redux/SiteManagerRedux'
import FirmwareActions from '../../Redux/FirmwareRedux'
import mapStateToProps from './device-form-wrapper.selector'

const mapDispatchToProps = dispatch => ({
  getDevices: () => dispatch(DeviceActions.deviceRequestGateways()),
  getSites: () => dispatch(SiteManagerActions.siteManagerRootSitesRequest()),
  firmSetCurrentSite: (site) => dispatch(FirmwareActions.firmwareSetCurrentSite(site)),
  setCurrentDevice: (id) => dispatch(DeviceActions.deviceCurrent(id))
})

export default translate('DeviceForm')(connect(mapStateToProps, mapDispatchToProps)(DeviceFormWrapper))
