import { createSelector } from 'reselect'
import moment from 'moment'
import { autoRenewPaths, autoRenewModals } from './expiration-modal-handler.constants'

const getSessionTimeout = state => state.login.sessionTimeout

const selectRenewSessionFlag = ({flags}) => flags.renewSession

export const getSessionExpirationTimeOut = createSelector(
  [getSessionTimeout],
  (sessionTimeout) => {
    const minutesToShowModal = 10
    const tokenExp = moment(sessionTimeout)
    const timeOut = (Math.max(tokenExp.diff(moment()), 0)) - (minutesToShowModal * 60 * 1000)
    return (timeOut)
  }
)

const getModal = state => state.modal

export const getAllowRenew =  ({ history }) => createSelector(
  getModal, () => history.location.pathname,
  (modal, currentUrl) => {
    if(autoRenewPaths.some(path => path === currentUrl))
    {
      return (modal.isOpen && autoRenewModals.some(modalType => modalType === modal.type))
    }
    return false
  }
  )
  
export default (state, props) => {
  return {
    sessionExpirationTimeOut: getSessionExpirationTimeOut(state),
    isRenewSessionEnabled: selectRenewSessionFlag(props),
    allowRenew: getAllowRenew(props)(state)
  }    
}
