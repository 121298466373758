import React from 'react'
import { DatePicker } from '@scuf/common'
import styled from 'styled-components/macro'
import { translate } from 'react-translate'

const StyledDatePicker = styled(DatePicker)`
`
const FormDatePicker = ({
  input,
  t,
  meta:
    { error, visited, submitFailed, touched },
  disabled,
  label
}) => {
  return (
    <div>
      <StyledDatePicker
        { ...input }
        {...(touched || submitFailed) && error
          ? { error: t(error) }
          : {}
        }
        disabled={disabled}
        label={label}

      />
    </div>
  )
}

export default translate('FormInput')(FormDatePicker)
