import { Button, Icon, Modal, Notification, Stepper } from '@scuf/common'
import React, { useEffect, useState } from 'react'
import Loader from 'Components/Loader';

import { SoftwareUpdateTitle as ThemeTitle } from '../../Themes/ScufStyledComponents'
import { SelectMode } from './components/AssetSelector'
import ConfigStep from './components/ScheduleWizardSteps/config-step'
import ConfirmationStep from './components/ScheduleWizardSteps/confirmation-step'
import DeviceSelectionStep from './components/ScheduleWizardSteps/devices-step'
import TimeSelectionStep from './components/ScheduleWizardSteps/time-selection-step'
import { AlertContainer, ButtonContainer, ControlContainer, FooterContainer, ModalContent, ScrollableForm, SoftwareTitle, StyledStepperItem,
          Title, SoftwareJobContainer, JobTitle, EditJobNameContainer, JobName, JobNameInput, StyledEditIcon, StyledCheckIcon, StyledCancelIcon
        } from './schedule-modal.styles'

const ScheduleModal = ({
  t,
  modalClose,
  modalCancel,
  handleSubmit,
  submit,
  onSubmit,
  onSubmitFromFile,
  assetsCount,
  assetsList,
  appLoading,
  selectedDevices,
  schedule,
  updatePreferences,
  selectMode,
  formValues,
  flags,
  isEditJobNameEnabled }) => {
  const Steps = { 'CONFIG': 1, 'DEVICES': 2, 'TIME': 3, 'CONFIRM': 4 }
  const [currentStep, setCurrentStep] = useState(Steps.CONFIG)
  const assetInfo = assetsList[0]
  const maxDevices = 1000

  const [loading, setLoading] = useState(false)
  const [validScheduleTime, setValidScheduleTime] = useState(true)
  const [validConfigStep, setValidConfigStep] = useState(false)
  const [validConfirmStep, setValidConfirmStep] = useState(false)
  const [isScheduleFromFile, setIsScheduleFromFile] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [jobName, setJobName] = useState('')
  const [editJobName, setEditJobName] = useState(false)
  const [isJobNameEdited, setIsJobNameEdited] = useState(false)

  useEffect(() => {
    setLoading(appLoading)
  }, [appLoading])

  const nextPage = () => {
    if ((currentStep === Steps.TIME || currentStep === Steps.CONFIRM) && !validateTime()) {
      setValidScheduleTime(false)
    } else if (currentStep !== Steps.CONFIRM) {
      setValidScheduleTime(true)
      setCurrentStep(currentStep + 1)
    } else {
      submit()
    }
  }

  function onConfirmationStepClick () {
    if (!validateTime()) {
      setValidScheduleTime(false)
    } else {
      setValidScheduleTime(true)
    }
    setCurrentStep(Steps.CONFIRM)
  }

  function validateTime () {
    return schedule.IsValid && validScheduleTime
  }

  function isLast () {
    return (currentStep === Steps.CONFIRM)
  }

  function onClose (success) {
    success ? modalClose(true) : modalCancel(true)
  }

  function toastClickEvent () {
    setCurrentStep(Steps.TIME)
  }

  const validateStep = (step = currentStep) => {
    switch (step) {
      case Steps.CONFIG:
        return validConfigStep
      case Steps.DEVICES:
        return (selectedDevices.length !== 0 &&
          (selectedDevices.length <= maxDevices || selectMode !== SelectMode.devices))
          || (selectedDevices.length === 0 && isScheduleFromFile)
      case Steps.TIME:
        return validateTime()
      case Steps.CONFIRM:
        return validScheduleTime && validConfirmStep
      default:
        return false
    }
  }

  const handleSubmitType = (formData) => {
    isScheduleFromFile
      ? onSubmitFromFile(formData, uploadFile)
      : onSubmit(formData)
    setIsScheduleFromFile(false)
  }

  const handleOnBlurEvent = (e) => {
    const targetClassName = e?.relatedTarget?.className
    if(targetClassName?.includes('check')) {
      setJobName(e?.target.value.trim())
      setEditJobName(false)
      setIsJobNameEdited(false)
    } else if(targetClassName === undefined) {
      e?.target.focus()
    } else {
      setEditJobName(false)
      setIsJobNameEdited(false)
    }
  }

  const handleOnKeyCapture = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault()
      if(isJobNameEdited){
        setJobName(e.target.value.trim())
        setEditJobName(false)
      }
    } else if(e.key === 'Escape'){
      setEditJobName(false)
    }
  }

  const handleOnInputChange = (e) => {
    if(e.target.value.trim().length > 0){
      setIsJobNameEdited(true)
    } else {
      setIsJobNameEdited(false)
    }
  }

  // n is `completed` if all 1..n are valid; n is `disabled` if any 1..(n-1) is invalid
  const stepperItems = [
    <StyledStepperItem
      key='config'
      title={t('Configuration')}
      active={currentStep === Steps.CONFIG}
      completed={validateStep(Steps.CONFIG)}
      disabled={editJobName}
      onClick={() => setCurrentStep(Steps.CONFIG)}
    />,
    <StyledStepperItem
      key='device'
      title={t('Devices')}
      active={currentStep === Steps.DEVICES}
      completed={validateStep(Steps.CONFIG) && validateStep(Steps.DEVICES)}
      disabled={editJobName || !validateStep(Steps.CONFIG)}
      onClick={() => setCurrentStep(Steps.DEVICES)}
    />,
    <StyledStepperItem
      key='time'
      title={t('Schedule')}
      active={currentStep === Steps.TIME}
      completed={validateStep(Steps.CONFIG) && validateStep(Steps.DEVICES) && validateStep(Steps.TIME)}
      disabled={editJobName || !validateStep(Steps.CONFIG) || !validateStep(Steps.DEVICES)}
      onClick={() => setCurrentStep(Steps.TIME)}
    />,
    <StyledStepperItem
      key='confirm'
      title={t('Confirmation')}
      active={currentStep === Steps.CONFIRM}
      completed={false}
      disabled={editJobName || !validateStep(Steps.CONFIG) || !validateStep(Steps.DEVICES) || !validateStep(Steps.TIME)}
      onClick={onConfirmationStepClick}
    />
  ]

  return (
    <ScrollableForm
      onSubmit={handleSubmit(form => {
        handleSubmitType({
            form,
            finalDateTime: form.Schedule.FinalDateTime,
            finalEndTime: form.Schedule.FinalEndTime,
            cronExpression: form.Schedule.CronExpression,
            type: 'Immediate',
            timeZone: form.Schedule.TimeZone,
            software: { id: assetInfo.id, name: assetInfo.name, jobName: jobName || assetInfo.name },
            updatePreferences: form.updatePreferences
          })
        onClose(true)
      })}
    >
      <Modal.Header>
        <div>
          <ThemeTitle>{t('ScheduleUpdate')}</ThemeTitle>
          <Loader loading={loading} customWidth='96%'/>
        </div>
      </Modal.Header>
      <ModalContent>
        <Title>
          {assetsCount === 1 && `${assetsCount} ${t('UpdateSelected')}`}
          {assetsCount > 1 && `${assetsCount} ${t('UpdatesSelected')}`}
        </Title>
        <Stepper fluid={true} ordered={true}>
          {stepperItems.map(item => item)}
        </Stepper>
        {!isEditJobNameEnabled
          ? <SoftwareTitle>
              {`${t('Update Name')}: ${assetInfo?.name}`}
            </SoftwareTitle>
          : <SoftwareJobContainer>
              <JobTitle>{`${t('Update Name')}: `}</JobTitle>
              {editJobName ?
                <EditJobNameContainer>
                  <JobNameInput
                    tabindex='0'
                    type='text'
                    defaultValue={jobName || assetInfo?.name}
                    ref={(input) => {editJobName && (input && input.focus())}}
                    disabled={!editJobName}
                    edited={editJobName}
                    lenght={assetInfo?.name.lenght}
                    maxLength={256}
                    onBlur={(e) => handleOnBlurEvent(e)}
                    onKeyDownCapture={(e) => handleOnKeyCapture(e)}
                    onChange={(e) => handleOnInputChange(e)}
                  />
                  <StyledCheckIcon
                    tabindex='1'
                    root='global'
                    size='medium'
                    type='icon'
                    name='check'
                    disabled={!isJobNameEdited}
                    onClick={() => setEditJobName(false)}
                  />
                  <StyledCancelIcon
                    tabindex='2'
                    root='global'
                    size='medium'
                    type='icon'
                    name='close'
                    onClick={() => setEditJobName(false)}
                  />
                </EditJobNameContainer>
                : <EditJobNameContainer>
                    <JobName>{`${jobName || assetInfo?.name}`}</JobName>
                    <StyledEditIcon
                      tabindex='0'
                      root='global'
                      size='large'
                      type='icon'
                      name='edit'
                      onClick={() => setEditJobName(true)}
                  />
                </EditJobNameContainer>
              }
            </SoftwareJobContainer>
        }
        <ControlContainer>
          {currentStep === Steps.CONFIG &&
            <ConfigStep
              t={t}
              assetsList={assetsList}
              isValid={setValidConfigStep}
              formValues={formValues}
            />}
          {currentStep === Steps.DEVICES &&
            <DeviceSelectionStep
              t={t}
              assetsList={assetsList}
              loading={loading}
              setLoading={setLoading}
              setIsScheduleFromFile={setIsScheduleFromFile}
              setUploadFile={setUploadFile}
              setFileName={setFileName}
              fileName={fileName}
            />}
          {currentStep === Steps.TIME &&
            <TimeSelectionStep
              t={t}
              flags={flags}
              validScheduleTime={validScheduleTime}
              setValidScheduleTime={setValidScheduleTime}
            />}
          {currentStep === Steps.CONFIRM &&
            <ConfirmationStep
              t={t}
              assets={assetsList}
              devices={selectedDevices}
              schedule={schedule}
              validScheduleTime={validScheduleTime}
              isValid={setValidConfirmStep}
              toastClick={toastClickEvent}
              isScheduleFromFile={isScheduleFromFile}
              fileName={fileName}
            />}
        </ControlContainer>
      </ModalContent>
      <Modal.Footer>
        <FooterContainer>
          {selectedDevices.length > maxDevices && (
            <AlertContainer>
              <Notification hasIcon={true} severity='critical' title={t('MaxSoftwareUpdates')}>
                {t('UpdateMax', { maxDevices })}
              </Notification>
            </AlertContainer>
          )}
          <ButtonContainer>
            <Button
              content='Cancel' size='small' type='secondary' onClick={() => onClose(false)}
            />
            <Button
              content={!isLast() ? t('Next') : schedule.OnSchedule ? t('Schedule') : t('Update')}
              size='small'
              type='primary'
              onClick={nextPage}
              disabled={editJobName || !validateStep()}
            />
          </ButtonContainer>
        </FooterContainer>
      </Modal.Footer>
    </ScrollableForm>
  )
}

ScheduleModal.defaultProps = {
  t: l => l
}

export default ScheduleModal
