import { createStructuredSelector, createSelector } from 'reselect'

export const avgBatteryCycleCard = state => state.dashboard.batteryHealthBetween

export const data = createSelector(
  [avgBatteryCycleCard],
  avgBatHp => avgBatHp.getIn(['batteryList'], [])
)

export default createStructuredSelector({
  data
})
