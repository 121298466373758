import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'
import { formatAppName } from 'Utils/format-app-names'
import { formatBytes } from 'Utils/BytesConverter'
import moment from 'moment'
import { DashboardApi } from '../../../Services'

export const deepSleepData = ({ dashboard }) => 
 dashboard.getIn(['deepSleeps'], [])

const filteredData = createSelector(
  [deepSleepData],
  apps => Object.keys(apps)
    .map(app => apps[app])
)

export const data = createSelector(
  [filteredData],
  deepSleeps => 
  deepSleeps.map(deepSleep => {
    return ({
      timestamp: deepSleep.timestamp,
      deepSleepIntervalList: deepSleep.deepSleepIntervalList,
      count: deepSleep.count
    })
  })

)

export default createStructuredSelector({  
  data
})
