import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { isValidJsonString } from '../Utils/PropertyHelper'

const { localStorage } = global
const OP_INTEL_KEY = 'opintel-filter-assets-hidden-columns'

const { Types, Creators } = createActions({
  filterDevicesAdd: ['filter'],
  filterDevicesRemove: ['filter'],
  filterDevicesRemoveAll: null,
  filterBatteriesAdd: ['filter'],
  filterBatteriesRemove: ['filter'],
  filterSetGlobalFilter: ['filter']
})

export const FilterTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  devices: {
    connectionStatus: {
      name: 'Filters_ConnectedStatus',
      filtersById: [],
      filters: {},
      translateFilters: true
    },
    deviceTypeFormatted: {
      name: 'Filters_AssetType',
      filtersById: [],
      filters: {},
      translateFilters: true
    },
    modelUpperCase: {
      name: 'Filters_AssetModel',
      filtersById: [],
      filters: {},
      translateFilters: false
    }
  },
  batteries: {
    cycleCountFilter: {
      name: 'Filters_CycleCount',
      filtersById: [],
      filters: {},
      translateFilters: false
    },
    fullCapacityFilter: {
      name: 'Filters_MaxCapacitymAh',
      filtersById: [],
      filters: {},
      translateFilters: false
    }
  },
  assets: {
    hiddenColumns: {
      default: [],
      mobilecomputer: [],
      scanner: [],
      printer: []
    }
  },
  filter: ''
})

const arrayConcat = (arr, x) => arr.indexOf(x) === -1 ? [...arr, x] : arr
const resetFilter = filter => ({
  ...filter,
  filtersById: [],
  filters: {},
  filter: ''
})

/* ------------- Reducers ------------- */

export const filterDevices = (state, { filter: { key, value } }) => {
  if (state.devices[key]) {
    return state
      .updateIn(['devices', key, 'filtersById'], arrayConcat, value)
      .setIn(['devices', key, 'filters', value], { key: value, selected: true })
  } else {
    return state
  }
}

export const removeFilter = (state, { filter }) => state
  .updateIn(['devices', filter.key, 'filtersById'], (arr, x) => arr.filter(a => a !== x), filter.value)
  .setIn(['devices', filter.key, 'filters', filter.value], undefined)

export const filterBatteries = (state, { filter: { key, value } }) => {
  if (state.batteries[key]) {
    return state
      .updateIn(['batteries', key, 'filtersById'], arrayConcat, value)
      .setIn(['batteries', key, 'filters', value], { key: value, selected: true })
  } else {
    return state
  }
}

export const removeBatteryFilter = (state, { filter }) => state
  .updateIn(['batteries', filter.key, 'filtersById'], (arr, x) => arr.filter(a => a !== x), filter.value)
  .setIn(['batteries', filter.key, 'filters', filter.value], undefined)

export const filterDevicesRemoveAll = state => state
  .updateIn(['devices', 'connectionStatus'], resetFilter)
  .updateIn(['devices', 'deviceTypeFormatted'], resetFilter)
  .updateIn(['devices', 'modelUpperCase'], resetFilter)

const setGlobalFilter = (state, { filter }) =>
  state.merge({ filter: filter })

export const getAssetsHiddenColumns = (state, { user, siteId }) => {
  let storage
  //  invalidate previous data structure
  if (isValidJsonString(localStorage.getItem(OP_INTEL_KEY))) {
    storage = JSON.parse(localStorage.getItem(OP_INTEL_KEY)) || {}
  } else {
    storage = {}
  }

  storage[user] = siteId
  localStorage.setItem(OP_INTEL_KEY, JSON.stringify(storage))
  return state.merge({ siteId })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FILTER_DEVICES_ADD]: filterDevices,
  [Types.FILTER_DEVICES_REMOVE]: removeFilter,
  [Types.FILTER_DEVICES_REMOVE_ALL]: filterDevicesRemoveAll,
  [Types.FILTER_BATTERIES_ADD]: filterBatteries,
  [Types.FILTER_BATTERIES_REMOVE]: removeBatteryFilter,
  [Types.FILTER_SET_GLOBAL_FILTER]: setGlobalFilter
})
