import { call, put, select } from 'redux-saga/effects'
import UserSettingsActions from 'Redux/UserSettingsRedux'

export function * getUserSettingsFilters (api, { userId, currentSection }) {
  const response = yield call(api.getFilters, { userId, currentSection })
  if (response.ok) {
    yield put(UserSettingsActions.userSettingsResponseGetFilters({ ...response.data }))
  } else {
    yield put(UserSettingsActions.userSettingsFailure())
  }
}

export function * setUserSettingsFilters (api, { userId, filters }) {
  const response = yield call(api.setFilters, { userId, filters })
  if (response.ok) {
    yield put(UserSettingsActions.userSettingsRequestSetFilters({ ...response.data }))
  } else {
    yield put(UserSettingsActions.userSettingsFailure())
  }
}

export function * updateUserSettingsFilters (api, { userId, filters }) {
  const response = yield call(api.updateFilters, { userId, filters })
  if (response.ok) {
    yield put(UserSettingsActions.userSettingsRequestUpdateFilters({ ...response.data }))
  } else {
    yield put(UserSettingsActions.userSettingsFailure())
  }
}

export function * getDashboardList (api, { isOptions }) {
  try {
    const siteId = yield select(({ user }) => user.getIn(['selectedOrganizationId'], ''))
    const response = yield call(api.getDashboardDetailList, siteId)
    if (isOptions) {
      if (response.ok) {
        yield put(UserSettingsActions.dashboardOptionsResponse(response.data))
      } else {
        yield put(UserSettingsActions.dashboardOptionsResponse())
      }
    } else if (response.ok) {
      yield put(UserSettingsActions.dashboardResponseList(response.data))
    } else {
      yield put(UserSettingsActions.dashboardResponseList())
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardResponseList())
  }
}

export function * getDashboardFullList (api, { roleId }) {
  try {
    const siteId = yield select(({ user }) => user.getIn(['selectedOrganizationId'], ''))
    const response = yield call(api.getDashboardsFullList, roleId, siteId)
    if (response.ok) {
      yield put(UserSettingsActions.dashboardResponseForRoleList(response.data))
    } else {
      yield put(UserSettingsActions.dashboardResponseForRoleList())
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardResponseForRoleList())
  }
}

export function * getDashboardsPerUserFullList (api, { roleId, organizationId: siteId, userId }) {
  try {
    const response = yield call(api.getDashboardsPerUserFullList, roleId, siteId, userId)
    const myDashboardPermission = yield select(({ permissions }) => permissions?.allPermissions?.['dashboard:user:layout:edit'] ?? false)
    if (response.ok) {
      yield put(UserSettingsActions.dashboardResponseUserForRoleList(response.data, userId, myDashboardPermission))
    } else {
      yield put(UserSettingsActions.dashboardResponseUserForRoleList())
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardResponseUserForRoleList())
  }
}

export function * deleteDashboard (api, { organizationId: siteId }) {
  try {
    const dashboardId = yield select(({ userSettings }) => userSettings.getIn(['selectedDashboard', 'id'], ''))
    const response = yield call(api.deleteDashboard, dashboardId)
    if (response.ok) {
      const roleId = yield select(({ userSettings }) => userSettings.getIn(['currentRoleId'], ''))
      yield put(UserSettingsActions.dashboardRequestForRoleList(roleId))
    }
    yield put(UserSettingsActions.dashboardRevokeResponse({}))
  } catch (e) {
    yield put(UserSettingsActions.dashboardRevokeResponse({}))
  }
}

export function * assignDashboard (api, { dashboardId }) {
  try {
    const roleId = yield select(({ userSettings }) => userSettings.getIn(['currentRoleId'], ''))
    const siteId = yield select(({ user }) => user.getIn(['selectedOrganizationId'], ''))
    const response = yield call(api.assignDashboard, { dashboardId, roleId, siteId })
    if (response.ok) {
      yield put(UserSettingsActions.dashboardRequestForRoleList(roleId))
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardAssignResponse({}))
  }
}

export function * editDashboard (api, { roleId, assignFlag }) {
  try {
    const dashboard = yield select(({ userSettings }) => userSettings.getIn(['dashboardPreferences'], {}))
    const siteId = yield select(({ userSettings }) => userSettings.getIn(['dashboardPreferences', 'siteId'], ''))
    const userId = yield select(({ user }) => user.getIn(['userData', 'id'], ''))
    const dashboardKey = dashboard.getIn(['key'], dashboard.getIn(['id'], ''), '')
    const response = yield call(api.editDashboard, { dashboardKey, dashboard })
    if (response.ok) {
      if (roleId) {
        if (assignFlag) {
          yield put(UserSettingsActions.dashboardRequestUserForRoleList(roleId, siteId, userId))
        } else {
          yield put(UserSettingsActions.dashboardRequestForRoleList(roleId, siteId))
        }
      } else {
        yield put(UserSettingsActions.dashboardRequestList())
      }
    } else {
      yield put(UserSettingsActions.dashboardResponseForRoleList())
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardResponseForRoleList())
  }
}

export function * createNewDashboard (api, { roleId, assignFlag }) {
  try {
    const dashboard = yield select(({ userSettings }) => userSettings.getIn(['dashboardPreferences'], {}))
    const siteId = yield select(({ userSettings }) => userSettings.getIn(['dashboardPreferences', 'siteId'], ''))
    const userId = yield select(({ user }) => user.getIn(['userData', 'id'], ''))
    const response = yield call(api.createNewDashboard, { dashboard })
    if (response.ok) {
      if (roleId) {
        if (assignFlag) {
          yield put(UserSettingsActions.dashboardRequestUserForRoleList(roleId, siteId, userId))
        } else {
          yield put(UserSettingsActions.dashboardRequestForRoleList(roleId, siteId))
        }
      } else {
        yield put(UserSettingsActions.dashboardRequestList())
      }
    } else {
      yield put(UserSettingsActions.dashboardResponseForRoleList())
    }
  } catch (e) {
    yield put(UserSettingsActions.dashboardResponseForRoleList())
  }
}

export function * deleteDashboardView (api) {
  try {
    const dashboardId = yield select(({ userSettings }) => userSettings.getIn(['deletedDashboards'], []))
    const response = yield call(api.deleteDashboardView, dashboardId)
    if (response.ok) {
      yield put(UserSettingsActions.userSettingsDeleteDashboardViewResponse())
    }
    yield put(UserSettingsActions.dashboardRevokeResponse({}))
  } catch (e) {
    yield put(UserSettingsActions.dashboardRevokeResponse({}))
  }
}
