import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import persistConfig from '../Config/PersistorConfig'

import { checkInOutReducer } from './check-in-out'
import { organizationsReducer } from 'features/organization-management/store'
import { sitesReducer } from 'features/site-management/store'
import { accessPointsReducer } from 'features/access-points'
import { networkRangesReducer } from '../features/network-ranges'
import {
  manufacturersReducer,
  assetTypesReducer,
  assetModelsReducer
} from 'features/assets-management/store'
import { releaseNotesReducer } from './release-notes'
import LtlTripReducer from '../features/ltl-trip-management/store'
import { ssoOauthreducer } from 'features/sso-oauth2/store'
import { ruleCreationReducer } from 'features/rules-engine/create-rule/create-rule.reducer'
import { detailsDataTableReducer } from 'Components/DashboardDetails/components/DetailsDataTable/dashboard-details-datatable.reducer'
import { deletedBatteriesDetailsReducer } from 'Components/DashboardCards/DeletedBatteries/Details/deleted-batteries-details.reducer'
import { totalBatteriesDetailsReducer } from 'Components/DashboardCards/TotalBatteries/Details/total-batteries-details.reducer'
import { notReportingBatteriesDetailsReducer } from 'Components/DashboardCards/NotReportingBatteries/Details/not-reporting-batteries-details.reducer'
import { organizationUsersReducer } from 'features/user-management/users-list/organization-users-reducer'
import { locationTimelineReducer } from 'Components/LocationDisplay/location-display.reducer'
import { endUserLicenseAgreementReducer } from 'features/end-user-license/end-user-license.reducer'
import { usersListReducer } from 'features/user-management/users-list/users-list.reducer'
import { applicationDisruptionsDetailsReducer } from 'Components/DashboardCards/ApplicationDisruptions/Details/application-disruptions-details.reducer'
import { applicationDisruptionsByAppDetailsReducer } from 'Components/DashboardCards/ApplicationDisruptionsByApp/Details/application-disruptions-app-details.reducer'
import { subscriptionAgreementReducer } from 'features/subscription-agreement/subscription-agreement.reducer'

export const baseReducer = combineReducers({
  login: require('../Redux/LoginRedux').reducer,
  user: require('../Redux/UserRedux').reducer,
  devices: require('../Redux/DeviceRedux').reducer,
  report: require('../Redux/ReportRedux').reducer,
  filters: require('../Redux/FilterRedux').reducer,
  userManager: require('../Redux/UserManagerRedux').reducer,
  siteManager: require('../Redux/SiteManagerRedux').reducer,
  organizations: organizationsReducer,
  sites: sitesReducer,
  form: require('redux-form').reducer,
  batteries: require('../Redux/BatteryRedux').reducer,
  firmware: require('../Redux/FirmwareRedux').reducer,
  breadcrumb: require('../Redux/BreadcrumbRedux').reducer,
  deviceTrends: require('../Redux/DeviceTrendsRedux').reducer,
  locations: require('../Redux/LocationRedux').reducer,
  bireport: require('../Redux/BiReportRedux').reducer,
  modal: require('../Redux/ModalRedux').reducer,
  dashboard: require('../Redux/DashboardRedux').reducer,
  userSettings: require('../Redux/UserSettingsRedux').reducer,
  logs: require('../Redux/LogRedux').reducer,
  siteComparison: require('../Redux/SiteComparisonRedux').reducer,
  alerts: require('../Redux/AlertsRedux').reducer,
  preferences: require('../Redux/PreferencesRedux').reducer,
  collapsable: require('../Redux/CollapsableDashboardRedux').reducer,
  updates: require('../Redux/UpdatesRedux').reducer,
  checkInOut: checkInOutReducer,
  tagging: require('./tagging/reducer').reducer,
  accessPoints: accessPointsReducer,
  roles: require('./roles/reducer').reducer,
  remoteAccess: require('../Redux/RemoteAccessRedux').reducer,
  counter: require('../Redux/CounterRedux').reducer,
  permissions: require('./permissions/reducer').reducer,
  networkRanges: networkRangesReducer,
  manufacturers: manufacturersReducer,
  assetTypes: assetTypesReducer,
  assetModels: assetModelsReducer,
  menuOptions: require('../Redux/CollapsableMenuOptionsRedux').reducer,
  releaseNotes: releaseNotesReducer,
  ltlTrip: LtlTripReducer,
  deviceActions: require('../Redux/DeviceActionsRedux').reducer,
  ssoOauth: ssoOauthreducer,
  [ruleCreationReducer.slice]: ruleCreationReducer,
  [detailsDataTableReducer.slice]: detailsDataTableReducer,
  [deletedBatteriesDetailsReducer.slice]: deletedBatteriesDetailsReducer,
  [totalBatteriesDetailsReducer.slice]: totalBatteriesDetailsReducer,
  [notReportingBatteriesDetailsReducer.slice]: notReportingBatteriesDetailsReducer,
  [endUserLicenseAgreementReducer.slice]: endUserLicenseAgreementReducer,
  [organizationUsersReducer.slice]: organizationUsersReducer,
  [locationTimelineReducer.slice]: locationTimelineReducer,
  [usersListReducer.slice]: usersListReducer,
  [applicationDisruptionsDetailsReducer.slice]: applicationDisruptionsDetailsReducer,
  [applicationDisruptionsByAppDetailsReducer.slice]: applicationDisruptionsByAppDetailsReducer,
  [subscriptionAgreementReducer.slice]: subscriptionAgreementReducer
})

export const rootReducer = persistReducer(persistConfig, baseReducer)
export type RootState = ReturnType<typeof rootReducer>

declare module 'react-redux' {
  export interface DefaultRootState extends RootState { }
}
