import React from 'react'
import PropTypes from 'prop-types'
import { Containter } from './TableContainer.styles'
import { Loader } from '@scuf/common';

const TableContainer = ({
  loading,
  children
}) => {

  return (
    <Containter>
      {
        loading ? <Loader overlayOpacity={0.5} /> : null
      }
      { children }
    </Containter>
  )
}
TableContainer.displayName = 'TableContainer'
TableContainer.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.object
}
TableContainer.defaultProps = {
  loading: false
}

export default TableContainer
