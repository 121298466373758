
const create = (api) => {
  const getTimeRangeFilter = () => {
    return {
      timeRanges: [
        {
          label: 'Last 7 Days',
          value: '0',
        },
        {
          label: 'Last 30 Days',
          value: '1',
        },
        // {
        //   label: 'Year to date',
        //   value: '2',
        // },
      ],
    };
  };

    const getWorkTypeOptions = () => api.get(`/datainsights/worktypes`);

    const getEfficiencyScore = (timeRange, workType) =>
    api.get(`/datainsights/efficiencyScore?timerange=${timeRange}&worktype=${workType}`);

    const getEfficiencyGoal = () => api.get(`/datainsights/efficiencyGoal`);

  const getTimeRangeVolumeData = (siteId, timeRange, workType) =>
    api.get(`/datainsights/timeratevolume?timerange=${timeRange}&worktype=${workType}`);

  const getDisruptionData = (siteId, timeRange, workType) =>
    api.get(`/datainsights/disruptions?timerange=${timeRange}&worktype=${workType}`);

  const getWorkTimeData = (siteId, timeRange, workType) => 
    api.get(`/datainsights/allocation?timerange=${timeRange}&worktype=${workType}`);

  return {
    getTimeRangeFilter,
    getWorkTypeOptions,
    getEfficiencyScore,
    getEfficiencyGoal,
    getTimeRangeVolumeData,
    getDisruptionData,
    getWorkTimeData,
  };
};

export default {
    create
}