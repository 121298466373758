import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import moment from 'moment'

const { Types, Creators } = createActions({
  deviceTrendsRequestAll: ['deviceId', 'deviceType'],
  deviceTrendsRequestCategory: ['deviceId', 'deviceType', 'category'],
  deviceTrendsCategorySuccess: ['category', 'data'],
  deviceTrendsCategoryFailure: ['category'],
  deviceTrendsSetLoaders: ['loading']
})

export const DeviceTrendsTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  deviceId: '',
  deviceType: '',
  categories: {
    battery: [],
    sensor: [],
    devinfo: [],
    powersupply: [],
    media: [],
    printhead: [],
    wlan: []
  },
  loading: {
    battery: false,
    sensor: false,
    devinfo: false,
    powersupply: false,
    media: false,
    printhead: false,
    wlan: false
  },
  error: null
})

/* ------------- Reducers ------------- */

const deviceTrendRequest = (state, { deviceId }) =>
  state.merge({ deviceId: deviceId, error: null })

const requestByCategory = (state, { category }) =>
  state.setIn(['loading', category], true)

const successByCategory = (state, { category, data }) => {
  const newData = data.map(c => ({
    displayName: c.displayName,
    unit: c.unit,
    description: c.description,
    lastUpdatedTime: c.lastUpdatedTime,
    dataModel: c.dataModel.map(item => ([parseInt(moment(item.timestamp).locale(window.navigator.language).format('x')), parseFloat(item.value)]))
  }))
  return state
    .setIn(['categories', category], newData)
    .setIn(['loading', category], false)
}

const failureByCategory = (state, { category }) => {
  return state
    .setIn(['categories', category], {})
    .setIn(['loading', category], false)
}

const loadingAll = (state, { loading }) =>
  state.set(['loading'], {
    battery: loading,
    sensor: loading,
    devinfo: loading,
    powersupply: loading,
    media: loading,
    printhead: loading
  }).set(['categories', {
    battery: [],
    sensor: [],
    devinfo: [],
    powersupply: [],
    media: [],
    printhead: []
  }])

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEVICE_TRENDS_REQUEST_ALL]: deviceTrendRequest,
  [Types.DEVICE_TRENDS_REQUEST_CATEGORY]: requestByCategory,
  [Types.DEVICE_TRENDS_CATEGORY_SUCCESS]: successByCategory,
  [Types.DEVICE_TRENDS_CATEGORY_FAILURE]: failureByCategory,
  [Types.DEVICE_TRENDS_SET_LOADERS]: loadingAll
})
