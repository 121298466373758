import { connect } from 'react-redux'

import AutomatedReportModal from './AutomatedReportModal'
import AutomatedReportModalSelector from './AutomatedReportModal.selector'
import { translate } from 'react-translate'
import PreferencesActions from 'Redux/PreferencesRedux'

const mapDisatchToProps = dispatch => ({
  onSubmit: form => {
    if (form.editing) {
      dispatch(PreferencesActions.preferencesEmailEditRequest(form))
    } else {
      //dispatch(PreferencesActions.preferencesEmailRequest(form))
    }
  }
})

export default translate('PreferencesEmail')(connect(
  AutomatedReportModalSelector,
  mapDisatchToProps
)(AutomatedReportModal))
