import { schema } from 'normalizr'

export const taggedAssetSchema = new schema.Entity('asset', {}, {
  idAttribute: 'assetId'
})

export const tagsSchema = new schema.Entity('tags', { assets: [taggedAssetSchema] }, {
  idAttribute: entity => {
    return `${entity.key}:${entity.value}`
  },
  processStrategy: ({ key, value, ...asset }) => {
    return {
      key,
      value,
      assets: [asset],
      id: `${key}:${value}`
    }
  },
  mergeStrategy: (tagA, tagB) => {
    return {
      ...tagA,
      assets: [
        ...tagA.assets,
        ...tagB.assets
      ]
    }
  }
})
