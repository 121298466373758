import styled from 'styled-components/macro'
import FormSelect from '../../../FormComponents/FormSelect'
import { InfoKey, FormRow } from '../../../../Themes/ScufStyledComponents'

export const QrFormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  & form {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  & > .ui.button {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

 /*  & button {
    display: flex !important;
    flex: 1 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center;
  } */

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    flex: 1;
    padding: 0 1rem 1rem 1rem;
  }

  @media screen and (max-width: 320px) {
    & .scuf-input-wrapper .ui.input {
      margin-bottom: 0.75rem;
    }

    & h4 {
      font-size: 14px;
    }
  }
`

export const QrSiteSelect = styled(FormSelect)`
  width: 100%;

  & .ui.selection.dropdown {
    justify-content: space-between;
  }

  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    max-height: 20vh;
  }

  @media screen (max-width: 720px) {
    & .ui.selection.dropdown {
      margin-bottom: 1rem !important;
      font-size: 14px;
    }
    & span.text {
      font-size: 14px !important;
    }
    & div.text {
      padding-top: 0;
      padding-bottom: 0;
    }
    & div {
      padding: 0.5rem;
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 320px) {
    padding-top: 0;
    padding-bottom: 0;

    & * {
      font-size: 0.8rem;
    }
  }
`

export const QrTermsText = styled(InfoKey)`
  font-size: 14px;
  align-self: center;
`

export const BreakLineRow = styled(FormRow)`
  @media screen and (max-width: 720px),
  @media screen and (max-width: 320px) {
    flex-direction: column;
  }
`

export const InputContainer = styled.div`
  min-width: 2rem;
  padding: 0.25rem;
`
