import { connect } from 'react-redux'
import { compose } from 'ramda'
import EndUserLicenseContent from './end-user-license-content'
import LoginActions from 'Redux/LoginRedux'
import { translate } from 'react-translate'
import { EndUserLicenseAgreementActions, getEulaInfoSelector } from '../../end-user-license.reducer'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => (
  {
    ...getEulaInfoSelector(state),
  }
)

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(LoginActions.loginLogoutRequest()),
  onAccept: () => dispatch(EndUserLicenseAgreementActions.initialEULAVerificationRequest()),
})

export default compose(
  translate('EndUserLicense'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EndUserLicenseContent)
