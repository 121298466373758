import React, { memo } from 'react'
import { Button } from '@scuf/common'
import { QrFooterContainer } from '../../qr-section.styles'
import {
  PrivacyContainer,
  PrivacySubtitle,
  PrivacyIframeContainer,
  PrivacyIframe
} from './qr-privacy-section.styles'

const QrPrivacy = memo(({ t, onBackClick }) => (
  <PrivacyContainer>
    <PrivacySubtitle>
      {t('PrivacyPolicy')}
    </PrivacySubtitle>
    <PrivacyIframeContainer>
      <PrivacyIframe
        title={t('PrivacyStatements')}
        src='https://www.honeywell.com/privacy-statement'
      />
    </PrivacyIframeContainer>
    <QrFooterContainer justifyContent='flex-end'>
      <Button
        content={t('Back')}
        type='secondary'
        onClick={onBackClick}
      />
    </QrFooterContainer>
  </PrivacyContainer>
))

export default QrPrivacy
