import React from 'react'
import { toast } from 'react-toastify'
import { call, put, select, all } from 'redux-saga/effects'
import { momentTimeYearHour } from '../Utils/DateLocalizationHelper'
import DeviceActions from '../Redux/DeviceActionsRedux'
import Device from '../Redux/DeviceRedux'
import ModalActions from 'Redux/ModalRedux'
import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'

export function* selectDeviceAction(api, { actionType }) {
  try {
    yield put(DeviceActions.selectedActionType(actionType))
    yield put(ModalActions.modalOpen('deviceActionsModal', {
      closeIcon: true,
      size: 'small',
      closeOnDimmerClick: false
    }))
  } catch (_) {
    yield put(DeviceActions.selectedActionType(''))
  }
}

export function* triggerActionCommand(api, { deviceId, actionCommand, commandParams, setButtonContent, setButtonClass, setWaitingResponse }) {
  try {
    setWaitingResponse(true)
    const response = yield call(api.sendActionCommand, deviceId, actionCommand, commandParams)
    if (response.ok) {
      setWaitingResponse(false)
      setButtonContent('Device command successful')
      setButtonClass('txtSuccessColor')
    } else {
      setWaitingResponse(false)
      setButtonClass('txtErrorColor')
      setButtonContent('Device command unsuccessful')
    }
  } catch (_) {
    setWaitingResponse(false)
    setButtonClass('txtErrorColor')
    setButtonContent('Device command unsuccessful')
  }
}

export function* triggerLogActionCommand(api, { payload, setButtonContent, setButtonClass, setWaitingResponse }) {
  try {
    setWaitingResponse(true)
    const response = yield call(api.sendLogActionCommand, payload)
    if (response.ok) {
      setWaitingResponse(false)
      setButtonContent('Device command successful')
      setButtonClass('txtSuccessColor')
    } else {
      setWaitingResponse(false)
      setButtonClass('txtErrorColor')
      setButtonContent('Device command unsuccessful')
    }
  } catch (_) {
    setWaitingResponse(false)
    setButtonClass('txtErrorColor')
    setButtonContent('Device command unsuccessful')
  }
}

export function* pullDeviceLogs(api, { payload, setWaitingResponse, t, closeModal }) {
  try {
    setWaitingResponse(true)
    const response = yield call(api.pullDeviceLogs, payload)
    if (response.ok) {
      setWaitingResponse(false)
      toast(<SuccessToast message={t('PullLogsSuccess')} />)
      closeModal()
    } else {
      setWaitingResponse(false)
      toast(<ErrorToast message={response.data} />)
      closeModal()
    }
  } catch (_) {
    setWaitingResponse(false)
    toast(<ErrorToast message={t('PullLogsFailure')} />)
    closeModal()
  }
}

export function* getLatestTelemetry (api, { serialNumber, pointName, setWaitingResponse, setTelemetryDate, currentCulture, t }) {
  try {
    setWaitingResponse(true)
    const params = {
      serialNumber: serialNumber,
      pointName: pointName
    }
    const response = yield call(api.getLatestTelemetry, params)
    if (response.ok) {
      setWaitingResponse(false)
      setTelemetryDate( response.data.timestamp != null ? momentTimeYearHour(response.data.timestamp, currentCulture.slice(0, 2)) : t('notAvailable'))
    } else {
      setWaitingResponse(false)
      setTelemetryDate(t('notAvailable'))
    }
  } catch (_) {
    setWaitingResponse(false)
    setTelemetryDate(t('notAvailable'))
  }
}

export function* getLastHeartbeat (api, { serialNumber, setWaitingResponse, setHeartbeat, currentCulture, t }) {
  try {
    setWaitingResponse(true)
    const response = yield call(api.getLastHeartbeat, serialNumber)
    if (response.ok) {
      setWaitingResponse(false)
      setHeartbeat(response.data.lastHeartBeat != null ? momentTimeYearHour(response.data.lastHeartBeat, currentCulture.slice(0, 2)) : t('notAvailable'))
    } else {
      setWaitingResponse(false)
      setHeartbeat(t('notAvailable'))
    }
  } catch (_) {
    setWaitingResponse(false)
    setHeartbeat(t('notAvailable'))
  }
}
export function* refreshConnectionStatus(api, { serialNumber, t}) {
  const response = yield call(api.refreshConnectionStatus, serialNumber)
  try {
    if (response.ok) {
      if(response.data.isOnline){
        yield put(Device.setStatusConnection('connected', serialNumber))
        toast(<SuccessToast message={t('Connection status refreshed')} />)
      } else {
        yield put(Device.setStatusConnection('disconnected', serialNumber))
        toast(<SuccessToast message={t('Connection status refreshed')} />)
      }   
    } 
  } catch (_) {
    yield put(Device.setStatusConnection(null, serialNumber))
    toast(<ErrorToast message={t('Connection status refreshed')} />)
  }
}
