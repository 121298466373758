import Immutable from 'seamless-immutable'
import { createSelector, createStructuredSelector } from 'reselect'
import moment from 'moment'
import { isEmpty } from 'ramda'

export const sites = state => state.siteManager.sites
export const sitesById = state => state.siteManager.sitesById
export const currentSite = state => state.siteManager.currentSite
export const parentSite = state => state.siteManager.parentSite
export const loading = state => state.siteManager.loading
export const loadingUsers = state => state.siteManager.loadingUsers
export const loadingDevices = state => state.siteManager.loadingDevices
export const currentUsers = state => state.siteManager.currentUsers
export const currentUser = state => state.siteManager.currentUser
export const currentDevices = state => state.siteManager.currentDevices
export const rootSites = state => state.siteManager.rootSites
export const error = state => state.siteManager.error
export const errorDevices = state => state.siteManager.errorDevices
export const errorUsers = state => state.siteManager.errorUsers
export const rootSitesError = state => state.siteManager.rootSitesError
export const rootSitesById = state => state.siteManager.rootSitesById
export const formData = state => state.form.siteForm
export const selectedSites = state => state.siteManager.selectedSites

export const isHoneywellAdmin = state => state.user.isHoneywellAdmin
export const isAdmin = state => state.user.isAdmin

export const getCurrentSite = createSelector(
  [sites, currentSite],
  (allSites, siteId) => {
    const site = allSites.getIn([siteId])
    if (!allSites || !site) {
      return Immutable({})
    }
    return Immutable(site)
  }
)

export const cleanCurrentSite = createSelector(
  [getCurrentSite],
  (current) => {
    if (isEmpty(current)) {
      return Immutable({})
    }
    let cleanSite = {
      ...current.getIn(['contactInformation']),
      ...current.getIn(['organizationalUnitIdentifier']),
      ...current.getIn(['organizationalUnitDetails']),
      ...current.without(['contactInformation', 'organizationalUnitIdentifier', 'organizationalUnitDetails'])
    }

    const address = current.getIn(['contactInformation', 'addresses', 0])
    if (address) {
      cleanSite['address'] = address
    }

    const hierarchy = current.getIn(['organizationalUnitHierarchy'])
    if (hierarchy) {
      const hierarchyArray = hierarchy.split('/')

      if (hierarchyArray.length > 0 && (hierarchyArray[0] === ' ' || !hierarchyArray[0])) {
        hierarchyArray.shift()
        cleanSite['hierarchy'] = hierarchyArray
      }

      cleanSite['hierarchy'] = hierarchyArray
    }

    return Immutable(cleanSite)
  }
)

export const cleanParentSite = createSelector(
  [sites, parentSite],
  (allSites, site) => {
    const current = allSites.getIn([site])
    if (isEmpty(current) || !current) {
      return Immutable({})
    }
    const cleanSite = {
      ...current.getIn(['organizationalUnitIdentifier']),
      ...current.without(['contactInformation', 'organizationalUnitIdentifier', 'organizationalUnitDetails'])
    }

    return Immutable(cleanSite)
  }
)

export const cleanSiteUsers = createSelector(
  [currentUsers],
  (users) => {
    if (isEmpty(users) || !users) {
      return Immutable([])
    }
    const cleanUsers = users.map(user => ({
      id: user.id,
      userName: user.getIn(['personIdentifier', 'loginEmailAddress']),
      firstName: user.getIn(['personDetails', 'firstName']),
      lastName: user.getIn(['personDetails', 'lastName'])
    }))

    return Immutable(cleanUsers)
  }
)

export const cleanSiteDevices = createSelector(
  [currentDevices],
  (devices) => {
    if (isEmpty(devices) || !devices) {
      return Immutable([])
    }
    const cleanDevices = devices.map(device => ({
      ...device,
      id: device.getIn(['deviceIdentifier', 'deviceGuid']),
      name: device.getIn(['deviceDetail', 'deviceName']),
      batterySerialNumber: device.getIn(['deviceDetail', 'batterySerialNumber']),
      model: device.getIn(['deviceDetail', 'deviceModel']),
      firmwareVersion: device.getIn(['deviceConfiguration', 'firmwareDetails', 'firmwareVersion']),
      securityPatchLevel: device.getIn(['deviceConfiguration', 'securityPatches', 'securityPatchLevel']),
      status: device.getIn(['status', 'status'], 'unknown'),
      lastOnline: device.getIn(['status', 'lastOnline'], new Date())
    }))

    return Immutable(cleanDevices)
  }
)

export const sitesSearchStruct = createSelector(
  [sitesById],
  (sites) => {
    return Immutable(sites.map(s => ({ title: s.name, id: s.guid })))
  }
)

export const sitesByHierarchy = createSelector(
  [sitesById],
  (sites) => {
    return Immutable(sites.reduce((acc, s) => ([
      ...acc, { text: s.name, value: s.name, key: s.name }]),
    [{ text: 'UNSELECT SITE FILTER', value: '', key: '' }]))
  }
)

export const cleanRootSites = createSelector(
  [rootSites],
  (sites) => {
    if (!sites || isEmpty(sites)) {
      return Immutable({})
    }
    const orderedSites = sites.reduce((acc, s) => {
      const parent = s.getIn(['parentIdentifier'])
      const parentId = s.getIn(['parentIdentifier', 'organizationalUnitGuid'])
      const id = s.getIn(['organizationalUnitIdentifier', 'organizationalUnitGuid'])
      const name = s.getIn(['organizationalUnitIdentifier', 'organizationalUnitName'])

      if (parentId || acc[id]) {
        acc[parentId] = {
          title: acc[parentId] ? acc[parentId]['title'] : parent['organizationalUnitName'],
          id: acc[parentId] ? acc[parentId]['id'] : parent['organizationalUnitGuid'],
          sites: [
            ...(acc[parentId] ? acc[parentId]['sites'].sort((a, b) => (a.title && a.title.localeCompare(b.title))) : []),
            {
              title: s.getIn(['organizationalUnitHierarchy']),
              id: s.getIn(['organizationalUnitIdentifier', 'organizationalUnitGuid'])
            }
          ]
        }
      } else {
        acc[id] = {
          title: name,
          id: id,
          sites: []
        }
      }
      return acc
    }, {})
    return Immutable(orderedSites)
  }
)

export const locationResultReducer = ({ locations }) => locations.getIn(['result'], [])

export const locationLoading = ({ locations }) => locations.loading

export const locationCount = createSelector(
  locationResultReducer,
  locations => locations.length
)

export const SiteFormSelector = createStructuredSelector({
  initialValues: getCurrentSite,
  sites: sitesSearchStruct,
  parentSite: cleanParentSite,
  formData,
  loading,
  isHoneywellAdmin
})

export default createStructuredSelector({
  sitesById,
  loading,
  loadingUsers,
  loadingDevices,
  currentUser,
  currentSite: cleanCurrentSite,
  currentUsers: cleanSiteUsers,
  currentDevices: cleanSiteDevices,
  parentSite: cleanParentSite,
  sitesByHierarchy,
  error,
  errorDevices,
  errorUsers,
  locationLoading,
  locationCount,
  isHoneywellAdmin,
  isAdmin
})
