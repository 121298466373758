import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import UserSettingsActions from '../../Redux/UserSettingsRedux'
import { Accordion, Button } from '@scuf/common'
import {
  ModalWrap,
  ModalContainer,
  HeaderWrap,
  StyledH2,
  Paragraph,
  StyledInput,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap,
  StyledCheckbox,
  StyledAccordion,
  AccordionWrapper,
  ResetContainer,
  Reset
} from './dashboard-preferences.styles'
import PropTypes from 'prop-types'
import { Dashboard } from '@scuf/dashboard'
import orderBy from 'lodash/orderBy'
import isEqual from 'lodash/isEqual'
import { useTranslate } from 'react-translate'

const DashboardAccordion = ({
  title,
  layout,
  dictionary,
  sectionKey,
  accordionClick,
  acordionIsActive,
  changeSectionVisibility,
  changeAllCardsVisibility,
  layoutChange,
  secondLayout,
  isSectionVisible,
}) => {
  useEffect(() => {
    changeSectionVisibility(sectionKey, isSectionVisible)
  }, [isSectionVisible])
  const t = useTranslate('DashboardPreferences');

  return (
    <AccordionWrapper>
      <StyledCheckbox
        checked={isSectionVisible}
        defaultChecked={isSectionVisible}
        onChange={(checked) => changeAllCardsVisibility(sectionKey, checked)}
      />
      <StyledAccordion>
        <Accordion.Content
          title={t(`${title}`)}
          active={acordionIsActive}
          onClick={() => accordionClick(sectionKey, !acordionIsActive)}
        >
          <Dashboard
            dictionary={dictionary}
            cols={{ lg: 6, md: 8, sm: 4, xs: 2, xxs: 1 }}
            layout={layout}
            onLayoutChange={(change) => {
              if (!isEqual(change, secondLayout)) {
                layoutChange(orderBy(change, ['y'], ['asc']).map((obj, index) => ({ name: obj.i, index })))
              }
            }}
          />
        </Accordion.Content>
      </StyledAccordion>
    </AccordionWrapper>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    accordionClick: (sectionKey, isActive) => dispatch(UserSettingsActions.accordionClick(sectionKey, isActive)),
    changeSectionVisibility: (sectionKey, isSectionVisible) => dispatch(UserSettingsActions.changeSectionVisibility(sectionKey, isSectionVisible)),
    layoutChange: (cards) => dispatch(UserSettingsActions.layoutChange(cards)),
    changeAllCardsVisibility: (sectionKey, isSectionVisible) => dispatch(UserSettingsActions.changeAllCardsVisibility(sectionKey, isSectionVisible))
  }
}

export const CustomAccordion = connect(null, mapDispatchToProps)(DashboardAccordion)

const DashboardPreferences = ({
  assignFlag,
  layout,
  dictionary,
  closeModal,
  roleId,
  submitAction,
  changeDashboardTitle,
  selectorDashboardTitle,
  isMyDashboard,
  resetDashboardToDefaultState,
  t,
  theme
}) => {
  const [dashboardTitle, setDashboardTitle] = useState(selectorDashboardTitle)
  const [errorTitle, setErrorTitle] = useState(null)
  useEffect(() => {
    if (isMyDashboard) {
      setDashboardTitle('My Dashboard')
    }
  }, [])
  return (
    <ModalWrap theme={theme}>
      <ModalHeader>
        <HeaderWrap>
          <StyledH2>{t('CustomizeDashboard')}</StyledH2>
          {
            !isMyDashboard && (
              <StyledInput
                value={dashboardTitle}
                error={errorTitle}
                placeholder={t('DashboardTitle')}
                label={t('DashboardTitle')}
                indicator='required'
                disabled={isMyDashboard}
                onChange={(value) => {
                  setDashboardTitle(value)
                }}
              />
            )
          }
          <Paragraph>
            {t('Description')}
          </Paragraph>
          {
            isMyDashboard && (
              <ResetContainer>
                <Reset
                  onClick={() => resetDashboardToDefaultState()}
                >
                  {t('Reset')}
                </Reset>
              </ResetContainer>
            )
          }
        </HeaderWrap>
      </ModalHeader>
      <ModalContainer>
        <Dashboard dictionary={dictionary} layout={layout} />
      </ModalContainer>
      <ModalFooter>
        <FooterWrap>
          <ButtonWrap>
            <Button type='secondary' size='medium' content={t('Cancel')} onClick={closeModal} />
            <Button type='primary' size='medium' content={t('Submit')} onClick={() => {
              if (dashboardTitle !== '') {
                if (isMyDashboard || (!isMyDashboard && dashboardTitle !== 'My Dashboard')) {
                  changeDashboardTitle(dashboardTitle)
                  submitAction(roleId, assignFlag)
                  closeModal()
                }
                if (!isMyDashboard && dashboardTitle === 'My Dashboard') {
                  setErrorTitle(t('MyDashboardError'))
                }

              } else {
                setErrorTitle(t('Error'))
              }
            }} />
          </ButtonWrap>
        </FooterWrap>
      </ModalFooter>
    </ModalWrap>
  )
}

DashboardAccordion.propTypes = {
  title: PropTypes.string,
  layout: PropTypes.array,
  dictionary: PropTypes.object,
  sectionKey: PropTypes.string,
  accordionClick: PropTypes.func,
  layoutChange: PropTypes.func,
  secondLayout: PropTypes.array,
  acordionIsActive: PropTypes.bool,
  isSectionVisible: PropTypes.bool,
  changeSectionVisibility: PropTypes.array
}

DashboardAccordion.defaultProps = {
  changeSectionVisibility: PropTypes.func,
  closeModal: PropTypes.func,
  dictionary: {},
  isSectionVisible: true,
  layout: [],
  secondLayout: [],
  title: ''
}

DashboardPreferences.propTypes = {
  assignFlag: PropTypes.bool,
  layout: PropTypes.array,
  dictionary: PropTypes.object,
  closeModal: PropTypes.func,
  changeDashboardTitle: PropTypes.func,
  submitAction: PropTypes.func,
  selectorDashboardTitle: PropTypes.string,
  roleId: PropTypes.string,
  t: PropTypes.func
}

DashboardPreferences.defaultProps = {
  assignFlag: true,
  changeDashboardTitle: () => {},
  closeModal: () => {},
  dictionary: {},
  layout: [],
  roleId: '',
  selectorDashboardTitle: '',
  submitAction: () => {},
  t: () => {}
}

export default DashboardPreferences
