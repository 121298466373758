
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import mapStateToProps from './manage-tags-modal.selector'
import ManageTagsModal from './manage-tags-modal.component'
import { TaggingActionCreators, TaggingActionTypes } from 'Store/tagging'

export const mapDispatchToProps = dispatch => {
  return {
    getTags: (devices) => dispatch(TaggingActionCreators.devicesTagsRequest(devices)),
    updateTags: tags => dispatch(TaggingActionCreators.updateTagsRequest(tags))
  }
}

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('ManageTagsModal')
)(ManageTagsModal)
