import React from 'react'
import { Modal, Icon } from '@scuf/common'
import styled from 'styled-components'
import { ModalWrap, ModalTitle } from './remote-access-in-progress-modal.styles'
import config from '../../Config/AppConfig'
import PropTypes from 'prop-types'

export const Container = styled.div`
  height: calc(95vh - 5vh);
  overflow-y: auto;
  overflow-x: hidden;
`

const RemoteAccessInProgressModal = ({ closeModal, t }) => {
  return (
    <ModalWrap>
      <ModalTitle>
        <Icon color='yellow' name='badge-warning' size='large' /> {t('Remote_Access')}
      </ModalTitle>
      <Modal.Content>
        {t('RemoteAccess_InProgressMsg')}
      </Modal.Content>
    </ModalWrap>
  )
}

RemoteAccessInProgressModal.defaultProps = {
  closeModal: () => null
}

RemoteAccessInProgressModal.propTypes = {
  closeModal: PropTypes.func,
  t: PropTypes.func
}

export default RemoteAccessInProgressModal
