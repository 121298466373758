export const percentageMonthlyGrowth = (measurements) => {
  const reverted = [...measurements].reverse()
  const [latest] = reverted
  const [, penultimate] = reverted
  const percentage = penultimate ? ((latest - penultimate) / penultimate) * 100 : 0
  return penultimate ? (percentage > 0 ? '+' : '') + numberToFixed(percentage, 1) : '-'
}

export const numberToFixed = (number, precision) => {
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export const kFormatter = (num) => {
  return num > 999 ? (num / 1000).toFixed(0) + 'k' : num
}

export const averageNum = (days) => {
  const average = days.reduce((sum, day) => sum + day, 0) / (days.length || 1)
  return Math.round(average * 10) / 10
}