import React, { useCallback, useEffect, useState, useRef } from 'react';
import { BeaconsApi } from '../../../../Services';
import { SuccessToast, ErrorToast, ImportantToast } from '../../../../Themes/ScufStyledComponents';
import { useDebounce } from 'Components/AssetManagementBase/components/assets-table/assets-table.hooks';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import { downloadHelper } from 'Utils/DownloadHelper';
import { StyledLink } from './access-points.styles';

import { ACCESS_POINT } from '../../../floor-plans/beacon/beacon.constants';

export function useAccessPoints() {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [currentSiteId, setCurrentSiteId] = useState(null);
  const [selectedRows, setSelectedRows] = useState();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const search = useDebounce(searchTerm, 500);
  const [totalItems, setTotalItems] = useState(null);
  const previousSearch = useRef(undefined);
  const pageSize = 100;

  const t = useTranslate('AccessPoints');

  const getAccessPoints = useCallback(
    async (siteId) => {
      let response;
      setCurrentSiteId(siteId);
      if (siteId) {
        setDataLoading(true);
        if (previousSearch.current !== search || data.length < 1 || page === 1) {
          response = await BeaconsApi.listBeacons('siteId', [siteId], ACCESS_POINT, pageSize, 1, search);
          if (response.ok) {
            previousSearch.current = search;
            setTotalItems(response.data.count);
            setData(response.data.data);
          }
        } else if (page > 1 && data.length > 0) {
          response = await BeaconsApi.listBeacons('siteId', [siteId], ACCESS_POINT, pageSize, page, search);
          if (response.ok) {
            setTotalItems(response.data.count);
            setData((data) => [...data, ...response.data.data]);
          }
        } else {
          toast(<ErrorToast message={t('ErrorLoad')} />);
          setData([]);
        }
        setDataLoading(false);
      }
    },
    [setCurrentSiteId, setDataLoading, setData, search, page, setTotalItems]
  );

  const getAccessPointsFull = useCallback(
    async (siteId, skipSelectedCheck = true) => {
      if (siteId && skipSelectedCheck) {
        const response = await BeaconsApi.listBeacons('siteId', [siteId], ACCESS_POINT, 0, 0, search);
        if (response.ok) {
          return response.data.data;
        }
      } else {
        const response = await BeaconsApi.listBeacons('siteId', [siteId], ACCESS_POINT, 0, 0);
        if (response.ok) {
          return response.data.data;
        }
      }
      toast(<ErrorToast message={t('ErrorLoad')} />);
    },
    [currentSiteId, search]
  );

  const importAccessPoints = useCallback(
    async (file) => {
      setDataLoading(true);
      const response = await BeaconsApi.bulkCreateBeacons(currentSiteId, file);
      if (response.ok && response.status === 200) {
        toast(<SuccessToast message={t('ImportSuccess', { num: Object.keys(response.data.data).length })} />);
        const newAPs = response.data.data.map((ap) => ({
          ...ap,
          bssid: ap.wifi.bssid,
          ssid: ap.wifi.ssid,
        }));
        setData((data) => [...data, ...newAPs]);
      } else if (response.ok && response.status === 207) {
        toast(
          <ImportantToast
            color="#ffC627"
            message={
              <div>
                {t('MixedSuccess')}
                <StyledLink
                  onClick={() =>
                    downloadHelper('import-errors', JSON.stringify(response.data, null, '\t'), 'json', '.json')
                  }
                >
                  {t('ErrorList')}
                </StyledLink>
              </div>
            }
          />
        );
      } else if (response.data.errors && response.data.errors.length > 5) {
        toast(
          <ErrorToast
            message={
              <div>
                {t('ManyErrors')}
                <StyledLink
                  onClick={() =>
                    downloadHelper('import-errors', JSON.stringify(response.data, null, '\t'), 'json', '.json')
                  }
                >
                  {t('ErrorList')}
                </StyledLink>
              </div>
            }
          />
        );
      } else if (response.data.errors && response.data.errors.length > 0) {
        response.data.errors.forEach((err) => {
          if (err.error === 'MissingColumns') {
            if (err.rowNumber) {
              toast(
                <ErrorToast
                  message={t('MissingColumns', {
                    rowNum: err.rowNumber,
                    columns: err.affectedColumns.join(', '),
                  })}
                />
              );
            } else {
              toast(<ErrorToast message={t('MissingColumnsNoRow', { columns: err.affectedColumns.join(', ') })} />);
            }
          } else if (err.error === 'DuplicateEntry') {
            if (err.rowNumber) {
              toast(<ErrorToast message={t('DuplicateEntry', { rowNum: err.rowNumber })} />);
            } else {
              toast(<ErrorToast message={t('DuplicateNoRow', { details: err.details })} />);
            }
          } else if (err.error === 'DuplicateFile') {
            toast(<ErrorToast message={t('DuplicateFile')} />);
          } else if (err.error === 'DuplicateBssidSsid') {
            toast(<ErrorToast message={t('DuplicateBssidSsid', { rowNum: err.rowNumber })} />);
          } else if (err.error === 'EmptyCSV') {
            toast(<ErrorToast message={t('EmptyCSV')} />);
          } else {
            toast(<ErrorToast message={t('ImportFailure')} />);
          }
        });
      }
      setDataLoading(false);
      return true;
    },
    [setDataLoading, currentSiteId, setData]
  );

  const deleteAccessPoints = useCallback(
    async (accessPoints) => {
      const ids = [];
      accessPoints.map((y) => {
        ids.push(y.id);
      });
      if (ids.length === 0) {
        toast(<ErrorToast message={t('NoAPs')} />);
      }
      setDataLoading(true);
      const response = await BeaconsApi.bulkDeleteBeacons('ids', ids);
      if (response.ok) {
        toast(<SuccessToast message={t('DeleteSuccess', { num: ids.length })} />);
        setPage(1);
        getAccessPoints(currentSiteId);
      } else {
        toast(<ErrorToast message={t('DeleteFailure')} />);
      }
      setDataLoading(false);
      setSelectedRows();
    },
    [setDataLoading, getAccessPoints, currentSiteId]
  );

  return {
    currentSiteId,
    data,
    dataLoading,
    getAccessPoints,
    importAccessPoints,
    deleteAccessPoints,
    selectedRows,
    setSelectedRows,
    setSearchTerm,
    setDataLoading,
    setPage,
    getAccessPointsFull,
    totalItems,
  };
}

