import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@scuf/common'
import { get } from 'lodash'
import {
  Form,
  Row,
  Padding
} from './modal.styles'
import Input from 'Components/Shared/formiks/input'
import TextArea from 'Components/Shared/formiks/text-area'
import { StyledModal } from '../../../../Components/Modal/modal.styles'
import { useFormikContext, useField } from 'formik'

const BaseModal = ({
  isOpen,
  t,
  handleSubmit,
  closeModal,
  resetForm
}) => {
  const [mask, setMask] = useState([])
  const { setFieldValue, values } = useFormikContext()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [firstIpAddress, metaFirstIpAddress] = useField('firstIpAddress')
  const [lastIpAddress, metaLastIpAddress] = useField('lastIpAddress')
  const [networkName, metaNetworkName] = useField('networkName')

  const onCloseModal = () => {
    resetForm()
    setMask([])
    closeModal()
  }

  useEffect(() => {
    if (
      get(values, 'firstIpAddress', '') !== '' &&
      get(values, 'lastIpAddress', '') !== '' &&
      get(values, 'firstIpAddress', '').length >= 3  &&
      metaFirstIpAddress.error === undefined &&
      metaLastIpAddress.error === undefined &&
      metaNetworkName.error === undefined 
    )
    {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false)
    }
  }, [values, metaFirstIpAddress, metaLastIpAddress, metaNetworkName])

  useEffect(() => {
    setFieldValue('mask', mask)
  }, [mask])
  return (
    <StyledModal
      closeIcon
      onClose={onCloseModal}
      open={isOpen}
      size='small'
    >
      <StyledModal.Header>
        {t('Network Range')}
      </StyledModal.Header>
      <Form onSubmit={handleSubmit}>
        <StyledModal.Content>
          <Row>
            <Input
              fluid
              indicator='required'
              label={t('StartIP')}
              name='firstIpAddress'
              placeholder="Enter Start IP Address" 
              setMask={setMask}
            />
          </Row>
          <Row>
            <Input
              fluid
              indicator='required'
              label={t('EndIP')}
              name='lastIpAddress'
              placeholder="Enter End IP Address" 
              setMask={setMask}
            />
          </Row>
          <Row>
            <TextArea
              fluid={true}
              label={t('Description')}
              name='networkName'
              placeholder="Enter Description" 
              setMask={setMask}
            />
          </Row>
        </StyledModal.Content>
        <StyledModal.Footer>
          <Button
            content={t('Cancel')}
            onClick={onCloseModal}
            type='secondary'
          />
          <Button
            actionType='submit'
            content={t('Button')}
            disabled={!isButtonEnabled}
            type='primary'
          />
        </StyledModal.Footer>
      </Form>
    </StyledModal>
  )
}

BaseModal.propTypes = {
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func
}

BaseModal.defaultProps = {
  t: l => l
}

export default BaseModal
