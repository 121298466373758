import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import { selectedTagsWithDevices } from 'Components/ManageTagsModal/manage-tags-modal.selector'
import RemoveTags from './remove-tags.component'
import { TaggingActionCreators } from 'Store/tagging'

export const mapDispatchToProps = dispatch => {
  return {
    getTags: (devices) => dispatch(TaggingActionCreators.devicesTagsRequest(devices)),
  }
}
export const mapStateToProps = state => {
  return {
    assetsWithTags: selectedTagsWithDevices(state)
  }
}

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('RemoveTags')
)(RemoveTags)
