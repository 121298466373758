import { useMemo } from 'react'
import { createClient, Provider, defaultExchanges } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import config from 'Config/AppConfig';
import { useApiHeaders } from 'Utils/authentication-headers.hook';


export const withGraphQLProvider = Component => props => {
  const headers = useApiHeaders()
  const client = useMemo(() => createClient({
    url: config.api.connectedaidc + '/data_services/v1/graphql',
    exchanges: [devtoolsExchange, ...defaultExchanges],
    fetchOptions: () => {
      return {
        headers
      }
    }
  }), [headers])
  return (
    <Provider value={client}>
      <Component {...props}/>
    </Provider>
  )
}