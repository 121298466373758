import { createSelector, createStructuredSelector } from 'reselect'

export const submitAction = ({ modal }) => modal.getIn(['modalProps', 'submitAction'], () => {})
export const assignFlag = ({ modal }) => modal.getIn(['modalProps', 'assignFlag'], true)
export const roleId = ({ modal }) => modal.getIn(['modalProps', 'roleId'], () => {})
export const selection = ({ modal }) => modal.getIn(['modalProps', 'selected'], false)
export const getAlertsUrl = ({ modal }) => modal.getIn(['modalProps', 'getAlertsFilters'], () => { })
export const PayloadDelete = ({ modal }) => modal.getIn(['modalProps', 'PayloadDelete'], false)
export const theme = ({ user }) => user.getIn(['theme'], {})
export const loading = ({ devices }) => devices.getIn(['loading'])
export const loadingAlert = ({ alerts }) => alerts.getIn(['loading'])

export default createStructuredSelector({
  submitAction,
  roleId,
  assignFlag,
  theme,
  selection,
  loading,
  getAlertsUrl,
  loadingAlert,
  PayloadDelete
})
