import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  updatesRequestList: ['ids'],
  updatesUpdateRequestList: ['ids', 'deviceType', 'models', 'assetTypes'],
  updatesCleanRequestList: [],
  updatesSuccessList: ['updates'],
  updatesFailureList: null,
  updatesRequestAssets: null,
  updatesSuccessAssets: ['updates'],
  updatesFailureAssets: null,
  updatesSelectUpdates: ['selectedUpdates', 'handleCancel'],
  updatesSetHoneywellUpdate: ['update'],
  updatesSelected: ['selected'],
  updatesSetEditMode: ['editable'],
  updatesRequestUpdate: ['scheduleTime'],
  updatesSuccessUpdate: null,
  updatesFailureUpdate: null,
  updatesTabSelection: ['tab'],
  updatesSchedulesRequest: ['userIds', 'lastTimestamp'],
  updatesDeletePackageRequest: ['ids'],
  updatesSuccessDeletePackage: ['ids'],
  updatesSchedulesSuccess: ['schedules', 'nextTimestamp'],
  updatesSchedulesSuccessAppend: ['data', 'nextTimestamp'],
  updatesSchedulesFailure: ['error'],
  updatesCancelRequest: ['task'],
  updatesUploadAssetRequest: ['isHash256', 'data', 'config', 'isHoneywellUpdate'],
  updatesEditPackageRequest: ['data', 'id'],
  updatesSuccessEditPackage: ['data', 'id'],
  updatesUploadAssetSuccess: null,
  updatesUploadAssetFailure: ['error'],
  updatesUploadSetPercent: ['percent'],
  updatesPrinterTabSelection: ['printerTab'],
  updatesSelectAssets: ['selectedUpdates'],
  updatesPrinterProvisionRequest: ['task'],
  updatesSelectUpdatesSuccess: ['id', 'data'],
  loadDevicesForAssets: ['selectedUpdates'],
  updatesUpdateAllRequest: ['data'],
  updatesUpdateSelectedRequest: ['data'],
  updatesDevicesToSchedule: ['devices'],
  updatesCalculateHashRequest: ['file', 'isHash256'],
  updatesCalculateHashSuccess: ['fileHash'],
  updatesCalculateHashFailure: null,
  updatesSetLoading: ['loading'],
  updatesSelectSchedule: ['guid'],
  updatesUpdateFromFileRequest: ['data', 'file'],
  updatesUpdateFromFileSuccess: null,
  updatesUpdateFromFileFailure: null,
})

export const UpdatesTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  updates: {},
  devices: [],
  loading: false,
  error: null,
  scheduleTime: null,
  selectedUpdates: [],
  devicesById: {},
  tab: 1,
  schedules: [],
  printerTab: 0,
  devicesPayload: {},
  percent: 0,
  fileHash: '',
  loadingHash: false,
  selectedSchedule: {}
})

/* ------------- Helpers ------------- */
export const devicesEntity = devicesArr => {
  const devices = devicesArr.reduce((obj, device) => {
    return {
      ...obj,
      [device.serialNumber]: device
    }
  }, {})
  return {
    devices,
    devicesById: Object.keys(devices),
    totalDevices: devicesArr.length
  }
}

/* ------------- Reducers ------------- */

export const returnState = state => state

export const updatesGeneralError = (state, { error }) => state.merge({
  loading: false,
  error
})

export const updatesRequestList = (state, { ids }) => state.merge({
  devices: ids,
  loading: true
})

export const updatesCleanRequestList = (state) => state.merge({
  devices: []
})

export const updatesSuccessList = (state, { updates }) => state.merge({
  updates,
  loading: false
})

export const updatesSuccessDeletePackage = (state, { ids }) => {
  return state
    .setIn(['updates', 'entities', 'distribution', ids], undefined)
    .merge({ loading: false })
}

export const updatesSuccessEditPackage = (state, { data, id }) => {
  let update = state
    .getIn(['updates', 'entities', 'distribution', id])

  update = update.merge({
    description: data.description,
    name: data.description,
    version: data.version === '' ? update.version : data.version,
    compatibleDeviceModels: data.compatibleDeviceModels,
    compatibleDevices: data.compatibleDeviceModels.join(', '),
    compatibleVersions: data.compatibleVersions
  })

  return state
    .setIn(['updates', 'entities', 'distribution', id], update)
    .merge({ loading: false })
}

export const updatesRequestAssets = state => state.merge({
  loading: true
})

export const updatesRequestUpdate = (state, { scheduleTime }) => state.merge({
  scheduleTime,
  loading: true
})

export const updatesSelectAssets = (state, { selectedUpdates }) => state.merge({ selectedUpdates })

export const updatesSelectUpdates = (state, { selectedUpdates }) => state.merge({ selectedUpdates })

export const updatesSelected = (state, { selected }) => state.merge({ selected })

export const updatesSetEditMode = (state, { editable }) => state.merge({ editable })

export const loadDevicesForAssets = (state, { selectedUpdates }) => state.merge({ selectedUpdates, loading: true })

export const updatesTabSelection = (state, { tab }) => state.merge({ tab })

export const updatesPrinterTabSelection = (state, { printerTab }) => state.merge({ printerTab })

export const updatesSchedulesSuccess = (state, { schedules, nextTimestamp }) => {
  return state.merge({ schedules, nextPageTimestamp: nextTimestamp, loading: false })
}

export const updatesSchedulesSuccessAppend = (state, { data, nextTimestamp }) => {
  return state
    .updateIn(['schedules'],
      (previousSchedules, schedules) => [...previousSchedules, ...schedules]
      , data)
    .merge({ nextPageTimestamp: nextTimestamp, loading: false })
}

export const updatesUploadAssetRequest = state => state.merge({ loading: true, percent: 0 })
export const updatesUploadAssetSuccess = state => state.merge({
  loading: false,
  percent: 100
})
export const updatesUploadAssetFailure = (state, { error }) => state.merge({ loading: false, percent: 0, error })

export const updatesUploadSetPercent = (state, { percent }) => state.merge({ percent })

export const updatesSelectUpdatesSuccess = (state, { data, id }) => {
  const { devicesById, ...devices } = devicesEntity(data.devices)
  return state.setIn(['devicesPayload', id], devices)
    .setIn(['devicesById', id], devicesById)
    .merge({ loading: false })
}

export const updatesUpdateAllRequest = state => state.merge({
  loading: true
})

export const updatesUpdateFromFileRequest = state => state.merge({ loading: true })

export const updatesUpdateFromFileSuccess = state => state.merge({ loading: false })

export const updatesUpdateFromFileFailure = state => state.merge({ loading: false })

export const updatesDevicesToSchedule = (state, { devices }) => state.merge({ devices })

export const updatesCalculateHashRequest = state => state.merge({ loadingHash: true })

export const updatesCalculateHashSuccess = (state, { fileHash }) => state.merge({ loadingHash: false, fileHash })

export const updatesCalculateHashFailure = state => state.merge({ loadingHash: false, error: 'CHECKSUM_ERROR' })

export const updatesSetLoading = (state, { loading }) => state.merge({ loading })

export const updatesSelectSchedule = (state, { guid }) => state.merge({ selectedSchedule: guid })

export const updatesOpenHoneywellUpdate = (state, { update }) => state
  .setIn(['updates', 'entities', 'distribution', update.id], update)
  .merge({ selectedUpdates: [update.id] })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATES_REQUEST_LIST]: updatesRequestList,
  [Types.UPDATES_SET_HONEYWELL_UPDATE]: updatesOpenHoneywellUpdate,
  [Types.UPDATES_UPDATE_REQUEST_LIST]: updatesRequestList,
  [Types.UPDATES_CLEAN_REQUEST_LIST]: updatesCleanRequestList,
  [Types.UPDATES_SUCCESS_LIST]: updatesSuccessList,
  [Types.UPDATES_FAILURE_LIST]: updatesGeneralError,
  [Types.UPDATES_REQUEST_ASSETS]: updatesRequestAssets,
  [Types.UPDATES_SUCCESS_ASSETS]: updatesSuccessList,
  [Types.UPDATES_FAILURE_ASSETS]: returnState,
  [Types.UPDATES_SELECT_UPDATES]: updatesSelectUpdates,
  [Types.UPDATES_SELECTED]: updatesSelected,
  [Types.UPDATES_SET_EDIT_MODE]: updatesSetEditMode,
  [Types.UPDATES_REQUEST_UPDATE]: updatesRequestUpdate,
  [Types.UPDATES_SUCCESS_ASSETS]: updatesSuccessList,
  [Types.UPDATES_FAILURE_ASSETS]: returnState,
  [Types.UPDATES_TAB_SELECTION]: updatesTabSelection,
  [Types.UPDATES_SCHEDULES_REQUEST]: updatesRequestAssets,
  [Types.UPDATES_SCHEDULES_SUCCESS]: updatesSchedulesSuccess,
  [Types.UPDATES_SCHEDULES_SUCCESS_APPEND]: updatesSchedulesSuccessAppend,
  [Types.UPDATES_SCHEDULES_FAILURE]: updatesGeneralError,
  [Types.UPDATES_CANCEL_REQUEST]: updatesRequestAssets,
  [Types.UPDATES_UPLOAD_ASSET_REQUEST]: updatesUploadAssetRequest,
  [Types.UPDATES_UPLOAD_ASSET_SUCCESS]: updatesUploadAssetSuccess,
  [Types.UPDATES_UPLOAD_ASSET_FAILURE]: updatesUploadAssetFailure,
  [Types.UPDATES_UPLOAD_SET_PERCENT]: updatesUploadSetPercent,
  [Types.UPDATES_PRINTER_TAB_SELECTION]: updatesPrinterTabSelection,
  [Types.UPDATES_SELECT_ASSETS]: updatesSelectAssets,
  [Types.UPDATES_PRINTER_PROVISION_REQUEST]: updatesRequestAssets,
  [Types.UPDATES_SELECT_UPDATES_SUCCESS]: updatesSelectUpdatesSuccess,
  [Types.LOAD_DEVICES_FOR_ASSETS]: loadDevicesForAssets,
  [Types.UPDATES_UPDATE_ALL_REQUEST]: updatesUpdateAllRequest,
  [Types.UPDATES_UPDATE_SELECTED_REQUEST]: updatesUpdateAllRequest,
  [Types.UPDATES_UPDATE_FROM_FILE_REQUEST]: updatesUpdateFromFileRequest,
  [Types.UPDATES_UPDATE_FROM_FILE_SUCCESS]: updatesUpdateFromFileSuccess,
  [Types.UPDATES_UPDATE_FROM_FILE_FAILURE]: updatesUpdateFromFileFailure,
  [Types.UPDATES_DEVICES_TO_SCHEDULE]: updatesDevicesToSchedule,
  [Types.UPDATES_CALCULATE_HASH_REQUEST]: updatesCalculateHashRequest,
  [Types.UPDATES_CALCULATE_HASH_SUCCESS]: updatesCalculateHashSuccess,
  [Types.UPDATES_CALCULATE_HASH_FAILURE]: updatesCalculateHashFailure,
  [Types.UPDATES_SET_LOADING]: updatesSetLoading,
  [Types.UPDATES_DELETE_PACKAGE_REQUEST]: updatesRequestAssets,
  [Types.UPDATES_SELECT_SCHEDULE]: updatesSelectSchedule,
  [Types.UPDATES_SUCCESS_DELETE_PACKAGE]: updatesSuccessDeletePackage,
  [Types.UPDATES_EDIT_PACKAGE_REQUEST]: updatesRequestAssets,
  [Types.UPDATES_SUCCESS_EDIT_PACKAGE]: updatesSuccessEditPackage
})
