import React, { useState, useReducer, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { downloadHelper } from 'Utils/DownloadHelper'

import {
  Button,
  FileDrop,
  Icon
} from '@scuf/common'
import { useDispatch } from 'react-redux'
import DataTable from 'Components/DataTable'
import { toast } from 'react-toastify'
import { isEqual } from 'lodash'

import {
  AccessContainer,
  ActionBarContainer,
  ActionBarSection,
  ActionContainer,
  ActionText,
  SimpleRow,
  StyledIcon,
  TableContainer,
  StyledLink,
  ButtonsContainer,
  FooterContainer,
  StyledLoader
} from './access-points.styles'
import { useAccessPoints } from './access-points.hooks'
import { Title, ErrorToast, SuccessToast } from '../../../../Themes/ScufStyledComponents'
import CreateAccessPoints from '../create-access-points'
import EditAccessPoints from '../edit-access-points'
import { AccessPointsActions } from '../../store'
import { StyledModal } from '../../../../Components/Modal/modal.styles'

const initialState = {
  isUploadModalOpen: false,
  isDeleteModalOpen: false,
  uploadModalName: '',
  deleteModalName: ''
}

function reducer (state, action) {
  switch (action.type) {
    case 'showUploadModal':
      return {
        ...state,
        isUploadModalOpen: true,
        uploadModalName: action.modal
      }
    case 'hideUploadModal':
      return {
        ...state,
        isUploadModalOpen: false,
        uploadModalName: action.modal
      }
    case 'showDeleteModal':
      return {
        ...state,
        isDeleteModalOpen: true,
        deleteModalName: action.modal
      }
    case 'hideDeleteModal':
      return {
        ...state,
        isDeleteModalOpen: false,
        deleteModalName: action.modal
      }

    default:
      return state
  }
}

const accessPointKeys = [
  { dataKey: 'name', label: 'ChartName', name: 'Name' },
  { dataKey: 'wifi', label: 'SSID', name: 'SSID', specialRender: 'ssid' },
  { dataKey: 'wifi', label: 'BSSID', name: 'BSSID', specialRender: 'bssid' },
  { dataKey: 'building', label: 'Building', name: 'Building' },
  { dataKey: 'floor', label: 'Floor', name: 'Floor' }
]

const AccessPoints = ({
  t,
  siteId,
  siteName,
  isEditModalVisible,
  permissions,
  modalError,
  isCreateSuccess,
  isEditSuccess
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [fileUpload, setFileUpload] = useState(null)
  const {
    data,
    dataLoading,
    getAccessPoints,
    importAccessPoints,
    deleteAccessPoints,
    selectedRows,
    setSelectedRows,
    id,
    setPage,
    setSearchTerm,
    setDataLoading,
    getAccessPointsFull,
    totalItems
  } = useAccessPoints()
  const reduxDispatch = useDispatch()
  const tableRef = useRef(null)

  React.useEffect(() => {
    getAccessPoints(siteId)
  }, [getAccessPoints, siteId])

  useEffect(() => {
    getAccessPointsFull(siteId)
  }, [getAccessPointsFull, siteId])

  useEffect(() => {
    setDataLoading(state.loading)
  }, [state.loading])

  React.useEffect(() => {
    if (modalError) {
      toast(<ErrorToast message={t(modalError)} />)
    } else if (isEditSuccess) {
      toast(<SuccessToast message={t('EditSuccess')} />)
    } else if (isCreateSuccess) {
      toast(<SuccessToast message={t('CreateSuccess')} />)
    }
  }, [modalError, isEditSuccess, isCreateSuccess])

  const exportCsv = async (siteId, skipSelectedCheck = false, bulkUploadModal = false) => {
    if (selectedRows?.length > 0 && !skipSelectedCheck && !bulkUploadModal) {
      return tableRef.current.downloadCSV &&
      tableRef.current.downloadCSV()
    }
    if (bulkUploadModal) {
      setDataLoading(true)
      const data = await getAccessPointsFull(siteId, false)
      downloadCSV(data)
      setDataLoading(false)
    } else {
      setDataLoading(true)
      const data = await getAccessPointsFull(siteId)
      downloadCSV(data)
      setDataLoading(false)
    }
  }

  const downloadCSV = (data) => {
    const columnName = accessPointKeys.map(headers => headers.name)
    const csv = Object.values(data).map(csvContent => accessPointKeys.map(({ dataKey, specialRender }) => {
      if (specialRender != null) {
        return csvContent[dataKey][specialRender]
      }
      return csvContent[dataKey]
    }).join(','))
    csv.splice(0, 0, columnName)
    downloadHelper(fileName, csv.join('\n'), 'csv')
  }

  const submitFile = async () => {
    if (fileUpload) {
      const response = await importAccessPoints(fileUpload)
      if (response) {
        setFileUpload(null)
        dispatch({ type: 'hideUploadModal', modal: 'bulkUploadModal' })
        getAccessPoints(siteId)
      }
    }
  }

  const openCreateAccessPointModal = () => {
    reduxDispatch(AccessPointsActions.showCreateAccessPointModal())
  }

  const openEditAccessPointModal = (accessPoint) => {
    reduxDispatch(AccessPointsActions.showEditAccessPointModal({ ...accessPoint, bssid: accessPoint.wifi.bssid, ssid: accessPoint.wifi.ssid }, true, permissions))
  }

  const onSubmitSuccess = siteId => () => {
    reduxDispatch(AccessPointsActions.closeModal())
    getAccessPoints(siteId)
    setSelectedRows()
  }

  const fileName = 'accessPoints-' + siteName

  const arraysSame = (array1, array2) => {
    return isEqual(array1, array2)
  }

  const checkSelectionChange = (rows) => {
    if (!arraysSame(rows, selectedRows)) {
      setSelectedRows(rows)
    }
  }

  const closeModalAction = () => dispatch({ type: 'hideDeleteModal', modal: 'bulkDeleteModal' })

  const exportCellData = (row, colProps) => {
    if (row[colProps.dataKey] instanceof Object) {
      if (colProps.specialRender !== undefined) {
        return '"' + row[colProps.dataKey][colProps.specialRender] + '"'
      } else {
        return ''
      }
    } else {
      return row[colProps.dataKey]
    }
  }

  return (
    <AccessContainer>
      <StyledModal open={state.isUploadModalOpen} size='small'>
        <StyledModal.Content>
          <Title>{t('ImportDescription')}</Title>
          {t('FileTypes')}
          <FileDrop
            acceptedTypes={['.csv']}
            multiple={false}
            onAccepted={
              files => setFileUpload(files[0])
            }
          />
        </StyledModal.Content>
        <StyledModal.Footer>
          <FooterContainer>
            <StyledLink onClick={() => { exportCsv(siteId, false, true) }}>
              {t('Download')}
            </StyledLink>
            <ButtonsContainer>
              <Button content='Cancel' onClick={() => dispatch({ type: 'hideUploadModal', modal: 'bulkUploadModal' })} size='medium' style={{ justifyContent: 'flex-end' }} type='secondary' />
              <Button content='Submit' loading={dataLoading} onClick={submitFile} size='medium' style={{ justifyContent: 'flex-end' }} type='primary' disabled={!fileUpload} />
            </ButtonsContainer>
          </FooterContainer>
        </StyledModal.Footer>
      </StyledModal>
      <StyledModal
        open={state.isDeleteModalOpen}
        size='small'
        closeIcon={true}
        onClose={closeModalAction}
      >
        <StyledModal.Header>
          <Title>{t('Delete')}</Title>
        </StyledModal.Header>
        <StyledModal.Content>
          {selectedRows && selectedRows.length > 1 &&
            `Are you sure you want to delete ${selectedRows.length} Access Points?`
          }
          {selectedRows && selectedRows.length === 1 &&
            `Are you sure you want to delete Access Point '${selectedRows[0].name}'?`
          }
        </StyledModal.Content>
        <StyledModal.Footer>
          <Button content='Cancel' onClick={closeModalAction} size='medium' style={{ justifyContent: 'flex-end' }} type='secondary' />
          <Button
            content='Submit'
            onClick={() => {
              deleteAccessPoints(selectedRows)
              dispatch({ type: 'hideDeleteModal', modal: 'bulkDeleteModal' })}
            }
            size='medium'
            style={{ justifyContent: 'flex-end' }}
            type='primary'
          />
        </StyledModal.Footer>
      </StyledModal>
      {!isEditModalVisible && <CreateAccessPoints
        siteId={siteId}
        onSubmitSuccess={onSubmitSuccess(siteId)}
        permissions={permissions}
      // eslint-disable-next-line react/jsx-closing-bracket-location
      />}
      {isEditModalVisible && <EditAccessPoints
        id={id}
        onSubmitSuccess={onSubmitSuccess(siteId)}
        permissions={permissions}
      // eslint-disable-next-line react/jsx-closing-bracket-location
      />}
      <ActionBarContainer>
        <ActionBarSection flex='none'>
          <SimpleRow className='pointer'>
            {permissions['beacons:create'] &&
              <ActionContainer
                className='create-button'
                onClick={openCreateAccessPointModal}
              >
                <StyledIcon
                  description={t('CreateDescription')}
                  name='device-add'
                  root='common'
                  size='medium'
                />
                <ActionText>
                  {t('CreateAccessPoint')}
                </ActionText>
              </ActionContainer>
            }
            {permissions['beacons:create'] &&
              <ActionContainer
                className='import-button'
                onClick={() => dispatch({ type: 'showUploadModal', modal: 'bulkUploadModal' })}
              >
                <StyledIcon
                  description={t('ImportDescription')}
                  name='cloud-upload'
                  root='common'
                  size='medium'
                />
                <ActionText>
                  {t('Import')}
                </ActionText>
              </ActionContainer>
            }
            {permissions['beacons:read'] &&
              <ActionContainer className='export-button'>
                <SimpleRow
                  className='pointer'
                  onClick={() => {
                    exportCsv(siteId)
                  }}
                >
                  <ActionContainer>
                    <StyledIcon
                      description={t('Export')}
                      name='exit'
                      root='common'
                      size='medium'
                    />
                    <ActionText>
                      {t('Export')}
                    </ActionText>
                  </ActionContainer>
                </SimpleRow>
              </ActionContainer>
            }
          </SimpleRow>
        </ActionBarSection>
      </ActionBarContainer>
      <TableContainer>
        <DataTable
          search
          totalRows={totalItems}
          rows={100}
          data={data}
          isLoadingPage={dataLoading}
          loadNextPage={setPage}
          onSearchChange={setSearchTerm}
          selection={true}
          ref={tableRef}
          onSelectionChange={checkSelectionChange}
          exportCellData={exportCellData}
        >
          <DataTable.ActionBar>
            {selectedRows && selectedRows.length === 1 && (permissions['beacons:edit'] || permissions['beacons:edit_friendly_name']) &&
              <DataTable.ActionBar.Item
                content='Edit'
                icon='edit'
                onClick={() => {
                  const [head] = selectedRows
                  openEditAccessPointModal(head)
                }}
              />
            }
            {permissions['beacons:delete'] && !dataLoading &&
              <DataTable.ActionBar.Item
                content='Delete'
                icon='delete'
                onClick={() => dispatch({ type: 'showDeleteModal', modal: 'bulkDeleteModal' })}
              />
            }
            {permissions['beacons:delete'] && dataLoading &&
              <Icon
                name='refresh'
                size='medium'
                loading={true}
                color='white'
              />
            }
          </DataTable.ActionBar>

          {accessPointKeys.map(column => {
            if (column.specialRender) {
              return <DataTable.Column
                cellRenderer={({ cellData }) =>
                  cellData
                    ? cellData[column.specialRender]
                    : ''}
                dataKey={column.dataKey}
                label={t(column.label)}
                key={column.dataKey}
                sortable={true}
                specialRender={column.specialRender} />
            }
            return <DataTable.Column dataKey={column.dataKey} label={t(column.label)} key={column.dataKey} sortable={true} />
          })}
        </DataTable>
      </TableContainer>
    </AccessContainer>
  )
}

AccessPoints.propTypes = {
  t: PropTypes.func,
  siteId: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired
}

AccessPoints.defaultProps = {
  permissions: {},
  t: key => key
}

export default AccessPoints
