import moment from 'moment'
import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { reduxForm } from 'redux-form'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import FirmwareRedux from '../../Redux/FirmwareRedux'
import ModalActions from '../../Redux/ModalRedux'
import UpdatesActions from '../../Redux/UpdatesRedux'
import ScheduelUpdatesModal from './schedule-updates'
import mapStateToProps, { assetCurrentVersion } from './schedule-updates.selector'

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose()),
    updatesPrinterTabSelection: tab => dispatch(UpdatesActions.updatesPrinterTabSelection(tab)),
    firmwareRequestToUpdate: (firmware, devices, scheduleTime, timeZone) => {
      const tasks = firmware.reduce((tss, f) => {
        const t = devices.map(d => {
          const currentVersion = assetCurrentVersion(d, f)
          return {
            ...d,
            ScheduledVersion: f.version || '1.0',
            AssetType: f.assetType,
            displayName: f.displayName,
            assetCategory: f.assetCategory,
            CAIDCAssetID: f.caidcAssetId,
            currentVersion,
            updatePreferences: f.updatePreferences,
            CommandParameters: {
              DeviceType: f.deviceType,
              VerificationCode: f.verificationCode,
              FileSize: f.fileSize,
              FileName: f.fileName,
              FileType: f.fileType,
              ChecksumType: f.encryption
            },
            deviceConfiguration: undefined,
            firmwareVersion: undefined
          }
        })
        return [
          ...tss,
          ...t
        ]
      }, [])
      dispatch(FirmwareRedux.firmwareRequestToUpdate({
        scheduleTime: moment(scheduleTime),
        updatePreferences,
        timeZone,
        DeviceList: tasks,
        RequestPriority: 'Optional'
      }))
    },
    onSubmit: items => dispatch(UpdatesActions.updatesPrinterProvisionRequest(items))
  }
}

export default pipe(
  translate('ScheduleUpdateModal'),
  reduxForm({
    form: 'scheduleUpdatesModalForm'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLDConsumer()
)
(ScheduelUpdatesModal)
