import { createStructuredSelector, createSelector } from 'reselect'
import { isEmpty } from 'ramda'
import moment from 'moment'

export const unreadAlerts = ({ alerts }) => alerts.unreadAlerts
export const theme = ({ user }) => user.getIn(['theme'], {})
export const totalAlerts = ({alerts}) => alerts.totalUnreadAlerts


export default createStructuredSelector({
  unreadAlerts,
  totalAlerts,
  theme
})
