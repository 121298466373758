import { createSelector, createStructuredSelector } from 'reselect'
import { find, propEq, isEmpty } from 'ramda'
import moment from 'moment'

import {
  deviceFilters,
  arrayConcat
} from './FilterSelector'

import {
  devices,
  selectedDevices,
  devicesObj
} from './DeviceSelector'

const results = state => state.firmware.getIn(['currentFirmware', 'result'])
const firmwares = state => state.firmware.getIn(['currentFirmware', 'entities', 'firmware'])
const softwares = state => state.firmware.getIn(['currentFirmware', 'entities', 'software'])
const distributions = state => state.firmware.getIn(['currentFirmware', 'entities', 'distribution'])
const assets = state => state.firmware.assets
const scheduled = state => state.firmware.scheduled
export const currentSite = state => state.firmware.currentSite
const currentFilters = state => state.firmware.filters
const assetFilters = state => state.firmware.assetFilters
const currentModels = state => state.firmware.currentModels
const selectedModel = state => state.firmware.selectedModel
const selectedType = state => state.firmware.selectedType
const error = state => state.firmware.error
const loading = state => state.firmware.loading
const searchAsset = state => state.firmware.searchAsset

const loadingDevs = state => state.devices.loading

export const currentScheduled = createSelector([scheduled], sch => sch && sch.length
  ? sch.reduce((arr, s) => [
    ...arr,
    {
      assetType: s['assetType'],
      deviceSerialNumber: s['deviceSerialNumber'],
      updatedDatetime: s['updatedDatetime'],
      currentVersion: s['currentVersion'],
      scheduledVersion: s['scheduledVersion'],
      masterGuid: s['masterGuid'],
      status: s['status'],
      scheduledDate: s['scheduledDate']
    }
  ], []).filter(s => s.status === 'OPEN')
  : []
)

export const notifications = createSelector([scheduled], sch => sch && sch.length
  ? sch.reduce((arr, s) => [
    ...arr,
    ...s.commandWorkFlow.map(w => ({
      ...s,
      ...w,
      updatedDatetime: w.timestamp
    }))
  ], [])
  : []
)

export const filters = createSelector([results, softwares, firmwares], (results, sof, firm) => {
  return results.map(f => {
    const soft = sof.getIn([f])
    return {
      key: f,
      name: f,
      filters: soft.value.map(s => ({
        key: s,
        name: s,
        selected: firm.getIn([s, 'selected']) || false
      }))
    }
  })
})

const filtersToRules = (filters) =>
  filters.reduce((a, fl) => [...a, ...fl.filters.reduce((e, n) => {
    if (n.selected) {
      return [
        ...e,
        n.key
      ]
    }
    return e
  }, [])], [])

const cleanDevicesFilters = createSelector([devices, deviceFilters], (devs, filter) => {
  if (!devs || !filter) {
    return []
  }
  const allFilters = devs.reduce((arr, d) => {
    return [
      ...arr,
      ['deviceTypeFormatted', 'filters', d.deviceType || 'Not Available'],
      ['modelUpperCase', 'filters', d.modelUpperCase || 'Not Available']
    ]
  }, [])
    .reduce((obj, x) => {
      if (!obj.getIn(x)) {
        const [key, , value] = x
        return obj
          .updateIn([key, 'filtersById'], arrayConcat, value)
          .setIn(x, { key: value, selected: false })
      } else {
        return obj
      }
    }, filter)
  return Object.keys(allFilters).map(f => {
    return {
      key: f,
      name: allFilters[f].name,
      filters: allFilters[f].filtersById.map(fs =>
        allFilters[f].filters[fs]
      )
    }
  })
})

export const sitesWithDevices = createSelector([devices], (devs) => {
  if (!devs) {
    return []
  }
  const allSites = devs.reduce((acc, x) => {
    const site = x.getIn(['hierarchy'])
    const t = find(propEq('value', site))(acc)
    return t
      ? acc
      : [...acc, {
        value: site,
        text: site,
        key: site
      }]
  }, [{ text: 'UNSELECT SITE FILTER', value: '' }])
  return allSites
})

const filteredBySite = (devs, site) => {
  const filteredBySite = site
    ? devs.filter(x => x.hierarchy === site)
    : devs
  return filteredBySite
}

export const fullDevicesBySite = createSelector([devices, currentSite], filteredBySite)

const currentDevices = createSelector([selectedDevices, devicesObj], (selected, fullDevs) => {
  const devs = selected.map(d => fullDevs.getIn([d]))
  return devs
})

export const firmware = createSelector([firmwares, distributions, filters, currentDevices], (objs, dis, filt, devs) => {
  const filters = filtersToRules(filt)
  let firmware = []

  if (filters && filters.length) {
    firmware = filters.map(o => ({
      ...objs[o],
      value: objs[o].value.map(b => dis.getIn([b]) || {})
    }))
  } else if (objs) {
    firmware = Object.keys(objs).map(o => ({
      ...objs[o],
      value: objs[o].value.map(b => dis.getIn([b]) || {})
    }))
  }

  const currentModels = devs.map(d => devs.model || 'Not Available')

  firmware.map(firm => {
    return firm.value.filter(value => {
      const firmInDevice = value.deviceModel.filter(model => currentModels.includes(model))
      return !isEmpty(firmInDevice)
    })
  })

  return firmware
})

const categories = createSelector([fullDevicesBySite], (devs) => {
  const n = devs.reduce((obj, d) => {
    if (obj[d.deviceType]) {
      if (!obj[d.deviceType][d.model] && d.model) {
        obj[d.deviceType] = {
          ...obj[d.deviceType],
          [d.model]: true
        }
      }
    } else {
      if (d.model) {
        obj[d.deviceType] = {
          [d.model]: true
        }
      }
    }
    return obj
  }, {})

  const m = Object.keys(n).reduce((acc, k) => [
    ...acc,
    {
      label: !k || k === 'undefined'
        ? 'OTHERS'
        : k.toUpperCase(),
      id: k,
      nodes: Object.keys(n[k]).map(m => ({ label: m.toUpperCase(), id: m })),
      translate: true
    }
  ], [])
  return m || []
})

export const filteredDevices = createSelector([fullDevicesBySite, currentFilters], (devs, filters) => {
  if (filters && filters.length) {
    return devs.filter(d => filters.includes(d.model))
  }
  return devs
})

const firmwareNodes = createSelector([firmware], firm => {
  if (!firm) {
    return []
  }

  const allValues = firm.reduce((acc, item) => {
    return [
      ...acc,
      ...item.value
    ]
  }, [])

  const nodeObj = allValues.reduce((acc, item) => {
    acc[item.assetCategory] = acc[item.assetCategory]
      ? {
        ...acc[item.assetCategory],
        nodes: acc[item.assetCategory]['nodes'][item.version]
          ? acc[item.assetCategory]['nodes']
          : {
            ...acc[item.assetCategory]['nodes'],
            [item.version]: item.version
          }
      }
      : {
        id: item.assetCategory,
        label: item.assetCategory,
        nodes: { [item.version]: item.version }
      }

    const date = moment(item.releaseDate).locale(window.navigator.language).format('MMM DD YYYY')

    acc['releaseDate'] = acc['releaseDate']
      ? {
        ...acc['releaseDate'],
        nodes: acc['releaseDate']['nodes'][date]
          ? acc['releaseDate']['nodes']
          : {
            ...acc['releaseDate']['nodes'],
            [date]: date
          }
      }
      : {
        id: 'releaseDate',
        label: 'Release Date',
        nodes: { [date]: date }
      }

    acc['deviceType'] = acc['deviceType']
      ? {
        ...acc['deviceType'],
        nodes: acc['deviceType']['nodes'][item.deviceType]
          ? acc['deviceType']['nodes']
          : {
            ...acc['deviceType']['nodes'],
            [item.deviceType]: item.deviceType
          }
      }
      : {
        id: 'deviceType',
        label: 'Device Type',
        nodes: { [item.deviceType]: item.deviceType }
      }

    return acc
  }, {})

  const nodes = Object.keys(nodeObj).map(n => ({
    id: nodeObj[n].id,
    label: nodeObj[n].label,
    nodes: Object.keys(nodeObj[n].nodes).map(i => ({
      id: i,
      label: i === 'mobilecomputer' ? 'Mobile Computer' : i
    }))
  }))

  return nodes
})

const filteredFirmware = createSelector([firmware, assetFilters, searchAsset], (firmwares, filters, search) => {
  if (!search && isEmpty(filters)) {
    return firmwares
  }
  const filtered = firmwares.map(firm => {
    const result = {
      key: firm.key,
      value: firm.value.filter(value => {
        const include = Object.keys(value).filter(x => {
          return typeof value[x] === 'string' &&
            ((x !== 'releaseDate'
              ? filters.includes(value[x])
              : filters.includes(moment(value.releaseDate).locale(window.navigator.language).format('MMM DD YYYY'))) ||
            (!!value[x] && !!search && value[x].toLowerCase().includes(search.toLowerCase())))
        })
        return !isEmpty(include)
      })
    }
    return result
  }).filter(x => !isEmpty(x.value))

  return filtered
})

const checkLoading = createSelector(
  loading,
  loadingDevs,
  (loading, loadingDevs) => loading || loadingDevs
)

export const firmwareSelector = createStructuredSelector({
  currentModels,
  selectedModel,
  selectedType,
  error,
  loading: checkLoading,
  firmware,
  filters,
  notifications,
  currentScheduled,
  deviceFilters: cleanDevicesFilters,
  sites: sitesWithDevices,
  assets,
  devices: filteredDevices,
  selectedDevices,
  currentDevices,
  nodes: categories,
  firmwareNodes,
  assetFilters,
  filteredFirmware,
  currentSite
})
