const create = (api) => {
  const setFilters = params => api.post('/settings/filter', params)
  const updateFilters = params => api.put('/settings/filter', params)
  const getFilters = params => api.get('/settings/filter', params)
  const getDashboardDetailList = (siteId) => api.get('settings/layout/details', { siteId })
  const getDashboardsFullList = (roleId, siteId) => api.get('settings/layout', { roleId, siteId, multiple: true })
  const getDashboardsPerUserFullList = (roleId, siteId, userId) => api.get('settings/layout/user', { roleId, siteId, userId, multiple: true })
  const deleteDashboard = (id) => api.delete('settings/layout', { 'dashboardIds[0].id': id })
  const editDashboard = ({ dashboardKey, dashboard }) => api.put(`settings/layout/${dashboardKey}`, dashboard)
  const createNewDashboard = ({ dashboard }) => api.post('settings/layout', dashboard)
  const assignDashboard = ({ dashboardId, roleId, siteId }) => api.post(`settings/layout/${dashboardId}`, { roleId, siteId })
  const deleteDashboardView = (ids) => api.delete('settings/layout', ids.reduce((newObj, obj, index) => {
    return { ...newObj, [`dashboardIds[${index}].dashboardKey`]: obj.id }
  }, 0))

  return {
    setFilters,
    updateFilters,
    getFilters,
    getDashboardDetailList,
    getDashboardsFullList,
    deleteDashboard,
    editDashboard,
    assignDashboard,
    deleteDashboardView,
    createNewDashboard,
    getDashboardsPerUserFullList
  }
}

export default {
  create
}