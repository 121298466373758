import React from 'react'
import { Select } from '@scuf/common'
import { translate } from 'react-translate'
import { errorTranslation } from './utils'

const FormSelect = ({
  t,
  className,
  input,
  options,
  label,
  indicator,
  placeholder,
  stretch = true,
  disabled = false,
  multiple = false,
  search = false,
  meta: {
    error,
    touched,
    visited,
    submitFailed,
    active
  }
}) => {
  return (
    <div>
      <Select
        {...input}
        className={className || ''}
        label={label}
        indicator={indicator}
        placeholder={placeholder}
        options={options}
        multiple={multiple}
        fluid={stretch}
        disabled={disabled}
        search={search}
        onBlur={() => {}}
        {...(touched || submitFailed) && error
          ? { error: errorTranslation(t, error) }
          : {}
        }
      />
    </div>
  )
}

export default translate('FormInput')(FormSelect)
