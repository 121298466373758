import { schema } from 'normalizr'
import get from 'lodash/get'

export const devicesSchema = new schema.Entity('devices', {}, {
  idAttribute: 'type',
  processStrategy: entity => {
    const deviceModels = get(entity, 'deviceModels', [])
      .map(model => {
        const text = model.toUpperCase()
        return {
          value: model,
          text
        }
      })
    return {
      ...entity,
      deviceModels
    }
  }
})

export const deviceNormalizer = new schema.Entity('devices', {}, {
  idAttribute: 'serialNumber',
  processStrategy: device => {
    const propertyValue = 'propertyValue'
    const hierarchy = get(device, 'siteHierarchy', '')
    const firmwareVersion = get(device, 'softwareUpdate.assets.0.version', '')
    const deviceStatus = get(device, 'properties.Status', '')
    const site = hierarchy.split('/').slice(-1).join('').trim()
    const telemetrySummary = get(device, 'telemetrySummary', {})
    const batteryInfo = get(telemetrySummary, 'battery.level', {})
    const batteryLevel = get(batteryInfo, propertyValue, '')
    const scanInfo = get(telemetrySummary, 'decoder.scangoodread', {})
    const scanVolume = get(scanInfo, propertyValue, '')
    const labelsPrintedInfo = get(telemetrySummary, 'printer.media.stats.labelsprinted', {})
    const labelsPrinted = get(labelsPrintedInfo, propertyValue, '')
    const odometerInfo = get(telemetrySummary, 'printer.printhead.odometer', {})
    const odometer = get(odometerInfo, propertyValue, '')
    return {
      ...device,
      site,
      firmwareVersion,
      deviceStatus,
      batteryLevel,
      scanVolume,
      labelsPrinted,
      odometer
    }
  }
})
