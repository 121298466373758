import styled from 'styled-components/macro'

const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`
const Header = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
`

const Container = styled(Flex)`
  flex-direction: column;
  height: calc(100% - 4rem);
`
const CategoryContainer = styled.div`
  overflow-y: scroll;
  height: 100%;;
`

export {
  Header,
  Container,
  CategoryContainer
}
