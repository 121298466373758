import React from 'react'

import { Ul } from '../../TreeNode'
import LocationNode from './location-node'

const LocationTree = ({
  nodes,
  depth
}) => {
  return (
    <Ul className='scrollable' style={{ flex: 0.5 }}>
      {nodes
        .filter(n => depth !== 1 || n.schema !== 'subzone')
        .map(n => (
          <LocationNode
            key={n.id}
            {...n}
            depth={depth}
          />
        ))
      }
    </Ul>
  )
}

export default LocationTree
