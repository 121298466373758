import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeletePackage from './delete-package'
import mapStateToProps from './delete-package.selector'
import UpdatesActions from '../../Redux/UpdatesRedux'
import ModalActions from '../../Redux/ModalRedux'

export const mapDispatchToProps = dispatch => ({
  deletePackage: (ids) => dispatch(UpdatesActions.updatesDeletePackageRequest(ids)),
  closeModal: () => dispatch(ModalActions.modalClose())
})

export default translate('DeletePackage')(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePackage))
