import { useEffect, useState, useMemo } from 'react'
import { AlertsApi } from 'Services'
import { get, range } from 'lodash'
import moment from 'moment'

export const useAutomatedReport = () => {
  const [dayOfWeek, setDayOfWeek] = useState(1)
  const [timeZoneNames, setTimeZoneNames] = useState([])

  const timeOptionsInHours = range(24).map(hour => ({
    text: moment({ hour: hour }).format('h:mm A'),
    value: hour
  }))
  const timeOptionsInSeconds = range(24).map(hour => ({
    text: moment({ hour: hour }).format('h:mm A'),
    value: hour * 3600
  }))

  const getTimeZones = async () => {
    const result = await AlertsApi.getTimeZones()
    if (result.ok) {
      setTimeZoneNames(
        get(result, ['data'], [])
          .map(name => ({
            value: name,
            text: name
          }))
      )
    }
  }

  useEffect(() => {
    getTimeZones()
  }, [])

  return {
    dayOfWeek,
    setDayOfWeek,
    timeZoneNames,
    setTimeZoneNames,
    timeOptionsInHours,
    timeOptionsInSeconds
  }
}
