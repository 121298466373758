import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as ScufCheckbox } from '@scuf/common'
import { useField, useFormikContext } from 'formik'

const Checkbox = ({
  name,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  const { submitCount } = useFormikContext()
  return (
    <ScufCheckbox
      error={(meta.touched || submitCount > 0) && meta.error}
      id={name}
      onBlur={() => helpers.setTouched(true)}
      onChange={helpers.setValue}
      checked={field.value}
      {...rest}
    />
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired
}

export default Checkbox
