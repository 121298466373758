import styled from 'styled-components'
import { Header } from '@scuf/common'
export const StyledHeader = styled(Header)`
& .ui .menu {
  align-items:center;
}
  & .page-header-menu .right {
    margin-right: 1rem !important;
  }
  & .profile-icon {
    border: solid 1px #f0f0f0;
    @media (max-width: 768px) {
     display: flex !important;
    }
  }
  & .page-header-menu.ui.menu .menu-btn-item {
    padding: 0.75rem 1.35rem !important;
  }
  & .honeywell-logo {
    margin-left: .8rem;
    max-width: 175px;
    max-height: 100px;
    background-image: ${({ imageurl }) => imageurl && 'url(' + imageurl + ') !important'};
  }
  & .main-logo {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  & .menu .transition .right .visible {
    position: absolute;
    z-index: 10;
  }
  & .page-header-menu.ui.menu .ui.dropdown.icon-menu{
    @media (max-width: 768px) {
      z-index: 10;
      position: absolute;
    }
  }
  & .page-header-menu.ui.menu .ui.dropdown.icon-menu .menu{
    background-color: ${props => props.theme.black};
  }
  & .user-profile-menu  .item {
    width: 100%
  }
  & .menu{
    @media (max-width: 768px) {
    overflow-x: visible !important;
    }
  }
  &.page-header-menu.ui.menu.ui.dropdown.icon-menu{
    @media (max-width: 768px) {
      z-index: 10;
      position: absolute;
      transform: translateX(-40px);
    }
  }
`
