import { createStructuredSelector, createSelector } from 'reselect'
import { toUpper } from 'lodash'

export const typesOfModelsCard = ({ dashboard }) =>
  dashboard.getIn(['typesOfModelsCard'], {})

export const modelDriven = createSelector(
  [typesOfModelsCard],
  avgBatHp => avgBatHp.getIn(['data'], [0])
)

export const total = createSelector(
  [typesOfModelsCard],
  avgBatHp => avgBatHp.getIn(['total'], [0])
)

export const groupByModelAndSumValue = data => {
  const series = {}
  data.forEach(({ key, value }) => {
    if (key) {
      key = toUpper(key)
      series[key] = series[key] || 0
      series[key] += value
    }
  })
  return series
}

export const groupedDevices = createSelector(
  [modelDriven],
  devices => {
    const groupedModels = groupByModelAndSumValue(devices)
    return Object.keys(groupedModels).map(model => ({ key: model, value: groupedModels[model] }))
  }
)

export const data = createSelector(
  modelDriven,
  total,
  groupedDevices,
  (modelDriven, total, grouped) => {
    return grouped.map(value => {
      let percentage = value.value * 100 / (total)
      percentage = Math.round(percentage * 10) / 10
      return {
        value: value.value,
        key: value.key,
        percentage
      }
    })
  }
)

export default createStructuredSelector({
  data
})
