import { connect } from 'react-redux'
import ModalActions from '../../../../Redux/ModalRedux'
import BatteryListModal from './battery-list-modal'
import BatteryListModalSelector from './battery-list-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    BatteryListModalSelector,
    mapDispatchToProps
  )
)(BatteryListModal)
