import React, { PureComponent } from 'react'
import DataTable from '../DataTable'
import { Button, Modal, Search } from '@scuf/common'
import { isEmpty } from 'ramda'
import { Title, FormRow } from '../../Themes/ScufStyledComponents'
import Error from '../Error'

class DeleteUserTable extends PureComponent {
  componentDidMount () {
    if (isEmpty(this.props.users)) {
      this.props.getUsers()
    }
  }

  render () {
    const {
      users,
      currentUser,
      setCurrentUser,
      closeModal,
      deleteUser,
      t,
      loading
    } = this.props
    return (
      <div>
        <Modal.Header>
          <Title>{!isEmpty(currentUser) ? currentUser.userName : t('DeleteUserTable_Title')}</Title>
        </Modal.Header>
        {loading || isEmpty(users)
          ? <Error loading={loading}>{t('DeleteUserTable_Empty')}</Error>
          : <div style={{ height: '66vh' }}>
            <DataTable
              search
              rowHeight={40}
              onRowClick={({ rowData }) => setCurrentUser(rowData.id)}
              data={users}
              selection
              selectionMode='single'
              selectionPath='id'
              hideActionBar
            >
              <DataTable.Column
                dataKey='firstName'
                label={t('DeleteUserTable_FirstName')}
                width={1}
                flexShrink={1}
                flexGrow={1}
              />
              <DataTable.Column
                dataKey='lastName'
                label={t('DeleteUserTable_LastName')}
                width={1}
                flexShrink={1}
                flexGrow={1}
              />
              <DataTable.Column
                dataKey='userName'
                label={t('DeleteUserTable_Email')}
                width={2.5}
                flexShrink={2.5}
                flexGrow={2.5}
              />
            </DataTable>
          </div>
        }
        <div style={{ position: 'absolute', padding: '1em', right: 0, bottom: 0 }}>
          <Button
            type='secondary'
            content='Cancel'
            actionType='button'
            onClick={closeModal}
          />
          <Button
            onClick={(e) => deleteUser(currentUser.id)}
            type='primary'
            content='Delete'
            actionType='button'
          />
        </div>
      </div>
    )
  }
}

export default DeleteUserTable
