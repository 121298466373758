import React from 'react'
import { Icon } from '@scuf/common'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const TooltipText = styled.span`
  visibility: hidden;
  max-width: 500px;
  min-width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -5px;
`

const ToolTip = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${TooltipText} {
    visibility: visible;
  }
  ${TooltipText}{
    right: ${props => props.left ? '105%' : ''};
    left: ${props => props.right ? '105%' : ''};
    ${props => props.tooltipStyle ? props.tooltipStyle : ''}
  }
`

const ErrorTooltip = ({ message, children, icon, component, ...props }) => (
  <ToolTip  {...props} >
    {children}
    {icon ? <Icon name='badge-alert' size='large' /> : null}
    {component ? <TooltipText >{component}</TooltipText> : null}
  </ToolTip>
)

ErrorTooltip.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.object,
  icon: PropTypes.bool,
  component: PropTypes.string
}

export default ErrorTooltip
