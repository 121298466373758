import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../AssetManagementTable'
import { ModalWrap, ModalTitle } from './battery-age-list-modal.styles'
import { Link } from 'react-router-dom'
import { DateFormatter } from 'Utils/DateHelper'

export const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'batteryAge', translation: 'AssetManagementTable_age', render: null },
  { field: 'currentDevice', translation: 'AssetManagementTable_lastKnowDevice', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_lastKnowDeviceAlias', render: null },
  { field: 'calculatedManufactureDate', translation: 'AssetManagementTable_manufactureDate', render: null },
  { field: 'deviceSite', translation: 'AssetManagementTable_deviceSite', render: null },
  { field: 'lastUpdated', translation: 'AssetManagementTable_lastReportedTimestamp', render: ({ value }) =>  <DateFormatter date={value} />},
]

const BatteryListModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('BatteryAgeCount_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName={t('BatteryAgeCount_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default BatteryListModal
