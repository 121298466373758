import { createStructuredSelector } from 'reselect'
import { selectedOrgId } from 'Selectors/LoaderSelector'

export const currentCulture = state => state.user.language

export const newReportModalOpen = ({ preferences }) => {
  return preferences.getIn(['newReport', 'isOpen'], false)
}

const subscriptionFeatures = ({ user }) => user.getIn(['subscription', 'features'], [])

export default createStructuredSelector({
  selectedOrgId,
  newReportModalOpen,
  featureList: subscriptionFeatures
})
