import { pipe } from 'ramda'
import BulkModal from './bulk-edit-modal'
import ModalActions from '../../../../Redux/ModalRedux'
import { translate } from 'react-translate'
import BulkCloseModalSelector from './bulk-edit-modal.selector'
import { connect } from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal, props) => dispatch(ModalActions.modalOpen(modal, props)),
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}
export default pipe(
  translate('BulkEditModal'),
  connect(
    BulkCloseModalSelector,
    mapDispatchToProps
  )
)(BulkModal)
