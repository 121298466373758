import apiClient from '../../../Services/ApiSauceInstance'
import { ApiResponse } from '../../../Services/api-response'
import { CancelToken } from 'axios'

export interface NewEmployee {
  organizationId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  employeeId: string;
  email?: string;
  siteId: string;
  roleId?: string;
}

export interface Employee extends NewEmployee {
  id: string;
}

export interface BulkCreateEmployeesRequest {
  employees: NewEmployee[];
  dryRun?: boolean;
  IsBulk?: boolean;
}

export type BulkCreateEmployeesResponse = ApiResponse<ApiResponse<Employee>[]>

export const bulkCreateEmployees = (request: BulkCreateEmployeesRequest, cancelToken?: CancelToken) => apiClient
  .post<BulkCreateEmployeesResponse>('employees:bulkCreate', request, { timeout: 10000, cancelToken })
