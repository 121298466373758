import Immutable from 'seamless-immutable'
import { createSelector } from 'reselect'

export const getAllRoles = ({ roles }) => roles.getIn(['roles'], Immutable({}))
export const getSitesRoles = ({ roles }) => roles.getIn(['rolesPerOrganization'], Immutable({}))

export const getRoles = organizationId => createSelector(
  () => organizationId,
  getAllRoles,
  getSitesRoles,
  (organization, roles, organizations) => {
    const genericRoles = Object.values(
      roles.getIn(['generic'], {})
    )
    if (!organization) {
      return genericRoles
    } else {
      const customRoles = organizations.getIn([organization], [])
        .map(roleId => roles.getIn(['custom', roleId], {}))
      return [
        ...genericRoles,
        ...customRoles
      ]
    }
  }
)
