import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import findIndex from 'lodash/findIndex'

const { Types, Creators } = createActions({
  locationRequest: ['site'],
  locationSuccess: ['data'],
  locationFailure: null,
  locationRequestDevices: null,
  locationRequestDevicesById: ['devices'],
  locationSuccessDevicesById: ['devices'],
  locationSuccessDevices: ['devices', 'counts'],
  locationFailureDevices: null,
  locationSelect: ['selectedLocation'],
  locationRequestCreateBuilding: ['building'],
  locationSuccessCreateBuilding: null,
  locationFailureCreateBuilding: null,
  locationRequestUpdateBuilding: ['building'],
  locationEditCurrent: null,
  locationSetBuilding: ['buildingId'],
  locationSetZone: ['zoneId'],
  locationReset: null,
  locationExpand: ['route'],
  locationRequestUpdateLocation: ['zoneGuid', 'location'],
  locationSuccessUpdateLocation: null,
  locationRequestCreateZone: ['zone'],
  locationRequestUpdateZone: ['zone'],
  locationRequestCreateSubZone: ['zone'],
  locationSuccessCreateZone: null,
  locationFailureCreateZone: null,
  locationSetFilter: ['key', 'filter'],
  locationSetDelete: ['deleteLocation'],
  locationRequestDelete: null,
  locationSuccessDelete: null,
  locationFailureDelete: null,
  locationSetStatusFilter: ['name']
})

export const LocationTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  site: '',
  entities: {},
  result: [],
  selectedLocation: [],
  locations: [],
  editCurrent: false,
  currentBuilding: '',
  currentZone: '',
  currentLocation: [],
  filters: {
    buildingId: [],
    zoneId: [],
    subzoneId: []
  },
  devices: [],
  deleteLocation: {},
  statusFilters: {
    outOfContact: false,
    offlineDevices: false
  },
  assets: {
    entities: {
      device: {}
    },
    result: []
  }
})

/* ------------- Helpers -------------- */
export const insertSubZones = l => {
  const locations = Immutable({ ...l })
  const aux = locations.getIn(['entities', 'subzone'])
  const subzones = aux ? Object.keys(aux) : []
  return subzones.reduce((l, s) => {
    const zoneId = l.getIn(['entities', 'subzone', s, 'referenceZone', 'zoneGuid'])
    return l.updateIn([
      'entities',
      'zone',
      zoneId,
      'zone'
    ], arr => findIndex(arr, o => o.id === s) !== -1
      ? arr
      : arr
        ?[...arr, {id: s, schema: 'subzone'}]
        : [{id: s, schema: 'subzone'}]
    )
  }, locations)
}

/* ------------- Reducers ------------- */

export const request = (state, { site }) => state.merge({ loading: true, site: site.guid })

export const success = (state, { data }) => {
  const foo = insertSubZones(data)
  return state.merge({ loading: false, ...foo })
}

export const genericSuccess = state =>
  state.merge({ loading: false })

export const genericFailure = state =>
  state.merge({ loading: false })

export const select = (state, { selectedLocation }) =>
  state.updateIn(['selectedLocation'], arr => arr.indexOf(selectedLocation) !== -1
    ? arr.filter(s => s !== selectedLocation)
    : [...arr, selectedLocation])
    .updateIn(['entities', 'site', selectedLocation, 'selected'], selected => !selected)

export const requestLocations = state => state.merge({ loading: true })

export const requestCreate = state => state.merge({ loading: true })

export const locationEditCurrent = state => state.merge({ editCurrent: true })

export const locationSetBuilding = (state, { buildingId }) => state.merge({ currentBuilding: buildingId, currentZone: '' })

export const locationSetZone = (state, { zoneId }) => state.merge({ currentZone: zoneId })

export const locationReset = state => state.merge({ currentBuilding: '', currentZone: '' })

export const locationExpand = (state, { route }) => state.updateIn(['selectedLocation'], s => s.indexOf(route[1]) !== -1 ? s.filter(l => l !== route[1]) : [...s, route[1]])
  .merge({ currentLocation: route })

export const filter = (state, { key, filter }) => state
  .set('loading', true)
  .updateIn(['filters'],
    (prevState, value) => {
      const prevFilter = prevState.getIn([key], [])
      if (key !== 'subzoneId' && value.length < prevFilter.length) {
        switch (key) {
          case 'buildingId':
            return prevState.merge({
              [key]: value,
              zoneId: [],
              subzoneId: []
            })
          case 'zoneId':
            return prevState.merge({
              [key]: value,
              subzoneId: []
            })
          default:
            return prevState
        }
      } else {
        return prevState.merge({
          [key]: value
        })
      }
    }, filter)

export const locationSuccessDevices = (state, { devices, counts }) => state.merge({
  devices,
  loading: false,
  ...counts
})

export const locationSetDelete = (state, { deleteLocation }) => state.merge({ deleteLocation })

export const locationSuccessDelete = state =>
  state.merge({
    loading: false,
    deleteLocation: {}
  })

export const locationSetStatusFilter = (state, { name }) => state.updateIn(['statusFilters', name], (currentFilter) => !currentFilter)

export const locationSuccessDevicesById = (state, { devices }) => state
  .updateIn(['assets', 'entities', 'device'], currentEntities => {
    return {
      ...currentEntities,
      ...devices.entities.device
    }
  })
  .updateIn(['assets', 'result'], currentResult => {
    return [
      ...currentResult,
      ...devices.result
    ]
  })
  .merge({
    loading: false
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOCATION_REQUEST]: request,
  [Types.LOCATION_SUCCESS]: success,
  [Types.LOCATION_FAILURE]: genericFailure,
  [Types.LOCATION_REQUEST_DEVICES]: requestLocations,
  [Types.LOCATION_REQUEST_DEVICES_BY_ID]: requestLocations,
  [Types.LOCATION_SUCCESS_DEVICES_BY_ID]: locationSuccessDevicesById,
  [Types.LOCATION_SUCCESS_DEVICES]: locationSuccessDevices,
  [Types.LOCATION_FAILURE_DEVICES]: genericFailure,
  [Types.LOCATION_SELECT]: select,
  [Types.LOCATION_REQUEST_CREATE_BUILDING]: requestCreate,
  [Types.LOCATION_REQUEST_UPDATE_BUILDING]: requestCreate,
  [Types.LOCATION_SUCCESS_CREATE_BUILDING]: genericSuccess,
  [Types.LOCATION_FAILURE_CREATE_BUILDING]: genericFailure,
  [Types.LOCATION_EDIT_CURRENT]: locationEditCurrent,
  [Types.LOCATION_SET_BUILDING]: locationSetBuilding,
  [Types.LOCATION_SET_ZONE]: locationSetZone,
  [Types.LOCATION_RESET]: locationReset,
  [Types.LOCATION_EXPAND]: locationExpand,
  [Types.LOCATION_REQUEST_UPDATE_LOCATION]: requestCreate,
  [Types.LOCATION_SUCCESS_UPDATE_LOCATION]: genericSuccess,
  [Types.LOCATION_REQUEST_CREATE_ZONE]: requestCreate,
  [Types.LOCATION_REQUEST_UPDATE_ZONE]: requestCreate,
  [Types.LOCATION_REQUEST_CREATE_SUB_ZONE]: requestCreate,
  [Types.LOCATION_SUCCESS_CREATE_ZONE]: genericSuccess,
  [Types.LOCATION_FAILURE_CREATE_ZONE]: genericFailure,
  [Types.LOCATION_SET_FILTER]: filter,
  [Types.LOCATION_SET_DELETE]: locationSetDelete,
  [Types.LOCATION_REQUEST_DELETE]: requestLocations,
  [Types.LOCATION_SUCCESS_DELETE]: locationSuccessDelete,
  [Types.LOCATION_FAILURE_DELETE]: genericFailure
})
