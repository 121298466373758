import moment from 'moment-timezone'

export function getDateTime ({ toTime, endDatetime, timeZone }) {
    const selectedDateTime = moment(endDatetime).tz(timeZone)
    selectedDateTime.set({ hour: toTime.hour(), minute: 0 })
    if (selectedDateTime.isBefore()) {
      return {
        toTime,
        toDateTime: selectedDateTime.add(1, 'day')
      }
    } else {
      return {
        toTime
      }
    }
  }

export const isEndTimeAfterStart = ({ startDate, startHour }, { endDate, endHour }) => {
  const startMidnight = startDate.startOf('day')
  const endMidnight = endDate.startOf('day')

  const startTimestamp = startMidnight.add({ hours: startHour }).unix()
  const endTimestamp = endMidnight.add({ hours: endHour }).unix()

  return endTimestamp > startTimestamp
}
