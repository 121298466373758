import { createSelector } from 'reselect'
import get from 'lodash/get'

export const userEmail = ({ user }) => user.getIn(['userData', 'personIdentifier', 'loginEmailAddress'])

export const updateList = createSelector(
  ({ updates }) => updates.getIn(['updates', 'entities', 'distribution'], {}),
  distribution => {
    const updateList = Object.keys(distribution).map(d => distribution[d])
    return {
      updateList,
      updateCount: updateList.length
    }
  }
)

export const devices = createSelector(
  ({ updates }) => updates.getIn(['devices'], []),
  ({ devices }) => devices.getIn(['devices'], {}),
  (devicesId, devices) => {
    return devicesId.map(device => {
      const d = devices.getIn([device], {})
      return {
        DeviceSerialNumber: d.serialNumber,
        SystemGuid: d.systemGuid || '',
        OperationType: '',
        CurrentVersion: d.firmwareVersion,
        deviceConfiguration: d.deviceConfiguration,
        firmwareVersion: d.firmwareVersion
      }
    })
  }
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export const assetCurrentVersion = (d, f) => {
  switch (f.assetCategory) {
    case 'OS Distribution':
      return get(d, 'deviceConfiguration.platform.operatingSystem', '')
    case 'Enabling Solutions For Mobility Edge':
      return get(d, 'deviceConfiguration.softwareDetails.softwareName', '')
    case 'Apps':
      return get(d, 'deviceConfiguration.appsDetails[0]', '')
    default:
      return d.firmwareVersion || ''
  }
}
