import styled from 'styled-components/macro'
import { SuperContainer, Subtitle } from '../../../../Themes/ScufStyledComponents'

export const PrivacyContainer = styled(SuperContainer)`
  background-color: transparent;
  padding: 0;
  align-items: center;
`

export const PrivacySubtitle = styled(Subtitle)`
  text-align: center;
`

export const PrivacyIframeContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`

export const PrivacyIframe = styled.iframe`
  width: 100%;
  height: 100%;
`
