import { call, put, all, select } from 'redux-saga/effects'
import React from 'react'
import get from 'lodash/get'

import SitesManagerActions from '../Redux/SiteManagerRedux'
import FirmwareActions from '../Redux/FirmwareRedux'
import UserManagerActions from '../Redux/UserManagerRedux'
import DashboardActions from '../Redux/DashboardRedux'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from '../Themes/ScufStyledComponents'

export function * getSites (api, { preSelect }) {
  try {
    const response = yield call(api.getSites)
    if (response.ok) {
      yield put(SitesManagerActions.siteManagerSuccess(response.data))
      if (preSelect) {
        yield call(getSiteDetails)
      }
    } else {
      yield put(SitesManagerActions.siteManagerFailure())
    }
  } catch (e) {
    yield put(SitesManagerActions.siteManagerFailure())
  }
}

export function * getSiteUsers (api, { siteId }) {
  try {
    const response = yield call(api.getSiteUsers, siteId)
    if (response.ok) {
      yield put(SitesManagerActions.siteManagerUsersSuccess(response.data))
      const [first] = response.data || []
      yield put(UserManagerActions.userManagerSetCurrent(first ? first.id : ''))
    } else {
      yield put(SitesManagerActions.siteManagerUsersFailure())
    }
  } catch (e) {
    yield put(SitesManagerActions.siteManagerUsersFailure())
  }
}

export function * getSiteDevices (api, { siteId }) {
  try {
    const response = yield call(api.getSiteDevices, siteId)

    if (response.ok && response.data) {
      yield put(SitesManagerActions.siteManagerDevicesSuccess(response.data || []))
    } else {
      yield put(SitesManagerActions.siteManagerDevicesFailure())
    }
  } catch (e) {
    yield put(SitesManagerActions.siteManagerDevicesFailure())
  }
}

export function * getSiteDetails (api) {
  const siteId = yield select(state => state.siteManager.currentSite)
  const siteHierarchy = yield select(state => state.siteManager.sites[siteId]['organizationalUnitHierarchy'])

  yield all([
    put(SitesManagerActions.siteManagerSetCurrent(siteId)),
    put(SitesManagerActions.siteManagerUsersRequest(siteId)),
    put(SitesManagerActions.siteManagerDevicesRequest(siteId)),
    put(FirmwareActions.firmwareSetCurrentSite(siteHierarchy))
  ])
}

export function * createSite (api, { data, parentGuid }) {
  try {
    const newData = {
      ...data,
      parentIdentifier: parentGuid
        ? { organizationalUnitGuid: parentGuid }
        : { organizationalUnitGuid: null }
    }

    const response = yield call(api.createSite, newData)

    if (response.ok) {
      yield all([
        put(SitesManagerActions.siteManagerGenericSuccess()),
        call(toast, <SuccessToast />),
        put(SitesManagerActions.siteManagerRequest(true)),
        put(SitesManagerActions.siteManagerRootSitesRequest())
      ])
    } else {
      yield put(SitesManagerActions.siteManagerGenericFailure())
      if (response.status === 409 || response.status === 424) {
        yield call(toast, <ErrorToast message='This site is already exists' />)
      }
    }
  } catch (e) {
        yield all([
          put(SitesManagerActions.siteManagerGenericFailure()),
          call(toast, <ErrorToast />)
        ])
  }
}

export function * updateSite (api, { data, siteId, parentGuid }) {
  try {
    const newData = {
      ...data,
      parentIdentifier: parentGuid
        ? { organizationalUnitGuid: parentGuid }
        : { organizationalUnitGuid: null }
    }

    const response = yield call(api.updateSite, newData, siteId)

    if (response.ok) {
      yield all([
        put(SitesManagerActions.siteManagerGenericSuccess()),
        call(toast, <SuccessToast />),
        put(SitesManagerActions.siteManagerRequest(true)),
        put(SitesManagerActions.siteManagerRootSitesRequest())
      ])
    } else {
      yield all([
        put(SitesManagerActions.siteManagerGenericFailure()),
        call(toast, <ErrorToast />)
      ])
    }
  } catch (e) {
    yield all([
      put(SitesManagerActions.siteManagerGenericFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function * deleteSite (api, { siteId }) {
  try {
    const response = yield call(api.deleteSite, siteId)

    if (response.ok) {
      yield all([
        put(SitesManagerActions.siteManagerDeleteSuccess()),
        call(toast, <SuccessToast />),
        put(SitesManagerActions.siteManagerRequest(true)),
        put(SitesManagerActions.siteManagerRootSitesRequest())
      ])
    } else {
      yield all([
        put(SitesManagerActions.siteManagerGenericFailure()),
        call(toast, <ErrorToast />)
      ])
    }
  } catch (e) {
    yield all([
      put(SitesManagerActions.siteManagerGenericFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function * removeUser (api, { siteId, userId }) {
  try {
    const response = yield call(api.removeUser, siteId, userId)

    if (response.ok) {
      yield all([
        put(SitesManagerActions.siteManagerGenericSuccess()),
        call(toast, <SuccessToast />),
        put(SitesManagerActions.siteManagerUsersRequest(siteId))
      ])
    } else {
      yield all([
        put(SitesManagerActions.siteManagerGenericFailure()),
        call(toast, <ErrorToast />)
      ])
    }
  } catch (e) {
    yield all([
      put(SitesManagerActions.siteManagerGenericFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function * getRootSites (api) {
  try {
    const response = yield call(api.getRootSites)
    if (response.ok) {
      yield all([
        put(SitesManagerActions.siteManagerRootSitesSuccess(response.data))
      ])
    } else {
      yield put(SitesManagerActions.siteManagerRootSitesFailure())
    }
  } catch (e) {
    yield put(SitesManagerActions.siteManagerRootSitesFailure())
  }
}

export function * getSitesWithReports (api) {
  try {
    const response = yield call(api.getSitesWithReports)
    const { data } = response;
    if (response.ok && data && data.length) {
      yield put(SitesManagerActions.siteManagerRequestReportsSuccess(data))
    } else {
      yield put(SitesManagerActions.siteManagerRequestReportsFailure())
    }
  } catch (e) {
    yield put(SitesManagerActions.siteManagerRequestReportsFailure())
  }
}

export function * getOrgFeatures (api, {siteId} ) {
  try{
    const response = yield call(api.getOrgFeatures,siteId)
    if(response.ok){
      yield put(SitesManagerActions.siteManagerOrgSettingsSuccess(response.data))
    }
    else{
      yield put(SitesManagerActions.siteManagerOrgSettingsFailure())
      yield call(toast, <ErrorToast />)
    }
  }
  catch(e) {
    yield call(toast, <ErrorToast />)
  }
}

export function * UpdateOrgFeatures (api, {siteId, automaticSettings}) {
  try{
    const response = yield call(api.updateOrgFeatues, automaticSettings)
    if(response.ok){
      yield put(SitesManagerActions.siteManagerUpdateOrgSettingsSuccess())
      yield call(toast, <SuccessToast />)
        const response = yield call(api.getOrgFeatures,siteId)
        if(response.ok){
          yield put(SitesManagerActions.siteManagerOrgSettingsSuccess(response.data))
        }
    }
    else{
      yield put(SitesManagerActions.siteManagerUpdateOrgSettingsFailure())
      yield call(toast, <ErrorToast />)
    }
  }
  catch(e) {
    yield call(toast, <ErrorToast />)
  }
}
