import React, { useState, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import Excel from "exceljs";
import FileSaver from "file-saver";
import { Button } from "@scuf/common";
import { useDispatch } from "react-redux";
import { get, isEqual, assign } from "lodash";
import { find } from "ramda";
import { toast } from "react-toastify";
import { ErrorToast } from "../../../../Themes/ScufStyledComponents";

import {
  AccessContainer,
  ActionBarContainer,
  ActionBarSection,
  ActionContainer,
  ActionText,
  SimpleRow,
  StyledIcon,
  TableContainer,
  StyledCSVLink,
  ButtonsContainer,
  FooterContainer,
  StyledDataTable,
  StyledNotification,
  StyledIconDisabled,
  StyledActionText,
  ActionTextDisabled
} from "./network-ranges.styles";
import { useNetworkRanges } from "./network-ranges.hooks";
import { MainTitle, Title } from "../../../../Themes/ScufStyledComponents";
import CreateNetworkRanges from "../create-network-ranges";
import EditNetworkRanges from "../edit-network-ranges";
import { NetworkRangesActions } from "../../store";
import { StyledModal } from "../../../../Components/Modal/modal.styles";
import { defaultFlags, flagsPropTypes } from "../../../../Utils/launch-darkly-flags";
import DropFile from "../../../../Components/Upload/components/sites-bulk-edit/components/UploadFile";

const initialState = {
  isUploadModalOpen: false,
  isDeleteModalOpen: false,
  uploadModalName: "",
  deleteModalName: ""
};

function reducer(state, action) {
  switch (action.type) {
    case "showUploadModal":
      return {
        ...state,
        isUploadModalOpen: true,
        uploadModalName: action.modal
      };
    case "hideUploadModal":
      return {
        ...state,
        isUploadModalOpen: false,
        uploadModalName: action.modal
      };
    case "showDeleteModal":
      return {
        ...state,
        isDeleteModalOpen: true,
        deleteModalName: action.modal
      };
    case "hideDeleteModal":
      return {
        ...state,
        isDeleteModalOpen: false,
        deleteModalName: action.modal
      };

    default:
      return state;
  }
}

const networkRangeProperties = [
  { dataKey: "firstIpAddress", label: "StartIP", name: "firstIpAddress" },
  { dataKey: "lastIpAddress", label: "EndIP", name: "lastIpAddress" },
  { dataKey: "networkName", label: "Description", name: "networkName" }
];
const networkRangePropertiesOrg = [
  { dataKey: "firstIpAddress", label: "StartIP", name: "firstIpAddress" },
  { dataKey: "lastIpAddress", label: "EndIP", name: "lastIpAddress" },
  { dataKey: "networkName", label: "Description", name: "networkName" },
  { dataKey: "site", label: "Site", name: "site" }
];

const csvData = ["StartIPAddress", "EndIPAddress", "Description"];

// eslint-disable-next-line complexity
const NetworkRanges = ({
  flags,
  t,
  siteId,
  siteName,
  isEditModalVisible,
  permissions,
  rootSites,
  sites,
  sitesById
}) => {
  const dataRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [flagUpdate, setFlagUpdate] = useState(0);
  const [fileUpload, setFileUpload] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sitesHierarchy, setHierarchyExport] = useState();
  const [sitesIdentifier, setIdentifierExport] = useState();
  const [isOrg, setIsOrg] = useState(false);
  const [auxiliarSelectedRow, setAuxiliarSelectedRow] = useState(undefined);
  const previousModalState = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hideActionBar, setHideActionBar] = useState(false);
  const [dataComplete, setDataComplete] = useState([]);
  const [idSites, setIdSites] = useState();
  const [sitesFromFile, setSitesFromFile] = useState({});
  const tableName = "NetworkRanges_" + siteName;
  const {
    data,
    dataLoading,
    getNetworkRanges,
    importNetworkRanges,
    deleteNetworkRanges,
    downloadData,
    id
  } = useNetworkRanges();
  const reduxDispatch = useDispatch();

  React.useEffect(() => {
    getNetworkRanges(siteId);
  }, [flagUpdate]);

  React.useEffect(() => {
    {
      rootSites.find(element => element === siteId) ? setIsOrg(true) : setIsOrg(false);
    }
    const AllSites = Object.values(sites);
    const SitesHierarchy = AllSites.filter(obj => obj.hierarchy);
    const SitesAndOrg = SitesHierarchy.filter(obj => obj.orgId === siteId || obj.id === siteId);
    const identifier = Object.keys(SitesAndOrg).map(key => {
      return { hierarchy: SitesAndOrg[key].hierarchy, id: SitesAndOrg[key].id };
    });
    const hierarchy = Object.keys(SitesAndOrg).map(key => {
      return SitesAndOrg[key].hierarchy;
    });
    const ids = Object.keys(SitesAndOrg).map(key => {
      return SitesAndOrg[key].id;
    });
    setHierarchyExport(hierarchy);
    setIdentifierExport(identifier);
    setIdSites(ids);
  }, []);

  React.useEffect(() => {
    const arrayWithHierarchy = data.map(d => {
      const SitesIds = sitesIdentifier.filter(site => site.id === d.organizationUnitKey);
      if (SitesIds[0] != undefined) {
        return assign(d, { site: SitesIds[0].hierarchy });
      }
    });
    setDataComplete(arrayWithHierarchy);
    setExportData(
      data.map(d => ({ StartIPAddress: d.firstIpAddress, EndIPAddress: d.lastIpAddress, Description: d.networkName }))
    );
  }, [data]);

  React.useEffect(() => {
    if (selectedRows !== undefined) {
      setSelection(
        selectedRows.map(d => ({
          StartIPAddress: d.firstIpAddress,
          EndIPAddress: d.lastIpAddress,
          Description: d.networkName
        }))
      );
    }
  }, [selectedRows]);

  const submitFile = async () => {
    const hierarchy2Site = sitesFromFile.map(line =>
      get(
        find(obj => {
          return get(obj, "hierarchy", "").replace(/ /g, "") === get(line, "Site", "").replace(/ /g, "");
        })(sitesById),
        "id",
        " "
      )
    );
    const response = await importNetworkRanges(sitesFromFile, hierarchy2Site, isOrg);
    if (response) {
      setFileUpload(null);
      dispatch({ type: "hideUploadModal", modal: "bulkUploadModal" });
      setFlagUpdate(flagUpdate + 1);
    }
  };

  const openCreateNetworkRangeModal = () => {
    reduxDispatch(NetworkRangesActions.showCreateNetworkRangeModal());
  };

  const openEditNetworkRangeModal = networkRange => {
    reduxDispatch(NetworkRangesActions.showEditNetworkRangeModal(networkRange));
  };

  const onSelectionChange = (selected) => {
    if (!isEqual(selectedRows, selected) && !isOpen && !previousModalState.current) {
      setSelectedRows(selected);
      setHideActionBar(false);
    }
    if (!isOpen && previousModalState.current) {
      setTimeout(() => {
        previousModalState.current = isOpen;
      }, 4000);
    }
  };

  const unselectUpdatesFromTable = () => {
    setAuxiliarSelectedRow([]);
    setHideActionBar(true);
    setTimeout(() => {
      setAuxiliarSelectedRow(undefined);
    }, 500);
  };

  const onSubmitSuccess = siteId => () => {
    reduxDispatch(NetworkRangesActions.closeModal());
    getNetworkRanges(siteId);
    setSelectedRows();
  };

  const downloadFile = async () => {
    const workbook = new Excel.Workbook();
    workbook.creator = "OperationalIntelligence";
    const bulkEditSheet = workbook.addWorksheet("Device to Update");
    const sitesSheet = workbook.addWorksheet("sites");
    sitesSheet.getColumn(1).values = sitesHierarchy;
    sitesSheet.columns = [{ width: 35 }, { width: 30 }];
    bulkEditSheet.columns = isOrg
      ? [
        { header: "startIPAddress", key: "StartIPAddress", width: 30 },
        { header: "endIPAddress", key: "EndIPAddress", width: 30 },
        { header: "Description", key: "Description", width: 30 },
        { header: "Site", key: "Site", width: 30 }
      ]
      : [
        { header: "startIPAddress", key: "StartIPAddress", width: 30 },
        { header: "endIPAddress", key: "EndIPAddress", width: 30 },
        { header: "Description", key: "Description", width: 30 }
      ];
    if (dataComplete.length > 0) {
      bulkEditSheet.state = "visible";
      Object.keys(dataComplete).forEach((serial, index) => {
        const row = index + 2;
        const device = data[index];
        const site = sitesIdentifier.filter(site => site.id === device.organizationUnitKey);
        bulkEditSheet.getCell(`D${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: ["sites!$A:$A"]
        };
        if (isOrg) {
          bulkEditSheet.getCell(`C${row}`).value = device.networkName;
          bulkEditSheet.getCell(`A${row}`).value = device.firstIpAddress;
          bulkEditSheet.getCell(`B${row}`).value = device.lastIpAddress;
          bulkEditSheet.getCell(`D${row}`).value = site[0].hierarchy;
        } else {
          bulkEditSheet.getCell(`C${row}`).value = device.networkName;
          bulkEditSheet.getCell(`A${row}`).value = device.firstIpAddress;
          bulkEditSheet.getCell(`B${row}`).value = device.lastIpAddress;
        }
      });
    } else {
      const siteNameInFile = isOrg ? " / " + siteName : " ";
      bulkEditSheet.state = "visible";
      const row = 2;
      bulkEditSheet.getCell(`D${row}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: ["sites!$A:$A"]
      };
      bulkEditSheet.getCell(`C${row}`).value = "test range";
      bulkEditSheet.getCell(`A${row}`).value = "192.168.0.1";
      bulkEditSheet.getCell(`B${row}`).value = "192.168.0.255";
      bulkEditSheet.getCell(`D${row}`).value = siteNameInFile;
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const dataBook = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(dataBook, "Operational Intelligence Upload Network Ranges Template.xlsx");
  };

  const dataInTable = dataComplete.length > 0
  const fileName = "networkRanges-" + siteName + ".csv";
  const closeModalAction = () => {
    dispatch({ type: "hideDeleteModal", modal: "bulkDeleteModal" });
    setIsOpen(false);
    setSelectedRows([]);
    unselectUpdatesFromTable();
  }
  return (
    <AccessContainer>
      <StyledModal scroll={true} open={state.isUploadModalOpen} size="small">
        {data.length > 0 && (
          <StyledNotification hasIcon={true} severity="important" title={t("OverwriteNetworkRanges_Title")}>
            {t("OverwriteNetworkRanges_Text")}
          </StyledNotification>
        )}
        <StyledModal.Content>
          <MainTitle>{t("ImportDescription")}</MainTitle>
          {t("FileTypes")}
          <DropFile
            onClick={downloadFile}
            onLoadFile={setFileUpload}
            onHideTemplate={dataInTable}
            onLoadFileSuccess={lines => {
              setSitesFromFile(
                lines.map(index => {
                  return Object.assign(index);
                })
              );
            }}
          />
        </StyledModal.Content>
        <StyledModal.Footer>
          <FooterContainer>
            {dataInTable &&
              <StyledActionText onClick={() => downloadFile()}>{t("Download")}</StyledActionText>
            }
            <ButtonsContainer>
              <Button
                content="Cancel"
                onClick={() => dispatch({ type: "hideUploadModal", modal: "bulkUploadModal" })}
                size="medium"
                style={{ justifyContent: "flex-end" }}
                type="secondary"
              />
              <Button
                content="Submit"
                onClick={submitFile}
                size="medium"
                style={{ justifyContent: "flex-end" }}
                type="primary"
                disabled={sitesFromFile ? false : true}
              />
            </ButtonsContainer>
          </FooterContainer>
        </StyledModal.Footer>
      </StyledModal>
      <StyledModal open={state.isDeleteModalOpen} size="small" closeIcon={true} onClose={closeModalAction}>
        <StyledModal.Header>
          <Title>{t("Delete")}</Title>
        </StyledModal.Header>
        <StyledModal.Content>
          {selectedRows &&
            selectedRows.length > 1 &&
            t("Multiple_Delete_Confirmation", {
              networkRangeCount: get(selectedRows, "length", "")
            })}
          {selectedRows &&
            selectedRows.length === 1 &&
            t("Single_Delete_Confirmation", {
              networkRangeName: get(selectedRows, "0.name", "")
            })}
        </StyledModal.Content>
        <StyledModal.Footer>
          <Button
            content="Cancel"
            onClick={closeModalAction}
            size="medium"
            style={{ justifyContent: "flex-end" }}
            type="secondary"
          />
          <Button
            content="Submit"
            onClick={() => {
              deleteNetworkRanges(selectedRows.map(row => row));
              dispatch({ type: "hideDeleteModal", modal: "bulkDeleteModal" });
              setIsOpen(false);
              setSelectedRows([]);
              unselectUpdatesFromTable();
            }}
            size="medium"
            style={{ justifyContent: "flex-end" }}
            type="primary"
          />
        </StyledModal.Footer>
      </StyledModal>
      {!isEditModalVisible && (
        <CreateNetworkRanges organizationUnitKey={siteId} addressType={1} onSubmitSuccess={onSubmitSuccess(siteId)} />
      )}
      {isEditModalVisible && <EditNetworkRanges networkdId={id} onSubmitSuccess={onSubmitSuccess(siteId)} />}
      <ActionBarContainer>
        <ActionBarSection flex="none">
          <SimpleRow className="pointer">
            {!flags.networkRange && permissions["network_ranges:create"] && !isOrg && (
              <ActionContainer className="create-button" onClick={openCreateNetworkRangeModal}>
                <StyledIcon description={t("CreateDescription")} name="radio-frequencies" root="common" size="medium" />
                <ActionText>{t("Create")}</ActionText>
              </ActionContainer>
            )}
            {!flags.networkRange && permissions["network_ranges:create"] && (
              <ActionContainer
                className="import-button"
                onClick={() => dispatch({ type: "showUploadModal", modal: "bulkUploadModal" })}
              >
                <StyledIcon description={t("ImportDescription")} name="cloud-upload" root="common" size="medium" />
                <ActionText>{t("Import")}</ActionText>
              </ActionContainer>
            )}
            {!flags.networkRange && permissions["network_ranges:read"] && (
              <ActionContainer className="export-button">
                {data.length > 0 ? (
                  <StyledIcon description={t("ExportDescription")} name="exit" root="common" size="medium" />
                ) : (
                    <StyledIconDisabled description={t("ExportDescription")} name="exit" root="common" size="medium" />
                  )}
                  {isOrg && dataComplete.length > 0 ?
                    <ActionText onClick={() => downloadFile()}>
                      <ActionText >
                        {t('Export')}
                      </ActionText>
                    </ActionText>
                    : !isOrg && dataComplete.length > 0 ?
                      <ActionText onClick={() => dataRef.current.downloadCSV && dataRef.current.downloadCSV()}>
                        <ActionText>
                          {t('Export')}
                        </ActionText>
                      </ActionText>
                      :
                      <ActionText>
                        <ActionTextDisabled>
                          {t('Export')}
                        </ActionTextDisabled>
                      </ActionText>
                  }
              </ActionContainer>
            )}
          </SimpleRow>
        </ActionBarSection>
      </ActionBarContainer>
      <TableContainer>
        <StyledDataTable
          data={dataComplete}
          loading={dataLoading}
          onSelectionChange={onSelectionChange}
          reorderableColumns={true}
          scrollable={true}
          search={true}
          searchPlaceholder="Search"
          selection="multiple"
          totalRecords={dataComplete.length}
          rowHeight={32}
          hideActionBar={hideActionBar}
          selectedItems={auxiliarSelectedRow}
          ref={dataRef}
          tableName={tableName}
          titles={csvData}
        >
          <StyledDataTable.ActionBar clearSelectionOnClose={false}>
            {selectedRows && selectedRows.length === 1 && permissions["network_ranges:edit"] && !isOrg && (
              <StyledDataTable.ActionBar.Item
                content="Edit"
                icon="edit"
                iconroot="common"
                //iconsize='big'
                onClick={() => {
                  const [head] = selectedRows;
                  openEditNetworkRangeModal(head);
                  setIsOpen(false);
                  previousModalState.current = true;
                }}
              />
            )}
            {permissions["network_ranges:delete"] && (
              <StyledDataTable.ActionBar.Item
                content="Delete"
                icon="delete"
                iconroot="common"
                onClick={() => {
                  dispatch({ type: "showDeleteModal", modal: "bulkDeleteModal" });
                  setIsOpen(false);
                  previousModalState.current = true;
                }}
              />
            )}
          </StyledDataTable.ActionBar>
          {isOrg
            ? networkRangePropertiesOrg.map(column => {
              return (
                <StyledDataTable.Column
                  dataKey={column.dataKey}
                  label={t(column.label)}
                  key={column.dataKey}
                  sortable={true}
                />
              );
            })
            : networkRangeProperties.map(column => {
              return (
                <StyledDataTable.Column
                  dataKey={column.dataKey}
                  label={t(column.label)}
                  key={column.dataKey}
                  sortable={true}
                />
              );
            })}
        </StyledDataTable>
      </TableContainer>
    </AccessContainer>
  );
};

NetworkRanges.propTypes = {
  flags: flagsPropTypes,
  t: PropTypes.func
};

NetworkRanges.defaultProps = {
  flags: defaultFlags,
  permissions: {},
  t: key => key
};

export default NetworkRanges;
