import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import ModalActions from '../../Redux/ModalRedux'
import UserSettingsActions from '../../Redux/UserSettingsRedux'
import DashboardPreferences from './dashboard-preferences'
import DashboardPreferencesSelector from './dashboard-preferences.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal) => dispatch(ModalActions.modalOpen(modal)),
    closeModal: () => dispatch(ModalActions.modalClose()),
    changeDashboardTitle: (value) => dispatch(UserSettingsActions.changeDashboardTitle(value)),
    resetDashboardToDefaultState: () => dispatch(UserSettingsActions.resetDashboardToDefaultState())
  }
}

export default pipe(
  translate('DashboardPreferences'),
  connect(
    DashboardPreferencesSelector,
    mapDispatchToProps
  ),
  withLDConsumer()
)(DashboardPreferences)
