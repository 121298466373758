import styled from 'styled-components'

const WeekDaysContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const WeekDay = styled.div`
  padding: 1rem;
  border-width: ${({ selected }) => selected ? '0.1rem' : ''};
  border-style: ${({ selected }) => selected ? 'solid' : ''};
  border-color: ${({ selected }) => selected ? '#1274B7' : ''};
  background-color: ${({ theme }) => theme.backgroundheader || '#FFFFFF'};
`
export {
  WeekDaysContainer,
  WeekDay
}
