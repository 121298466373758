import React, { useState, useEffect } from 'react'
import { Button, DatePicker } from '@scuf/common'
import moment from 'moment'
import {
  ModalWrap,
  ModalContainer,
  HeaderWrap,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap
} from './custom-time-date-modal.styles'

const CustomTimeDateModal = ({
  closeModal,
  closeAndUpdate,
  submitAction,
  onCancel,
  t,
  theme
}) => {
  const [endDateError, setEndDateError] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [startDateError, setStartDateError] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const displayFormat = 'MM/DD/YY, HH:mm'
  const timespan = null

  const errorsCheck = (from, to) => {
    if(from && to){
      const start = moment(from).toISOString()
      const end = moment(to).toISOString()
      submitAction(start, end, { from, to })
      closeAndUpdate(`${start} - ${end}`)
      closeModal()
    }
  }

  const customCloseModal = () => {
    closeAndUpdate(timespan)
    closeModal()
  }

  useEffect(() => {
    const fromDateCheck = Math.ceil(moment().diff(startDate, 'days', true))
    const fromToDiff =  moment(endDate).diff(startDate, 'days', true)    

    setStartDateError(null)
    setEndDateError(null)
    setIsDisabled(true)  

    if(startDate && fromDateCheck > 90){
      setStartDateError(t('StartDateError'))
    }else if(endDate && fromToDiff > 30){
      setEndDateError(t('EndDateError'))
    }else if(endDate && fromToDiff < 0){
      setEndDateError(t('StartEndDateWarn'))
    }else if(startDate  && endDate){
      setIsDisabled(false)  
    }
  }, [startDate, endDate])

  return (
    <ModalWrap theme={theme}>
      <ModalHeader>
        <HeaderWrap>
          <h1>{t('Title')}</h1>
          <p>{t('Subtitle')}</p>
        </HeaderWrap>
      </ModalHeader>
      <ModalContainer>
        <DatePicker
          value={startDate}
          label={t('StartDate')}
          type='datetime'
          displayFormat={displayFormat}
          placeholder={displayFormat}
          disableFuture
          onChange={setStartDate}
          error={startDateError}
        />
        <DatePicker
          value={endDate}
          label={t('EndDate')}
          type='datetime'
          displayFormat={displayFormat}
          placeholder={displayFormat}
          disableFuture
          onChange={setEndDate}
          error={endDateError}
        />
      </ModalContainer>
      <ModalFooter>
        <FooterWrap>
          <ButtonWrap>
            <Button type='secondary' size='medium' content={t('Cancel')} onClick={() => customCloseModal()} />
            <Button type='primary' size='medium' disabled={isDisabled} content={t('Apply')} onClick={() => errorsCheck(startDate, endDate)} />
          </ButtonWrap>
        </FooterWrap>
      </ModalFooter>
    </ModalWrap>
  )
}

export default CustomTimeDateModal
