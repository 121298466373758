
export const getAlertColor = type => {
  let color = ''
  switch (type) {
    case 'warning':
      color = '#BE271C'
      break
    case 'alert':
      color = '#F5A623'
      break
    case 'info':
      color = '#1792E5'
      break
    default:
      color = '#808080'
      break
  }
  return color
}