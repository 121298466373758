import Excel from 'exceljs';
import { toast } from 'react-toastify';
import { ErrorToast } from 'Themes/ScufStyledComponents';

export const useAssetsImport = () => {
    const getAssetImportTemplate = async (t) => {
        try {
            const workbook = createTemplateWorkbook()
            await downloadTemplateWorkbook(workbook)
        } catch (e) {
            toast(<ErrorToast message={t('FromFileErrorMessage')} />)
        }
    }

    const onFileLoaded = async (setFileName, file) => {
        setFileName(file.name)
    }

    const onFileProcessed = async (data, setIsFileInvalid, setIsScheduleFromFile, setUploadFile, setFileName, t) => {
        try {
            if(Object.keys(data[0]).toString().toUpperCase() !== "SERIAL NUMBER") {
                setIsFileInvalid(true)
                setIsScheduleFromFile(false)
                setFileName('')
                return
            }
            const file = await createUploadFile(data)
            setIsFileInvalid(false)
            setUploadFile(file)  
            setIsScheduleFromFile(true)
        } catch (e) {
            setIsScheduleFromFile(false)
            setIsFileInvalid(false)  
            setFileName('')
            toast(<ErrorToast message={t('FromFileProcessErrorMessage')} />)
        }
    }

    const onFileReset = async (setIsScheduleFromFile, setUploadFile, setFileName) => {
        setIsScheduleFromFile(false)
        setFileName('')
        setUploadFile(null)
    }

    return { getAssetImportTemplate, onFileLoaded, onFileLoaded, onFileProcessed, onFileReset }
} 

function createTemplateWorkbook() {
    const workbook = new Excel.Workbook()
    workbook.creator = 'Operational Intelligence'
    const assetsSheet = workbook.addWorksheet('Assets')
    assetsSheet.columns = [
        { header: 'Serial Number', key: 'serialNumber', width: 30}
    ]
    assetsSheet.getColumn(1).numFmt = '@'

    return workbook
}

async function createUploadFile(data) {
    const workbook = new Excel.Workbook()
    workbook.creator = 'Operational Intelligence'
    const assetsSheet = workbook.addWorksheet('Assets')
    assetsSheet.columns = [
        { header: 'serialNumber', key: 'serialNumber', width: 30}
    ]
    data.map((d) => Object.values(d).map((val) => assetsSheet.addRow({serialNumber: val})))
    
    const buffer = await workbook.csv.writeBuffer()
    const uploadFile = new Blob([buffer], { type: 'text/csv;charset=utf-8;' })

    return uploadFile
}

async function downloadTemplateWorkbook(workbook) {
    const buffer = await workbook.xlsx.writeBuffer()

    const blob = new Blob(
        [buffer],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }
       )
    
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Operational Intelligence Assets SW Update.xlsx'
    anchor.click()
    window.URL.revokeObjectURL(url)
}