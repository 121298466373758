import {
  createStructuredSelector,
  createSelector
} from 'reselect'

export const tags = ({ tagging }) => tagging.getIn(['tags', 'result'], [])

export const selectedTags = createSelector(
  tags,
  ({ tagging }) => tagging.getIn(['tags', 'entities', 'tags'], {}),
  (results, tags) => results.reduce((selectedTags, tagId) => {
    const tag = tags.getIn([tagId], {})
    return tag.selected
      ? [
        ...selectedTags,
        tag
      ]
      : selectedTags
  }, [])
)

export default createStructuredSelector({
  tags,
  selectedTags,
  loading: ({ tagging }) => tagging.loading,
  devices: ({ tagging }) => tagging.getIn(['assets'], []),
  permissions: ({ permissions }) => permissions?.allPermissions
})
