export const initialState = {
  attested: false,
  content: '',
  datetime: '',
  version: '',
  userName: '',
  loading: false,
  waitValidation: true,
  error: null
}

export const subscriptionAgreementReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case initialSLARequest.type:
      return {
        ...state,
        loading: true,
        error: null,
        waitValidation: false
      }
    case initialSLASuccess.type:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      }
    case initialSLAFailure.type:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case setWaitValidation.type:
      return {
        ...state,
        waitValidation: payload,
      }
    case initialSLAVerificationRequest.type:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case initialSLAVerificationSuccess.type:
      return {
        ...state,
        attested: payload,
        content: '',
        loading: false,
        error: null,
      }
    case initialSLAVerificationFailure.type:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

const initialSLARequest = () => ({ type: initialSLARequest.type })
const initialSLASuccess = (sla = {}) => ({ type: initialSLASuccess.type, payload: sla })
const initialSLAFailure = (error = null) => ({ type: initialSLAFailure.type, payload: error })
const setWaitValidation = (waitValidation = 0) => ({ type: setWaitValidation.type, payload: waitValidation })
const initialSLAVerificationRequest = () => ({ type: initialSLAVerificationRequest.type })
const initialSLAVerificationSuccess = (attested = null) => ({ type: initialSLAVerificationSuccess.type, payload: attested })
const initialSLAVerificationFailure = (error = null) => ({ type: initialSLAVerificationFailure.type, payload: error })

const subscriptionAgreementPrefix = 'subscription-agreement/'
subscriptionAgreementReducer.slice = 'subscriptionAgreement'

initialSLARequest.type = subscriptionAgreementPrefix + 'initialSLARequest'
initialSLASuccess.type = subscriptionAgreementPrefix + 'initialSLASuccess'
initialSLAFailure.type = subscriptionAgreementPrefix + 'initialSLAFailure'
setWaitValidation.type = subscriptionAgreementPrefix + 'setWaitValidation'
initialSLAVerificationRequest.type = subscriptionAgreementPrefix + 'initialSLAVerificationRequest'
initialSLAVerificationSuccess.type = subscriptionAgreementPrefix + 'initialSLAVerificationSuccess'
initialSLAVerificationFailure.type = subscriptionAgreementPrefix + 'initialSLAVerificationFailure'

export const SubscriptionAgreementActions = {
  initialSLARequest,
  initialSLASuccess,
  initialSLAFailure,
  setWaitValidation,
  initialSLAVerificationRequest,
  initialSLAVerificationSuccess,
  initialSLAVerificationFailure
}
