import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import DashboardActions from '../../../../../Redux/DashboardRedux'
import SiteFilterModal from './site-filter-modal'
import SiteFilterModalSelector from './site-filter-modal.selector'
import SiteComparisonActions from 'Redux/SiteComparisonRedux'
import CounterActions from 'Redux/CounterRedux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'


const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose()),
    setSiteId: (user, value) => dispatch(DashboardActions.dashboardSetSiteId(user, value)),
    addSites: (siteId, level, siteName) => dispatch(SiteComparisonActions.addComparingSite(siteId, level, siteName)),
    addSite: (siteId, level, siteName) => dispatch(SiteComparisonActions.addRankingSite(siteId, level, siteName)),
    counterSites: (siteId, level, siteName) => dispatch(CounterActions.addSite(siteId, level, siteName))
  }
}

export default pipe(
  translate('DashboardCard'),
  connect(
    SiteFilterModalSelector,
    mapDispatchToProps
  )
)(SiteFilterModal)
