import React, { useState } from 'react'
import {
  Header,
  ActionWrapper,
  Actions,
  Container
} from './preferences-email.styles'
import AutomatedReportModal from './components/modal-form'

import { Icon } from '@scuf/common'
import 'react-toastify/dist/ReactToastify.css'
import PreferencesTable from '../preferences-table'
import { StyledToastContainer } from '../../../toasts'

const PreferencesEmail = ({
  handleSubmit,
  setSelectedReport,
  openModal,
  t
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <Header>
        <Actions>
          <ActionWrapper
            id='newReport'
            onClick={() => {
              openModal()
              setSelectedReport(null)
            }}
            color='black'
          >
            <Icon className='Icon' name='add' root='building' exactSize='1.25rem' /> {t('NewReport')}
          </ActionWrapper>
        </Actions>

      </Header>
      <PreferencesTable openModal={() => openModal()} />
      <AutomatedReportModal
        handleSubmit={handleSubmit}
        open={open}
        setOpen={setOpen}
      />
      <StyledToastContainer
        hideProgressBar={true}
        closeOnClick={false}
        closeButton={false}
        newestOnTop={true}
        toastClassName='toast-notification-wrap'
      />
    </Container>
  )
}

PreferencesEmail.defaultProps = {
  t: () => null
}
export default PreferencesEmail
