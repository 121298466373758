const create = (api) => {
  const getTotalCount = OrgUnitId => api.get(`OrgUnits/${OrgUnitId}/PersonsCount`)
  const updateLimit = (OrgUnitId, limit) => api.put(`OrgUnits/${OrgUnitId}/PersonCountLimit`, { limit })
  const changeCurrentCount = (OrgUnitId, personsCount) => api.put(`OrgUnits/${OrgUnitId}/PersonsCount`, { personsCount })

  return {
    getTotalCount,
    updateLimit,
    changeCurrentCount
  }
}

export default {
  create
}
