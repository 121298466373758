import Immutable from 'seamless-immutable'

const FIRST_PAGE = '@SW_DEVICES/FIRST_PAGE'
const NEXT_PAGE = '@SW_DEVICES/NEXT_PAGE'

export default {
  FIRST_PAGE,
  NEXT_PAGE
}

export const initialState = Immutable({
  devices: [],
  pageNumber: 0,
  totalCount: 0
})

function setfirstPage (state, { devices, totalCount }) {
  return state.merge({
    devices,
    totalCount,
    pageNumber: 1
  })
}

function appendPage (state, action) {
  return state.update(
    'devices',
    devices => [...devices, ...action.devices]
  )
    .merge({
      pageNumber: action.pageNumber
    })
}

export function reducer (state, action) {
  switch (action.type) {
    case FIRST_PAGE:
      return setfirstPage(state, action)
    case NEXT_PAGE:
      return appendPage(state, action)
    default:
      return state
  }
}
