import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeleteUser from './delete-user'
import mapStateToProps from './delete-user.selector'
import UserManagerActions from '../../Redux/UserManagerRedux'
import ModalActions from '../../Redux/ModalRedux'
import FilterActions from '../../Redux/FilterRedux'

export const mapDispatchToProps = dispatch => ({
  setCurrentUser: (userId) => dispatch(UserManagerActions.userManagerSetCurrent(userId)),
  deleteUser: (userId) => dispatch(UserManagerActions.userManagerDeleteRequest(userId)),
  closeModal: () => dispatch(ModalActions.modalClose()),
  setFilter: (filter) => dispatch(FilterActions.filterSetGlobalFilter(filter)),
  getUsers: () => dispatch(UserManagerActions.userManagerRequest())
})

export default translate('DeleteUserTable')(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteUser))
