import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Organization, OrganizationsResult } from '../service'

export interface OrganizationsState {
  organizationsById: Record<string, Organization>
  isLoading: boolean
  error: string | null
}

const organizationsInitialState: OrganizationsState = {
  organizationsById: {},
  isLoading: false,
  error: null
}

function startLoading (state: OrganizationsState) {
  state.isLoading = true
}

function loadingFailed (state: OrganizationsState, action: PayloadAction<string>) {
  state.isLoading = false
  state.error = action.payload
}

const organizations = createSlice({
  name: 'organizations',
  initialState: organizationsInitialState,
  reducers: {
    getOrganizationsRequest: startLoading,
    getOrganizationsSuccess (state, { payload } : PayloadAction<OrganizationsResult>) {
      const { organizations } = payload
      state.isLoading = false
      state.error = null

      organizations.forEach(org => {
        state.organizationsById[org.id] = org
      })
    },
    getOrganizationsFailure: loadingFailed
  }
})

export const {
  getOrganizationsRequest,
  getOrganizationsSuccess,
  getOrganizationsFailure
} = organizations.actions

export const organizationsReducer = organizations.reducer
