import { SortIconContainer, SortSvg } from '../../data-table.styles'
import SortIconDefault from 'Assets/Sort-Default.svg'
import SortIconAsc from 'Assets/Sort-A-Z.svg'
import SortIconDesc from 'Assets/Sort-Z-A.svg'

const SortIcon = ({ sortDirection = '' }) => {

  let svg = SortIconDefault

  switch (sortDirection) {
    case 'ascending':
      svg = SortIconAsc
      break
    case 'descending':
      svg = SortIconDesc
      break
    default:
      svg = SortIconDefault
      break
  }


  return (
    <SortIconContainer>
      <SortSvg src={svg} />
    </SortIconContainer>)
}

export default SortIcon
