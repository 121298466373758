import { createStructuredSelector } from 'reselect'

const initialState = {
  error: null,
  askMorePoints: false,
  data: [],
  loading: false,
  total: 0
}

export const locationTimelineReducer = (state = initialState, {type, payload} = {}) => {
  switch (type) {
    case initialPointsRequest.type:
      return {
        ...state,
        loading: true,
        error: null
      }
    case initialPointsNextPageRequest.type:
      return {
        ...state,
        loading: false,
        error: null
      }
    case initialPointsSuccess.type:
      return {
        ...state,
        error: null,
        loading: false,
        data: payload
      }
    case initialPointsFailure.type:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case initialPointsNextPageSuccess.type:
      return {
        ...state,
        error: null,
        loading: false,
        data: state.data.concat(payload)
      }
    case initialPointsNextPageFailure.type:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case initialEnableMorePoints.type:
      return {
        ...state,
        askMorePoints: payload,
        loading: false
      }
    case initialTotalPoints.type:
      return {
        ...state,
        total: payload,
        loading: false
      }
    default:
      return state
  }
}

//action creator
const initialPointsRequest = (deviceId, params) => ({ type: initialPointsRequest.type, deviceId, params })
const initialPointsSuccess = (points = []) => ({ type: initialPointsSuccess.type, payload: points })
const initialPointsFailure = (error = null) => ({ type: initialPointsFailure.type, payload: error })
const initialPointsNextPageRequest = (deviceId, params) => ({ type: initialPointsNextPageRequest.type, deviceId, params })
const initialPointsNextPageSuccess = (points = []) => ({ type: initialPointsNextPageSuccess.type, payload: points })
const initialPointsNextPageFailure = (error = null) => ({ type: initialPointsNextPageFailure.type, payload: error })
const initialEnableMorePoints = (askMorePoints = null) => ({ type: initialEnableMorePoints.type, payload: askMorePoints })
const initialTotalPoints = (total = 0) => ({ type: initialTotalPoints.type, payload: total })

const locationTimelinePrefix =  'location-timeline/'
locationTimelineReducer.slice = 'location-timeline'

initialPointsRequest.type = locationTimelinePrefix + 'initialPointsRequest'
initialPointsSuccess.type = locationTimelinePrefix + 'initialPointsSuccess'
initialPointsFailure.type = locationTimelinePrefix + 'initialPointsFailure'
initialPointsNextPageRequest.type = locationTimelinePrefix + 'initialPointsNextPageRequest'
initialPointsNextPageSuccess.type = locationTimelinePrefix + 'initialPointsNextPageSuccess'
initialPointsNextPageFailure.type = locationTimelinePrefix + 'initialPointsNextPageFailure'
initialEnableMorePoints.type = locationTimelinePrefix + 'initialEnableMorePoints'
initialTotalPoints.type = locationTimelinePrefix + 'initialTotalPoints'

export const LocationTimelineActions = {
  initialPointsRequest,
  initialPointsSuccess,
  initialPointsFailure,
  initialPointsNextPageRequest,
  initialPointsNextPageSuccess,
  initialPointsNextPageFailure,
  initialEnableMorePoints,
  initialTotalPoints
}

export const getLocationTimelineListTest = (state) => state.data
export const getLoadingTest = (state) => state.loading
export const getErrorTest = (state) => state.error
export const getEnableMorePointsTest = state => state.askMorePoints
export const getInitialTotalPointsTest = state => state.total

export const getLocationTimelineList = (state) => state[locationTimelineReducer.slice].data
export const getError = state => state[locationTimelineReducer.slice].error
export const getLoading = state => state[locationTimelineReducer.slice].loading
export const getEnableMorePoints = state => state[locationTimelineReducer.slice].askMorePoints
export const getInitialTotalPoints = state => state[locationTimelineReducer.slice].total


export const getLocationTimelineSelector = createStructuredSelector({
  points: getLocationTimelineList,
  error: getError,
  loading: getLoading,
  askMorePoints: getEnableMorePoints,
  total: getInitialTotalPoints
})
