export class ContactTracingService {
  constructor (api) {
    this.api = api
  }

  getEventsContacts (params) {
    return this.api.get('/v1/events/proximity/contacts', params)
  }

  async getEvents (filter) {
    const response = await this.api.get('/v1/events/proximity/traces', {
      filter: JSON.stringify(filter)
    })
    if (!response.ok) {
      return response
    }
    const data = response.data?.data?.map(event => {
      const secondary = event?.secondary || {}
      const primaryDeviceName = event?.primary?.deviceName
      return {
        ...event,
        ...secondary,
        primaryDeviceName
      }
    })
    return {
      ...response,
      data
    }
  }
}
