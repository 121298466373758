const create = (api) => {
  const getExportFile = (siteId, startDate, endDate, chartName, language) => {
    const params = {
      startDate: startDate,
      endDate: endDate,
      query: `{"summary.siteId":"${siteId}"}`,
      includeChildren: 'true',
      sort: chartName === 'applicationUsage' ? `{"usage.total":"desc"}` : `{"network.total":"desc"}`,
      chartName: chartName === 'applicationUsage' ? 'APPLICATION_USAGE' : 'NETWORK_IO_USAGE',
      language: language,
      fields: 'network,usage',
      groupBy: 'name'
    }
    return api.post(`/dashboard/exportfiles/${siteId}`, {BatteriesExporter: []}, { params })
  }

  const getExportFileDetailByName = (siteId, name, startDate, endDate, chartName, language) => {
    const params = {
      startDate: startDate,
      endDate: endDate,
      query: `{"summary.siteId":"${siteId}", "name":"${name}"}`,
      includeChildren: 'true',
      sort: chartName === 'applicationUsage' ? `{"usage.total":"desc"}` : `{"network.total":"desc"}`,
      chartName: chartName === 'applicationUsage' ? 'APPLICATION_USAGE' : 'NETWORK_IO_USAGE',
      language: language,
      fields: 'network,usage,summary',
      groupBy: 'summary.sourceId'
    }
    return api.post(`/dashboard/exportfiles/${siteId}`, {BatteriesExporter: []}, { params })
  }

  return {
    getExportFile,
    getExportFileDetailByName
  }
}

export default {
  create
}
