import React, { Fragment,useEffect} from 'react'
import { FileDrop, Progress, InputLabel } from '@scuf/common'
import { translate } from 'react-translate'
import {
  Title
} from '../../../Themes/ScufStyledComponents'

export const FileDropWrapper = ({
  meta,
  input,
  loadingFile,
  loadingHash,
  loadedPercent,
  acceptedTypes,
  label,
  t,
  fileType
}) => {
  useEffect(()=>{
    const buttonsWithClass = document.getElementsByClassName("ui medium button inline-secondary upload-button")
    if(buttonsWithClass.length > 1){
      document.getElementsByClassName("ui medium button inline-secondary upload-button")[1].click()
    }
  },[fileType])

  return (
    <div>
      {loadingFile
        ? <Fragment>
          <InputLabel label={label} />
          <div className='ui file-drop'>
            <div className='drop-zone'>
              {!loadingHash && <Progress size='large' percent={loadedPercent} circular />}
              {loadingHash && <Title>{t('Calculating Hash')}</Title>}
            </div>
          </div>
        </Fragment>
        : null
      }
      <FileDrop
        acceptedTypes={acceptedTypes}
        className={loadingFile ? 'display-none' : null}
        error={meta.error && t(meta.error)}
        label={label}
        multiple={false}
        onAccepted={([file]) => input.onChange(file)}
        onReset={() => input.onChange(null)}
        placeholder='Drop or Upload Any File'
      />
    </div>
  )
}

FileDropWrapper.defaultProps = {
  acceptedTypes: [],
  label: 'Upload Asset',
  t: l => l,
  loadingHash: false
}

const adaptFileEventToValue = delegate =>
  e => delegate(e.target.files[0])

export const FileInput = ({
  input: {
    value: omitValue,
    onChange,
    onBlur,
    ...inputProps
  },
  meta: omitMeta,
  ...props
}) =>
  <input
    onChange={adaptFileEventToValue(onChange)}
    onBlur={adaptFileEventToValue(onBlur)}
    type='file'
    {...inputProps}
    {...props}
  />

export default translate('FileDropWrapper')(FileDropWrapper)
