import React from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'

const Select = styled.select`
  border: ${props => props.error && props.incomplete ? '1px solid #BE271C' : props.incomplete ? '1px solid #ffC627' : ''};
`

const DataSelection = (props) => {
  const { id, dataKey, options } = props
  return (
    <Field
      {...props}
      name={`${id}.${dataKey}`}
      component={({
        input,
        meta
      }) => {
        return (
          <Select
            {...input}
            onClick={e => {
              e.stopPropagation()
            }}
            incomplete={meta.invalid}
            error={meta.error}
          >
            {options.map(({ text, value }, i) => {
              return (
                <option key={i} value={value}>{text}</option>
              )
            })}
          </Select>
        )
      }}
    />
  )
}

export default DataSelection
