import React, { Component } from 'react'
import moment from 'moment'
import { Modal, DatePicker } from '@scuf/common'
import { ModalWrap, ModalTitle, DataTableContainer, DatePickerContainer } from './application-report-modal.styles'
import AssetManagementTable from '../../AssetManagementTable'
import PropTypes from 'prop-types'

class ApplicationReportModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      columns: [
        { field: 'appName', translation: 'AssetManagementTable_appName', render: ({ value }) => <p>{value}</p>, initialWidth:'20%' },
        { field: 'pkgName', translation: 'AssetManagementTable_appPackageName', render:({ value }) => <p>{value}</p>, initialWidth:'28%' },
        { field: 'useTime', translation: 'AssetManagementTable_useTime', render: ({ value }) => <p>{value}</p> },
        { field: 'netIn', translation: 'AssetManagementTable_netIn', render: ({ value }) => <p>{value}</p> },
        { field: 'netOut', translation: 'AssetManagementTable_netOut', render: ({ value }) => <p>{value}</p> }
      ],
      rangeValue: this.props.defaultRangeValue,
      error: '',
      to: '',
      from: ''
    }
  }

  componentDidMount () {
    const startDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DDTHH')
    const endDate = moment().utc().add(1, 'hours').format('YYYY-MM-DDTHH')
    this.props.getDeviceAppDataCustom(this.props.deviceId, startDate, endDate)
  }

  render () {
    const { deviceId, loading, data, getDeviceAppDataCustom, t } = this.props
    const { columns, rangeValue, error } = this.state
    const { startDateError, endDateError } = this.state

    return (
      <ModalWrap>
        <ModalTitle>{t('AppUsage_Title')}</ModalTitle>
        <Modal.Content style={{ borderBottom: '0px' }}>
          <DatePickerContainer>
            <DatePicker
              closeOnDocumentClick={true}
              closeOnSelection={true}
              disableFuture={true}
              disablePast={false}
              displayFormat='DD/MM/YYYY'
              error={error || startDateError || endDateError}
              key={'true'.toString()}
              label='Select Date Range'
              onRangeSelect={({ from, to }) => {
                this.setState({
                  to: typeof to === 'object' ? to.toDateString() : '',
                  from: typeof from === 'object' ? from.toDateString() : '',
                  ...(to && from && { rangeValue: { to, from } })
                })
                if (from && to) {
                  const sDate = moment(new Date(from)).utc().format('YYYY-MM-DDTHH')
                  const eDate = moment(new Date(to)).utc().add(1, 'days').format('YYYY-MM-DDTHH')

                  const fromDateCheck = Math.ceil(moment().diff(sDate, 'days', true))
                  const fromToDiff =  moment(eDate).diff(sDate, 'days', true)

                  if(sDate && fromDateCheck > 15) {
                    this.setState({ startDateError: t('StartDateErrorFourteen') })
                  }else if(fromToDiff > 15) {
                    this.setState({ endDateError: t('EndDateErrorFourteen') })
                  }else if(eDate && fromToDiff < 0) {
                    this.setState({ endDateError: t('StartEndDateWarn') })
                  }else if(sDate && eDate){
                    this.setState({ startDateError: false, endDateError: false })
                    getDeviceAppDataCustom(deviceId, sDate, eDate)
                  }

                } else if (from === undefined && to === undefined) {
                  getDeviceAppDataCustom(deviceId)
                }
              }
              }
              onTextChange={(value, error) => this.setState({ error })}
              rangeValue={rangeValue}
              type='daterange'
            />
          </DatePickerContainer>

          <DataTableContainer>
            <AssetManagementTable
              columns={columns}
              data={data}
              disableRowClick={true}
              fileName='Application Usage Reports'
              loading={loading}
              selectionMode='none'
              isApplicationReportExportsButtonDisable={startDateError || endDateError ? true : false}
            />
          </DataTableContainer>
        </Modal.Content>
      </ModalWrap>
    )
  }
}

ApplicationReportModal.defaultProps = {
  data: [],
  getDeviceAppDataCustom: () => null,
  loading: false
}

ApplicationReportModal.propTypes = {
  data: PropTypes.array,
  deviceId: PropTypes.string,
  defaultRangeValue: PropTypes.object,
  getDeviceAppDataCustom: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.func
}

export default ApplicationReportModal