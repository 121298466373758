import { EndUserLicenseAgreementActions } from 'features/end-user-license/end-user-license.reducer'
import React from 'react'
import { toast } from 'react-toastify'
import { all, call, put } from 'redux-saga/effects'
import { ErrorToast } from 'Themes/ScufStyledComponents'

export function* getEulaVerification(api) {
  const response = yield call(api.getEULAVerification)
  if (response.ok) {
    yield put(EndUserLicenseAgreementActions.initialEULASuccess(response.data))
  } else {
    yield all([
      put(EndUserLicenseAgreementActions.initialEULAFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* sendEulaValidation(api) {
  const response = yield call(api.sendEulaVerification)
  if (response.ok) {
    yield put(EndUserLicenseAgreementActions.initialEULAVerificationSuccess(response.data.updated))
  } else {
    yield all([
      put(EndUserLicenseAgreementActions.initialEULAVerificationFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}
