import NetworkRanges from './network-ranges'
import { pipe } from 'ramda'
import { translate } from 'react-translate'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import mapStateToProps from './network-ranges.selector'
import { withPermissions } from 'Utils/permissions'


export default pipe(
  translate('NetworkRanges'),
  withLDConsumer(),
  connect(
    mapStateToProps,
  ),
  withPermissions((_, props) => props.siteId)
)(NetworkRanges)
