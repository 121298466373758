import { createSelector } from 'reselect'
import { organizationsIds } from 'Selectors/LoaderSelector'

export const orgId = ({ login }) => login.selectedOrgId

export const orgName = createSelector(
  organizationsIds,
  orgId,
  (organizationsIds, orgId) => organizationsIds.filter(org => org.siteId === orgId)
    .getIn(['0', 'siteName'], '')
)

export default (state) => {
  return {
    orgName: orgName(state)
  }
}
