import { AsyncThunkPayloadCreator, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import * as API from '../apis'
import throttle from 'lodash/throttle'
import { RootState } from 'Store'
import { ErrorMessage } from '../../../Services/api-response'
import { LoginTypes } from '../../../Redux/LoginRedux'

const assetTypesAdapter = createEntityAdapter<API.AssetType>({
  selectId: (model: API.AssetType) => model.id,
  sortComparer: (a: API.AssetType, b: API.AssetType) =>
    a.name.localeCompare(b.name)
})

const fetchAssetTypesPayloadCreator: AsyncThunkPayloadCreator<API.AssetType[], string, { rejectValue: string }> =
  async (organizationId, thunkAPI) => {
    const response = await API.getAssetTypes(organizationId)
    if (response.ok && response.data?.data) {
      return response.data.data
    }
    return thunkAPI.rejectWithValue(`There was an error fetching asset types.`)
  }

export const fetchAssetTypes = createAsyncThunk<API.AssetType[], string, { rejectValue: string }>(
  'assetTypes/fetchAll',
  // @ts-ignore
  throttle(fetchAssetTypesPayloadCreator, 1000, { leading: true, trailing: false })
)

export const createAssetType = createAsyncThunk<API.AssetType, API.CreateAssetType, { rejectValue: ErrorMessage[] }>(
  'assetTypes/createOne',
  async (assetType, thunkAPI) => {
    const response = await API.createAssetType(assetType)
    if (response.ok && response.data?.data) {
      return response.data.data
    } else if (response.data?.errors) {
      return thunkAPI.rejectWithValue(response.data.errors)
    }
    throw new Error('There was an error creating the asset type.')
  }
)

export const assetTypesSlice = createSlice({
  name: 'assetTypes',
  initialState: assetTypesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(LoginTypes.LOGIN_SET_CURRENT_ORGANIZATION, assetTypesAdapter.removeAll)
    builder.addCase(fetchAssetTypes.fulfilled, assetTypesAdapter.upsertMany)
    builder.addCase(createAssetType.fulfilled, assetTypesAdapter.addOne)
  }
})

export const {
  selectById: selectAssetTypeById,
  selectIds: selectAssetTypeIds,
  selectEntities: selectAssetTypeEntities,
  selectAll: selectAllAssetTypes,
  selectTotal: selectTotalAssetTypes
} = assetTypesAdapter.getSelectors((state: RootState) => state.assetTypes)

export const assetTypesReducer = assetTypesSlice.reducer
