import { connect } from 'react-redux'
import mapStateToProps from './preferences-table.selector'
import PreferencesTable from './preferences-table'
import PreferencesActions from '../../../../Redux/PreferencesRedux'
import SiteManagerActions from '../../../../Redux/SiteManagerRedux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = dispatch => ({
  onDelete: (preferencesId) => dispatch(PreferencesActions.preferencesEmailDeleteRequest(preferencesId)),
  getScheduledReports: userId => dispatch(PreferencesActions.preferencesEmailGetRequest(userId)),
  getSites: () => dispatch(SiteManagerActions.siteManagerRequest()),
  setSelectedReport: (id) => dispatch(PreferencesActions.preferencesSetSelectedReport(id))
})

export default pipe(
  translate('PreferencesEmail'),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(PreferencesTable)
