import React, { useEffect } from 'react'
import { useTranslate } from 'react-translate'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { parse } from 'qs'
import { Loader } from '@scuf/common'

import { SsoOauthActions } from '../store'

export function useOauthCallback () {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isPending, isFulfilled } = useSelector(({ login, ssoOauth }) => ({
    isPending: ssoOauth.isPending,
    isFulfilled: ssoOauth.isFulfilled
  }))

  useEffect(() => {
    const { code } = parse(history.location.search, { ignoreQueryPrefix: true })
    // For some reason this component gets remounted after login success
    // which tries to exchange the code twice. So, we check if the request is fulfilled
    if (!isPending && !isFulfilled) {
      dispatch(SsoOauthActions.exchangeOauthCode(code))
    }
  }, [dispatch, history.location.search, isPending, isFulfilled])
}

function OauthCallback () {
  const t = useTranslate('Callback')
  useOauthCallback()
  return (
    <Loader text={t('Callback_Logging')}>
      <div className='placeholder' />
    </Loader>
  )
}

export default OauthCallback
