import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    background-color:transparent !important;
  }
`

const ModalTitle = styled(Modal.Header)`
  font-size: 1.75rem;
`
const TreeContainer = styled.div`
  & .tree-content .tree-item .content {
    font-size: 0.9rem;
  
    & .h-icon {
      font-size: 0.9rem;
    }
  }
  .ui.tree {
    width: 100%;
  }
  position: relative;
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.background ? props.theme.background: '#ffffff'};;
  border: 1px solid #D0D0D0;
  height: 60vh !important;
  width: 100%;
  overflow-y: scroll; 
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
`

const EmptyMessage = styled.div`
  padding: 1rem;
`
export { ModalWrap, ModalTitle, TreeContainer, ActionWrapper, EmptyMessage }
