import {
  createStructuredSelector,
  createSelector
} from 'reselect'
import {
  flatMap
} from 'lodash'
import { find, propEq } from 'ramda'

export const tags = ({ tagging }) => tagging.getIn(['tags', 'result'], [])

export const selectedTags = createSelector(
  tags,
  ({ tagging }) => tagging.getIn(['tags', 'entities', 'tags'], {}),
  (results, tags) => results.reduce((selectedTags, tagId) => {
    const tag = tags.getIn([tagId], {})
    return tag.selected
      ? [
        ...selectedTags,
        tag
      ]
      : selectedTags
  }, [])
)

export const selectedTagsWithDevices = createSelector(
  selectedTags,
  ({ tagging }) => tagging.getIn(['tags', 'entities', 'asset'], {}),
  ({ tagging }) => tagging.getIn(['assets'], []),
  (tags, assets, devices) => flatMap(
    tags,
    tag => tag.assets.map((asset) => ({
      key: tag.key,
      value: tag.value,
      newValue: tag.newValue,
      ...assets.getIn([asset], {}),
      siteChildId: (find(propEq('AssetId', asset))(devices) || {}).getIn(['SiteId'], ''),
      siteName: (find(propEq('AssetId', asset))(devices) || {}).getIn(['siteName'], ''),
      assetUniqueIdentifier: (find(propEq('AssetId', asset))(devices) || {}).getIn(['AssetUniqueIdentifier'], '')
    }))
  )
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  tags,
  theme,
  selectedTags: selectedTagsWithDevices
})
