import React from 'react'
import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'
import config from '../../../../Config/AppConfig'
import { ContentLoader } from '../../../DeviceCard/devicecard.styles'
import { Title } from '../../../../Themes/ScufStyledComponents'

const LargerScrollingContent = styled(Modal.Content)`
  max-height: 90vh !important;
  display: flex;
`
const ModalContentWrapper = styled.div`
  width: 900px;
  height: 800px;
  border-style: none;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoaderPosition = styled.div`
  position: absolute;
  left: 45%;
  top: 45%;
`
const FailedMsgPosition = styled.div`
  font-size: large;
`
const RemoteAccessModal = ({ remoteAccessDeviceId, remoteAccessLoading, accessToken, t }) => {

  const url = `${config.api.remoteAccessUrl}?deviceId=${remoteAccessDeviceId}`

  function iFrameLoaded(){
    let frame = document.getElementById('RAFrame');
    frame.contentWindow.postMessage({ accessToken, deviceId: remoteAccessDeviceId }, '*');
  }

  return (
    <div>
      <Title>{t('remote_access')}</Title>
      <LargerScrollingContent size='large'>
        {
          remoteAccessLoading || remoteAccessDeviceId != null
            ? <ModalContentWrapper>
                <iframe
                  id="RAFrame"
                  title='Remote Access'
                  src={url}
                  style={{
                    width: '850px',
                    height: '800px',
                    borderStyle: 'none',
                    alignSelf: 'center'
                  }}
                  onLoad={() => iFrameLoaded()} />
              </ModalContentWrapper>
            : <ModalContentWrapper>
              <FailedMsgPosition><b>{t('remote_access_failed')}</b></FailedMsgPosition>
            </ModalContentWrapper>
        }

      </LargerScrollingContent>
    </div>
  )
}

export default RemoteAccessModal
