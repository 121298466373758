import React, { Component, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import {
  Button
} from '@scuf/common'


const FormZone = lazy(() => import('../form-zone'))
const FormBuilding = lazy(() => import('../form-building'))
const FormSubZone = lazy(() => import('../form-subzone'))

const MenuFormContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
`

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 1rem;
  border: 1px solid black;
`

class Form extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tab: null
    }
    // TODO: after master modal defines a clear pattern, this will not be needed
    // but until then it will be buggy, when changing tabs
    // props.onChange(false, () => null)
    this._onChange = this._onChange.bind(this)
  }

  _onChange (a, b, { valid, submit }) {
    this.props.onChange(valid, submit)
  }

  componentDidMount () {
    const { locationType } = this.props
    switch (locationType) {
      case 'Building':
        this.setState({ tab: 0 })
        break;
      case 'zone':
        this.setState({ tab: 1 })
        break;
      case 'subzone':
        this.setState({ tab: 2 })
        break;
      default:
        this.setState({ tab: 0})
        break;
    }
  }

  componentDidUpdate (prevProps) {
    const { locationType } = this.props
    if (prevProps.locationType !== locationType) {
      switch (locationType) {
        case 'Building':
          this.setState({ tab: 0 })
          break;
        case 'zone':
          this.setState({ tab: 1 })
          break;
        case 'subzone':
          this.setState({ tab: 2 })
          break;
        default:
          this.setState({ tab: 0})
          break;
      }
    }
  }

  render () {
    const { tab } = this.state
    const { locationType, locationGuid, t } = this.props
    return (
      <MenuFormContainer>
        <Button.Group className='Location-Button-Group'>
          <Button
            type={tab === 0 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_Buildings')}
            disabled={tab !== 0}
          />
          <Button
            type={tab === 1 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_FloorPlans')}
            disabled={tab !== 1}
          />
          <Button
            type={tab === 2 ? 'inline' : 'inline-secondary'}
            content={t('LocationForm_Zones')}
            disabled={tab !== 2}
          />
        </Button.Group>
        {tab === 0 && <Suspense
          fallback={
            <FormContainer>{t("LocationForm_Loading")}</FormContainer>
          }>
            <FormBuilding onChange={this._onChange} />
          </Suspense>
        }
        {tab === 1 && <Suspense
          fallback={
            <FormContainer>{t("LocationForm_Loading")}</FormContainer>
          }>
            <FormZone refrenceid={locationGuid} onChange={this._onChange} />
          </Suspense>
        }
        {tab === 2 && <Suspense
          fallback={
            <FormContainer>{t("LocationForm_Loading")}</FormContainer>
          }>
            <FormSubZone loading={false} onChange={this._onChange} />
          </Suspense>
        }
      </MenuFormContainer>
    )
  }
}

Form.defaultProps = {
  t: label => label
}

export default Form
