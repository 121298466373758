import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button
} from '@scuf/common'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import {
  Rows,
  Padding,
  DateContainer,
  Content,
  Footer,
  TimeControls
} from './alerts-management-modal.styles'
import {
  Input,
  Select,
  Checkbox,
  DatePicker,
  DaysPicker
} from 'Components/Shared/formiks'
import TimeSelection from './time.component'
import { AlertsApi } from 'Services'

const AlertsManagementModal = ({
  closeAlertsModal,
  isOpen,
  t,
  closeModal,
  site,
  onSubmit,
  handleSubmit,
  isSubmitting,
  handleReset,
  disabled,
  title
}) => {
  const { values: { repeated, scheduleTimeZoneId } } = useFormikContext()
  const [timezones, setTimeZones] = useState([])
  const hoursOptions = Array.from(Array(12).keys()).map(e => {
    const value = e + 1
    return {
      value,
      text: value === 1 ? t('OneHourOption', { value: value }) : t('HoursOptions', { value: value })
    }
  })

  const getTimeZones = async () => {
    const result = await AlertsApi.getTimeZones()
    if (result.ok) {
      setTimeZones(
        get(result, ['data'], [])
          .map(name => ({
            value: name,
            text: name
          }))
      )
    } else {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      setTimeZones([{
        value: timezone,
        text: timezone
      }])
    }
  }

  useEffect(() => {
    if (!disabled) {
      getTimeZones()
    } else if (scheduleTimeZoneId) {
      setTimeZones([{
        value: scheduleTimeZoneId,
        text: scheduleTimeZoneId
      }])
    }
  }, [disabled, scheduleTimeZoneId])

  const handleCancel = () => {
    handleReset()
    closeModal()
  }

  return (
    <Modal
      open={isOpen}
      size='medium'
      closeIcon
      onClose={closeModal}
    >
      <Modal.Header>
        {title}
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Content>
          <Modal.Content>
            <Input
              label={t('Name')}
              name='name'
              fluid
              placeholder={t('Enter alert name')}
              disabled={disabled}
            />
            <Checkbox
              label={t('Apply to Checked out devices only')}
              name='checkedDevices'
              disabled={disabled}
            />
            <DateContainer>
              <TimeControls>
                <DatePicker
                  label={t('Date')}
                  type='datetime'
                  name='scheduleTime'
                  disabled={repeated || disabled}
                  fluid
                  displayFormat='MM/DD/YY, H:mm'
                  minuteStep={10}
                />
              </TimeControls>
            </DateContainer>
            <Checkbox
              label={t('Repeat')}
              name='repeated'
              disabled={disabled}
            />
            <div hidden={!repeated}>
              <DateContainer>
                <TimeControls>
                  <Select
                    name='scheduleTimeZoneId'
                    label={t('TimeZone')}
                    options={timezones}
                    noPadding
                    disabled={disabled}
                    fluid
                    search
                  />
                </TimeControls>
                <TimeControls>
                  <Select
                    name='repeat'
                    label={t('Repeat')}
                    options={hoursOptions}
                    fluid
                    noPadding
                    disabled={disabled}
                  />
                </TimeControls>
              </DateContainer>
              <Padding />
              <DaysPicker
                key='en'
                name='days'
                label={t('Days')}
                disabled={disabled}
              />
              <Padding />
              <Rows>
                <TimeSelection
                  name='fromHour'
                  label={t('FromHour')}
                  disabled={disabled}
                />
                <TimeSelection
                  name='toHour'
                  label={t('ToHour')}
                  disabled={disabled}
                />
              </Rows>
            </div>
          </Modal.Content>
        </Content>
        <Footer>
          <Modal.Footer>
            <Button
              content={t('Cancel')}
              onClick={handleCancel}
              type='secondary'
              disabled={disabled}
            />
            <Button
              content={t('Save')}
              actionType='submit'
              disabled={isSubmitting || disabled}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </Footer>
      </form>
    </Modal>
  )
}

AlertsManagementModal.defaultProps = {
  isOpen: false,
  t: label => label,
  handleSubmit: () => { }
}

AlertsManagementModal.propTypes = {
  t: PropTypes.func
}

export default AlertsManagementModal
