import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { TaggingActionTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  tags: {},
  error: {},
  assets: []
})

export const devicesTagsRequest = (state, { assets }) => state.merge({
  loading: true,
  assets
})

export const devicesTagsSuccess = (state, { tags }) => state.merge({
  loading: false,
  tags
})

export const deviceTagsFailure = (state, { error }) => state.merge({
  loading: false,
  error
})

export const toggleSelection = (state, { id }) => state.updateIn(['tags', 'entities', 'tags', id, 'selected'], selected => !selected)

export const devicesCreateTagRequest = state => state.merge({ loading: true })

export const tagsAllKeysRequest = state => state

export const tagsAllKeysSuccess = (state, { values }) => state
  .updateIn(['tags', 'entities', 'tags'], (stateToUpdate, tags) => {
    return tags.reduce((currentState, tag) => {
      return currentState.setIn([tag.tag, 'values'], tag.values)
    }, stateToUpdate)
  }, values)

export const tagUpdateValue = (state, { key, newValue }) => state
  .setIn(['tags', 'entities', 'tags', key, 'newValue'], newValue)

export const updateTagsRequest = state => state.merge({ loading: true })

export const updateTagsSuccess = state => state.merge({ loading: false })

export const reducer = createReducer(INITIAL_STATE, {
  [TaggingActionTypes.DEVICES_TAGS_REQUEST]: devicesTagsRequest,
  [TaggingActionTypes.DEVICES_TAGS_SUCCESS]: devicesTagsSuccess,
  [TaggingActionTypes.DEVICES_TAGS_FAILURE]: deviceTagsFailure,
  [TaggingActionTypes.TOGGLE_SELECTION]: toggleSelection,
  [TaggingActionTypes.DEVICES_CREATE_TAG_REQUEST]: devicesCreateTagRequest,
  [TaggingActionTypes.TAGS_ALL_KEYS_REQUEST]: tagsAllKeysRequest,
  [TaggingActionTypes.TAGS_ALL_KEYS_SUCCESS]: tagsAllKeysSuccess,
  [TaggingActionTypes.TAG_UPDATE_VALUE]: tagUpdateValue,
  [TaggingActionTypes.UPDATE_TAGS_REQUEST]: updateTagsRequest,
  [TaggingActionTypes.UPDATE_TAGS_SUCCESS]: updateTagsSuccess
})
