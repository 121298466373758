import { withFormik } from 'formik'
import { translate } from 'i18n/translate.helpers'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import BaseModal from '../modal-base'
import validationSchema from './create-access-points.validators'
import { AccessPointsActions } from '../../store'

export const mapStateToProps = state => {
  return {
    isOpen: state.accessPoints.getIn(['isShowingModal'], false)
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(AccessPointsActions.closeModal()),
    createAccessPointRequest: (siteId, accessPoint, callback) => dispatch(AccessPointsActions.createAccessPointRequest(siteId, accessPoint, callback))
  }
}

export default pipe(
  withFormik({
    validationSchema,
    handleSubmit: (payload, { props, resetForm }) => props.createAccessPointRequest(
      props.siteId,
      payload,
      () => {
        props.onSubmitSuccess()
        resetForm()
      }),
    displayName: 'AccessPoints'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CreateAccessPoints')
)(BaseModal)
