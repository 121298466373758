import { pipe } from 'ramda'
import PreferencesEmail from './preferences-email'
import { translate } from 'react-translate'
import PreferencesEmailActions from 'Redux/PreferencesRedux'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(PreferencesEmailActions.preferencesEmailNewReportOpen()),
  setSelectedReport: value => dispatch(PreferencesEmailActions.preferencesSetSelectedReport(value))
})

export default translate('PreferencesEmail')(
  connect(
    null,
    mapDispatchToProps
)(PreferencesEmail))
