export const GENERAL_ASSETS = 0
export const FONT_ASSETS = 1
export const IMAGES_ASSETS = 2

export const FILE_TYPES = {
  [FONT_ASSETS]: 'font',
  [IMAGES_ASSETS]: 'image'
}

export const DRIVER_TYPES = [
  { value: '', text: '' },
  { value: 'G', text: 'G - Default Drive' },
  { value: 'D', text: 'D - Ram Drive' },
  { value: 'X', text: 'X - Protected Drive' },
  { value: 'Y', text: 'Y - Protected Drive' }
]

export const LABEL_TYPES = [
  { value: 'dpl', text: 'DPL' },
  { value: 'ez-print', text: 'EZ-PRINT' }
]

const UPPER_A = 'A'.charCodeAt(0)
const LOWER_A = 'a'.charCodeAt(0)

export const FONT_ID = [...Array(901).keys()].map(t => {
  const value = t ? t + 99 : ''
  return { value, text: value }
})

export const FONT_ID_SHORT_RANGE = [...Array(51).keys()].map(t => {
  const value = t ? t + 49 : ''
  return { value, text: value }
})

export const FONT_ID_A_LOWERS_RANGE = [...Array(26).keys()].map(t => {
  const value = '9' + String.fromCharCode(t + LOWER_A)
  return {
    value,
    text: value
  }
})

export const FONT_ID_A_UPPERS_RANGE = [...Array(26).keys()].map(t => {
  const value = '9' + String.fromCharCode(t + UPPER_A)
  return {
    value,
    text: value
  }
})

export const UPDATE_PREFERENCES = [
  { key: 'WiFi', text: 'WiFi'},
  { key: 'WWAN', text: 'WWAN'},
  { key: 'Ethernet', text: 'Ethernet'}
]
