import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  fetchManufacturers,
  createManufacturer,
  selectAllManufacturers,
  selectTotalManufacturers
} from '../store'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { useTranslate } from 'react-translate'
import { useAppDispatch } from 'Store'
import { CreateManufacturer } from '../apis'

export const useFetchManufacturers = () => {
  const dispatch = useAppDispatch()
  const translate = useTranslate('Manufacturers')

  const [isLoading, setIsLoading] = useState(false)
  const manufacturers = useSelector(selectAllManufacturers)
  const total = useSelector(selectTotalManufacturers)

  const fetchData = useCallback(async (organizationId: string) => {
    setIsLoading(true)
    const resultAction = await dispatch(fetchManufacturers(organizationId))

    if (fetchManufacturers.fulfilled.match(resultAction)) {
      setIsLoading(false)
      return unwrapResult(resultAction)
    } else {
      setIsLoading(false)
      toast(<ErrorToast message={translate('GetError')} closeToast={() => {}} />)
      return null
    }
  }, [setIsLoading, dispatch, translate])

  return {
    isLoading,
    manufacturers,
    total,
    fetchManufacturers: fetchData
  }
}

export const useCreateManufacturer = () => {
  const dispatch = useAppDispatch()
  const [isPending, setIsPending] = useState(false)

  const submitData = useCallback(async (manufacturer: CreateManufacturer): Promise<null> => {
    setIsPending(true)
    const resultAction = await dispatch(createManufacturer(manufacturer))
    setIsPending(false)

    if (createManufacturer.fulfilled.match(resultAction)) {
      setIsPending(false)
      return unwrapResult(resultAction)
    } else if (resultAction.payload) {
      throw resultAction.payload.map(err => err.detail)
    } else {
      throw new Error(resultAction.error.message)
    }
  }, [setIsPending, dispatch])

  return {
    isPending,
    createManufacturer: submitData
  }
}
