import { createStructuredSelector, createSelector } from 'reselect'
import { getRootSiteIds, getSites } from '../../../../features/site-management/store'
import { reduce, assoc } from 'ramda'


export const isEditModalVisible = ({ networkRanges }) => networkRanges.getIn(['mode'], '') === 'edit'

export const sitesById = ({ sites }) => sites.sitesById
export const sitesByIdArray = createSelector(  sitesById,  sites => Object.values(sites))
export const userEmail = ({ login }) => login.userName   
export default createStructuredSelector({
  isEditModalVisible,
  rootSites: getRootSiteIds,
  sites: getSites,
  sitesById: sitesByIdArray,
  userEmail
})
