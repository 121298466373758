import styled from 'styled-components/macro'
import { Button } from '@scuf/common'

export const OnboardPromptWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: center;
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  align-self: center;
  justify-self: center;
`

export const OnboardTitle = styled.div`
  font-size: 2em;
  font-weight: 500;
  color: #303030;
  margin: 1rem;
`

export const OnboardSubtitle = styled.div`
  font-size: 1.25em;
  font-weight: 500;
  color: #606060;
  width: 65%;
  text-align: center;
`

export const OnboardButtonHolder = styled.div`
  margin: 1rem;
`

export const StyledButton = styled(Button)`
  margin-top: 1rem;
`
