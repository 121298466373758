import styled from 'styled-components'
import { Modal } from '@scuf/common'

const StyledModal = styled.div`
  &.ui.large.modal{
    width: 50% !important;
  }
`
const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`

const ModalBody = styled(Flex)`
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
`

const ScheduleForm = styled.div`
  padding: 1rem 1rem;
`

const Column = styled(Flex)`
  flex-direction: column;
  style: ${({ style }) => style || ''}
`

export {
  ModalBody,
  ScheduleForm,
  Column,
  StyledModal
}
