import { createStructuredSelector, createSelector } from 'reselect'
import { filteredDevices, groupByModelAndSumValue } from '../../offline-devices-by-model.selector'
import { calculateDistribution } from '../../../dashboard-cards-helpers'

export const groupedDevices = createSelector(
  [filteredDevices],
  devices => {
    const groupedModels = groupByModelAndSumValue(devices)
    return Object.keys(groupedModels).map(model => ({ key: model, value: groupedModels[model] }))
  }
)
export const total = createSelector(
  [groupedDevices],
  groupedDevices => groupedDevices.reduce((sum, modelData) => {
    return sum + modelData.value
  }, 0)
)

export const data = createSelector(
  groupedDevices,
  total,
  (values, total) => calculateDistribution(values, total)
)

export default createStructuredSelector({
  data
})
