import React, { useCallback, useState } from 'react'
import { Dictionary } from '@reduxjs/toolkit'
import Immutable from 'seamless-immutable'
import axios from 'axios'

import Excel, { Workbook } from 'exceljs'
import range from 'lodash/range'

import { toast } from 'react-toastify'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import {
  AssetModelWithReferenceNames,
  useFetchManufacturers,
  useFetchAssetTypes,
  useFetchAssetModels
} from '../../hooks'
import { AssetModel, AssetType, Manufacturer, importAssets } from '../../apis'
import { NonIotImportTableRow } from './non-iot-import-table'
import { ValidationStatus } from './validation-status'

export const nameSeparator = ' || '

export enum SpreadsheetHeaders {
  Model = 'Model',
  SerialNumber = 'Serial Number',
  Alias = 'Alias (optional)'
}

export const useDownloadImportTemplate = () => {
  const { fetchManufacturers } = useFetchManufacturers()
  const { fetchAssetTypes } = useFetchAssetTypes()
  const { fetchAssetModels } = useFetchAssetModels()
  const [isProcessing, setIsProcessing] = React.useState(false)

  const fetchDependencies = React.useCallback((organizationId: string) => {
    return Promise.all([
      fetchManufacturers(organizationId),
      fetchAssetTypes(organizationId),
      fetchAssetModels(organizationId)
    ] as const)
  }, [fetchManufacturers, fetchAssetTypes, fetchAssetModels])

  const downloadImportTemplate = React.useCallback(async (organizationId: string) => {
    try {
      setIsProcessing(true)
      const [manufacturers, assetTypes, assetModels] = await fetchDependencies(organizationId)

      if (manufacturers === null || assetTypes == null || assetModels == null) {
        throw new Error('Error fetching entities.')
      }

      const assetModelsWithReferences = getReferences(manufacturers, assetTypes, assetModels)
      const workbook = createExcelWorkbook(assetModelsWithReferences)
      await downloadExcelWorksheet(workbook)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message='There was an error getting the file.' />)
    } finally {
      setIsProcessing(false)
    }
  }, [fetchDependencies])

  return {
    isProcessing,
    downloadImportTemplate
  }
}

export const useImportForm = () => {
  const [validationStatus, setValidationStatus] = useState(ValidationStatus.Validated)
  const [isValid, setIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [siteId, setSiteId] = useState<string | null>(null)
  const [tableData, setTableData] = useState(Immutable([] as NonIotImportTableRow[]))
  const [cancelTokenSource, setCancelTokenSource] = useState(axios.CancelToken.source())

  const submitForm = useCallback(async () => {
    if (!isValid || siteId == null) {
      throw new Error('Form is not valid.')
    }

    setIsSubmitting(true)
    const response = await importAssets({
      siteId,
      assets: tableData.asMutable(),
      dryRun: false
    })
    setIsSubmitting(false)
    if (response.ok && response.data) {
      return response.data.data
    }

    throw new Error('An unexpected error occurred while creating employees.')
  }, [isValid, siteId, tableData])

  const validateAndUpdateForm = useCallback(async (siteId: string | null, data: Immutable.ImmutableArray<NonIotImportTableRow>) => {
    if (data.length === 0 || siteId == null) {
      setValidationStatus(ValidationStatus.Validated)
      setIsValid(false)
      return
    }

    setValidationStatus(ValidationStatus.Validating)
    cancelTokenSource.cancel()
    const newCancelTokenSource = axios.CancelToken.source()
    setCancelTokenSource(newCancelTokenSource)

    const response = await importAssets({
      siteId,
      assets: data.asMutable(),
      dryRun: true
    }, newCancelTokenSource.token)
    if (response.ok && response.data?.data) {
      setIsValid(true)
      setValidationStatus(ValidationStatus.Validated)
      const assetsWithoutErrors = data.map(asset => {
        return asset.set('errors', undefined) as NonIotImportTableRow
      })
      setTableData(assetsWithoutErrors)
    } else if (response.problem === 'CANCEL_ERROR') {
      setIsValid(false)
    } else if (response.data?.data) {
      const responseData = response.data.data
      const assetsWithErrors = data.map(
        (asset, idx) => asset.merge({
          errors: responseData[idx]?.errors
        }) as NonIotImportTableRow)
      setTableData(assetsWithErrors)
      setIsValid(false)
      setValidationStatus(ValidationStatus.Validated)
    } else {
      setIsValid(false)
      setValidationStatus(ValidationStatus.Failed)
      throw new Error('An error occurred while validating the employees.')
    }
  }, [cancelTokenSource])

  const setSiteIdAndValidate = useCallback(async (siteId: string | null) => {
    setSiteId(siteId)
    await validateAndUpdateForm(siteId, tableData)
  }, [tableData, validateAndUpdateForm])

  const setTableDataAndValidate = useCallback(async (data: Immutable.ImmutableArray<NonIotImportTableRow>) => {
    setTableData(data)
    await validateAndUpdateForm(siteId, data)
  }, [siteId, validateAndUpdateForm])

  return {
    validationStatus,
    isValid,
    isSubmitting,
    setSiteId: setSiteIdAndValidate,
    setTableData: setTableDataAndValidate,
    siteId,
    tableData,
    submitForm
  }
}

function getReferences (manufacturers: Manufacturer[], assetTypes: AssetType[], assetModels: AssetModel[]): AssetModelWithReferenceNames[] {
  const manufacturerNameById: Dictionary<string> = manufacturers.reduce((acc, m) => ({
    ...acc,
    [m.id]: m.manufacturerName
  }), {})

  const assetTypeNameById: Dictionary<string> = assetTypes.reduce((acc, t) => ({
    ...acc,
    [t.id]: t.name
  }), {})

  return assetModels.map(m => ({
    ...m,
    manufacturerName: manufacturerNameById[m.manufacturerId] ?? '',
    assetTypeName: assetTypeNameById[m.assetTypeId] ?? ''
  }))
}

async function downloadExcelWorksheet (workbook: Workbook) {
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }
  )
  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'Operational Intelligence Import Non-IoT Template.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}

function createExcelWorkbook (assetModels: AssetModelWithReferenceNames[]) {
  const workbook = new Excel.Workbook()
  workbook.creator = 'Operational Intelligence'

  const assetsSheet = workbook.addWorksheet('Assets')
  assetsSheet.columns = [
    { header: SpreadsheetHeaders.Model, key: 'Model', width: 80 },
    { header: SpreadsheetHeaders.SerialNumber, key: 'SerialNumber', width: 50 },
    { header: SpreadsheetHeaders.Alias, key: 'Alias', width: 255 }
  ]
  assetsSheet.state = 'visible'
  range(2, 100).forEach(i => {
    const row = assetsSheet.getRow(i)
    row.getCell('A').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['Models!$A:$A']
    }
    row.getCell('B').numFmt = '@'
  })

  const modelsSheet = workbook.addWorksheet('Models')
  modelsSheet.getColumn(1).values = assetModels
    .map(m => [m.manufacturerName, m.assetTypeName, m.name].join(nameSeparator))

  return workbook
}
