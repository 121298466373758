import CustomTimeDateModal from 'Components/CustomTimeDateModal'
import ScheduledDevicesModal from 'Components/ScheduledDevicesModal'
import DetailsModal from 'Components/UserActivityReport/components/DetailsModal'
import { AddAssetsModal } from 'features/assets-management/components/add-assets-modal'
import { AddUsersModal } from 'features/user-management/components/add-users-modal'
import ScheduledDevicesWrapper from 'features/v2/SoftwareUpdates/UpdateSummary/ScheduledDevicesWrapper'

import { DeviceForm, SiteForm, SiteFormEdit, UserFormEdit } from '../../Forms'
import AlertsManagementModal from '../AlertsManagementModal'
import ApplicationReportModal from '../ApplicationReports/ApplicationReportModal'
import DeviceUsageCpuModal from '../ApplicationReports/DeviceUsageCpu/Components/DeviceUsageCpuModal'
import DeviceUsageNetModal from '../ApplicationReports/DeviceUsageNetwork/Components/DeviceUsageNetworkModal'
import DeviceUsageModal from '../ApplicationReports/DeviceUsageReport/Components/DeviceUsageModal'
import RemoteAccessModal from '../AssetManagementBase/components/remote-access-modal'
import BatteryAgeList from '../DashboardCards/AvgBatteryAge/BatteryAgeModal'
import BatteryList from '../DashboardCards/AvgBatteryCycleCount/BatteryListModal'
import BatteryLowHealthList from '../DashboardCards/BatteriesLowHealth/BatteryLowHealthModal'
import BatteriesAbove from '../DashboardCards/BatteryCycleAbove300/BatteryListModal'
import BatteriesBelow from '../DashboardCards/BatteryHealthBelow50/BatteryLowHealthModal'
import BatteriesBetween from '../DashboardCards/BatteryHealthBetween/BatteryLowHealthModal'
import DevicesBySiteModal from '../DashboardCards/DevicesBySite/components/DevicesBySiteChartModal'
import DevicesStatus from '../DashboardCards/DevicesStatus/components/DevicesStatusModal'
import DroppedDevicesModal from '../DashboardCards/DroppedDevicesCard/components/DroppedDevicesModal'
import ReebotsModal from '../DashboardCards/HardReboots/components/HardRebootsModal'
import MobileOsModal from '../DashboardCards/MobileOsCard/components/MobileOsModal'
import NewBatteriesListModal from '../DashboardCards/NewBatteries/NewBatteriesListModal'
import OfflineDevicesByModelModal from '../DashboardCards/OfflineDevicesByModel/components/OfflineDevicesByModelModal'
import OfflineDevicesBySiteModal from '../DashboardCards/OfflineDevicesBySite/components/OfflineDevicesBySiteModal'
import PrintersFaultyDots from '../DashboardCards/PrinterFaultyDots/components/PrinterFaultyDotsModal'
import PrintersFirmwareModal from '../DashboardCards/PrintersFirmwareCard/components/PrintersFirmwareModal'
import PrintersOutOfPaper from '../DashboardCards/PrintersOutOfPaper/components/PrintersOutOfPaperModal'
import PrintersOutOfRibbon from '../DashboardCards/PrintersOutOfRibbon/components/PrintersOutOfRibbonModal'
import ScannerFirmwareModal from '../DashboardCards/ScannerFirmwareCard/components/ScannerFirmwareModal'
import SecurityPatchModal from '../DashboardCards/SecurityPatch/components/SecurityPatchChartModal'
import TypesOfModelsModal from '../DashboardCards/TypesOfModels/components/TypesOfModelsModal'
import DashboardPreferences from '../DashboardPreferences'
import DashboardSitesFilter from '../DashboardSections/DashboardTitle/components/DashboardSiteFilterModal'
import DeleteDevices from '../DeleteDevices'
import DeleteLocation from '../DeleteLocation'
import DeletePackageModal from '../DeletePackage'
import DeleteSite from '../DeleteSite'
import DeleteUser from '../DeleteUser'
import OutOfRangeModal from '../DevicesOutOfRangeModal'
import EditLocation from '../EditLocation'
import ExpirationModal from '../ExpirationModal'
import LocationForm from '../Location'
import ManageTagsModal from '../ManageTagsModal'
import OSSAgreement from '../OSSAgreement'
import { QrModalWrapper } from '../QrSection'
import RemoteAccessInProgressModal from '../RemoteAccessInProgressModal'
import ScheduleModal from '../ScheduleModal'
import ScheduleUpdateModal from '../ScheduleUpdateModal'
import SettingsModal from '../SettingsModal'
import UpdatesModal from '../UpdatesModal'
import BulkEditModal from '../Upload/components/BulkEditModal'
import BulkCloseModal from '../Upload/components/BulkEditModal/BulkCloseModal'
import UploadAssetModal from '../UploadAssetModal'
import UploadCsvSection from '../UploadCsvSection'
import VersionSupportModal from '../VersionSupportModal'
import DeviceActionsModal from '../DeviceActions/components'
import SignalStrengthModal from '../SignalStrengthModal'
import DeepSleepModel from '../DashboardCards/DeepSleepModel'

export const MODALS = {
  siteForm: SiteForm,
  siteFormEdit: SiteFormEdit,
  userForm: AddUsersModal,
  userFormEdit: UserFormEdit,
  deviceForm: DeviceForm,
  locationForm: LocationForm,
  editLocation: EditLocation,
  deleteSite: DeleteSite,
  deleteUser: DeleteUser,
  deleteLocation: DeleteLocation,
  deviceOnBoard: QrModalWrapper,
  deviceOnBoardSimple: AddAssetsModal,
  uploadCsv: UploadCsvSection,
  ossTerms: OSSAgreement,
  deleteDevices: DeleteDevices,
  batteryList: BatteryList,
  batteryAgeList: BatteryAgeList,
  batteryListLowHealth: BatteryLowHealthList,
  batteriesBelow: BatteriesBelow,
  batteriesBetween: BatteriesBetween,
  batteriesAbove: BatteriesAbove,
  typesOfModelsModal: TypesOfModelsModal,
  mobileOsModal: MobileOsModal,
  printersFirmwareModal: PrintersFirmwareModal,
  updatesModalList: UpdatesModal,
  scheduleModal: ScheduleModal,
  scheduleUpdateModal: ScheduleUpdateModal,
  uploadAssetModal: UploadAssetModal,
  printersFaultyDots: PrintersFaultyDots,
  scannerFirmwareModal: ScannerFirmwareModal,
  remoteAccessModal: RemoteAccessModal,
  offlineDevicesByModelModal: OfflineDevicesByModelModal,
  offlineDevicesBySiteModal: OfflineDevicesBySiteModal,
  devicesBySiteModal: DevicesBySiteModal,
  securityPatchModal: SecurityPatchModal,
  printersOutOfPaper: PrintersOutOfPaper,
  printersOutOfRibbon: PrintersOutOfRibbon,
  sessionExpiration: ExpirationModal,
  applicationReportModal: ApplicationReportModal,
  deviceApplicationUsage: DeviceUsageModal,
  deviceUsageCpuModal: DeviceUsageCpuModal,
  deviceNetworkIO: DeviceUsageNetModal,
  deepSleepModel: DeepSleepModel,
  dashboardSitesFilter: DashboardSitesFilter,
  droppedDevicesModal: DroppedDevicesModal,
  newBatteriesList: NewBatteriesListModal,
  settingsModal: SettingsModal,
  devicesStatusModal: DevicesStatus,
  dashboardPreferences: DashboardPreferences,
  outOfRangeModal: OutOfRangeModal,
  manageTagsModal: ManageTagsModal,
  versionSupportModal: VersionSupportModal,
  remoteAccessInProgressModal: RemoteAccessInProgressModal,
  customTimeDateModal: CustomTimeDateModal,
  alertsManagement: AlertsManagementModal,
  detailsModal: DetailsModal,
  bulkEditModal: BulkEditModal,
  bulkCloseModal: BulkCloseModal,
  rebootsModal: ReebotsModal,
  deletePackageModal: DeletePackageModal,
  scheduledDevicesModal: ScheduledDevicesModal,
  editPackageModal: UploadAssetModal,
  deviceActionsModal: DeviceActionsModal,
  signal_strength: SignalStrengthModal,
  newScheduledDevicesModal: ScheduledDevicesWrapper
}
