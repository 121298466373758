import React from 'react'
import { Modal } from '@scuf/common'
import moment from 'moment'
import AssetManagementTable from '../../../../AssetManagementTable'
import { ModalTitle, ModalWrap } from './printers-out-of-paper-modal.styles'
import { Link } from 'react-router-dom'

export const columns = [
  { field: 'value', translation: 'AssetManagementTable_faultyDotsSerialNumber', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'aliasName', translation: 'AssetManagementTable_alias', render: null},
  { field: 'timestamp', translation: 'AssetManagementTable_hour', render: null  },
  { field: 'deviceSite', translation: 'AssetManagementTable_deviceSite', render: null }
]

const MobileOsModal = ({ data, t }) => {
  return (
    <ModalWrap>
      <ModalTitle>{t('PrinterOutMedia_title')}</ModalTitle>
      <Modal.Content>
        <AssetManagementTable columns={columns} data={data} fileName={t('PrinterOutMedia_title')} />
      </Modal.Content>
    </ModalWrap>
  )
}

export default MobileOsModal
