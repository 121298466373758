import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-translate'
import { toast } from 'react-toastify'
import Immutable from 'seamless-immutable'

import SiteManagerActions from 'Redux/SiteManagerRedux'
import { selectedOrgId } from 'Selectors/LoaderSelector'

import { Button } from '@scuf/common'
import { Form, Row, StyledSelect, FormButtons, FormContent, TableContainer } from './add-other-equipment-form.styles'
import { NonIotImportTable } from './non-iot-import-table'
import {
  useDownloadImportTemplate,
  useImportForm
} from './import-assets-hooks'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'
import { AssetImport } from 'features/assets-management/apis'
import { FileUploader } from './file-uploader'
import { ValidationStatus } from './validation-status'

export interface AddOtherEquipmentFormProps {
  onCancel: () => any
  onSuccess: () => any
}

export const AddOtherEquipmentForm: React.FC<AddOtherEquipmentFormProps> = ({
  onCancel,
  onSuccess
}) => {
  const t = useTranslate('OtherEquipmentForm')
  const dispatch = useDispatch()

  const orgId = useSelector(selectedOrgId)
  const { downloadImportTemplate, isProcessing } = useDownloadImportTemplate()

  const {
    validationStatus,
    isValid,
    isSubmitting,
    setSiteId,
    siteId,
    setTableData,
    tableData,
    submitForm
  } = useImportForm()

  useEffect(() => {
    dispatch(SiteManagerActions.siteManagerRootSitesRequest())
  }, [dispatch])

  const sites = useSelector(state => state.siteManager.rootSitesById)
  useEffect(() => {
    if (sites.length > 0 && siteId == null) {
      setSiteId(sites[0].value).catch(() => {})
    }
  }, [setSiteId, siteId, sites])

  const onSubmit = useCallback(async () => {
    try {
      await submitForm()
      toast(<SuccessToast message={t('Success')} />)
      onSuccess()
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={t('Error')} />)
    }
  }, [t, onSuccess, submitForm])

  const setSiteIdWithToasts = useCallback(async (siteId: string) => {
    try {
      await setSiteId(siteId)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={t('ErrorOccurredValidating')} />)
    }
  }, [t, setSiteId])

  const setTableDataWithToasts = useCallback(async (data: Immutable.ImmutableArray<AssetImport>) => {
    try {
      await setTableData(data)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={t('ErrorOccurredValidating')} />)
    }
  }, [t, setTableData])

  const revalidateForm = useCallback(async () => {
    await setTableDataWithToasts(tableData)
  }, [setTableDataWithToasts, tableData])

  const onFileLoaded = useCallback(async (data: AssetImport[]) => {
    await setTableDataWithToasts(Immutable(data))
  }, [setTableDataWithToasts])

  const onResetClick = useCallback(async () => {
    await setTableDataWithToasts(Immutable([] as AssetImport[]))
  }, [setTableDataWithToasts])

  return (
    <Form>
      <FormContent>
        <Row>
          <StyledSelect
            indicator='required'
            label={t('Site')}
            onChange={setSiteIdWithToasts}
            options={sites}
            reserveSpace={true}
            search={true}
            helperText={t('SiteHelp')}
            value={siteId}
          />
        </Row>
        {/* <Row> */}
        {/*  <SingleAssetForm /> */}
        {/* </Row> */}
        <Row>
          <b>{`${t('TableLabel')} - `}</b>
          <Button
            type='link'
            content={t('DownloadTemplate')} onClick={() => downloadImportTemplate(orgId)}
            disabled={isProcessing}
          />
        </Row>
        <TableContainer>
          {
            tableData.length > 0
              ? (
                <NonIotImportTable
                  data={tableData}
                  onChange={data => setTableDataWithToasts(data)}
                  validationStatus={validationStatus}
                  submitting={isSubmitting}
                />
              ) : <FileUploader onFileLoaded={onFileLoaded} />
          }
        </TableContainer>
      </FormContent>
      <FormButtons>
        <Button
          onClick={onResetClick}
          content={t('Reset')}
          disabled={tableData.length === 0}
          type='secondary'
        />
        <Button
          content={t('Cancel')}
          onClick={onCancel}
          disabled={isSubmitting}
          type='secondary'
        />
        {validationStatus === ValidationStatus.Failed && (
          <Button
            id='submit-button'
            content={t('Validate')}
            onClick={() => revalidateForm()}
            type='primary'
          />
        )}
        <Button
          content={t('Submit')}
          onClick={onSubmit}
          disabled={!(isValid && validationStatus === ValidationStatus.Validated && tableData.length !== 0)}
          type='primary'
        />
      </FormButtons>
    </Form>
  )
}
