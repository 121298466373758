import { put, call, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import React from 'react'
import { SuccessToast, ErrorToast } from '../../../Themes/ScufStyledComponents'

import {
  NetworkRangesTypes,
  NetworkRangesActions
} from './actions'
import { NetworkRangeApi } from '../../../Services'
import { get } from 'lodash'

export function * createNetworkRangeRequest ({ siteId, networkRange, callback }) {
  const response = yield call(NetworkRangeApi.createSingleNetworkRange, {
      ...networkRange,
      siteId
    })
  if (response.ok) {
    yield all([
      put(NetworkRangesActions.createNetworkRangeSuccess()),
      call(toast, <SuccessToast message='Successfully created a Network Range!' />)])
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } else {
    if (Array.isArray(response.data.errors)){
      const error = get(response, 'data.errors[1]', 'Start IP should be less than End IP') 
      yield all([
        put(NetworkRangesActions.createNetworkRangeFailure()),
        call(toast, <ErrorToast message={error} />)
      ])
    }else {
      if (response.data.includes('NetworkError01') ){
      const error = get(response, 'data.errors[0].message', 'Duplicate network range found.')
      yield all([
        put(NetworkRangesActions.createNetworkRangeFailure()),
        call(toast, <ErrorToast message={error} />)
      ])
      } else if (response.data.includes('NetworkError02')){
        const error = get(response, 'data.errors[0].message', 'Overlapping network range found.')
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      } else if (response.data.includes('NetworkError04')){
        const siteName = response.data.substr(15)
        const error = get(response, 'data.errors[0].message', 'Duplicate or Overalapping network range found in site '+ siteName)
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      } else {
        const error = get(response, 'data.errors[0]', 'Invalid Ip Address')
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      }
    }
  }
}

export function * editNetworkRangeRequest ({ networkRange, callback }) {
  const response = yield call(NetworkRangeApi.updateNetworkRange, networkRange.networkdId, networkRange)
  if (response.ok) {
    yield all([
      yield put(NetworkRangesActions.editNetworkRangeSuccess()),
      call(toast, <SuccessToast message='Successfully edited a Network Range!' />)
    ])
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } else if (!response.ok && response.status === 401) {
    yield all([
      NetworkRangesActions.editNetworkRangeFailure(),
      call(toast, <ErrorToast message='User does not have sufficient permissions for this action' />)
    ])
  } else {
    if (Array.isArray(response.data.errors)){
      const error = get(response, 'data.errors[0]', 'Start IP should be less than End IP')
      yield all([
        put(NetworkRangesActions.createNetworkRangeFailure()),
        call(toast, <ErrorToast message={error} />)
      ])
    }else {
      if (response.data.includes('NetworkError01') ){
      const error = get(response, 'data.errors[0].message', 'Duplicate network range found.')
      yield all([
        put(NetworkRangesActions.createNetworkRangeFailure()),
        call(toast, <ErrorToast message={error} />)
      ])
      } else if (response.data.includes('NetworkError02')){
        const error = get(response, 'data.errors[0].message', 'Overlapping network range found.')
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      } else if (response.data.includes('NetworkError04')){
        const siteName = response.data.substr(15)
        const error = get(response, 'data.errors[0].message', 'Duplicate or Overalapping network range found in site '+ siteName)
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      } else {
        const error = get(response, 'data.errors[0]', 'Invalid Ip Address')
        yield all([
          put(NetworkRangesActions.createNetworkRangeFailure()),
          call(toast, <ErrorToast message={error} />)
        ])
      }
    }
  }
}

export function * networkRangesSagas () {
  yield all([
    takeLatest(NetworkRangesTypes.CREATE_NETWORK_RANGE_REQUEST, createNetworkRangeRequest),
    takeLatest(NetworkRangesTypes.EDIT_NETWORK_RANGE_REQUEST, editNetworkRangeRequest)
  ])
}
