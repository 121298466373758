import moment from 'moment'

export const today = (format) => moment().format('YYYY-MM-DD').toString() + ' 23:59:59'

export const getStartDate = (startDate) => moment(startDate).format('YYYY-MM-DD').toString() + 'T00:00:00.000Z'

export const getEndDate = (endDate) => moment(endDate).format('YYYY-MM-DD').toString() + 'T23:59:59.999Z'

export const todayDate = () => moment().format('YYYY-MM-DD').toString()

export const getStartDateByUnitTime = (unitOfTime, format) => moment().startOf(unitOfTime).format('YYYY-MM-DD').toString() + ' 00:00:00'

export const substract = (startDate, amount, unitOfTime, format) => moment(startDate).subtract(amount, unitOfTime).format('YYYY-MM-DD').toString() + ' 00:00:00'

export const substractStartDay = (startDate, amount, unitOfTime, format) => moment(startDate).subtract(amount, unitOfTime).format('YYYY-MM-DD+00:00:00').toString()

export const substractStartDayCurrently = (startDate, amount, unitOfTime, format) => moment(startDate).subtract(amount, unitOfTime).format().toString()
/* utcIso date to milli second format */
export const isoDateToms = date => moment(date).valueOf()

export const dateWithAmPm = (time) => time.format('MM/DD/YYYY - hh:mm A').toString()

export  const getStartDateUTCFormat = (option) => {
  const today = new Date();
  const initDate = new Date();
  switch (option) {
    case 0 :
      return new Date(new Date(today.getTime() - today.getTimezoneOffset() * 60000).setUTCHours(0,0,0,0)).toISOString();
    case 1:
      initDate.setDate(initDate.getDate() - option)
      return new Date(new Date(initDate.getTime() - today.getTimezoneOffset() * 60000).setUTCHours(0,0,0,0)).toISOString();
    default:
      initDate.setDate(initDate.getDate() - (option - 1))
      return new Date(new Date(initDate.getTime() - today.getTimezoneOffset() * 60000).setUTCHours(0,0,0,0)).toISOString();
  }
}

export const getEndDateUTCFormat = (option) => {
  const today = new Date();
  const initDate = new Date();
  switch (option) {
    case 1:
      initDate.setDate(initDate.getDate() - option)
      return new Date(new Date(initDate.getTime() - today.getTimezoneOffset() * 60000).setUTCHours(23,59,59,999)).toISOString();
    default:
      return new Date(new Date(today.getTime() - today.getTimezoneOffset() * 60000).setUTCHours(23,59,59,999)).toISOString();
  }
}

export const addDays = (date, days) => {
  const finalDate = new Date(date);
  finalDate.setDate(finalDate.getDate() + days);
  return finalDate.toDateString();
}

export  const getDateFormat = (value, option) => {
  switch (option) {
    case 0 :
      return moment(value).format('ddd')
    case 1:
      return moment(value).format('DD')
    case 2:
      return moment(value).format('MMM')
    default:
      return moment(value).format('ddd')
  }
}
