import { useState, useEffect } from 'react'
import get from 'lodash/get'

import { TagsApi } from 'Services'

export const useKeyValue = () => {
  const [keys, setKeys] = useState([])
  const [values, setValues] = useState([])
  const [key, setKey] = useState('')

  const getKeys = async () => {
    const response = await TagsApi.searchKeys('', 'device')
    if (response.ok) {
      const key = get(response, 'data.results', [])
        .map(k => ({
          value: k,
          text: k
        }))
      setKeys(key)
    }
  }

  const getValues = async (key) => {
    const response = await TagsApi.getValues(key, 'device')
    if (response.ok) {
      const result = get(response, 'data.results', [])
        .map(k => ({
          value: k,
          text: k
        }))
      setValues(result)
    }
  }

  useEffect(() => {
    if (key) {
      getValues(key)
    }
  }, [key])

  useEffect(() => {
    getKeys()
  }, [])

  return {
    keys,
    values,
    setKey,
    key
  }
}
