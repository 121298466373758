import { createSelector } from 'reselect'
import { RootState } from 'Store'
import { NormalizedSite, SiteType } from '../service'
import * as React from 'react'
import { useSelector } from 'react-redux'

export type SitesStateSlice = Pick<RootState, 'sites'>
export type UserSlice = Pick<RootState, 'user'>
export const getIsLoading = ({ sites }: SitesStateSlice) => sites.isLoading ?? true
export const getError = ({ sites }: SitesStateSlice) => sites.error
export const getSites = ({ sites }: SitesStateSlice) => sites.sitesById ?? {}
export const getRootSiteIds = ({ sites }: SitesStateSlice) => sites.rootSiteIds ?? []
export const hasPackageCarSubscribedFeature = ({ user }:UserSlice) => { 
   return user?.subscription?.features?.includes('packagecar')
 }

export function getChildLevelType (locationType: SiteType) {
  switch (locationType) {
    case SiteType.ORGANIZATION:
      return SiteType.SITE
    case SiteType.SITE:
      return SiteType.BUILDING + "," + SiteType.PACKAGE_CAR
    case SiteType.BUILDING:
      return SiteType.FLOOR
    case SiteType.FLOOR:
      return SiteType.ZONE
    default:
      return null
  }
}
export const useGetPackageCarSelector =() => {
  return useSelector(React.useMemo(() => hasPackageCarSubscribedFeature))
}

export const makeGetSiteByIdSelector = () => {
  return createSelector(
    [getSites, (_: any, siteId: string) => siteId],
    (sites, siteId) => {
      return (sites[siteId] || null) as NormalizedSite | null
    }
  )
}

export const useSiteByIdSelector = (siteId?: string | null | undefined) => {
  const siteSelector = React.useMemo(makeGetSiteByIdSelector, [])
  return useSelector<SitesStateSlice, NormalizedSite | null>(
    state => siteId == null ? null : siteSelector(state, siteId)
  )
}

export const makeGetSitePathSelector = () => {
  return createSelector(
    [getSites, (_: any, siteId: string) => siteId],
    (sites, siteId) => {
      let currentSite: undefined | NormalizedSite = sites[siteId]
      const result: NormalizedSite[] = []

      while (currentSite !== undefined) {
        result.unshift(currentSite)
        currentSite = currentSite.parentId == null
          ? undefined
          : sites[currentSite.parentId]
      }

      return result
    }
  )
}

export const makeGetParentByTypeSelector = () => {
  return createSelector(
    getSites,
    (_: any, { id, type }: { id: string, type: SiteType }) => ({ id, type }),
    (sites, { id, type }) => {
      let currentSite: undefined | NormalizedSite = sites[id]

      while (currentSite !== undefined && currentSite.type !== type) {
        currentSite = currentSite.parentId == null
          ? undefined
          : sites[currentSite.parentId]
      }

      return currentSite ?? null
    }
  )
}

export const useParentByTypeSelector = (id: string | null, type: SiteType) => {
  const siteSelector = React.useMemo(makeGetParentByTypeSelector, [id, type])
  return useSelector<SitesStateSlice, NormalizedSite | null>(
    state => id == null ? null : siteSelector(state, { id, type })
  )
}

export const useSitePathSelector = (siteId: string | null) => {
  type Result = NormalizedSite[]

  const sitePathSelector = React.useMemo(makeGetSitePathSelector, [])
  return useSelector<SitesStateSlice, Result>(
    state => siteId == null ? [] : sitePathSelector(state, siteId)
  )
}
