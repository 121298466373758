import React, { PureComponent } from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../../AssetManagementTable'
import { ModalWrap, ModalTitle } from './printers-firmware-modal.styles'

export const columns = [
  { field: 'key', translation: 'AssetManagementTable_firmwareVersion', render: ({ value }) => <p>{value.toUpperCase()} </p> },
  { field: 'value', translation: 'AssetManagementTable_firmwareValue', render: null },
  { field: 'percentage', translation: 'AssetManagementTable_percentage', render: ({ value }) => <p>{value} %</p> }
]

const PrintersFirmwareModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('PrinterFirmware_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        columns={columns}
        data={data}
        disableRowClick
        fileName={t('PrinterFirmware_title')}
      />
    </Modal.Content>

  </ModalWrap>
)

export default PrintersFirmwareModal
