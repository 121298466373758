import { createSelector, createStructuredSelector } from 'reselect'

const isOpen = ({ releaseNotes }) => releaseNotes.isModalOpen || false
const userId = ({ login }) => login.userName || ''
const pastReleases = ({ releaseNotes }) => releaseNotes.releases?.filter(
  r => r.type === 'History'
) || []
const upcomingReleases = ({ releaseNotes }) => releaseNotes.releases?.filter(
    r => r.type === 'Upcoming'
  )
  .sort(
    (a, b) => (new Date(b.releasedate)) - (new Date(a.releasedate))
  ) || []

export const currentUserReleases = createSelector(
  userId,
  ({ user }) => user.releaseNotes || {},
  (id, releases) => releases[id] || {}
)

export const hasUnreadeReleaseNotesSelector = createSelector(
  currentUserReleases,
  ({ releaseNotes }) => releaseNotes.releases || [],
  (userReadedRelease, releases) => {
    const pendingToReadReleases = releases.filter(
      ({ id }) => !userReadedRelease[id]
    )
    return Object.keys(pendingToReadReleases).length > 0
  }
)

export const helpMenuSelector = createStructuredSelector({
  hasUnreadeReleaseNotes: hasUnreadeReleaseNotesSelector,
  accessToken: ({ login }) => login.accessToken
})

export default function (state) {
  const [latestReleases, ...rest] = pastReleases(state)
  return {
    isOpen: isOpen(state),
    upcomingReleases: upcomingReleases(state),
    latestReleases: latestReleases ? [latestReleases] : [],
    pastReleases: rest,
    userId: userId(state)
  }
}
