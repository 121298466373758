import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { FormRow, InfoText } from '../../Themes/ScufStyledComponents'

const StyledTab = styled.div`
  color: #A0A0A0;
  border-bottom: ${props => props.active ? '0.25rem solid #1274B7' : 'none'};
  padding: 0.75rem 0 0.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  &:hover {
    cursor: pointer;
  }
  &:first-child {
    margin-left: 0;
  }
`

const TabText = styled(InfoText)`
  color: #a0a0a0;
  font-weight: 400;
  &:hover {
    color: #303030;
  }
  &:active {
    color: #303030;
  }
`

const TabsBase = ({ tabs, activeIndex, onTabChange }) => (
  <FormRow>
    {
      tabs.map((t, i) => t ? (
        <StyledTab
          key={i}
          active={i === activeIndex}
          onClick={() => {
            onTabChange && onTabChange({ title: t.title, index: i })
            t.onClick && t.onClick({ title: t.title, index: i })
          }}>
          <TabText>
            {t.title}
          </TabText>
        </StyledTab>
      ): null)
    }
  </FormRow>
)

TabsBase.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  onTabChange: PropTypes.func
}

TabsBase.defaultProps = {
  tabs: [],
  activeIndex: 0,
  onTabChange: () => null
}

const Tabs = memo(props => <TabsBase {...props} />)

export default Tabs
