const create = (api) => {
    const getLTLTotalTripsCard = (siteId, params) => api.get(`/tripmanagement/tripcount/${siteId}`, params)
    const getLTLMissedStopsCard = (siteId, params) => api.get(`/tripmanagement/missedstopcount/${siteId}`, params)
    const getLTLDwellTimeCard = (siteId, params) => api.get(`/tripmanagement/dwelltimeaverage/${siteId}`, params)
  
    return {
      getLTLTotalTripsCard,
      getLTLMissedStopsCard,
      getLTLDwellTimeCard
    }
  }
  
  export default {
    create
  }
  