import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import FormSelect from 'Components/FormComponents/FormSelect'
import { makeGetRolesAsOptions } from './selectors'

const mapStateToProps = (state, props) => {
  const getRolesAsOptions = makeGetRolesAsOptions()
  return {
    options: getRolesAsOptions(state, props)
  }
}

export default connect(mapStateToProps)(FormSelect)
