import apisauce from 'apisauce'
import config from '../Config/AppConfig'

const create = (baseURL = '') => {
  const api = apisauce.create({
    baseURL,
    timeout: 10000
  })

  const logout = (logoutUrl, params, headers) => (
    api.get(logoutUrl, 
      params, 
      headers)
  )

  return {
    logout
  }
}

export default {
  create
}
