import { create } from 'apisauce'

export class FilesService {
  constructor (api) {
    this.api = api
    this.uploadApi = create({
      timeout: undefined
    })
  }

  pingMinio = url => {
    const site = new URL(url)
    return this.uploadApi.get(site.origin)
  }

  getUploadUrl = body => {
    try {
      return this.api.post('/v2/files/uploadposturl', body)
    } catch (error) {
      return { error }
    }
  }

  uploadFileToUrl = (url, file, params, config = {}) => {
    try {
      const formData = new FormData()
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      formData.append('file', file)
      Object.keys(params).forEach(key => {
        formData.append(key, params[key])
      })
      return this.uploadApi.post(url, formData, {
        ...config,
        headers
      })
    } catch (error) {
      return {
        ok: false,
        error
      }
    }
  }

  linkFileToPackage = payload => {
    return this.api.post('/v2/packages', payload)
  }

  uploadPackageFile = ({ file, ...payload }, config = {}, token, honeywellUpdatesAPI) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('info', JSON.stringify(payload))
      if (honeywellUpdatesAPI) {
        formData.append('vendorPackage', true)
      }

      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      if (token) {
        headers['Authorization'] = `Bearer ${token}`
      }

      return this.api.post('/v2/packages', formData, {
        ...config,
        timeout: undefined,
        headers
      })
    } catch (error) {
      return {
        ok: false,
        error
      }
    }
  }
}
