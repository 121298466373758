import { withFormik } from 'formik'
import { translate } from 'i18n/translate.helpers'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import BaseModal from '../modal-base'
import validationSchema from './edit-access-points.validators'
import { AccessPointsActions } from '../../store'

export const mapStateToProps = state => {
  return {
    isOpen: state.accessPoints.getIn(['isShowingModal'], false),
    form: state.accessPoints.getIn(['form'], {}),
    isEdit: state.accessPoints.getIn(['isEdit'], true),
    permissions: state.accessPoints.getIn(['permissions'], [])
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(AccessPointsActions.closeModal()),
    editAccessPointRequest: (id, accessPoint, callback) => dispatch(AccessPointsActions.editAccessPointRequest(id, accessPoint, callback))
  }
}

export default pipe(
  withFormik({
    mapPropsToValues: ({ form }) => form,
    validationSchema,
    handleSubmit: (payload, { props, resetForm }) => props.editAccessPointRequest(
      props.id,
      payload,
      () => {
        props.onSubmitSuccess()
        resetForm()
      }),
    displayName: 'AccessPoints'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('EditAccessPoints')
)(BaseModal)
