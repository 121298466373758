import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import GeneralTable from './general-table'
import { updatesGeneralList, onSchedule, isValidTime } from '../../schedule-updates.selector'
import UpdatesActions from '../../../../Redux/UpdatesRedux'

const validate = ({ deviceList }) => {
  const list = Object.keys(deviceList || {}).reduce((errors, asset) => {
    if (!deviceList[asset].selected) {
      return errors
    }
    return {
      ...errors,
      [asset]: {
        driver: deviceList[asset].driver ? '' : 'error',
        fontId: deviceList[asset].fontId ? '' : 'error'
      }
    }
  }, {})
  return { deviceList: list }
}

const mapStateToProps = state => {
  return {
    data: updatesGeneralList(state),
    onSchedule: onSchedule(state),
    IsValid: isValidTime(state),
    initialValues: {
      requestPriority: 'Optional',
      deviceList: {}
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatesSelectAssets: updates => {
      const updatesId = updates.map(u => u.id)
      dispatch(UpdatesActions.updatesSelectAssets(updatesId))
    }
  }
}

export default pipe(
  reduxForm({
    form: 'generalProvisionForm',
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GeneralTable)
