import React from 'react'
import { DomainApi } from 'Services'
import PropTypes from 'prop-types'
import config from 'Config/AppConfig'
import honeywellLogo from 'Assets/honeywelllogo.png'
import {
  MainWrapper, AuthWrapper, Title, FlexCenter, FlexLeft, DescriptionDiv, DescriptionText, UserText, AppPermisionLabelTitle, AppPermisionLabel, ContentWrapperButton, FooterWrapper, FooterWrapperTermsAndConditions, CopyrightsText, StyledButtonLink, SeparatorText
} from './login-auth-HonSSO.styles'
import { Button } from '@scuf/common'

const ConditionsLink = 'https://www.honeywell.com/en-us/terms-and-conditions'

function openWindow(url, target) {
  const _component = document.createElement('a')
  _component.href = url
  _component.target = target
  _component.click()
}

const LoginAuthHonSSO = ({
  currentLogin,
  idToken,
  headerToken,
  requireConsent,
  consentUserName,
  consentOpenToken,
  t,
  logOut,
  setConsent
}) => {

  const ApproveProcess = () => {
    var authSSOType = ''
    try {
      switch (currentLogin)
      {
        case 'saml':
          authSSOType = 'HONSSOOpenID'
          break
        case 'google':
          authSSOType = 'GOOGLE_CLIENT'
          break
        case 'macys':
          authSSOType = 'MacySSOOpenID'
          break        
        default:
          authSSOType = 'HONSSOOpenID'
          break
      }

      setConsent(false, '', '')
      SaveConsent(true, authSSOType)
      
    } catch {
      setConsent(false, '', '')
    }
  }

  const SaveConsent = (consent, authSSOType) => {
    try {
      DomainApi.setConsentSSOAuth(consent, authSSOType, consentOpenToken)
    }
    catch {
    }
  }

  return (
    requireConsent === false ?
    null
    :
    <MainWrapper>
      <AuthWrapper>
        <FlexCenter marginTop='17px'>
          <img
            width='227px'
            src={honeywellLogo}
          />
        </FlexCenter>
        <FlexCenter>
          <Title>{t('LoginAuthHonSSO_Title')}</Title>
        </FlexCenter>
        <DescriptionDiv>
          <DescriptionText>
            {t('LoginAuthHonSSO_Description')}
          </DescriptionText>
        </DescriptionDiv>
        <UserText useBold paddingLeft='32px'>
          {t('LoginAuthHonSSO_LoginUser')}
        </UserText>
        <UserText >
          {consentUserName}
        </UserText>
        <FlexLeft marginTop='21px'>
          <AppPermisionLabelTitle>
            {t('LoginAuthHonSSO_PermissionTitle')}
          </AppPermisionLabelTitle>
        </FlexLeft>
        <FlexLeft marginTop='12px'>
          <AppPermisionLabel>{t('LoginAuthHonSSO_PermissionIdentity')}</AppPermisionLabel>
          <AppPermisionLabel>{t('LoginAuthHonSSO_PermissionUserName')}</AppPermisionLabel>
          <AppPermisionLabel>{t('LoginAuthHonSSO_PermissionEmail')}</AppPermisionLabel>
          <AppPermisionLabel>{t('LoginAuthHonSSO_PermissionName')}</AppPermisionLabel>
        </FlexLeft>
        <ContentWrapperButton>
          <Button
            content={t('LoginAuthHonSSO_ButtonDeny')}
            type='inline-secondary'
            minWidth='182px'
            onClick={logOut}
          />
          <Button
            content={t('LoginAuthHonSSO_ButtonApprove')}
            type='primary'
            minWidth='182px'
            onClick={() => ApproveProcess()}
          />
        </ContentWrapperButton>
        <FooterWrapper>
          <FlexLeft>
            <CopyrightsText>
              {t('LoginAuthHonSSO_Copyright')}
            </CopyrightsText>
          </FlexLeft>
          <FooterWrapperTermsAndConditions>
            <StyledButtonLink
              content={t('LoginAuthHonSSO_TermsConditions')}
              type='link'
              onClick={() => openWindow(ConditionsLink, '_blank')}
            />
            <SeparatorText>
              |
            </SeparatorText>
            <StyledButtonLink
              content={t('LoginAuthHonSSO_PrivacyStatement')}
              type='link'
              onClick={() => openWindow(config.api.privacyUrl, '_blank')}
            />
          </FooterWrapperTermsAndConditions>
        </FooterWrapper>
      </AuthWrapper>
    </MainWrapper>
  )
}

LoginAuthHonSSO.propTypes = {
  currentLogin: PropTypes.string,
  idToken: PropTypes.string,
  headerToken: PropTypes.string,
  requireConsent: PropTypes.bool,
  consentUserName: PropTypes.string,
  consentOpenToken: PropTypes.string,
  t: PropTypes.func,
  logOut: PropTypes.func,
  setConsent: PropTypes.func
}

LoginAuthHonSSO.defaultProps = {
  currentLogin: '',
  idToken: '',
  headerToken: '',
  requireConsent: false,
  consentUserName: '',
  consentOpenToken: '',
  t: () => null,
  logOut: () => null,
  setConsent: () => null
}

export default LoginAuthHonSSO
