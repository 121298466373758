import {
  createStructuredSelector,
  createSelector
} from 'reselect'

export const getState =  state => null

export const stateSelector = createSelector(
  getState,
  state => state
)

export default createStructuredSelector({
  stateSelector
})
