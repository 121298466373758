import { compose } from 'ramda'
import { connect } from 'react-redux'
import scheduledDevicesSelector from './scheduled-devices.selector'
import ScheduledDevicesModal from './scheduled-devices'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

export default compose(
  connect(scheduledDevicesSelector),
  withLDConsumer()
)(ScheduledDevicesModal)
