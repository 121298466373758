import { createStructuredSelector } from 'reselect'

import { fullDevicesBySite, currentSite } from '../../Selectors/FirmwareSelector'
import { sitesByHierarchy } from '../../Selectors/SiteSelector'

const deviceFormWrapper = createStructuredSelector({
  loading: state => (state.siteManager.loading || state.devices.loading),
  sites: state => state.siteManager.rootSitesById,
  devices: fullDevicesBySite,
  sitesByHierarchy,
  currentSiteId: currentSite
})

export default deviceFormWrapper
