import { Checkbox, Radio, Select } from '@scuf/common'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import PromptError from 'Components/PromptError/prompt-error'

import { useAssetSelector } from './asset-selector.hooks'
import { Container, StyledSelect } from './asset-selector.styles'
import DevicesTable from './device-table'
import AssetFileUploader from '../AssetFileUploader'

export const SelectMode = { 'tags': 1, 'devices': 2, 'sites': 3, 'fromFile': 4 }

const AssetSelector = ({
  input: { onChange },
  assetsList,
  loading,
  setLoading,
  init,
  setIsScheduleFromFile,
  setUploadFile,
  setFileName,
  fileName,
  t }) => {
  const assetId = assetsList[0].id
  const [selectMode, setSelectMode] = useState(get(init, 'selectMode', SelectMode.tags))
  const [includeChildSites, setIncludeChildSites] = useState(get(init, 'includeChildSites', false))
  const [search, setSearch] = useState(undefined)
  const [filter, setFilter] = useState({})
  const flags = useFlags()
  const lastPromise = useRef();

  const useTags = selectMode === SelectMode.tags
  const useSites = selectMode === SelectMode.sites
  const useDeviceList = selectMode === SelectMode.devices
  const useFromFile =  selectMode === SelectMode.fromFile

  const [allDevices, setAllDevices] = useState([])
  const [allTags, setAllTags] = useState([])
  const [allSites, setAllSites] = useState([])

  const devices = useRef([])
  const [tags, setTags] = useState(get(init, 'Tags', []))
  const [site, setSite] = useState(get(init, 'Sites', []))

  const showDeviceTable = !useFromFile && (allDevices.length > 0 || search !== undefined || !isEmpty(filter))
  const tableRef = useRef(null)
  const [currPage, setCurrPage] = useState(1)
  const totalRecords = useRef(0)
  const pageSize = 100

  const { getDevices, getTags, getSites } = useAssetSelector(setLoading)

  useEffect(() => {
    getTags()
      .then(data => setAllTags(data))
      .catch(() => null)
    getSites()
      .then(data => setAllSites(data))
      .catch(() => null)
  }, [])

  useEffect(() => {
    devices.current = []
    if (site.length === 0) {
      setAllDevices([])
    }
    setSearch(undefined)
  }, [selectMode, assetId, tags, site, includeChildSites])

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.infiniteLoaderRef.resetLoadMoreRowsCache()
    }
    setCurrPage(1)
  }, [selectMode, assetId, tags, site, includeChildSites, search, filter])

  useEffect(() => {
  if ((useTags && tags.length) === 0 || (useSites && site.length) === 0 || site === null || useFromFile) {
      return
    }

    if (site != '' || filter != '' || tags.length != 0) {
    const currentPromise = getDevices(
      assetId,
      useTags ? tags : null,
      useSites ? site : null,
      useSites && includeChildSites,
      search,
      filter,
      currPage,
      pageSize)
      .then(({ data, total }) => {
        totalRecords.current = total || 0
        return data
      })
    .catch(() => null)

    lastPromise.current = currentPromise;
    currentPromise.then(
      result =>  {
        if (currentPromise === lastPromise.current) {
          setLoading(false);
          if (result) {
            const devicePage = Object.values(result.devices || [])
            if (currPage === 1) {
              return setAllDevices(devicePage)
            } else {
              setAllDevices(prevState => [...prevState, ...devicePage])
            }
          }
        }
      }
    )}
  }, [selectMode, assetId, tags, site, includeChildSites, search, filter, currPage])

  useEffect(() => {
    submitFormArgs()
  }, [allDevices])

  useEffect(() => {
    if(useFromFile) {
      setAllDevices([])
      setSearch(undefined)
    } else {
      setIsScheduleFromFile(false)
      setFileName('')
      setUploadFile(null)
    }
  }, [selectMode])

  const submitFormArgs = () => {
    onChange({
      selectMode,
      Devices: useDeviceList ? devices.current : allDevices,
      Tags: tags,
      Sites: site ? site : [],
      includeChildSites: includeChildSites || undefined
    })
  }
  return (
    <>
      <span style={{ margin: '1em' }}>
        <Radio
          id='bySite'
          checked={useSites}
          disabled={loading}
          label={t('BySite')}
          onChange={() => {
            setSelectMode(SelectMode.sites)
          }}
        />
        <Radio
          id='byTags'
          checked={useTags}
          disabled={loading}
          label={t('ByGroups')}
          onChange={() => {
            setSelectMode(SelectMode.tags)
          }}
        />
        <Radio
          id='fromList'
          checked={useDeviceList}
          disabled={loading}
          label={t('FromList')}
          onChange={() => {
            setSelectMode(SelectMode.devices)
          }}
        />
        {flags.swUpdateFromFile &&
        <>
        <Radio
          id='fromFile'
          checked={useFromFile}
          disabled={loading}
          label={t('FromFile')}
          onChange={() => {
            setSelectMode(SelectMode.fromFile)
          }}
          />
          <b>{fileName}</b>
        </>}
        {useSites &&
          <span style={{ marginLeft: '4em' }}>
            <Checkbox
              disabled={loading}
              checked={includeChildSites}
              label={t('Include children sites')}
              onChange={checked => setIncludeChildSites(checked)}
            />
          </span>}
      </span>
      {useTags &&
        <Container style={{ maxWidth: '50%' }}>
          <StyledSelect
            label={t('SelectGroupsMsg')}
            fluid
            key={tags.join(':')}
            value={tags}
            multiple
            onChange={tags => setTags(tags)}
            options={allTags}
            search={true}
            placeholder={t('SelectDeviceGroup')}
          />
        </Container>}
      {useSites &&
        <Container>
          <Select
            label={t('SelectSiteMsg')}
            fluid
            value={site}
            onChange={site => setSite(site)}
            options={allSites}
            multiple
            search={true}
            placeholder={t('SelectSite')}
          />
        </Container>}
      {useFromFile
        ? <AssetFileUploader
            setIsScheduleFromFile={setIsScheduleFromFile}
            setUploadFile={setUploadFile}
            setFileName={setFileName}
          />
        : (showDeviceTable
          ? <DevicesTable
            t={t}
            assetId={assetId}
            totalRows={totalRecords.current}
            devices={allDevices}
            allowSelect={useDeviceList}
            showSearch={true}
            selectionChangeHandle={selectedDevices => {
              if (useDeviceList) {
                devices.current = selectedDevices
                submitFormArgs()
              }
            }}
            tableRef={tableRef}
            loadNextPage={setCurrPage}
            pageSize={pageSize}
            searchChangeHandle={setSearch}
            filter={filter}
            setFilter={setFilter}
            setLoading={setLoading}
          />
          : <div style={{ margin: '3rem' }}>
              <PromptError type='softwareUpdates' />
            </div>)
      }
    </>
  )
}

AssetSelector.defaultProps = {
  t: label => label
}

AssetSelector.propTypes = {
  t: PropTypes.func
}

export default AssetSelector
