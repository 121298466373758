import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import * as R from 'ramda'
import { CheckInOutActionTypes } from './actions'

export const INITIAL_STATE = Immutable({
  devices: {
    entities: {},
    result: []
  }
})

export const checkOutDevices = (state, { information, deviceIds }) => {
  const updatedDevices = deviceIds.reduce((deviceList, id) => ({
    ...deviceList,
    [id]: {
      ...information,
      status: 'Checked Out'
    }
  }), {})

  const newDeviceIds = R.union(deviceIds, R.pathOr([], ['devices', 'result'], state))

  return Immutable(R.mergeDeepRight(state, {
    devices: {
      entities: {
        devices: updatedDevices
      },
      result: newDeviceIds
    }
  }))
}

export const checkInDevices = (state, { deviceChanges }) => {
  const updatedDevices = R.mapObjIndexed((val, key, obj) => ({
    ...val,
    userId: null,
    checkoutDueDatetime: null,
    returnLocation: null,
    status: val.damaged ? 'Damaged' : 'Checked In'
  }), deviceChanges)

  const newDeviceIds = R.union(R.keys(updatedDevices), R.pathOr([], ['devices', 'result'], state))

  return Immutable(R.mergeDeepRight(state, {
    devices: {
      entities: {
        devices: updatedDevices
      },
      result: newDeviceIds
    }
  }))
}

export const transferDevices = (state, { userId, deviceIds }) => {
  const updatedDevices = deviceIds.reduce((deviceList, id) => ({
    ...deviceList,
    [id]: {
      userId
    }
  }), {})

  const newDeviceIds = R.union(deviceIds, R.pathOr([], ['devices', 'result'], state))

  return Immutable(R.mergeDeepRight(state, {
    devices: {
      entities: {
        devices: updatedDevices
      },
      result: newDeviceIds
    }
  }))
}

export const checkInOutReducer = createReducer(INITIAL_STATE, {
  [CheckInOutActionTypes.CHECK_OUT_DEVICES]: checkOutDevices,
  [CheckInOutActionTypes.CHECK_IN_DEVICES]: checkInDevices,
  [CheckInOutActionTypes.TRANSFER_DEVICES]: transferDevices
})
