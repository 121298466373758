import React, { useState } from 'react'
import {
  SelectorContainer,
  SiteSelector,
  IconContainer,
  StyledButton
} from './site-selector.styles'
import { PreferencesApi } from 'Services'
import { Icon } from '@scuf/common'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'
import { toast } from 'react-toastify'

const PreferencesSiteSelector = ({
  sites,
  t,
  selectedOrgId,
  updateSites,
  isAlertsEnabled,
  selectedSites,
  openModal
}) => {
  const [currentSelectedSites, setCurrentSelectedSites] = useState(selectedSites)
  const [siteSelectionHasChanged, setSiteSelectionHasChanged] = useState(false)

  const saveChanges = async () => {
    const result = await PreferencesApi.disableInstantAlertsNotifications(isAlertsEnabled, selectedOrgId, currentSelectedSites)
    if (result.ok) {
      setSiteSelectionHasChanged(false)
      setCurrentSelectedSites(currentSelectedSites)
      updateSites(currentSelectedSites)
      toast(<SuccessToast message={t('SaveSuccess')} />)
    } else {
      toast(<ErrorToast message={t('ErrorSavingConfiguration')} />)
    }
  }

  const changeSites = async (sites) => {
    setCurrentSelectedSites(sites)
    setSiteSelectionHasChanged(true)
  }

  const addSite = async (site) => {
    if (!currentSelectedSites.includes(site)) {
      setCurrentSelectedSites([...currentSelectedSites, site])
      setSiteSelectionHasChanged(true)
    }
  }

  return (
    <div>
      <SelectorContainer changePending={siteSelectionHasChanged}>
        <label>{t('Sites')}</label>
        <SiteSelector
          value={currentSelectedSites}
          options={sites}
          multiple={true}
          onChange={(value) => {
            changeSites(value)
          }}
          search={true}
        />
        <IconContainer
          onClick={() => openModal('dashboardSitesFilter', {
            closeIcon: true,
            size: 'small',
            className: 'modal-height modal-center',
            closeOnDimmerClick: false,
            addSite,
            sitePreferences: true
          })}
        >
          <Icon root="common" name="submenu" size="medium" />
        </IconContainer>
      </SelectorContainer>
      <StyledButton type='primary' content='Save Selection' onClick={saveChanges} disabled={!siteSelectionHasChanged} />
    </div>
  )
}

export default PreferencesSiteSelector
