import { withFormik } from 'formik'
import { translate } from 'react-translate'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import BaseModal from '../modal-base'
import validationSchema from './edit-network-ranges.validators'
import { NetworkRangesActions } from '../../store'

export const mapStateToProps = state => {
  return {
    isOpen: state.networkRanges.getIn(['isShowingModal'], false),
    form: state.networkRanges.getIn(['form'], {})
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(NetworkRangesActions.closeModal()),
    editNetworkRangeRequest: (id, networkRange, callback) => dispatch(NetworkRangesActions.editNetworkRangeRequest(id, networkRange, callback))
  }
}

export default pipe(
  withFormik({
    mapPropsToValues: ({ form }) => form,
    validationSchema,
    handleSubmit: (payload, { props, resetForm }) => props.editNetworkRangeRequest(
      props.id,
      payload,
      () => {
        props.onSubmitSuccess()
        resetForm()
      }),
    displayName: 'NetworkRanges'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('EditNetworkRanges')
)(BaseModal)
