import React, { memo } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

const NotificationsFooter = styled.div`
display: flex;
flex: 1;
flex-direction:row;
justify-content: flex-end;
padding: 0.5rem 1rem;
font-weight: bold;
`
const Text = styled.a`
padding-right: 1.5rem;
cursor:pointer;
`
const footer = memo(({ t }) => {
  const history = useHistory()
  return (
    <NotificationsFooter>
      <Text onClick={() => history.push('/alerts/')}> { t('BellNotification_ViewAll') }</Text>
    </NotificationsFooter>
  )
})

export default footer
