import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setSitePermissions: ['siteId', 'permissions'],
  permissionsRequest: null,
  permissionsSuccess: ['allPermissions', 'permissionsPerSites'],
  permissionsFailure: ['errors', 'tryAttemps']
}, {
  prefix: '@PERMISSIONS/'
})

export const PermissionsTypes = Types
export const PermissionsActions = Creators
