import styled from 'styled-components/macro'
const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`
const Header = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
`
const ActionWrapper = styled.div`
  cursor: pointer;
  padding: 0 0 1rem 0;
  font-weight: bold;
  color: ${({ color }) => color || 'black'} ;
  & .icon {
    color: ${({ color }) => color || ''} !important
  }
`
const Actions = styled.div`
`

const TimeSeparator = styled.div`
  padding: 1rem 0.5rem;
`

const Container = styled(Flex)`
  flex-direction: column
`

const FrequencyText = styled(Flex)`
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 0.5rem;
  font-weight: 500;
`
export {
  Header,
  ActionWrapper,
  Actions,
  TimeSeparator,
  Container,
  FrequencyText
}
