import styled from 'styled-components/macro'

const Container = styled.div`
  position: relative;
  display: flex;
flex-direction: column;
margin: 1rem ;
background-color: ${props => props.theme.dashboardcard ? props.theme.dashboardcard : '#ffffff'};
 
width: 400px;
`
const SubContainerNotAvailable = styled.div`
  position: absolute;
   min-width: -webkit-fill-available;
  top: 40%;
  padding: 1rem;
  
`
const CardContainer = styled.div`
display: flex;
flex-direction: column;
margin: 1rem, 1rem, 1rem, 1rem;
background-color: ${props => props.theme.dashboardcard ? props.theme.dashboardcard : '#ffffff'};
padding: 1rem;
width: 400px;
`

const HeaderContainerNotAvailable = styled.div`
    text-align: center;
    font-family: 'Honeywell Sans Web';
    margin: 0em;
    color: #606060;
    font-weight: bold;
    font-size: 18px;
 
  `
const ContentContainerNotAvailable = styled.p`
  text-align: center ;
  font-family: 'Honeywell Sans Web';
  margin: -0.2em;
  color: #1274B7;
  font-weight: bold;
  font-size: 18px;
  top: 50%;
  left: 50%;
`
const OptionContainer = styled.div`
display: flex;
margin: 0.5rem 1.5rem;
`

export { Container, SubContainerNotAvailable, HeaderContainerNotAvailable, ContentContainerNotAvailable, OptionContainer, CardContainer }
