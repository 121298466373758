import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import MobileOsModal from './mobile-os-modal'
import MobileOsModalSelector from './mobile-os-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    MobileOsModalSelector,
    mapDispatchToProps
  )
)(MobileOsModal)
