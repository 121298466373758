import { createSelector } from 'reselect'
import moment from 'moment'

const getSessionTimeout = state => state.login.sessionTimeout

const selectRenewSessionFlag = ({flags}) => flags.renewSession

const getRenewSessionError = state => { return state.login.errorRefreshToken === 'notExtended' ? true : false}

const getRenewSessionStatus = state => state.login.loading

export const getsessionExpirationTimestamp = createSelector(
    [getSessionTimeout],
    (sessionTimeout) => {
        return moment(sessionTimeout).unix()
    }
)

export default (state, props) => {
    return {
        sessionExpirationTimestamp: getsessionExpirationTimestamp(state),
        isSessionExtendedError: getRenewSessionError(state),
        renewSessionStatus: getRenewSessionStatus(state),
        isRenewSessionEnabled: selectRenewSessionFlag(props)
    }    
}