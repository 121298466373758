import { createStructuredSelector } from 'reselect'

const initialState = {
  pageNumber: 1,
  search: '',
  sortField: '',
  sortDirection: 'ASC',
  selectedSites: [],
  includeChildren: true,
}

export const detailsDataTableReducer = (state = initialState, { payload, type } = {}) => {
  switch (type) {
    case resetFilters.type:
      return {
        ...state,
        search: '',
        sortField: '',
        sortDirection: 'ASC',
        selectedSites: [],
        includeChildren: true,
      }
    case setPageNumber.type:
      return {
        ...state,
        pageNumber: payload
      }
    case setSearch.type:
      return {
        ...state,
        search: payload,
        pageNumber: 1
      }
    case setSort.type:
      return {
        ...state,
        sortField: payload.sortField,
        sortDirection: payload.sortDirection
      }
    case setSelectedSites.type:
      return {
        ...state,
        selectedSites: payload
      }
    case setIncludeChildren.type:
      return {
        ...state,
        includeChildren: payload
      }
    default:
      return state
  }
}
detailsDataTableReducer.slice = 'details-table'

export const resetFilters = () => ({ type: resetFilters.type })
resetFilters.type = 'details-table/resetFilters'

export const setPageNumber = (pageNumber = 0) => ({ type: setPageNumber.type, payload: pageNumber })
setPageNumber.type = 'details-table/setPageNumber'

export const setSearch = (search = '') => ({ type: setSearch.type, payload: search })
setSearch.type = 'details-table/setSearch'

export const setSort = (sortField = '', sortDirection = 'ASC') => ({ type: setSort.type, payload: { sortField, sortDirection } })
setSort.type = 'details-table/setSort'

export const setSelectedSites = (selectedSites = []) => ({ type: setSelectedSites.type, payload: selectedSites })
setSelectedSites.type = 'details-table/setSelectedSites'

export const setIncludeChildren = (includeChildren = false) => ({ type: setIncludeChildren.type, payload: includeChildren })
setIncludeChildren.type = 'details-table/setIncludeChildren'


export const getPageNumber = state => state[detailsDataTableReducer.slice].pageNumber
export const getSearch = state => state[detailsDataTableReducer.slice].search
export const getSortField = state => state[detailsDataTableReducer.slice].sortField
export const getSortDirection = state => state[detailsDataTableReducer.slice].sortDirection
export const getSelectedSites = state => state[detailsDataTableReducer.slice].selectedSites
export const getIncludeChildren = state => state[detailsDataTableReducer.slice].includeChildren

export const getDetailsDataTableSelectors = createStructuredSelector({
  pageNumber: getPageNumber,
  search: getSearch,
  sortField: getSortField,
  sortDirection: getSortDirection,
  selectedSites: getSelectedSites,
  includeChildren: getIncludeChildren,
})
