import React from 'react'
import styled from 'styled-components'
import { Icon } from '@scuf/common'
import { useTranslate } from 'react-translate'

import { useServiceWorker } from 'features/service-worker'

const Banner = styled.div`
  display: flex;
  background-color: #1891e5;
  flex: 1;
  height: 2.25rem;
  justify-content: space-between;
  align-content: center;
`

const MessageContainer = styled.div`
  color: #ffffff;
  align-self: center;
  justify-content: space-around;
  cursor: pointer;
`
const Message = styled.span`
  padding-left: 0.5rem;
`

const IconContainer = styled.div`
  align-self: center;
  cursor: pointer;
`

function AppVersionBanner () {
  const t = useTranslate('VersionBanner')
  const {
    isNewVersion,
    sendUpdateAppMessage,
    isBannerClosed,
    closeBanner
  } = useServiceWorker()

  return ((isNewVersion && !isBannerClosed) &&
    <Banner>
      <IconContainer />
      <MessageContainer onClick={sendUpdateAppMessage}>
        <Icon root='common' name='badge-info' color='white' />
        <Message>{t('New version')}</Message>
      </MessageContainer>
      <IconContainer onClick={closeBanner}>
        <Icon name='close' color='white' />
      </IconContainer>
    </Banner>
  )
}

export default AppVersionBanner
