import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { RolesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  rolesPerOrganization: {},
  roles: {},
  errors: []
})

export const rolesRequest = state => state.merge({
  loading: true
})

export const rolesSuccess = (state, { roles, rolesPerOrganization }) => state.merge({
  loading: false,
  rolesPerOrganization,
  roles
})

export const rolesFailure = (state, { errors }) => state.merge({
  loading: false,
  errors
})

export const reducer = createReducer(INITIAL_STATE, {
  [RolesTypes.ROLES_REQUEST]: rolesRequest,
  [RolesTypes.ROLES_SUCCESS]: rolesSuccess,
  [RolesTypes.ROLES_FAILURE]: rolesFailure
})
