import styled from 'styled-components'
import { Modal, Button, TextArea } from '@scuf/common'

const ModalWrap = styled.div`
  background: ${props => props.theme.background === '#202020 !important' ? '#202020 !important' : '#fff'}  ;
  & .forge-datatable-wrap {
    min-height:350px;
  }
  & .ui.modal .close-icon-wrap .modal-close {
    overflow: hidden !important;
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    background-color: transparent !important;
  }

  & .accordion.ui.fluid .active.content {
    background: ${props => props.theme.background};
    border: none !important;
  }

  & .ui.dashboard-wrap .layout-grid .ui.widget-container {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder};
  }
  & .ui.widget-container .widget-wrapper > div {
    border: none !important;
  }
  & .ui.widget-container .widget-wrapper > div > div > div > img{
    background: #fff;
    border-radius: 3px;
  }
  & .accordion.ui.fluid .title {
    border: none !important;
  }
  & .cONqjN {
    box-shadow: ${props => props.theme.boxShadow};
  }
  & .eTvQHC {
    border-top: ${props => props.theme.darkBorder};
  }
  & .cBfEdo {
    margin-top:1px;
  } 
  & .cell-data-wrap {
    font-size: 12px;
  }
`
const HeaderWrap = styled.div`
  width:100%;
`
const StyledH2 = styled.div`
  color: #404040;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
`
const ParagraphModal = styled.div`
  margin-top:1.8vh;
  font-size: .875rem;
  color: #404040;
  line-height: 22px;
`
const StyledTextArea = styled(TextArea)`
margin-top:1vh;
textarea {
    resize: none !important;
  }
  overflow-x: auto;
`
const ModalHeader = styled(Modal.Header)`
  padding: 0 !important;
`
const StyledSubmit = styled(Button)`
justifyContent: flex-end;
`

const ModalFooter = styled(Modal.Footer)`
padding-right: 0.2rem !important;
padding-bottom: 0.5rem !important;
padding-left: 1rem !important;
  & a {
    float: left;
  }
`
const NotAvailable = styled.div`
font-family: 'Honeywell Sans Web';
font-size: .75rem !important;
`

export {
  ModalWrap,
  HeaderWrap,
  StyledH2,
  ParagraphModal,
  ModalHeader,
  ModalFooter,
  StyledTextArea,
  StyledSubmit,
  NotAvailable
}
