const create = (api) => {

  const listFloorPlans = (filterName, ids) => {
    const json = JSON.stringify({ [filterName]: { '$in': ids } })
    return api.get(`/floor-plans?filter=${json}`)
  }

  const getFloorPlan = (id) => api.get(`/floor-plans/${id}`)

  const uploadFloorPlan = (floorPlan) => {
    const formData = new FormData()
    formData.append('image', floorPlan.image)
    formData.append('name', floorPlan.name)
    formData.append('entity_id', floorPlan.entityId)
    formData.append('building_id', floorPlan.buildingId)
    formData.append('site_id', floorPlan.siteId)
    formData.append('floor_num', floorPlan.floorNum)
    formData.append('width', floorPlan.width)
    formData.append('height', floorPlan.height)

    return api.post(`/floor-plans`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const updateFloorPlan = (floorPlan) => {
    const formData = new FormData()
    formData.append('image', floorPlan.image)
    formData.append('name', floorPlan.name)
    formData.append('entity_id', floorPlan.entityId)
    formData.append('site_id', floorPlan.siteId)
    formData.append('floor_num', floorPlan.floorNum)
    formData.append('width', floorPlan.width)
    formData.append('height', floorPlan.height)

    return api.post(`/floor-plans/${floorPlan.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const deleteFloorPlan = (id) => api.delete(`floor-plans/${id}`)

  const deleteFloorPlansForSite = (id) => {
    const json = JSON.stringify({ 'siteId': { '$in': id } })
    return api.delete(`/floor-plans?filter=${json}`)
  }

  return {
    listFloorPlans,
    getFloorPlan,
    uploadFloorPlan,
    updateFloorPlan,
    deleteFloorPlan,
    deleteFloorPlansForSite
  }
}

export default {
  create
}
