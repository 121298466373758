import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import mapStateToProps from './controls.selector'
import Controls from './controls.component'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

export default pipe(
  connect(
    mapStateToProps
  ),
  translate('Controls'),
  withLDConsumer()
)(Controls)
