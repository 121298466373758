import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledSelect,
  StyledIcon,
  ActionContainer,
  InputsContainer,
  StyledButton,
  StyledButtonContainer,
} from '../../tags.styles'
import ActionBar from 'Components/ActionBar'
import { useKeyValue } from '../../tags.hooks'
import { translate } from 'react-translate'

const Tags = ({
  createTag,
  setIsAddingTag,
  t,
  ...rest
}) => {
  const [searchValue, setSearch] = useState(undefined)
  const [searchKey, setSearchKey] = useState(undefined)
  const [value, setValue] = useState('')
  const {
    key,
    keys,
    setKey,
    values
  } = useKeyValue()
  return (
    <InputsContainer>
      <ActionBar>
        <ActionBar.Section>
          <StyledSelect
            label={t('Name')}
            onChange={value => {
              setKey(value)
              if (searchKey && value !== searchKey.value) {
                setSearchKey(undefined)
              }
            }}
            onSearch={search => setSearchKey({
              text: search,
              value: search
            })}
            options={searchKey
              ? [
                ...keys,
                searchKey
              ]
              : keys
            }
            search
            value={key}
          />
          <StyledSelect
            disabled={!key}
            label={t('Value')}
            onChange={value => {
              setValue(value)
              if (searchValue && value !== searchValue.value) {
                setSearch(undefined)
              }
            }}
            onSearch={search => setSearch({
              text: search,
              value: search
            })}
            options={searchValue
              ? [
                ...values,
                searchValue
              ]
              : values
            }
            search
            value={value}
          />
          <StyledButtonContainer>
            <StyledButton
              content={t('Submit')}
              disabled={!value}
              onClick={() => {
                createTag({
                  key,
                  value
                })
                setKey('')
                setValue('')
                setSearchKey(undefined)
                setSearch(undefined)
              }}
              type='primary'
            />
          </StyledButtonContainer>
        </ActionBar.Section>
        <ActionBar.Section>
          <ActionContainer>
		        {t('Cancel')}
            <StyledIcon
              description={t('Cancel')}
              name='close'
              onClick={() => { setIsAddingTag(false) }}
              root='common'
              size='small'
            />
          </ActionContainer>
        </ActionBar.Section>
      </ActionBar>
    </InputsContainer>
  )
}

Tags.defaultProps = {
  t: label => label,
  t: PropTypes.func
}

Tags.propTypes = {
  t: PropTypes.func,
  createTag: PropTypes.func,
  setIsAddingTag: PropTypes.func,
  t: () => null
}

export default translate('TaggingView')(Tags)
