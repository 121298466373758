import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from "../../../../../Components/AssetManagementTable"
import { ModalWrap, ModalTitle } from './security-patch-modal.styles'

export const columns = [
  { field: 'key', translation: 'AssetManagementTable_updated', render: null },
  { field: 'value', translation: 'AssetManagementTable_modelCount', render: null },
  { field: 'percentage', translation: 'AssetManagementTable_percentage', render: ({ value }) => <p>{value} %</p> }
]

const SecurityPatchModal = ({ data, t }) => {
return(

  <ModalWrap>
    <ModalTitle>{t('SecurityPatch_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        columns={columns}
        data={data}
        disableRowClick
        fileName={t('SecurityPatch_title')}
      />
    </Modal.Content>
  </ModalWrap>
)
}


export default SecurityPatchModal
