import isEqual from 'lodash/isEqual'
import React, { useRef } from 'react'
import { useTranslate } from 'react-translate'
import { Form, FormSection } from 'redux-form'
import styled from 'styled-components/macro'

import DataTable from '../../../DataTable'
import DateLocationFormat from '../../../DateLocationFormat'
import { DRIVER_TYPES } from '../../schedule-updates.constants'
import DataNameField from '../DataNameField'
import DataSelection from '../DataSelection'
import DataLabel from './DataLabel'

const DataTableContainer = styled.div`
  width: 100%;
  height: ${props => props.compact ? 'calc(100% - 3rem)' : '100%'};
`

const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
`

const ImagesTable = ({
  handleSubmit,
  data,
  submit,
  updatesSelectAssets,
  onSubmit,
  compact,
  onSchedule,
  isValidTime,
  valid
}) => {
  const t = useTranslate('ScheduleUpdateModal')
  const assetList = useRef([])

  const handleSelectionChange = selectedAssets => {
    if (!isEqual(selectedAssets, assetList.current)) {
      assetList.current = selectedAssets
      updatesSelectAssets(selectedAssets)
    }
  }

  return (
    <StyledForm
      onSubmit={
        handleSubmit(form => {
          onSubmit(assetList.current.map(
            a => {
              return {
                ...a,
                form: form.deviceList[a.id]
              }
            }
          ))
        })
      }
    >
      <DataTableContainer compact={compact}>
        <FormSection name='deviceList'>
          <DataTable
            search
            data={data}
            selection
            selectionMode='single'
            totalRows={data.length}
            rows={data.length}
            rowHeight={40}
            generateFilters
            onSelectionChange={handleSelectionChange}
          >
            <DataTable.ActionBar>
              {isValidTime && valid &&
                <DataTable.ActionBar.Item
                  content={t('Update')}
                  onClick={submit}
                />
              }
            </DataTable.ActionBar>
            <DataTable.Column
              label={t('Update Name')}
              dataKey='name'
              width={0.75}
              flexShrink={0.75}
              flexGrow={0.75}
              cellRenderer={props =>
                <DataNameField {...props} />
              }
            />
            <DataTable.Column
              label={t('Version')}
              dataKey='version'
              cellRenderer={({ cellData }) => {
                return cellData || 'Not applicable'
              }}
              width={0.3}
              flexShrink={0.3}
              flexGrow={0.3}
            />
            <DataTable.Column
              label={t('File size')}
              dataKey='fileSize'
              width={0.25}
              flexShrink={0.25}
              flexGrow={0.25}
            />
            <DataTable.Column
              label={t('Release date')}
              dataKey='releaseDate'
              width={0.5}
              flexShrink={0.5}
              flexGrow={0.5}
              cellRenderer={props =>
                <DateLocationFormat
                  {...props}
                  field='releaseDate'
                />
              }
            />
            <DataTable.Column
              label={t('Drive')}
              dataKey='drive'
              width={0.7}
              flexShrink={0.7}
              flexGrow={0.7}
              cellRenderer={props => {
                return (
                  <DataSelection
                    {...props}
                    id={props.rowData.id}
                    options={DRIVER_TYPES}
                  />
                )
              }}
            />
            <DataTable.Column
              label={t('Label')}
              dataKey='label'
              width={0.7}
              flexShrink={0.7}
              flexGrow={0.7}
              cellRenderer={props => {
                return (
                  <DataLabel
                    {...props}
                    id={props.rowData.id}
                    placeholder={t('Required label')}
                  />
                )
              }}
            />
          </DataTable>
        </FormSection>
      </DataTableContainer>
    </StyledForm>
  )
}

ImagesTable.defaultProps = {
  data: []
}

export default ImagesTable
