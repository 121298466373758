import React, { Component, memo } from 'react'
import DataTable from '../../../../../DataTable'
import { Input } from '@scuf/common'
import PropTypes from 'prop-types'
import { InputWrapper, StyledBadge, StatusWrapper } from './SiteEditTable.styles'

const AliasInput = memo(({ rowIndex, data, updateData, value, error }) => {
  return (
    <InputWrapper>
      <Input value={value} onChange={newValue => {
        const newData = [...data]
        newData[rowIndex] = newData[rowIndex].setIn(['NewDeviceAlias'], { value: newValue })
        updateData(newData)
      }}
      />
    </InputWrapper>
  )
})

AliasInput.displayName = 'AliasInput'
AliasInput.propTypes = {

}

class SiteEditTable extends Component {

  render() {
    const { t, data, sites } = this.props
    return (
      <DataTable
        data={data}
      >
        <DataTable.Column
          cellRenderer={({ cellData }) => <p> {cellData.value}</p>}
          flexGrow={3}
          flexShrink={3}
          dataKey='serial'
          label='Device Serial Number'
          width={3}
        />
        <DataTable.Column
          cellRenderer={({ rowIndex, cellData = {} }) =>
            <AliasInput data={data} error={cellData.error} value={cellData.value}
              rowIndex={rowIndex}
              updateData={this.props.updateBulkEditData}
            />}
          dataKey='alias'
          flexGrow={3}
          flexShrink={3}
          label='Device Alias'
          width={3}
        />
        <DataTable.Column
          cellRenderer={({ cellData, rowIndex }) => {
            if (!cellData || cellData.error) {
              return <p> Site not found </p>
            } else {
              return <p> {cellData.value}</p>
            }
          }}
          dataKey='NewDeviceSite'
          label='Site'
          width={6}
          flexGrow={6}
          flexShrink={6}
        />

        <DataTable.Column
          cellRenderer={({ cellData }) =>
            !cellData ?
              <StatusWrapper>
                <StyledBadge color='green' empty /> Ready
              </StatusWrapper>
              :
              <StatusWrapper>
                <StyledBadge color={cellData.value ? 'green' : 'red'} empty /> {cellData.value || cellData.error}
              </StatusWrapper>
          }
          flexGrow={2}
          flexShrink={2}
          dataKey='status'
          label='Status'
          width={2}
        />
      </DataTable >
    )
  }
}

SiteEditTable.propTypes = {
  t: PropTypes.func,
  data: PropTypes.array,
  updateBulkEditData: PropTypes.func,
  sites: PropTypes.array
}

export default SiteEditTable
