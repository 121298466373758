import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import mapStateToProps from './edit-tags.selector'
import EditTags from './edit-tags.component'
import { TaggingActionCreators } from 'Store/tagging'
import { withPermissions } from 'Utils/permissions'


export const mapDispatchToProps = dispatch => {
  return {
    getValues: () => dispatch(TaggingActionCreators.tagsAllKeysRequest()),
    updateValue: (key, newValue) => dispatch(TaggingActionCreators.tagUpdateValue(key, newValue))
  }
}

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate('EditTags'),
  withPermissions()
)(EditTags)
