import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

export class PermissionsService {
  constructor (api) {
    this.api = api
  }

  getPermissions = async (params) => {
    const response = await this.api.get('/me/permissions', params)
    if (!response?.ok) {
      return response
    }
    try {
      const permissions = response.data?.data ?? {}
      const permissionsKeys = Object.keys(permissions)

      const allPermissions = mapValues(permissions, (values, key) => !(key === 'asset:viewonlyassigned' && values.length === 0) )

      const permissionsPerSite = permissionsKeys.reduce((sitesWithPermissions, permissionKey) => {
        return get(permissions, permissionKey, [])
          .reduce((sites, site) => {
            if (sites[site] !== undefined) {
              sites[site].push(permissionKey)
            } else {
              sites[site] = [permissionKey]
            }
            return sites
          }, sitesWithPermissions)
      }, {})

      return {
        ...response,
        data: {
          allPermissions,
          permissionsPerSite
        }
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getAuthorizedOrganizationalUnits = (permission) => this.api.get(`/me/permissions/${permission}/organizational-units`)

  getAllPermissions = () => this.api.get(`/permissions`)

  getAllFeatures = () => this.api.get(`/permissions/features`)
}
