import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createReducer } from '@reduxjs/toolkit'
import { PermissionsTypes } from './actions'
import { LoginTypes } from 'Redux/LoginRedux'
import config from '../../Config/AppConfig'

export const INITIAL_STATE = {
  loading: false,
  allPermissions: {},
  permissionsPerSites: {},
  errors: []
}

export const setSitePermissions = (state, { siteId, permissions }) => {
  state.permissionsPerSites[siteId] = permissions
}

export const permissionsRequest = state => {
  state.loading = true
  state.errors = []
}

export const permissionsSuccess = (state, { allPermissions, permissionsPerSites }) => {
  state.loading = false
  state.allPermissions = allPermissions
  state.permissionsPerSites = permissionsPerSites
}

export const permissionsFailure = (state, { errors }) => {
  state.loading = false
  state.errors = errors
}

export const permissionsLogout = (state) => {
  state.loading = false
  state.allPermissions = {}
  state.permissionsPerSites = {}
  state.errors = []
}

export const baseReducer = createReducer(INITIAL_STATE, {
  [PermissionsTypes.SET_SITE_PERMISSIONS]: setSitePermissions,
  [PermissionsTypes.PERMISSIONS_REQUEST]: permissionsRequest,
  [PermissionsTypes.PERMISSIONS_SUCCESS]: permissionsSuccess,
  [PermissionsTypes.PERMISSIONS_FAILURE]: permissionsFailure,
  [LoginTypes.LOGIN_LOGOUT_REQUEST]: permissionsLogout
})

export const reducer = persistReducer({
  key: 'permissions',
  storage,
  blacklist: [
    'loading'
  ],
  debug: config.debug
}, baseReducer)
