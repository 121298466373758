const create = (api) => {
  const checkOutAssets = (data) => api.post('/inventories/opintel/checkout-commands', data, { timeout: 10000 })
  const checkInAssets = (data) => api.post('/inventories/opintel/checkin-commands', data, { timeout: 10000 })

  return {
    checkOutAssets,
    checkInAssets
  }
}

export default {
  create
}
