import { filter, get, isEmpty, union } from 'lodash'
import memoize from 'memoize-one'
import { search, sort } from '../../Utils'

// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
// This actually helps a lot

export const getTableCoreProps = memoize((data) => {
  return {
    rowCount: data.length,
    rowGetter: ({ index }) => data[index],
    data
  }
})

export const getFullIndexedData = memoize((data) => {
  return (data || []).map((d, i) => ({ ...d, initialIndex: i }))
})

export const getSearchData = memoize((state, data, globalFilterValue, useDeepSearch) => {
  const searchData = search(data, state.searchFilterValue || globalFilterValue, useDeepSearch)

  return state.sortBy && state.sortDirection
    ? sort(state.sortBy, state.sortDirection, searchData)
    : searchData
})

function getUnionData (data, unions) {
  const unionPaths = Object.keys(unions)
  return filter(data, (item) =>
    !isEmpty(unionPaths.filter(path => {
      return Array.isArray(item[path])
        ? item[path].map(i => {
          return unions[path].includes(i) ? true : null
        }).toString().replace(/,/g, '')
        : unions[path].includes(get(item, path))
    }
    )))
}

function getIntersectionData (data, intersections) {
  const interPaths = Object.keys(intersections)

  return filter(data, (item) =>
    interPaths.filter(path =>
      intersections[path] === get(item, path)).length === interPaths.length
  )
}

export const getFilteredData = memoize((data, unions, intersections) => {
  const unionData = isEmpty(unions)
    ? data
    : getUnionData(data, unions)

  return isEmpty(intersections)
    ? unionData
    : getIntersectionData(unionData, intersections)
})

export const getSelectedItems = (args) => {
  if (args.isOnSelectionV2) {
    return args.isAccessPoint ? args.exportedData
    : args.selectionFullItems
      ? args.data.filter(item => args.selectedItems.some(s => s.id === item.id))
      : args.selectedItems
  }
  else {
    return args.isAccessPoint ? args.exportedData
    : args.selectionFullItems
      ? args.data.filter(item => args.selectedItems.includes(get(item, args.selectPath, undefined)))
      : args.selectedItems
  }
}

export const getFiltersFromPath = memoize((data, filterPath) => {
  return data.reduce((acc, item) => {
    const filt = get(item, filterPath, undefined)
    if (Array.isArray(filt)) {
      return union(acc, filt)
    }
    return !filt || acc.includes(filt) ? acc : [...acc, filt]
  }, [])
})

export const formFilterSections = memoize((data, filterSections) => {
  return filterSections.map(f => ({
    ...f,
    filters: getFiltersFromPath(data, f.path)
  }))
})

export const getFilterCount = memoize((filters) => {
  return Object.keys(filters).reduce((acc, item) => {
    return Array.isArray(filters[item])
      ? acc + filters[item].length
      : acc + 1
  }, 0)
})
