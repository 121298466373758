import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import UpdatesActions from '../../../../Redux/UpdatesRedux'
import { isValidTime, onSchedule, updatesImagesList } from '../../schedule-updates.selector'
import ImagesTable from './images-table'

const validate = ({ deviceList }) => {
  if (deviceList) {
    const list = Object.keys(deviceList).reduce((errors, asset) => {
      if (!deviceList[asset].selected) {
        return errors
      }
      return {
        ...errors,
        [asset]: {
          drive: deviceList[asset].drive ? '' : 'error',
          fontId: deviceList[asset].fontId ? '' : 'error',
          label: deviceList[asset].label ? '' : 'error'
        }
      }
    }, {})
    return { deviceList: list }
  }
}

const mapStateToProps = state => {
  return {
    data: updatesImagesList(state),
    onSchedule: onSchedule(state),
    isValidTime: isValidTime(state),
    initialValues: {
      requestPriority: 'Optional',
      deviceList: {}
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatesSelectAssets: updates => {
      const updatesId = updates.map(u => u.id)
      dispatch(UpdatesActions.updatesSelectAssets(updatesId))
    }
  }
}

export default pipe(
  reduxForm({
    form: 'imagesPrinterForm',
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImagesTable)
