import React from 'react'
import { Checkbox } from '@scuf/common'

const FormCheckbox = (props) => {
  const {
    placeholder,
    label,
    input: { value, onChange },
    type,
    disabled,
    style
  } = props
  return (
    <Checkbox
      label={label}
      disabled={disabled}
      className='Input-Label-Error'
      placeholder={placeholder}
      type={type}
      style={{...style, marginTop: '0.9em'}}
      checked={ !!value }
      onChange={ onChange }

    />
  )
}

export default FormCheckbox
