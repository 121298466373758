import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TranslatorProvider } from 'react-translate'


import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/zh-cn'
import 'moment/locale/en-ca'
import 'moment/locale/pt-br'
import 'moment/locale/ja'
import 'moment/locale/th'
import 'moment/locale/ko'

const momentLocations = {
  'en-US': 'en',
  'de-DE': 'de',
  'es-MX': 'es',
  'fr-FR': 'fr',
  'zh-CN': 'zh-cn',
  'pt-BR': 'pt-br',
  'ja-JA': 'ja',
  'ko-KR': 'ko',
  'th-TH': 'th',
}

export class Translate extends Component {
  constructor(props) {
    super(props)
    this.state = { translations: null }
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.setup()
    }
  }

  componentDidMount() {
    this.setup()
  }

  setup() {
    moment.locale(momentLocations[this.props.lang])
    this.setState({ translations: require(`../i18n/${this.props.lang || 'en-US'}.json`) })
  }

  render() {
    const { translations } = this.state
    return (
      translations
        ? <TranslatorProvider translations={this.state.translations}>
          {this.props.children}
        </TranslatorProvider>
        : null
    )
  }
}

const mapStateToProps = state => {
  const lang = state.user.getIn(['language'], 'en-US')
  return {
    lang,
    key: lang
  }
}

export default connect(
  mapStateToProps
)(Translate)
