import React from 'react'
import styled from 'styled-components/macro'
import get from 'lodash/get'

import { RowItem } from '../../../DataTable'
import { FormRow } from '../../../../Themes/ScufStyledComponents'
import DeviceType from '../device-type'

const StatusTitle = styled.p`
  padding-left: 0;
  text-transform: uppercase;
`

export function DeviceTypeFromCellData ({ cellData }) {
  return <DeviceName rowData={{ type: cellData }} />
}

const DeviceName = ({
  t,
  cellData,
  rowData,
  onClick,
  dataKey
}) => {
  const deviceName = get(rowData, dataKey, '')
  const type = get(
    rowData,
    'type',
    get(rowData, 'deviceType', '')
  )
  return (
    <RowItem>
      <FormRow>
        <DeviceType t={t} cellData={type} />
      </FormRow>
    </RowItem>
  )
}

DeviceName.defaultProps = {
  t: label => label
}

export default DeviceName
