import { createStructuredSelector, createSelector } from 'reselect'
import get from 'lodash/get'

export const details = ({ modal }) => modal.getIn(['modalProps', 'details'], [])

export const columns = createSelector(
  details,
  (details) => Object.keys(get(details, '0.events', {})).map(column => ({
    field: column,
    translation: column,
    render: null
  }))
)

export const data = createSelector(
  details,
  (details) => details.map(event => event.getIn(['events'], {}))
)

export default createStructuredSelector({
  data,
  columns
})
