import { useEffect, useMemo, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SoftwareUpdateApi } from 'Services'
import { toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents';

import Types, { initialState, reducer } from './ScheduledDevicesWrapper.reducer'

export function useJobDevices(scheduleId, language = 'en-US', isUnreachedTab, t) {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [searchTerm, setSearchTerm] = useState(undefined)
    const debounceSearchTerm = useDebounce(searchTerm, 500)
    const PAGE_SIZE = 100
    const EXPORT_REPORT_NAME = "UNREACHED_SW_FW_UPDATE"

    const filter = useMemo(() => JSON.stringify(
            { $freeform: !!debounceSearchTerm ? [debounceSearchTerm] : undefined }
        ), [debounceSearchTerm])

    async function getJobDevices() {
        setLoading(true)
        dispatch({ type: Types.FIRST_PAGE, devices: [], totalCount: 0 })
        const { data, totalCount } = await requestJobDevices(
            scheduleId,
            {
                pageNumber: 1,
                pageSize: PAGE_SIZE,
                filter,
                selectExcluded: isUnreachedTab
            })
        dispatch({ type: Types.FIRST_PAGE, devices: data, totalCount })
        setLoading(false)
    }

    async function requestJobDevices(scheduleId, params) {
        try {
          const resp = await SoftwareUpdateApi.getDevicesFromJob(scheduleId, params)
          if (resp.ok && resp.status === 200) {
            return {
              data: resp.data?.schedulerSoftwareDetailList || [],
              totalCount: resp.data?.schedulerSoftwareDevicesTotalCount
            }
          } else {
            return { data: [], totalCount: 0 }
          }
        } catch {
          return { data: [], totalCount: 0 }
        }
      }
    
    async function setPageNumber(pageNumber) {
        setLoading(true)
        const { data } = await requestJobDevices(scheduleId, { pageNumber, pageSize: PAGE_SIZE, filter, selectExcluded: isUnreachedTab })
        dispatch({ type: Types.NEXT_PAGE, devices: data })
        setLoading(false)
    }

    async function requestDownloadCSV() {
        setLoading(true)
        try {
          const resp = await SoftwareUpdateApi.sendDownloadRequest(scheduleId,
            {
              filter,
              language,
              reportName: isUnreachedTab ? EXPORT_REPORT_NAME : null
            })
          if (resp.ok && resp.status === 200) {
            const path = '/reports/downloads';
            return toast(<SuccessToast message={t('ToastSuccess')}
              detailsClick={() => history.push(path)}
              detailsText={t('ToastTextDetails')} />);
          } else {
            return toast(<ErrorToast message={t('ToastError')} />)
          }
        } catch {
            return toast(<ErrorToast message={t('ToastError')} />);
        } finally {
            setLoading(false)
        }
        
      }

    useEffect(() => {
        if (scheduleId) {
          getJobDevices()
        }
    }, [scheduleId, filter])

    useEffect(() => {
      getJobDevices()
    }, [isUnreachedTab])

    return {
        devicesLoading: loading,
        devices: state.devices,
        pageSize: PAGE_SIZE,
        totalCount: state.totalCount,
        setSearchTerm,
        setPageNumber,
        exportDevices: requestDownloadCSV,
        refreshDevices: getJobDevices
    }
}


function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        return () => clearTimeout(timeoutHandler)
    }, [value])

    return debouncedValue
}