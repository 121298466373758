import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Icon
} from '@scuf/common'
import { useTranslate } from 'react-translate'
import {
  StyledSelect,
  SubmitButtonContainer,
  TagsContainer,
  Tag,
  Label,
  RowIcon,
  StyledIcon
} from './filter-tags.styles'
import { useKeyValue } from 'Components/Tags/tags.hooks'

const FilterTags = ({
  setSelectedTags,
  paramTags,
  ...rest
}) => {
  const {
    key,
    keys,
    setKey,
    values
  } = useKeyValue()
  const t = useTranslate('ConnectionStatus')
  const [value, setValue] = useState()
  return (
    <Fragment>
      <StyledSelect
        fluid
        label={t('tagName')}
        onChange={value =>
          setKey(value)
        }
        options={keys}
        search
        value={key}
      />
      <SubmitButtonContainer>
        <StyledSelect
          disabled={!key}
          fluid
          label={t('tagValue')}
          onChange={value =>
            setValue(value)
          }
          options={values}
          search
          value={value}
        />
        <RowIcon>
          <StyledIcon
            enabled={value}
            name='add'
            onClick={() =>
              value && setSelectedTags(currentTags => [
                ...paramTags,
                { [key]: value }
              ])
            }
            root='building'
            size='medium'
            type='link'
          />
        </RowIcon>
      </SubmitButtonContainer>
      <TagsContainer>
        {paramTags.map((tag, i) => {
          const [key] = Object.keys(tag)
          return (
            <Tag key={i} onClick={() =>
              setSelectedTags(() => paramTags.filter(
                t => tag !== t
              ))
            }>
              <Label>{`${key}: ${tag[key]}`}</Label>
              <Icon name='close' size='small' />
            </Tag>
          )
        }
        )}
      </TagsContainer>
    </Fragment>
  )
}

FilterTags.defaultProps = {
  paramTags: [],
  t: label => label
}

FilterTags.propTypes = {
  t: PropTypes.func
}

export default FilterTags
