const create = (api) => {
  const listOrganization = (id) => {
    return api.get(`/organizations/${id}`)
  }

  const listOrganizations = (pageNumber = 1, pageSize = 100, freeform, dataFilter) => {
    let filterJson = ''
    const filterObj = {}
    if (freeform != null && freeform.length > 0) {
      filterObj['$freeform'] = [freeform]
    }
    if (dataFilter != null && Object.keys(dataFilter).length !== 0) {
      Object.keys(dataFilter).forEach(key => {
        filterObj[key] = dataFilter[key]
      })
    }
    if (Object.keys(filterObj).length !== 0 && filterObj.constructor === Object) {
      filterJson = JSON.stringify(filterObj)
    }
    return api.get(`/organizations?filter=${filterJson}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
  }

  const listAllOrganizations = (pageNumber, pageSize) => {
    return api.get(`/organizations?filter=&pageSize=${pageSize}&pageNumber=${pageNumber}`)
  }

  const createOrganization = (organization) => api.post(`/organizations`, organization)

  const editOrganization = (organization, id, dirtyFields) => api.post(`/organizations/${id}`, { ...organization, mask: dirtyFields })

  const getStatistics = (startDate, endDate) => api.get(`/organizations/analytics?startDate=${startDate}&endDate=${endDate}`)

  const updateLogo = (logo, orgId) => {
    const formData = new FormData()
    formData.append('image', logo)

    return api.post(`/organizations/${orgId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const createLogo = (logo, orgId) => {
    const formData = new FormData()
    formData.append('image', logo)
    formData.append('id', orgId)

    return api.post(`/organizations/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const deleteLogo = async (orgId) => {
    const response = await api.delete(`/organizations/${orgId}/logo`)
    return response
  }

  const getLogo = (id, cancelToken = null) => {
    return api.get(`/organizations/${id}/logo`, null, {cancelToken})
  }
  return {
    listOrganization,
    listOrganizations,
    createOrganization,
    editOrganization,
    getStatistics,
    listAllOrganizations,
    createLogo,
    updateLogo,
    getLogo,
    deleteLogo
  }
}

export default {
  create
}
