import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { reduxForm } from 'redux-form'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { hasAssetRobot, hasPackageCarSubscribedFeature } from 'Selectors/PermissionsSelector'

import ModalActions from '../../Redux/ModalRedux'
import UpdatesActions from '../../Redux/UpdatesRedux'
import UploadAssetModal from './upload-asset-modal'
import { appModule, assetTypes, deviceType, fileType, isInEditMode, loadedPercent, loading, reducer, selectedUpdate } from './upload-asset-modal.selector'

const mapStateToProps = state => {
  return {
    deviceType: deviceType(state),
    appModule: appModule(state),
    assetTypes: assetTypes(state),
    formData: reducer(state),
    loadingFile: loading(state),
    loadingHash: state.updates.getIn(['loadingHash'], false),
    loadedPercent: loadedPercent(state),
    fileType: fileType(state),
    canAssetRobot: hasAssetRobot(state),
    isEditMode: isInEditMode(state),
    selectedUpdates: selectedUpdate(state),
    hasPackageCarSubscribedFeature:hasPackageCarSubscribedFeature(state)
  }
}

const mapDispatchToProps = (dispatch, { isHoneywellUpdate, flags }) => {
 const isHash256 = flags.hash256;
  return {
    onSubmit: form => {
      const config = {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          dispatch(UpdatesActions.updatesUploadSetPercent(percentCompleted))
        }
      }
      dispatch(UpdatesActions.updatesUploadAssetRequest(isHash256, form, config, isHoneywellUpdate))
    },
    editSubmit: (data, id) => {
      dispatch(UpdatesActions.updatesEditPackageRequest(data, id))
    },
    modalClose: () => dispatch(ModalActions.modalClose()),
    onFileChange: file => {
      dispatch(UpdatesActions.updatesCalculateHashRequest(file, isHash256 ))
    }
  }
}

export default pipe(
  reduxForm({
    form: 'uploadAssetForm'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLDConsumer(),
  translate('UploadAsset')
)(UploadAssetModal)
