import { useEffect, useMemo, useReducer, useState } from 'react'

import { useDebounce } from 'Components/AssetManagementBase/components/assets-table/assets-table.hooks'
import { SoftwareUpdateApi } from 'Services'
import { useTranslate } from 'react-translate'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import Types, { initialState, reducer } from './scheduled-devices.reducer'

export function useScheduledDevices(scheduleId) {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [searchTerm, setSearchTerm] = useState(undefined)
  const debounceSearchTerm = useDebounce(searchTerm, 500)
  const PAGE_SIZE = 100
  const t = useTranslate('ScheduleUpdateModal')

  const filter = useMemo(() => JSON.stringify({
    $freeform: !!debounceSearchTerm ? [debounceSearchTerm] : undefined
  })
    , [debounceSearchTerm])

  useEffect(() => {
    if (scheduleId) {
      getDevices()
    }
  }, [scheduleId, filter])

  async function getDevices() {
    setLoading(true)
    dispatch({ type: Types.FIRST_PAGE, devices: [], totalCount: 0 })
    const { data, totalCount } = await requestJobDevices(
      scheduleId,
      {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        filter
      }
    )
    dispatch({ type: Types.FIRST_PAGE, devices: data, totalCount })
    setLoading(false)
  }

  async function requestJobDevices(scheduleId, params) {
    try {
      const resp = await SoftwareUpdateApi.getDevicesFromJob(scheduleId, params)
      if (resp.ok && resp.status === 200) {
        return {
          data: resp.data?.schedulerSoftwareDetailList || [],
          totalCount: resp.data?.schedulerSoftwareDevicesTotalCount
        }
      } else {
        return { data: [], totalCount: 0 }
      }
    } catch {
      return { data: [], totalCount: 0 }
    }
  }

  async function requestDownloadCSV(language = 'en-US') {
    try {
      const resp = await SoftwareUpdateApi.sendDownloadRequest(scheduleId,
        {
          filter,
          language
        })
      if (resp.ok && resp.status === 200) {
        const path = '/reports/downloads';
        return toast(<SuccessToast message={t('ToastSuccess')}
          detailsClick={() => history.push(path)}
          detailsText={t('ToastTextDetails')} />);
      } else {
        return toast(<ErrorToast message={t('ToastError')} />)
      }
    } catch {
      return toast(<ErrorToast message={t('ToastError')} />);
    }
  }

  async function setPageNumber(pageNumber) {
    setLoading(true)
    const { data } = await requestJobDevices(scheduleId, { pageNumber, pageSize: PAGE_SIZE, filter })
    dispatch({ type: Types.NEXT_PAGE, devices: data })
    setLoading(false)
  }

  return {
    loading,
    devices: state.devices,
    setPageNumber,
    pageSize: PAGE_SIZE,
    totalCount: state.totalCount,
    setSearchTerm,
    requestDownloadCSV,
    refreshListOfDevices: getDevices
  }
}
