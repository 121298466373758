export class OrgInternalReportingDashboardService {
  constructor (apiClient) {
    this.api = apiClient
  }

  // GET
  getOrgAllEvents = (orgId, params) => this.api.get(`/v2/devices/${orgId}/eventsCount`, params)
  getTotalTelemetry = (orgId, params) => this.api.get(`/v2/devices/${orgId}/totaltelemetry`, params)
  getOpintelLicenses = (params) => this.api.get(`/LicenseManagement/subscriptions`, params)
}
