import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  dashboardRequestLTLTotalTripsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseLTLTotalTripsCard: ['data'],
  dashboardRequestLTLMissedStopsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseLTLMissedStopsCard: ['data'],
  dashboardRequestLTLDwellTimeCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseLTLDwellTimeCard: ['data']

})

export const LtlTripTypes = Types
export const LtlTripActions = Creators
