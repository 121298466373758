import * as React from 'react'
import { useTranslate } from 'react-translate'
import Immutable from 'seamless-immutable'

import { Icon } from '@scuf/common'
import { DataTable } from '@scuf/datatable'
import { AssetImport } from '../../apis'
import { ErrorsCell } from './errors-cell'
import { ErrorMessage } from 'Services/api-response'
import { DeleteButton } from './non-iot-import-table.styles'
import { ValidationStatus } from './validation-status'

const nameof = <T, > (name: keyof T) => name

export interface NonIotImportTableRow extends AssetImport {
  errors?: ErrorMessage[]
}

export interface NonIotImportTableProps {
  data: Immutable.ImmutableArray<NonIotImportTableRow>;
  validationStatus: ValidationStatus;
  submitting: boolean;
  onChange: (data: Immutable.ImmutableArray<AssetImport>) => any;
}

export const NonIotImportTable: React.FC<NonIotImportTableProps> = ({
  data,
  validationStatus,
  submitting,
  onChange
}) => {
  const translateForm = useTranslate('OtherEquipmentForm')
  const translateAssetModel = useTranslate('AssetModels')
  const translateAsset = useTranslate('Asset')

  const onEdit = React.useCallback((newValue, cellData) => {
    // @ts-ignore
    const newData = Immutable(data).updateIn([cellData.rowIndex, cellData.field as keyof NonIotImportTableRow], newValue)

    onChange(newData)
  }, [data, onChange])

  const onRemoveRow = React.useCallback((cellData) => {
    const newData = Immutable([
      ...data.asMutable().slice(0, cellData.rowIndex),
      ...data.asMutable().slice(cellData.rowIndex + 1, data.length)
    ])
    onChange(newData)
  }, [data, onChange])

  return (
    <DataTable
      className='non-iot-import-table'
      data={data as unknown as object[]}
      totalRows={data.length}
      scrollable={true}
      scrollHeight='45vh'
      onEdit={onEdit}
    >
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('manufacturerName')}
        header={translateAssetModel('Manufacturer')}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('assetTypeName')}
        header={translateAssetModel('Type')}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('assetModelName')}
        header={translateAssetModel('ModelName')}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('serialNumber')}
        header={translateAsset('SerialNumber')}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('alias')}
        header={translateAsset('AliasOptional')}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('errors')}
        header={translateForm('Status')}
        renderer={(cellData) => (<ErrorsCell cellData={cellData} validationStatus={validationStatus} />)}
      />
      <DataTable.Column
        field={nameof<NonIotImportTableRow>('errors')}
        header=''
        initialWidth='4rem'
        renderer={(cellData) => (
          <DeleteButton
            title={translateForm('DeleteRow')}
            type='link'
            onClick={() => onRemoveRow(cellData)}
            disabled={submitting}
          >
            <Icon name='delete' size='small' color='red' />
          </DeleteButton>
        )}
      />
    </DataTable>
  )
}
