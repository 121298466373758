import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components/macro'

const LoadingBarAnim = keyframes`
  from {left: -100px; width: 10%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% {left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
`

const LoaderContainer = styled.div`
  height: 5px;
  width:${props => props.customWidth ? `${props.customWidth}`: '100%'};
  position: absolute;
  overflow: hidden;
  z-index: 1;
  margin-top: ${props => props.isDetailsTable ? '-2rem' : '0rem'};
`

const LoaderBar = styled.div`
  display: block;
  position: absolute;
  left: -200px;
  width: 200px;
  height: 5px;
  background-color: #1792E5;
  animation: ${LoadingBarAnim} 2s linear infinite;
`

const Loader = ({ loading, isDetailsTable, customWidth }) => (
  // Loader must always keep its space otherwise it will move everything below a few pixels after finishing load.
  // @Praneesh
  <LoaderContainer isDetailsTable={isDetailsTable} customWidth={customWidth}>
    {loading
      ? <LoaderBar />
      : null
    }
  </LoaderContainer>
)

Loader.propTypes = {
  loading: PropTypes.bool.isRequired
}

Loader.defaultProps = {
  loading: false
}

export default Loader
