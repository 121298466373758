import moment from 'moment';
import { substractStartDay, substractStartDayCurrently, substract } from 'Utils/MomentHelpers'

export default function TimeConversion(time) {
  if (!isNaN(time)) {
    if (time < 60) {
      return `${time} seconds`
    } else if (time > 60 && time < 3600) {
      return `${Math.round(time / 60)} minutes`
    } else if (time > 3600 && time < 86400) {
      return `${Math.round(time / 60 / 60)} hours`
    } else {
      return `${Math.round(time / 60 / 60 / 24)} days`
    }
  } else {
    return 'Not Available'
  }
}

export const time2Hours = (n) => {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return num > 0 ? (rminutes > 0 ? rhours + "h " + rminutes + 'm' : 0) : ('00h 00m')
}

export const milsec2secs = (n) => {
  const time = isNaN(parseInt(n))
    ? '0' : `${(parseInt(n) / (1000 * 60)).toFixed(2)}` === '0.00'
      ? 0 : `${(parseInt(n) / (1000 * 60)).toFixed(2)}`
  return time
}

export const msToTime = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)))

  hours = hours < 10 ? "0" + hours : hours
  minutes = minutes < 10 ? "0" + minutes : minutes
  seconds = seconds < 10 ? "0" + seconds : seconds

  return hours + "h " + minutes + "m " + seconds + "s";
}


export const startDateConverter = (quantityTime, timeType, isCurrently = false) => {
  const today = moment().format().toString()
  return isCurrently ? substractStartDayCurrently(today, quantityTime, `${timeType}`, 'l')
  : substractStartDay(today, quantityTime, `${timeType}`, 'l')
}

export const endDateConverter = (isCurrently = false) => {
  return isCurrently ? moment().format().toString()
  : moment().format('YYYY-MM-DD+23:59:59').toString()
}

export const startDateTimeConverter = (quantityTime, timeType) => {
  let today = {}
  if(timeType === 'hour') {
    today = moment.utc().startOf('hour').format('YYYY-MM-DD HH:mm:ss').toString()
  } else {
    today = moment.utc().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
  }
  return moment(today).subtract(quantityTime, `${timeType}`).format('YYYY-MM-DD HH:mm:ss')
}

export const endDateTimeConverter = () => {
  return moment.utc().format('YYYY-MM-DD HH:mm:ss')
}
