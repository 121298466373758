import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from 'Components/AssetManagementTable'
import { ModalWrap, ModalTitle } from './details-modal.styles'

const DetailsModal = ({ data, columns, t }) => (
  <ModalWrap>
    <ModalTitle>{t('DetailsModalTitle')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns}
        disableRowClick
        fileName={t('DetailsModalTitle')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default DetailsModal
