import styled from 'styled-components/macro'
import React from 'react'
import { Button } from '@scuf/common'
import { ActionWrapper } from '../../preferences-email.styles'

const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`

const ButtonsSection = styled(Flex)`
  max-height: 3rem;
  justify-content: space-between;
  padding: 0rem 1rem;
`

const ModalButtons = styled.div`
  padding-top: 20px;
  padding-bottom: 50px;
`
const ModalAction = ({ editing, onCancel, onSubmit, t }) =>
  <ButtonsSection>
    {editing ?
      <ActionWrapper
        className='Icon'
        onClick={() => alert('delete')}
        color='#6DA9D3'>
        Delete
      </ActionWrapper>
      :
      <ActionWrapper></ActionWrapper>
    }
    <ModalButtons>
      <Button type="secondary" size="medium" content={t('MasterModal_Cancel')} onClick={onCancel} />
      <Button type="primary" size="medium" content={t('MasterModal_Submit')}
        onClick={onSubmit} />
    </ModalButtons>
  </ButtonsSection>

export default ModalAction
