import { connect } from 'react-redux'
import ModalActions from '../../../../Redux/ModalRedux'
import BatteryListModal from './battery-list-modal'
import BatteryListModalSelector from './battery-list-modal.selector'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default connect(
  BatteryListModalSelector,
  mapDispatchToProps
)(BatteryListModal)
