import { useEffect, useRef } from 'react'
import { defaultFlags } from 'Utils/launch-darkly-flags'

const ExpirationModalHandler = ({
  children,
  history = {},
  sessionExpirationTimeOut = 0,
  openExpirationModal = () => { },
  renewSession = () => { },
  isRenewSessionEnabled = defaultFlags.renewSession,
  allowRenew = false
}) => {
  const isAutoRenew = useRef(allowRenew)
  const flagRenewSession = useRef(isRenewSessionEnabled)

  useEffect(() => {
    isAutoRenew.current = allowRenew
  }, [allowRenew])

  useEffect(() => {
    flagRenewSession.current = isRenewSessionEnabled
  }, [isRenewSessionEnabled])
  
  const shouldModalOpen = () => {
    if (isAutoRenew.current && flagRenewSession.current) {
      renewSession()
    } else {
      openExpirationModal()
    }
  }
  useEffect(() => {
    const timeout = setTimeout(shouldModalOpen, sessionExpirationTimeOut)    
    return () => clearTimeout(timeout)
  }, [sessionExpirationTimeOut])

  return <div>{children}</div>
}
export default ExpirationModalHandler