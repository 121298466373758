import React from 'react'
import styled from 'styled-components'
import { Modal, Icon, Badge } from '@scuf/common'
import { Tag, EditIcon, CheckIcon, CloseIcon  } from '@qasp/ui'
import { hwColorBlue22 } from 'Themes/honeywell-colors'

export const StyledIcon = styled(Icon)`
  display: flex;
  align-content: center;
  padding: 5px;
  width: 30px;
  height: 30px;

`
export const Title = styled.div`
  margin: 0rem 0.5rem 0.3rem 1.35rem;
  align-self: center;
  font-size: 26px;
`

export const StyledEditDeviceNameContainer = styled.div`
display: flex;
align-items: center;
`

export const StyledEditTitle = styled.div`
margin-left: 1.35rem;
font-size: 16px;
font-style: normal;
font-weight: 600;
`

export const AliasInput = styled.input`
  margin: 0rem 0.5rem 0.3rem 0.3rem;
  padding: 9px 8px 7px 8px;
  border: none;
  align-self: center;
  background: #FFFFFF;
  height: 34px;
  border-color: ${props => props.edited ? '#D0D0D0' : 'none'};
  border-width: 1px;
  border-style: solid;
  max-width: inherit;
  &:placeholder-shown {
    float: left;
  }
  &:focus {
    outline: none;
  }
`

export const StyledValidationRename = styled.div`
display: flex;
`

export const StyledCloseIcon = styled((props) => <CloseIcon {...props}/>)`
  padding: 0px 10px;
  background: #FFFFFF;
  color: #1274B7;
  height: 36px !important;
  width: 40px;
  margin-top: 4px;
  border: 1px solid #1274B7;
`

export const StyledIconContainer = styled.div`
  cursor: pointer;
  padding-right: 7px;
  padding-left: 7px;
`

export const StyledEditIcon = styled((props) => <EditIcon {...props}/>)`
  color: #005EAC;
  height: 1.7rem !important;
`

export const StyledValidateIcon = styled((props) => <CheckIcon {...props}/>)`
  padding: 0px 10px;
  color: #FFFFFF;
  background: #1274B7;
  height: 36px !important;
  width: 40px;
  margin-top: 4px;
`

export const badgeRenderer = (status) => {
  const color = status === null ? 'grey' : status ? 'green' : 'red'
  return <Badge color={color} empty />
}

export const Container = styled.div`
height: calc(90vh - 3.25vh);
overflow-y: auto;
overflow-x: hidden;
`

export const HigherModal = styled(Modal.Content)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const AssetTabsContainer = styled.div`
margin: '1rem'
& .div.ui.pointing.secondary.ui.scuf-tab.menu{
  display: flex;
  flex-wrap: wrap;
}
`
export const AssetsTabsContainer = styled.div`
margin: 1rem;
min-height: 60vh;
& .ui.secondary.menu {
  @media (max-width: 320px) {
    display: flex;
    flex-wrap: wrap;
  }
}
`
export const TitleContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
font-size: 20px;
`

export const OptionContainer = styled.div`
display: flex;
border-bottom: solid 1px gainsboro;
`
export const SimpleDiv =styled.div`

`

export const StyledTag = styled((props) => <Tag {...props}/>)`
`

export const NewBadgeWrapper = styled.div`
  margin-left: 0.5rem;
`

export const StyledVerticalLine = styled.div`
padding: 0 7px 0 7px;
border-left: 2px solid #E0E0E0;
height: 35px;
`

export const StyledDeviceInformation = styled.div`
margin: 0.3rem 1.35rem 0.3rem 1.35rem;
display: flex;
flex-direction: row;
align-items: center;
align-content: flex-end;
justify-content: space-between;
`

export const StyledActionOptions = styled.div`
width:35%;
display: flex;
flex-direction: row;
justify-content: flex-end;
@media (width >= 1280px) and (width <= 1451px) { width: 53% }
@media (width >= 1451px) and (width <= 1786px) { width: 45% }

`

export const StyledBreadcrumbContainer = styled.span`
width:60% !important;
@media (width >= 1280px) and (width <= 1451px) { width: 42%  !important}
@media (width >= 1451px) and (width <= 1786px) { width: 50%   !important}

`
export const StyledAction = styled.div`
color: #1274B7 !important;
width: 30.6%;
display: flex;
justify-content: center;
align-items: center;
`
