import { Icon } from '@scuf/common'
import React, { Component, memo, createRef } from 'react'
import PropTypes from 'prop-types'
import { getAlertColor } from './notification-bell.helper'
import { ThemeProvider } from 'styled-components'
import moment from 'moment'

import {
  NotificationsWrapper,
  NotificationLine,
  BellBadge,
  BellWrapper,
  NotificationColumn,
  NotificationRow,
  NotificationHeader,
  NotificationText,
  NotificationHeaderText,
  NotificationHeaderTitle,
  ZeroNotifications,
  HeaderNotificationsWrapper,
  Alerts,
  StyledBadge
} from './notification-bell.styles'
import Polling from './components/polling'
import NotificationBellFooter from './components/footer'

const isMobile = window.screen.width < 420

const AlertTime = ({ time }) => <p>{moment.utc(time).local().fromNow()}</p>

const NotificationAlert = memo(({ alert, t }) => {
  return (
    <NotificationLine>
      <Icon color={getAlertColor(alert.icon)} name={`badge-${alert.icon}`} size='large' />
      <NotificationColumn>
        <NotificationHeader>
          <NotificationHeaderTitle>{alert.title}</NotificationHeaderTitle>
          <NotificationHeaderText><AlertTime time={alert.time} /></NotificationHeaderText>
        </NotificationHeader>
        <NotificationRow>
          <NotificationText>{alert.message}</NotificationText>
        </NotificationRow>
      </NotificationColumn>
    </NotificationLine>
  )
})

NotificationAlert.displayName = 'NotificationAlert'
NotificationAlert.propTypes = {
  alert: PropTypes.object,
  t: PropTypes.func
}

const BadgedIcon = memo(({ color, onClick }) => (
  <Icon color={color} name='notification' onClick={onClick} root='common' size='medium' />
))

BadgedIcon.displayName = 'BadgeIcon'
BadgedIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func
}

class BellNotification extends Component {
  constructor () {
    super()
    this.state = {
      show: false
    }
    this.showNotifications = this.showNotifications.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.captureFilterClick = this.captureFilterClick.bind(this)
    this.filterContainer = createRef()
  }

  showNotifications () {
    document.addEventListener('click', this.captureFilterClick)
    this.setState(prevState => ({ show: !prevState.show }))
  }

  captureFilterClick (e) {
    if (this.filterContainer && !this.filterContainer.contains(e.target)) {
      document.removeEventListener('click', this.captureFilterClick)
    }
  }

  setWrapperRef (node) {
    this.filterContainer = node
  }

  render () {
    const { unreadAlerts, t, theme, history, totalAlerts } = this.props
    const { show } = this.state
    return (
      <HeaderNotificationsWrapper className='notifications-wrapper'>
        <Polling>
          <BellWrapper color='black' onClick={() => isMobile ? history.push('/alerts/') : this.showNotifications}>
            { isMobile
              ? <Alerts onClick={() => history.push('/alerts/')}>
                 Alerts
                { totalAlerts > 0
                  ? <StyledBadge color='red' onClick={() => history.push('/alerts/')} >+{totalAlerts < 100 ? totalAlerts : 100}</StyledBadge>
                  : null
                }
              </Alerts>
              : <div >
                <BadgedIcon onClick={this.showNotifications} />
                { totalAlerts > 0
                  ? <BellBadge onClick={this.showNotifications}>
                    <StyledBadge color='red'>+{totalAlerts < 100 ? totalAlerts : 100}</StyledBadge>
                  </BellBadge>
                  : null
                }
              </div>
            }
          </BellWrapper>
          {
            show
              ? <ThemeProvider theme={theme}>
                <NotificationsWrapper
                  ref={this.setWrapperRef}
                >
                  {unreadAlerts
                    .slice(0, 5)
                    .map(alert => (
                      <NotificationAlert alert={alert} key={alert.id} t={t} />
                    ))}
                  {
                    totalAlerts === 0
                      ? <ZeroNotifications>{t('BellNotification_ZeroNotifications')}</ZeroNotifications> : null}
                  <NotificationBellFooter />
                </NotificationsWrapper>
              </ThemeProvider>
              : null
          }
        </Polling>
      </HeaderNotificationsWrapper>
    )
  }
}

BellNotification.defaultProps = {
  alerts: []
}

BellNotification.propTypes = {
  alerts: PropTypes.array,
  t: PropTypes.func,
  theme: PropTypes.object
}

export default BellNotification
