import { createSelector, createStructuredSelector } from 'reselect'

export const totalDevices = state => state.dashboard.totalDevicesStatus

export const currentCulture = state => state.user.language

export const dashboard = state => state.dashboard

export const hoursOffline = createSelector(
  [dashboard],
  hoursOffline => hoursOffline.getIn(['hoursOffline'], 72)
)

export const groupedDevices = createSelector(
  [totalDevices],
  devices => groupByTypeAndSumValue(devices.getIn(['data'], []))
)

export const totalOfflineDevices = createSelector(
  [groupedDevices],
  devices => Object.keys(devices).reduce((sum, key) => sum + devices[key], 0)
)

export const statusCode = createSelector(
  [totalDevices],
  devicesStatus => devicesStatus.getIn(['statusCode'], undefined)
)

export const loading = createSelector(
  [totalDevices],
  card => card.getIn(['loading'], true)
)

export const siteId = createSelector(
  [dashboard],
  dashboard => dashboard.getIn(['siteId'], '')
)

export const groupByTypeAndSumValue = data => {
  const series = {}
  data.filter(deviceStatus => deviceStatus.property === '0') // 0 means offline
    .forEach(({ type, value }) => {
      if (type) {
        series[type] = series[type] || 0
        series[type] += value
      }
    })
  return series
}

/**
 * Return the percentage of the most representative value in the dictionary
 * @param {dictionaty<string, numeric>} data dictionary with pair property and value
 */
export const representativePercentage = data => {
  const top = { percentage: 0, value: '' }
  Object.entries(data).forEach(([key, value]) => {
    if (value > top.percentage) {
      top.percentage = value
      top.value = key
    }
  })

  const total = Object.keys(data).reduce((sum, key) => {
    return sum + data[key]
  }, 0)
  const percentage = (top.percentage * 100) / (total || 1)
  top.percentage = Math.round(percentage * 10) / 10
  return top
}

/**
 * Get the top most representative percetage offline device type
 */
export const top = createSelector(
  [totalDevices],
  devicesStatus => {
    const groupedDevices = groupByTypeAndSumValue(devicesStatus.getIn(['data'], []))
    return representativePercentage(groupedDevices)
  }
)

const isSubscribedFeature = ({ user }) => user.subscription?.features?.includes('devices.offline_by_type')

export default createStructuredSelector({
  statusCode,
  loading,
  top,
  currentCulture,
  hoursOffline,
  totalOfflineDevices,
  siteId,
  isSubscribed: isSubscribedFeature
})
