import { TextArea } from '@scuf/common'
import React from 'react'
import { useTranslate } from 'react-translate'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'

import { errorTranslation } from 'Components/FormComponents/utils'

import { Title } from '../../Themes/ScufStyledComponents'

const TitleContainer = styled.div`
margin-bottom:0;
font-weight: 700;
float:left
`
const RadioButtonContainer = styled.div`
margin-top: -7%;
`

const TitleExample = styled.p`
margin-bottom:0;
margin-left:10px;
font-weight: 500 ;
font-style: oblique;
color:#686868;
float:right
`
const ControlRow = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`
const StyledTitle = styled(Title)`
font-size:.875rem;
font-weight:700;
`

const ControlContainer = styled.div`
width: 47%;
margin-bottom: ${props => props.marginBotton || '.5rem'};
`

const UpdateName = styled(Field)`
  .below-text{
    display: block !important;
  }
`
const StyledTextArea = styled(TextArea)`
width:100%;
  .below-text{
    display: block !important;
  }
`
const FieldStyle = styled(Field)`
  .ui.dropdown.disabled.fluid{
    min-height: 2.71428571em;
  }
`
export function FormTextArea (
  { t,
    input: { onChange },
    meta: { error },
    ...props
  }) {
  t = useTranslate('HoneywellUpdates')
  return <StyledTextArea onChange={onChange} error={error && errorTranslation(t, error)} {...props} />
}

const ReleaseNoteContainer = styled.div`
width: 95%;
margin-bottom: ${props => props.marginBotton || '.5rem'};
`

const StyledInput = styled.input`
margin-right: .25rem;
`

export {
  ControlContainer,
  UpdateName,
  ControlRow,
  TitleContainer,
  TitleExample,
  StyledTextArea,
  ReleaseNoteContainer,
  StyledTitle,
  RadioButtonContainer,
  StyledInput,
  FieldStyle
}
