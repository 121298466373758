const create = (api) => {

  const updateOrganization = data => api.post('/userManagement/orgunits/marketplace', data)

  const getOrganization = id => api.get(`/userManagement/orgunits/marketplace/${id}`)

  return {
    updateOrganization,
    getOrganization
  }

}

export default {
  create
}
