import { createStructuredSelector } from 'reselect'

import { fullDevicesBySite } from '../../../../Selectors/FirmwareSelector'
import { selectedDevices } from '../../../../Selectors/DeviceSelector'

const deviceFormTableSelector = createStructuredSelector({
  selectedDevices,
  devices: fullDevicesBySite
})

export default deviceFormTableSelector
