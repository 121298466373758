const create = (api) => {
  const getURLDownloadFile = (fileId) => {
    return api.get(`/v2/files/${fileId}/url`)
  }

  return {
    getURLDownloadFile
  }

}

export default {
  create
}
