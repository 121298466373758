import apiClient from 'Services/ApiSauceInstance'
import { ApiResponse } from 'Services/api-response'

export interface CreateAssetModel {
  organizationId: string;
  assetTypeId: string;
  manufacturerId: string;
  name: string;
  description?: string;
}

export interface AssetModel extends CreateAssetModel {
  id: string;
}

export const getAssetModels = (organizationId: string) => apiClient
  .get<ApiResponse<AssetModel[]>>('asset-models')

export const createAssetModel = (assetModel: CreateAssetModel) => apiClient
  .post<ApiResponse<AssetModel>>('asset-models', assetModel)
