import React from 'react'
import styled from 'styled-components/macro'
import { Card, Grid, Icon, Tooltip, Button } from '@scuf/common'
import GridLayout from 'react-grid-layout'
import CLoader from 'react-content-loader'
import { isMobile } from 'Utils/constants'

const CardWrap = styled.div`
width: ${props => props.cardwidth};
margin: ${props => props.cardMargin};
min-height: ${props => props?.isCard ? '100px': '450px'};
min-width: 350px;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
  height: ${props => props.theme.height};
  background-color: ${props => props.theme.dashboardcard || '#fff'};

  border: ${props => props.theme.border};
  &:hover {
    box-shadow: ${props => props.theme.shadow};
  }
  & form {
    margin-top: 4px;
  }
  & .input-wrap {
    margin: 0;
  }
  & .below-text {
    margin: 0;
  }
  & .input-box {
    min-width:16rem;
    height: 2rem !important;
  }
  & .error-message {
    font-size: 0.6rem !important;
  }
`

const StyledGrid = styled(GridLayout)`
  width: 99%;
`
const StyledGridItem = styled.div`
  height: 33%;
`

const CardHeader = styled.div`
  height: 44px;
  padding-left:${props => props.theme.padding};
  background-color:${props => props.theme.background};
  color:${props => props.theme.color};
  font-weight:${props => props.theme.weight};
  border-bottom: rgba(0,0,0,0.05) 1px solid !important;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CardIcon = styled.div`
  width: 40px;
  height: 44px;
  float: left;
  margin:${props => props.theme.iconMargin};
  display: ${props => props.theme.display};
  justify-content: center;
  align-items: center;
  & .h-icon:not(.common) {
    color: white !important;
  }
`
const CardTitle = styled.div`
  width: ${props => props.theme.titleWidth};
  height: 44px;
  text-align: left;
  vertical-align: middle;
  padding-top:${props => props.theme.paddingTop};
  padding-bottom: ${props => props.theme.paddingTop};
  padding-left:${props => props.theme.paddingLeft};
  padding-right: .5rem;
  line-height: 1rem;
  float: left;
  font-size: .875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 480px) {
    width: auto;
    font-size: .875rem;
  }
`

const CardDate = styled.div`
  font-weight:400;
  width:auto;
  height:40px;
  float:left;
  font-size:14px;
  text-align:right;
  padding-right:1rem;
  vertical-align: middle;
  padding-top:.6rem;

  display:${props => props.theme.display};
  @media only screen and (max-width:480px){
    width:auto;
    font-size:9px;
  }
`
const CardBody = styled.div`
  filter: ${props => props.blur ? 'blur(0.0rem)' : null};
  pointer-events: ${props => props.blur ? 'none' : null};
  opacity: ${props => props.blur ? '40%' : null};
`

const CardContent = styled.div`
  width: 100%;
  background: ${props => props.theme.dashboardcard || '#fff'};
  height: ${props => props.theme.contentHeight};
  max-height:340px;
  text-align: left;
  font-size: 0.875rem;
  position: relative;
  padding:${props => props.isChart ? '0 1rem' : '0 1rem'};
  line-height: 46px;
  & .highcharts-title tspan {
    font-weight:500;
  };
  filter: ${props => props.blur ? 'blur(0.25rem)' : null};
`
const CardNumber = styled.div`
  margin-right: .3rem;
  font-size: 1.25rem;
  float: left;
  text-align: left;
`

const NumWrap = styled.div`
  width: 30%;
  height: 60px;
  float: right;
  display: ${props => props.theme.numdisplay};
`
const CardNum = styled.div`
  width: 85%;
  height: 35px;
  font-size: 1.25rem;
  float: left;
  text-align: right;
  line-height: 50px;
  display: ${props => props.theme.numdisplay};
`
const NumIcon = styled.div`
  width: 15%;
  height: 35px;
  float: right;
  text-align: right;
  line-height: 50px;
  display: ${props => props.theme.numdisplay};
`
const NumText = styled.div`
  text-align: center;
  font-size: 1.25rem;
  text-align: right;
  line-height:12px;
  display: ${props => props.theme.numdisplay};
`
const ChartFooter = styled.div`
  display: block;
  background: ${props => props.theme.dashboardcard || '#fff'};
  float:left;
  width:100%;
  text-align: center;
  display: ${props => props.theme.footdis};
  padding-bottom: 1rem;
  min-height: 4rem;
`

const FooterFix = styled.div`
  height: 100%;
  width: 100%;
`

const FooterNum = styled.div`
  font-size: 1.25rem;
  justify-content: flex-start;
  padding-left: 1rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
  text-align:left;
  line-height:.85rem;
  display: flex;
  align-items: center;
`
const FooterCont = styled.div`
width: 100%;
  background: ${props => props.theme.dashboardcard || '#fff'};
  display: flex;
  flex: 3;
  padding: 0 0.75rem;
  line-height: 1.1rem;
  font-size: 0.875rem;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
  text-align:left;
  display: flex;
  align-items: center;
`
const CardMenu = styled.div`
  & span {
  width: 44px;
  padding:0;
  height: 44px;
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items:center;
  transition: all ease-in 200ms;
  }
  & span:hover {
    background: rgba(0,0,0,0.025)
  }
  pointer-events: ${props => props.isSubscribed === false ? 'none' : null};
`
const EditHeader = styled.div`

  height:20px;
  padding: .75rem 1rem 0 1rem;
`

const EditIcon = styled(Icon)`
  float: right;
  width: 37.16px !important;
  margin:.5rem;
  padding-top: 0px;
  vertical-align: middle;
`
const StyledColumn = styled(Grid.Column)`
  & .scuf-col:last-child {
    padding-left:0;
    padding-right:0.5rem;
  }
`
const StyledH4 = styled.h4`
  padding: 1rem 0 0 .5rem;
  margin:0;
  min-width: 40rem;
`
const StyledPropertiesH4 = styled.h4`
  padding:0rem 0 0 .5rem;
  margin:0;
  min-width:40rem;
`

const CardError = styled.div`
  font-size: 1rem;
  font-weight:500;
  color:#7D7D7D;
  & .h-icon {
    margin-top:-8px;
  }
`
const ContainerEdit = styled.div`
  display: inline-block;

  margin-right: 0px;
  & .h-icon {
    padding-top: 0rem;
  }
`

const themeDan = {
  titleWidth: '80%',
  border: '2px solid #be271c',
  background: '#B9261C',
  color: '#fff',
  display: '',
  padding: '0px',
  lineheight: '40px',
  shadow: '0 4px 8px rgba(0,0,0, .1)',
  height: '100px',
  footdis: 'none',
  paddingTop: '.5rem',
  editMargin: '.6rem 0 0 0',
  paddingLeft: '.5rem',
  iconMargin: '0 0 0 0.5rem;',
  weight: '600',
  contentHeight: '56px'
}

const noTheme = {
  titleWidth: '100%',
  display: 'none',
  background: 'transparent',
  padding: '0px',
  lineheight: '60px',
  height: '100px',
  footdis: 'none',
  paddingTop: '1rem',
  editMargin: '.8rem 0 0 0',
  paddingLeft: '1rem',
  iconMargin: '.7rem 0.1rem 0 0;',
  weight: '400',
  contentHeight: '56px'
}

const themeWarn = {
  titleWidth: '100%',
  border: '2px solid #F5A623',
  background: '#F5A623',
  color: '#fff',
  display: 'flex',
  padding: '0px',
  lineheight: '40px',
  shadow: '0 4px 8px rgba(0,0,0, .1)',
  height: '100px',
  footdis: 'none',
  paddingTop: '.5rem',
  editMargin: '.6rem 0 0 0',
  paddingLeft: '.5rem',
  iconMargin: '0 0 0 0.5rem;',
  weight: '600',
  contentHeight: '56px'
}

const themeNoti = {
  titleWidth: '100%',
  border: '2px solid #1792e5',
  background: '#4A90E2',
  color: '#fff',
  display: '',
  padding: '0px',
  lineheight: '40px',
  shadow: '0 4px 8px rgba(0,0,0, .1)',
  height: '100px',
  footdis: 'none',
  paddingTop: '.5rem',
  editMargin: '.6rem 0 0 0',
  paddingLeft: '1rem',
  iconMargin: '.7rem 0.1rem 0 0;',
  weight: '600',
  contentHeight: '56px'
}
const noThemeChart = {
  titleWidth: '100%',
  contwidth: '99%',
  border: '',
  background: '#transparent',
  display: 'none',
  padding: '.2rem',
  lineheight: '60px',
  height: '460px',
  cardwidth: '99%',
  numdisplay: 'none',
  footdis: 'flex',
  paddingTop: '1rem',
  editMargin: '.8rem 0 0 0',
  paddingLeft: '1rem',
  iconMargin: '.7rem 0.1rem 0 0;',
  weight: '400',
  contentHeight: '340px'
}

const themeChartNoti = {
  titleWidth: '100%',
  contwidth: '99%',
  border: '2px solid #4A90E2',
  background: '#4A90E2',
  color: '#fff',
  display: 'none',
  padding: '.2rem',
  lineheight: '40px',
  height: '460px',
  cardwidth: '99%',
  paddingTop: '.5rem',
  numdisplay: 'none',
  footdis: 'flex',
  paddingLeft: '1rem',
  iconMargin: '.7rem 0.1rem 0 0.5rem;',
  weight: '600',
  contentHeight: '340px'
}

const themeChartWarn = {
  titleWidth: '100%',
  contwidth: '99%',
  border: '2px solid #F5A623',
  background: '#F5A623',
  color: '#fff',
  display: '',
  padding: '0',
  lineheight: '40px',
  height: '460px',
  cardwidth: '99%',
  paddingTop: '.5rem',
  numdisplay: 'none',
  footdis: 'flex',
  paddingLeft: '.5rem',
  iconMargin: '.7rem 0.1rem 0 0.5rem;',
  weight: '600',
  contentHeight: '340px'
}

const themeChartDan = {
  titleWidth: '100%',
  contwidth: '99%',
  border: '2px solid #B9261C',
  background: '#B9261C',
  color: '#fff',
  display: '',
  padding: '.2rem',
  lineheight: '40px',
  height: '460px',
  cardwidth: '99%',
  paddingTop: '.5rem',
  numdisplay: 'none',
  footdis: 'flex',
  paddingLeft: '.5rem',
  iconMargin: '.7rem 0.1rem 0 0.5rem;',
  weight: '600',
  contentHeight: '340px'
}

const chartColors = [
  '#095486',
  '#A6C1D3',
  '#F69B64',
  '#0090E8',
  '#7A4290',
  '#8BC8F2',
  '#1274B7',
  '#5C8AAA',
  '#B1ACE2',
  '#BAE3CA',
  '#A0A0A0'
]

const chartBarColors = [
  '#095486',
  '#A6C1D3',
  '#0090E8',
  '#7A4290',
  '#8BC8F2',
  '#1274B7',
  '#5C8AAA',
  '#B1ACE2',
  '#BAE3CA',
  '#A0A0A0',
  '#707070'
]

const chartChargeColors = [
  '#7eb338',
  '#FFC627',
  '#be271c'
]

const MainContainer = styled.div`
  width: ${props => props.width ? props.width + 'px' : '99%'};
  &:last-of-type {
    margin-bottom: 3rem;
  }
`

const HealthDanger = styled.p`
  color: ${props => props.value <= 50 ? '#B9261C' : '#000'};
`

const CycleDanger = styled.p`
  color: ${props => props.value >= 500 ? '#B9261C' : '#000'};
`

const AgeDanger = styled.p`
  color: ${props => props.value >= 30 ? '#B9261C' : '#000'};
`

const HealthWarning = styled.p`
  color: ${props => props.value <= 75 ? '#F5A623' : '#000'};
`

const CycleWarning = styled.p`
  color: ${props => props.value >= 300 ? '#F5A623' : '#000'};
`

const AgeWarning = styled.p`
  color: ${props => props.value >= 24 ? '#F5A623' : '#000'};
`
const OldMainContainer = styled.div`
  width: ${props => props.width + 'px'};
  padding-right: ${() => isMobile() ? '0' : '0.3rem'};
  height:99%;
  & .scuf-col:last-child {
    padding-right:.5rem;
  }
  & .ui.scuf-grid {
    margin-left:.5rem;
  }
  & .scuf-col {
    height:${props => props.theme.height};
  }
`

const StyledScufCard = styled(Card)`
  width: 500px !important;
`

const StyledTooltip = styled(Tooltip)`
&.ui.tooltip:before {
  background: ${props => props.theme.h1color};
}
  background-color: ${props => props.theme.h1color};
`
const ToolTipTitle = styled.h4`
color: ${props => props.theme.sidebaractive};
`
const ToolTipWrap = styled.div`
  min-width:350px;
  padding: .5rem .5rem 1rem .5rem;
`
const ToolTipContent = styled.div`
font-size: .875rem;
color: ${props => props.theme.sidebaractive};
`

const ContentLoader = (theme) => (
  <CLoader
    primaryColor={theme.theme.backgroundheader === '#000' ? '#202020' : '#f3f3f3'}
    secondaryColor={theme.theme.backgroundheader === '#000' ? '#101010' : '#ecebeb'}
    style={{ margin: '1rem 0px 0px 1rem', width: '99%', height: '20px' }}
    height={100}

    preserveAspectRatio='xMinYMin meet'
  >
    <rect x='0' y='0' width='300' height='90' />
  </CLoader>
)

const ChartLoader = (theme) =>
  <CLoader
    primaryColor={theme.theme.backgroundheader === '#000' ? '#202020' : '#f3f3f3'}
    secondaryColor={theme.theme.backgroundheader === '#000' ? '#101010' : '#ecebeb'}
    style={{ margin: '0px 0px 0px 1rem', height: '380', width: '99%' }}
    height={340}
    preserveAspectRatio='xMinYMin meet'
  >
    <rect x='0' y='48' width='400' height='300' />
  </CLoader>

const CardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
`
const ContainerProperties = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`

const ContainerWidthCard = styled.div`
  display: block;
  width: 100%;
`

const DetailsButton = styled.div`
  color: ${props => props.isWhite === true ? '#FFFFFF' : props.isSubscribed === false ? 'grey' : '#45A7EA'};
  height: 44px;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  width: 30% !important;
  align-items: center;
  cursor: ${props => props.isSubscribed === false ? null : 'pointer'};
  transition: ${props => props.isSubscribed === false ? 'none' : 'all ease-in 200ms'};
  &:hover {
    background: ${props => props.isSubscribed === false ? 'none' : 'rgba(0,0,0,0.025)'};
    color: ${props => props.isWhite === true ? '#0090E8' : props.isSubscribed === false ? 'none' : '#0090E8'};
  }
`
const DetailsWhiteButton = styled.div`
  color: #FFFFFF ;
  height: 44px;
  font-size: 0.75rem ;
  padding: 0 1rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  width: 30% !important;
  align-items: center;
  cursor: ${props => props.isSubscribed === false ? null : 'pointer'};
  transition: ${props => props.isSubscribed === false ? 'none' : 'all ease-in 200ms'};
  &:hover {
    background: ${props => props.isSubscribed === false ? 'none' : 'rgba(0,0,0,0.025)'};
    color: #0090E8;
  }
`

export {
  noThemeChart,
  themeChartWarn,
  themeChartNoti,
  themeChartDan,
  CardWrap,
  StyledGrid,
  StyledGridItem,
  CardHeader,
  CardContent,
  CardBody,
  CardNumber,
  CardIcon,
  CardTitle,
  CardDate,
  NumWrap,
  CardNum,
  NumIcon,
  NumText,
  themeDan,
  noTheme,
  themeWarn,
  themeNoti,
  ChartFooter,
  FooterNum,
  FooterCont,
  FooterFix,
  CardMenu,
  EditHeader,
  EditIcon,
  StyledH4,
  CardError,
  chartColors,
  chartBarColors,
  ContainerEdit,
  MainContainer,
  ContentLoader,
  ChartLoader,
  HealthDanger,
  CycleDanger,
  AgeDanger,
  HealthWarning,
  CycleWarning,
  AgeWarning,
  OldMainContainer,
  StyledScufCard,
  StyledColumn,
  StyledPropertiesH4,
  chartChargeColors,
  StyledTooltip,
  ToolTipTitle,
  ToolTipContent,
  ToolTipWrap,
  CardsContainer,
  ContainerProperties,
  ContainerWidthCard,
  DetailsButton,
  DetailsWhiteButton
}
