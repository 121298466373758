import { connect } from 'react-redux'
import { pipe } from 'ramda'

import { withPermissions } from 'Utils/permissions'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  permissionsLoading: state.permissions.loading
})

export default pipe(
  connect(mapStateToProps),
  withPermissions()
)(PrivateRoute)
