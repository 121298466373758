import { createStructuredSelector, createSelector } from 'reselect'
import { subscriptionAgreementReducer } from './subscription-agreement.reducer'

export const getAcceptenceTest = state => state.attested
export const getErrorTest = state => state.error
export const getLoadingTest = state => state.loading

export const getAcceptence = state => state[subscriptionAgreementReducer.slice].attested
export const getVersion = state => state[subscriptionAgreementReducer.slice].version
export const getContent = state => state[subscriptionAgreementReducer.slice].content
export const getDateTime = state => state[subscriptionAgreementReducer.slice].datetime
export const getError = state => state[subscriptionAgreementReducer.slice].error
export const getSLALoading = state => state[subscriptionAgreementReducer.slice].loading
export const getWaitValidation = state => state[subscriptionAgreementReducer.slice].waitValidation

export const getPermissionsLoading = ({ permissions }) => !!permissions?.loading

const getLoading = createSelector(
  getSLALoading,
  getPermissionsLoading,
  (slaLoading, permissionsLoading) => slaLoading || permissionsLoading
)

export const getSLAInfoSelector = createStructuredSelector({
  accepted: getAcceptence,
  version: getVersion,
  slaContent: getContent,
  dateVersion: getDateTime,
  error: getError,
  loading: getLoading,
  waitValidation: getWaitValidation
})
