import React from 'react';
import { Field } from 'redux-form';
import { useTranslate } from 'react-translate'
import { UPDATE_PREFERENCES } from '../../../ScheduleUpdateModal/schedule-updates.constants';
import { StyledCheckbox, Paragraph } from '../../schedule-modal.styles';

const renderCheckbox = ({
  input = () => {},
  label = null,
  disabled = false
}) => (
  <StyledCheckbox
    label={label}
    checked={input.value ? true : false}
    onChange={input.onChange}
    disabled={disabled}
  />
);

const UpdateExecution = ({
  editionDisabled = false
}) => {
  const t = useTranslate('AssetManagement');
  return(
    <>
      <h4>{t('NetworkRestriction')}</h4>
      {UPDATE_PREFERENCES.map((method) =>
        <Field
          component={renderCheckbox}
          fluid
          label={`${method.text}`}
          name={`UpdatePreferences.${method.key}`}
          t={t}
          disabled= {editionDisabled}
        />
      )}
    </>
  );
};

export default UpdateExecution;
