import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import SecurityPatchModal from './security-patch-modal'
import SecurityPatchModalSelector from './security-patch-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  connect(
    SecurityPatchModalSelector,
    mapDispatchToProps
  ),
  translate('DashboardCard')
)(SecurityPatchModal)
