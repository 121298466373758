import { call, put } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'

import BatteryActions from '../Redux/BatteryRedux'

const batteries = new schema.Entity('batteries', {}, {
  idAttribute: 'serialNumber',
  processStrategy: (entity) => ({
    ...entity,
    batteryHealthCustom: entity.batteryPercentageHealth && !isNaN(entity.batteryPercentageHealth) ? `${entity.batteryPercentageHealth}` : entity.health && !entity.health.includes('BATTERY') ? entity.health : 'Not Available',
    cycleCountFilter: isNaN(entity.cycleCount) ? 'Not Available' : entity.cycleCount > 500 ? '> 500' : entity.cycleCount > 400 ? '401 - 500' : entity.cycleCount > 300 ? '301 - 400' : entity.cycleCount > 200 ? '201 - 300' : entity.cycleCount > 100 ? '101 - 200' : '< 100',
    fullCapacityFilter: isNaN(entity.fullCapacity) ? 'Not Available' : entity.fullCapacity >= 6667 ? '> 6667' : entity.fullCapacity > 5334 ? '5334 - 6666' : entity.fullCapacity > 4001 ? '4001 - 5333' : entity.fullCapacity > 2668 ? '2668 - 4000' : entity.fullCapacity > 1335 ? '1335 - 2667' : '< 1334',
    averageCurrentFilter: isNaN(entity.averageCurrent) ? 'Not Available' : entity.averageCurrent >= 3331 ? '> 3331' : entity.averageCurrent > 2665 ? '2665 - 3330' : entity.averageCurrent > 1999 ? '1999 - 2664' : entity.averageCurrent > 1333 ? '1333 - 1998' : entity.averageCurrent > 667 ? '667 - 1332' : '< 666'
  })
})

export function * getBatteries (api, action) {
  try {
    const response = yield call(api.getBatteries)
    if (response.ok) {
      const norm = normalize(response.data.batteries, [batteries])
      yield put(BatteryActions.batterySuccess(norm))
    } else {
      yield put(BatteryActions.batteryFailure())
    }
  } catch (e) {
    yield put(BatteryActions.batteryFailure())
  }
}

export function * getBatteryHealth (api, { site, period, number }) {
  try {
    const response = yield call(api.getBatteryHealth, site, {
      delta: period,
      numPrev: number
    })
    if (response.ok) {
      yield put(BatteryActions.batteryHealthSuccess(response.data))
    } else {
      yield put(BatteryActions.batteryHealthFailure())
    }
  } catch (e) {
    yield put(BatteryActions.batteryHealthFailure(e))
  }
}

export function * getBatteryLevels (api, { site, date }) {
  try {
    const response = yield call(api.getBatteryLevels, site, date)
    if (response.ok) {
      yield put(BatteryActions.batteryLevelsSuccess(response.data))
    } else {
      yield put(BatteryActions.batteryLevelsFailure())
    }
  } catch (e) {
    yield put(BatteryActions.batteryLevelsFailure(e))
  }
}
