import { call, put } from 'redux-saga/effects'
import BiReportRedux from '../Redux/BiReportRedux'

export function * getReportToken (api, { site, deviceType }) {
  try {
    const response = yield call(api.getReportToken, site, deviceType)
    const { data } = response
    if (response.ok && data && data.groupId && data.reportToken && data.reportId) {
      yield put(BiReportRedux.bireportTokenSuccess({
        reportToken: data.reportToken,
        groupId: data.groupId,
        reportId: data.reportId
      }))
    } else {
      yield put(BiReportRedux.bireportTokenFailure())
    }
  } catch (e) {
    yield put(BiReportRedux.bireportTokenFailure())
  }
}

export function * getDeviceTypeBySiteId (api, { site }) {
  try {
    const response = yield call(api.getDeviceTypeBySiteId, site)
    const { data } = response
    if (response.ok) {
      yield put(BiReportRedux.bireportDeviceTypeBySiteSuccess({ deviceTypes: data }))
    } else {
      yield put(BiReportRedux.bireportDeviceTypeBySiteFailure())
    }
  } catch (e) {
    yield put(BiReportRedux.bireportDeviceTypeBySiteFailure())
  }
}
