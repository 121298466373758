import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import DroppedDevicesModal from './dropped-devices-modal'
import DroppedDevicesModalSelector from './dropped-devices-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  connect(
    DroppedDevicesModalSelector,
    mapDispatchToProps
  ),
  translate('CardsModals')
)(DroppedDevicesModal)
