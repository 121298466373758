import { connect } from 'react-redux'
import ModalActions from '../../Redux/ModalRedux'
import UpdateSiteModal from './devices-out-of-range-modal'
import DevicesOutRangeSelector from './devices-out-of-range-modal.selector'
import DeviceActions from '../../Redux/DeviceRedux'
import AlertsActions from '../../Redux/AlertsRedux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modal) => dispatch(ModalActions.modalOpen(modal)),
    closeModal: () => dispatch(ModalActions.modalClose()),
    onSubmit: data => dispatch(DeviceActions.updateBulkEditDataRequest(data)),
    getDevices: () => dispatch(DeviceActions.devicesOutOfRangeRequest()),
    deleteAlerts: alerts => dispatch(AlertsActions.alertsCleanRecommended(alerts))
  }
}

export default pipe(
  translate('DevicesOutOfRangeModal'),
  connect(
    DevicesOutRangeSelector,
    mapDispatchToProps
  )
)(UpdateSiteModal)
