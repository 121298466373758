import * as Yup from 'yup'
const ipAddressRegex = /^([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])(\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])){3}$/;

function ip2int(ip) {
  return ip?.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
}

export default ({ t }) => Yup.object().shape({
  firstIpAddress: Yup.string()
    .matches(ipAddressRegex, 'Invalid IP Address')
    .required(t('Required')),
  lastIpAddress: Yup.string()
    .matches(ipAddressRegex, 'Invalid IP Address')
    .test('match', 'First and second octets mismatch',
      function (lastIpAddress) {
        if (this.parent.firstIpAddress && lastIpAddress) {
          let firstOctetLIPA = lastIpAddress.substring(0, lastIpAddress.indexOf('.'))
          let secondOctetLIPA = lastIpAddress.substring(lastIpAddress.indexOf('.') + 1, lastIpAddress.indexOf('.', lastIpAddress.indexOf('.') + 1))
          let firstOctetFIPA = this.parent.firstIpAddress.substring(0, this.parent.firstIpAddress.indexOf('.'))
          let secondOctetFIPA = this.parent.firstIpAddress.substring(this.parent.firstIpAddress.indexOf('.') + 1, this.parent.firstIpAddress.indexOf('.', this.parent.firstIpAddress.indexOf('.') + 1))
          return firstOctetLIPA === firstOctetFIPA && secondOctetLIPA === secondOctetFIPA;
        } else {
          return false;
        }
      })
    .test('biggerThan', 'Start IP should be less than End IP',
      function (lastIpAddress) {
        return ip2int(lastIpAddress) > ip2int(this.parent.firstIpAddress)
      })
    .required(t('Required')),
  networkName: Yup.string()
    .max(75, 'Maximum 75 characters are allowed')
    .nullable()
})
