import styled from 'styled-components/macro'
import { Select } from '@scuf/common';
import { Button } from '@scuf/common'


const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`
const Header = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
`

const Container = styled(Flex)`
  flex-direction: column;
`

const SelectorContainer = styled.div`
display: flex;
padding: 0.5rem;
justify-content: center;
align-content: center;
align-items: center;
margin: 1.5rem 0rem;
`

const SiteSelector = styled(Select)`
width: 100% !important;
margin: 0rem 1rem;
z-index: 5 !important;
 & .ui.label {
  min-width: fit-content !important;
}

& .ui.multiple.selection.dropdown{
  display: flex;
  flex-wrap: wrap;
}

& .visible.menu.transition{
  display: flex;
  flex-direction: column !important;
}
`

const ButtonAsAlerts = styled(Button)`
  min-width: auto;
  display: flex;
  width: max-content;
  margin: 0.5rem 0 1rem 0 !important;
`

export {
  Header,
  Container,
  SelectorContainer,
  SiteSelector,
  ButtonAsAlerts
}
