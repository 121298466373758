import get from 'lodash/get'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'

import Immediate from './time-selector-immediate'
import Schedule from './time-selector-scheduled'
import { SchedulerSelector, SoftwareTitle, TimezoneSelect } from './time-selector.styles'

const TimeSelector = ({
  input: { onChange, value },
  flags,
  limitNumberOfDays,
  setValidScheduleTime,
  t
}) => {
  const init = value
  const timezones = moment.tz.names()
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [updateOnSchedule, setUpdateOnSchedule] = useState(get(init, 'OnSchedule', true))
  const [newTimeZone, setNewTimeZone] = useState(get(init, 'TimeZone',
    timezones.find(timezone => timezone === clientTimezone) ? clientTimezone : null))
  const [timeZoneError, setTimeZoneError] = useState('')

  const setDefaultDates = () => { }

  return (
    <Fragment>
      <SchedulerSelector
        updateOnSchedule={updateOnSchedule}
        setUpdateOnSchedule={setUpdateOnSchedule}
        setDefaultDates={setDefaultDates}
        t={t}
      />
      <div style={{ width: '40%' }}>
        <SoftwareTitle>
          {t('TimeAndDateConfiguration')}
        </SoftwareTitle>
        <TimezoneSelect
          label={t('TimeZone')}
          onChange={setNewTimeZone}
          options={timezones.map((item) => ({
            value: item,
            text: item,
          }))}
          placeholder={t('Select time zone')}
          search
          value={newTimeZone}
          error={timeZoneError}
          fluid
        />
      </div>
      {!updateOnSchedule && <Immediate
        key={newTimeZone}
        limitNumberOfDays={limitNumberOfDays}
        timeZone={newTimeZone}
        hidden={updateOnSchedule}
        init={init}
        t={t}
        onChange={onChange}
      />}
      {updateOnSchedule && <Schedule
        key={newTimeZone}
        limitNumberOfDays={limitNumberOfDays}
        timeZone={newTimeZone}
        hidden={!updateOnSchedule}
        init={init}
        setValidScheduleTime={setValidScheduleTime}
        t={t}
        onChange={onChange}
      />}
    </Fragment>
  )
}

TimeSelector.defaultProps = {
  t: label => label
}

TimeSelector.propTypes = {
  t: PropTypes.func
}

export default TimeSelector
