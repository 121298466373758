import React from 'react'
import { call, put, select, all } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'
import moment from 'moment'
import { toast } from 'react-toastify'

import FirmwareRedux from '../Redux/FirmwareRedux'
import UpdatesActions, { UpdatesTypes } from '../Redux/UpdatesRedux'
import ModalActions from '../Redux/ModalRedux'

import {
  SuccessToast,
  ErrorToast
} from '../Themes/ScufStyledComponents'
import { distribution as distributionv2 } from './UpdatesSagas'

const distribution = new schema.Entity('distribution', {}, {
  idAttribute: entity => entity.caidcAssetId,
  processStrategy: entity => ({
    ...entity,
    releaseDateFormatted: moment(entity.releaseDate).locale(window.navigator.language).format('D MMM YYYY')
  })
})

const firmware = new schema.Entity('firmware', { value: [distribution] }, {
  idAttribute: 'key'
})

const software = new schema.Entity('software', { value: [firmware] }, { idAttribute: 'key' })

const dist = new schema.Entity('distribution', {}, {
  processStrategy: e => {
    const [assetCategory] = e.type.split('-')
    return {
      ...e,
      releaseDate: e.releaseDate,
      fileSize: `${e.size} B`,
      priority: 'Normal',
      displayName: e.name,
      caidcAssetId: e.id,
      assetCategory
    }
  }
})

export function * getModels (api, { deviceType }) {
  try {
    const response = yield call(api.getModels, deviceType)
    if (response.ok) {
      yield put(FirmwareRedux.firmwareModelSuccess(response.data))
    } else {
      yield put(FirmwareRedux.firmwareModelFailure())
    }
  } catch (e) {
    yield put(FirmwareRedux.firmwareModelFailure())
  }
}

export function * getDevicesByModel (api, { deviceType, model }) {
  try {
    const response = yield call(api.getDevicesByModel, deviceType, model)

    if (response.ok && response.data.devices.length > 0) {
      yield put(FirmwareRedux.firmwareDeviceSuccess(response.data.devices))
    } else {
      yield put(FirmwareRedux.firmwareDeviceFailure())
    }
  } catch (e) {
    yield put(FirmwareRedux.firmwareDeviceFailure())
  }
}

export function * getFirmwareByModel (api, { deviceType, model }) {
  try {
    const response = yield call(api.getFirmwareByModel, deviceType, model)
    if (response.ok && response.data.response.length > 0) {
      const data = normalize(response.data.response, [software])
      yield put(FirmwareRedux.firmwareVersionSuccess(data))
    } else {
      yield put(FirmwareRedux.firmwareVersionFailure())
    }
  } catch (e) {
    yield put(FirmwareRedux.firmwareVersionFailure())
  }
}

export function * getAllAssetsInfo (api) {
  try {
    const response = yield call(api.getAllFirmwareAssets)
    if (response.ok) {
      const data = response.data ? normalize(response.data.response, [software]) : { result: [], entities: {} }
      yield put(FirmwareRedux.firmwareVersionSuccess(data))
    } else {
      yield put(FirmwareRedux.firmwareVersionFailure())
    }
  } catch (e) {
    yield put(FirmwareRedux.firmwareVersionFailure())
  }
}

export function * getAssetsInfo (api) {
  try {
    const devices = yield select(state => state.devices.selectedDevices)

    const response = yield call(api.getAssetsInfo, devices)
    if (response.ok) {
      const data = response.data ? normalize(response.data.response, [software]) : { result: [], entities: {} }
      yield put(FirmwareRedux.firmwareVersionSuccess(data))
    } else {
      yield put(FirmwareRedux.firmwareVersionFailure())
    }
  } catch (e) {
    yield put(FirmwareRedux.firmwareVersionFailure())
  }
}

export function * firmwareRequestAssetsForDevice (api, { type, ...deviceParams }) {
  try {
    let filter
    switch (type) {
      case UpdatesTypes.UPDATES_REQUEST_LIST:
        yield put(ModalActions.modalOpen('updatesModalList'))
        filter = JSON.stringify({ compatibledeviceids: deviceParams.ids.join(',') })
        break
      case UpdatesTypes.UPDATES_UPDATE_REQUEST_LIST:
        yield put(ModalActions.modalOpen('scheduleUpdateModal', {
          closeIcon: true,
          size: 'large',
          className: 'modal-height modal-center',
          closeOnDimmerClick: false,
          style: {overflowY:'scroll'}
        }))
        filter = {
          compatibledeviceType: deviceParams.deviceType,
          compatibledevicemodels: deviceParams.models.join(','),
          type: deviceParams.assetTypes }
        break
    }

    const response = yield call(api.getAllAssetsV2, { filter })
    if (response.ok) {
      const data = response.data ? normalize(response.data.data, [distributionv2]) : { result: [], entities: {} }
      yield put(UpdatesActions.updatesSuccessList(data))
    } else {
      yield put(UpdatesActions.updatesFailureList())
      yield call(toast, <ErrorToast />)
    }
  } catch (e) {
    yield put(UpdatesActions.updatesFailureList())
    yield call(toast, <ErrorToast />)
  }
}

export function * getScheduled (api) {
  const response = yield call(api.getSchedules)
  if (response.ok && response.data) {
    yield put(FirmwareRedux.firmwareSuccessScheduled(response.data))
  } else {
    yield put(FirmwareRedux.firmwareFailureScheduled())
  }
}

export function * firmwareRequestToUpdate (api, { firmware }) {
  try {
    const response = yield call(api.updateFirmware, firmware)
    if (response.ok && response.data) {
      yield all([
        put(FirmwareRedux.firmwareSuccessToUpdate(response.data)),
        call(getScheduled, api),
        put(UpdatesActions.updatesTabSelection(2))
      ])
      yield put(ModalActions.modalClose())
    } else {
      yield put(FirmwareRedux.firmwareFailureToUpdate())
    }
  } catch (error) {
    yield put(FirmwareRedux.firmwareFailureToUpdate())
  }
}
