import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  showCreateNetworkRangeModal: null,
  showEditNetworkRangeModal: ['networkRange'],
  createNetworkRangeRequest: ['siteId', 'networkRange', 'callback'],
  createNetworkRangeSuccess: null,
  createNetworkRangeFailure: null,
  editNetworkRangeRequest: ['id', 'networkRange', 'callback'],
  editNetworkRangeSuccess: null,
  editNetworkRangeFailure: null,
  closeModal: null
}, {
  prefix: '@NETWORK_RANGES/'
})

export const NetworkRangesTypes = Types
export const NetworkRangesActions = Creators
