import React from 'react'

import ColumnGraph from '../../Assets/ColumnGraph.png'
import LineGraph from '../../Assets/LineGraph.png'
import NumberGraph from '../../Assets/NumberGraph.png'
import DonutGraph from '../../Assets/DonutGraph.png'
import { StyledConfigureCard, Title, Paragraph, Image, DragIcon, TextContainer, StyledCheckbox, ImageContainer, Center } from './configure-card.styles'
import PropTypes from 'prop-types'

const imageType = (type) => {
  switch (type) {
    case 0: return ColumnGraph
    case 1: return LineGraph
    case 2: return NumberGraph
    case 3: return DonutGraph
    default: return NumberGraph
  }
}

const ConfigureCard = ({
  checked,
  type,
  title,
  description,
  checkBoxClick,
  t
}) => {
  return (
    <StyledConfigureCard>
      <StyledCheckbox
        defaultChecked={checked}
        onChange={(checked) => checkBoxClick(title, checked)}
      />
      <ImageContainer>
        <Center>
          <Image alt='Graph type' src={imageType(type)} />
        </Center>
      </ImageContainer>
      <TextContainer>
        <Title>{t(title)}</Title>
        <Paragraph>{t(`${description}_description`)}</Paragraph>
      </TextContainer>
      <DragIcon className='icon-drag' />
    </StyledConfigureCard>
  )
}

ConfigureCard.defaultProps = {
  checked: true,
  description: '',
  t: () => {},
  title: '',
  type: 1
}

ConfigureCard.propTypes = {
  checked: PropTypes.bool,
  description: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  checkBoxClick: PropTypes.func,
  type: PropTypes.number
}

export default ConfigureCard
