import { AsyncThunkPayloadCreator, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import * as API from '../apis'
import throttle from 'lodash/throttle'
import { RootState } from 'Store'
import { ErrorMessage } from '../../../Services/api-response'
import { LoginTypes } from '../../../Redux/LoginRedux'

const assetModelsAdapter = createEntityAdapter<API.AssetModel>({
  selectId: (model: API.AssetModel) => model.id,
  sortComparer: (a: API.AssetModel, b: API.AssetModel) =>
    a.name.localeCompare(b.name)
})

const fetchAssetModelsPayloadCreator: AsyncThunkPayloadCreator<API.AssetModel[], string, { rejectValue: string }> =
  async (organizationId: string, thunkAPI) => {
    const response = await API.getAssetModels(organizationId)
    if (response.ok && response.data?.data) {
      return response.data.data
    }
    return thunkAPI.rejectWithValue('There was an error fetching asset models.')
  }

export const fetchAssetModels = createAsyncThunk<API.AssetModel[], string, { rejectValue: string }>(
  'assetModels/fetchAll',
  // @ts-ignore
  throttle(fetchAssetModelsPayloadCreator, 1000, { leading: true, trailing: false })
)

export const createAssetModel = createAsyncThunk<API.AssetModel, API.CreateAssetModel, { rejectValue: ErrorMessage[] }>(
  'assetModels/createOne',
  async (assetModel, thunkAPI) => {
    const response = await API.createAssetModel(assetModel)
    if (response.ok && response.data?.data) {
      return response.data.data
    } else if (response.data?.errors) {
      return thunkAPI.rejectWithValue(response.data.errors)
    }
    throw new Error('There was an error creating the asset model.')
  }
)

const assetModelsSlice = createSlice({
  name: 'assetModels',
  initialState: assetModelsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(LoginTypes.LOGIN_SET_CURRENT_ORGANIZATION, assetModelsAdapter.removeAll)
    builder.addCase(fetchAssetModels.fulfilled, assetModelsAdapter.upsertMany)
    builder.addCase(createAssetModel.fulfilled, assetModelsAdapter.addOne)
  }
})

export const {
  selectById: selectAssetModelById,
  selectIds: selectAssetModelIds,
  selectEntities: selectAssetModelEntities,
  selectAll: selectAllAssetModels,
  selectTotal: selectTotalAssetModels
} = assetModelsAdapter.getSelectors((state: RootState) => state.assetModels)

export const assetModelsReducer = assetModelsSlice.reducer
