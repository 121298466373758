const renderStatus = (value, t) => {
    return value ? t(value) : t('NotAvailable')
}

const renderValue = (value) => <div>{value}</div>

export const getColumns = (t) => {
    return [
      { dataKey: 'deviceSerialNumber', field: 'deviceSerialNumber', translation: t('SerialNumber'), renderValue: ({ cellData }) => renderValue(cellData)},
      { dataKey: 'i18NStatus', field: 'i18NStatus', translation: t('UpdateStatus'), render: ({ cellData }) => renderValue(t(cellData))},
      { dataKey: 'status', field: 'status', translation: t('Remarks'), render: ({ cellData }) => renderStatus(cellData, t)},
    ]
  }