import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  changeMenuCollapsed: ['section', 'sectionState']
})

export const FilterTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  adminOpts: false,
  assetsOpts: false,
  reportOpts: false,
  siteOpts: false,
  toolsOpts: false
})

/* ------------- Reducers ------------- */
export const sectionClick = (state, { section, sectionState }) =>
  state.setIn([section], sectionState)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_MENU_COLLAPSED]: sectionClick
})
