import { createSelector, createStructuredSelector } from 'reselect'
import { legacyTranslate } from '../i18n/translate.helpers'

export const currentCulture = ({ user }) => user.language

export const notAvailable = createSelector(
  currentCulture,
  (currentCulture) => legacyTranslate(`${currentCulture}.Not_available`)
)

export default createStructuredSelector({
  notAvailable,
  currentCulture
})
