import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from './../../../../../Components/AssetManagementTable'
import { ModalTitle, ModalWrap } from './offline-devices-by-model-modal.styles'

export const columns = [
  { field: 'key', translation: 'AssetManagementTable_model', render: null },
  { field: 'value', translation: 'AssetManagementTable_modelCount', render: null },
  { field: 'percentage', translation: 'AssetManagementTable_percentage',  render: ({ value }) => <p>{value} %</p> }
]

const OfflineDevicesByModelModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('OfflineDevicesByModel_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns}
        disableRowClick
        fileName={t('OfflineDevicesByModel_title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default OfflineDevicesByModelModal
