import { connect } from 'react-redux'
import { pipe } from 'ramda'
import SitesBulkEdit from './sites-bulk-edit'
import SitesBulkEditSelector from './sites-bulk-edit.selector'
import DeviceActions from '../../../../Redux/DeviceRedux'
import SiteManagerActions from '../../../../Redux/SiteManagerRedux'
import { translate } from 'react-translate'

export const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(DeviceActions.updateBulkEditDataRequest(data)),
  cleanResults: () => dispatch(DeviceActions.deviceUploadProvisionClean()),
  updateBulkEditData: newData => dispatch(DeviceActions.updateBulkEditData(newData)),
  getSites: () => dispatch(SiteManagerActions.siteManagerRootSitesRequest())
})

export default pipe(
  translate('FormUploadDevices'),
  connect(
    SitesBulkEditSelector,
    mapDispatchToProps
  ))(SitesBulkEdit)
