import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@scuf/common'

import {
  Form,
  Row,
  Padding
} from './modal.styles'
import Input from 'Components/Shared/formiks/input'
import { StyledModal } from '../../../../Components/Modal/modal.styles'
import { useFormikContext } from 'formik'

const BaseModal = ({
  isOpen,
  t,
  handleSubmit,
  closeModal,
  resetForm,
  isEdit,
  permissions
}) => {
  const [mask, setMask] = useState([])
  const [isEditFriendlyOnly, setIsEditFriendlyOnly] = useState(true)
  const { setFieldValue } = useFormikContext()
  const onCloseModal = () => {
    resetForm()
    setMask([])
    closeModal()
  }

  useEffect(() => {
    setFieldValue('mask', mask)
  }, [mask])

  useEffect(() => {
    if (permissions) {
      setIsEditFriendlyOnly(isEdit && permissions['beacons:edit_friendly_name'])
    }
  }, [isEdit, permissions])

  return (
    <StyledModal
      closeIcon
      onClose={onCloseModal}
      open={isOpen}
      size='small'
    >
      <StyledModal.Header>
        {t('Access Point')}
      </StyledModal.Header>
      <Form onSubmit={handleSubmit}>
        <StyledModal.Content>
          <Row>
            <Input
              fluid
              indicator='required'
              label={t('Name')}
              name='name'
              setMask={setMask}
            />
          </Row>
          <Row>
            <Input
              fluid
              disabled={isEditFriendlyOnly}
              indicator='required'
              label={t('SSID')}
              name='ssid'
              setMask={setMask}
            />
            <Padding />
            <Input
              fluid
              disabled={isEditFriendlyOnly}
              indicator='required'
              label={t('BSSID')}
              name='bssid'
              setMask={setMask}
            />
          </Row>
          <Row>
            <Input
              fluid
              disabled={isEditFriendlyOnly}
              indicator='required'
              label={t('Building')}
              name='building'
              setMask={setMask}
            />
            <Padding />
            <Input
              fluid
              disabled={isEditFriendlyOnly}
              indicator='required'
              label={t('Floor')}
              name='floor'
              setMask={setMask}
            />
          </Row>
        </StyledModal.Content>
        <StyledModal.Footer>
          <Button
            content={t('Cancel')}
            onClick={onCloseModal}
            type='secondary'
          />
          <Button
            actionType='submit'
            content={t('Button')}
            disabled={mask.length === 0}
            type='primary'
          />
        </StyledModal.Footer>
      </Form>
    </StyledModal>
  )
}

BaseModal.propTypes = {
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func
}

BaseModal.defaultProps = {
  isEdit: false,
  permissions: [],
  t: l => l
}

export default BaseModal
