import Immutable from 'seamless-immutable'
import { createSelector, createStructuredSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import { legacyTranslate } from '../i18n/translate.helpers'
import { notAvailable } from 'Selectors/CommonSelector'

export const currentCulture = state => state.user.language
export const theme = state => state.user.theme
export const devicesById = state => state.devices.devicesbyId
export const devicesObj = state => state.devices.devices
export const current = state => state.devices.currentDevice
export const countersObj = state => state.devices.counters
export const loading = state => state.devices.loading
export const loadingBackground = state => state.devices.loadingBackground
export const deviceTrends = state => state.deviceTrends.categories
export const deviceTrendsLoading = state => state.deviceTrends.loading
export const errors = state => state.devices.errors
export const telemetryData = state => state.devices.telemetryData
export const selectedDevices = state => state.devices.selectedDevices
export const selectedSite = state => state.devices.selectedSite
export const reloading = state => state.devices.reloading
export const totalDevices = state => state.devices.totalDevices
export const stopLoading = state => state.devices.stopLoading
export const filterDevices = ({ devices }) => devices.getIn(['filterDevicesById'], [])

export const deviceTypes = () => ({
  mobilecomputer: {
    value: 0,
    name: 'Filters_MobileComputers'
  },
  printer: {
    value: 1,
    name: 'Filters_Printers'
  },
  scanner: {
    value: 2,
    name: 'Filters_Scanners'
  },
  gateway: {
    value: 3,
    name: 'Filters_Gateways'
  },
  robots: {
    value: 4,
    name: 'Filters_Robots'
  },
  rfidreader: {
    value: 5,
    name: 'Filters_RFIDReaders'
  }  
})

const batteryLevels = () => [{
  name: 'Unknown',
  data: [[], [], []]
}, {
  name: '0 - 20%',
  data: [[], [], []]
}, {
  name: '20 - 40%',
  data: [[], [], []]
}, {
  name: '40 - 70%',
  data: [[], [], []]
}, {
  name: '70 - 100%',
  data: [[], [], []]
}]

const cycleCounts = () => [{
  name: 'Unknown',
  data: [[], [], []]
}, {
  name: '> 500',
  data: [[], [], []]
}, {
  name: '401 - 500',
  data: [[], [], []]
}, {
  name: '301 - 400',
  data: [[], [], []]
}, {
  name: '201 - 300',
  data: [[], [], []]
}, {
  name: '101 - 200',
  data: [[], [], []]
}, {
  name: '< 100',
  data: [[], [], []]
}]

const avgDrawn = () => [{
  name: 'Unknown',
  data: [[], [], []]
}, {
  name: '> 3330',
  data: [[], [], []]
}, {
  name: '2665 - 3330',
  data: [[], [], []]
}, {
  name: '1999 - 2664',
  data: [[], [], []]
}, {
  name: '1333 - 1998',
  data: [[], [], []]
}, {
  name: '666 - 1332',
  data: [[], [], []]
}, {
  name: '< 666',
  data: [[], [], []]
}]

export const devices = createSelector([devicesById, devicesObj, deviceTypes, currentCulture, filterDevices], (ids, devs, types, culture, filter) => {
  if (!ids.length) {
    return Immutable([])
  }

  const result = ids.map(id => {
    const current = devs.getIn([id])
    const currType = current ? current.deviceType : 'Not Available'
    const connectionStatus = current ? current.status && current.status === 'connected' ? 'Filters_Connected' : 'Filters_Disconnected' : 'Filters_NotAvailable'
    const locationFormatted = current ? current.location ? `${current.location.country}, ${current.location.state}` : '' : 'Not Available'
    const deviceTypeFormatted = current ? currType && types[currType] ? types[currType].name : '' : 'Filters_NotAvailable'
    const dateFormatted = current ? current.securityPatchLevel ? moment(current.securityPatchLevel).locale(window.navigator.language).format('MMM YYYY') : notAvailable : notAvailable
    const updateDateFormatted = current && current.lastUpdated ? moment.utc(current.lastUpdated).locale(window.navigator.language).fromNow() : 'Not Available'
    const modelUpperCase = current && current.model ? current.model.toUpperCase() : 'Not Available'
    const name = current.getIn(['deviceName']) || 'Not Available'
    const deviceState = current.getIn(['deviceState'], null)
    const userName = current.getIn(['userName']) || 'Not Available'
    const deviceGuid = current.getIn(['deviceIdentifier', 'deviceGuid']) || current.getIn(['deviceGuid']) || id
    const dropEvents = current.getIn(['dropEvents'], {})
    const displayModel = get(current, 'displayModel', modelUpperCase) || modelUpperCase
    const site = current.getIn(['hierarchy'], '').split(' / ') // [, ...site] deconstruct was not working
    const batteryLevel = legacyTranslate(`${culture}.Not_available`)
    const lastUser = legacyTranslate(`${culture}.Not_available`)
    const scanVolume = legacyTranslate(`${culture}.Not_available`)
    const labelsPrinted = legacyTranslate(`${culture}.Not_available`)
    const odometer = legacyTranslate(`${culture}.Not_available`)
    return {
      ...current,
      connectionStatus,
      deviceTypeFormatted,
      locationFormatted,
      dateFormatted,
      updateDateFormatted,
      modelUpperCase,
      name,
      deviceState,
      id,
      userName,
      deviceGuid,
      dropEvents,
      site: site[site.length - 1],
      displayModel,
      batteryLevel,
      lastUser,
      scanVolume,
      labelsPrinted,
      odometer
    }
  })
  if (filter && filter.length) {
    return Immutable(result.filter(device => filter.indexOf(device.id) !== -1))
  }
  return Immutable(result)
})

export const currentDevice = createSelector([current, devicesObj], (id, devs) => devs.getIn([id, Immutable({})]))

export const counters = createSelector([countersObj], devs => {
  const ids = Object.keys(devs)
  if (!ids.length) {
    return Immutable([])
  }

  const result = ids.map(id => ({ ...devs.getIn([id]), id }))
  return Immutable(result)
})

export const allDevicesCounter = createSelector([counters], counter => {
  let total = 0
  const aux = counter.map(c => {
    total += isNaN(c.all) ? 0 : c.all
    return {
      name: c.id,
      id: c.id,
      amount: isNaN(c.all) ? 0 : c.all
    }
  })
  return Immutable([...aux, { id: 'total', name: 'total', amount: total }])
})

export const onlineDevicesCounter = createSelector([counters], counter => {
  let total = 0
  const aux = counter.map(c => {
    total += isNaN(c.connected) ? 0 : c.connected
    return {
      name: c.id,
      id: c.id,
      amount: isNaN(c.connected) ? 0 : c.connected
    }
  })
  return Immutable([...aux, { id: 'total', name: 'total', amount: total }])
})

export const disconnectedDevicesCounter = createSelector([counters], counter => {
  let total = 0
  const aux = counter.map(c => {
    total += isNaN(c.disconnected) ? 0 : c.disconnected
    return {
      name: c.id,
      id: c.id,
      amount: isNaN(c.disconnected) ? 0 : c.disconnected
    }
  })
  return Immutable([...aux, { id: 'total', name: 'total', amount: total }])
})

export const attentionDevicesCounter = createSelector([counters], counter => {
  let total = 0
  const aux = counter.map(c => {
    const amount = c.all ? c.all - c.connected - c.disconnected : c.all
    total += isNaN(amount) ? 0 : amount
    return {
      name: c.id,
      id: c.id,
      amount: isNaN(amount) ? 0 : amount
    }
  })
  return Immutable([...aux, { id: 'total', name: 'total', amount: total }])
})

export const batteryLevel = createSelector([devices, batteryLevels, deviceTypes], (devs, levels, types) => devs.reduce((t, d) => {
  const position = isNaN(d.batteryLevel) ? 0 : d.batteryLevel <= 20 ? 1 : d.batteryLevel <= 40 ? 2 : d.batteryLevel <= 70 ? 3 : 4
  const type = types[d.type || d.deviceType]
    ? types[d.type || d.deviceType].value
    : ''
  if (t[position].data[type]) {
    t[position].data[type] = [...t[position].data[type], d.id]
  }
  return t
}, levels)
)

export const batteryCycle = createSelector([devices, cycleCounts, deviceTypes], (devs, cycles, types) => devs.reduce((t, d) => {
  const position = isNaN(d.cycleCount) ? 0 : d.cycleCount > 500 ? 1 : d.cycleCount > 400 ? 2 : d.cycleCount > 300 ? 3 : d.cycleCount > 200 ? 4 : 5
  const type = types[d.type || d.deviceType]
    ? types[d.type || d.deviceType].value
    : ''
  if (t[position].data[type]) {
    t[position].data[type] = [...t[position].data[type], d.id]
  }
  return t
}, cycles)
)

export const batteryAvgDrawn = createSelector([devices, avgDrawn, deviceTypes], (devs, drawns, types) => {
  return devs.reduce((t, d) => {
    const position = isNaN(d.avgDrawn) ? 0 : d.avgDrawn > 3330 ? 1 : d.avgDrawn > 2664 ? 2 : d.avgDrawn > 1998 ? 3 : d.avgDrawn > 1332 ? 4 : 5
    const type = types[d.type || d.deviceType]
      ? types[d.type || d.deviceType].value
      : ''
    if (t[position].data[type]) {
      t[position].data[type] = [...t[position].data[type], d.id]
    }
    return t
  }, drawns)
})

export const getUpdateDevicesData = (devs, selectDevs, site, data) => {
  if (!selectDevs || !selectDevs.length) {
    return Immutable([])
  }
  let result = []
  if (selectDevs.length === 1 && data) {
    result = [
      {
        deviceDetail: {
          ...data.deviceDetail
        },
        deviceIdentifier: {
          deviceGuid: data.deviceGuid,
          serialNumber: data.serialNumber
        }
      }]
  } else {
    result = selectDevs.map(item => {
      let newItem = {
        deviceIdentifier: {
          deviceGuid: devs.getIn([item, 'deviceGuid']),
          serialNumber: devs.getIn([item, 'serialNumber'])
        }
      }
      return newItem
    })
  }

  if (site) {
    result = result.map(x => ({
      ...x,
      institutionAssociation: {
        organizationalUnitSiteAssociation: {
          organizationalUnitIdentifier: {
            organizationalUnitGuid: site
          }
        }
      }
    }))
  }

  return result
}

export const deviceUpdateData = createSelector([devicesObj, selectedDevices, selectedSite, () => null], getUpdateDevicesData)

export const devicesSelector = createStructuredSelector({
  devices
})

export const batterySelector = createStructuredSelector({
  batteryLevel,
  batteryCycle,
  batteryAvgDrawn,
  loading
})

export const currentDeviceSelector = createStructuredSelector({
  id: current,
  currentDevice,
  devicesObj,
  deviceTrends,
  deviceTrendsLoading,
  telemetryData,
  theme
})

export default createStructuredSelector({
  devices,
  currentDevice,
  batteryLevel,
  allDevicesCounter,
  onlineDevicesCounter,
  disconnectedDevicesCounter,
  attentionDevicesCounter
})
