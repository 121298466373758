import { call, put } from 'redux-saga/effects'
import AlertsActions from '../Redux/AlertsRedux'
import { get } from 'lodash'

export const getOrganizationName = sitePath => {
  if (!sitePath) {
    return ''
  }
  const siteParts = sitePath.split('/')
  if (siteParts.length === 0) {
    return ''
  }
  return siteParts[siteParts.length - 1].trim()
}

export const mapAlertTypeToIcon = {
  info: 'info',
  critical: 'warning',
  warning: 'alert'
}
export const parseAlertsResponse = (rawAlerts) => rawAlerts.map(alert => ({
  ...alert,
  icon: mapAlertTypeToIcon[get(alert, 'type', '').toLowerCase()],
  organizationalName: getOrganizationName(alert.siteName)
}))

export function * getAlerts (api, { pageNumber, pageSize }) {
  try {
    const response = yield call(api.getAlerts, { pageNumber, pageSize })
    if (response.ok) {
      const data = parseAlertsResponse(response.data.alerts || [])
      yield put(AlertsActions.alertsSuccess(data, response.data.total))
    } else {
      yield put(AlertsActions.alertsFailure())
    }
  } catch (_) {
    yield put(AlertsActions.alertsFailure())
  }
}

export function * getUnreadAlerts (api, { pageNumber, pageSize}) {
  try {
    const response = yield call(api.getAlerts, { pageNumber, pageSize, status: 'unread' })
    if (response.ok) {
      const data = parseAlertsResponse(response.data.alerts || [])
      yield put(AlertsActions.unreadAlertsSuccess(data, response.data.total))
    } else {
      yield put(AlertsActions.alertsFailure())
    }
  } catch (_) {
    yield put(AlertsActions.alertsFailure())
  }
}

export function * markAsRead (api, { alerts }) {
  try {
    const response = yield call(api.updateAlerts, alerts)
    if (response.ok) {
      yield put(AlertsActions.alertsMarkReadRequestSuccess(response.data || []))
    } else {
      yield put(AlertsActions.alertsMarkReadRequestFailure(alerts))
    }
  } catch (_) {
    yield put(AlertsActions.alertsMarkReadRequestFailure(alerts))
  }
}

export function * deleteAlerts (api, { alerts }) {
  try {
    const response = yield call(api.deleteAlerts, alerts)
    if (response.ok) {
      yield put(AlertsActions.alertsCleanSelectedAlertsSuccess(alerts))
    } else {
      yield put(AlertsActions.alertsCleanSelectedAlertsFailure())
    }
  } catch (_) {
    yield put(AlertsActions.alertsCleanSelectedAlertsFailure())
  }
}

export function * deleteAlertsRecommended (api, { alerts }) {
  try {
    const response = yield call(api.deleteRecommendedAlerts, alerts)
    if (response.ok) {
      yield put(AlertsActions.alertsCleanRecommendedSuccess(alerts))
    } else {
      yield put(AlertsActions.alertsCleanRecommendedFailure())
    }
  } catch (_) {
    yield put(AlertsActions.alertsCleanRecommendedFailure())
  }
}
