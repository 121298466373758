import { connect } from 'react-redux'
import { translate } from 'react-translate'
import ModalActions from '../../../../Redux/ModalRedux'
import RemoteAccessModal from './remote-access-modal'
import RemoteAccessModalSelector from './remote-access-modal.selector'

const mapStateToProps =(state) => {
    const selectedRemoteAccess = state.devices.selectedRemoteAccessDevice
    return { deviceId: selectedRemoteAccess }
}

export default translate('RemoteAccessModal')(connect(
    RemoteAccessModalSelector,
    null
)(RemoteAccessModal))

//export default translate('RemoteAccessModal')(RemoteAccessModal)
