import styled from 'styled-components/macro'
import { Button } from '@scuf/common'
import { isMobile } from 'Utils/constants'

export const MainWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: black;
`

export const AuthWrapper = styled.div`
  height: 589px;
  width: 450px;
  background-color: white;
`

export const FlexCenter = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`

export const FlexLeft = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
`

export const Title = styled.h1`
  color: #707070; 
  width: '100%';
  font-family: "Honeywell Sans Web";
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  align-content:center;
  font-size: 24px;
`

export const DescriptionDiv = styled.div`
  height: 118px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DescriptionText = styled.label`
  width: 387px;
  color: #707070;
  font-family: "Honeywell Sans Web";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  align-content:center;
`

export const UserText = styled.label`
  color: #707070;
  font-family: "Honeywell Sans Web";
  font-size: 12px;
  font-weight: ${props => props.useBold ? 'bold' : 'normal'};
  letter-spacing: 0;
  line-height: 15px;
  text-align: left;
  align-content:left;
  padding-right: 3px;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '0px'}; 
`

export const AppPermisionLabelTitle = styled.label`
  color: #404040;
  font-family: "Honeywell Sans Web Extrabold";
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  align-content:left;
  padding-left: 32px; 
`

export const AppPermisionLabel = styled.label`
  color: #404040;
  font-family: "Honeywell Sans Web Medium";
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  text-align: left;
  align-content:left;
  padding-top: 13px;
  padding-left: 32px; 
`

export const ContentWrapperButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 75px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
`

export const CopyrightsText = styled.label`
  color: #707070;
  font-family: "Honeywell Sans Web";
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  padding-left: 32px; 
`

export const FooterWrapperTermsAndConditions = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  justify-content: left;
`

export const StyledButton = styled(Button)`
  display: flex;
  align-self: center;
  width: '186px' !important;
`

export const StyledButtonLink = styled(Button)`
  color: #1792E5 !important;
  font-family: "Honeywell Sans Web" !important;
  font-size: 10px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
`

export const SeparatorText = styled.label`
  color: #979797;
  font-family: "Honeywell Sans Web";
  font-size: 22px ;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px ;
  text-align: center;
  align-content:center;
  padding-left: 2px;
  padding-right: 2px;
`