import React, { PureComponent } from 'react'
import { DataTable } from '@scuf/datatable'
import { Checkbox, Button, Modal, Search } from '@scuf/common'
import { isEmpty } from 'ramda'
import { Title, FormRow } from '../../Themes/ScufStyledComponents'
import Error from '../Error'

class DeleteSiteTable extends PureComponent {
  componentDidMount () {
    if (isEmpty(this.props.sites)) {
      this.props.getSites()
    }
  }

  render () {
    const {
      sites,
      currentSite,
      setCurrentSite,
      filter,
      closeModal,
      deleteSite,
      t,
      setFilter,
      sitesResults,
      loading
    } = this.props
    return (
      <div>
        <Modal.Header>
          <div style={{ width: '100%' }}>
            <FormRow justifyContent='space-between'>
              <Title>{!isEmpty(currentSite) ? currentSite.organizationalUnitHierarchy : t('DeleteSiteTable_Title')}</Title>
              <Search
                results={sitesResults}
                placeholder={t('DeleteSiteTable_MissingSelection')}
                onSearchChange={setFilter}
              />
            </FormRow>
          </div>
        </Modal.Header>
        { loading || isEmpty(sites)
          ? <Error loading={loading}>{t('DeleteSiteTable_Empty')}</Error>
          : <DataTable
            onRowClick={(e) => setCurrentSite(e.data.guid)}
            data={sites}
            columnResizeMode='fit'
            resizableColumns={true}
            metaKeySelection={false}
            scrollable={true}
            scrollHeight='57vh'
            globalFilter={filter}
          >
            <DataTable.Column
              field=''
              header=''
              initialWidth='10%'
              renderer={({ rowData }) => (
                <div style={{ textAlign: 'center' }}><Checkbox checked={currentSite.organizationalUnitGuid === rowData.guid} /></div>
              )}
            />
            <DataTable.Column
              field='name'
              sortable={true}
              header={t('DeleteSiteTable_Sites')}
              renderer={(row) => (
                <div style={{ textAlign: 'left' }}>{row.value}</div>
              )}
            />
          </DataTable>
        }
        <div style={{ position: 'absolute', padding: '1em', right: 0, bottom: 0 }}>
          <Button
            type='secondary'
            content={t('DeleteSiteTable_Cancel')}
            onClick={closeModal}
          />
          <Button
            onClick={(e) => deleteSite(currentSite.organizationalUnitGuid)}
            type='primary'
            content={t('DeleteSiteTable_Delete')}
          />
        </div>
      </div>
    )
  }
}

export default DeleteSiteTable
