import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-translate'

import { Description, TableContainer } from './ReachedDevices.styles'
import DataTable from 'Components/DataTable'

import { getColumns } from './ReachedDevices.constants'

const ReachedDevices = ({
    columns = [],
    data = [],
    loading = false,
    pageSize = 0,
    total = 0,
    setPageNumber = () => {},
    setSearchTerm = () => {},
    t = () => {}
}) => {
    return(<>
        <TableContainer id='reachedDevices' disabled={loading} >
            <Description>{t('ReachedDescription')}</Description>
            <DataTable 
                data={data}
                rows={pageSize}
                rowHeight={32}
                totalRows={total}
                search={true}
                resizableColumns={true}
                columnResizeMode='expand'
                searchPlaceholder={t('Search')}
                loadNextPage={setPageNumber}
                isLoadingPage={loading}
                onSearchChange={setSearchTerm}
            >
                {columns.map((column) => (
                    <DataTable.Column
                        dataKey={column.dataKey}
                        cellRenderer={column.render}
                        label={column.translation}
                        align='center'
                        disableSort
                />
                ))}
            </DataTable>
        </TableContainer>
    </>)
}

export default ({
   data = [],
   loading = false,
   pageSize = 0,
   total = 0,
   setPageNumber = () => {},
   setSearchTerm = () => {},
   t = () => {}
}) => {
    const [columns, setColumns] = useState([])
    const tr = useTranslate('AssetManagement')
    const td = useTranslate('Devicetable')

    useEffect(() => {
        setColumns(getColumns(t, tr, td))
    },[])

    const props = {
        columns,
        data,
        loading,
        pageSize,
        total,
        setPageNumber,
        setSearchTerm,
        t
    }

    return <ReachedDevices {...props} />
}