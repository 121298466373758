import { create } from 'apisauce'

export class BulkProvisionService {
  constructor (api) {
    this.api = api
    this.uploadApi = create({
      timeout: undefined
    })
  }

  provisionDevice = (data, site, config) => {
    try {
      return this.api.post(`/deviceonboarding/devices/${site}`, data, {
        ...config,
        timeout: 120000
      })
    }
    catch (error) {
      return {
        ok: false,
        error
      }
    }
  }
}
