import styled from 'styled-components/macro'
import { Icon } from '@scuf/common'

export const ToolsItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 4rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  color: ${props => props.disabled ? '#A0A0A0' : '#1B1D1F'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  &:hover {
    background-color: #F0F0F0;
  }
`

export const ToolsTotalCount = styled.div`
  color: ${props => props.disabled ? '#A0A0A0' : '#1B1D1F'};
  font-weight: 600;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`

export const ToolsIcon = styled(Icon)`
  color: ${props => props.disabled ? '#A0A0A0' : '#1B1D1F'};
  font-weight: 500;
`
