import { createStructuredSelector } from 'reselect'
import {
  sitesById as sites,
  cleanCurrentSite as currentSite,
  sitesSearchStruct,
  loading
} from '../../Selectors/SiteSelector'

export default createStructuredSelector({
  sites,
  currentSite,
  filter: state => state.filters.filter,
  sitesSearch: sitesSearchStruct,
  loading
})
