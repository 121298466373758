export class CheckOutInService {
  constructor(apiClient) {
    this.api = apiClient
  }

  // GET
  getOrgTime = siteId => this.api.get(`/inventories/${siteId}/time`)

  getLocationBarcode = siteId => this.api.get(`/inventories/${siteId}/barcode-number`)

  // POST
  setNewOrgTime = (siteId, value) => this.api.post(`/inventories/${siteId}/time/${value}`)

  setLocationBarcode = (siteId) => this.api.post(`/inventories/${siteId}/new-barcode-number`)

  sendEmail = (payload) => this.api.post('/inventories/checkout-email', payload)
}
