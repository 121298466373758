import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { AccessPointsTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  mode: '',
  isShowingModal: false,
  form: {},
  error: '',
  editSuccess: false,
  createSuccess: false,
  site: '',
  data: [],
  loadingAccessData: false,
  accessPointRanges: {
    excellent: [0,-64],
    good: [-85,-65],
    poor: [-86,-120]
  }
})

export const showCreateAccessPointModal = state => state.merge({
  isShowingModal: true,
  mode: 'create'
})

export const showEditAccessPointModal = (state, { accessPoint, isEdit, permissions }) => state.merge({
  isShowingModal: true,
  mode: 'edit',
  form: accessPoint,
  isEdit,
  permissions
})

export const startLoading = state => state.merge({ loading: true })

export const createSuccess = state => state.merge({ loading: false, createSuccess: true })

export const editSuccess = state => state.merge({ loading: false, editSuccess: true })

export const failure = (state, { error }) => state.merge({ loading: false, error, editSuccess: false, createSuccess: false })

export const reset = () => INITIAL_STATE

export const updateSite = (state, { site }) => state.merge({ site: site })

export const getAccessPointDataRequest = (state) => state.merge({ loadingAccessData: true })

export const getAccessPointDataResponse = (state, { data }) => state.merge({
  data: data || [],
  loadingAccessData: false
})

export const updateAccessRanges = (state, { params }) => console.log(params) || state.merge({
  accessPointRanges: {
    excellent: params.excellenteRange,
    good: params.goodRange,
    poor: params.poorRange
  }
})

export const accessPointsReducer = createReducer(INITIAL_STATE, {
  [AccessPointsTypes.SHOW_CREATE_ACCESS_POINT_MODAL]: showCreateAccessPointModal,
  [AccessPointsTypes.SHOW_EDIT_ACCESS_POINT_MODAL]: showEditAccessPointModal,
  [AccessPointsTypes.CREATE_ACCESS_POINT_REQUEST]: startLoading,
  [AccessPointsTypes.CREATE_ACCESS_POINT_SUCCESS]: createSuccess,
  [AccessPointsTypes.CREATE_ACCESS_POINT_FAILURE]: failure,
  [AccessPointsTypes.EDIT_ACCESS_POINT_REQUEST]: startLoading,
  [AccessPointsTypes.EDIT_ACCESS_POINT_SUCCESS]: editSuccess,
  [AccessPointsTypes.EDIT_ACCESS_POINT_FAILURE]: failure,
  [AccessPointsTypes.CLOSE_MODAL]: reset,
  [AccessPointsTypes.UPDATE_SITE]: updateSite,
  [AccessPointsTypes.GET_ACCESS_POINT_DATA_REQUEST]: getAccessPointDataRequest,
  [AccessPointsTypes.GET_ACCESS_POINT_DATA_RESPONSE]: getAccessPointDataResponse,
  [AccessPointsTypes.UPDATE_ACCESS_RANGES]: updateAccessRanges
})
