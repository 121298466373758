import styled from 'styled-components'
import { Checkbox } from '@scuf/common'
import { Button } from '@scuf/common'
import ReactMarkdown from 'react-markdown'

export const EndUserLicenseContainer = styled.section`
  height: 100vh;
  overflow: hidden;
`;

export const LogOutButton = styled(Button)`
`;

export const AcceptenceButton = styled(Button)`
`;

export const Content = styled.section`
  padding: 18px 54px 0px 24px;
  position: relative;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100vw !important;
    height: 100vh !important;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`

export const TermsConditionsContent = styled(ReactMarkdown)`
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: justify;
`

export const Actions = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
`

export const TermsCheckbox = styled(Checkbox)`
margin: 0 !important;
`

export const ButtonsSection = styled.section`
  margin-top: 38px;
`
