import { createStructuredSelector, createSelector } from 'reselect'
import { orderBy } from 'lodash'
import Immutable from 'seamless-immutable'

export const distributionOfDipsCard = ({ dashboard }) =>
  dashboard.getIn(['distributionOfOsCard'], {})

export const osVersion = createSelector(
  [distributionOfDipsCard],
  distributionOfDipsCard => distributionOfDipsCard.getIn(['firmwares'], [])
)

export const osValue = createSelector(
  [distributionOfDipsCard],
  distributionOfDipsCard => distributionOfDipsCard.getIn(['totals'], [])
)

export const osPtc = createSelector(
  [distributionOfDipsCard],
  distributionOfDipsCard => distributionOfDipsCard.getIn(['percentages'], [])
)

export const data = createSelector(
  osVersion,
  osValue,
  osPtc,
  (osVersion, osValue, osPtc) => {
    return orderBy(osVersion.map((value, index) => {
      return {
        key: value || 'Unknown',
        value: osValue[index],
        percentage: osPtc[index]
      }
    }), ['value'], ['desc'])
  }
)

export default createStructuredSelector({
  data
})
