import { DeviceApi, SitesManagerApi, TagsApi, TypeApi } from 'Services'

export function useAssetSelector (setLoading) {
  const getDevices = async (assetId, tags, site, includeChildSites, search, filter, pageNum, pageSz) => {
    setLoading(true)
    let resp
    resp = await TypeApi.getDevicesModelsV2({
      pageSize: pageSz,
      assetId,
      tags: tags ? tags.map(t => ({ [t.key]: t.val })) : null,
      sites: site ? site.map(item => item?.id) : filter.sites,
      includeChildSites: includeChildSites ? includeChildSites.toString() : null,
      connection: [ 'connected', 'disconnected' ],
      deviceDbStatus: { '$in': ['active'] },
      search: search !== undefined && search,
      model: filter.models?.length ? filter.models : undefined,
      firmware: filter.firmwares?.length ? filter.firmwares : undefined
    },
    null, pageNum, true)
    return resp.ok ? resp.data : null
  }

  const getTags = async () => {
    setLoading(true)
    let resp
    try {
      resp = await TagsApi.searchAllTags()
    } finally {
      setLoading(false)
    }
    if (resp.ok) {
      return resp.data.tags
        .map(t => ({
          value: { key: t.key, val: t.value },
          text: `${t.key}:${t.value}`
        }))
    }
    return []
  }

  const getSites = async () => {
    setLoading(true)
    let resp
    try {
      resp = await SitesManagerApi.getSites({}, true)
    } finally {
      setLoading(false)
    }
    if (resp.ok) {
      return resp.data.map(s => ({
        value: { id: s.organizationalUnitIdentifier.organizationalUnitGuid, name: s.organizationalUnitHierarchy },
        text: s.organizationalUnitHierarchy
      }))
    }
    return []
  }

  const getFirmwareVersions = async (assetId, t) => {
    setLoading(true)
    let resp
    try {
      resp = await DeviceApi.getFirmwareVersions({ assetId })
    } finally {
      setLoading(false)
    }
    if (resp.ok) {
      return resp.data.map(m => ({ value: m.value, text: m.value || t('N/A') }))
    }
    return []
  }

  return { getDevices, getTags, getSites, getFirmwareVersions }
}
