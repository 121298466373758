const create = (api) => {
  const getAlerts = params => api.get('/alerts/devices', params)

  const getSiteAlerts = (siteId, params) => api.get(`/alerts/${siteId}`, params)

  const updateAlerts = params => api.put('/alerts/devices', params)

  const deleteAlerts = params => api.delete('/alerts/devices', null, { data: params })

  const deleteRecommendedAlerts = params => api.put('/alerts/recommended', { ...params })

  const getDeviceAlerts = (deviceId, params) => api.get(`/alerts/devices/${deviceId}`, params)

  const updateDeviceAlerts = params => api.put('/alerts/devices', params)

  const getTimeZones = () => api.get('/alerts/settings/timezones')

  return {
    getAlerts,
    getSiteAlerts,
    updateAlerts,
    deleteAlerts,
    getTimeZones,
    updateDeviceAlerts,
    getDeviceAlerts,
    deleteRecommendedAlerts
  }
}

export default {
  create
}
