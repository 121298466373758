const pagination = {
  pageNumber: 1,
  pageSize: 5000
} //Leaving this in case we need

const create = (api) => {
  const getSites = (pageParams = pagination, swfwUpdate = false) => {
    const url = '/groupmgmt/OrgUnits/' + (swfwUpdate ? 'swfwupdate' : '')
    return api.get(url, pageParams)
  }

  const getSiteUsers = (siteId, pageParams = pagination) => api.get(`/groupmgmt/OrgUnits/${siteId}/relations/OwnedByUser`, pageParams)

  const getSiteDevices = (siteId, pageParams = pagination) => api.get(`/groupmgmt/OrgUnits/${siteId}/relations/OwnsDevice`, pageParams)

  const createSite = (data) => api.post('/groupmgmt/OrgUnits/', [data])

  const deleteSite = (siteId) => api.delete(`/groupmgmt/OrgUnits/${siteId}`)

  const updateSite = (data, siteId) => api.put(`/groupmgmt/OrgUnits/${siteId}`, data)

  const removeUser = (siteId, userId) => api.delete(`/groupmgmt/OrgUnits/${siteId}/relations/OwnedByUser/${userId}`)

  const getRootSites = (pageParams = pagination) => api.get('/groupmgmt/OrgUnits/administrable', pageParams)

  const getSitesWithReports = () => api.get('/groupmgmt/OrgUnits', { hasReports: true, ...pagination })

  const updateOrgUnit = (siteId, params) => api.post(`/OrgUnits/${siteId}/PersonCountUpdates`, {...params, TimeStamp: new Date()})

  const getOrgFeatures = (siteId) => api.get(`/UserManagement/groupConfiguration/${siteId}`)

  const updateOrgFeatues = (automaticSettings) => api.post(`UserManagement/groupConfiguration?automaticConfiguration=${automaticSettings}`)
  
  const moveDevicesNow = () => api.post(`devices/RecommendationsOnDemand`)
  
  const addPackageCarComment = (filters) => api.post('/packagecar/comments', filters)

  const getPackageCarComment = (siteId) => api.get(`/packagecar/comments?siteId=${siteId}`)

  return {
    getSites,
    getSiteUsers,
    getSiteDevices,
    createSite,
    updateSite,
    deleteSite,
    removeUser,
    getRootSites,
    getSitesWithReports,
    updateOrgUnit,
    getOrgFeatures,
    updateOrgFeatues,
    moveDevicesNow,
    addPackageCarComment,
    getPackageCarComment
  }
}

export default {
  create
}
