import { Icon, Modal, Button } from '@scuf/common'
import React from 'react'
import { useTranslate } from 'react-translate'

import { DeviceTypeFromCellData } from 'Components/AssetManagementBase/components/device-name/device-name'
import DataTable from 'Components/DataTable'

import { useScheduledDevices } from './scheduled-devices.hooks'
import {
  BasicValidationRender,
  Form,
  HeaderContainer,
  SimpleRow,
  SiteNameWithHierarchy,
  TableContainer,
  Title,
  ButtonWrap,
  Note,
  ModalFooter
} from './scheduled-devices.styles'
import JobIdentifier from '../../features/software-update/components/job-identifier'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const ScheduledDevicesModal = ({ language, scheduleId, flags }) => {
  const t = useTranslate('ScheduleUpdateModal')
  const tr = useTranslate('AssetManagement')
  const {
    devices,
    loading,
    setPageNumber,
    pageSize,
    totalCount,
    setSearchTerm,
    requestDownloadCSV,
    refreshListOfDevices,
  } = useScheduledDevices(scheduleId)
  return (
    <Form>
      <Modal.Header>
        <HeaderContainer>
          <SimpleRow>
            <Title>{t('List of devices')}</Title>
            {loading &&
              <div>
                <Icon loading={true} name='refresh' size='small' />
              </div>
            }
          </SimpleRow>
          <SimpleRow
            className='refresh-history'
            onClick={refreshListOfDevices}
          >
            {flags.downloadExports &&
              <ButtonWrap heigthDrill={true}>
                <Button id='export-button' type='link' onClick={() => requestDownloadCSV(language)}
                  iconPosition='left'
                  icon={<Icon root="building" name="export" size="large" />}
                  content={t('Export')}>
                </Button>

              </ButtonWrap>
            }
            <ButtonWrap>
              <Button id='refresh-button' type='link'
                iconPosition='left'
                icon={<Icon root="global" name="refresh" size="medium" />}
                content={t('Refresh')}
              />
            </ButtonWrap>
          </SimpleRow>
        </HeaderContainer>
      </Modal.Header>
      <Modal.Content>
        <TableContainer>
          <DataTable
            data={devices}
            rows={pageSize}
            rowHeight={32}
            totalRows={totalCount}
            search
            resizableColumns={false}
            columnResizeMode='expand'
            searchPlaceholder={t('Search for')}
            loadNextPage={setPageNumber}
            isLoadingPage={loading}
            onSearchChange={setSearchTerm}
          >
            <DataTable.Column
              dataKey='assetType'
              flexGrow={0.2}
              flexShrink={0.1}
              cellRenderer={DeviceTypeFromCellData}
              label={t('Type')}
              align='center'
              style={{ paddingLeft: 'inherit' }}
              initialWidth='125px'
              width={25}
              disableSort
            />
            <DataTable.Column
              dataKey='model'
              flexGrow={0.8}
              flexShrink={0.8}
              label={t('Model')}
              width={0.75}
              disableSort
            />
            <DataTable.Column
              dataKey='alias'
              flexGrow={1}
              flexShrink={1}
              label={t('Device Name')}
              width={1}
              disableSort
            />
            <DataTable.Column
              dataKey='deviceSerialNumber'
              flexGrow={0.8}
              flexShrink={0.8}
              label={t('Serial Number')}
              width={0.8}
              disableSort
            />
            <DataTable.Column
              dataKey='siteName'
              cellRenderer={SiteNameWithHierarchy}
              flexGrow={1}
              flexShrink={1}
              label={t('Hierarchy')}
              width={0.8}
              disableSort
            />
            <DataTable.Column
              dataKey='i18NStatus'
              flexGrow={0.75}
              flexShrink={0.75}
              label={t('UpdateStatus')}
              width={0.75}
              disableSort
              cellRenderer={
                ({ cellData }) => cellData
                  ? tr(cellData)
                  : tr('NotAvailable')
              }
            />
            <DataTable.Column
              dataKey='status'
              flexGrow={0.75}
              flexShrink={0.75}
              label={t('Remarks')}
              width={0.75}
              disableSort
              cellRenderer={BasicValidationRender}
            />
          </DataTable>
        </TableContainer>
      </Modal.Content>
      <ModalFooter>
        <Note>{t('Note')} </Note>
        <JobIdentifier masterGuid={scheduleId} />
      </ModalFooter>
    </Form>
  )
}

ScheduledDevicesModal.defaultProps = {
  flags: defaultFlags,
  t: l => l,
}

ScheduledDevicesModal.propTypes = {
  flags: flagsPropTypes
}

export default withLDConsumer()(ScheduledDevicesModal)
