const create = (api) => {
  const saveReport = (data) => api.post('/reportmgmt/tco', data)

  const saveHaasReport = (data) => api.post('/reportmgmt/haas', data)

  const getTcoReports = () => api.get('/reportmgmt/reports', { reportType: 'TCO' })

  const getHaasReports = () => api.get('/reportmgmt/reports', { reportType: 'HaaS' })

  const getReportId = (id) => {
    return api.get(`/reportmgmt/tco/${id}`)
  }

  const getBaseReportId = (id) => {
    return api.get(`/reportmgmt/haas/${id}`)
  }

  const deleteBaseReportId = (id) => {
    return api.delete(`/reportmgmt/reports/${id}`)
  }

  const calculateTco = (data) => api.post('/reportmgmt/tco/calculate', data)

  const calculateHaas = (data) => api.post('/reportmgmt/haas/calculate', data)

  const getField = (fieldName) => {
    return api.get('/reportmgmt/pricingmodel')
  }

  const getTerms = (fieldName) => {
    return api.get('/reportmgmt/leasepricing')
  }

  const postDevice = (data) => {
    return api.post('/reportmgmt/pricingmodel', data)
  }

  const putDevice = (data) => {
    return api.put('/reportmgmt/pricingmodel', data)
  }

  const postTerm = (data) => {
    return api.post('/reportmgmt/leasepricing', data)
  }

  const putTerm = (data) => {
    return api.put('/reportmgmt/leasepricing', data)
  }

  const getTemplates = () => {
    return api.get('/v1/reports/templates')
  }

  const getEventReports = async (payload) => {
    const { filter, from, to, template, pageNumber, pageSize } = payload
    const response = await api.get('/v1/reports', {
      filter: JSON.stringify(filter),
      template,
      from,
      to,
      pageNumber: pageNumber ?? 1,
      pageSize: pageSize ?? 100
    })
    if (!response.ok) {
      return response
    }
    return {
      ...response,
      ...response.data
    }
  }

  const getEventReportExportCSVFile = async (params) => {
    return api.get('/v1/reports/export', params)
  }

  return {
    saveReport,
    saveHaasReport,
    getTcoReports,
    getHaasReports,
    getReportId,
    getBaseReportId,
    deleteBaseReportId,
    calculateTco,
    calculateHaas,
    getField,
    getTerms,
    postDevice,
    putDevice,
    postTerm,
    putTerm,
    getTemplates,
    getEventReports,
    getEventReportExportCSVFile,
    axiosInstance: api.axiosInstance
  }
}

export default {
  create
}
