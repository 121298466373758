import { createStructuredSelector, createSelector } from 'reselect'

export const appData = ({ dashboard }) => dashboard.getIn(['appData'], [])

const filteredData = createSelector(
  [appData],
  apps => Object.keys(apps).map(app => apps[app]).filter(f => f && f['cpu.total'])
)

export const data = createSelector(
  [filteredData],
  apps => apps.map(app => {
    return ({
      appName: app.packagename,
      cpuUse: isNaN(parseInt(app['cpu.total'])) ? '0' : `${(parseInt(app['cpu.total']) / (1000 * 60) ).toFixed(2)}` == '0.00' ? 0 : `${(parseInt(app['cpu.total']) / (1000 * 60) ).toFixed(2)}`,
    })
  })
)

export default createStructuredSelector({
  appData,
  data
})
