/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import UserSettingsActions from '../../Redux/UserSettingsRedux'
import { Accordion, Button, Icon } from '@scuf/common'
import { DataTable } from '@scuf/datatable'
import DeviceType from '../AssetManagementBase/components/device-type-modal'
import styled from 'styled-components'
import Loader from '../Loader'
import {
  ModalWrap,
  HeaderWrap,
  StyledH2,
  ParagraphModal,
  ParagraphDescription,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap,
  StyledCloseIcon,
  NotAvailable
} from './devices-out-of-range-modal.styles'
import PropTypes from 'prop-types'

export const StyledIcon = styled(Icon)`
size:large !important
`

const UpdateSiteModal = ({
  closeModal,
  t,
  theme,
  selection,
  onSubmit,
  loading,
  getDevices,
  deleteAlerts,
  getAlertsUrl,
  loadingAlert,
  PayloadDelete,
  ...props
}) => {
  const [data, setData] = useState(selection)
  const [Dataupdate, setDataUpdate] = useState([])
  const [AlertId, setAlertId] = useState([])
  const [payload, setPayload] = useState()

  useEffect(() => {
    setPayload({ Type: "Apply", AlertInfo: PayloadDelete.AlertInfo, Alerts: PayloadDelete.Alerts })
  }, [PayloadDelete])

  useEffect(() => {
    const arrayData = Object.keys(data).map(key => {
      return data[key]
    })
    setDataUpdate(arrayData.map(d =>
      ({ serial: { value: d.serial }, alias: { value: d.name }, siteId: { value: d.recommendedOrganizationUnitId } })
    ))
    setAlertId(arrayData.map(s =>
      (s.id)
    ))
  }, [data])

  return (
    <ModalWrap theme={theme}>
      <Loader loading={loading} />
      <ModalHeader>
        <HeaderWrap>
          <StyledH2>{t('UpdateTitle')}</StyledH2>
          <ParagraphModal>
            {t('Description') + ' '}
            {t('DescriptionAction')}
          </ParagraphModal>
        </HeaderWrap>
      </ModalHeader>
      <div style={{ height: '1px' }} />
      <DataTable
        data={data}
        scrollable={true}
        scrollHeight='300px'
        resizableColumns={true}
      >
        <DataTable.Column
          header={t('Type')}
          field='type'
          initialWidth='11%'
          align='center'
          renderer={cellData =>
            <DeviceType class={StyledIcon} cellData={cellData.rowData.type} />
          }
        />
        <DataTable.Column
          header={t('Alias')}
          field='name'
          initialWidth='11%'
          align='center'
        />
        <DataTable.Column
          align='center'
          header={t('Serial')}
          field='serial'
          initialWidth='19%'
        />
        <DataTable.Column
          align='center'
          header={t('Site')}
          initialWidth='18%'
          field='siteName'
          renderer={cellData => {
            return cellData.rowData.siteName
              ? <NotAvailable>
                {cellData.rowData.siteName}
              </NotAvailable>
              : t('NotAvailable')
          }}
        />
        <DataTable.Column
          align='center'
          header={t('RecommendedSite')}
          field='recommendedOrganizationUnitName'
          initialWidth='20%'
          renderer={cellData => {
            return cellData.rowData.recommendedOrganizationUnitName
              ? <NotAvailable>
                {cellData.rowData.recommendedOrganizationUnitName}
              </NotAvailable>
              : t('NotAvailable')
          }}
        />
        <DataTable.Column
          align='center'
          header={t('CurrentIP')}
          field='recommendedIPAddress'
          initialWidth='19%'
        />
        <DataTable.Column
          align='center'
          initialWidth='6%'
          field='id'
          renderer={cellData =>
            <Icon root='building' name='delete' size='medium' onClick={() => {
              const newdata = data.filter((e) => {
                return e.id !== cellData.rowData.id
              })
              setData(newdata)
            }} />
          }
        />
      </DataTable>
      <ModalFooter>
        <FooterWrap>
          <ButtonWrap>
            <Button type='secondary' size='medium' content={t('Cancel')} onClick={closeModal} />
            <Button type='primary' size='medium' content={t('Update')} onClick={() => {
              onSubmit(Dataupdate)
              if (!loading) {
                deleteAlerts(payload)
                if (!loadingAlert) {
                  setTimeout(() => getAlertsUrl(), 500)
                  closeModal()
                }
              }
            }}
            />
          </ButtonWrap>
        </FooterWrap>
      </ModalFooter>
    </ModalWrap>
  )
}

UpdateSiteModal.propTypes = {
  assignFlag: PropTypes.bool,
  closeModal: PropTypes.func,
  getDevices: PropTypes.func,
  getAlertsFilters: PropTypes.func,
  roleId: PropTypes.string,
  t: PropTypes.func,
  onSubmit: PropTypes.func,
  deleteAlert: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  loadingAlert: PropTypes.bool
}

UpdateSiteModal.defaultProps = {
  assignFlag: true,
  closeModal: () => { },
  getDevices: s => s,
  loading: false,
  loadingAlert: false,
  roleId: '',
  t: () => { }
}

export default UpdateSiteModal
