import get from 'lodash/get'
import { normalize } from 'normalizr'

import { deviceNormalizer, devicesSchema } from './entity'

export class DeviceService {
  constructor (api) {
    this.api = api
  }
  getSupportedTypes = () => this.api.get('/v2/devices/supportedtypes')
  getDevicesModels = async () => {
    const response = await this.api.get('/v2/devices/supportedtypes')
    try {
      if (response.ok) {
        const data = normalize(response.data, [devicesSchema])
        return {
          ...response,
          data
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getProvisioningStatus = async () => {
    const response = await this.api.get('/deviceonboarding/provisioningstatus')
    try {
      if (response.ok) {
        const data = get(response, 'data', [])
        return {
          ...response,
          data
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  removeDevices = async () => {
    const response = await this.api.delete('/deviceonboarding/removedevices ')
    try {
      if (response.ok) {
        const data = get(response, 'data', [])
        return {
          ...response,
          data
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getDevicesModelsV2 = async ({
    type,
    search,
    sites,
    deviceManufacturer,
    model,
    tags,
    assetId,
    status,
    deviceDbStatus,
    state,
    serialNumber,
    alias,
    includeChildSites,
    deviceCheckStatus,
    ipStart,
    ipEnd,
    ipNotAvailable,
    connection,
    firmware,
    pageSize,
    deviceState
  }, sort, pagenumber, swfwUpdate = false) => {
    const profileId = '684D492B-CE98-43CF-AEEF-F333A84440BF'

    const url = '/v2/devices' + (swfwUpdate ? '/swfwupdate' : '')

    const response = await this.api.get(url, {
      pagenumber,
      pagesize: pageSize,
      filter: JSON.stringify({
        serialNumber: serialNumber && { '$in': serialNumber },
        name: alias || undefined,
        status,
        deviceDbStatus,
        state,
        type,
        deviceCheckStatus,
        deviceState,
        '$freeform': search ? [search] : undefined,
        siteId: sites
          ? {
            '$in': sites
          }
          : undefined,
        model: model
          ? {
            '$in': model
          }
          : undefined,
        deviceManufacturer: deviceManufacturer
          ? {
            '$in': deviceManufacturer
          }
          : undefined,  
        includeChildSites: includeChildSites || undefined,
        assetId,
        connectionStatus: connection && { '$in': connection },
        firmwareVersion: firmware && { '$in': firmware },
        ipStart,
        ipEnd,
        ipNotAvailable
      }),
      tags: tags && JSON.stringify({
        '$or': tags
      }),
      sort: sort && JSON.stringify([sort]),
      profileId
    })
    try {
      if (response.ok) {
        const devices = get(response, 'data.data', [])
        const normalizeDevices = normalize(devices, [deviceNormalizer])
        const result = get(normalizeDevices, 'result', [])
        return {
          ...response,
          data: {
            ...response.data,
            devicesIds: result.join(','),
            data: {
              devices: normalizeDevices.entities.devices,
              result
            }
          }
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getDevicesModelsIds = async ({
    devicesIds,
    serialNumber
  }) => {
    const profileId = '684D492B-CE98-43CF-AEEF-F333A84440BF'
    const payload = devicesIds.split(',')
    const response = await this.api.get('/v2/devices', {
      pagenumber: 1,
      filter: JSON.stringify({
        serialNumber: { '$in': payload }
      }),
      profileId
    })
    try {
      if (response.ok) {
        const devices = get(response, 'data')
        return {
          devices,
          devicesIds
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getAlertsRecommended = async ({
    type,
    search,
    sites,
    organizationalUnitId,
    recommendedOrganizationalUnitId,
    ipStart,
    ipEnd,
    ipNotAvailable
  }, pageSize, pageNumber) => {
    const profileId = '684D492B-CE98-43CF-AEEF-F333A84440BF'
    const response = await this.api.get('alerts/recommended', {
      pageNumber,
      pageSize,
      filters: JSON.stringify({
        organizationalUnitId: organizationalUnitId || undefined,
        recommendedOrganizationalUnitId: recommendedOrganizationalUnitId || undefined,
        ipStart,
        ipEnd,
        ipNotAvailable
      }),
      profileId
    })
    try {
      if (response.ok) {
        const devices = get(response, 'data.alerts', [])
        return {
          ...response,
          data: {
            ...response.data
          }
        }
      } else {
        return response
      }
    } catch (e) {
      return {
        ...response,
        ok: false,
        error: e
      }
    }
  }

  getDeviceTrackingNav = () => {
    return this.api.get('/devicetracking/devicelocation')
  }
}
