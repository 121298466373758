import { connect } from 'react-redux'
import ConfigureCard from './configure-card'
import UserSettingsActions from '../../Redux/UserSettingsRedux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    checkBoxClick: (card, checked) => dispatch(UserSettingsActions.changeCardVisibleState(card, checked))
  }
}

export default pipe(
  translate('ConfigureCard'),
  connect(
    null,
    mapDispatchToProps
  )
)(ConfigureCard)
