import { call, put, select, all } from 'redux-saga/effects'
import React from 'react'
import CounterActions from 'Redux/CounterRedux'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'

export function * getTotalCountRequest (api, { siteId: OrgUnitId }) {
  const response = yield call(api.getTotalCount, OrgUnitId)
  if (response.ok) {
    yield put(CounterActions.getTotalCountResponse(response.data))
  } else {
    yield put(CounterActions.getTotalCountResponse({}))
  }
}

export function * updateLimit (api, { siteId: OrgUnitId, limit }) {
  const response = yield call(api.updateLimit, OrgUnitId, limit)
  if (response.ok) {
    yield all([
      put(CounterActions.getTotalCountRequest(OrgUnitId)),
      call(toast, <SuccessToast message='Updated limit succesfully' />)
    ])
  } else {
    yield all([
      put(CounterActions.getTotalCountRequest(OrgUnitId)),
      call(toast, <ErrorToast message='Failed update to limit' />)
    ])
  }
}

export function * changeCurrentCount (api, { siteId: OrgUnitId, personsCount }) {
  const response = yield call(api.changeCurrentCount, OrgUnitId, personsCount)
  if (response.ok) {
    yield all([
      put(CounterActions.getTotalCountRequest(OrgUnitId)),
      call(toast, <SuccessToast message='Reseted current count succesfully' />)
    ])
  } else {
    yield all([
      put(CounterActions.getTotalCountRequest(OrgUnitId)),
      call(toast, <ErrorToast message='Failed reset' />)
    ])
  }
}
