import { createSelector, createStructuredSelector } from 'reselect'
import { userData } from 'Selectors/UserSelector'
import { getUsertTimeZone, FREQUENCIES } from './NewReportForm.helpers'
import Inmutable from 'seamless-immutable'
import moment from 'moment'
import { get, find } from 'lodash'
import { getTimeZonedHourFromUtcHour, getUtcHourFromMsAndTimeZone } from '../../preferences-email.helpers'
import { selectedOrgId } from 'Selectors/LoaderSelector'

export const alertsData = ({ preferences }) => preferences.getIn(['alertsData'], {})

export const alerts = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['alerts'], [])
)

export const sites = ({ siteManager }) =>
  siteManager.getIn(['rootSitesById'], [])

export const isAlertsEnabled = createSelector(
  alertsData,
  alertsData => alertsData.getIn(['enabled'], false)
)

export const categories = createSelector(
  alerts,
  (alerts) => Array.from(new Set(alerts.map(alert => alert.deviceType)))
)

export const selectedReportId = ({ preferences }) => preferences.getIn(['selectedReportId'], null)

export const scheduledReports = ({ preferences }) => preferences.getIn(['scheduledReports'], [])

export const reportNames = createSelector(
  [scheduledReports],
  reports => reports.map(report => report.name)
)

export const userId = createSelector(
  userData,
  user => user.getIn(['id'], '')
)

export const getPreferencesEmailForm = createSelector(
  [userData, selectedReportId, scheduledReports, reportNames],
  (_user, _selectedReportId, _scheduledReports, _reportNames) => {
    const form = {
      userId: _user.getIn(['id']),
      confTimeZone: getUsertTimeZone(),
      reportNames: _reportNames || []
    }
    if (_selectedReportId) {
      const report = get(_scheduledReports.filter(report => report.id === _selectedReportId), [0], null)
      if (report) {
        form.id = _selectedReportId
        fillFormInitialData(report, form)
      }
    }
    return Inmutable(form)
  }
)

export const fillFormInitialData = (report, form) => {
  form.confName = report.name
  form.confTimeZone = report.timeZone
  form.confSites = report.orgUnitIds
  form.confReceiveDaysStart = getUtcHourFromMsAndTimeZone(report.startDate.seconds * 1000, report.timeZone)
  form.confReceiveDaysTime = report.notificationHour
  form.confReceiveFrequency = report.type
  form.editing = true

  if (report.type === FREQUENCIES.WEEKLY) {
    form.confWeekDaySelector = get(report, ['daysOfWeek', 0], 0)
  }
  if (report.type === FREQUENCIES.MONTHLY) {
    form.confMonthDaySelector = report.dayOfMonth
  }
}

export const selectedFrequency = ({ form }) =>
  get(form, ['preferencesEmailForm', 'values', 'confReceiveFrequency'], 0)

export const loading = ({ preferences }) => preferences.getIn(['loading'], false)

export const selectedReport = createSelector(
  selectedReportId,
  scheduledReports,
  (reportId, reports) => get(reports.filter(report => report.id === reportId), '0', Inmutable({}))
)

export const reportName = createSelector(
  selectedReport,
  report => get(report, 'name', '')
)

export const selectedSites = createSelector(
  selectedReport,
  report => get(report, 'orgUnitIds', [])
)

export const defaultAlerts = createSelector(
  alertsData,
  alerts => get(alerts, 'alerts', []).map(alert => ({
    ...alert,
    enabled: false,
    notificationEmail: false
  }))
)

export const notifications = createSelector(
  selectedReport,
  defaultAlerts,
  (report, defaultAlerts) => {
    const settings = get(report, 'settings', defaultAlerts)
    let mergedAlerts = []
    if (settings.length > 0) {
      mergedAlerts = settings.map(value => {
        const defaultAlert = find(defaultAlerts, alrt => alrt.id === value.id)
        return ({
          id: value.id,
          description: defaultAlert.description,
          enabled: value.enabled,
          deviceType: defaultAlert.deviceType,
          subCategory: defaultAlert.subCategory,
          notificationEmail: value.notificationEmail,
          isOrgSetting: value.isOrgSetting
        })
      })
    }
    return settings.length > 0 ? mergedAlerts : defaultAlerts
  }
)

export const filteredAlerts = createSelector(
  notifications,
  (alerts) => alerts.reduce((acum, currentAlert) => {
    acum[currentAlert.deviceType] = [...get(acum, `${currentAlert.deviceType}`, []), currentAlert]
    return acum
  }, {})
)

export const timeZone = createSelector(
  selectedReport,
  (report) => get(report, 'timeZone', getUsertTimeZone())
)

export const frequencyType = createSelector(
  selectedReport,
  (report) => get(report, 'type', 0)
)

export const startDate = createSelector(
  selectedReport,
  timeZone,
  (report, timeZone) => getUtcHourFromMsAndTimeZone(get(report, 'startDate.seconds', moment().unix()) * 1000, timeZone)
)

export const dayOfMonth = createSelector(
  selectedReport,
  (report) => get(report, 'dayOfMonth', null)
)

export const daysOfWeek = createSelector(
  selectedReport,
  (report) => get(report, 'daysOfWeek.0', null)
)

export const notificationHour = createSelector(
  selectedReport,
  (report) => get(report, 'notificationHour', null)
)

export const includeAssignedUserInfo = createSelector(
  selectedReport,
  (report) => get(report, 'includeAssignedUserInfo', false)
)

export const theme = ({ user }) => user.getIn(['theme'], {})

const subscriptionFeatures = ({ user }) => user.subscription?.features

export default createStructuredSelector({
  sites,
  initialValues: getPreferencesEmailForm,
  getUsertTimeZone,
  selectedFrequency,
  selectedReportId,
  loading,
  isAlertsEnabled,
  initialReportName: reportName,
  initialSelectedSites: selectedSites,
  initialNotifications: notifications,
  initialTimeZone: timeZone,
  initialFrequencyType: frequencyType,
  initialStartDate: startDate,
  initialDayOfMonth: dayOfMonth,
  initialDaysOfWeek: daysOfWeek,
  initialNotificationHour: notificationHour,
  initialFilteredAlerts: filteredAlerts,
  initialIncludeAssignedUserInfo: includeAssignedUserInfo,
  selectedReport,
  categories,
  alerts,
  userId,
  theme,
  featureList: subscriptionFeatures,
  selectedOrgId
})
