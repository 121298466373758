import styled from 'styled-components/macro'
import { Select, Icon, Progress } from '@scuf/common'

const Container = styled.div`
display: flex;
flex:1;
flex-direction: column;
height:  calc(80vh - 8rem);
`
const Col = styled.div`
  display: flex;
flex: ${props => props.flex ? props.flex : 1};
flex-direction: column;
align-self: flex-start;
margin: 0 10px 0 10px;
`
const PaddedContainer = styled.section`
@media (max-height: 950px){
  overflow-y: auto;
  height: calc(80vh - 9.5rem);
}
height:  100%;
`
const Row = styled.div`
  display: flex;
flex: ${props => props.flex ? props.flex : 1};
flex-direction: row;
justify-content: ${ ({ justifyContent }) => justifyContent || ''}
`
const AddIcon = styled(Icon)`
cursor: pointer;
`

const BoldError = styled.b`
color: #be271c;
`
const ProvisionProgressBar = styled(Progress)`
visibility:${props => props.isvisible ? '' : 'hidden'};
width: 100%;
max-height: 0.5rem;
`
const LineProgressBar = styled(Progress)`
.ui.progress:last-child{
  margin: 0px !important
}
`
const InputLabelHelper = styled.p`
font-size: 0.8rem;
color: #a0a0a0;
margin: 2px !important;
`

const StyledSelect = styled(Select)`
&.ui.scuf-dropdown-wrapper.fluid{
  z-index:99;
}
`
const TableTitle = styled.span`
  font-weight: 700;
  padding-bottom: 0.5rem;
`

const FileDropContainer = styled.div`

`

StyledSelect.displayName = 'StyledSelect'

export {
  FileDropContainer,
  Container,
  Col,
  Row,
  PaddedContainer,
  AddIcon,
  BoldError,
  ProvisionProgressBar,
  LineProgressBar,
  StyledSelect,
  InputLabelHelper,
  TableTitle
}
