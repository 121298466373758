import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'i18n/translate.helpers'
import { reduxForm } from 'redux-form'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import ModalActions from '../../Redux/ModalRedux'
import UpdatesActions from '../../Redux/UpdatesRedux'
import ScheduleModal from './schedule-modal'
import mapStateToProps from './schedule-modal.selector'

export const validate = (form) => {
  return Object.keys(form).reduce((errors, asset) => {
    if (asset !== 'Tags' && asset !== 'ScheduleTime') {
      const fields = form[asset]
      if (Object.prototype.hasOwnProperty.call(fields, 'drive') && !fields.drive) {
        errors[asset] = {
          ...errors[asset],
          drive: 'Drive is required'
        }
      }
      if (Object.prototype.hasOwnProperty.call(fields, 'fontId') && !fields.fontId) {
        errors[asset] = {
          ...errors[asset],
          fontId: 'Font Id is required'
        }
      }
    }
    return errors
  }, {})
}

const mapDispatchToProps = dispatch => {
  return {
    modalClose: () => dispatch(ModalActions.modalClose()),
    onSchedule: datetime => dispatch(UpdatesActions.updatesRequestUpdate(datetime)),
    onSubmit: form => dispatch(UpdatesActions.updatesUpdateAllRequest(form)),
    onSubmitFromFile: (form, file) => dispatch(UpdatesActions.updatesUpdateFromFileRequest(form, file)),
    loadDeviceList: updates => dispatch(UpdatesActions.loadDevicesForAssets(updates))
  }
}

export default pipe(
  translate('ScheduleUpdateModal'),
  reduxForm({
    form: 'softwareUpdateWizard',
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLDConsumer()
)(ScheduleModal)
