import { connect } from 'react-redux'

import { tagInformationToProps } from './tag.selector'
import { TaggingActionCreators } from 'Store/tagging'
import Tag from './tag'

export const mapDispatchToProps = (dispatch, { id }) => {
  return {
    onClick: () => dispatch(TaggingActionCreators.toggleSelection(id))
  }
}

export default connect(
  tagInformationToProps,
  mapDispatchToProps
)(Tag)
