import React, { useState, useCallback } from 'react'
import {
  CollapseContainer,
  ChildrenContainer,
  Button,
  StyledIcon,
  MainContainer,
  StyledCheckbox,
  CheckboxContainer,
  DropContainer
} from './CollapsePreferences.styles'

const Collapse = ({
  children,
  category,
  alertInfo,
  saveAlerts,
  selectedOrgId,
  tabType,
  t,
  enabledChecked,
  typeAlert = false,
  theme,
  hasFeatureAccess
}) => {
  const [select, setSelect] = useState(false)
  const enabled = alertInfo.filter(alert => alert[tabType]).length > 0
  const features = alertInfo.map(alert => hasFeatureAccess(alert.deviceType, alert.subCategory))
  const selectAllEnabled = features.every(f => f === true)

  const onChangeCheckbox = useCallback((value) => {
    const alertList = alertInfo.map(alert => ({
      ruleId: alert.id,
      organizationId: selectedOrgId,
      enabled: tabType === 'enabled' ? value : alert.enabled,
      notificationEmail: tabType === 'notificationEmail' ? value : alert.notificationEmail,
      subCategory: alert.subCategory,
      deviceType: alert.deviceType
    })).filter(alert => hasFeatureAccess(alert.deviceType, alert.subCategory))
    saveAlerts(alertList)
  }, [alertInfo])

  return (
    <MainContainer select={select}>
      <DropContainer>
      <CheckboxContainer>
        {selectAllEnabled &&
          <StyledCheckbox
            checked={enabled}
            disabled={!enabledChecked && typeAlert}
            onChange={onChangeCheckbox}
          />
        }
      </CheckboxContainer>
      <CollapseContainer>
        <Button type='button' onClick={() => setSelect(!select)}>
          {t(`${category}`)}
          <StyledIcon
            name={select ? 'caret-up' : 'caret-down'}
            size='small'
          />
        </Button>
      </CollapseContainer>
      </DropContainer>
      <ChildrenContainer>{select && children}</ChildrenContainer>
    </MainContainer>
  )
}

export default Collapse
