import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import Excel, { CellValue } from 'exceljs'

export interface ICsvFileDropHookProps {
  sampleData?: string[][],
  onLoadFile?: (file?: File) => any,
  onLoadFileError?: () => any,
  onLoadFileSuccess: (data: Record<string, string>[]) => any,
  fileHeadersCheck?: any
}

export function useCsvFileDrop ({
  sampleData,
  onLoadFile = () => {},
  onLoadFileError = () => {},
  onLoadFileSuccess,
  fileHeadersCheck
}: ICsvFileDropHookProps) {
  const [isProcessing, setIsProcessing] = useState(false)
  const [uploadedFileIsEmpty, setUploadedFileIsEmpty] = useState(false)

  const sampleCsvFileUrl = useMemo(() => {
    if (sampleData) {
      return 'data:text/csv;charset=utf-8,' + encodeURI(parseSampleDataToCsvFormat(sampleData))
    }

    return 'data:text/csv;charset=utf-8,'
  }, [sampleData])

  const fileReaderOnError = useCallback((event: ProgressEvent<FileReader>) => {
    if (event?.target?.error?.name === 'NotReadableError') {
      toast(<ErrorToast message='Error reading csv file' />, { containerId: 'MODAL_TOAST_CONTAINER' })
    } else if (event?.target?.error) {
      toast(<ErrorToast message={event.target.error} />, { containerId: 'MODAL_TOAST_CONTAINER' })
    }
    onLoadFileError()
  }, [onLoadFileError])

  const fileReaderOnLoad = useCallback(async (event: ProgressEvent<FileReader>) => {
    if (event?.target?.result == null) {
      fileReaderOnError(event)
      return
    }

    try {
      setIsProcessing(true)
      const data = event.target.result instanceof ArrayBuffer
        ? await processXlsxFile(event.target.result)
        : processCsvFile(event.target.result)

      if (data.length === 0) {
        setUploadedFileIsEmpty(true)
      } else {
        if(fileHeadersCheck && (JSON.stringify(Object.keys(data[0])) !== JSON.stringify(["Device Type", "Device Model", "Serial Number", "Alias", "Site"]))) {
          const error = { target: { error: 'file structure error ' } }
            throw error
        }

        setUploadedFileIsEmpty(false)
        onLoadFileSuccess(data)

      }
    } catch (e) {
      fileReaderOnError(e)
    } finally {
      setIsProcessing(false)
    }
  }, [onLoadFileSuccess, fileReaderOnError])

  const processFile = useCallback((file?: File) => {
    onLoadFile(file)
    const reader = new FileReader()
    reader.onload = fileReaderOnLoad
    reader.onerror = fileReaderOnError

    if (file) {
      const fileParts = file.name.split('.')
      const fileType = fileParts.length > 0 ? fileParts[fileParts.length - 1] : ''

      if (fileType === 'csv') {
        reader.readAsText(file)
      } else if (fileType === 'xlsx') {
        reader.readAsArrayBuffer(file)
      }
    }
  }, [onLoadFile, fileReaderOnLoad, fileReaderOnError])

  return {
    isProcessing,
    uploadedFileIsEmpty,
    processFile,
    sampleCsvFileUrl
  }
}

function parseSampleDataToCsvFormat (data: string[][]): string {
  try {
    const headers: string[] = []
    let csvText = ''
    data.forEach(column => {
      if (column.length > 0) {
        headers.push(column[0])
      } else {
        headers.push('NA')
      }
    })
    csvText += headers.join(',') + '\n'
    let row = []
    for (let i = 1; i < data[0].length; i++) {
      row = []
      for (let j = 0; j < data.length; j++) {
        row.push(data[j][i])
      }
      csvText += row.join(',') + '\n'
    }
    return csvText
  } catch (e) {
    toast(<ErrorToast message='Error creating sample file' />, { containerId: 'MODAL_TOAST_CONTAINER' })
    return ''
  }
}

async function processXlsxFile (input: ArrayBuffer): Promise<Record<string, string>[]> {
  const error = { target: { error: 'file structure error ' } }
  const workbookReader = new Excel.Workbook()
  const file = await workbookReader.xlsx.load(input)
  if (file.worksheets.length < 1) {
    throw error
  }
  const worksheet = file.getWorksheet(1)
  if (worksheet.rowCount === 0 || worksheet.columnCount === 0) {
    throw error
  }

  const headers = (worksheet.getRow(1)?.values || []) as CellValue[]
  const result: Record<string, string>[] = []
  worksheet.eachRow((row, rowIdx) => {
    // Skip header
    if (rowIdx <= 1) return

    const lineData: Record<string, string> = {}
    headers.forEach((column, i) => {
      if (column != null && i > 0) {
        lineData[column.toString()] = row.getCell(i).toString()
      }
    })
    result.push(lineData)
  })
  return result
}

function processCsvFile (input: string): Record<string, string>[] {
  const result: Record<string, string>[] = []

  // eslint-disable-next-line prefer-const
  let [headerLine, ...lines] = input.split(/\r\n|\n/)
  lines = lines.filter(line => line !== '')

  const headers = headerLine.split(',')
  lines.forEach(line => {
    const lineCells = line.split(',')
    const lineData: Record<string, string> = {}
    headers.forEach((column, i) => {
      lineData[column] = lineCells[i]
    })
    result.push(lineData)
  })
  return result
}
