import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

export const INITIAL_STATE = Immutable({
  reportId: '',
  groupId: '',
  reportToken: '',
  loading: false,
  error: '',
  deviceTypes: []
})

const { Types, Creators } = createActions({
  bireportGetReportToken: ['site', 'deviceType'],
  bireportTokenSuccess: ['data'],
  bireportTokenFailure: null,
  bireportCleanData: null,
  bireportDeviceTypeBySite: ['site'],
  bireportDeviceTypeBySiteFailure: null,
  bireportDeviceTypeBySiteSuccess: ['data']
})
export const BiReportTypes = Types
export default Creators

export const getReportToken = (state) => state.merge({ loading: true, reportId: '', groupId: '', reportToken: '' })

export const getReportTokenFailure = (state) => state.merge({ error: 'BiReportPage_Error', loading: false })

export const getReportTokenSucess = (state, { data }) => state.merge({
  reportId: data.reportId,
  groupId: data.groupId,
  reportToken: data.reportToken,
  loading: false
})

export const cleanData = (state) => state.merge({ reportId: '', groupId: '', reportToken: '' })

export const getDeviceTypeBySiteRequest = (state) => state.merge({ deviceTypes: [] })

export const getDeviceTypeBySiteFailure = (state) => state.merge({ error: 'Error finding device types' })

export const getDeviceTypeBySiteSuccess = (state, { data }) => state.merge({ deviceTypes: data.deviceTypes })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BIREPORT_GET_REPORT_TOKEN]: getReportToken,
  [Types.BIREPORT_TOKEN_SUCCESS]: getReportTokenSucess,
  [Types.BIREPORT_TOKEN_FAILURE]: getReportTokenFailure,
  [Types.BIREPORT_CLEAN_DATA]: cleanData,
  [Types.BIREPORT_DEVICE_TYPE_BY_SITE]: getDeviceTypeBySiteRequest,
  [Types.BIREPORT_DEVICE_TYPE_BY_SITE_FAILURE]: getDeviceTypeBySiteFailure,
  [Types.BIREPORT_DEVICE_TYPE_BY_SITE_SUCCESS]: getDeviceTypeBySiteSuccess
  
})
