import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'

export const daysList = ({ dashboard }) =>
  dashboard.getIn(['hardRebootsCard', 'days'], [])

export const data = createSelector(
  daysList,
  (daysList) => {
    const hardRebootsByDevice = {}
    const softRebootsByDevice = {}
    daysList.forEach(value => {
      value.getIn(['reboots'], []).forEach(result => {
        hardRebootsByDevice[result.serialNumber] = { hardReboots: get(hardRebootsByDevice, `${result.serialNumber}.hardReboots`, 0) + result.hardReboots }
        softRebootsByDevice[result.serialNumber] = { softReboots: get(softRebootsByDevice, `${result.serialNumber}.softReboots`, 0) + result.softReboots }
      })
    })
    return Object.keys(hardRebootsByDevice).map(value => {
      return {
        serialNumber: value,
        hardReboots: hardRebootsByDevice[value].hardReboots,
        softReboots: softRebootsByDevice[value].softReboots
      }
    })
  }
)

export default createStructuredSelector({
  data
})
