import memoize from 'memoize-one'
import momentTz from 'moment-timezone'

import { todayDate } from '../../../../Utils'

export const getTimeZonedHourFromUtcHour = memoize(hour => {
  const builtDate = `${todayDate('YYYY-MM-DD')} ${hour < 10 ? '0' : ''}${hour}:00`
  const localTimeZone = getUsertTimeZone()
  return momentTz.utc(builtDate).tz(localTimeZone)
})

export const getUtcHourFromMsAndTimeZone = memoize((dateMs, timeZone) => {
  return momentTz.tz(dateMs, timeZone).toDate()
})

export const getUsertTimeZone = () => {
  return momentTz.tz.guess(true)
}
