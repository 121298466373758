
import React, { Component } from 'react'
import { Button } from '@scuf/common'
import { isEmpty, reduce, assoc, toPairs } from 'ramda'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import SiteEditTable from './components/SiteEditTable'
import TableContainer from './components/TableContainer'
import Excel from 'exceljs'
import FileSaver from 'file-saver'
import { get } from 'lodash'
import DropFile from './components/UploadFile'
import {
  Container,
  Col,
  Row,
  PaddedContainer,
  FileDropContainer
} from './sites-bulk-edit.styles'

class FormUploadDevices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingCsv: false
    }
    this.downloadFile = this.downloadFile.bind(this)
  }

  componentDidMount() {
    if (isEmpty(this.props.sites)) {
      this.props.getSites()
    }
  }

  getErrors() {
    return this.props.data
      .reduce((res, line) => res + (toPairs(line).filter(([_, val]) => !!val.error).length > 0 ? 1 : 0), 0)
  }

  /**
   * Create a template file with xlsx extension with the following columns:
   * Device serial Number
   * Device alias
   * Device site
   */
  async downloadFile() {
    const workbook = new Excel.Workbook()
    const { devices } = this.props
    workbook.creator = 'OperationalIntelligence'
    const bulkEditSheet = workbook.addWorksheet('Device to Update')
    const sitesSheet = workbook.addWorksheet('sites')
    const deviceSheet = workbook.addWorksheet('devices')

    sitesSheet.getColumn(1).values = this.props.sitesList
    deviceSheet.getColumn(1).values = Object.keys(this.props.devices)

    bulkEditSheet.columns = [
      { header: 'DeviceSerialNumber', key: 'DeviceSerialNumber', width: 30 },
      { header: 'NewDeviceAlias', key: 'NewDeviceAlias', width: 30 },
      { header: 'NewDeviceSite', key: 'NewDeviceSite', width: 30 }
    ]
    bulkEditSheet.state = 'visible'
    Object.keys(devices).slice(0, 10)
      .forEach((serial, index) => {
        const row = index + 2
        const device = devices[serial]
        bulkEditSheet.getCell(`C${row}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['sites!$A:$A']
        }
        bulkEditSheet.getCell(`A${row}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['devices!$A:$A']
        }
        bulkEditSheet.getCell(`C${row}`).value = get(this.props.sites, [device.site, 'organizationalUnitHierarchy'])
        bulkEditSheet.getCell(`A${row}`).value = serial
        bulkEditSheet.getCell(`B${row}`).value = device.alias
      })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data = new Blob([buffer], { type: fileType })
    FileSaver.saveAs(data, 'Operational Intelligence Bulk Device Edit Template.xlsx')
  }

  render() {

    const { t, data, mapSiteNameToSiteId, onSubmit, loading } = this.props
    const errors = this.getErrors()
    return (<Container>
      {
        <PaddedContainer>
          <Row >
            <Col flex={1} style={{ flexWrap: 'wrap' }}>
              <Row >
                <Col>
                  <TableContainer loading={loading} >
                    {
                      data.length <= 0 ?
                        <FileDropContainer>
                          <DropFile
                            onClick={this.downloadFile}
                            onLoadFile={() => { }}
                            onLoadFileSuccess={(lines) => {
                              const mappedLines = lines.map(line =>
                                reduce(
                                  (acc, prop) => assoc(prop, { value: [prop], error: '' }, acc),
                                  {},
                                  Object.keys(line)
                                )
                              ).map(line => {
                                const siteName = get(line, ['NewDeviceSite', 'value'], 'NA')
                                return {
                                  ...line,
                                  siteId: {
                                    value: get(mapSiteNameToSiteId, [siteName], ''),
                                    error: get(mapSiteNameToSiteId, [siteName], '') ? '' : 'Not found'
                                  }
                                }
                              })
                              this.props.updateBulkEditData(mappedLines)
                            }}
                          />
                        </FileDropContainer> :
                        <SiteEditTable
                          data={data}
                        />
                    }
                  </TableContainer>
                </Col>
              </Row>
              {
                data.length > 0 ?
                  <Row style={{ marginTop: '1rem' }} >
                    <Col flex={2} style={{ alignSelf: 'flex-end' }}>
                      <Row justifyContent='space-between' >
                        <p>{errors > 0 ? `${errors} line(s) with errors` : ''}</p>
                        <div>
                          <Button
                            content='Refresh'
                            disabled={data.length <= 0}
                            id='removeall'
                            type='secondary'

                            onClick={() => {
                              this.props.updateBulkEditData([])
                            }} />
                          <Button
                            disabled={
                              errors > 0 || data.length <= 0
                            }
                            type='primary'
                            id='submit'
                            content='submit'
                            onClick={() => {
                              onSubmit(data)
                            }}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  : null
              }
            </Col>
          </Row>
        </PaddedContainer>
      }
    </Container>)
  }
}

FormUploadDevices.displayName = 'FormUploadDevices'

FormUploadDevices.propTypes = {
  sites: PropTypes.object,
  getSites: PropTypes.func,
  sitesList: PropTypes.array,
  updateBulkEditData: PropTypes.func,
  data: PropTypes.array,
  devices: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  mapSiteNameToSiteId: PropTypes.object
}

FormUploadDevices.defaultProps = {
  devices: [],
  errors: [],
  loading: false,
  sites: []
}

export default FormUploadDevices
