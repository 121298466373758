import { call, put, select, all } from 'redux-saga/effects'
import LogActions from '../Redux/LogRedux'

export function * getActivityLogReport (api, { filters: { startDate, endDate, actionType, userId, siteId }, pageNumber, pageSize }) {
  const response = yield call(api.activityLogReport, { startDate, endDate, type: actionType, userId, siteId, pageNumber, pageSize })
  if (response.ok) {
    yield put(LogActions.logResponseActivityLog(response.data))
    yield put(LogActions.logGenericResponse())
  } else {
    yield put(LogActions.logResponseActivityLog([]))
    yield put(LogActions.logGenericResponse())
  }
}

export function * getAditionalActivityLogReport (api, { filters: { startDate, endDate, actionType, userId, siteId }, pageNumber, pageSize }) {
  const response = yield call(api.activityLogReport, { startDate, endDate, type: actionType, userId, siteId, pageNumber, pageSize })
  if (response.ok) {
    yield put(LogActions.logResponseAditionalActivityLog(response.data))
    yield put(LogActions.logGenericResponse())
  } else {
    yield put(LogActions.logResponseAditionalActivityLog([]))
    yield put(LogActions.logGenericResponse())
  }
}
