import React, { useState, useEffect } from 'react'

import DataTable from 'Components/DataTable'

import { TableContainer, Description } from './UnreachedDevices.styles'
import { getColumns } from './UnreachedDevices.constants'

const UnreachedDevices = ({
    columns = [],
    data = [],
    loading = false,
    pageSize = 0,
    total = 0,
    setPageNumber = () => {},
    setSearchTerm = () => {},
    t = () => {}
}) => {
    return(
        <TableContainer id='unreachedDevices' disabled={loading} >
            <Description>{t('UnreachedDescription')}</Description>
            <DataTable
                data={data}
                rows={pageSize}
                rowHeight={32}
                totalRows={total}
                search={true}
                resizableColumns={true}
                columnResizeMode='expand'
                searchPlaceholder={t('SearchUnreached')}
                loadNextPage={setPageNumber}
                isLoadingPage={loading}
                onSearchChange={setSearchTerm}
            >
                {columns.map((column) => (
                    <DataTable.Column
                        dataKey={column.dataKey}
                        cellRenderer={column.render}
                        label={column.translation}
                        align='center'
                        disableSort
                    />
                ))}
            </DataTable>
        </TableContainer>
    )
}

export default ({
    data = [],
    loading = false,
    pageSize = 0,
    total = 0,
    setPageNumber = () => {},
    setSearchTerm = () => {},
    t = () => {}
}) => {

    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns(getColumns(t))
    },[])

    const props = {
        columns,
        data,
        loading,
        pageSize,
        total,
        setPageNumber,
        setSearchTerm,
        t
    }

    return <UnreachedDevices {...props} />
}