import React, { memo } from 'react'
import { Button } from '@scuf/common'
import { QrFooterContainer } from '../../qr-section.styles'
import {
  TermsContainer,
  TermsText,
  TermsSubtitle
} from './qr-terms-section.styles'

const QrTerms = memo(({ t, onBackClick }) => (
  <TermsContainer>
    <TermsSubtitle>
      {t('TermsTitle')}
    </TermsSubtitle>
    <TermsText>
      {t('Terms')}
    </TermsText>
    <QrFooterContainer justifyContent='flex-end'>
      <Button
        content={t('Back')}
        type='secondary'
        onClick={onBackClick}
      />
    </QrFooterContainer>
  </TermsContainer>
))

export default QrTerms
