import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'
import ModalActions from '../../Redux/ModalRedux'
import VersionSupport from './version-support-modal'
import VersionSupportSelector from './version-support-modal.selector'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('VersionSupportModal'),
  connect(
    VersionSupportSelector,
    mapDispatchToProps
  )
)(VersionSupport)
