import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  checkOutDevices: ['information', 'deviceIds'],
  checkInDevices: ['deviceChanges'],
  transferDevices: ['userId', 'deviceIds']
}, {
  prefix: '@CHECK_IN_OUT/'
})

export const CheckInOutActionTypes = Types
export const CheckInOutActionCreators = Creators
