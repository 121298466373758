import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

import Form from './form'
import { currentLocation } from '../../../Location/components/form/form.selectors'

const mapStateToProps = (state) => {
  return {
    ...currentLocation(state)
  }
}

export default pipe(
  translate('LocationForm'),
  connect(
    mapStateToProps
  )
)(Form)
