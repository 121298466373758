import get from 'lodash/get'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { makeGetSitePathSelector } from 'features/site-management/store'
import { adminRoles, deviceAdmin, orgAdmin } from 'Utils/constants'
import { getAllRoles, getSitesRoles } from 'Utils/roles/selectors'
import { isGlobalUser } from 'Selectors/PermissionsSelector'

export const caac = 1

const formIndexSelector = (_, { index }) => index

const makeGetSelectedSitePath = () => {
  const formValueSelector = getFormValues('userForm')
  const sitePathSelector = makeGetSitePathSelector()
  return (state, { index }) => {
    const formValues = formValueSelector(state)
    const selected = formValues?.organization?.organizationalUnits?.[index] ?? {}
    const selectedId = selected.id ? selected.id : selected.parent

    return sitePathSelector(state, selectedId)
      .map(site => site.id)
  }
}

const currentUsersOrgRolesSelector = ({ user }) => (user?.userData?.assetAssociation ?? []).flatMap(asset => {
  const childAssets = asset.organizationalUnitAssociation.map(childAsset => {
    if (childAsset.roles !== null) {
      return {
        roleIds: childAsset.roles.map(r => r.guid),
        siteId: childAsset.organizationalUnitIdentifier.organizationalUnitGuid
      }
    }
  })

  if (asset.roles !== null) {
    return [
      {
        roleIds: asset.roles.map(r => r.guid),
        siteId: asset.organizationIdentifier.organizationGuid
      },
      ...childAssets
    ]
  }
  return childAssets
})

const getRolesInfo = (role) => ({ text: role.name, value: role.guid })

export const makeGetRolesAsOptions = () => {
  const selectedSitePathSelector = makeGetSelectedSitePath()

  return createSelector(
    getAllRoles,
    getSitesRoles,
    getFormValues('userForm'),
    selectedSitePathSelector,
    formIndexSelector,
    currentUsersOrgRolesSelector,
    (_, props) => props.initialValues,
    isGlobalUser,
    (roles, sites, formValues, path, formIndex, currentUsersOrgRoles, initialValues, isGlobalUser) => {
      const assignedParentRoles = get(formValues, 'organization.organizationalUnits', [])
        .filter(org => path.includes(org.id ? org.id : org.parent))
        .map(org => org.role)

      const userRoleMatches = currentUsersOrgRoles
        .filter(orgRole => path.includes(orgRole.siteId))
        .map(orgRole => orgRole.roleIds)
        .flat()

      const initialRole = initialValues?.organization?.organizationalUnits?.[formIndex]?.role

      const organizationId = get(formValues, `organization.organizationalUnits.${formIndex}.parent`, null)

      const genericRoles = Object.values(roles?.['generic'] ?? {}).reduce((res, role) => {
        if (isGlobalUser) res.push(getRolesInfo(role))
        else if (initialRole === role.guid) res.push(getRolesInfo(role))
        else if (!adminRoles.includes(role.guid)) res.push(getRolesInfo(role))
        else if (adminRoles.includes(role.guid) && userRoleMatches.includes(orgAdmin)) res.push(getRolesInfo(role))
        return res
      }, [])

      const customRoles = Object.values(sites?.[organizationId] ?? [])
        .map(roleId => {
          const role = roles?.['custom']?.[roleId] ?? {}
          return getRolesInfo(role)
        })

      if (organizationId) {
        return [
          ...genericRoles,
          ...customRoles
        ]
      } else {
        return genericRoles
      }
    }
  )
}
