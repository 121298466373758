import { useTranslate } from 'react-translate'
 
import { Container,
  SubContainerNotAvailable,
  HeaderContainerNotAvailable,
  ContentContainerNotAvailable,
  CardContainer
} from './locked-card-alerts.styles'
 

import React from 'react'

const LockedCardAlerts = ({
  innerCard,
  alertInfo,
  type
}) => {
  const t = useTranslate('Locked')
  return (
    <Container style={{ position: 'relative' }}>
      <div>
       {innerCard}
       </div>
      <SubContainerNotAvailable>
        <HeaderContainerNotAvailable>
          {t('LockedCard_Header')}
        </HeaderContainerNotAvailable>
        <ContentContainerNotAvailable>
          {t('LockedCard_Content')}
        </ContentContainerNotAvailable>
        <ContentContainerNotAvailable>
          {t('LockedCard_Footer')}
        </ContentContainerNotAvailable>
      </SubContainerNotAvailable>
    </Container>
  )
}

export default LockedCardAlerts
