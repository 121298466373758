import AccessPoints from './access-points'
import { pipe } from 'ramda'
import { translate } from 'i18n/translate.helpers'
import { connect } from 'react-redux'
import mapStateToProps from './access-points.selector'
import { withPermissions } from 'Utils/permissions'

export default pipe(
  translate('AccessPoints'),
  connect(
    mapStateToProps
  ),
  withPermissions((_, props) => props.siteId)
)(AccessPoints)
