import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-translate'

import AddLicensesBanner from './AddLicensesBanner'

export default pipe(
  withLDConsumer()
)(AddLicensesBanner)
