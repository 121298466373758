
import moment from 'moment'
import { call, put, all, delay, retry, select, fork, cancel, take, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import DeviceActions from 'Redux/DeviceRedux'
import LocationActions from 'Redux/LocationRedux'
import { LocationApi } from 'Services'
import chunk from 'lodash/chunk'
import React from 'react'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import DashboardActions from 'Redux/DashboardRedux'

import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'
import { CheckInOutActionCreators } from '../Store/check-in-out'
import AppConfig from 'Config/AppConfig'

export function* getDevices(api, action) {
  const response = yield call(api.devices)
  if (response.ok) {
    yield put(DeviceActions.deviceSuccess(response.data))
  } else {
    yield put(DeviceActions.deviceGenericStop())
  }
}

export function* getDevice(api, { currentDevice }) {
  try {
    const response = yield call(api.getDeviceById, currentDevice)
    if (response.ok && response.data) {
      const [location] = yield all([
        call(LocationApi.getDeviceLocation, { deviceId: currentDevice }),
        put(DeviceActions.deviceSuccess({
          devices: [{
            ...response.data
          }]
        }))
      ])
      if (location.ok && location.data && location.data.length > 0) {
        yield put(DeviceActions.deviceSuccess({
          devices: [{
            ...response.data,
            location: location.data[0]
          }]
        }))
      }
    } else {
      yield put(DeviceActions.deviceGenericStop())
    }
  } catch (error) {
    yield put(DeviceActions.deviceGenericStop())
  }
}

export function* getGateway(api, { currentDevice }) {
  const response = yield call(api.getGatewayById, currentDevice)
  if (response.ok) {
    yield put(DeviceActions.deviceSuccess([response.data]))
  } else {
    yield put(DeviceActions.deviceGenericStop())
  }
}

export function* getDevicesCount(api, action) {
  yield all([
    call(getDeviceCount, api.devicesCount, { devicetype: 'mobilecomputer' }),
    call(getDeviceCount, api.devicesCount, { devicetype: 'printer' }),
    call(getDeviceCount, api.devicesCount, { devicetype: 'scanner' }),
    call(getDeviceCount, api.devicesCount, { devicetype: 'gateway' })
  ])
  yield put(DeviceActions.deviceGenericStop())
}

export function* getOnlineCount(api, action) {
  yield all([
    call(getOnlineCounter, api.devicesCount, { devicetype: 'mobilecomputer', status: 'connected' }),
    call(getOnlineCounter, api.devicesCount, { devicetype: 'printer', status: 'connected' }),
    call(getOnlineCounter, api.devicesCount, { devicetype: 'scanner', status: 'connected' }),
    call(getOnlineCounter, api.devicesCount, { devicetype: 'gateway', status: 'connected' })
  ])
  yield put(DeviceActions.deviceGenericStop())
}

export function* getDisconnectedCount(api, action) {
  yield all([
    call(getDisconnectedCounter, api.devicesCount, { devicetype: 'mobilecomputer', status: 'disconnected', since: '7d' }),
    call(getDisconnectedCounter, api.devicesCount, { devicetype: 'printer', status: 'disconnected', since: '7d' }),
    call(getDisconnectedCounter, api.devicesCount, { devicetype: 'scanner', status: 'disconnected', since: '7d' }),
    call(getDisconnectedCounter, api.devicesCount, { devicetype: 'gateway', status: 'disconnected', since: '7d' })
  ])
  yield put(DeviceActions.deviceGenericStop())
}

export function* getAllBatteriesHealth(api, action) {
  yield all([
    call(getBatteryHealth, api.getTelemetry, { pointid: 'battery.soh', devicetype: 'printer' }),
    call(getBatteryHealth, api.getTelemetry, { pointid: 'battery.soh', devicetype: 'mobilecomputer' }),
    call(getBatteryHealth, api.getTelemetry, { pointid: 'battery.soh', devicetype: 'scanner' })
  ])
  yield put(DeviceActions.deviceSuccessBattery())
}

export function* getAllBatteriesCycle(api, action) {
  yield all([
    call(getBatteryCycle, api.getTelemetry, { pointid: 'battery.cyclecount', devicetype: 'printer' }),
    call(getBatteryCycle, api.getTelemetry, { pointid: 'battery.cyclecount', devicetype: 'mobilecomputer' }),
    call(getBatteryCycle, api.getTelemetry, { pointid: 'battery.cyclecount', devicetype: 'scanner' })
  ])
  yield put(DeviceActions.deviceSuccessCycle())
}

export function* getAllAvgDrawn(api, action) {
  yield all([
    call(getAvgDrawn, api.getTelemetry, { pointid: 'battery.averagecurrent', devicetype: 'printer' }),
    call(getAvgDrawn, api.getTelemetry, { pointid: 'battery.averagecurrent', devicetype: 'mobilecomputer' })
  ])
  yield put(DeviceActions.deviceSuccessAverage())
}

export function* getDeviceCount(apiFunction, params = {}) {
  const [response] = yield all([
    call(apiFunction, params),
    put(DeviceActions.deviceRequestCounter(params))
  ])
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessCounter({ ...params, ...response.data }))
  }
}

export function* getOnlineCounter(apiFunction, params = {}) {
  const [response] = yield all([
    call(apiFunction, params),
    put(DeviceActions.deviceRequestCounter(params))
  ])
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessCounterOnline({ ...params, ...response.data }))
  }
}

export function* getDisconnectedCounter(apiFunction, params = {}) {
  const [response] = yield all([
    call(apiFunction, params),
    put(DeviceActions.deviceRequestCounter(params))
  ])
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessCounterDisconnected({ ...params, ...response.data }))
  }
}

export function* getBatteryHealth(apiFunction, params = {}) {
  const response = yield call(apiFunction, params)
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessBattery(response.data, params))
  }
}

export function* getBatteryCycle(apiFunction, params = {}) {
  const response = yield call(apiFunction, params)
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessCycle(response.data, params))
  }
}

export function* getAvgDrawn(apiFunction, params = {}) {
  const response = yield call(apiFunction, params)
  if (response.ok) {
    yield put(DeviceActions.deviceSuccessAverage(response.data, params))
  }
}

export function* getBatteriesDetails(api) {
  yield put(DeviceActions.deviceRequestGateways())
  yield all([
    put(DeviceActions.deviceRequestBattery()),
    put(DeviceActions.deviceRequestCycle()),
    put(DeviceActions.deviceRequestAverage())
  ])
}

export function* getLocationsByDeviceList({ devices }) {
  yield delay(100, 'foo')
}

export function* getGateways(api, action) {
  const { pageNumber, pageSize, deviceType } = action
  const response = yield call(api.getGateways, pageNumber, pageSize, deviceType)
  if (response.ok) {
    // NOTE: Was receiving null
    try {
      if (action.type !== 'DEVICE_REQUEST_REFRESH') {
        if (response.data.deviceGatewayList && response.data.deviceGatewayList.length) {
          yield all([
            put(DeviceActions.deviceSuccessGateways(response.data.deviceGatewayList)),
            call(getLocationsByDeviceList, response.data.deviceGatewayList)
          ])
        } else {
          yield all([
            put(DeviceActions.deviceSuccessGateways(response.data)),
            // put(LocationActions.locationRequestDevicesById(response.data))
          ])
        }
      } else {
        if (response.data.deviceGatewayList && response.data.deviceGatewayList.length) {
          yield put(DeviceActions.deviceRefreshSuccess(response.data.deviceGatewayList))
        } else {
          yield put(DeviceActions.deviceRefreshSuccess(response.data))
        }
      }
    } catch (e) {
      if (action.type !== 'DEVICE_REQUEST_REFRESH') {
        yield put(DeviceActions.deviceFailure())
      } else {
        yield put(DeviceActions.deviceRefreshFailure())
      }
    }
  } else {
    if (action.type !== 'DEVICE_REQUEST_REFRESH') {
      yield put(DeviceActions.deviceFailure())
    } else {
      yield put(DeviceActions.deviceRefreshFailure())
    }
  }
}

export function* getTelemetry(api, { id }) {
  const response = yield call(api.getDeviceTelemetryData, id)
  if (response.ok) {
    if (response.data && response.data.summary) {
      yield put(DeviceActions.deviceSuccessTelemetry(response.data.summary))
    } else {
      yield put(DeviceActions.deviceFailure())
    }
  } else {
    yield put(DeviceActions.deviceFailure())
  }
}

export function* updateDevices(api, { data }) {
  const response = yield call(api.updateDevices, data)
  if (response.ok) {
    yield all([
      put(DeviceActions.deviceUpdateSuccess()),
      call(toast, <SuccessToast />),
      put(DeviceActions.deviceRequestGateways())
    ])
  } else {
    yield ([
      put(DeviceActions.deviceUpdateFailure()),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* provision(api, { data, site }) {
  const responses = yield call(api.provisionDevice, data, site)
  if (responses.ok) {
  const succ = responses
  yield all([
    put(DeviceActions.deviceUploadProvisionRequestSuccess(succ))
  ])
    }
}

export function* getFamilies(api) {
  const res = yield call(api.getFamilies, true)
  if (res.ok) {
    const { response } = res.data
    yield put(DeviceActions.deviceFamiliesAndModelsRequestSuccess(response))
  } else {
    yield put(DeviceActions.deviceFamiliesAndModelsRequestFailure())
  }
}

export function* getPrinterMediaStatus(api, { id }) {
  try {
    const response = yield call(api.getPrinterMediaStatus, id)
    if (response.ok) {
      if (response.data && response.data.eventList) {
        yield put(DeviceActions.devicePrinterMediaStatusSuccess(response.data))
      } else {
        yield put(DeviceActions.devicePrinterMediaStatusFailure())
      }
    } else {
      yield put(DeviceActions.devicePrinterMediaStatusFailure())
    }
  } catch (_) {
    yield put(DeviceActions.devicePrinterMediaStatusFailure())
  }
}

export function* getPrinterRibbonStatus(api, { id }) {
  try {
    const response = yield call(api.getPrinterRibbonStatus, id)
    if (response.ok) {
      if (response.data && response.data.eventList) {
        yield put(DeviceActions.devicePrinterRibbonStatusSuccess(response.data))
      } else {
        yield put(DeviceActions.devicePrinterRibbonStatusFailure())
      }
    } else {
      yield put(DeviceActions.devicePrinterRibbonStatusFailure())
    }
  } catch (_) {
    yield put(DeviceActions.devicePrinterRibbonStatusFailure())
  }
}

export function* updateDeviceAlias(api, { data }) {
  try {
    const response = yield call(api.updateDeviceAlias, data)
    if (response.ok) {
      yield put(DeviceActions.updateDeviceAliasSuccess(response))
    } else {
      yield put(DeviceActions.updateDeviceAliasFailure())
    }
  } catch (_) {
    yield put(DeviceActions.updateDeviceAliasFailure())
  }
}

export function* getMoreDevices(api, { pageNumber, pageSize, deviceType, singlePage }) {
  const signal = CancelToken.source()
  try {
    const response = yield retry(3, 5000, api.getGateways, pageNumber, pageSize, deviceType, signal)
    if (response.ok && response.data) {
      if (deviceType === 'mobilecomputer') {
        yield put(LocationActions.locationRequestDevicesById(response.data))
      }
      const hasMorePages = pageNumber * pageSize < get(response, 'data.totalDevices', 0)
      yield put(DeviceActions.deviceSuccessGateways(response.data))

      if (hasMorePages && !singlePage) {
        yield put(DeviceActions.deviceBackgroundRequestAll(pageNumber + 1, pageSize, deviceType))
      }
    } else {
      yield put(DeviceActions.deviceFailure())
    }
  } catch (e) {
    yield put(DeviceActions.deviceFailure())
  } finally {
    if (yield cancelled()) {
      signal.cancel('Cancelled background load')
      yield put(DeviceActions.deviceFailure())
    }
  }
}

export function* getDevicesInBackground(api, action) {
  const getDevsTask = yield fork(getMoreDevices, api, action)
  while (true) {
    const cancelTask = yield take('DEVICE_BACKGROUND_CANCEL_ALL')
    if (getDevsTask && cancelTask) {
      yield cancel(getDevsTask)
    }
  }
}

export function* generateQr(api, action) {
  try {
    const options = yield select(state => state.devices.qrData)
    const user = yield select(state => state.login.userName)

    const data = {
      ...options,
      CustomerName: user
    }
    const { addMoreInformation } = options
    const response = yield call(AppConfig.tenant.qrData ? api.onboardDeviceQrSecondVersion
      : addMoreInformation ? api.onboardDeviceQrSecondVersion
        : api.onboardDevice, data)

    if (response.ok) {
      yield put(DeviceActions.deviceQrSuccess(response.data))
    } else {
      yield put(DeviceActions.deviceQrFailure(response.status))
    }
  } catch (e) {
    yield put(DeviceActions.deviceQrFailure())
  }
}

export function* updateBulkEditDevices(api, { data }) {
  try {
    const payLoad = { data: {} }
    payLoad.data = data.map(line => ({
      id: line.serial.value,
      name: line.alias.value,
      organizationunitkey: line.siteId.value
    }))
    const response = yield call(api.updateBulkEditDevices, payLoad)
    if (response.ok) {
      yield put(DeviceActions.updateBulkEditDataRequestSuccess(data))
    } else {
      yield put(DeviceActions.updateBulkEditDataRequestFailure(data))
    }
  } catch (_) {
    yield put(DeviceActions.updateBulkEditDataRequestFailure(data))
  }
}

export function* deviceSendRaCommand(api, { deviceId, cmdStr }) {
  try {
    yield call(api.sendRACommand(deviceId, cmdStr))
  } catch (e) {

  }
}

export function* deviceSitefilter(api, { siteId }) {
  const response = yield call(api.getDevicesModelsV2, { sites: [siteId] })
  if (response.ok) {
    yield put(DeviceActions.deviceSiteFilterSuccess(response.data))
  } else {
    yield put(DeviceActions.deviceSiteFilterFailure())
  }
}

export function* getDevicesOutOfRange (api,urlParams,pageSize, pageNumber) {
  let{
    q,
    organizationalUnitId,
    recommendedOrganizationalUnitId,
    ipStart,
    ipEnd,
    ipNotAvailable} = urlParams.urlParams
  try {
    const profileId = '684D492B-CE98-43CF-AEEF-F333A84440BF'
    const response = yield call(api.getDevicesOutOfRange,{
      pageNumber,
      pageSize,
      filters: JSON.stringify({
        organizationalUnitId: organizationalUnitId || undefined,
        recommendedOrganizationalUnitId: recommendedOrganizationalUnitId || undefined,
        deviceSerialNumber: q || undefined,
        ipStart,
        ipEnd,
        ipNotAvailable
      }),
      profileId
    })
    if (response.ok) {
      yield put(DeviceActions.devicesOutOfRangeSuccess(response.data))
      yield put(DeviceActions.devicesOutOfRangeGenericResponse())
    } else {
      yield put(DeviceActions.devicesOutOfRangeGenericResponse())
    }
  } catch (err) {
    yield put(DeviceActions.devicesOutOfRangeGenericResponse())
  }
}

export function* getDevicesOutOfRangeAdditional (api,urlParams,pageSize, pageNumber) {
  let{
    q,
    organizationalUnitId,
    recommendedOrganizationalUnitId,
    ipStart,
    ipEnd,
    ipNotAvailable} = urlParams.urlParams
  try {
    const profileId = '684D492B-CE98-43CF-AEEF-F333A84440BF'
    const response = yield call(api.getDevicesOutOfRange,{
      pageNumber,
      pageSize,
      filters: JSON.stringify({
        organizationalUnitId: organizationalUnitId || undefined,
        recommendedOrganizationalUnitId: recommendedOrganizationalUnitId || undefined,
        deviceSerialNumber: q || undefined,
        ipStart,
        ipEnd,
        ipNotAvailable
      }),
      profileId
    })
    if (response.ok) {
      yield put(DeviceActions.devicesOutOfRangeAditionalSuccess(response.data))
      yield put(DeviceActions.devicesOutOfRangeGenericResponse())
      
    } else {
      yield put(DeviceActions.devicesOutOfRangeGenericResponse())
    }
  } catch (err) {
    yield put(DeviceActions.devicesOutOfRangeGenericResponse())
  }
}