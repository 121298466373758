import { connect } from 'react-redux'
import { pipe } from 'ramda'
import FormDevices from './form-devices'
import formDevicesSelector from './form-devices.selector'
import DeviceActions from '../../../../Redux/DeviceRedux'
import SiteManagerActions from '../../../../Redux/SiteManagerRedux'
import { translate } from 'react-translate'
import ModalActions from 'Redux/ModalRedux'

export const mapDispatchToProps = dispatch => ({
  getDevices: () => dispatch(DeviceActions.deviceBackgroundRequestAll(1, 7000, null, true)),
  onSubmit: (data, site) => dispatch(DeviceActions.deviceUploadProvisionRequest(data, site)),
  cleanResults: () => dispatch(DeviceActions.deviceUploadProvisionClean()),
  getFamiliesAndModels: () => dispatch(DeviceActions.deviceFamiliesAndModelsRequest()),
  getSites: () => dispatch(SiteManagerActions.siteManagerRequest(true)),
  closeModal: () => dispatch(ModalActions.modalClose()),
  openModal: (modal, props) => dispatch(ModalActions.modalOpen(modal, props)),
  provisionStatusUpdate: (provisionedList) => dispatch(DeviceActions.deviceUploadProvisionRequestSuccess(provisionedList))
})

export default pipe(
  translate('FormUploadDevices'),
  connect(
    formDevicesSelector,
    mapDispatchToProps
  ))(FormDevices)
