import { createStructuredSelector, createSelector } from 'reselect'
import { calculateDistribution } from '../../../dashboard-cards-helpers'
import { groupedDevices } from '../../../DevicesBySite/components/DevicesBySiteChart/devices-by-site-chart.selector'

export const groupedDevicesBySite = createSelector(
  [groupedDevices],
  devices => Object.keys(devices).map(Site => ({ key: Site, value: devices[Site] }))
)
export const total = createSelector(
  [groupedDevicesBySite],
  groupedDevices => groupedDevices.reduce((sum, modelData) => {
    return sum + modelData.value
  }, 0)
)

export const data = createSelector(
  groupedDevicesBySite,
  total,
  (values, total) => calculateDistribution(values, total)
)

export default createStructuredSelector({
  data
})
