import Immutable from 'seamless-immutable'
import { createSelector, createStructuredSelector } from 'reselect'
import { legacyTranslate } from '../i18n/translate.helpers'
import { selectedOrgId, organizationsIds } from 'Selectors/LoaderSelector'
import { pipe } from 'lodash/fp'

export const currentCulture = state => state.user.language
export const devicesObj = ({ devices }) => devices.getIn(['devices'], {})
export const current = ({ devices }) => devices.getIn(['currentDevice'], '')
export const currentDevice = createSelector([current, devicesObj], (id, devs) => devs.getIn([id], Immutable({})))
export const agentVersion = createSelector([currentDevice, currentCulture],
  (currentDevice, currentCulture) => currentDevice.getIn(['deviceConfiguration', 'appsDetails', 0, 'appVersion'], legacyTranslate(`${currentCulture}.Not_available`)))


export const site = createSelector([currentDevice],
  currentDevice => currentDevice.getIn(['hierarchy'], 'Not Available'))

export const orgName = createSelector(
  organizationsIds,
  selectedOrgId,
  (organizationsIds, selectedOrgId) => organizationsIds.filter(org => org.siteId === selectedOrgId)
    .getIn(['0', 'siteName'], '')
)

export const isEnabled = ({ user }) => user.subscription?.features?.includes('devices.performance')

export const isDeviceInSelectedOrg = createSelector(
  orgName,
  site,
  (orgName, site) => site.includes(orgName)
)

export const getSubscriptionFeatures = ({user}) => user?.getIn(['subscription', 'features'], [])

export const offerSubscription = pipe(
  getSubscriptionFeatures,
  (features = []) => !features.includes("talkmanonly")
)

export default createStructuredSelector({
  currentDevice,
  agentVersion,
  isDeviceInSelectedOrg,
  isEnabled,
  offerSubscription
})
