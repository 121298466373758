import React, { Component } from 'react'
import { Form, FormSection } from 'redux-form'
import styled from 'styled-components/macro'

import DataTable from '../../../DataTable'
import DateLocationFormat from '../../../DateLocationFormat'
import DataNameField from '../DataNameField'

const DataTableContainer = styled.div`
  width: 100%;
  height: ${props => props.compact ? 'calc(100% - 3rem)' : '100%'};
`

const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
`

class GeneralTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      assetList: []
    }
  }

  render () {
    const {
      handleSubmit,
      data,
      t,
      submit,
      onSubmit,
      compact,
      onSchedule,
      IsValid
    } = this.props
    return (
      <StyledForm
        onSubmit={
          handleSubmit(form => {
            onSubmit(this.state.assetList.map(
              a => {
                return {
                  ...a,
                  form: form.deviceList[a.id]
                }
              }
            ))
          })
        }
      >
        <DataTableContainer compact={compact}>
          <FormSection name='deviceList'>
            <DataTable
              search
              data={data}
              selection
              selectionMode='single'
              totalRows={data.length}
              rows={data.length}
              rowHeight={30}
              generateFilters
            >
              <DataTable.ActionBar>
                {IsValid &&
                  <DataTable.ActionBar.Item
                    content={t('Update')}
                    onClick={assetList => this.setState({ assetList }, submit)}
                  />
                }
              </DataTable.ActionBar>
              <DataTable.Column
                label={t('Name')}
                dataKey='name'
                width={0.75}
                flexShrink={0.75}
                flexGrow={0.75}
                cellRenderer={props =>
                  <DataNameField {...props} />
                }
              />
              <DataTable.Column
                label={t('Version')}
                dataKey='version'
                cellRenderer={({ cellData }) => {
                  return cellData || 'Not applicable'
                }}
                width={0.2}
                flexShrink={0.2}
                flexGrow={0.2}
              />
              <DataTable.Column
                label={t('File size')}
                dataKey='fileSize'
                width={0.25}
                flexShrink={0.25}
                flexGrow={0.25}
              />
              <DataTable.Column
                label={t('ReleaseDate')}
                dataKey='releaseDate'
                width={0.5}
                flexShrink={0.5}
                flexGrow={0.5}
                cellRenderer={props =>
                  <DateLocationFormat
                    {...props}
                    field='releaseDate'
                  />
                }
              />
            </DataTable>
          </FormSection>
        </DataTableContainer>
      </StyledForm>
    )
  }
}

GeneralTable.defaultProps = {
  t: l => l,
  data: []
}

export default GeneralTable
