import { createStructuredSelector, createSelector } from 'reselect'
import { msToTime } from 'Utils/ConvertTime'
import { formatAppName } from 'Utils/format-app-names'


export const appData = ({ dashboard }) => dashboard.getIn(['appData'], [])

const filteredData = createSelector(
  [appData],
  apps => Object.keys(apps).map(app => apps[app]).filter(f => f['appusagetime'])
)

export const data = createSelector(
  [filteredData],
  apps => apps.map(app => {
    return ({
      appName: formatAppName(app.packagename),
      pkgName: app.packagename,
      version: app.versionname,
      useTime: msToTime(app.appusagetime)
    })
  })
)

const finalData = createSelector(
  [data],
  apps => apps.filter(f => f.useTime > 0)
)

export default createStructuredSelector({
  appData,
  data: finalData
})
