import React, { Component, Fragment } from 'react';

class Polling extends Component {

  pollingAction = () => {
    const {totalUnreadAlerts, getAlerts} = this.props
    totalUnreadAlerts < 100 && getAlerts()
  }

  componentDidMount() {
    const { getAlerts, duration } = this.props
    if (!getAlerts) {
      return
    }

    this.dataPolling = setInterval(this.pollingAction, duration);
  }

  componentWillUnmount() {
    clearInterval(this.dataPolling);
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

export default Polling
