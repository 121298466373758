import React from 'react'
import { StyledIcon, CenterWrapper } from '../../asset-management-base.styles'

function getIcon(data) {
  switch (data) {
    case 'other':
      return {
        root: 'common',
        name: 'device-group'
      }
    case 'mobilecomputer':
      return {
        root: 'global',
        name: 'smartphone'
      }
    case 'scanner':
      return {
        root: '',
        name: '',
        className: 'icon-Scanner'
      }
    case 'printer':
      return {
        root: '',
        name: '',
        className: 'icon-print'
      }
      case 'rfid':
        return {
          root: '',
          name: '',
          className: 'icon-rfid'
        }
    case 'robot':
      return {
        root: '',
        name: '',
        className: 'icon-robot'
      }
    default:
      return {
        root: 'global',
        name: 'pc'
      }
  }
}

export function DeviceTypeOrAllAsset ({ cellData }) { 
  return cellData
    ? <DeviceType cellData={cellData} />
    : <img src={require('Assets/AllAssets.png')} />
}

const DeviceType = ({
  t,
  cellData
}) => {
  return (
    cellData
      ? <StyledIcon
        {...getIcon(cellData)}
        size='small'
        description={t(cellData)}
      />
      : <span>{t('unknown')}</span>
  )
}

DeviceType.defaultProps = {
  t: label => label
}

export default DeviceType
