import { sortBy as sortArr } from 'lodash'
import { SortDirection } from 'react-virtualized'

function Sort (sortBy, sortDirection, data) {
  const nilItems = data.filter(item => !item[sortBy])
  const valuedItems = data.filter(item => item[sortBy])
  const tempList = sortArr(valuedItems, item => item[sortBy])
  return [...(sortDirection === SortDirection.DESC ? tempList.reverse() : tempList), ...nilItems]
}

export default Sort

export const ConvertSortDirection = (isAbbreviation, direction) => {
  switch (isAbbreviation) {
    case true:
      return direction === 'asc' ? '1' : '-1'
    default:
      return direction === '1' ? 'asc' : 'desc'
  }
}


