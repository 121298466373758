import PreferencesInstantAlerts from './PreferencesInstantAlerts'
import { translate } from 'react-translate'
import PreferencesSelector from './PreferencesInstantAlerts.selector'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

export default pipe(
  translate('PreferencesInstantAlerts'),
  connect(PreferencesSelector)
)(PreferencesInstantAlerts)
