import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

export const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    border-bottom: 1px solid #c8c8c8;
    background-color:transparent !important;
  }

  & .p-datatable-scrollable-body {
    max-height: 54.5vh !important;
  }
  & .ui.single-input .search-icon {
    height: 2.5rem;
  }

  & .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }
`

export const ModalTitle = styled.div`
  font-size: 1.75rem;
`
