import styled from 'styled-components'
import { Modal } from '@scuf/common'
import { hwColorGrey75, hwColorGrey85, hwColorGrey91, hwColorWhite } from 'Themes/honeywell-colors'

const getButtonSectionColor = (disabled, isDark) => {
    return disabled ? isDark ? hwColorGrey75 : hwColorGrey91 
            : isDark ? hwColorWhite : hwColorGrey85
}

export const ModalContent = styled.div`
    margin-top: -2.25rem;
    font-size: 14px;
`

export const ModalFooter = styled(Modal.Footer)`
    justify-content: space-between !important;
    display: flex !important;
`

export const FooterNote = styled.div`
    font-size: 12px;
    padding-top: 10px;
`

export const ButtonSection = styled.div`
    display: flex;
    flex-direction: row-reverse;
`

export const ExportSection = styled.a`
    display: flex;
    align-items: center;
    color: ${({disabled, isDark}) => getButtonSectionColor(disabled, isDark)} !important;
    cursor: pointer;
    pointer-events: ${props => props.disabled && 'none'};
    margin-left: 1rem; 
    & p {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        padding-left: 0.25rem;
    }
    & i {
        color: ${({disabled, isDark}) => getButtonSectionColor(disabled, isDark)} !important;
    }
`

export const RefreshSection = styled.a`
    display: flex;
    align-items: center;
    color: ${({disabled, isDark}) => getButtonSectionColor(disabled, isDark)} !important;
    cursor: pointer;
    pointer-events: ${props => props.disabled && 'none'};
    margin-left: 1rem;  
    & p {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        padding-left: 0.25rem;
    }
    & i {
        color: ${({disabled, isDark}) => getButtonSectionColor(disabled, isDark)} !important;
    }
`

export const Title = styled.div`
    text-transform: uppercase;
`
