import apisauce from 'apisauce'
import config from 'Config/AppConfig'

const create = (baseURL = config.api.connectedaidc) => {
  const api = apisauce.create({
    baseURL,
    timeout: 60000,
    headers: config.api.apiKey ? {
      'apikey': config.api.apiKey
    }
      : undefined
  })

  api.addRequestTransform(request => {
    if (request.data.useOpentoken === 'true') {
      request.headers['x-honopentoken'] = request.data.headerToken
    }
    request.data.headerToken = undefined
    request.data.useOpentoken = undefined
  })

  const login = (accessToken, clientType, headerToken, currentNonce, useOpentoken, organizationId) =>
    api.post('/v2/auth', {
      grantType: 'authorization_code',
      clientType,
      accessToken,
      headerToken,
      useOpentoken
    }, {
      headers: { 'x-honnonce': currentNonce },
      params: { organizationId: (organizationId ? organizationId : undefined) }
    })

  return {
    login,
    axiosInstance: api.axiosInstance
  }
}

export default {
  create
}
