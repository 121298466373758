import { select, call, put } from 'redux-saga/effects'
import { change } from 'redux-form'
import ReportActions from '../Redux/ReportRedux'
import moment from 'moment'
import { dateWithAmPm } from '../Utils/MomentHelpers'

export function * reportGetField (api, { fieldName }) {
  try {
    let response = null
    switch (fieldName) {
      case 'products':
        response = yield call(api.getField, fieldName)
        break
      case 'terms':
        response = yield call(api.getTerms, fieldName)
        break
      default:
        response = {
          ok: false
        }
    }
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportUpdateFieldOptions(fieldName, response.data))
    } else {
      yield put(ReportActions.reportUpdateFieldOptions(fieldName, []))
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateFieldOptions(fieldName, []))
  }
}

export function * reportGet (api, { id }) {
  try {
    if (id !== '' && id !== undefined) {
      const response = yield call(api.getReportId, id)
      if (response.ok && response.data !== null) {
        yield put(ReportActions.reportUpdateResults(response.data))
      } else {
        yield put(ReportActions.reportUpdateReports([]))
      }
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateReports([]))
  } finally {
    yield put(ReportActions.reportSuccess('Loading'))
  }
}

export function * calculateTco (api) {
  try {
    const data = yield select(state => state.report.data)
    const response = yield call(api.calculateTco, data)
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportUpdateResults(response.data))
    } else {
      yield put(ReportActions.reportUpdateResults())
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateResults())
  } finally {
    yield put(ReportActions.reportSuccess('Loading'))
  }
}

export function * calculateHaas (api) {
  try {
    const data = yield select(state => state.report.data)
    const response = yield call(api.calculateHaas, data)
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportUpdateResults(response.data))
    } else {
      yield put(ReportActions.reportUpdateResults({}))
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateResults({}))
  } finally {
    yield put(ReportActions.reportSuccess('Loading'))
  }
}

export function * reportSave (api, { reportType }) {
  try {
    let data = yield select(state => state.report.data)

    let response = null
    switch (reportType) {
      case 'tco':
        data = data.setIn(['reportName'], 'Total Cost of Ownership by ' + data.reportInfo.userName)
        response = yield call(api.saveReport, data)
        break
      case 'haas':
        data = data.setIn(['reportName'], 'HaaS Proposal Letter by ' + data.reportInfo.userName)
        response = yield call(api.saveHaasReport, data)
        break
      default:
        response = {
          ok: false
        }
    }
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportUpdateResults(response.data))
      yield put(ReportActions.reportUpdateId(response.data.id))
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateResults())
  }
}

export function * reportGetAll (api, { reportType }) {
  try {
    let response = null
    switch (reportType) {
      case 'tco':
        response = yield call(api.getTcoReports)
        break
      case 'haas':
        response = yield call(api.getHaasReports)
        break
      default:
        response = {
          ok: false
        }
    }
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportUpdateReports(response.data))
    } else {
      yield put(ReportActions.reportUpdateReports([]))
    }
  } catch (e) {
    yield put(ReportActions.reportUpdateReports([]))
  }
}

export function * reportSubmitDevice (api, { device }) {
  try {
    const response = yield device.id ? call(api.putDevice, device) : call(api.postDevice, device)
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportSuccess())
    } else {
      yield put(ReportActions.reportSuccess())
    }
  } catch (e) {
    yield put(ReportActions.reportSuccess())
  }
}

export function * reportSubmitTerm (api, { term }) {
  try {
    const response = yield term.id ? call(api.putTerm, term) : call(api.postTerm, term)
    if (response.ok && response.data !== null) {
      yield put(ReportActions.reportSuccess())
    } else {
      yield put(ReportActions.reportSuccess())
    }
  } catch (e) {
    yield put(ReportActions.reportSuccess())
  }
}

export function * changePricingModel (api, { pricingModelId }) {
  try {
    const pricingModel = yield select(state => state.report.device)
    yield put(change('tcoForm', 'reportInputs.competitorDevice', pricingModel))
  } catch (e) {
    yield put(change('tcoForm', 'reportInputs.competitorDevice', {}))
  }
}

export function * getTemplates(api) {
  try {
    const response = yield call(api.getTemplates)
    if (response.data !== null && response.ok) {
      yield put(ReportActions.updateTemplates(response.data?.templates))
    } else {
      yield put(ReportActions.updateTemplates([]))
    }
  } catch (e) {
    yield put(ReportActions.updateTemplates([]))
  }
}

export function * getEventReports(api, { payload , selectedDateRange}) {
  try {

    if (Number(selectedDateRange)) {
      payload['to'] = moment().toISOString()
      payload['from'] = moment().subtract(selectedDateRange, 'hours').toISOString()
    }
    const response = yield call(api.getEventReports, { ...payload })
    if (response.data !== null && response.ok) {
      const { fieldNames, newData,totalRows } = yield createEventReportsData(response)
      yield put(ReportActions.updateEventReports(fieldNames, newData,totalRows))
    } else {
      yield put(ReportActions.updateEventReports([], []))
    }
  } catch (e) {
    yield put(ReportActions.updateEventReports([], []))
  }
}

export function * getAdditionalEventReports(api, { payload , selectedDateRange, isExport}) {
  try {
    if (Number(selectedDateRange)) {
      payload['to'] = moment().toISOString()
      payload['from'] = moment().subtract(selectedDateRange, 'hours').toISOString()
    }
    const response = yield call(api.getEventReports, { ...payload })
    if (response.data !== null && response.ok) {
      const { fieldNames, newData,totalRows } = yield createEventReportsData(response)
      if (!isExport) {
        yield put(ReportActions.updateAdditionalEventReports(fieldNames, newData, totalRows))
      } else{
        yield put(ReportActions.getTotalEventReports([]))
      }
    } else {
      yield put(ReportActions.updateAdditionalEventReports([], []))
    }
  }
  catch (e) {
    yield put(ReportActions.updateAdditionalEventReports([], []))
  }
}

async function createEventReportsData(response) {
  const fieldNames = response?.data?.reportOutputs?.map(
    output => ({
      dataKey: output.field,
      label: output.field
    }))

  const totalRows = response?.data?.total
  let data = await response?.data?.reportOutputs?.reduce((obj, x) => {
    return x.values.reduce((objs, y, index) => {
      if (!objs[index]) {
        return {
          ...objs,
          [index]: [y]
        }
      } else {
        return {
          ...objs,
          [index]: [
            ...objs[index],
            y
          ]
        }
      }
    }, obj)
  }, {})

  data = Object.values(data)

  function toObject(names, values) {
    const result = {}
    for (let i = 0; i < names.length; i++) {
      if (moment(values[i], moment.ISO_8601, true).isValid()) {
        values[i] = dateWithAmPm(moment(values[i]))
      }
      result[names[i]['dataKey']] = values[i]
    }
    return result
  }

  const newData = []
  data.forEach(d => {
    newData.push(toObject(fieldNames, d))
  })
  return { fieldNames, newData, totalRows }
}
