import PropTypes from 'prop-types'

import { ToolsTotalCount } from '../actionbar/actionbar.styles'

const Total = ({
  t,
  totalCount,
  rowsCount,
  selectedCount
}) => (
  <ToolsTotalCount>
    {totalCount < rowsCount
    ? t('Scroll', { count: rowsCount, selected: selectedCount })
    : t('Total', { count: totalCount, selected: selectedCount })
    } 
  </ToolsTotalCount>
)

Total.propTypes = {
  t: PropTypes.func,
  totalCount: PropTypes.number,
  rowsCount: PropTypes.number,
  selectedCount: PropTypes.number
}

Total.defaultProps = {
  t: l => l
}

export default Total
