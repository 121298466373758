import { pipe } from 'ramda'
import React, { useState, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'

import AssetInfo from '../AssetInfo/asset-info.connect'

const ConfigStep = ({
  assetsList,
  formValues,
  t,
  isValid }) => {
  const [isAssetInfoValid, setIsAssetInfoValid] = useState(false)

  useEffect(() => {
    isValid(isAssetInfoValid)
  }, [isAssetInfoValid, isValid])

  return (
    <Field
      component={AssetInfo}
      assetsList={assetsList}
      formValues={formValues}
      style={{ justifyContent: 'space-between', marginBottom: '4em' }}
      name='AssetConfig'
      t={t}
      isValid={setIsAssetInfoValid}
    />
  )
}

export default pipe(
  reduxForm({
    form: 'softwareUpdateWizard',
    destroyOnUnmount: false
  })
)(ConfigStep)
