import React from 'react'
import { all, apply, put, takeLatest, cancelled, Effect } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'

import { GetAllResult, ISitesService } from '../service'
import { getSitesFailure, getSitesRequest, getSitesSuccess } from './slice'
import axios from 'axios'

export function toastError (error: string): void {
  toast(<ErrorToast message={error} />)
}

export function toastSuccess (message: string): void {
  toast(<SuccessToast message={message} />)
}

export function * getAll (siteService: ISitesService): Generator<Effect> {
  const source = axios.CancelToken.source()
  try {
    const response = (yield apply(siteService, siteService.getAll, [source.token])) as GetAllResult
    yield put(getSitesSuccess(response))
  } catch (err) {
    if (axios.isCancel(err)) return
    yield put(getSitesFailure(err.toString()))
    toastError(err.toString())
  } finally {
    if ((yield cancelled()) as boolean) {
      source.cancel()
    }
  }
}

export function * sitesSaga (sitesService: ISitesService): Generator<Effect> {
  yield all([
    takeLatest(getSitesRequest.toString(), getAll, sitesService)
  ])
}
