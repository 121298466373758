import { put, call, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import React from 'react'
import { SuccessToast, ErrorToast } from '../../../Themes/ScufStyledComponents'
import {
  ACCESS_POINT
} from '../../floor-plans/beacon/beacon.constants'

import {
  AccessPointsTypes,
  AccessPointsActions
} from './actions'
import { BeaconsApi } from '../../../Services'
import { get } from 'lodash'
import { AccessPoints } from '../../../Services'

export function* createAccessPointRequest({ siteId, accessPoint, callback }) {
  const response = yield call(BeaconsApi.createBeacon, {
    ssid: accessPoint.ssid,
    building: accessPoint.building,
    floor: accessPoint.floor,
    name: accessPoint.name,
    uuid: accessPoint.bssid,
    Type: ACCESS_POINT,
    siteId
  })
  if (response.ok) {
    yield all([
      put(AccessPointsActions.createAccessPointSuccess()),
    ])
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } else {
    const error = get(response, 'data.errors[0].detail', 'Error')
    yield all([
      put(AccessPointsActions.createAccessPointFailure(error))
    ])
  }
}

export function* editAccessPointRequest({ accessPoint, callback }) {
  const response = yield call(BeaconsApi.updateBeacon, accessPoint.id, {
    ssid: accessPoint.ssid,
    building: accessPoint.building,
    floor: accessPoint.floor,
    name: accessPoint.name,
    uuid: accessPoint.bssid,
    Type: ACCESS_POINT,
    siteId: accessPoint.siteId
  })
  if (response.ok) {
    yield all([
      yield put(AccessPointsActions.editAccessPointSuccess())
    ])
    if (typeof callback === 'function') {
      yield call(callback)
    }
  } else if (!response.ok && response.status === 401) {
    yield all([
      AccessPointsActions.editAccessPointFailure('NoPermission')
    ])
  } else {
    const error = get(response, 'data.errors[0].detail', 'Error')
    yield all([
      AccessPointsActions.editAccessPointFailure(error)
    ])
  }
}

export function* getAccessPointData({ siteId, params }) {
  const response = yield call(AccessPoints.getAccessPoints, siteId, params)
  if (response.ok) {
    const data = [...response.data['data']]
    yield all([
      yield put(AccessPointsActions.getAccessPointDataResponse(data))
    ])
  } else {
    yield all([
      yield put(AccessPointsActions.getAccessPointDataResponse())
    ])
  }
}

export function* accessPointsSagas() {
  yield all([
    takeLatest(AccessPointsTypes.CREATE_ACCESS_POINT_REQUEST, createAccessPointRequest),
    takeLatest(AccessPointsTypes.EDIT_ACCESS_POINT_REQUEST, editAccessPointRequest),
    takeLatest(AccessPointsTypes.GET_ACCESS_POINT_DATA_REQUEST, getAccessPointData)
  ])
}
