import { useFlags } from 'launchdarkly-react-client-sdk'
import qs from 'qs'
import { useEffect, useState } from 'react'

import { UserApi } from '../../../../Services'

// Our hook
export function useDebounce (value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  )

  return debouncedValue
}

export function useUrlQueryParams (query, history, assetType, userId = '') {
  const [params, setParams] = useState(null)
  const [filterCount, setFilterCount] = useState(0)
  const { saveFilters } = useFlags()

  useEffect(() => {
    const pr = qs.parse(query, {
      ignoreQueryPrefix: true
    })

    const totalFilters = Object.keys(pr)
    .filter(filterName => filterName !== 'q' )
      .reduce((count, filterKey) => {
        const filter = pr[filterKey]
        return count + (typeof filter === 'string' ? 1 : filter.length || 0)
      }, 0)
    setFilterCount(totalFilters)
    setParams(pr)
  }, [query])

  const resetAllFilters = () => {
    setParams({})
    history.push(
      history.location.pathname
    )
    postFilters('')
  }

  const postFilters = async (search) => {
    const data = {
      section: assetType || 'all',
      search,
      userId
    }
    await UserApi.postFilters(data)
  }

  const pushParamToUrl = (name, value) => {
    try {
      const searchQuery = qs.stringify({
        ...params,
        [name]: value
      })
      history.push({
        search: searchQuery
      })
      if (saveFilters) {
        postFilters(searchQuery)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    params,
    pushParamToUrl,
    resetAllFilters,
    filterCount
  }
}
