import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeleteLocation from './delete-location'
import mapStateToProps from './delete-location.selector'
import LocationActions from '../../Redux/LocationRedux'
import ModalActions from '../../Redux/ModalRedux'

export const mapDispatchToProps = dispatch => ({
  onSelect: location => dispatch(LocationActions.locationSetDelete(location)),
  getLocations: (site = { guid: '', name: '' }) => dispatch(LocationActions.locationRequest(site)),
  deleteLocation: () => dispatch(LocationActions.locationRequestDelete()),
  closeModal: () => dispatch(ModalActions.modalClose())
})

export default translate('DeleteLocationTable')(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteLocation))
