import styled from 'styled-components'
import { Modal, Input, Checkbox, Accordion } from '@scuf/common'

const ModalWrap = styled.div`
  background: ${props => props.theme.background === '#202020 !important' ? '#303030 !important' : '#fff'}  ;
  & .ui.modal .close-icon-wrap .modal-close {
    overflow: hidden !important;
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    background-color: transparent !important;
  }


  & .accordion.ui.fluid .active.content {
    border: none !important;
    padding: 0 !important;
  }

  & .ui.dashboard-wrap .layout-grid .ui.widget-container {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder};
  }
  & .ui.widget-container .widget-wrapper > div {
    border: none !important;
  }
  & .ui.widget-container .widget-wrapper > div > div > div > img{
    background: #fff;
    border-radius: 3px;
  }
  & .accordion.ui.fluid .title {
    border: none !important;
    background: ${props => props.theme.background};
  }
  & .cONqjN {
    box-shadow: ${props => props.theme.boxShadow};
  }
  & .eTvQHC {
    border-top: ${props => props.theme.darkBorder};
  }
  & .cBfEdo {
    margin-top:1px;
  } 
  & .widget-wrapper {
    background: ${props => props.theme.background};
  }
`

const ModalContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
  margin-top: 1px;
  & .ui.dashboard-wrap .layout-grid .ui.widget-container {
    box-shadow: none;
  }
`
const ModalHeader = styled(Modal.Header)`
  padding: 0 !important;
  box-shadow: 0px 1px 0px #e0e0e0;
`
const ModalFooter = styled(Modal.Footer)`
  padding: 1rem !important;
  border-top: 1px solid #e0e0e0 !important;
  & a {
    float: left;
  }
`

const FooterWrap = styled.div`
 width:100%;
 display: flex;
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const HeaderWrap = styled.div`
`
const StyledH2 = styled.div`
  font-size:26px;
  font-weight:500;
`

const Paragraph = styled.div`
  font-size: 0.875rem;
  color: #707070;
  line-height: 1rem;
  margin:1rem 0 ;
`

const Link = styled.a`
  min-width: 100px;
  align-self: center;
`

const StyledInput = styled(Input)`
  width:100% ;
  margin-top: 1rem;
  & .below-text {
    min-height: 0 !important;
  }
  & input {
    width: 100% !important;
  }
  & div {
    width: 100% !important;
  }
`

const AccordionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${props => props.theme.headerhover};
  & div {
    background: ${props => props.theme.headerhover};
    }
  & .accordion.ui.fluid {
    border: none !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  width: 2% !important;
  padding-left: 0.5rem;
  padding-top: 1.3rem;
`

const StyledAccordion = styled(Accordion)`
  width: 98% !important;
  padding: 0 !important;
`

const Reset = styled.div`
  font-size: .75rem;
  margin:0 .5rem .5rem 0;
  color: #1792E5;
  cursor: pointer;
  & p {
    line-height: 1.45rem;
  }
  &:hover {
    & p {
      color: #337ab7;
    }
  }
`

const ResetContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

export {
  ModalWrap,
  ModalContainer,
  HeaderWrap,
  StyledH2,
  Paragraph,
  Link,
  StyledInput,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap,
  StyledCheckbox,
  StyledAccordion,
  AccordionWrapper,
  Reset,
  ResetContainer
}
