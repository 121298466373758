import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../../AssetManagementTable'
import { ModalTitle, ModalWrap } from './dropped-devices-modal.styles'

export const columns = (t) => {
  return [
    { field: 'key', translation: 'AssetManagementTable_faultyDotsSerialNumber', render: null },
    { field: 'aliasName', translation: 'AssetManagementTable_alias', render: null},
    { field: 'hardImpact', translation: 'AssetManagementTable_hardImpact', render: null },
    { field: 'softImpact', translation: 'AssetManagementTable_softImpact', render: null },
    { field: 'freeFall', translation: 'AssetManagementTable_freeFall', render: null },
    { field: 'siteId', translation: 'AssetManagementTable_deviceSite', render: null }
  ]
}

const TypesOfModelModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('DroppedDevices_Title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns(t)}
        disableRowClick
        fileName={t('DroppedDevices_Title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default TypesOfModelModal
