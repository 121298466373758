import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  rolesRequest: null,
  rolesSuccess: ['roles', 'rolesPerOrganization'],
  rolesFailure: ['errors']
}, {
  prefix: '@ROLES/'
})

export const RolesTypes = Types
export const RolesActions = Creators
