import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../../AssetManagementTable'
import { ModalTitle, ModalWrap } from './hard-reboots-modal.styles'

export const columns = [
  { field: 'day', translation: 'AssetManagementTable_date', render: null },
  { field: 'hardReboots', translation: 'AssetManagementTable_hardReboots', render: null },
  { field: 'softReboots', translation: 'AssetManagementTable_softReboots', render: null }
]

const HardRebootsModal = ({ data, t }) => (
  <ModalWrap>
    <Modal.Header>
      <ModalTitle>{t('HardReboots_Title')}</ModalTitle>
    </Modal.Header>
    <Modal.Content>
      <AssetManagementTable
        data={data}
        columns={columns}
        disableRowClick
        fileName={t('HardReboots_Title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default HardRebootsModal
