import { createStructuredSelector, createSelector } from 'reselect'
import {
  sitesById
} from './SiteSelector'
import {
  devices
} from './DeviceSelector'

export const deviceModels = createSelector([devices], devs => {
  const models = {}
  devs.forEach(d => { models[d.modelUpperCase] = true })
  var parsedDeviceModels = Object.keys(models)
    .filter(model => model !== 'Not Available')
    .sort()
  return parsedDeviceModels.map(model => ({ value: model, text: model, key: model }))
})

const deviceTypes = () =>
  [
    { value: 'Fixed Printers', key: 'Fixed Printers', text: 'Fixed Printers' },
    { value: 'Mobile Printers', key: 'Mobile Printers', text: 'Mobile Printers' },
    { value: 'Mobility Edge', key: 'Mobility Edge', text: 'Mobility Edge' },
    { value: 'Scanners', key: 'Scanners', text: 'Scanners' }
  ]

export const sites = createSelector([sitesById], sites => sites.map(s => ({
  title: s.name,
  ocupation: s.guid
})))

export default createStructuredSelector({
  deviceModels,
  deviceTypes,
  sites
})
