import { create } from 'apisauce'
import moment from 'moment'

import config from 'Config/AppConfig'
import LoginActions from 'Redux/LoginRedux'

const apiSauceClient = create({
  baseURL: config.api.connectedaidc,
  timeout: 60000,
  headers: config.api.apiKey
    ? {
      'apikey': config.api.apiKey
    }
    : undefined
})

if (apiSauceClient?.axiosInstance?.interceptors?.response?.handlers?.length === 0) {
  import('../Store').then(({ store }) => {
    apiSauceClient.axiosInstance.interceptors.response.use(
      response => {
        if (response.status === 401) {
          store.dispatch(LoginActions.loginReset())
        }

        return response
      }
    )
  }).catch(e => null)
}

if (apiSauceClient?.axiosInstance?.interceptors?.request?.handlers?.length === 0) {
  import('../Store').then(({ store }) => {
    apiSauceClient.axiosInstance.interceptors.request.use(
      response => {
        const { login } = store.getState()
        const tokenExpirationTime = login.getIn(['decodedTokenPayload', 'exp'], 0)
        if(tokenExpirationTime !== 0 && tokenExpirationTime < moment().unix())
        {
          store.dispatch(LoginActions.loginReset())
          throw new apiSauceClient.axiosInstance.Cancel('Request cancelled because of expired Token.')
        }
        return response
      }
    )
  }).catch(e => null)
}

export default apiSauceClient

export const setToken = token => apiSauceClient.setHeader('Authorization', `Bearer ${token}`)
export const apiLogout = () => apiSauceClient.setHeader('Authorization', '')
