import React from 'react'
import { Modal } from '@scuf/common'
import { useTranslate } from 'react-translate'

import { useReleaseNotes } from './release-notes.hook'
import { ReleaseNote, SectionTitle } from './release-notes.styles'
import SubscribeRelease from './subscribe-releases'

export default function ReleaseNotesModal () {
  const {
    isOpen,
    handleClose,
    upcomingReleases,
    latestReleases,
    pastReleases,
    markAsRead
  } = useReleaseNotes()
  const t = useTranslate('ReleaseNotes')
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      size='small'
      closeIcon
      closeOnDocumentClick
    >
      <Modal.Header>
        {t('Release notes')}
      </Modal.Header>
      <Modal.Content>
        {upcomingReleases.length > 0 && <div>
          <SectionTitle>{t('Upcoming Release')}</SectionTitle>
          {upcomingReleases.map((r, i) =>
            <ReleaseNote key={r.id} onClick={markAsRead} {...r} />
          )}
        </div>}
        {latestReleases.length > 0 && <div>
          <SectionTitle>{t('Latest Release')}</SectionTitle>
          {latestReleases.map((r, i) =>
            <ReleaseNote key={r.id} onClick={markAsRead} {...r} />
          )}
        </div>}
        {pastReleases.length > 0 && <div>
          <SectionTitle>{t('Release History')}</SectionTitle>
          {pastReleases.map((r, i) =>
            <ReleaseNote key={r.id} onClick={markAsRead} {...r} />
          )}
        </div>}
        <SubscribeRelease />
      </Modal.Content>
    </Modal>
  )
}
