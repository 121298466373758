import { withRouter } from 'react-router-dom'
import { translate } from 'react-translate'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'
import { connect } from 'react-redux'
import MenuOptionsActions from 'Redux/CollapsableMenuOptionsRedux'
import MenuOptions from './MenuOptions'
import ModalActions from 'Redux/ModalRedux'
import mapStateToProps from './menu-options.selector'
import DashbordActions from 'Redux/DashboardRedux'


const mapDispatchToProps = dispatch => {
  return {
    openAlertsModal: () => dispatch(ModalActions.modalOpen('alertsManagement', { className: '.modal-height-medium', closeIcon: true })),
    changeMenuCollapsed: (section, state) => dispatch(MenuOptionsActions.changeMenuCollapsed(section, state)),
    closeDrillDown: () => dispatch(DashbordActions.dashboardCloseDrilldown())
  }
}

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  translate('MenuOptions'),
  withLDConsumer(),
  withRouter
)(MenuOptions)
