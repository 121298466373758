import config from 'Config/AppConfig';
import { useSelector } from 'react-redux';

const getApiToken = ({ login }) => login.accessToken

export const useApiHeaders = () =>{ 
  const apiToken = useSelector(getApiToken)
  const apigeeToken = config.api.apiKey
  return {
    'apikey': apigeeToken || undefined,
    'Authorization': apiToken ? `Bearer ${apiToken}` : undefined
  }
}