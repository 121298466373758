import React from 'react'
import { useTranslate } from 'react-translate'
import PropTypes from 'prop-types'
import { DescriptionContainer, JobIdentifierContainer, MasterGuidContainer } from './style'

function JobIdentifier ({ masterGuid }) {
  const translate = useTranslate('ScheduleUpdateModal')

  return (
    <JobIdentifierContainer>
        <DescriptionContainer id='description-container'>
            {translate('Job ID')}
        </DescriptionContainer>
        <MasterGuidContainer id='masterguid-container'>
            {masterGuid}
        </MasterGuidContainer>
    </JobIdentifierContainer>
  )
}

JobIdentifier.propTypes = {
    masterGuid: PropTypes.string
}

JobIdentifier.defaultProps = {
  masterGuid: ''
}

export default JobIdentifier
