import { createReducer, createActions } from 'reduxsauce'
import get from 'lodash/get'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  userManagerRequest: ['preSelect'],
  userManagerSuccess: ['data'],
  userRolesRequest: ['preSelect'],
  userRolesSuccess: ['data'],
  userManagerFailure: null,
  userManagerGenericSuccess: null,
  userManagerSetCurrent: ['userId'],
  userManagerUpdateRequest: ['userData', 'siteId'],
  userManagerCreateRequest: ['userData'],
  userManagerDeleteRequest: ['userId', 'orgId'],
  userManagerClearCurrent: null,
  userManagerRolesRequest: null,
  userManagerRolesSuccess: ['roles'],
  userManagerRolesFailure: null,
  userManagerRegistrationRoleRequest: null,
  userManagerRegistrationRoleSuccess: ['data'],
  userManagerRegistrationRoleFailure: null,
  userManagerRolesUiRequest: null,
  userManagerRolesUiRequestSuccess: ['data'],
  userManagerRolesUiRequestFailure: null,
  userManagementSetCurrentRole: ['roleId'],
  userManagerUsersByRoleRequest: ['roleId'],
  userManagerUsersByRoleResponse: ['data'],
})

export const UserManagerTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  error: false,
  users: {},
  usersById: [],
  currentUser: '',
  roles: [],
  rolesError: false,
  uiRoles: [],
  registrationRole: {},
  registrationRoleError: false,
  preSelect: true,
  currentRole: {},
  usersForCurrentRole: []
})

/* ------------- Reducers ------------- */
export const request = (state, { preSelect }) =>
  state.merge({ loading: true, error: false, preSelect })

export const failure = state =>
  state.merge({ loading: false, error: true, preSelect: true })

export const success = (state, { data }) => {
  const fullData = data.reduce((obj, item) => {
    const guid = item['id'] || ''
    obj[guid] = { ...item }
    return obj
  }, {})

  const usersById = data.map(s => ({
    firstName: s['firstName'] || '',
    lastName: s['lastName'] || '',
    userName: s['userName'] || '',
    id: s['id'] || '',
    employeeId: s['employeeId'] || ''
  }))

  usersById.sort((a, b) => (a.firstName && a.firstName.localeCompare(b.firstName)))

  const [firstUser] = usersById
  const selected = state.getIn(['currentUser'], firstUser ? firstUser.id : '')
  // NOTE: getIn() default seem to not be working properly all the time

  const currentUser = state.getIn(['preSelect'])
    ? selected
      ? selected
      : (firstUser ? firstUser.id : '')
    : ''

  return state.merge({
    loading: false,
    users: fullData,
    usersById,
    currentUser,
    error: false,
    preSelect: true
  })
}

export const updateRequest = state => state.merge({ loading: true })

export const genericSuccess = state =>
  state.merge({ loading: false, error: false })

export const setCurrentUser = (state, { userId }) => state.merge({ currentUser: userId })
export const clearCurrentUser = state => state.merge({ currentUser: '' })

export const rolesRequest = state => state.merge({ loading: true, rolesError: null })
export const rolesSuccess = (state, { roles }) => state.merge({ loading: false, rolesError: false, roles: roles.sort((a, b) => (a.name && a.name.localeCompare(b.name))) })
export const rolesFailure = state => state.merge({ loading: false, rolesError: true })

export const registrationRoleSuccess = (state, { data }) => state.merge({ registrationRole: data, registrationRoleError: false })
export const registrationRoleFailure = state => state.merge({ registrationRoleError: true })

export const rolesUiRequestSuccess = (state, { data }) => state.merge({ uiRoles: data })
export const rolesUiRequestFailure = state => state.merge({ loading: false, rolesError: true })

export const userManagerRequestUsersByRole = (state) => genericSuccess(state)
export const userManagerResponseUsersByRole = (state, { data }) => {
  return state.merge({ usersForCurrentRole: data.users, loading: false })
}

const userManagementSetCurrentRole = (state, { roleId }) => {
  const currentRole = state.managementRoles?.find(o => get(o, 'roleId', '') === roleId)
  return state.merge({ currentRole })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_MANAGER_REQUEST]: request,
  [Types.USER_MANAGER_SUCCESS]: success,
  [Types.USER_MANAGER_FAILURE]: failure,
  [Types.USER_MANAGER_UPDATE_REQUEST]: updateRequest,
  [Types.USER_MANAGER_DELETE_REQUEST]: request,
  [Types.USER_MANAGER_GENERIC_SUCCESS]: genericSuccess,
  [Types.USER_MANAGER_SET_CURRENT]: setCurrentUser,
  [Types.USER_MANAGER_CLEAR_CURRENT]: clearCurrentUser,
  [Types.USER_MANAGER_ROLES_REQUEST]: rolesRequest,
  [Types.USER_MANAGER_ROLES_SUCCESS]: rolesSuccess,
  [Types.USER_MANAGER_ROLES_FAILURE]: rolesFailure,
  [Types.USER_MANAGER_REGISTRATION_ROLE_SUCCESS]: registrationRoleSuccess,
  [Types.USER_MANAGER_REGISTRATION_ROLE_FAILURE]: registrationRoleFailure,
  [Types.USER_MANAGER_ROLES_UI_REQUEST]: request,
  [Types.USER_MANAGER_ROLES_UI_REQUEST_SUCCESS]: rolesUiRequestSuccess,
  [Types.USER_MANAGER_ROLES_UI_REQUEST_FAILURE]: rolesUiRequestFailure,
  [Types.USER_MANAGEMENT_SET_CURRENT_ROLE]: userManagementSetCurrentRole,
  [Types.USER_MANAGER_USERS_BY_ROLE_REQUEST]: userManagerRequestUsersByRole,
  [Types.USER_MANAGER_USERS_BY_ROLE_RESPONSE]: userManagerResponseUsersByRole,
})
