import { useEffect, useState } from 'react'
import { AlertsApi } from 'Services'
import { get } from 'lodash'

export const useAutomatedReport = () => {

  const [dayOfWeek, setDayOfWeek] = useState(1)
  const [timeZoneNames, setTimeZoneNames] = useState([])

  const getTimeZones = async () => {
    const result = await AlertsApi.getTimeZones()
    if (result.ok) {
      setTimeZoneNames(
        get(result, ['data'], [])
          .map(name => ({
            value: name,
            text: name
          })
        )
      )
    }
  }

  useEffect(() =>{
    getTimeZones()
  }, [])

  return {
    dayOfWeek,
    setDayOfWeek,
    timeZoneNames,
    setTimeZoneNames
  }
}

