import sortBy from 'lodash/sortBy'
import { flatten, isEmpty } from 'ramda'
import { createSelector, createStructuredSelector } from 'reselect'
import Immutable from 'seamless-immutable'

import { isGlobalUser } from 'Selectors/PermissionsSelector'
import { selectedOrgId } from 'Selectors/LoaderSelector'
import { cleanRootSites } from './SiteSelector'

export const users = state => state.userManager.users
export const usersById = state => state.userManager.usersById
export const currentUser = state => state.userManager.currentUser
export const currentRoleId = state => state.userManager.getIn(['currentRole', 'roleId'], '')
export const loading = state => state.userManager.loading
export const roles = state => state.userManager.roles
export const uiRoles = state => sortBy(state.userManager.uiRoles, ['name'])

export const error = state => state.userManager.error
export const rolesError = state => state.userManager.rolesError
export const registrationRole = state => state.userManager.registrationRole
export const registrationRoleError = state => state.userManager.registrationRoleError

export const rootSites = state => state.siteManager.rootSites

export const formData = state => state.form.userForm

export const userData = state => state.user.userData
export const isHoneywellAdmin = state => state.user.isHoneywellAdmin
export const isAdmin = state => state.user.isAdmin

export const uiRolesWithDescription = createSelector([uiRoles], _uiRoles => {
  if (isEmpty(_uiRoles) || !_uiRoles) {
    return []
  }

  return _uiRoles.map(role => {
    return { name: role.name, description: role.description }
  })
})

const currentUsersOrgRolesSelector = ({ state }) => (state.user?.userData?.assetAssociation ?? []).flatMap(asset => {
  const childAssets = asset.organizationalUnitAssociation.map(childAsset => {
    if (childAsset.role !== null) {
      return { roleId: childAsset.role.guid, siteId: childAsset.organizationalUnitIdentifier.organizationalUnitGuid }
    }
  })
  if (asset.role !== null) {
    return [
      { roleId: asset.role.guid, siteId: asset.organizationIdentifier.organizationGuid },
      ...childAssets
    ]
  }
  return childAssets
})

export const currentUserRoles = createSelector(
  userData,
  selectedOrgId,
  (_userData, selectedOrgId) => {
    return _userData?.assetAssociation?.filter(siteId => siteId.organizationIdentifier.organizationGuid === selectedOrgId)
    .map(asset => {
      return asset.roles.map(r => ({
        guid: r.guid,
        org: asset.organizationIdentifier.organizationGuid || ''
      }))
    }).flat()
})

export const canAuthorize = createSelector([userData, currentUser], (_userData, _currentUser) => {
  if (!_userData || isEmpty(_userData)) {
    return false
  }
  const assets = _userData.getIn(['assetAssociation'], [])
  let authorizer = false
  assets.forEach(organization => {
    if (organization.getIn(['organizationIdentifier', 'organizationName']) === 'Honeywell') {
      if (organization.getIn(['roles']).some(r => r.isAdmin)) {
        authorizer = true
      }
    }
  })
  authorizer = authorizer && _userData.getIn(['id']) !== _currentUser
  return authorizer
})

export const getCurrentUser = createSelector(
  [users, currentUser],
  (allUsers, user) => {
    if (isEmpty(allUsers) || !user) {
      return Immutable({})
    }
    const cleanUser = allUsers.getIn([user])
    return Immutable(cleanUser)
  }
)

export const getFormUser = createSelector(
  getCurrentUser,
  user => {
    const formUser = {
      id: user.getIn(['id']),
      contactInformation: {
        addresses: [
          {
            ...user.getIn(['address']),
            countryFullName: user.getIn(['address', 'country']),
            stateFullName: user.getIn(['address', 'state']),
            cityFullName: user.getIn(['address', 'city']),
            address1: user.getIn(['address', 'address1']),
            address2: user.getIn(['address', 'postalCode'])
          }
        ],
        emailAddresses: [{
          emailAddressFull: user.getIn(['email'])
        }]
      },
      personDetails: {
        firstName: user.getIn(['firstName']),
        middleName: user.getIn(['middleName']),
        lastName: user.getIn(['lastName']),
        fromdateauth: user.getIn(['fromdateauth']),
        todateauth: user.getIn(['todateauth'])
      },
      personIdentifier: {
        loginEmailAddress: user.getIn(['email']),
        employeeId: user.employeeId
      }
    }

    if (user.getIn(['organization'])) {
      formUser['organization'] = {
        organizationalUnits: flatten(
          user.getIn(['organization']).map(org => {
            let orgs = org.getIn(['organizationalUnits']).map(unit =>
              unit['roles']?.map(r => ({
                parent: org['guid'],
                id: unit['guid'],
                role: r.guid
              }))
            )
            orgs = org['roles']
              ? [org['roles']?.map(r => ({
                parent: org['guid'],
                id: '',
                role: r.guid
              })),
              ...orgs
              ]
              : orgs

            return orgs
          })
        )
      }
    }

    return Immutable(formUser)
  }
)

export const userSearchStruct = createSelector(
  [usersById],
  (users) => {
    return Immutable(users.map(s => ({ title: s.userName, id: s.id })))
  }
)

export const UserFormSelector = createStructuredSelector({
  initialValues: getFormUser,
  roles,
  uiRoles,
  rootSites: cleanRootSites,
  formData,
  loading,
  users: userSearchStruct,
  canAuthorize,
  registrationRole,
  registrationRoleError,
  uiRolesWithDescription,
  currentUserRoles,
  isGlobalUser
})

export default createStructuredSelector({
  loading,
  currentRoleId,
  users: usersById,
  currentUser: getCurrentUser,
  isHoneywellAdmin,
  isAdmin,
})
