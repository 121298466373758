import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-translate'

import ScheduledDevicesWrapper from './ScheduledDevicesWrapper'
import ScheduledDevicesWrapperSelector from './ScheduledDevicesWrapper.selector'

export default pipe(
    translate('ScheduledDevicesModal'),
    connect(ScheduledDevicesWrapperSelector),
  )(ScheduledDevicesWrapper)