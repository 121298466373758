import React, { PureComponent } from 'react'
import { Button, Modal } from '@scuf/common'

class DeleteSiteTable extends PureComponent {

  render () {
    const {
      selectedPackages,
      closeModal,
      deletePackage,
      t,
      loading
    } = this.props
    return (
      <div>
        <Modal.Header>
          <h3>{t('DeleteHeader')}</h3>
        </Modal.Header>
        <Modal.Content scrolling={false}>
          <p>{t('Question')}</p>
        </Modal.Content>
        <Modal.Footer>
          <Button type='secondary' content={t('No')} size='medium' onClick={closeModal} />
          <Button
            onClick={(e) => {
              deletePackage(selectedPackages)
              closeModal()
            }}
            type='primary'
            loading={loading}
            disabled={loading}
            content={t('Yes')}
          />
        </Modal.Footer>
      </div>
    )
  }
}

export default DeleteSiteTable
