import * as React from 'react'
import { useTranslate } from 'react-translate'
import { Tab, Modal } from '@scuf/common'
import QrSection from 'Components/QrSection'
import { AddOtherEquipmentForm } from '../add-other-equipment-form'
import { useAppDispatch } from 'Store'
import ModalActions from 'Redux/ModalRedux'

import styled from 'styled-components/macro'

const Container = styled(Modal.Content)`
  & > #tab-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
`

export const AddAssetsModal: React.FC = () => {
  const translate = useTranslate('AddAssetsModal')
  const dispatch = useAppDispatch()
  const closeModal = React.useCallback(() => {
    dispatch(ModalActions.modalClose())
  }, [dispatch])

  return (
    <>
      <Modal.Header>{translate('AddAssets')}</Modal.Header>
      <Container scrolling={false}>
        <Tab id='tab-container' defaultActiveIndex={0}>
          <Tab.Pane title={translate('Connected')}>
            {/* @ts-ignore */}
            <QrSection isModal />
          </Tab.Pane>
          <Tab.Pane title={translate('NonConnected')}>
            <AddOtherEquipmentForm onCancel={closeModal} onSuccess={closeModal} />
          </Tab.Pane>
        </Tab>
      </Container>
    </>
  )
}
