import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker as ScufDate } from '@scuf/common'
import { useField, useFormikContext } from 'formik'

const DatePicker = ({
  name,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  const { submitCount } = useFormikContext()
  return (
    <ScufDate
      error={(meta.touched || submitCount > 0) && meta.error}
      id={name}
      onBlur={() => helpers.setTouched(true)}
      onChange={helpers.setValue}
      value={field.value}
      {...rest}
    />
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired
}

DatePicker.defaultProps = {
  displayFormat: 'MM/DD/YY, H:mm'
}

export default DatePicker
