import { createSelector, createStructuredSelector } from 'reselect'
import { siteId } from '../../../../../Selectors/DashboardSelector'
import { getRootSiteIds, getSites } from '../../../../../features/site-management/store'
import { SiteType } from '../../../../../features/site-management/service'

export const user = ({ user }) => user.getIn(['userData', 'personIdentifier', 'loginEmailAddress'])
export const siteComparison = ({ modal }) => modal.getIn(['modalProps', 'siteComparison'], false)
export const siteRanking = ({ modal }) => modal.getIn(['modalProps', 'siteRanking'], false)
export const sitePreferences = ({ modal }) => modal.getIn(['modalProps', 'sitePreferences'], false)
export const addSiteToPreferences = ({ modal }) => modal.getIn(['modalProps', 'addSite'], () => {})
export const alertsReportForm = ({ modal }) => modal.getIn(['modalProps', 'alertsReportForm'], () => {})
export const siteCounter = ({ modal }) => modal.getIn(['modalProps', 'siteCounter'], false)
export const getOrganizationsAndSites = createSelector(
  getSites,
  sites => Object.keys(sites).reduce((result, siteId) => {
    const site = sites[siteId]
    if (site.type === SiteType.ORGANIZATION || site.type === SiteType.SITE) {
      result[siteId] = site
    }
    return result
  }, {})
)
export const lastSelectedSiteId = ({ modal }) => modal.getIn(['modalProps', 'selectedSite', 'siteId'], '')
export const lastSelectedSiteLevel = ({ modal }) => modal.getIn(['modalProps', 'selectedSite', 'level'], 0)
export const lastSelectedSiteName = ({ modal }) => modal.getIn(['modalProps', 'selectedSite', 'siteName'], '')
export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  siteTree: getOrganizationsAndSites,
  rootSites: getRootSiteIds,
  siteId,
  user,
  siteComparison,
  siteRanking,
  siteCounter,
  lastSelectedSiteId,
  lastSelectedSiteLevel,
  lastSelectedSiteName,
  sitePreferences,
  addSiteToPreferences,
  theme
})
