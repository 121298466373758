import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  batteryRequest: null,
  batterySuccess: ['data'],
  batteryFailure: null,
  batteryHealthRequest: ['site', 'period', 'number'],
  batteryHealthSuccess: ['data'],
  batteryHealthFailure: null,
  batteryLevelsRequest: ['site', 'date'],
  batteryLevelsSuccess: ['data'],
  batteryLevelsFailure: null
})

export const BatteryTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  entities: {},
  result: [],
  batteryHealth: [],
  batteryHealthLoading: false,
  batteryHealthError: '',
  batteryLevels: [ { label: 'HIGH' }, { label: 'MEDIUM' }, { label: 'LOW' } ],
  batteryLevelsLoading: false,
  batteryLevelsError: ''
})

/* ------------- Reducers ------------- */

export const request = state => state.merge({ loading: true })

export const success = (state, { data }) => state.merge({ loading: false, ...data })

export const failure = state => state.merge({ loading: false, result: [], entities: {} })

export const batteryHealthRequest = state => state.merge({ batteryHealthLoading: true, batteryHealthError: false })

export const batteryHealthSuccess = (state, { data }) =>
  state.merge({ batteryHealthLoading: false, batteryHealth: data, batteryHealthError: false })

export const batteryHealthFailure = state => state.merge({ batteryHealthLoading: false, batteryHealthError: true, batteryHealth: [] })

export const batteryLevelsRequest = state => state.merge({ batteryLevelsLoading: true, batteryLevelsError: false })

export const batteryLevelsSuccess = (state, { data }) =>
  state.merge({ batteryLevelsLoading: false, batteryLevels: data, batteryLevelsError: false })

export const batteryLevelsFailure = state =>
  state.merge({
    batteryLevelsLoading: false,
    batteryLevelsError: true,
    batteryLevels: [ { label: 'HIGH', value: 0 }, { label: 'MEDIUM', value: 0 }, { label: 'LOW', value: 0 } ] 
  })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BATTERY_REQUEST]: request,
  [Types.BATTERY_SUCCESS]: success,
  [Types.BATTERY_FAILURE]: failure,
  [Types.BATTERY_HEALTH_REQUEST]: batteryHealthRequest,
  [Types.BATTERY_HEALTH_SUCCESS]: batteryHealthSuccess,
  [Types.BATTERY_HEALTH_FAILURE]: batteryHealthFailure,
  [Types.BATTERY_LEVELS_REQUEST]: batteryLevelsRequest,
  [Types.BATTERY_LEVELS_SUCCESS]: batteryLevelsSuccess,
  [Types.BATTERY_LEVELS_FAILURE]: batteryLevelsFailure
})
