import styled from 'styled-components'
import { Checkbox } from '@scuf/common'

export const StyledConfigureCard = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 650px;
  height:100px;
  
  border-bottom: 1px solid #E0E0E0;
  & .checkbox {
    margin:.5rem !important;
    min-width:25px !important;
    min-height: 25px !important;
    cursor: pointer;
    display:flex !important;
    align-items:center;
    justify-content:center;
  }
  & label {
    margin-bottom:11px;
    margin-right:14px;
  }
`
export const Image = styled.img`
  height: 35px;
  margin:auto;
`
export const Center = styled.div`
  display:flex;
  align-items: center;
`

export const Title = styled.div`
  font-size: .875rem;
  font-weight:700;
  margin-bottom: 0.25rem;
`
export const Paragraph = styled.div`
  font-size: 0.75rem;
  line-height: .875rem;
`
export const DragIcon = styled.div`
  font-size: 1rem;
  align-self: center;
  margin: 0 1rem;
`
export const TextContainer = styled.div`
  padding: 1rem 0;
  width:100%;
`
export const StyledCheckbox = styled(Checkbox)`
  width: 17px;
  align-self: center;
`
export const ImageContainer = styled.div`
  min-width:35px;
  align-self: center;
  margin-right: 1.5rem;

`
