import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'

export const typesOfModelsCard = ({ dashboard }) =>
  dashboard.getIn(['droppedDevicesCard'], {})

export const devicesList = createSelector(
  [typesOfModelsCard],
  avgBatHp => avgBatHp.getIn(['data', 'devices'], [])
)

export const data = createSelector(
  devicesList,
  (devicesList) => {
    return devicesList.map(value => {
      const hardImpact = value.drops.find(dropType => dropType.dropType === 'HardImpact')
      const softImpact = value.drops.find(dropType => dropType.dropType === 'SoftImpact')
      const freeFall = value.drops.find(dropType => dropType.dropType === 'FreeFall')
      return {
        key: value.serialNumber,
        aliasName: value.aliasName,
        hardImpact: get(hardImpact, 'count', 0),
        softImpact: get(softImpact, 'count', 0),
        freeFall: get(freeFall, 'count', 0),
        siteId: value.deviceSite
      }
    })
  }
)

export default createStructuredSelector({
  data
})
