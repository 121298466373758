import styled from 'styled-components'
import { Select, Button, Checkbox } from '@scuf/common'

export const DrilldownContainer = styled.div`
  display: flex;
  margin: 0rem 1rem;
  padding: 1rem 0;
  flex-direction: column;
  max-width: 1250px;
  min-width: 90%;
`

export const Title = styled.h1`
  font-size: 26px !important;
  font-weight: 500 !important;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
`

export const GeneralContainer = styled.div`
  width: 100%;
  margin-top: -2rem;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  background: ${({theme}) => theme.background ? theme.background : '#f7f7f7'};
  z-index: 2;
  overflow-y: auto;
`

export const BreadcrumbContainer = styled.div`
  width: fit-content;
  cursor: pointer;
  font-size: 1rem !important;
  color: #1792E5 !important;
  display: flex;
  align-items: center;
  & i {
    color: #1792E5 !important;
}

&:hover {
  color:#1274b7 !important;
  & i {
    color: #1274b7 !important;
  }
}
`
export const BreadcrumbTitle = styled.span`
  margin-left: 0.5rem;
  cursor: pointer;
`

export const StyledFilter = styled(Select)`
  margin-right: 1rem;
  width: 10% !important;
  & .dropdown {
    min-height: 2rem !important;
    max-height: 2rem !important;
    padding: .22rem .5rem .5rem .75rem !important;
  }
  & .text {
    line-height: 1.3rem !important;
  }
`
export const StyledModal = styled.div`
  &.ui.large.modal{
    width: 50% !important;
  }
`
export const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
`

export const ModalBody = styled(Flex)`
  & html:not(.honeywell-dark-theme) .ui.single-input > .input-wrap > input {
    height: 2.4rem;
    align-items: center;
  }
  & html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper > .ui.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper > .ui.multiple.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper > .ui.dropdown.disabled.fluid {
    justify-content: space-between;
    padding-right: 1rem;
}
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
`

export const ScheduleForm = styled.div`
`

export const Column = styled(Flex)`
  flex-direction: column;
  style: ${({ style }) => style || ''};

`
export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-bottom: 2rem;
`
export const StepOne = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  width: 65%;
`
export const Paragraph = styled.p`
  padding-top: 1rem;
  padding-bottom: 1rem;
`
export const TimeWrapper = styled.p`
  display: flex;
  flex-direction: row;
`
export const IndividualTime = styled.p`
  display: flex;
  flex-direction: column;
  width: 25%;
`
export const SelectorContainer = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.black ? theme.black : '#fff'};
  margin: 1.5rem 0rem;
`

export const SiteSelector = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-right: ${props => !props.noPadding && props.theme.padding};
  padding-left: 1rem;
  .delete.icon {
    z-index: 200;
  }
  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
  .ui.fluid.dropdown {
    display: block !important;
  }
`

export const IconContainer = styled.div`
  min-width: 39px;
  min-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${({theme}) => theme.background ? theme.background : '#f7f7f7'};
}
`
export const AlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40%;
`
export const AlertLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0rem;
  opacity: ${({ enabled }) => !enabled ? 0.5 : 1};
`

export const ButtonAsAlerts = styled(Button)`
  min-width: auto;
  display: flex;
  width: max-content;
  margin-bottom: 1rem !important;
`
export const StyledCheckbox = styled(Checkbox)`
  margin-top: 50px !important;
  &.ui.checkbox input ~ label {
    font-weight: 700 !important;
  }
`
