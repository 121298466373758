import React from 'react'
import PropTypes from 'prop-types'
import { Select as ScufSelect } from '@scuf/common'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'

const StyledSelect = styled(ScufSelect)`
  width:100% !important;
  & .text {
    width:100%;
  }
`

const Input = ({
  name,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  const { submitCount } = useFormikContext()
  return (
    <StyledSelect
      error={(meta.touched || submitCount > 0) && meta.error}
      id={name}
      onBlur={() => helpers.setTouched(true)}
      onChange={helpers.setValue}
      value={field.value}
      {...rest}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired
}

export default Input
