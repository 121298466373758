import React, { Component, PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, VerticalMenu, Popup } from '@scuf/common'
import styled from 'styled-components/macro'
import { translate } from 'react-translate'

export const ActionBarContainer = styled.div`
  display: flex;
  background: transparent;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1}
  & .below-text {
    display: none;
  }
`

const IconWrapper = styled.div`
  flex: 1;
  textAlign: right;
  margin-left: 1rem;
`

export default class ActionBar extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedAction: 'add'
    }
    this._handleAdd = this._handleAdd.bind(this)
    this._handleEdit = this._handleEdit.bind(this)
    this._handleDelete = this._handleDelete.bind(this)
    this._handleUpload = this._handleUpload.bind(this)
  }

  _handleAdd () {
    this.setState({ selectedAction: 'add' })
    this.props.handleAdd()
  }

  _handleEdit () {
    this.setState({ selectedAction: 'edit' })
    this.props.handleEdit()
  }

  _handleDelete () {
    this.setState({ selectedAction: 'delete' })
    this.props.handleDelete()
  }

  _handleUpload () {
    this.setState({ selectedAction: 'upload' })
    this.props.handleUpload()
  }

  render () {
    const { addIconExtra, allowAdd, allowDelete, allowEdit, allowUpload, deleteConfirmation,
      iconUploadProps, iconAddProps, iconDeleteProps, iconEditProps, style } = this.props
    return (
      <ActionBarContainer
        style={!this.props.children ? { ...style, justifyContent: 'flex-end' } : style}
        {...this.props}
        className={`Action-Bar ${this.props.className || ''}`}
      >
        {this.props.children}
        {allowAdd || allowEdit || allowDelete
          ? <ActionBarSection flex='0'>
            {allowUpload
              ? <IconWrapper>
                <Icon className='Icon' size='medium' name='file-upload' {...iconUploadProps} onClick={() => this._handleUpload()} />
              </IconWrapper>
              : null
            }
            { allowAdd
              ? <IconWrapper>
                <Icon className='Icon' size='medium' name='add' root='building' {...iconAddProps} onClick={() => this._handleAdd()}>{addIconExtra}</Icon>
              </IconWrapper>
              : null
            }
            { allowEdit
              ? <IconWrapper>
                <Icon className='Icon' size='medium' name='edit' root='common' {...iconEditProps} onClick={() => this._handleEdit()} />
              </IconWrapper>
              : null
            }
            { allowDelete
              ? <IconWrapper>
                {deleteConfirmation
                  ? <Popup
                    element={
                      <Icon className='Icon' size='large' name='delete' {...iconDeleteProps} />
                    }
                    on='click'
                    position='bottom left'>
                    <VerticalMenu>
                      <VerticalMenu.Header>{'Delete this item?'}</VerticalMenu.Header>
                      <VerticalMenu.Item onClick={() => null}>{'No'}</VerticalMenu.Item>
                      <VerticalMenu.Item onClick={() => this._handleDelete()}>{'Yes'}</VerticalMenu.Item>
                    </VerticalMenu>
                  </Popup>
                  : <Icon className='Icon' size='medium' name='delete' root='common' {...iconDeleteProps} onClick={() => this._handleDelete()} />
                }
              </IconWrapper>
              : null
            }
          </ActionBarSection>
          : null
        }
      </ActionBarContainer>
    )
  }
}

ActionBar.Section = ActionBarSection

ActionBar.propTypes = {
  addIconExtra: PropTypes.any,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowEdit: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpload: PropTypes.func,
  children: PropTypes.node,
  isHeader: PropTypes.bool,
  deleteConfirmation: PropTypes.bool,
  t: PropTypes.func,
  iconUploadProps: PropTypes.object,
  iconAddProps: PropTypes.object,
  iconDeleteProps: PropTypes.object,
  iconEditProps: PropTypes.object
}

ActionBar.defaultProps = {
  addIconExtra: null,
  allowAdd: false,
  allowDelete: false,
  allowEdit: false,
  handleEdit: () => null,
  handleAdd: () => null,
  handleDelete: () => null,
  handleUpload: () => null,
  isHeader: true,
  deleteConfirmation: true
}

ActionBar.Section.propTypes = {
  children: PropTypes.node
}
