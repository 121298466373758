import React, { useEffect, useState } from 'react'
import { get, find } from 'lodash'
import { toast } from 'react-toastify'
import { translate } from 'react-translate'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { MODAL_TOAST_CONTAINER } from 'Utils/constants'
import { TypeApi, DeviceApi } from '../../Services'

const Error = translate('UploadAsset')(
  ({ t }) => <ErrorToast message={t('Error loading models')} />
)

const ErrorOsVersion = translate('UploadAsset')(
  ({ t }) => <ErrorToast message={t('RequestFailed')} />
)

export const useDeviceModels = (deviceType, fileType) => {
  const [types, setTypes] = useState({})
  const [models, setModel] = useState([])
  const [fileTypes, setFiles] = useState([])
  const [extensions, setExtensions] = useState([])
  useEffect(() => {
    const getDevices = async () => {
      const response = await TypeApi.getDevicesModels()
      if (response.ok) {
        const types = get(response, 'data.entities.devices', {})
        setTypes(types)
      } else {
        toast(
          <Error />
          , { containerId: MODAL_TOAST_CONTAINER }
        )
      }
    }
    getDevices()
  }, [])

  useEffect(() => {
    const DeviceType = find(types, ['type', `${deviceType}`])
    const files = get(DeviceType, 'mimeTypes', [])
      .map(file => {
        return {
          value: `${file.category}-${file.type}`,
          text: file.displayName,
          extensions: file.extensions
        }
      })
    const model = get(DeviceType, 'deviceModelsV2', [])
      .filter(model => model.displayName)
      .map(model => {
        return {
          text: model.displayName,
          value: model.name
        }
      })
    const uploadExtensions = files.find(item => item.value === fileType)
    setExtensions(uploadExtensions)
    setModel(model)
    setFiles(files)
  }, [deviceType, types, fileType])

  return {
    models,
    fileTypes,
    extensions,
    assetTypes: types
  }
}

export const getOsVersion = async () => {
  const response = await DeviceApi.getOsVersions()
  if (response.ok) {
    return response.data?.data?.map(x => {
      return { text: x.os + ' ' + x.version, value: x.version }
    })
  } else {
    toast(
      <ErrorOsVersion />
      , { containerId: MODAL_TOAST_CONTAINER }
    )
  }
}
