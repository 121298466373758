import React from 'react'
import { Tooltip } from '@scuf/common'
import { useAssetLastOnline } from 'Components/DeviceLastOnline'
import DeviceStatus from 'Components/DeviceStatus'
import styled from 'styled-components/macro'

const StatusTitle = styled.p`
  padding-left: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const StyledTooltip = styled(Tooltip)`
background: ${props => props.theme.h1color};
color: ${props => props.theme.grayback};
&:before{
  display: none !important;
}
`

export function DeviceLastStatus(props) {
  return (
    <StatusTitle>
      <DeviceStatus {...props} />
    </StatusTitle>
  )
}

export function DeviceLastOnline(props) {
  const { dateFromNow, formattedDay } = useAssetLastOnline(props)

  return (
    <StyledTooltip
      content={formattedDay}
      element={
        <StatusTitle>
          {dateFromNow}
        </StatusTitle>
      }
      event='hover'
      size='mini'
    />
  )
}
