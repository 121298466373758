import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { pipe } from 'ramda'
import moment from 'moment-timezone'
import get from 'lodash/get'

import { getUpdatedTime } from './time-selector.selectors'
import {
  DateTimeContainer, Row, StyledDatePicker,
  StyledInput,
  Line,
  StyledFilter
} from './time-selector.styles'
import { getDateTime } from './time-selector.helpers'

function Immediate ({
  hidden,
  limitNumberOfDays,
  init,
  t,
  timeZone,
  onChange
}) {
  const timeRange = useMemo(() =>
    Array.from(new Array(24), (_, index) => ({
      value: moment({ hour: index }),
      text: moment({ hour: index }).format('h:mm A')
    })), [])

  const [initDateTime] = useState(moment.tz(moment(), timeZone))

  const [endDatetime, setEndDatetime] = useState(() => {
    return init?.EndTime ? init.EndTime : initDateTime
  })

  const [to, setTo] = useState(() => {
    return init?.To ? init.To : undefined
  })
  const [toTimeError, setToTimeError] = useState('')
  const [errorMsgEnd, setErrorToMsgEnd] = useState('')

  const _handleTextChange = (value, error) => {
    if (error || value === '') {
      setErrorToMsgEnd(t('SelectFutureDate'))
    } else {
      setErrorToMsgEnd('')
    }
  }

  const _changeToTime = useCallback(
    to => {
      const { toDateTime, toTime } = getDateTime({ endDatetime, timeZone, toTime: to })
      if (toDateTime) {
        setEndDatetime(toDateTime)
      }
      setTo(toTime)
    } ,[endDatetime, timeZone, setEndDatetime, setTo])

  function isValidSelection (date, time) {
    if (limitNumberOfDays && moment.utc(endDatetime).diff(initDateTime, 'days') > limitNumberOfDays - 1) {
      setErrorToMsgEnd(t('DateRangeCannotBeOver30Days'))
      return false
    }
    else{
      setErrorToMsgEnd('')
    }
    if (!((date && time) && (date.isValid() && time.isValid()))) {
      return false
    }
    const now = moment()
    const selectedTime = moment.tz(date, timeZone)
      .set({
        hour: time.hour(),
        minute: time.minutes(),
        second: 0,
        millisecond: 0
      })
    if (selectedTime.isBefore(now)) {
      setToTimeError(t('ToHourInPast'))
      return false
    } else {
      setToTimeError('')
    }
    return true
  }

  const initFrom = useMemo(() => moment.tz(initDateTime, timeZone).format('h:mm A'), [initDateTime])

  useEffect(() => {
    const selection = {
      OnSchedule: false,
      Recurring: false,
      DateTime: moment.tz(timeZone),
      EndTime: moment.tz(timeZone),
      TimeZone: timeZone,
      From: initFrom,
      To: to,
      FinalDateTime: getUpdatedTime(initDateTime, initDateTime, timeZone),
      FinalEndTime: getUpdatedTime(endDatetime, to, timeZone) || '',
      CronExpression: '',
      IsValid: timeZone && isValidSelection(endDatetime, to),
      Priority: 'mandatory'
    }
    onChange(selection)
  }, [endDatetime, to])

  const _momnentSetEndDate = pipe(moment, setEndDatetime)
  const _handleDateChange = date => {
    _momnentSetEndDate(date)
    setTo(undefined)
  }

  return (
    <Row style={{ justifyContent: 'space-between' }} hidden={hidden}>
      <diV>
        <DateTimeContainer>
          <StyledDatePicker
            value={initDateTime}
            timezone={timeZone}
            label={t('StartingDate')}
            highlightWeekends={true}
            type='date'
            disabled={true}
          />
          <StyledInput
            value={initFrom}
            disabled={true}
            label={t('From')}
          />
        </DateTimeContainer>
      </diV>
      <Line />
      <diV>
        <DateTimeContainer>
          <StyledDatePicker
            value={endDatetime}
            error={errorMsgEnd}
            timezone={timeZone}
            label={t('EndBy')}
            disablePast={true}
            onChange={_handleDateChange}
            onTextChange={_handleTextChange}
            highlightWeekends={true}
            type='date'
          />
          <StyledFilter
            key={to}
            label={t('To')}
            options={timeRange}
            onChange={_changeToTime}
            error={toTimeError}
            value={to}
          />
        </DateTimeContainer>
      </diV>
    </Row>
  )
}

export default Immediate
