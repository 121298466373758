import styled from 'styled-components'

export const DescriptionContainer = styled.div`
padding-bottom: 10px;
`

export const JobIdentifierContainer = styled.div`
font-size: 12px;
padding-top: 10px;
`

export const MasterGuidContainer = styled.div`
text-align: end;
`
