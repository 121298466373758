import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react'
import { register } from '../../registerServiceWorker'

const ServiceWorkerContext = createContext()
export const useServiceWorker = () => useContext(ServiceWorkerContext)

export const ServiceWorkerProvider = ({ children }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [isNewVersion, setisNewVersion] = useState(false)
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [isBannerClosed, setIsBannerClosed] = useState(false)

  useEffect(() => {
    register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting)
        setisNewVersion(true)
      }
    })
  }, [])

  useEffect(() => {
    if (!waitingServiceWorker || isAppLoading) return
    waitingServiceWorker.addEventListener('statechange', event => {
      if (event.target.state === 'activated') {
        setIsAppLoading(true)
        window.location.reload()
      }
    })
  }, [waitingServiceWorker])

  const closeBanner = () => setIsBannerClosed(true)

  const value = useMemo(() => ({
    isNewVersion,
    isBannerClosed,
    closeBanner,
    sendUpdateAppMessage: () => {
      if (waitingServiceWorker) {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    }
  }), [isNewVersion, waitingServiceWorker, isBannerClosed])

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}
