import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { SsoOauthApi } from 'Services'
import config from 'Config/AppConfig'

const PREFIX = 'SSO_OAUTH/'

export const setOauthInfo = createAction(
  PREFIX + 'SET_OAUTH_INFO',
  oauthProviderInfo => ({ payload: oauthProviderInfo })
)

export const setRedirectRoute = createAction(
  PREFIX + 'SET_REDIRECT_ROUTE',
  route => ({ payload: route })
)

export const changeOrganization = createAction(
  PREFIX + 'CHANGE_ORGANIZATION',
  organizationId => ({ payload: organizationId })
)

export const exchangeOauthCode = createAsyncThunk(
  PREFIX + 'EXCHANGE_OAUTH_CODE',
  async (code, { getState }) => {
    const { ssoOauth } = getState()
    const response = await SsoOauthApi.getToken({
      code,
      redirectUrl: config.auth.oauth.redirect_uri,
      organizationId: ssoOauth.selectedOrg,
      ...ssoOauth
    })
    if (response.ok) {
      return response.data
    }

    throw response.originalError
  }
)
