import SiteEditTable from './SiteEditTable'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { translate } from 'react-translate'
import SiteEditTableActions from '../../../../../../Redux/DeviceRedux'
import SiteEditTableSelect from './SiteEditTable.selector'

export const mapDispatchToProps = dispatch => ({
  onSubmit: () => { },
  updateBulkEditData: newData => dispatch(SiteEditTableActions.updateBulkEditData(newData))
})

export default pipe(
  translate('SiteBulkEdit'),
  connect(SiteEditTableSelect, mapDispatchToProps)
)(SiteEditTable)
