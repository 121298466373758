import { createReducer } from '@reduxjs/toolkit'

import {
  releaseNotesCloseodal,
  releaseNotesOpenModal,
  fetchReleaseNotes
} from './actions'

export const INITIAL_STATE = {
  isModalOpen: false,
  isLoading: false,
  releases: []
}
const fetchNotesReject = (state, { payload }) => {
  state.isLoading = false
  state.error = payload
}

const fetchNotesFullfilled = (state, { payload }) => {
  state.isLoading = false
  state.releases = payload.releaseCommunications
}

export default createReducer(INITIAL_STATE, {
  [releaseNotesOpenModal]: state => {
    state.isModalOpen = true
  },
  [releaseNotesCloseodal]: state => {
    state.isModalOpen = false
  },
  [fetchReleaseNotes.pending]: state => {
    state.isLoading = true
  },
  [fetchReleaseNotes.rejected]: fetchNotesReject,
  [fetchReleaseNotes.fulfilled]: fetchNotesFullfilled
})
