import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Icon, Radio } from '@scuf/common'
import { FilterCheckBoxContainer } from './filter.styles'

const FilterItem = memo(({
  onChange,
  icon,
  iconRoot,
  label,
  active,
  type,
  children
}) => {
  return (
    <span>
      <FilterCheckBoxContainer>
        {type === 'checkbox'
          ? <Checkbox
            label={label.includes('_') ? label.split('_')[1] : label}
            checked={active}
            onChange={onChange}
          />
          : <Radio
            label={label.includes('_') ? label.split('_')[1] : label}
            checked={active}
            onChange={onChange}
          />
        }
        <Icon name={icon} root={iconRoot} size='xsmall' />
        
      </FilterCheckBoxContainer>
      {children}
    </span>
  )
})

FilterItem.propTypes = {
  type: PropTypes.oneOf([
    'checkbox',
    'radio'
  ])
}

FilterItem.defaultProps = {
  type: 'checkbox'
}

export default FilterItem
