import React, { Component } from 'react'
import { FilterSectionContainer, SectionTitleRow, CaretIcon } from './filter.styles'
import { InfoKey } from '../../../../Themes/ScufStyledComponents'

class FilterSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: props.defaultExpanded ?? false
    }
  }

  render() {
    const { label, children } = this.props
    const { expanded } = this.state
    return (
      <FilterSectionContainer>
        <SectionTitleRow onClick={() => this.setState({ expanded: !expanded })}>
          <InfoKey>{label}</InfoKey>
          <CaretIcon
            name={expanded ? 'caret-up' : 'caret-down'}
            size='medium'
            description='Expand'
          />
        </SectionTitleRow>
        <div style={{ display: expanded ? 'initial' : 'none' }}>
          {children}
        </div>
      </FilterSectionContainer>
    )
  }
}

export default FilterSection
