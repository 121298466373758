import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeviceFormTable from './device-form-table'
import DeviceActions from '../../../../Redux/DeviceRedux'
import mapStateToProps from './device-form-table.selector'

const mapDispatchToProps = dispatch => ({
  setMultipleDevices: (id) => dispatch(DeviceActions.deviceToggleMultiple(id))
})

export default translate('Devicetable')(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceFormTable))
