import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { TaggingActionCreators } from 'Store/tagging'

import {
  Icon,
  Button
} from '@scuf/common'
import {
  MenuContainer,
  MenuTitle,
  ControlsContainer,
  ControlsRow,
  RowNumber
} from './edit-tags.styles'
import {
  TagsContainer
} from '../../tags.styles'

import Select from '../select'
import { ButtonContainer } from 'Components/ScheduleModal/schedule-modal.styles'

const EditTags = ({
  onCloseEdit,
  selectedTags,
  getValues,
  updateValue,
  isAssetDetail,
  updateTags,
  siteId,
  permissions,
  t,
  ...rest
}) => {
  useEffect(() => {
    getValues()
  }, [getValues])

  const dispatch = useDispatch()
  const sendTagsUpdate = (tags) => {
    dispatch(TaggingActionCreators.updateTagsRequest(
      tags.map(a => ({ assetId: a.assets[0], key: a.key, newValue: a.newValue, value: a.value, siteId }))))
  }
  let hasCreateTaggingPermissions = true

  if(permissions['tagging:create']){
    hasCreateTaggingPermissions = true
  }
  else{
    hasCreateTaggingPermissions = false
  }

  return (
    <Fragment>
      <MenuContainer>
        <MenuTitle>
		{t('EditTags')}
        </MenuTitle>
        <Icon
          color='#FFFFFF'
          name='close'
          onClick={onCloseEdit}
          size='small'
        />
      </MenuContainer>
      <TagsContainer>
        <ControlsContainer>
          {selectedTags.map((tag, i) =>
            <ControlsRow key={tag.id}>
              <RowNumber>{i + 1}</RowNumber>
              <Select
                disabled={true}
                label={i ? '\u00A0' : t('Name')}
                options={[]}
                placeholder={tag.key}
              />
                <Select
                  defaultValue={tag.value}
                  label={i ? '\u00A0' : t('Value')}
                  onChange={value => updateValue(tag.id, value)}
                  options={tag.values || []}
                  search={hasCreateTaggingPermissions}
                />
            </ControlsRow>
          )}
        </ControlsContainer>
      </TagsContainer>
      <ButtonContainer style={{ paddingTop: '.5rem' }}>
        {isAssetDetail &&
          <Button
            content={t('Apply')}
            onClick={() => sendTagsUpdate(selectedTags)} />
        }
      </ButtonContainer>
    </Fragment>
  )
}

EditTags.defaultProps = {
  selectedTags: [],
  t: label => label
}

EditTags.propTypes = {
  selectedTags: PropTypes.array,
  t: PropTypes.func
}

export default EditTags
