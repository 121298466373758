import { withFormik } from 'formik'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import AlertsManagementModal from './alerts-management-modal.component'

export default pipe(
  withFormik({
    displayName: 'ViewMaintenanceRuleModal',
    mapPropsToValues: ({ rule }) => rule,
    enableReinitialize: true
  }),
  translate('AlertsManagementModal')
)(AlertsManagementModal)
