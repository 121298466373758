import { createStructuredSelector, createSelector } from 'reselect'
import { totalDevices, statusCode, loading, representativePercentage, totalOfflineDevices } from '../OfflineDevicesByType/offline-devices-by-type.selector'
import { toUpper } from 'lodash'

export const groupByModelAndSumValue = data => {
  const series = {}
  data.forEach(({ key, value }) => {
    if (key) {
      key = toUpper(key)
      series[key] = series[key] || 0
      series[key] += value
    }
  })
  return series
}

export const currentCulture = state => state.user.language

export const dashboard = state => state.dashboard

export const hoursOffline = createSelector(
  [dashboard],
  hoursOffline => hoursOffline.getIn(['hoursOffline'], 72)
)

export const filteredDevices = createSelector(
  [totalDevices],
  devicesStatus => devicesStatus.getIn(['data'], [])
    .filter(device => device.property === '0')
    .map(device => {
      const sitePath = (device.member || '').split('/')
      if (sitePath.length) {
        return { ...device, site: sitePath[sitePath.length - 1] }
      } else {
        return device
      }
    })
)

export const top = createSelector(
  [filteredDevices],
  devices => {
    const groupedDevices = groupByModelAndSumValue(devices)
    return representativePercentage(groupedDevices)
  }
)

export const siteId = createSelector(
  [dashboard],
  dashboard => dashboard.getIn(['siteId'], '')
)

const isSubscribedFeature = ({ user }) => user.subscription?.features?.includes('devices.offline_by_model')

export default createStructuredSelector({
  statusCode,
  loading,
  top,
  currentCulture,
  hoursOffline,
  totalOfflineDevices,
  siteId,
  isSubscribed: isSubscribedFeature
})
