import styled from 'styled-components'

export const EmptyTableContainer = styled.div`
display: flex;
flex-direction:column;
text-align: center;
justify-content:center;
align-items:center;
border: 1px solid #d8d8d8;
height: 100%;
& .ui.button.primary {
  margin: 1rem 0 0 0 !important;
}
`

export const EmptyText = styled.div`
  font-size: 1.25rem;
  font-weight:500;
  max-width:70vh;
  color:#606060;
  padding: 1rem 0;
`
