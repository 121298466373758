export const START_RV = 'StartRV'

export const STOP_RV = 'StopRV'

export const CHILD_CLOSED = 'ChildClosed'

export const DEVCOMMAND_RVSTART = 'DEVCOMMAND-RVSTART'

export const DEVCOMMAND_RVSTOP = 'DEVCOMMAND-RVSTOP'

export const GET_ACCESS_TOKEN = 'GetAccessToken'

export const ACCESS_TOKEN_RECEIVED = 'AccessTokenReceived'

export const REMOTE_ACCESS_DENIED = 'RemoteAccessDenied'

