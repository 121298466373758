import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'
import { reduce, assoc } from 'ramda'

export const bulkEditData = ({ devices }) => devices.getIn(['devicesBulkEdit'])

export const sites = ({ siteManager }) => siteManager.getIn(['sites'])

export const devicesData = ({ devices }) => devices.getIn(['devices'])

export const loading = ({ devices }) => devices.getIn(['loading'])

export const devices = createSelector(
  [devicesData],
  devices => reduce(
    (acc, serial) => assoc(serial, {
      site: get(devices[serial], ['originalObject', 'institutionAssociation', 'organizationalUnitSiteAssociation', 'organizationalUnitIdentifier', 'organizationalUnitGuid']),
      alias: get(devices[serial], 'deviceName')
    }, acc),
    {},
    Object.keys(devices)
  )
)

export const validateSiteName = createSelector(
  [sites, bulkEditData],
  (sites, data) => data.map(deviceData => {
    const siteData = sites[get(deviceData, 'siteId.value', null)]
    return {
      ...deviceData,
      site: {
        value: get(siteData, ['organizationalUnitHierarchy'], ''),
        error: siteData ? '' : 'Not found'
      }
    }
  })
)

export const validateSerialNumber = createSelector(
  [devicesData, bulkEditData],
  (devices, data) => data.map(deviceData => {
    const deviceFound = devices[get(deviceData, 'DeviceSerialNumber.value', null)]
    return {
      ...deviceData,
      serial: {
        value: deviceFound ? get(deviceData, 'DeviceSerialNumber.value') : 'Device not found',
        error: deviceFound ? '' : 'Not found'
      }
    }
  })
)

export const validateAlias = createSelector(
  [validateSerialNumber],
  data => data.map(deviceData => {
    const alias = get(deviceData, 'NewDeviceAlias.value', '')
    return {
      ...deviceData,
      alias: {
        value: alias,
        error: alias ? '' : 'Field required'
      }
    }
  })
)

export const sitesById = ({ siteManager }) => siteManager.getIn(['sitesById'])

export const sitesList = createSelector(
  [sitesById],
  sites => sites.map(site => site.name.replace(',', '|'))
)

export const mapSiteNameToSiteId = createSelector(
  [sitesById],
  sites => sites.reduce((acc, curr) => {
    acc[curr.name] = curr.guid
    return acc
  }, {})
)

export default createStructuredSelector({
  data: validateAlias,
  sites,
  sitesById,
  sitesList,
  devices,
  mapSiteNameToSiteId,
  loading
})
