export const ACCESS_POINT = 'Wifi'
export const ULTRA_WIDE_BAND = 'UltraWideBand'
export const BLUETOOTH = 'Bluetooth'
export const NOT_SELECTED = 'NotSelected'

export const NOT_SELECTED_TYPE = -1
export const ACCESS_POINT_TYPE = 8
export const BLUETOOTH_TYPE = 9
export const ULTRA_WIDE_BAND_TYPE = 10

export const TYPE_MAPPINGS = {
  ACCESS_POINT: ACCESS_POINT_TYPE,
  BLUETOOTH: BLUETOOTH_TYPE,
  ULTRA_WIDE_BAND: ULTRA_WIDE_BAND_TYPE,
  NOT_SELECTED: NOT_SELECTED_TYPE,

  [ACCESS_POINT_TYPE]: ACCESS_POINT,
  [BLUETOOTH_TYPE]: BLUETOOTH,
  [ULTRA_WIDE_BAND_TYPE]: ULTRA_WIDE_BAND,
  [NOT_SELECTED_TYPE]: NOT_SELECTED
}
