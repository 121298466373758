import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'
import { formatAppName } from 'Utils/format-app-names'
import { formatBytes } from 'Utils/BytesConverter'



export const appData = ({ dashboard }) => dashboard.getIn(['appData'], [])

const filteredData = createSelector(
  [appData],
  apps => Object.keys(apps)
    .map(app => apps[app])
    .filter(f => (get(f, 'network.received', 0)) && (get(f, 'network.sent', 0)))
)

export const data = createSelector(
  [filteredData],
  apps => apps.map(app => {
    return ({
      appName: formatAppName(app.packagename),
      pkgName: app.packagename,
      netIn: formatBytes(app['network.received']),
      netOut: formatBytes(app['network.sent'])
    })
  })
)

const finalData = createSelector(
  [data],
  apps => apps.filter(f => (f.netIn + f.netOut) > 0)
)


export default createStructuredSelector({
  appData,
  data: finalData
})
