import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../../AssetManagementTable'
import { Link } from 'react-router-dom'
import DeviceSite from 'Components/AssetManagementBase/components/device-assigned-site'

export const columns = [
  { field: 'value', translation: 'AssetManagementTable_faultyDotsSerialNumber', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'aliasName', translation: 'AssetManagementTable_alias', render: null },
  { field: 'key', translation: 'AssetManagementTable_faultyDotsSerialNumberValue', render: null },
  { field: 'deviceSite', translation: 'AssetManagementTable_deviceSite', render: ({ value }) => <DeviceSite cellData={value} /> },
]

const PrinterFaultyDots = ({ data, t }) => {
  return (
    <div>
      <Modal.Header>{t('PrinterFaultyDots_title')}</Modal.Header>
      <Modal.Content>
        <AssetManagementTable
          columns={columns}
          data={data}
          disableRowClick
          fileName={t('PrinterFaultyDots_title')}
        />
      </Modal.Content>
    </div>
  )
}

export default PrinterFaultyDots
