import React from 'react'
import PropTypes from 'prop-types'
import { StyledSelect } from 'Components/AssetManagementBase/components/filters/filters'
import {
  Select
} from 'Components/Shared/formiks'

import { TimeContainer } from './alerts-management-modal.styles'

const hours = Array.from(Array(24),
  (_, h) => ({text: h < 10 ? `0${h}:00` : h + ':00', value: h})
)

const TimeSelection = (
  props
) => {
  return (
    <TimeContainer>
      <Select
        options={hours}
        fluid
        {...props}
      />
    </TimeContainer>
  )
}

TimeSelection.defaultProps = {
  t: label => label
}

TimeSelection.propTypes = {
  t: PropTypes.func
}

export default TimeSelection
