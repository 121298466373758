
import React from 'react'
import styled from 'styled-components'
import { Icon, Button } from '@scuf/common'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

export const AssetsContainer = styled.div`
  width: 100%;
  height: 86vh;
`
export const ExportLimitDiv = styled.div`
  width: 100%
  height: 100%;
  padding-left: 0.5rem;
`

export const SubAssetsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
`

export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1rem !important;
  padding-left: 0.5rem;
`
export const StyledIconModal = styled(Icon)`
  color: #1B1D1F;
  font-size: 1.2rem !important;
  margin-top: 0.15rem;
  align:left ! important
`
export const TableContainer = styled.div`
  height: calc(100% - 3rem);
  & ${StyledIcon} {
    padding-left: 0px;
  }
  @media (max-width: 767px) {
  height: 90%;

  }

`
export const StyledUploadIcon = styled.div`
  color: #1B1D1F;
  font-size: 1rem !important;
  padding-right: 0.1rem;
  margin-top: 0.05rem;
  padding-left: 1.5rem;
`

export const OnboardButtonHolder = styled.div`
  margin: 1rem;
`
export const StyledButton = styled(Button)`
  margin-top: 1rem;
`
export const ActionText = styled.div`
padding-left: 8px;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px;
`

export const CenterWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`

export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'all'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`

export const DisplaySimpleRow = styled.div`
@media (max-width: 768px) {
  display: flex;
}
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  & * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#1B1D1F'};
  }
  &:hover * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#404040'};
  }
`

export const TableLoader = (theme) => (
  <ContentLoader
    primaryColor={get(theme, 'theme.backgroundheader') === '#000' ? '#202020' : '#f3f3f3'}
    secondaryColor={get(theme, 'theme.backgroundheader') === '#000' ? '#101010' : '#ecebeb'}
    height={400}
    style={{ height: '100%', width: '100%' }}
  >
    <rect x='0' y='0' width='400' height='36' />
    <rect x='0' y='48' width='400' height='400' />
  </ContentLoader>
)

export const EditAssetsActionBar = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`
