import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import { Modal } from '@scuf/common'

import DataTable from '../DataTable'
import { TableContainer } from '../../Themes/ScufStyledComponents'

const DataTableContainer = styled.div`
  margin-top: 0.5rem;
  width: 99.95%;
  height: 95%;
`

const ModalContent = styled(Modal.Content)`
  height: 100%;
  width: 100%;
  overflow-x: unset;
`

const UpdatesModal = ({
  updateList,
  updateCount,
  t,
  firmwareRequestToUpdate,
  devices
}) => {
  return (
    <Fragment>
      <Modal.Header>
        {t('Select assets')}
      </Modal.Header>
      <ModalContent>
        <DataTableContainer>
          <DataTable
            search
            data={updateList}
            selection
            totalRows={updateCount}
            rows={updateCount}
            rowHeight={40}
            generateFilters
            filterSections={[
              {
                path: 'model',
                label: 'Model',
                type: 'checkbox'
              }
            ]}
          >
            <DataTable.ActionBar>
              <DataTable.ActionBar.Item
                content={t('Apply')}
                onClick={firmware => firmwareRequestToUpdate(firmware, devices)}
              />
            </DataTable.ActionBar>
            <DataTable.Column
              label={t('Name')}
              dataKey='displayName'
              width={0.75}
              flexShrink={0.75}
              flexGrow={0.75}
            />
            <DataTable.Column
              label={t('Version')}
              dataKey='version'
              cellRenderer={({ cellData }) => {
                return cellData || 'Not applicable'
              }}
              width={0.5}
              flexShrink={0.5}
              flexGrow={0.5}
            />
            <DataTable.Column
              label={t('Importance')}
              dataKey='priority'
              width={0.2}
              flexShrink={0.2}
              flexGrow={0.2}
            />
            <DataTable.Column
              label={t('File size')}
              dataKey='fileSize'
              width={0.2}
              flexShrink={0.2}
              flexGrow={0.2}
            />
            <DataTable.Column
              label={t('Release Date')}
              dataKey='releaseDate'
              cellRenderer={({ cellData }) => {
                return cellData ? moment(cellData).format('Mo MMM YYYY') : t('Unknown')
              }}
              width={0.4}
              flexShrink={0.4}
              flexGrow={0.4}
            />
            <DataTable.Column
              label={t('Type')}
              dataKey='assetCategory'
              width={0.3}
              flexShrink={0.3}
              flexGrow={0.3}
            />
          </DataTable>
        </DataTableContainer>
      </ModalContent>
    </Fragment>
  )
}

UpdatesModal.defaultProps = {
  t: l => l
}

export default UpdatesModal
