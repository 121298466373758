import { createStructuredSelector } from 'reselect'

const initialState = {
  pageNumber: 1,
  search: '',
  sortField: 'firstName',
  sortDirection: 'ASC',
  selectedUser: {},
  selectedRow: [],
  auxiliarSelectedRow: null,
  hideActionBar: false,
  isDeleteModalOpen: false,
}

export const usersListReducer = (state = initialState, { payload, type } = {}) => {
  switch (type) {
    case resetTableSelection.type:
      return {
        ...state,
        selectedUser: {},
        hideActionBar: true,
        selectedRow: [],
        auxiliarSelectedRow: [],
        isDeleteModalOpen: false
      }
    case resetTableFilters.type:
      return {
        ...state,
        pageNumber: 1,
        search: '',
        sortField: 'firstName',
        sortDirection: 'ASC',
      }
    case setPageNumber.type:
      return {
        ...state,
        pageNumber: payload
      }
    case setSearch.type:
      return {
        ...state,
        search: payload,
        pageNumber: 1
      }
    case setSort.type:
      return {
        ...state,
        pageNumber: 1,
        sortField: payload.sortField,
        sortDirection: payload.sortDirection
      }
    case setSelectedUser.type:
      return {
        ...state,
        selectedUser: payload
      }
    case setSelectedRow.type:
      return {
        ...state,
        selectedRow: payload,
        hideActionBar: false
      }
    case setAuxiliarSelectedRow.type:
      return {
        ...state,
        auxiliarSelectedRow: payload
      }
    case setDeleteModalIsOpen.type:
      return {
        ...state,
        isDeleteModalOpen: payload
      }
    default:
      return state
  }
}

const usersListReducerPrefix = 'users-list'
usersListReducer.slice = usersListReducerPrefix

export const resetTableSelection = () => ({ type: resetTableSelection.type })
resetTableSelection.type = usersListReducerPrefix + '/resetTableSelection'

export const resetTableFilters = () => ({ type: resetTableFilters.type })
resetTableFilters.type = usersListReducerPrefix + '/resetTableFilters'

export const setPageNumber = (pageNumber = 0) => ({ type: setPageNumber.type, payload: pageNumber })
setPageNumber.type = usersListReducerPrefix + '/setPageNumber'

export const setSearch = (search = '') => ({ type: setSearch.type, payload: search })
setSearch.type = usersListReducerPrefix + '/setSearch'

export const setSort = (sortField = '', sortDirection = 'ASC') => ({ type: setSort.type, payload: { sortField, sortDirection } })
setSort.type = usersListReducerPrefix + '/setSort'

export const setSelectedUser = (selectedUser = []) => ({ type: setSelectedUser.type, payload: selectedUser })
setSelectedUser.type = usersListReducerPrefix + '/setSelectedUser'

export const setSelectedRow = (selectedRow = []) => ({ type: setSelectedRow.type, payload: selectedRow })
setSelectedRow.type = usersListReducerPrefix + '/setSelectedRow'

export const setAuxiliarSelectedRow = (auxiliarSelectedRow = []) => ({ type: setAuxiliarSelectedRow.type, payload: auxiliarSelectedRow })
setAuxiliarSelectedRow.type = usersListReducerPrefix + '/setAuxiliarSelectedRow'

export const setDeleteModalIsOpen = (deleteModalIsOpen = false) => ({ type: setDeleteModalIsOpen.type, payload: deleteModalIsOpen })
setDeleteModalIsOpen.type = usersListReducerPrefix + '/setDeleteModalIsOpen'

export const getPageNumber = state => state[usersListReducer.slice].pageNumber
export const getSearch = state => state[usersListReducer.slice].search
export const getSortField = state => state[usersListReducer.slice].sortField
export const getSortDirection = state => state[usersListReducer.slice].sortDirection
export const getSelectedUser = state => state[usersListReducer.slice].selectedUser
export const getSelectedRow = state => state[usersListReducer.slice].selectedRow
export const getAuxiliarSelectedRow = state => state[usersListReducer.slice].auxiliarSelectedRow
export const getHideActionBar = state => state[usersListReducer.slice].hideActionBar
export const getIsDeleteModalOpen = state => state[usersListReducer.slice].isDeleteModalOpen

export const getUsersListReducerSelectors = createStructuredSelector({
  pageNumber: getPageNumber,
  search: getSearch,
  sortField: getSortField,
  sortDirection: getSortDirection,
  selectedUser: getSelectedUser,
  selectedRow: getSelectedRow,
  auxiliarSelectedRow: getAuxiliarSelectedRow,
  hideActionBar: getHideActionBar,
  isDeleteModalOpen: getIsDeleteModalOpen
})
