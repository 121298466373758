import React, { PureComponent } from 'react'
import { DataTable } from '@scuf/datatable'
import { Checkbox, Button, Modal, Search } from '@scuf/common'
import { Title, FormRow } from '../../Themes/ScufStyledComponents'
import { isEmpty } from 'ramda'
import ErrorComponent from '../Error'
import { ModalTabs } from '../Tabs'

class DeleteLocation extends PureComponent {
  constructor (props) {
    super (props)
    this.state = {
      filter: ''
    }
  }
  componentDidMount () {
    if (isEmpty(this.props.locations)) {
      this.props.getLocations()
    }
  }
  render () {
    const { locations, onSelect, loading, deleteLocation, closeModal, t } = this.props
    const { filter } = this.state
    return (
      loading
        ? <ErrorComponent loading={loading} />
        : <div>
          <Modal.Header>
            <div style={{ width: '100%' }}>
              <FormRow justifyContent='space-between'>
                <Title>{t('DeleteLocationTable_Title')}</Title>
                <Search
                  placeholder={t('DeleteLocationTable_MissingSelection')}
                  onSearchChange={(term) => this.setState({ filter: term })}
                />
              </FormRow>
              <ModalTabs type='delete' activeIndex={3} />
            </div>
          </Modal.Header>
          <DataTable
            data={locations}
            scrollable
            scrollHeight='57vh'
            onCellClick={({rowData}) => onSelect(rowData)}
            sortField='hierarchy'
            sortOrder={1}
            globalFilter={filter}
          >
            <DataTable.Column
              field='selected'
              header=''
              initialWidth='10%'
              renderer={(row) => (
                <div style={{ textAlign: 'center' }}>
                  <Checkbox checked={row.value}/>
                </div>
              )}
            />
            <DataTable.Column
              field='hierarchy'
              sortable
              header={t('DeleteLocationTable_Locations')}
              renderer={(row) => (
                <div style={{ textAlign: 'left' }}>
                  {row.value}
                </div>
              )}
            />
          </DataTable>
          <div style={{ position: 'absolute', padding: '1em', right: 0, bottom: 0 }}>
            <Button
              type='secondary'
              content={t('DeleteLocationTable_Cancel')}
              onClick={closeModal}
              actionType='button'
            />
            <Button
              onClick={deleteLocation}
              type='primary'
              content={t('DeleteLocationTable_Delete')}
              actionType='button'
            />
          </div>
        </div>
    )
  }
}

export default DeleteLocation
