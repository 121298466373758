import React from 'react'
import { DataTable } from '@scuf/datatable'
import { Icon, Tooltip } from '@scuf/common'
import { upperFirst } from 'lodash'
import {
  ActionsRow
} from './preferences-table.styles'
import { isEmpty } from 'ramda'

class PreferencesTable extends React.Component {
  constructor () {
    super()
    this.state = {
      lastEventData: null
    }

    this._renderActions = this._renderActions.bind(this)
    this._renderFrequency = this._renderFrequency.bind(this)
  }

  componentDidMount () {
    this.props.getSites()
    this.props.getScheduledReports(this.props.userId)
  }

  _renderActions ({ rowData }) {
    const {
      onDelete,
      openModal,
      setSelectedReport,
      t
    } = this.props

    if (rowData.isAutomationReport) {
      return (
        <Tooltip
          content={t('NoEditing')}
          element={
            <ActionsRow disabled={true}>
              <Icon
                className='Icon'
                size='medium'
                name='edit'
              />
              <Icon className='Icon' size='medium' name='delete' />
            </ActionsRow>
          }
        />
      )
    } else {
      return (
        <ActionsRow>
          <Icon
            className='Icon'
            size='medium'
            name='edit'
            onClick={() => {
              setSelectedReport(rowData.id)
              openModal()
            }}
          />
          <Icon className='Icon' size='medium' name='delete' onClick={() => onDelete(rowData.id)} />
        </ActionsRow>
      )
    }
  }

  _renderFrequency ({ rowData }) {
    const daysOfWeek = rowData.days.map(day => this.props.t(day)).join(', ')
    return (
      <p>{`${upperFirst(this.props.t(rowData.programmed))} - ${daysOfWeek}  ${rowData.hour}:00`}</p>
    )
  }

  render () {
    const {
      scheduledReports,
      t
    } = this.props
    return (
      <DataTable
        data={scheduledReports}
        scrollHeight='55vh'
        scrollable
      >
        <DataTable.Column field='name' header={t('ReportName')} />
        <DataTable.Column field='sites' header={t('Sites')} />
        <DataTable.Column
          header={t('Frequency')}
          renderer={this._renderFrequency}
        />
        <DataTable.Column
          field='actions'
          header={t('Actions')}
          renderer={this._renderActions}
          initialWidth={200}
        />
      </DataTable>
    )
  }
}

export default PreferencesTable
