import {
  noSpecialCharactersWithoutSpaces,
  isRequired,
  noSpecialCharacters,
  maxLength
} from '../../../../Forms/Validators'
import React, { useCallback, useState } from 'react'
import Excel, { Workbook } from 'exceljs';
import { toast } from 'react-toastify';
import { ErrorToast } from 'Themes/ScufStyledComponents';
import range from 'lodash/range'
import get from 'lodash/get'
import { TypeApi, SitesManagerApi } from '../../../../Services'
export const nameSeparator = ' || '

export const SpreadsheetHeaders = {
  type : 'Device Type',
  model : 'Device Model',
  serial : 'Serial Number',
  alias: 'Alias',
  site : 'Site'
}

export const columnsValidations = {
  'serial': [
    { validation: noSpecialCharactersWithoutSpaces },
    { validation: isRequired },
    { validation: maxLength(10, 'Serial'), params: { limit: 10 } }
  ],
  'model': [
    { validation: noSpecialCharacters },
    { validation: isRequired }
  ],
  'alias': [
    { validation: noSpecialCharacters },
    { validation: isRequired },
    { validation: maxLength(30, 'Alias'), params: { limit: 30 } }
  ],
  'type': [
    { validation: isRequired }
  ],
  'siteId': [
    { validation: isRequired }
  ],

}

export const ERRORS = {
  EMPTY: 'FormError_Empty',
  MODELNOTFOUND: 'FormError_ModelNotFound',
  TYPENOTFOUND: 'FormError_TypeNotFound',
  SITENOTFOUND: 'FormError_SiteNotFound',
  ALIASNOTFOUND: 'FormError_AliasNotFound',
  SERIALNOTFOUND: 'FormError_SerialNotFound'
}

export const downloadProvisonResults = data => 'data:text/csv;charset=utf-8,' + encodeURI(data)

export const useDownloadImportTemplate = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const downloadImportTemplate = React.useCallback(async () => {
    try {
      setIsProcessing(true)
      const rootSitesResponse = await SitesManagerApi.getRootSites();
      const getTypesModels = await getDevices()
      const workbook = createExcelWorkbook(getTypesModels, rootSitesResponse.data)
     await downloadExcelWorksheet(workbook)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message='There was an error getting the file.' />)
    } finally {
      setIsProcessing(false)
    }
  }, [])

  return {
    isProcessing,
    downloadImportTemplate
  }
}

async function downloadExcelWorksheet (workbook) {
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }
   )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'BULK_PROVISIONING_Template.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}

function createExcelWorkbook (typesModels, sites) {
  const workbook = new Excel.Workbook()
  workbook.creator = 'BULK_PROVISIONING_Template'
  const bulkProvisionSheet = workbook.addWorksheet('Bulk Provision')
  bulkProvisionSheet.columns = [

    { header: SpreadsheetHeaders.type, key: 'type', width: 25 },
    { header: SpreadsheetHeaders.model, key: 'model', width: 25 },
    { header: SpreadsheetHeaders.serial, key: 'serial', width: 50 },
    { header: SpreadsheetHeaders.alias, key: 'alias', width: 25 },
    { header: SpreadsheetHeaders.site, key: 'site', width: 50 }
  ]
  bulkProvisionSheet.state = 'visible'
  range(2, 1001).forEach(i => {
    const row = bulkProvisionSheet.getRow(i)
    row.getCell('A').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['Data!$1:$1']
    }
    row.getCell('B').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['INDIRECT("Data!"&ADDRESS(2,MATCH(INDIRECT(ADDRESS(ROW(),1)),Data!$A$1:$B$1,0), 4) & ":" & ADDRESS(100,MATCH(INDIRECT(ADDRESS(ROW(),1)),Data!$A$1:$B$1,0), 4))']
    }

    row.getCell('C').dataValidation = {
      type: 'textLength',
  operator: 'lessThan',
  showInputMessage: true,
  allowBlank: true,
  formulae: [11],
      prompt: 'Maximum 10 characters length',
    }

    row.getCell('D').dataValidation = {
      type: 'textLength',
  operator: 'lessThan',
  showInputMessage: true,
  allowBlank: true,
  formulae: [31],
      prompt: 'Maximum 30 characters length',
    }
    row.getCell('E').dataValidation = {
      type: 'list',
      allowBlank: false,
      formulae: ['Sites!$A:$A']
    }
  })

  const sitesSheet = workbook.addWorksheet('Sites')
    sitesSheet.getColumn(1).values = sites
    .map(m => m.organizationalUnitHierarchy);

    const dataSheet = workbook.addWorksheet('Data')

    typesModels.deviceTypeLists.map((type, i) => {
      dataSheet.getCell(`${String.fromCharCode(i+65)}1`).value = type.text
      typesModels.deviceModelLists
  .filter(model => {
    if(mathedType(model.type) === type.text)
    return model.text
  }).map(model => model.text).forEach((val, index) => {
    dataSheet.getCell(`${String.fromCharCode(i+65)}${index+2}`).value = val
  })
    })

  return workbook
}

export const mathedType = (type ) => {
  return type === 'mobilecomputer' ? 'Mobile Computer' : (type === 'industrialprinter' ? 'Industrial/Desktop Printer': type)
}



  export const getDevices = async () => {
    let models, assets =[], getModels= [];
    const response = await TypeApi.getDevicesModels()
    if (response.ok) {
      const types = get(response, 'data.entities.devices', {})
       assets = Object.keys(types)
        .map(type => ({
          text:  mathedType(type),
          value: type,
          key: type
        })).filter(type => type.value === 'mobilecomputer' || type.value === 'industrialprinter')

     const industrialprinter = get(types, 'industrialprinter.deviceModelsV2', [])
     const mobilecomputer = get(types, 'mobilecomputer.deviceModelsV2', [])
     models = industrialprinter.concat(mobilecomputer)
      getModels = Object.values(types)
        .map(otherType => {
                return {
                  type: otherType.type,
                  models: get(types, `${otherType.type}.deviceModelsV2`, [])
                }

        }).filter( types =>  types.type === 'mobilecomputer' || types.type === 'industrialprinter').map((modelsObject) => {

              return {
               models: modelsObject.models.map(model => ({
                key: model.displayName,
                text: model.displayName,
                value: model.displayName,
                type: modelsObject.type,
                name: model.name
               }))
              }
          }).reduce( (arr, midelList) => {
            return [...midelList.models, ...arr.models]
          })
     return {
      deviceTypeLists: assets,
      deviceModelLists: getModels
    }
  }
  }

  export const mathedDeviceType = (type ) => {
    return type === 'Mobile Computer' ? 'mobilecomputer' : (type === 'Industrial/Desktop Printer' ? 'printer': type)

  }

