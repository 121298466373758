const downloadEncoders = {
  xml: {
    type: 'application/xml;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.xml'
  },
  image: {
    type: 'image/png',
    encoding: 'base64',
    suffix: '.png'
  },
  csv: {
    type: 'text/csv;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.csv'
  },
  json: {
    type: 'application/json;charset=utf-8',
    encoding: 'UTF-8',
    suffix: '.json'
  },
  url: {
    type: null,
    encoding: null,
    suffix: null
  },
  json: {
    type: 'application/json',
    encoding: 'UTF-8',
    suffix: '.json'
  }
}

export function downloadHelper (fileName, fileData, fileType = 'xml', fileSuffix) {
  // NOTE: file suffix must come if fileType is 'url'
  const { type, encoding, suffix } = downloadEncoders[fileType]

  const blob = new Blob([fileData], {
    type,
    encoding
  })

  const _component = document.createElement('a')

  _component.download = `${fileName}${suffix || fileSuffix}`
  _component.href = fileType === 'url'
    ? fileData
    : URL.createObjectURL(blob)
  _component.click()
}
