import React, { useCallback, useEffect, useState } from 'react'
import { NetworkRangeApi } from '../../../../Services'
import { SuccessToast, ErrorToast } from '../../../../Themes/ScufStyledComponents'
import { toast } from 'react-toastify'

export function useNetworkRanges() {
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [currentSiteId, setCurrentSiteId] = useState(null)
  const [downloadData, setDownloadData] = useState([])
  const [selectedRows, setSelectedRows] = useState()

  const getNetworkRanges = useCallback(async (siteId) => {
    setCurrentSiteId(siteId)
    if (siteId) {
      setDataLoading(true)
      const response = await NetworkRangeApi.getNetworkRanges(siteId)
      if (response.ok) {
        const dataArray = Object.keys(response.data).map(key => {
          return response.data[key]
        })
        setData(dataArray)
      } else {
        toast(<ErrorToast message='Error: Could not load network ranges.' />)
        setData([])
      }
      setDataLoading(false)
    } else {
      setData([])
      setDataLoading(true)
    }
  }, [setCurrentSiteId, setDataLoading, setData])

  const importNetworkRanges = useCallback(async (sitesFromFile, hierarchy2Site, isOrg) => {
    setDataLoading(true)
    const response = await NetworkRangeApi.uploadNetworkRanges(currentSiteId, sitesFromFile, hierarchy2Site, isOrg)
    if (response.ok) {
      toast(<SuccessToast message={`Successfully imported the Network Ranges!`} />)
      const dataArray = Object.keys(response.data.data).map(key => {
        return response.data.data[key]
      })
      setData(dataArray)
    } else if (Array.isArray(response.data.errors)) {
      response.data.errors.forEach(err => {
        toast(<ErrorToast message={`${err.message || err}`} />)
      })
    } else if (isOrg && hierarchy2Site.includes(" ")) {
      toast(<ErrorToast message={`No site(s) found`} />)
    }
    else {
      toast(<ErrorToast message={`${response.data.errors}`} />)
    }
    setDataLoading(false)
    return response
  }, [setDataLoading, currentSiteId, setData])

  const deleteNetworkRanges = useCallback(async (networkRange) => {
    if (networkRange.length === 0) {
      toast(<ErrorToast message='No Network Ranges to delete' />)
    }
    setDataLoading(true)
    const response = await NetworkRangeApi.deleteNetworkRanges(networkRange, currentSiteId)
    if (response.ok) {
      await getNetworkRanges(currentSiteId)
      toast(<SuccessToast message={`Successfully deleted ${networkRange.length} Network Ranges!`} />)
    } else {
      toast(<ErrorToast message='An error occurred while deleting Network Ranges' />)
    }
    setDataLoading(false)
    setSelectedRows()
  }, [setDataLoading, getNetworkRanges, currentSiteId])

  useEffect(() => {
    setDownloadData(data.map(d =>
      ({ firstIpAddress: d.firstIpAddress, lastIpAddress: d.lastIpAddress, networkName: d.networkName })))
  }, [data])

  return {
    currentSiteId,
    data,
    dataLoading,
    getNetworkRanges,
    importNetworkRanges,
    deleteNetworkRanges,
    downloadData,
    selectedRows,
    setSelectedRows
  }
}
