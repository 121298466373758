import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { defaultSections, defaultCards } from '../Components/DashboardPreferences/dashboard-preferences.constants'
import { differenceWith, assoc, has } from 'ramda'
import { get } from 'lodash'

const { Types, Creators } = createActions({
  userSettingsRequestDashboardPreferences: ['userId'],
  userSettingsResponseDashboardPreferences: ['data'],
  userSettingsRequestGetFilters: ['userId', 'currentSection'],
  userSettingsResponseGetFilters: ['data'],
  userSettingsRequestSetFilters: ['userId', 'filters'],
  userSettingsRequestUpdateFilters: ['userId', 'filters'],
  userSettingsFailure: null,
  accordionClick: ['sectionKey', 'isActive'],
  changeSectionVisibility: ['sectionKey', 'isSectionVisible'],
  changeAllCardsVisibility: ['sectionKey', 'isSectionVisible'],
  layoutChange: ['cards'],
  changeCardVisibleState: ['card', 'checked'],
  changeDashboardTitle: ['value'],
  dashboardRequestList: [],
  dashboardResponseList: ['dashboardList'],
  dashboardOptionsRequest: ['isOptions'],
  dashboardOptionsResponse: ['dashboardOptions'],
  dashboardRequestForRoleList: ['roleId'],
  dashboardResponseForRoleList: ['dashboardList'],
  dashboardRequestUserForRoleList: ['roleId', 'organizationId', 'userId'],
  dashboardResponseUserForRoleList: ['dashboardList', 'userId', 'myDashboardPermission'],
  userManagementSetCurrentDashboard: ['dashboardId'],
  dashboardManagementSetCurrentDashboard: ['dashboardId'],
  dashboardEditRequest: ['roleId', 'assignFlag'],
  dashboardEditResponse: ['dashboard'],
  createNewDashboardRequest: ['roleId', 'assignFlag'],
  createNewDashboardResponse: ['dashboard'],
  dashboardRevokeRequest: [],
  dashboardRevokeResponse: [],
  dashboardAssignRequest: ['dashboardId'],
  dashboardAssignResponse: [],
  userSettingsDeleteDashboardView: ['dashboardList'],
  setEditedSelectedDashboard: [],
  setSelectedDashboardToModal: ['organizationId', 'roleId'],
  setDefaultDashboardToModal: ['roleId', 'assignFlag', 'organizationId'],
  resetDashboardToDefaultState: [null],
  userSettingsDeleteDashboardViewResponse: [],
  changeSelectedDashboard: ['value'],
  changeContentVisibility: null,
  setCurrentRole: ['roleId'],
  updateIsSectionEnabled: ['payload']
})

export const UserSettingsTypes = Types
export default Creators

const INITIAL_DASHBOARD = {
  loading: false,
  dashboardTitle: '',
  sections: defaultSections,
  cards: defaultCards
}

export const INITIAL_STATE = Immutable({
  dashboardPreferences: INITIAL_DASHBOARD,
  selectedDashboard: INITIAL_DASHBOARD,
  id: '',
  userId: '',
  filters: {
    currentSection: '',
    unionFilters: {},
    intersectionFilters: {}
  },
  loading: false,
  dashboardList: [],
  dashboardOptions: [],
  currentRole: {},
  currentRoleId: '',
  rolesList: [
    { key: '5b308a3cf4162420e49a7694', roleTitle: 'Admin', users: 176, dashboardsAssigned: 5, dashboardCustomization: 'Yes' },
    { key: '5b308a3bf4162420e49a7692', roleTitle: 'User', users: 176, dashboardsAssigned: 5, dashboardCustomization: 'No' },
    { key: '5c34fa68f36648483da2e5a6', roleTitle: 'Installer', users: 176, dashboardsAssigned: 5, dashboardCustomization: 'No' }],
  deletedDashboards: [],
  hiddenContent: false
})

/* ------------- Reducers ------------- */
export const dashboardRequestLoading = (state) => state.setIn(['dashboardPreferences', 'loading'], true)

export const dashboardResponseLoading = (state) => state.setIn(['dashboardPreferences', 'loading'], false)

export const userSettingsRequestDashboardPreferences = (state) => state.merge({ dashboardPreferences: { loading: true } })

export const userSettingsResponseDashboardPreferences = (state, { data }) => {
  return state.merge({ dashboardPreferences: { ...(data || {}), loading: false } })
}

export const userSettingsSetDashboard = (state, { data }) => {
  return state.merge({ selectedDashboard: { ...(data || {}), loading: false } })
}

export const accordionClick = (state, { sectionKey, isActive }) =>
  state.setIn(['dashboardPreferences', 'sections', `${sectionKey}`, 'acordionIsActive'], isActive)

export const changeSectionVisibility = (state, { sectionKey, isSectionVisible }) =>
  state.setIn(['dashboardPreferences', 'sections', `${sectionKey}`, 'isVisible'], isSectionVisible)

export const changeAllCardsVisibility = (state, { sectionKey, isSectionVisible }) => {
  const cards = state.getIn(['dashboardPreferences', 'sections', sectionKey, 'cards'], [])
  const prevCard = state.getIn(['dashboardPreferences', 'cards'])
  const newState = { ...prevCard }
  cards.forEach(card => {
    newState[card] = assoc('isVisible', isSectionVisible, prevCard[card])
  })
  return state.setIn(['dashboardPreferences', 'cards'], newState)
}

export const changeCardVisibleState = (state, { card, checked }) =>
  state.setIn(['dashboardPreferences', 'cards', `${card}`, 'isVisible'], checked)

export const changeDashboardTitle = (state, { value }) =>
  state.setIn(['dashboardPreferences', 'dashboardTitle'], value)

export const layoutChange = (state, { cards }) => {
  const prevCard = state.getIn(['dashboardPreferences', 'cards'])
  const newState = { ...prevCard }
  cards.forEach(card => {
    newState[card.name] = assoc('position', card.index, prevCard[card.name])
  })
  return state.setIn(['dashboardPreferences', 'cards'], newState)
}

export const setEditedSelectedDashboard = (state) => {
  const prevCard = state.getIn(['dashboardPreferences'])
  const newState = { ...prevCard }
  return state.setIn(['selectedDashboard'], newState)
}

export const setDefaultDashboardToModal = (state, { roleId, assignFlag, organizationId }) => {
  if (assignFlag) {
    return state.setIn(['dashboardPreferences'], INITIAL_DASHBOARD)
      .setIn(['dashboardPreferences', 'roleId'], roleId)
      .setIn(['dashboardPreferences', 'siteId'], organizationId)
  } else {
    return state.setIn(['dashboardPreferences'], INITIAL_DASHBOARD)
      .setIn(['dashboardPreferences', 'siteId'], organizationId)
  }
}

export const resetDashboardToDefaultState = (state) =>
  state.setIn(['dashboardPreferences', 'cards'], defaultCards)
    .setIn(['dashboardPreferences', 'sections'], defaultSections)

export const changeSelectedDashboard = (state, { value }) => {
  const prevCard = state.getIn(['dashboardList', value])
  const newState = { ...prevCard }
  return state.setIn(['selectedDashboard'], newState)
}

export const setSelectedDashboardToModal = (state, { organizationId, roleId }) => {
  const prevCard = state.getIn(['selectedDashboard'])
  const newState = { ...prevCard }
  if (roleId) {
    return state.setIn(['dashboardPreferences'], newState)
      .setIn(['dashboardPreferences', 'siteId'], organizationId)
      .setIn(['dashboardPreferences', 'roleId'], roleId)
  } else {
    return state.setIn(['dashboardPreferences'], newState)
      .setIn(['dashboardPreferences', 'siteId'], organizationId)
  }
}

export const userSettingsRequestGetFilters = (state) => state.merge({ filters: { currentSection: '', unionFilters: {}, intersectionFilters: {} } })
export const userSettingsResponseGetFilters = (state, { data }) => state.merge({ filters: { ...(data || {}) } })

export const userSettingsRequestSetFilters = (state) => state.merge({})

export const userSettingsRequestUpdateFilters = (state) => state.merge({})

const dashboardRequestList = (state) => state.merge({ dashboardList: [], loading: true })

const dashboardResponseList = (state, { dashboardList }) => state.merge({ dashboardList, loading: false })

const dashboardOptionsRequest = (state) => state.merge({ dashboardOptions: [] })

const dashboardOptionsResponse = (state, { dashboardOptions }) => state.merge({ dashboardOptions })

const dashboardAssignRequest = (state) => state.merge({ loading: true })

const dashboardAssignResponse = (state) => state.merge({ loading: false })

const dashboardRequestForRoleList = (state) => state.merge({ dashboardList: [], loading: true })

const dashboardResponseForRoleList = (state, { dashboardList }) => {
  return state.merge({ dashboardList, loading: false })
}

const dashboardResponseUserForRoleList = (state, { dashboardList, userId, myDashboardPermission }) => {
  if (has('userId', get(dashboardList, '0', {}))) {
    if (!myDashboardPermission) {
      const filteredDashboardList = dashboardList
        .filter(dashboard => dashboard.dashboardTitle !== 'My Dashboard')
      return state.merge({ filteredDashboardList, loading: false })
    }
    return state.merge({ dashboardList, loading: false })
  } else {
    const myDashboard = { ...INITIAL_DASHBOARD }
    myDashboard['dashboardTitle'] = 'My Dashboard'
    myDashboard['userId'] = userId

    if (!myDashboardPermission) {
      return state.merge({ dashboardList, loading: false })
    }
    return state.merge({ dashboardList: [...[myDashboard], ...dashboardList], loading: false })
  }
}

const userManagementSetCurrentDashboard = (state, { dashboardId }) => {
  const currentDashboard = state.dashboardList.find(o => get(o, 'id', {}) === dashboardId)
  return userSettingsSetDashboard(state, { data: currentDashboard })
}

const dashboardManagementSetCurrentDashboard = (state, { dashboardId }) => {
  const currentDashboard = state.dashboardList.find(o => get(o, 'dashboard.id', {}) === dashboardId).dashboard
  return userSettingsSetDashboard(state, { data: currentDashboard })
}

const userSettingsDeleteDashboardView = (state, dashboardsSelected) => {
  const diff = differenceWith((x, y) => x.dashboard.id === y.id, state.dashboardList, dashboardsSelected.dashboardList)
  return state.merge({ loading: true, dashboardList: diff, deletedDashboards: dashboardsSelected.dashboardList })
}

const userSettingsDeleteDashboardViewResponse = (state) => {
  return state.merge({ loading: false })
}

const changeContentVisibility = (state) => {
  return state.update('hiddenContent', (value) => !value)
}

const setCurrentRole = (state, { roleId }) => state.merge({ currentRoleId: roleId })

const updateIsSectionEnabled = (state, { payload }) => {
  const { sectionKey, isSectionEnabled } = payload
  return state.setIn(['selectedDashboard', 'sections', sectionKey, 'isVisible'], isSectionEnabled)
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_SETTINGS_REQUEST_DASHBOARD_PREFERENCES]: userSettingsRequestDashboardPreferences,
  [Types.USER_SETTINGS_RESPONSE_DASHBOARD_PREFERENCES]: userSettingsResponseDashboardPreferences,
  [Types.USER_SETTINGS_REQUEST_GET_FILTERS]: userSettingsRequestGetFilters,
  [Types.USER_SETTINGS_RESPONSE_GET_FILTERS]: userSettingsResponseGetFilters,
  [Types.USER_SETTINGS_REQUEST_SET_FILTERS]: userSettingsRequestSetFilters,
  [Types.USER_SETTINGS_REQUEST_UPDATE_FILTERS]: userSettingsRequestUpdateFilters,
  [Types.ACCORDION_CLICK]: accordionClick,
  [Types.CHANGE_SECTION_VISIBILITY]: changeSectionVisibility,
  [Types.CHANGE_ALL_CARDS_VISIBILITY]: changeAllCardsVisibility,
  [Types.LAYOUT_CHANGE]: layoutChange,
  [Types.CHANGE_DASHBOARD_TITLE]: changeDashboardTitle,
  [Types.CHANGE_SELECTED_DASHBOARD]: changeSelectedDashboard,
  [Types.SET_EDITED_SELECTED_DASHBOARD]: setEditedSelectedDashboard,
  [Types.SET_SELECTED_DASHBOARD_TO_MODAL]: setSelectedDashboardToModal,
  [Types.SET_DEFAULT_DASHBOARD_TO_MODAL]: setDefaultDashboardToModal,
  [Types.CHANGE_CARD_VISIBLE_STATE]: changeCardVisibleState,
  [Types.DASHBOARD_REQUEST_LIST]: dashboardRequestList,
  [Types.DASHBOARD_RESPONSE_LIST]: dashboardResponseList,
  [Types.DASHBOARD_OPTIONS_REQUEST]: dashboardOptionsRequest,
  [Types.DASHBOARD_OPTIONS_RESPONSE]: dashboardOptionsResponse,
  [Types.DASHBOARD_REQUEST_FOR_ROLE_LIST]: dashboardRequestForRoleList,
  [Types.DASHBOARD_RESPONSE_FOR_ROLE_LIST]: dashboardResponseForRoleList,
  [Types.DASHBOARD_REQUEST_USER_FOR_ROLE_LIST]: dashboardRequestForRoleList,
  [Types.DASHBOARD_RESPONSE_USER_FOR_ROLE_LIST]: dashboardResponseUserForRoleList,
  [Types.DASHBOARD_EDIT_REQUEST]: dashboardRequestLoading,
  [Types.DASHBOARD_EDIT_RESPONSE]: userSettingsResponseDashboardPreferences,
  [Types.DASHBOARD_REVOKE_REQUEST]: dashboardRequestLoading,
  [Types.DASHBOARD_REVOKE_RESPONSE]: userSettingsResponseDashboardPreferences,
  [Types.DASHBOARD_ASSIGN_REQUEST]: dashboardAssignRequest,
  [Types.DASHBOARD_ASSIGN_RESPONSE]: dashboardAssignResponse,
  [Types.USER_MANAGEMENT_SET_CURRENT_DASHBOARD]: userManagementSetCurrentDashboard,
  [Types.DASHBOARD_MANAGEMENT_SET_CURRENT_DASHBOARD]: dashboardManagementSetCurrentDashboard,
  [Types.USER_SETTINGS_DELETE_DASHBOARD_VIEW]: userSettingsDeleteDashboardView,
  [Types.USER_SETTINGS_DELETE_DASHBOARD_VIEW_RESPONSE]: userSettingsDeleteDashboardViewResponse,
  [Types.CREATE_NEW_DASHBOARD_REQUEST]: dashboardRequestLoading,
  [Types.CREATE_NEW_DASHBOARD_RESPONSE]: userSettingsResponseDashboardPreferences,
  [Types.RESET_DASHBOARD_TO_DEFAULT_STATE]: resetDashboardToDefaultState,
  [Types.CHANGE_CONTENT_VISIBILITY]: changeContentVisibility,
  [Types.SET_CURRENT_ROLE]: setCurrentRole,
  [Types.UPDATE_IS_SECTION_ENABLED]: updateIsSectionEnabled
})