import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import { InfoRow, InfoTitle } from 'Components/ScheduleModal/schedule-modal.styles'
import { ImportantToast, InfoContainer, InfoText } from 'Themes/ScufStyledComponents'

import AssetInfo from '../AssetInfo/asset-info.connect'
import DevicesTable from '../AssetSelector/device-table'

export const ScheduleContainer = styled.div`
  border-left: ${({ showError }) => showError ? '5px solid red' : ''};
  padding-left: 20px;
  margin-left: -20px;
`

const ConfirmationStep = ({
  assets,
  devices,
  schedule,
  validScheduleTime,
  t,
  formValues,
  isValid,
  isScheduleFromFile,
  fileName,
  toastClick }) => {
  const [validAssetInfo, setValidAssetInfo] = useState(false)
  const [hasErrorToastShown, setHasErrorToastShown] = useState(false)
  useEffect(() => {
    isValid(validAssetInfo)
  }, [isValid, validAssetInfo])

  useEffect(() => {
    if (!validScheduleTime && !hasErrorToastShown) {
      setHasErrorToastShown(true)
    } else if (validScheduleTime && !hasErrorToastShown) {
      setHasErrorToastShown(false)
    }
  }, [validScheduleTime])
  return (
    <>
      <AssetInfo
        formValues={formValues}
        style={{ placeContent: 'start space-between', width: '50vw'}}
        assetsList={assets}
        t={t}
        isValid={setValidAssetInfo}
        editionDisabled={true}
      />
      <hr style={{ width: '100%', marginTop: '2em' }} />
      <ScheduleContainer
        showError={!validScheduleTime}
      >
        <h3>{t('Schedule')}</h3>
        {schedule.OnSchedule
          ? <TimeContainer
            t={t}
            schedule={schedule}
            validScheduleTime={validScheduleTime}
            toastClick={toastClick}
            hasErrorToastShown={hasErrorToastShown}
          />
          : <h5>{t('ImmediatelyApplied')}</h5>}
      </ScheduleContainer>
      <hr style={{ width: '100%', marginTop: '2em' }} />
      <h3>{t('Devices')}</h3>
      {isScheduleFromFile 
        ? <div>{t('FromFileNameMessage') + fileName}</div> 
        : <DevicesTable
            devices={devices}
            allowSelect={false}
            t={t}
          />}
    </>
  )
}

const TimeContainer = ({ t, schedule, validScheduleTime, toastClick, hasErrorToastShown }) => {
  const getFormattedTime = (schedule) => {
    if (schedule?.DateTime) {
      return {
        tz: schedule.TimeZone,
        date: schedule.DateTime?.format('dddd, MMMM Do YYYY'),
        end: schedule.EndTime?.format('dddd, MMMM Do YYYY'),
        recur: schedule.Recurring,
        ts: schedule.DateTime?.format('h:mm:ss a'),
        tsEnd: schedule.EndTime?.format('h:mm:ss a'),
        from: schedule.From?.format('h:mm:ss a'),
        to: schedule.To?.format('h:mm:ss a'),
        style: !validScheduleTime ? { color: 'red' } : null
      }
    }
    return ''
  }
  if (!validScheduleTime && !hasErrorToastShown) {
    toast(<ImportantToast
      message={t('SelectFutureDate')}
      title={t('ScheduleDateExpired')}
      color='red'
      detailsClick={toastClick}
      detailsText={t('DetailsText')}
    />)
  }

  const { tz, date, ts, end, recur, tsEnd, from, to, style } = getFormattedTime(schedule)
  return (
    <div>
    <InfoRow>
      <InfoContainer>
        <InfoTitle>{t('TimeZone')}</InfoTitle>
        <InfoText>{tz}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle {...style}>{t('ScheduleDate')}</InfoTitle>
        <InfoText {...style}>{date}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle {...style}>{t('Time')}</InfoTitle>
        <InfoText {...style}>{from}</InfoText>
      </InfoContainer>
    </InfoRow>
    </div>
  )
}

export default ConfirmationStep
