import React, { useEffect } from 'react'
import { Field, Form } from 'redux-form'
import { Button, Select } from '@scuf/common'

import config from '../../../../Config/AppConfig'
import FormCheckbox from '../../../FormComponents/FormCheckbox'
import { QrFormWrapper, QrSiteSelect, QrTermsText } from './qr-form.styles'
import {
  FormInputContainer,
  FormRow,
} from '../../../../Themes/ScufStyledComponents'

const QrForm = ({
  handleSubmit,
  onSubmit,
  valid,
  t,
  onChange,
  sites,
  isHoneywellAdmin,
  data,
  isModal,
  hide,
  setShow,
  setData,
  flags
}) => {

  useEffect(() => { onChange('addMoreInformation', flags.includeTenantInformation) }, [flags.includeTenantInformation])

  return (
    <QrFormWrapper
      flexDirection='column'
      justifyContent='flex-start'
      mediaJustifyContent='flex-start'
      padding='0.5rem'>
      <Form
        className={hide ? 'display-none' : ''}
        id='qrForm'
        onSubmit={handleSubmit(data => {
          setShow(isModal ? 'all' : 'qr')
          onSubmit(data)
        })}
      >
        <FormRow>
          <FormInputContainer padding='0.25rem'>
            <Select
              component={QrSiteSelect}
              fluid
              id='Site'
              indicator='required'
              label={t('Site')}
              name='Site'
              onBlur={(e) => e.preventDefault()}
              onChange={(value) => {
                onChange('Site', value)
                setData(value)
                const siteName = sites.filter((item) => {
                  if(item.value === value){
                    return item.text
                  }
                })
                onChange('SiteName', siteName[0].text)
              }}
              options={sites}
              placeholder={t('Sites')}
              search
            />
          </FormInputContainer>
        </FormRow>
        <FormRow>
          <Field
            component={FormCheckbox}
            id='acceptedTerms'
            name='acceptedTerms'
            onChange={(e, value, prev, name) => onChange(name, value)}
            style={{ flex: 0, alignSelf: 'center' }}
          />
          <QrTermsText>
            {t('Accept')}
            <span
              className='link pointer'
              onClick={() => setShow('terms')}
            >
              {` ${t('Terms')} `}
            </span>
            {`${t('And')} `}
            <a
              href={config.api.privacyUrl}
              rel='noopener noreferrer'
              target='_blank'>
              {t('PrivacyPolicy')}
            </a>
          </QrTermsText>
        </FormRow>
        <FormRow alignItems='flex-end' flex={4} justifyContent='flex-end'>
          {
            isModal
              ? null
              : <Button
                actionType='submit'
                content={t('GenerateQR')}
                disabled={!valid || !data.acceptedTerms}
                icon='refresh'
                iconPosition='left'
                type='primary'
              />
          }
        </FormRow>
      </Form>
    </QrFormWrapper>
  )
}

QrForm.defaultProps = {
  reset: () => null,
  t: () => null,
  onChange: () => null,
  setShow: () => null,
  setData: () => null,
  data: {}
}

export default QrForm
