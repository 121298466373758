import React from 'react'
import { Modal, Tooltip, Button } from '@scuf/common'
import styled from 'styled-components'
import { useTranslate } from 'react-translate'

export const Form = styled.form`
  max-height: 100vh;
  height: 80vh;
`

export const ModalContent = styled(Modal.Content)`
  max-height: auto;
`
export const ModalFooter = styled(Modal.Footer)`
  justify-content: space-between !important;
  display: flex !important;
`
export const HeaderContainer = styled.div`
display: flex;
flex: 1;
justify-content: space-between;
`
export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 24px;
  margin: 2rem 1.1em 0rem 1rem;
`

export const TableContainer = styled.div`
  height: 63vh;
`

export const SimpleRow = styled.div`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`

export const Note = styled.div`
font-size: 12px;
padding-top: 10px;
`

function BasicValidatioComponent ({
  cellData
}) {
  const t = useTranslate('Devicetable')
  return cellData ? cellData : t('Devicetable_NotAvailable')
}

export function BasicValidationRender (props) {
  return <BasicValidatioComponent {...props}/>
}

export const ButtonWrap = styled.div`
  margin-left: 1rem;
  button {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors?.textHighContrast} !important;
  }
`

export function SiteNameWithHierarchy ({ cellData }) {
  const hierarchyTokens = cellData?.split('/')
  const siteName = hierarchyTokens?.slice(-1)[0].trim()
  return (
    <Tooltip
      element={<p>{siteName}</p>}
      content={cellData}
      size='small'
    />
  )
}
