import apiClient from '../../../Services/ApiSauceInstance'
import { ApiResponse } from 'Services/api-response'
import { CancelToken } from 'axios'

export interface NewUser {
    organizationId: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    siteRole?: string[];
    siteId: string;
    roleId: string;
}

export interface BulkCreateUsersRequest {
    bulkOnboardUser: NewUser[];
    dryRun?: boolean;
}

export type BulkCreateUsersResponse = ApiResponse<ApiResponse<NewUser>[]>

export const bulkCreateUsers = (request: BulkCreateUsersRequest, cancelToken?: CancelToken) => apiClient
    .post<BulkCreateUsersResponse>('UserManagement/v2/users:bulkCreate', request, { timeout: 60000, cancelToken })