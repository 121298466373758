import React from 'react'
import { useTranslate } from 'react-translate'
import IFileDropProps from '@scuf/common/dist/components/FileDrop/IFileDropProps'
import { ICsvFileDropHookProps, useCsvFileDrop } from './csv-file-drop.hooks'
import { EmptyFileError } from './empty-file-error'
import { Button } from '@scuf/common'
import {
  FileDropContainer,
  StyledFileDrop,
  StyledFileDropProps,
  StyledLoader,
  SubText,
  StyledDiv
} from './csv-file-drop.styles'
import { useDownloadImportTemplate } from '../../Components/Upload/components/form-devices/form-devices.helpers'

export type CsvFileDropFileType = '.csv' | '.xlsx'


export interface CsvFileDropProps extends ICsvFileDropHookProps, IFileDropProps, StyledFileDropProps {
  acceptedTypes?: CsvFileDropFileType[],
  sampleDataLink?: boolean,
  placeholder?: string,
}

export const CsvFileDrop: React.FC<CsvFileDropProps> = ({
  acceptedTypes = ['.csv', '.xlsx'],
  onReset,
  disabled,
  sampleData,
  sampleDataLink,
  placeholder,
  chars,
  onLoadFile,
  onLoadFileError,
  onLoadFileSuccess,
  height,
  fileHeadersCheck,
  ...rest
}) => {
  const translate = useTranslate('CsvFileDrop')
  const {
    isProcessing,
    uploadedFileIsEmpty,
    processFile,
    sampleCsvFileUrl
  } = useCsvFileDrop({ sampleData, onLoadFile, onLoadFileSuccess, onLoadFileError, fileHeadersCheck })
  const { downloadImportTemplate } = useDownloadImportTemplate()
  return (
    <FileDropContainer>
      <StyledFileDrop
        label=''
        acceptedTypes={acceptedTypes}
        placeholder={uploadedFileIsEmpty ? <EmptyFileError /> : placeholder ?? translate('DropFile')} // @ts-ignore
        buttonText={translate('BrowseFiles')}
        onAccepted={(files: File[]) => processFile(files[0])}
        onReset={() => onReset()}
        height={height}
        error={uploadedFileIsEmpty ? ' ' : undefined}
        {...rest}
        style={{'visibility' : isProcessing ? 'hidden' : 'visible'}}
      />
      {isProcessing
        ? <StyledDiv>
          <StyledLoader minHeight={height} text={translate('ProcessingFile')} />
          </StyledDiv>
        :null}
      {sampleDataLink && (
        <SubText>
          <Button type="link"
                  content={translate("click")}
                  onClick={() => downloadImportTemplate()} /> {translate("textDownload")}
        </SubText>
      )}
    </FileDropContainer>
  )
}
