const create = (api) => {
  const siteRankingsApi = (params) => api.get('/rankings', params)
  const getSiteComparisonApi = (params) => api.get('/rankings/comparison', params)

  return {
    siteRankingsApi,
    getSiteComparisonApi
  }
}

export default {
  create
}
