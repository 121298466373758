import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import { ReleaseNoteApi } from 'Services'

const PREFIX = 'RELEASE_NOTES/'

export const releaseNotesOpenModal = createAction(
  PREFIX + 'OPEN_MODAL'
)

export const releaseNotesCloseodal = createAction(
  PREFIX + 'CLOSE_MODAL'
)

export const releaseNotesMarkAsRead = createAction(
  PREFIX + 'MARK_AS_READ',
  (userId, releaseId) => ({
    payload: {
      userId,
      releaseId
    }
  })
)

export const fetchReleaseNotes = createAsyncThunk(
  PREFIX + 'FETCH_RELEASE_NOTES',
  async (_, { rejectWithValue }) => {
    const response = await ReleaseNoteApi.getReleaseNotes()
    if (response.ok) {
      return {
        releaseCommunications: response.data?.releaseCommunications
      }
    } else {
      return rejectWithValue(response.data)
    }
  }
)
