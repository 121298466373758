import { useMemo } from 'react'
import { useTranslate } from 'react-translate'
import m from 'moment'

export function useAssetLastOnline({
  lastOnline,
  status,
  time,
  recommendedIPAddress
}) {
  const t = useTranslate('DeviceStatus')
  return useMemo(() => {
    const datetime = recommendedIPAddress ? m(time) : m(lastOnline)
    let formattedDay = datetime.format('MMMM Do YYYY, h:mm:ss a')
    let dateFromNow
    if (recommendedIPAddress || lastOnline) {
      dateFromNow = datetime.fromNow()
    } else if (
      status != null &&
      status !== 'deprovisioning' &&
      status !== 'deprovisioned' &&
      status !== 'deregistering' &&
      status !== 'deregisteringfailed' &&
      status !== 'deregistered'
    ) {
      dateFromNow = t(status)
      formattedDay = t(status)

    } else {
      dateFromNow = t('Not available')
      formattedDay = t('Not available')
    }
    return {
      dateFromNow,
      formattedDay
    }
  }
    , [lastOnline, status, t])
}
