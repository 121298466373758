const create = (api) => {
  const getReportToken = (site, deviceType) => api.get(`dashboard/report/${site}`, {
    deviceType
  })

  const getDeviceTypeBySiteId = site => api.get(`dashboard/report/${site}/deviceTypes`)

  return {
    getReportToken,
    getDeviceTypeBySiteId
  }
}

export default {
  create
}
