  const create = (api) => {
    const renewToken = (accessToken, organizationId) => 
      
      api.post('/v2/renewtoken', 
      {
        accessToken: accessToken,
        clientType: 'custom',
        grantType: 'authorization_code'
      },{
        params: { organizationId: (organizationId ? organizationId : null) }
      })

    return { renewToken }
  }
  
  export default {
    create
  }