import React from 'react'
import { useTranslate } from 'react-translate'
import { Icon } from '@scuf/common'

export const EmptyFileError: React.FC = () => {
  const translate = useTranslate('CsvFileDrop')
  return (
    <>
      <Icon root='common' name='badge-important' size='xlarge' />
      <br />
      <h4>{translate('FileEmpty')}</h4>
    </>
  )
}
