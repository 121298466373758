import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import moment from 'moment'

import persistConfig from 'Config/PersistorConfig'
import config from 'Config/AppConfig'
import Reactotron from 'Config/ReactotronConfig'
import { rootReducer, RootState } from './root-reducer'
import sagas from 'Sagas'
import LoginActions from 'Redux/LoginRedux'
import { setToken } from 'Services/ApiSauceInstance'
import { useDispatch } from 'react-redux'

function createStore (debug: boolean = false) {
  if (debug) {
    Reactotron.connect()
  }

  // @ts-expect-error
  const sagaMiddleware = debug && Reactotron.createSagaMonitor !== undefined
    // @ts-expect-error
    ? createSagaMiddleware({ sagaMonitor: Reactotron.createSagaMonitor() })
    : createSagaMiddleware({})

  return {
    store: configureStore({
      reducer: rootReducer,
      middleware: [
        sagaMiddleware,
        ...getDefaultMiddleware<RootState, {}>({
          immutableCheck: debug,
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        })
      ] as const, // prevent this from becoming just `Array<Middleware>`
      // @ts-expect-error
      enhancers: debug && Reactotron.createEnhancer !== undefined ? [Reactotron.createEnhancer()] : []
    }),
    sagaMiddleware
  }
}

const { store, sagaMiddleware } = createStore(config.debug)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default
    store.replaceReducer(persistReducer(persistConfig, newRootReducer))
  })
}

// mount it on the Store
store.subscribe(() => {
  const state = store.getState()
  const m = moment()

  // @ts-ignore
  if (state.login.accessToken) {
    // @ts-ignore
    setToken(state.login.accessToken)
  }

  // @ts-ignore
  if (state.login.loggedIn && m.isSameOrAfter(state.login.sessionTimeout)) {
    store.dispatch(LoginActions.loginLogoutRequest())
  }
})

// then run the saga
sagaMiddleware.run(sagas)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export { store }
