import { all, call, put, takeLatest } from 'redux-saga/effects'
import { IOrganizationsService, OrganizationsResult } from '../service'
import { getOrganizationsSuccess, getOrganizationsFailure } from './slice'

export function * getOrganizations (orgService: IOrganizationsService) {
  try {
    const response: OrganizationsResult = yield call(orgService.getOrganizations)
    yield put(getOrganizationsSuccess(response))
  } catch (err) {
    yield put(getOrganizationsFailure(err.toString()))
  }
}

export function * organizationsSaga (orgService: IOrganizationsService) {
  yield all([
    takeLatest(getOrganizationsSuccess.toString(), getOrganizations, orgService)
  ])
}
