import styled from 'styled-components/macro'
import { Select, Badge } from '@scuf/common'

export const InputWrapper = styled.div`
  padding: 0 0.2rem 0 0.2rem;
  margin-top: 0.5rem;
`
export const StyledBadge = styled(Badge)`
  margin-right: 0.25rem !important;
`
export const StatusWrapper = styled.div`
  font-size: .875rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
