import styled from 'styled-components'
import {
  Select,
  Icon,
  Button
} from '@scuf/common'

export const InputsContainer = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const ControlInputContainer = styled.div`
  display: flex;
  flex: 6;
  justify-content: flex-start;
`

export const StyledButton = styled(Button)`
max-height: 2.71428571em;
`
export const StyledButtonContainer = styled.div`
  padding-top: 3.5%;
  display:flex;
  align-items: center;
`

export const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-right: ${props => props.theme.padding};
  .delete.icon {
    z-index: 200;
  }
  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }

  .ui.disabled.dropdown {
    min-height: 2.71428571em;
  }
`

export const ActionContainer = styled.div`
  padding-top: 3.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  & * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#1B1D1F'};
  }
  &:hover * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#606060'};
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-height: 20rem;
  border: 0.0625rem solid #d0d0d0;
  align-content: flex-start;
`

export const Tag = styled.div`
  display: block;
  background-color: ${({ selected }) => selected
    ? '#5DB2EC'
    : props => props.theme.black || '#E4E4E6'};
  margin: 0.3rem 0.3rem;
  max-width: 33%;
  padding: 0.25rem 1rem;
  min-height: 1.5rem !important;
  color: ${({ selected }) => selected
    ? '#FFFFFF'
    : '#303030'};
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
`

export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1rem !important;
  margin-top: 0.15rem;
  padding-left: 1.5rem;
`
