import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

import LocationTree from '../Location/components/location-tree'
import Form from './components/form'
import { ModalTabs } from '../Tabs'
import { Title, FormRow } from '../../Themes/ScufStyledComponents'

const Div = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.5rem;
  height: calc(80vh - 11.5rem);
  margin-top: 0;
`

class EditLocation extends Component {
  componentDidMount () {
    this.props.getLocations()
  }

  render () {
    const {
      nodes,
      onChange,
      t
    } = this.props
    return (
      <div style={{ width: '100%' }}>
        <Modal.Header>
          <div>
            <FormRow>
              <Title>{t('Location_Title')}</Title>
            </FormRow>
            <ModalTabs type='edit' activeIndex={3} />
          </div>
        </Modal.Header>
        <Div>
          <LocationTree
            nodes={nodes}
            depth={0}
          />
          <Form onChange={onChange}/>
        </Div>
      </div>
    )
  }
}
export default EditLocation
