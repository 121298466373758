import styled from 'styled-components/macro'

const PaddedDiv = styled.div`
overflow: scroll;
min-height: 400px;
max-height: 420px;

@media (max-width: 1280px){
  max-width: 585px;
}

@media screen and (min-width: 1281px) and  (max-width: 1366px){
  max-width: 650px;
}

.Icon{
  padding: 0px !important;
  color: black;
}
.ui-datatable .ui-datatable-data .cell-row-input{
  margin: 0px 10px 10px 10px !important;
  border: 1px solid black !important;
}
.p-datatable.p-component {
  width: 1050px;
}

.p-datatable .p-datatable-tbody tr > td {
  line-height: 0.76rem !important;
}
`
export {
  PaddedDiv
}
