import React from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ScheduleForm, Column } from './AutomatedReportModal.styles'
import ModalAction from '../modal-actions'
import { Modal, InputLabel, Loader } from '@scuf/common'
import { StyledModal } from 'Components/Modal/modal.styles'
import { FormInput } from 'Components/FormComponents/FormInput'
import FormSelect from 'Components/FormComponents/FormSelect'
import { isSelectRequired, isRequired, isUnique } from 'Components/../Forms/Validators'
import { FormRow } from 'Themes/ScufStyledComponents'
import FormDatePicker from '../form-datepicker'
import { reduxForm, Form, Field } from 'redux-form'
import { getHours, validate, FREQUENCIES } from './AutomatedReportModal.helpers'
import SelectWrapper from 'Components/Location/components/form/components/form-building/components/select-wrapper'
import WeekDaySelector from './components/weekday-selector'
import { useAutomatedReport } from './AutomatesReportModal.hooks'

const monthDays = [...Array(31)]
  .map((_, i) => i + 1)
  .map(day => ({ value: day, text: day }))

const LoaderContainer = ({
  loading,
  children
}) => {
  return (
    <ScheduleForm>
      {
        loading ? <Loader overlayOpacity={0.5} /> : null
      }
      { children }
    </ScheduleForm>
  )
}

const AutomatedReportModal = ({
  handleSubmit,
  reset,
  sites,
  t,
  frequency,
  open,
  setOpen,
  submit,
  valid,
  selectedFrequency,
  resetValues,
  loading,
  reportNames
}) => {
  const {
    setDayOfWeek,
    timeZoneNames
  } = useAutomatedReport()

  return (
    <StyledModal
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      size='medium'
      open={open}
      closeIcon={true}
      className='medium'
    >
      <Modal.Header>
        {t('AutomatedReport')}
      </Modal.Header>

      <ModalBody>
        <LoaderContainer loading={loading}>
          <Form
            id='preferencesEmailForm'
            onSubmit={handleSubmit}
          >
            <InputLabel
              label={t('Name')}
              htmlFor='confName'
            />
            <Field
              placeholder={t('NamePlaceholder')}
              name='confName'
              id='confName'
              component={FormInput}
              validate={[
                isRequired
              ]}
              t={t}
            />
            <InputLabel
              label={t('Sites')}
              htmlFor='confSites'
            />
            <Field
              placeholder={t('SitesPlaceholder')}
              name='confSites'
              multiple={true}
              icon='caret-down'
              search={true}
              component={SelectWrapper}
              options={sites}
              validate={[
                isSelectRequired
              ]}
              fluid
            />
            <p style={{ backgroundColor: '#f7f7f7', marginTop: '4px', fontsize: '16px', color: '#606064', fontStyle: 'italic', marginLeft: '0px', marginTop: '-20px', marginBottom: '0px' }}>Note: Reports are applicable only for child sites, ensure to have permissions for each of the selected site(s).</p>
            <InputLabel
              label={t('TimeZone')}
              htmlFor='confTimeZone'
            />
            <Field
              placeholder={t('TimeZonePlaceholder')}
              name='confTimeZone'
              id='confTimeZone'
              component={FormSelect}
              options={timeZoneNames}
              validate={[]}
            />

            <FormRow>
              <Column flex={0.4} style={{ paddingRight: '0.5rem ' }}>
                <InputLabel
                  label={t('Receive')}
                  htmlFor='confReceiveFrequency'
                />
                <Field
                  name='confReceiveFrequency'
                  id='confReceiveFrequency'
                  placeholder={t('ReceivePlaceholder')}
                  validate={[isRequired]}
                  options={frequency}
                  component={FormSelect}
                />
              </Column>
            </FormRow>
            {
              selectedFrequency === FREQUENCIES.WEEKLY
                ? <FormRow>
                  <Column>
                    <InputLabel
                      label={t('DayPlaceholder')}
                      htmlFor='confWeekDaySelector'
                    />
                    <Field
                      defaultValue={1}
                      id='confWeekDaySelector'
                      name='confWeekDaySelector'
                      component={WeekDaySelector}
                      validate={[isRequired]}
                      onChange={(day) => setDayOfWeek(day)}
                    />

                  </Column>
                </FormRow> : null
            }
            {
              selectedFrequency === FREQUENCIES.MONTHLY
                ? <FormRow>
                  <Column flex={0.1}>
                    <InputLabel
                      label={t('OnDay')}
                      htmlFor='confMonthDaySelector'
                    />
                    <Field
                      component={FormSelect}
                      id='confMonthDaySelector'
                      name='confMonthDaySelector'
                      options={monthDays}
                      validate={[isRequired]}
                    />
                  </Column>
                </FormRow>
                : null
            }
            <FormRow>
              <Column flex={0.2} style={{ paddingRight: '0.5rem ' }}>
                <InputLabel
                  label={t('StartDate')}
                  htmlFor='confReceiveDaysStart'
                />
                <Field
                  name='confReceiveDaysStart'
                  id='confReceiveDaysStart'
                  placeholder={t('StartDateLabel')}
                  component={FormDatePicker}
                  validate={[isRequired]}
                />
              </Column>
              <Column flex={0.2}>
                <InputLabel
                  label={t('Time')}
                  htmlFor='confReceiveDaysTime'
                />
                <Field
                  name='confReceiveDaysTime'
                  id='confReceiveDaysTime'
                  placeholder={t('TimeLabel')}
                  validate={[isRequired]}
                  options={getHours()}
                  component={FormSelect}
                />
              </Column>
            </FormRow>
          </Form>
        </LoaderContainer>
        <ModalAction
          onCancel={() => {
            reset('preferencesEmailForm')
            setOpen(false)
          }}
          onSubmit={() => {
            submit()
            if (valid && !loading) {
              setOpen(false)
            }
          }}
        />
      </ModalBody>
    </StyledModal>
  )
}

AutomatedReportModal.displayName = 'AutomatedReportModal'
AutomatedReportModal.propTypes = {
  handleSubmit: PropTypes.func,
  sites: PropTypes.array,
  timeZoneNames: PropTypes.array,
  t: PropTypes.func,
  frequency: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  submit: PropTypes.func,
  valid: PropTypes.bool,
  selectedFrequency: PropTypes.number,
  loading: PropTypes.bool,
  reportNames: PropTypes.array
}

AutomatedReportModal.defaultProps = {
  reportNames: []
}
export default reduxForm({
  form: 'preferencesEmailForm',
  enableReinitialize: true,
  validate
})(AutomatedReportModal)
