
/**
 * Create a percentual distribution of a list of values over a total value
 * @param {list<Pair>} values  list of Pair objects  Pair = { key: <string> , value: <number>, ...additionalParams}
 * @param {number} total
 * @param {list<string>} additionalParams list of aditional parameters to be added in the list of results
 * @returns {list<Result>} returns a list of the percentual distribution of each passed value
 * Result={
 *  value: <number>
 *  key: <string>,
 *  percentage: <number>
 *  {... additionalParams}
 * }
 */
export const calculateDistribution = (values, total = 1, additionalParams = []) => {
  const data = values.map(value => {
    let percentage = value.value * 100 / (total)
    percentage = Math.round(percentage * 10) / 10
    let result = {
      value: value.value,
      key: value.key,
      percentage: percentage
    }
    additionalParams.forEach(param => {
      result = { ...result, param: value[param] }
    })
    return result
  })
  return data
}
