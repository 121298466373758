import styled from 'styled-components'
import { Icon, Checkbox } from '@scuf/common'

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ChildrenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Button = styled.button`
  display: flex;
  height: 3rem;
  align-items: center;
  border: none;
  background: none;
  color: ${props => props.theme.iconscolor};
  font-family: "Honeywell Sans Web";
  font-weight: 600;

cursor: pointer;
  outline: none;
  &:hover  {
    background: ${props => props.theme.grayback ? props.theme.grayback : '#f0f0f0'};
  }
`

export const StyledIcon = styled(Icon)`
  margin: 0 1rem;
  color: #303030;  
`

export const StyledCheckbox = styled(Checkbox)`
  & .ui.checked.fitted.checkbox.sc-fzpkJw gebRCf{
  margin: 1rem 0rem 1rem 0rem  !important;
}
  & .ui.checkbox{
    margin: 1rem 0rem 1rem 0rem  !important;
  }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;

`

export const CheckboxContainer = styled.div`
  display: flex;
  margin: 1rem 0rem 0rem auto;

`

export const DropContainer = styled.div`
  display: flex;
`