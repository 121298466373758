import React from 'react'
import { Field } from 'redux-form'

const DataLabel = (props) => {
  const { id, dataKey } = props
  return (
    <Field
      {...props}
      component='input'
      name={`${id}.${dataKey}`}
    />
  )
}

export default DataLabel
