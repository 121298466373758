import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withRouter } from 'react-router-dom'
import EndUserLicense from './end-user-license'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { EndUserLicenseAgreementActions, getEulaInfoSelector } from './end-user-license.reducer'

const mapDispatchToProps = (dispatch) => {
  return {
    getEulaValidation: () => dispatch(EndUserLicenseAgreementActions.initialEULARequest()),
  }
}

export default compose(
  withLDConsumer(),
  withRouter,
  connect(getEulaInfoSelector, mapDispatchToProps),
)(EndUserLicense)
