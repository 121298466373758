import styled from 'styled-components/macro'
import { SuperContainer, InfoText, Subtitle } from '../../../../Themes/ScufStyledComponents'

export const TermsContainer = styled(SuperContainer)`
  flex-direction: column;
  background-color: transparent;
  padding: 0;
  align-items: center;

  @media screen and (max-width: 720px) {
    & * {
      font-size: 14px;
    }
  }
`

export const TermsSubtitle = styled(Subtitle)`
  text-align: center;
  padding-top: 1rem;
  flex: 0.1;
`

export const TermsText = styled(InfoText)`
  overflow-y: auto;
  padding: 1rem;
  padding-top: 0;
  flex: 2;
`
