import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withRouter } from 'react-router-dom'
import SubscriptionAgreement from './subscription-agreement'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { SubscriptionAgreementActions } from './subscription-agreement.reducer'
import { getSLAInfoSelector } from './subscription-agreement.selector'
import { withPermissions } from 'Utils/permissions'

const mapDispatchToProps = (dispatch) => {
  return {
    getSLAValidation: () => dispatch(SubscriptionAgreementActions.initialSLARequest()),
    endSLAValidation: () => dispatch(SubscriptionAgreementActions.setWaitValidation(false))
  }
}

export default compose(
  withLDConsumer(),
  connect(getSLAInfoSelector, mapDispatchToProps),
  withPermissions()
)(SubscriptionAgreement)
