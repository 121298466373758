import { withFormik } from 'formik'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import AlertsManagementModal from './alerts-management-modal.component'
import { MaintenanceApi } from 'Services'
import CronBuilder from './cron-builder'
import validationSchema from './alerts-management-modal.validators'

export const handleSubmit = async ({
  scheduleTime,
  repeated,
  fromHour,
  toHour,
  repeat,
  days,
  scheduleTimeZoneId,
  ...payload
}, {
    props: {
      onSubmit,
      closeModal
    },
    resetForm
  }) => {
  let cEx = ''
  if (repeated) {
    const hours = Array.from(Array(toHour - fromHour + 1),
      (_, i) => i + fromHour
    )
      .filter((hour, index) => index % repeat === 0)
    try {
      const cronExp = new CronBuilder()
      const exp = cronExp.getAll()
      exp.minute = [0]
      exp.hour = hours
      exp.dayOfTheWeek = days
      exp.dayOfTheMonth = ['?']
      cronExp.setAll(exp)
      cEx = cronExp.build()
    } catch (e) {
      // handle error
    }
  }
  const cron = repeated ? `0 ${cEx} *` : undefined
  const response = await MaintenanceApi.createRule({
    ...payload,
    fromHour,
    toHour,
    repeat,
    cron,
    scheduleTime: !repeated ? scheduleTime : undefined,
    scheduleTimeZoneId: repeated ? scheduleTimeZoneId : undefined,
    uiScheduleTimeZoneId: scheduleTimeZoneId
  })
  if (response.ok) {
    resetForm()
    closeModal()
    onSubmit()
  }
}

export default pipe(
  withFormik({
    displayName: 'CreateMaintenanceRuleModal',
    handleSubmit,
    validationSchema,
    mapPropsToValues: props => ({
      ...props,
      scheduleTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  }),
  translate('AlertsManagementModal')
)(AlertsManagementModal)
