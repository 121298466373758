import { without } from 'lodash'
import get from 'lodash/get'
import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { releaseNotesMarkAsRead } from 'Store/release-notes'

import { getDefaultHiddenColumns } from '../Components/AssetManagementBase/asset-management-base.constants'
import { themeLight } from '../Containers/AppFrame.styles'

const { Types, Creators } = createActions({
  userDetailsRequest: null,
  userRequest: null,
  userSuccess: ['data'],
  userFailure: null,
  userDetailSuccess: ['data'],
  userSetLanguage: ['language'],
  userSetHiddenColumns: ['deviceType', 'column', 'hidden'],
  userSetTheme: ['theme'],
  userSelectOrganization: ['selectedOrganizationId'],
  userSetUnitSystem:["unitSystem"]
})

export const UserTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  firstName: '',
  lastName: '',
  userEmail: '',
  features: {},
  id: '',
  language: 'en-US',
  isAdmin: false,
  isHoneywellAdmin: false,
  userData: { 'none': 'nonde' },
  userRole: '',
  roles: [],
  organizations: [],
  assets: {
    hiddenColumns: {}
  },
  theme: themeLight,
  selectedOrganizationId: '',
  releaseNotes: {},
  unitSystem: 'metric'
})

/* ------------- Reducers ------------- */

export const request = state => state.merge({ loading: true, isAdmin: false, isToolsViewer: false, roles: [] })

export const success = (state, { data }) => state.merge({ loading: false, subscription: data })

function checkRole (roleName) {
  switch (roleName) {
    case 'Administrator':
      return 'isAdmin'
    case 'Tools Admin':
    case 'Tools Viewer':
      return 'isToolsViewer'
    case 'Tools Tco Viewer':
      return 'isTCOViewer'
    case 'Tools HaaS Viewer':
      return 'isHaaSViewer'
    case 'HaaS Admin':
      return 'isHaaSAdmin'
    case 'Device User':
      return 'isDeviceViewUser'
    default:
      return ''
  }
}

const detailSuccess = (state, { data }) => {
  const assets = data.assetAssociation || []

  const personDetail = {
    firstName: get(data, 'personDetails.firstName', ''),
    lastName: get(data, 'personDetails.lastName', '')
  }

  const { isHoneywellAdmin } = data

  let isAdmin = false
  let userRole = ''
  let roles = []
  const organizations = assets.map(asset => ({
    siteName: get(asset, 'organizationIdentifier.organizationName', ''),
    siteId: get(asset, 'organizationIdentifier.organizationGuid', '')
  }))

  for (let i = 0; i < assets.length; i++) {
    const roleNames = get(assets[i], 'roles', []).map(r => r.name)
    const adminRoles = get(assets[i], 'roles', []).map(r => r.isAdmin)

    if (adminRoles.some(b => b)) {
      isAdmin = true
    }

    roles = [...roles, ...roleNames]
    userRole = checkRole(get(roleNames, ['0'], ''))

    for (let j = 0; j < assets[i].organizationalUnitAssociation.length; j++) {
      const roleNames = get(assets[i], `organizationalUnitAssociation[${j}].roles`, []).map(r => r.name)
      const adminRoles = get(assets[i], `organizationalUnitAssociation[${j}].roles`, []).map(r => r.isAdmin)

      if (adminRoles.some(b => b)) {
        isAdmin = true
      }

      roles = [...roles, ...roleNames]
      userRole = checkRole(get(roleNames, ['0'], ''))
    }
  }

  return state.merge({
    loading: false,
    ...personDetail,
    isAdmin,
    userRole,
    userData: data,
    isHoneywellAdmin,
    roles: Array.from(new Set(roles)),
    organizations
  })
}

export const failure = state => state.merge({ loading: false, isAdmin: false, subscription: null })

const setLanguage = (state, { language }) => state.merge({ language })

export const setHiddenColumns = (state, { deviceType, column, hidden }) => {
  const hiddenColumns = get(state, `assets.hiddenColumns.${state.userData.id}.${deviceType}`, getDefaultHiddenColumns()[deviceType])
  const newHiddenColumns = hidden
    ? without(hiddenColumns, column)
    : [...hiddenColumns, column]
  return state.setIn(['assets', 'hiddenColumns', state.userData.id, deviceType], newHiddenColumns)
}

export const setTheme = (state, { theme }) => state.merge({ theme })

export const setUnitSystem = (state, { unitSystem }) => state.merge({ unitSystem })


export const setSelectedOrganization = (state, { selectedOrganizationId }) => state
  .setIn(['selectedOrganizationId'], selectedOrganizationId)

export const releaseNotesMarkAsReadReducer = (state, { payload }) => {
  const { userId, releaseId } = payload
  return state
    .setIn(['releaseNotes', userId, releaseId], true)
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_DETAILS_REQUEST]: request,
  [Types.USER_REQUEST]: request,
  [Types.USER_SUCCESS]: success,
  [Types.USER_DETAIL_SUCCESS]: detailSuccess,
  [Types.USER_FAILURE]: failure,
  [Types.USER_SET_LANGUAGE]: setLanguage,
  [Types.USER_SET_HIDDEN_COLUMNS]: setHiddenColumns,
  [Types.USER_SET_THEME]: setTheme,
  [Types.USER_SELECT_ORGANIZATION]: setSelectedOrganization,
  [releaseNotesMarkAsRead]: releaseNotesMarkAsReadReducer,
  [Types.USER_SET_UNIT_SYSTEM]: setUnitSystem
})