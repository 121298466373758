import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  remoteAccessInProgressRequest: ['deviceId', 'accessToken'],
  remoteAccessInProgressResponse: ['inProgress']
})

export const RemoteAccessTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  inProgress: false
})

/* ------------- Reducers ------------- */
export const updateRemoteAccessInProgress = (state, { inProgress }) => state.merge({ inProgress })
export const reducer = createReducer(INITIAL_STATE, {
  [Types.REMOTE_ACCESS_IN_PROGRESS_RESPONSE]: updateRemoteAccessInProgress
})
