const devicesSampleData = [
  ['type',
    'Mobile Computer',
    'Mobile Computer',
    'Mobile Computer',
    'Mobile Computer',
    'Mobile Computer',
    'Mobile Computer',
    'Mobile Computer'],
    ['model',
    'CN80',
    'CN80',
    'CN80',
    'CN80',
    'CN80',
    'CN80',
    'CN80'],
  ['serial',
    '22DACB3DD4',
    '22DACB3DD5',
    '22DACB3DD6',
    '22DACB3DD7',
    '22DACB3DD8',
    '22DACB3DD9',
    '22DACB3DD10'],
  ['alias',
    'First floor',
    'Second floor',
    'Third floor',
    'Laboratory 1',
    'Laboratory 2',
    'Workstation 1',
    'Workstation 2'],
    ['site',
    '/ Honeywell / Site1',
    '/ Honeywell / Site2',
    '/ Honeywell / Site3',
    '/ Honeywell / Site4',
    '/ Honeywell / Site5',
    '/ Honeywell / Site6',
    '/ Honeywell / Site7']
]
export default devicesSampleData
