import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetAllResult, NormalizedSite } from '../service'

export interface SitesState {
  sitesById: Record<string, NormalizedSite>
  rootSiteIds: string[]
  isLoading: boolean
  error: string | null
}

const sitesInitialState: SitesState = {
  sitesById: {},
  rootSiteIds: [],
  isLoading: false,
  error: null
}

function startLoading (state: SitesState) {
  state.isLoading = true
}

function loadingFailed (state: SitesState, action: PayloadAction<string>) {
  state.isLoading = false
  state.error = action.payload
}

const sites = createSlice({
  name: 'sites',
  initialState: sitesInitialState,
  reducers: {
    getSitesRequest: startLoading,
    getSitesSuccess (state, { payload } : PayloadAction<GetAllResult>) {
      const { sites, rootSiteIds } = payload
      state.isLoading = false
      state.error = null

      state.rootSiteIds = rootSiteIds
      state.sitesById = sites
    },
    getSitesFailure: loadingFailed
  }
})

export const {
  getSitesRequest,
  getSitesSuccess,
  getSitesFailure
} = sites.actions

export const sitesReducer = sites.reducer
