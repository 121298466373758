export class SsoOauthService {
  constructor (api) {
    this.api = api
  }

  getToken ({
    code,
    authProvider,
    redirectUrl,
    organizationId
  }) {
    return this.api.post('/v2/auth', {
      accessToken: code,
      clientType : 'custom',
      grantType: 'authorization_code',
      code,
      authProvider,
      redirectUrl
    }, {
      params: { organizationId }
    })
  }
}

export default SsoOauthService
