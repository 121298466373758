import { createStructuredSelector } from 'reselect'
import {
  usersById as users,
  getCurrentUser as currentUser,
  userSearchStruct,
  loading
} from '../../Selectors/UserSelector'

export default createStructuredSelector({
  users,
  currentUser,
  filter: state => state.filters.filter,
  usersResults: userSearchStruct,
  loading
})
