import { withFormik } from 'formik'
import { translate } from 'react-translate'
import { connect } from 'react-redux'
import { pipe } from 'ramda'

import BaseModal from '../modal-base'
import validationSchema from './create-network-ranges.validators'
import { NetworkRangesActions } from '../../store'


export const mapStateToProps = state => {
  return {
     isOpen: state.networkRanges.getIn(['isShowingModal'], false)
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(NetworkRangesActions.closeModal()),
    createNetworkRangeRequest: (siteId, networkRange, callback) => dispatch(NetworkRangesActions.createNetworkRangeRequest(siteId, networkRange, callback))
  }
}

export default pipe(
  withFormik({
    validationSchema,
    handleSubmit: (payload, { props, resetForm }) => props.createNetworkRangeRequest(
      props.siteId,
      payload,
      () => {
        props.onSubmitSuccess()
        resetForm()
      }),
    displayName: 'NetworkRanges'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate('CreateNetworkRanges')
)(BaseModal)
