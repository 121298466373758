import { createStructuredSelector, createSelector } from 'reselect'
import { calculateDistribution } from '../../../dashboard-cards-helpers'
import { filteredDevices, groupBySiteAndSumValue } from '../../offline-devices-by-site.selector'

export const groupedDevices = createSelector(
  [filteredDevices],
  devices => {
    const groupedSites = groupBySiteAndSumValue(devices)
    return Object.keys(groupedSites).map(Site => ({ key: Site, value: groupedSites[Site] }))
  }
)
export const total = createSelector(
  [groupedDevices],
  groupedDevices => groupedDevices.reduce((sum, modelData) => {
    return sum + modelData.value
  }, 0)
)

export const data = createSelector(
  groupedDevices,
  total,
  (values, total) => calculateDistribution(values, total)
)

export default createStructuredSelector({
  data
})
