import { createSelector, createStructuredSelector } from 'reselect'
import { isEmpty } from 'ramda'

export const getBatteriesById = state => state.batteries.result

export const loading = state => state.batteries.loading

export const getBatteries = state => state.batteries.getIn(['entities', 'batteries'])

export const batteries = createSelector([getBatteriesById, getBatteries], (ids, bt) =>
  ids.map(i => bt[i])
)

export const sitesById = state => state.siteManager.sitesById

export const rootSites = createSelector([sitesById], sites => sites.map(s => ({
  value: s.guid,
  text: s.name,
  key: s.name
})))

/**
 * Calculate percentage difference to each propery between consecutive periods
 * @param {Array<Object>} batteryHealth
 * @return {Array<Object>} [{...batteryHealth, propChange: <number> }, ...]
 */
export const batteryHealth = ({ batteries: { batteryHealth } }) => {
  if (!batteryHealth || isEmpty(batteryHealth)) {
    return [{}]
  }
  let batteriesSorted = [...batteryHealth].sort((a, b) => a.id > b.id)
  if (batteriesSorted.length > 1) {
    Object.keys(batteriesSorted[0])
      .filter(props => props !== 'id')
      .forEach(prop => {
        batteriesSorted = batteriesSorted.map((battery, i) => {
          // There is at least one period before to compare agains to
          if (i < batteriesSorted.length - 1) {
            const diff = batteriesSorted[i][prop] - batteriesSorted[i + 1][prop]
            const change = ((diff * 100) / (batteriesSorted[i + 1][prop] || 1)) || 0
            return {
              ...battery,
              [`${prop}Change`]: parseFloat(change.toFixed(2)),
            }
          } else {
            return { ...battery }
          }
        })
      })
    return batteriesSorted
  } else {
    Object.keys(batteriesSorted[0])
      .filter(prop => prop !== 'id')
      .forEach(prop => {
        batteriesSorted = batteriesSorted.map(battery => {
          return { ...battery, [`${prop}Change`]: 0 }
        })
      })
    return batteriesSorted
  }
}

/**
 * Calculate percentage of each battery level
 * @param {Array<Object>} batteryLevels
 * @returns {Object} {HIGH: {percent: float}, MEDIUM; {percent: floar}, LOW: {percent: float}}
 */
export const batteryLevels = ({ batteries: { batteryLevels } }) => {
  if (!batteryLevels || isEmpty(batteryLevels)) {
    return { HIGH: { percent: 0, value: 0 }, MEDIUM: { percent: 0, value: 0 }, LOW: { percent: 0, value: 0 } }
  }
  const total = batteryLevels.reduce((sum, category) => sum + category.value, 0)
  const COLORS = {
    HIGH: '#7EB338', MEDIUM: '#FFD153', LOW: '#EE3124'
  }
  batteryLevels = batteryLevels.reduce((res, category) => {
    const percent = parseFloat(((100 * category.value) / total).toFixed(1)) || 0
    res[category.label] = {
      value: category.value,
      percent: percent,
      label: `${percent}%`,
      angle: percent,
      color: COLORS[category.label]
    }
    return res
  }, {
    HIGH: { percent: 0, value: 0 },
    LOW: { percent: 0, value: 0 },
    MEDIUM: { percent: 0, value: 0 }
  })
  return batteryLevels
}

export const batteryHealthLoading = ({ batteries: { batteryHealthLoading, batteryLevelsLoading } }) => {
  return batteryHealthLoading || batteryLevelsLoading
}

export const batteryHealthError = ({ batteries: { batteryHealthError, batteryLevelsError } }) => {
  return batteryHealthError || batteryLevelsError
}

export const batteriesSelector = createStructuredSelector({
  batteries,
  loading,
  rootSites,
  batteryHealth,
  batteryLevels,
  batteryHealthLoading,
  batteryHealthError
})
