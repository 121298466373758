import styled from 'styled-components'
import {
  Select,
  Icon
} from '@scuf/common'

export const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-right: ${props => props.theme.padding};
  .delete.icon {
    z-index: 200;
  }
  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
`

export const StyledIcon = styled(Icon)`
  color: ${({ enabled }) => enabled && '#4183C4'};
  cursor: ${({ enabled }) => enabled && 'pointer'};
`
export const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.2rem;
`
export const TagsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-height: 2.5rem;
  max-height: 15rem;
  border: 0.0625rem solid #d0d0d0;
  align-content: flex-start;
`
export const Tag = styled.div`
  display: block;
  display: flex;
  background-color: ${({ selected }) => selected
    ? '#5DB2EC'
    : '#E4E4E6'};
  margin: 0.3rem 0.3rem;
  max-width: 70%;
  padding: 0.25rem 1rem;
  min-height: 1.5rem !important;
  color: ${({ selected }) => selected
    ? '#FFFFFF'
    : '#303030'};
  text-transform: uppercase;
  user-select: none;
  align-items: center;
  cursor: pointer;
`

export const Label = styled.div`
  margin-right: 0.5rem;
`

export const RowIcon = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.75rem;
  padding-top: 1.5rem;
`
