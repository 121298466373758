import { createReducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import config from '../../../Config/AppConfig'

import LoginActions from 'Redux/LoginRedux'

import {
  setOauthInfo,
  setRedirectRoute,
  exchangeOauthCode,
  changeOrganization
} from './actions'

export const INITIAL_STATE = {
  authProvider: null,
  appId: null,
  key: null,
  loginUrl: null,
  redirectRoute: null,
  selectedOrg: null,
  logoutUrl: null,
  isPending: false,
  isFulfilled: false
}

const setOauthInfoReducer = (state, { payload }) => {
  state.key = payload.key
  state.authProvider = payload.clientName
  state.appId = payload.appId
  state.loginUrl = payload.loginUrl
  state.logoutUrl = payload.logoutUrl
}

const removeAllValues = (state) => {
  state.key = null
  state.authProvider = null
  state.appId = null
  state.loginUrl = null
  state.redirectRoute = null
  state.selectedOrg = null
  state.isPending = false
  state.isFulfilled = false
  state.logoutUrl = null
}

const setRedirectRouteReducer = (state, { payload }) => {
  state.redirectRoute = payload
}

const setSelectedOrganization = (state, { payload }) => {
  state.selectedOrg = payload
}

const fetchOauthTokenPendingReducer = (state, { payload }) => {
  state.isPending = true
  state.isFulfilled = false
}

const fetchOauthTokenFulfilledReducer = (state, { payload }) => {
  state.isPending = false
  state.isFulfilled = true
}

const fetchOauthTokenRejectedReducer = (state, { payload }) => {
  state.isPending = false
  state.isFulfilled = false
}

const reducer = createReducer(INITIAL_STATE, {
  [setOauthInfo]: setOauthInfoReducer,
  [setRedirectRoute]: setRedirectRouteReducer,
  [exchangeOauthCode.pending]: fetchOauthTokenPendingReducer,
  [exchangeOauthCode.fulfilled]: fetchOauthTokenFulfilledReducer,
  [exchangeOauthCode.rejected]: fetchOauthTokenRejectedReducer,
  [changeOrganization]: setSelectedOrganization,
  [LoginActions.loginLogoutRequest]: removeAllValues,
  [LoginActions.loginSignInError]: removeAllValues,
  [LoginActions.loginReset]: removeAllValues
})

export const ssoOauthreducer = persistReducer({
  key: 'ssoOauth',
  storage,
  blacklist: [
    'isPending',
    'isFulfilled'
  ],
  debug: config.debug
}, reducer)
