import styled from 'styled-components/macro'
import { Icon } from '@scuf/common'

const FilterResetAll = styled.div`
  color: #4183C4;
  margin: 10px 5px;
  cursor: pointer;
  font-weight: 600;
`

const FilterNonApplied = styled.div`
  color: #303030;
  margin: 1rem;
  font-weight: 600;
`

const FilterSubcategoryContainer = styled.div`
  overflow-y: hidden;
  max-height: 12rem;
  &:hover {
    overflow-y: scroll;
  }
`
const FilterCheckBoxContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 2.5rem;
  align-items: center;
`

const FilterSectionContainer = styled.div`
  min-width: 20rem;
  margin: 1rem 1rem 1rem 0.5rem;
  border-top: solid 1px #C0C0C0;
  width: inherit;
`

const SectionTitleRow = styled.div`
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: auto;
  justify-items: center;
  vertical-align: middle;
  &:not(:first-of-type) {
    border-top: solid 1px #C0C0C0;
  }
`

const CaretIcon = styled(Icon)`
  color: #606060;
`

export {
  FilterResetAll,
  FilterNonApplied,
  FilterSubcategoryContainer,
  FilterCheckBoxContainer,
  FilterSectionContainer,
  SectionTitleRow,
  CaretIcon
}
