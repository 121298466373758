import { connect } from 'react-redux'
import mapStateToProps from './notification-bell.select'
import NotificationBell from './notification-bell'
import { translate } from 'react-translate'
import { pipe } from 'ramda'
import { withRouter } from 'react-router'

export default pipe(
  translate('BellNotification'),
  withRouter,
  connect(mapStateToProps, null)
)(NotificationBell)
