import { createSelector, createStructuredSelector } from 'reselect'

export const submitAction = ({ modal }) => modal.getIn(['modalProps', 'submitAction'], () => {})
export const theme = ({ user }) => user.getIn(['theme'], {})
export const Cls = ({ modal }) => modal.getIn(['modalProps', 'cls'], () => {})

export default createStructuredSelector({
  submitAction,
  theme,
  Cls
})
