import * as Yup from 'yup'

export default ({ t }) => Yup.object().shape({
  name: Yup.string()
    .required(t('Required'))
    .max(32, t('MaxLength')),
  repeated: Yup.bool(),
  scheduleTime: Yup.date()
    .when('repeated', (repeated, schema) => (!repeated
      ? Yup.date().typeError(t('InvalidDate')).required(t('Required'))
      : schema
    )),
  repeat: Yup.number()
    .when('repeated', (repeated, schema) => (repeated
      ? Yup.number().required(t('Required'))
      : schema
    )),
  days: Yup.array()
    .when('repeated', (repeated, schema) => (repeated
      ? Yup.array().required(t('Required'))
      : schema
    )),
  fromHour: Yup.number()
    .when('repeated', (repeated, schema) => (repeated
      ? Yup.number().required(t('Required'))
      : schema
    )),
  toHour: Yup.number()
    .when('repeated', (repeated, schema) => (repeated
      ? Yup.number().required(t('Required'))
      : schema
    ))
    .when('fromHour', (fromHour, schema) => (!isNaN(fromHour)
      ? schema.moreThan(fromHour, t('MaxValue'))
      : schema)
    ),
  scheduleTimeZoneId: Yup.string()
    .when('repeated', (repeated, schema) => (repeated
      ? Yup.string().required(t('Required'))
      : schema
    ))
})
