import { createSelector, createStructuredSelector } from 'reselect'
import { userData } from 'Selectors/UserSelector'
import { getUsertTimeZone, FREQUENCIES } from './AutomatedReportModal.helpers'
import Inmutable from 'seamless-immutable'
import moment from 'moment'
import { get } from 'lodash'
import { getTimeZonedHourFromUtcHour, getUtcHourFromMsAndTimeZone } from '../../preferences-email.helpers'

export const frequencies = ['Daily', 'Weekly', 'Monthly']

export const sitesReducer = ({ siteManager }) =>
  siteManager.getIn(['sites'], {})

export const sites = createSelector(
  sitesReducer,
  sites => Object.keys(sites).map(
    value => ({
      text: sites.getIn([
        value,
        'organizationalUnitHierarchy'], ''),
      value
    })
  )
)

export const frequency = () => {
  return frequencies.map((value, index) => ({
    value: index + 1, text: value
  }))
}

export const selectedReportId = ({ preferences }) => preferences.getIn(['selectedReportId'], null)

export const scheduledReports = ({ preferences }) => preferences.getIn(['scheduledReports'], [])

export const reportNames = createSelector(
  [scheduledReports],
  reports => reports.map(report => report.name)
)

export const getPreferencesEmailForm = createSelector(
  [userData, selectedReportId, scheduledReports, reportNames],
  (_user, _selectedReportId, _scheduledReports, _reportNames) => {
    const form = {
      userId: _user.getIn(['id']),
      confTimeZone: getUsertTimeZone(),
      reportNames: _reportNames || []
    }
    if (_selectedReportId) {
      const report = get(_scheduledReports.filter(report => report.id === _selectedReportId), [0], null)
      if (report) {
        form.id = _selectedReportId
        fillFormInitialData(report, form)
      }
    }
    return Inmutable(form)
  }
)

export const fillFormInitialData = (report, form) => {
  form.confName = report.name
  form.confTimeZone = report.timeZone
  form.confSites = report.orgUnitIds
  form.confReceiveDaysStart = getUtcHourFromMsAndTimeZone(report.startDate.seconds * 1000, report.timeZone)
  form.confReceiveDaysTime = report.notificationHour
  form.confReceiveFrequency = report.type
  form.editing = true

  if (report.type === FREQUENCIES.WEEKLY) {
    form.confWeekDaySelector = get(report, ['daysOfWeek', 0], 0)
  }
  if (report.type === FREQUENCIES.MONTHLY) {
    form.confMonthDaySelector = report.dayOfMonth
  }
}

export const selectedFrequency = ({ form }) =>
  get(form, ['preferencesEmailForm', 'values', 'confReceiveFrequency'], 0)

export const loading = ({ preferences }) => preferences.getIn(['loading'], false)

export default createStructuredSelector({
  sites,
  frequency,
  initialValues: getPreferencesEmailForm,
  getUsertTimeZone,
  selectedFrequency,
  selectedReportId,
  reportNames,
  loading
})
