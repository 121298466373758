import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

export const StyledModal = styled(Modal)`
  & .ui.radio.checkbox input ~ label {
    color: ${props => props.theme.h1color};
  }
  & div.ui.input-label .input-label-message {
    color: ${props => props.theme.h4color};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown {
    background: ${props => props.theme.inputback};
    color: ${props => props.theme.h1color};
    border: ${props => props.theme.inputborder};
  }
  & .ui.scuf-dropdown-wrapper.disabled .ui.disabled.dropdown {
    background: ${props => props.theme.inputback};
    color: ${props => props.theme.h1color};
    border: ${props => props.theme.inputborder};
  }
  & .oss-agree {
    background-color: ${props => props.theme.inputback};
  }

  & .close-icon-wrap {
    position: static !important;
    float: right;
  }
  & i {
    color: ${props => props.theme.h1color};
  }
  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder};
    color: ${props => props.theme.h1color};
  }
  & .ui.active.visible.selection.dropdown .selected.item {
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.h1color};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item {
    color: ${props => props.theme.h1color};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item:hover {
    background-color: ${props => props.theme.black};
  }

  & .scrolling.content {
    border-bottom:none !important;
  }

  & .ui.single-input .input-box {
    background: ${props => props.theme.inputback};
    color: ${props => props.theme.h1color};
    border-color: ${props => props.theme.inputbordercolor};
  }
  & .ui.single-input .search-icon {
    background: ${props => props.theme.intercolor};
  }

  & .ui.tree {
    background: ${props => props.theme.background};
  }

  & .ui.search-wrap .ui.search.ui-search .prompt {
    background: ${props => props.theme.inputback};
    color: ${props => props.theme.h1color};
    border: ${props => props.theme.inputborder};
  }
  & .ui.search-wrap .search-icon {
    background: ${props => props.theme.intercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item {
    color: ${props => props.theme.h1color};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item {
    color: ${props => props.theme.inactivecolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item {
    color: ${props => props.theme.tabcolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & .datatable > div {
    background: ${props => props.theme.background};
    border-color: ${props => props.theme.inputbordercolor};
  }
  & .datatable > div > div {
    background: ${props => props.theme.background};
  }
  & .datatable > div > div > div {
    border-color: ${props => props.theme.inputbordercolor};
    color: ${props => props.theme.bodyfontcolor};
    background: ${props => props.theme.background};
  }
  & .datatable > div > div > div > p {
    color: ${props => props.theme.bodyfontcolor};
  }
  & .ui.radio.checkbox > label {
    color: ${props => props.theme.bodyfontcolor};
  }
  & .ui.checkbox > label {
    color: ${props => props.theme.bodyfontcolor};
  }
  & .datatable > div > div > div > div {
    color: ${props => props.theme.bodyfontcolor};
  }

  & .ui.search-wrap .ui.search.ui-search .results {
    background: ${props => props.theme.background};
  }
  & .ui.search-wrap .ui.search.ui-search .results .result {
    background: ${props => props.theme.background};
  }
  & .ui.search-wrap .ui.search.ui-search .results .result:hover {
    background: ${props => props.theme.black};
  }
  & .ui.notification {
    background:${props => props.theme.background}}
  & .ui.notification > div {
      background:${props => props.theme.background}}

  & .accordion.ui.fluid .title {
    background: ${props => props.theme.inputback}
  }
  & .accordion.ui.fluid .cVXHQF {
    color: ${props => props.theme.h1color}
  }

  & .calendar-overlay-wrap {
    background: ${props => props.theme.inputback}
  }
  & .ui.date-picker-calendar {
    background: ${props => props.theme.inputback};
    color: ${props => props.theme.h1color};
  }
  & .ui.date-picker-calendar .day {
    color: ${props => props.theme.h1color};
  }
  & .ui.date-picker-calendar .week > .day:not(.selected):not(.different-month):not(.disabled):hover {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder}
  }
  & .ui.modal {
    @media screen and (max-width: 720px){
      position: static !important;
      height: 100% !important;
    }
  }
  & .modal-close {
    font-size: 1rem !important;
  }

  & .fabLgN {
    background:${props => props.theme.grayback};
  }

  & .eUCkry {
    border: ${props => props.theme.darkBorder};
  }

  & .jhKkuA {
    color: ${props => props.theme.headercolor}
  }
  & .header:not(header) {
    font-size: 26px !important;
    font-weight: 500 !important;
  }
  &.ui.large.modal {
    width: ${props => props.width} !important;
}
`
