import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { normalize } from 'normalizr'

import { tagsSchema } from './entity'

export class TagsService {
  constructor (api) {
    this.api = api
  }

  getTagsForAsset (assetId, assetType = 'device') {
    return this.api.get('/v1/tagging/asset', { assetId, assetType })
  }

  getTagsForGivenAssets = async (assetSiteList, assetType = 'device') => {
    try {
      const response = await this.api.post('/v1/tagging/assets', { assetSiteList, assetType })
      if (response.ok) {
        const tags = flatMap(
          get(response, 'data.tagResponses', []),
          ({ tags, siteId }) => tags.map(tag => ({ ...tag, siteId }))
        )
        const data = normalize(tags, [tagsSchema])
        return {
          ok: true,
          data: {
            ...data,
            result: uniq(data.result)
          }
        }
      } else {
        return response
      }
    } catch (problem) {
      return {
        ok: false,
        problem
      }
    }
  }

  removeTagsForGivenAssets = (assetSiteList, assetType = 'device') => {
    return this.api.post('/v1/tagging/remove', { assetSiteList, assetType })
  }

  searchKeys (filter, assetType = 'device') {
    return this.api.get('/v1/tagging/keys', { filter, assetType })
  }

  getValues = (key, assetType = 'device', value = undefined) => {
    return this.api.get('/v1/tagging/values', { key, assetType, value })
  }

  queryKeyValue (key, value) {
    return this.api.post('/v1/tagging/query', {
      assetType: 'device',
      query: {
        keyvalue: { [key]: value }
      }
    })
  }

  queryTags (tags, assetType = 'device') {
    return this.api.post('/v1/tagging/query', {
      assetType,
      query: {
        'Or': tags.map(tag => {
          const [key, value] = tag.split(':')
          return {
            keyvalue: { [key]: value }
          }
        })
      }
    })
  }

  createTag (assetId, tag) {
    return this.api.post('/v1/tagging', {
      assetType: 'device',
      tags: [tag]
    })
  }

  createTagsForAssets = (assetSiteList, action, assetType = 'device') => {
    return this.api.post('/v1/tagging/bulk', {
      assetSiteList,
      action,
      assetType
    })
  }

  searchAllTags () {
    return this.api.get(`/v1/tagging`)
  }
}
