import { ApisauceInstance } from 'apisauce'
import { IOrganizationsService, Organization, OrganizationsResult } from './interfaces'
import * as R from 'ramda'

export class OrganizationsService implements IOrganizationsService {
  private client: ApisauceInstance

  constructor (client: ApisauceInstance) {
    this.client = client
  }

  public async getOrganizations (): Promise<OrganizationsResult> {
    const url = '/api/groupmgmt/OrgUnits'

    const response = await this.client.get<any[]>(url)
    if (response.ok && response.data != null) {
      return {
        organizations: response.data?.filter(entity => isOrg(entity)).map(prettifyOrg)
      }
    } else {
      throw Error('There was an error loading all organizations.')
    }
  }
}

function isOrg (entity: any) {
  return R.hasPath(['organizationalUnitIdentifier', 'organizationalUnitName'], entity) &&
    entity.parentIdentifier == null
}

function prettifyOrg (entity: any): Organization {
  const id = entity.organizationalUnitIdentifier.organizationalUnitGuid

  return {
    id,
    name: entity.organizationalUnitIdentifier.organizationalUnitName
  }
}
