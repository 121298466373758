import { createStructuredSelector, createSelector } from 'reselect'

export const siteById = ({ siteManager }) => siteManager.getIn(['sitesById'])

export const sites = createSelector(
  [siteById],
  _sitesById => _sitesById.map(({ name, guid }) => ({ value: guid, text: name }))
)

export default createStructuredSelector({
  sites
})
