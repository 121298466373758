import { pipe } from 'ramda'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import AssetSelector from '../AssetSelector'

const DeviceSelectionStep = ({
  t,
  assetsList,
  loading,
  setLoading,
  setIsScheduleFromFile,
  setUploadFile,
  setFileName,
  fileName,
  ...rest }) => {
  return (
    <Fragment>
      <h4>{t('AskHowMsg')}</h4>
      <Field
        component={AssetSelector}
        t={t}
        assetsList={assetsList}
        loading={loading}
        setLoading={setLoading}
        init={rest.initialValues}
        setIsScheduleFromFile={setIsScheduleFromFile}
        setUploadFile={setUploadFile}
        setFileName={setFileName}
        fileName={fileName}
        name='AssetSelector'
      />
    </Fragment>
  )
}

export default pipe(
  reduxForm({
    form: 'softwareUpdateWizard',
    destroyOnUnmount: false
  }),
  connect(
    state => ({ initialValues: state.form.softwareUpdateWizard.values?.AssetSelector })
  )
)(DeviceSelectionStep)
