import {call, put, all, takeLatest} from 'redux-saga/effects'
import {LtlTripActions, LtlTripTypes} from './actions'
import DashboardActions from '../../../Redux/DashboardRedux'
import {LTLTripApi} from '../../../Services'


export function * getLTLTotalTripsCard ({ siteId, initialDate, finalDate }) {
    try {
      const response = yield call(LTLTripApi.getLTLTotalTripsCard, siteId, { 'startDate': initialDate, 'endDate': finalDate })
      if (response.ok) {
        yield all([
          put(LtlTripActions.dashboardResponseLTLTotalTripsCard({ ...response.data, statusCode: response.status })),
          put(DashboardActions.dashboardGenericSuccess())
        ])
      } else {
        yield all([
          put(LtlTripActions.dashboardResponseLTLTotalTripsCard({ statusCode: response.status })),
          put(DashboardActions.dashboardGenericFailure())
        ])
      }
    } catch (_) {
      yield all([
        put(LtlTripActions.dashboardResponseLTLTotalTripsCard({})),
        put(DashboardActions.dashboardGenericFailure())
      ])
    }
  }

  export function * getLTLMissedStopsCard ({ siteId, initialDate, finalDate }) {
    try {
      const response = yield call(LTLTripApi.getLTLMissedStopsCard, siteId, { 'startDate': initialDate, 'endDate': finalDate })
      if (response.ok) {
        yield all([
          put(LtlTripActions.dashboardResponseLTLMissedStopsCard({ ...response.data, statusCode: response.status })),
          put(DashboardActions.dashboardGenericSuccess())
        ])
      } else {
        yield all([
          put(LtlTripActions.dashboardResponseLTLMissedStopsCard({ statusCode: response.status })),
          put(DashboardActions.dashboardGenericFailure())
        ])
      }
    } catch (_) {
      yield all([
        put(LtlTripActions.dashboardResponseLTLMissedStopsCard({})),
        put(DashboardActions.dashboardGenericFailure())
      ])
    }
  }

  export function * getLTLDwellTimeCard ({ siteId, initialDate, finalDate}) {
    try{
      const response = yield call(LTLTripApi.getLTLDwellTimeCard, siteId, { 'startDate' : initialDate , 'endDate' : finalDate })
      if(response.ok){
        yield all([
           put(LtlTripActions.dashboardResponseLTLDwellTimeCard({...response.data, statusCode : response.status})),
           put(DashboardActions.dashboardGenericSuccess())
        ])
      } else {
         yield all([
           put(LtlTripActions.dashboardResponseLTLDwellTimeCard({statusCode: response.status})),
           put(DashboardActions.dashboardGenericFailure())
         ])
      }
    } catch(_){
      yield all([
         put(LtlTripActions.dashboardResponseLTLDwellTimeCard({})),
         put(DashboardActions.dashboardGenericFailure())
      ])
    }
    }

  export function * ltlSaga () {
    yield all([
      takeLatest(LtlTripTypes.DASHBOARD_REQUEST_LTL_TOTAL_TRIPS_CARD, getLTLTotalTripsCard),
      takeLatest(LtlTripTypes.DASHBOARD_REQUEST_LTL_MISSED_STOPS_CARD, getLTLMissedStopsCard),//,
      takeLatest(LtlTripTypes.DASHBOARD_REQUEST_LTL_DWELL_TIME_CARD, getLTLDwellTimeCard)
    ])
  }