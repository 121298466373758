import apisauce from 'apisauce'
import config from 'Config/AppConfig'

const create = (baseURL = config.api.connectedaidc) => {
  const api = apisauce.create({
    baseURL,
    timeout: 60000,
    headers: config.api.apiKey ? {
      'apikey': config.api.apiKey
    }
      : undefined
  })

  const getParamsToLoginWithEmail = domain => api.get(`/ssoconfig?clientName=${domain}`)
  
  const setConsentSSOAuth = (consent, authType, openToken) => {
    return api.put('/user/consent',  
      { authType, consent }, 
      { headers: {'Authorization': `Bearer ${openToken}`}
    })
  }

  return {
    getParamsToLoginWithEmail,
    setConsentSSOAuth,
    axiosInstance: api.axiosInstance
  }
}

export default {
  create
}
