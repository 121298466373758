import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import React from 'react'

import {
  TaggingActionCreators,
  TaggingActionTypes
} from './actions'
import { toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from '../../Themes/ScufStyledComponents'
import { TAG_MANAGEMENT_TOAST_CONTAINER } from './constants'
import { selectedTags } from 'Components/Tags/tags.selector'
import { TagsApi } from 'Services'
import {
  get,
  flatMap
} from 'lodash'

export const mapTagsToAssets = ({ tagging }) => tagging.getIn(['assets'], [])

export function createRequestForValuesOnSelectedKeys (state) {
  return selectedTags(state)
    .map(({ key, value }) => {
      return call(TagsApi.getValues, key, 'device', value)
    })
}

export function * getTagsForDevices (api, { assets }) {
  const response = yield call(api.getTagsForGivenAssets, assets)
  if (response.ok) {
    yield put(TaggingActionCreators.devicesTagsSuccess(response.data))
  } else {
    toast(<ErrorToast message='An error occurred while retrieving tags for devices' />, { containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
    yield put(TaggingActionCreators.devicesTagsFailure(response.problem))
  }
}

export function * createTagForAllAssets (api, { tag }) {
  const devices = yield select(mapTagsToAssets)
  const action = 'add'
  const assetsMerged = devices.map(asset => {
    return {
      ...asset,
      ...tag
    }
  })
  const response = yield call(api.createTagsForAssets, assetsMerged, action)
  if (response.ok) {
    yield put(TaggingActionCreators.devicesTagsRequest(devices))
    toast(<SuccessToast message={`Successfully created a tag for ${devices.length} devices`} />, { containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
  } else {
    toast(<ErrorToast message='An error occurred while creating a tag' />, { containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
  }
}

export function * getValuesForSelectedTags () {
  const request = yield select(createRequestForValuesOnSelectedKeys)
  const response = yield all(request)
  const tagsValues = response
    .filter(res => res.ok)
    .map(({ data, config }) => {
      const key = get(config, 'params.key', '')
      const value = get(config, 'params.value', '')
      const tag = `${key}:${value}`
      return {
        tag,
        values: get(data, 'results', []).map(val => ({ text: val, value: val }))
      }
    })
  yield put(TaggingActionCreators.tagsAllKeysSuccess(tagsValues))
}

export function * updateTagsRequestSaga (api, { tags }) {
  const devices = yield select(mapTagsToAssets)
  const action = 'update'
  const newTags = tags.map((tag, index) => {
    const device = devices.find(dev => dev.AssetId === tag.assetId)
    return ({
      assetUniqueIdentifier: device.AssetUniqueIdentifier,
      siteName: device.siteName,
      ...tag,
      value: tag.newValue
    })
  }
  )
  const response = yield call(api.createTagsForAssets, newTags, action)
  if (response.ok) {
    const devices = yield select(
      ({ tagging }) => tagging.getIn(['assets'], [])
    )
    toast(<SuccessToast message='Successfully updated tags' />, { containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
    yield put(TaggingActionCreators.devicesTagsRequest(devices))
  } else {
    toast(<ErrorToast message='An error occurred while updating a tag' />, { containerId: TAG_MANAGEMENT_TOAST_CONTAINER })
  }
}

export function * taggingSaga (api) {
  yield all([
    takeLatest(TaggingActionTypes.DEVICES_TAGS_REQUEST, getTagsForDevices, api),
    takeLatest(TaggingActionTypes.DEVICES_CREATE_TAG_REQUEST, createTagForAllAssets, api),
    takeLatest(TaggingActionTypes.TAGS_ALL_KEYS_REQUEST, getValuesForSelectedTags),
    takeLatest(TaggingActionTypes.UPDATE_TAGS_REQUEST, updateTagsRequestSaga, api)
  ])
}
