import { CenterCard } from './qr-section.styles'
import { QrModalWrapper } from './components'

import QrSection from './qr-section.connect'

export {
  CenterCard,
  QrModalWrapper
}

export default QrSection
