import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0.5rem;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  & * {
    color: ${({ disabled, theme }) => disabled ? '#A0A0A0' : theme.h1color};
  }
  &:hover * {
    color: ${({ disabled, theme }) => disabled ? '#A0A0A0' : theme.h1color};
  }
`
export const ButtonLabel = styled.span`
  padding-left: 0.25rem;
`
