import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import ScannerFirmwareModal from './scanner-firmware-modal'
import ScannerFirmwareModalSelector from './scanner-firmware-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    ScannerFirmwareModalSelector,
    mapDispatchToProps
  )
)(ScannerFirmwareModal)
