import React from 'react';
import { call, put, select, all } from 'redux-saga/effects';
import moment from 'moment';
import DashboardActions from '../Redux/DashboardRedux';
import { parseAlertsResponse } from './AlertSagas';
import { substract } from '../Utils/MomentHelpers';
import { percentageMonthlyGrowth } from '../Utils/MathHelper';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents';

// WIP
export function* getGenericBatteryHealth(api, { reqType, extraParam, paramDefaultValue }) {
  const paramName = extraParam ? `battery${reqType}${extraParam}${paramDefaultValue}` : `battery${reqType}`;
  try {
    const siteId = yield select(({ dashboard }) => dashboard.getIn(['siteId'], ''));

    const params = {
      startDate: '2019-01-01',
      endDate: moment().format('YYYY-MM-DD').toString() + ' 23:59:59',
    };
    if (extraParam) {
      params[extraParam] = yield select(({ dashboard }) => dashboard.getIn([extraParam], paramDefaultValue));
    }

    const response = yield call(api.getBatteryHealth, reqType, siteId, params);

    if (response.ok) {
      yield put(DashboardActions.dashboardGenericSuccess(paramName, { ...response.data, statusCode: response.status }));
    } else {
      yield put(DashboardActions.dashboardGenericFailure(paramName));
    }
  } catch (e) {
    yield put(DashboardActions.dashboardGenericFailure(paramName));
  }
}

export function* getAvgBatteryAgeCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getAvgBatteryAgeCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryAgeCard()),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryAgeCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseAvgBatteryAgeCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDeviceUsageLevel(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getDeviceUsageLevel, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseDeviceUsageLevel({ ...response.data, statusCode: 200 })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDeviceUsageLevel({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeviceUsageLevel({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrinterUsageLevelByMeters(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrinterUsageLevelByMeters, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'Printer',
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponsePrinterUsageLevelByMeters({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrinterUsageLevelByMeters({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrinterUsageLevelByMeters({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getMobilePrinterUsageLevelByMeters(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getMobilePrinterUsageLevelByMeters, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'Mobile Printer',
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseMobilePrinterUsageLevelByMeters({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseMobilePrinterUsageLevelByMeters({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseMobilePrinterUsageLevelByMeters({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getAvgBatteryCycleCard(api, { siteId, initialDate, finalDate }) {
  try {
    const cycleCount = yield select(({ dashboard }) => dashboard.getIn(['cycleCountAbove'], 500));
    const response = yield call(api.getAvgBatteryCycleCountCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      cycleCount,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryCycleCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryCycleCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseAvgBatteryCycleCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getScanPullsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getScanPullsCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseScanPullsCard({
            ...response.data,
            statusCode: response.status,
            monthlyGrowth: 0,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseScanPullsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseScanPullsCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintersOutOfPaperCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintersOutOfPaperCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponsePrintersOutOfPaperCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintersOutOfPaperCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrintersOutOfPaperCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintersOutOfRibbonCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintersOutOfRibbonCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponsePrintersOutOfRibbonCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintersOutOfRibbonCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrintersOutOfRibbonCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getEventsForPrintersCard(api, { siteId, initialDate, finalDate }) {
  try {
    const OutOfPaper = 'Printer.Media.Status';
    const OutOfRibbon = 'Printer.Ribbon.Status';
    const response = yield call(api.getDashboardEventsByName, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      'events[0].key': OutOfRibbon,
      'events[0].values': 'OUT OF RIBBON,OUT',
      'events[1].key': OutOfPaper,
      'events[1].values': 'OUT OF MEDIA,OUT',
    });
    if (response.ok) {
      const data = response.data || {};
      const printersOutOfPaperCard = get(
        data,
        ['events', OutOfPaper.charAt(0).toLowerCase() + OutOfPaper.slice(1)],
        {}
      );
      const printersOutOfRibbonCard = get(
        data,
        ['events', OutOfRibbon.charAt(0).toLowerCase() + OutOfRibbon.slice(1)],
        {}
      );
      yield all([
        put(
          DashboardActions.dashboardResponseEventsForPrinters({
            printersOutOfPaperCard,
            printersOutOfRibbonCard,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseEventsForPrinters({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseEventsForPrinters({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPtcDevicesUseCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPtcDevicesUseCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponsePtcDevicesUse({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePtcDevicesUse({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePtcDevicesUse({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getHardRebootsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getHardRebootsCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseHardRebootsCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseHardRebootsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseHardRebootsCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatterySwapsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getBatterySwapsCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseBatterySwaps({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatterySwaps({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatterySwaps({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getAlerts(api, { siteId, pageNumber, pageSize, status }) {
  try {
    const response = yield call(api.getAlerts, siteId, { pageNumber, pageSize, status });
    if (response.ok) {
      const data = parseAlertsResponse(response.data || []);
      yield all([put(DashboardActions.dashboardSuccessAlerts(data)), put(DashboardActions.dashboardGenericSuccess())]);
    } else {
      yield all([put(DashboardActions.dashboardFailureAlerts()), put(DashboardActions.dashboardGenericFailure())]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardFailureAlerts()), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getPrinterUsageLevelByLabels(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrinterUsageLevelByLabels, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'Printer',
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponsePrinterUsageLevelByLabels({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrinterUsageLevelByLabels({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrinterUsageLevelByLabels({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getMobilePrinterUsageLevelByLabels(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getMobilePrinterUsageLevelByLabels, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'Mobile Printer',
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseMobilePrinterUsageLevelByLabels({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseMobilePrinterUsageLevelByLabels({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseMobilePrinterUsageLevelByLabels({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getAgingBatteriesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const agingMonths = yield select(({ dashboard }) => dashboard.getIn(['agingMonths'], 30));
    const response = yield call(api.getAgingBatteriesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      agingMonths,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseAgingBatteriesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseAgingBatteriesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseAgingBatteriesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatteriesLowHealthCard(api, { siteId, initialDate, finalDate }) {
  try {
    const batteryPercentage = yield select(({ dashboard }) => dashboard.getIn(['batteryPercentage'], 30));
    const response = yield call(api.getBatteriesLowHealthCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteryPercentage,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseBatteriesLowHealthCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatteriesLowHealthCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatteriesLowHealthCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatteriesLowHealthEventsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getBatteriesLowHealthEventsCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseBatteriesLowHealthEventsCard({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatteriesLowHealthEventsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatteriesLowHealthEventsCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getScanVolumeCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getScanVolumeCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseScanVolume({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseScanVolume({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseScanVolume({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getScanVolumeByDeviceCard(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const response = yield call(api.getScanVolumeCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      serialNumber,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseScanVolumeByDevice({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseScanVolumeByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseScanVolumeByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDistributionOfDipsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getDistributionOfDipsCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseDistributionOfDipsCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDistributionOfDipsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDistributionOfDipsCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTotalDevicesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      group: 'type',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTotalDevicesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTotalBatteriesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalBatteries, siteId, {
      startDate: initialDate,
      endDate: finalDate,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseTotalBatteriesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTotalBatteriesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTotalBatteriesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTotalDevicesWithoutBatteriesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalSmartBatteries, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteries: false,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseTotalDevicesWithoutBatteriesCard({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesWithoutBatteriesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTotalDevicesWithoutBatteriesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTotalDevicesWithBatteriesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalSmartBatteries, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteries: true,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseTotalDevicesWithBatteriesCard({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesWithBatteriesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTotalDevicesWithBatteriesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getFixedDevicesOfflineCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getFixedDevicesOfflineCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseFixedDevicesOfflineCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseFixedDevicesOfflineCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseFixedDevicesOfflineCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatteriesCycleAboveCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getBatteryStatsCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteryPercentage: 75,
      bottomBatteryPercentage: 50,
      cycleCount: 300,
      cycleCountTop: 500,
      agingMonths: 24,
      agingMonthsTop: 30,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseBatteriesCycleAboveCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatteriesCycleAboveCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatteriesCycleAboveCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatteriesHealthBelowCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getBatteryStatsCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteryPercentage: 50,
      cycleCount: 500,
      agingMonths: 30,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseBatteriesHealthBelowCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatteriesHealthBelowCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatteriesHealthBelowCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getBatteriesHealthBetweenCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getBatteriesHealthBetweenCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      batteryPercentage: 75,
      bottomBatteryPercentage: 50,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseBatteriesHealthBetweenCard({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseBatteriesHealthBetweenCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseBatteriesHealthBetweenCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTypesOfDevicesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      group: 'type',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseTypesOfDevicesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTypesOfDevicesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTypesOfDevicesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTypesOfModelsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getTotalDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      group: 'model',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseTypesOfModelsCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTypesOfModelsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseTypesOfModelsCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getFirmwareCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getFirmwareCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'printer',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseFirmwareCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseFirmwareCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseFirmwareCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getScannerFirmwareCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getScannerFirmwareCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'scanner',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseScannerFirmwareCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseScannerFirmwareCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseScannerFirmwareCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getOsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getOsCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType: 'mobilecomputer',
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseOsCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseOsCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseOsCard({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getTotalDevicesStatus(api, { siteId, initialDate, finalDate, hours }) {
  try {
    const response = yield call(api.getTotalDevicesStatus, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      hours,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesStatus({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseTotalDevicesStatus({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield put(DashboardActions.dashboardGenericFailure());
  }
}

export function* getDroppedDevicesCard(dashboardService, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(dashboardService.getDroppedDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
    });

    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardDroppedDevicesCardSuccess(response.data, response.status)),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardDroppedDevicesCardFailure(response.status)),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardDroppedDevicesCardFailure(null)),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getAvgBatteryChargeCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getAvgBatteryChargeCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryChargeCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseAvgBatteryChargeCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseAvgBatteryChargeCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintVolumeCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintVolumeCard, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponsePrintVolumeCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintVolumeCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrintVolumeCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintersFaultyDots(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintersFaultyDots, siteId, { startDate: initialDate, endDate: finalDate });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponsePrintersFaultyDots({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintersFaultyDots({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardRequestPrintersFaultyDots({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getHardUseDevicesCard(api, { siteId, initialDate, finalDate }) {
  try {
    const minHours = yield select(({ dashboard }) => dashboard.getIn(['hoursOfUsePerDay'], 8));
    const response = yield call(api.getHardUseDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      minHours,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseHardUseDevicesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseHardUseDevicesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseHardUseDevicesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintVolumeByDeviceCard(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintVolumeCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      serialNumber,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponsePrintVolumeByDevice({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintVolumeByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrintVolumeByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getHardRebootsByDevice(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const response = yield call(api.getHardRebootsCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      serialNumber,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseHardRebootsByDevice({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseHardRebootsByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseHardRebootsByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDeepSleepsByDevice(api, { id, siteId }) {
  try {
    const startDate = moment().subtract(7, 'days').format("YYYY-MM-DD")
    const endDate = moment().format("YYYY-MM-DD")
    const response = yield call(api.getDeviceDeepSleepAndBatteryVoltageList, id, startDate, endDate, siteId)
    if (response.ok) {
      const data = response.data.deepSleepPointsList;
      yield put(DashboardActions.dashboardResponseDeepSleepData({ ...data, statusCode: data.length > 0 ? response.status : 204 }))
    } else {
      yield put(DashboardActions.dashboardResponseDeepSleepData({ statusCode: response.status }))
    }
  } catch (e) {
    yield all([
      put(DashboardActions.dashboardResponseDeepSleepData({})),
      put(DashboardActions.dashboardGenericFailure())
    ])
  }
}

export function* getErrorsByDevice(api, { id, siteId }) {
  try {
    const startDate = moment().subtract(7, 'days').format("YYYY-MM-DD")
    const endDate = moment().format("YYYY-MM-DD")
    const response = yield call(api.getDeviceErrorList, id, startDate, endDate, siteId)
    if (response.ok) {
      const data = response.data.errorPointsList;
      yield put(DashboardActions.dashboardResponseErrorsByDevice({ ...response.data, statusCode: data.length > 0 ? response.status : 204 }))
    } else {
      yield put(DashboardActions.dashboardResponseErrorsByDevice({ statusCode: response.status }))
    }
  } catch (e) {
    yield all([
      put(DashboardActions.dashboardResponseErrorsByDevice({})),
      put(DashboardActions.dashboardGenericFailure())
    ])
  }
}

export function* getInventoryCyclesByDevice(api, { id }) {
  try {
    const startDate = moment().subtract(24, 'hours').format("YYYY-MM-DD")
    const endDate = moment().format("YYYY-MM-DD")
    const response = yield call(api.getDeviceInventoryCycleList, id, startDate, endDate)
    if (response.ok) {
      const data = response.data.inventoryCyclePointsList;
      yield put(DashboardActions.dashboardResponseInventoryCyclesByDevice({ ...response.data, statusCode: data.length > 0 ? response.status : 204 }))
    } else {
      yield put(DashboardActions.dashboardResponseInventoryCyclesByDevice({ statusCode: response.status }))
    }
  } catch (e) {
    yield all([
      put(DashboardActions.dashboardResponseInventoryCyclesByDevice({})),
      put(DashboardActions.dashboardGenericFailure())
    ])
  }
}

export function* getSecurityPatch(api, { siteId, initialDate, finalDate, deviceType }) {
  try {
    const response = yield call(api.getSecurityPatch, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      deviceType,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseSecurityPatch({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseSecurityPatch({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseSecurityPatch({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getUsagePerDayByDevice(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const startOfSixtyDaysAgo = substract(initialDate, 30, 'days', 'l');
    const endDateOfSixtyDaysAgo = substract(initialDate, 1, 'days', 'l');
    const [responsePreviousMonth, response] = yield all([
      call(api.getHardUseDevicesCard, siteId, {
        startDate: startOfSixtyDaysAgo,
        endDate: endDateOfSixtyDaysAgo,
        serialNumber,
      }),
      call(api.getHardUseDevicesCard, siteId, { startDate: initialDate, endDate: finalDate, serialNumber }),
    ]);
    if (response.ok && responsePreviousMonth.ok) {
      const monthlyGrowth = percentageMonthlyGrowth([
        get(responsePreviousMonth, 'data.averageHours', 0),
        get(response, 'data.averageHours', 0),
      ]);
      yield all([
        put(
          DashboardActions.dashboardResponseUsagePerDayByDevice({
            ...response.data,
            statusCode: response.status,
            monthlyGrowth,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseUsagePerDayByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseUsagePerDayByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getPrintedMetersByDevice(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const response = yield call(api.getPrintedMetersByDevice, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      serialNumber,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponsePrintedMetersByDevice({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponsePrintedMetersByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponsePrintedMetersByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDroppedDevicesByDevice(api, { siteId, serialNumber, initialDate, finalDate }) {
  try {
    const response = yield call(api.getDroppedDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      serialNumber,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseDroppedDevicesByDevice({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDroppedDevicesByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDroppedDevicesByDevice({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getNewBatteriesLastMonthCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getNewBatteriesLastMonthCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseNewBatteriesLastMonthCard({ ...response.data, statusCode: response.status })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseNewBatteriesLastMonthCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseNewBatteriesLastMonthCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDeviceAppData(api, { id }) {
  const today = moment().format('YYYY-MM-DD').toString() + ' 23:59:59';
  try {
    //  const newSDate = moment('2021-02-19T00:00:00.000').utc().format('YYYY-MM-DDTHH')
    //  const newEDate = moment('2021-02-20T00:00:00.000').utc().format('YYYY-MM-DDTHH')
    const startDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DDTHH').toString();
    const endDate = moment().utc().add(1, 'hours').format('YYYY-MM-DDTHH').toString();
    const response = yield call(api.getApplicationReports, id, startDate, endDate);
    if (response.ok) {
      const data =
        response.data &&
        response.data.appPointDataModel.map((app) => ({
          ...JSON.parse(app.applicationDataJson),
          packagename: app.applicationName,
        }));
      yield put(
        DashboardActions.dashboardResponseAppData({ ...data, statusCode: data.length > 0 ? response.status : 204 })
      );
    } else {
      yield put(DashboardActions.dashboardResponseAppData({ statusCode: response.status }));
    }
  } catch (e) {
    yield all([put(DashboardActions.dashboardResponseAppData({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getDeviceAppDataCustom(api, { id, startDate, endDate }) {
  try {
    if (!startDate && !endDate) {
      yield put(DashboardActions.dashboardResponseAppDataCustom({}));
      return;
    }
    const response = yield call(api.getApplicationReportsCustom, id, startDate, endDate);
    if (response.ok) {
      const data =
        response.data &&
        response.data.appPointDataModel.map((app) => ({
          ...JSON.parse(app.applicationDataJson),
          packagename: app.applicationName,
        }));
      yield put(DashboardActions.dashboardResponseAppDataCustom({ ...data, statusCode: response.status }));
    } else {
      yield put(DashboardActions.dashboardResponseAppDataCustom({ statusCode: response.status }));
    }
  } catch (e) {
    yield all([
      put(DashboardActions.dashboardResponseAppDataCustom({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getTotalBatteriesDetailsNextPage(
  api,
  { siteId, params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort } }
) {
  const response = yield call(api.getTotalBatteriesDetails, siteId, {
    startDate,
    endDate,
    pageNumber,
    pageSize,
    query,
    sites,
    includeChildren,
    sort,
    directionSort,
  });
  if (response.ok) {
    yield all([
      put(
        DashboardActions.dashboardResponseTotalBatteriesDetailsNextPage({
          query,
          data: [...response.data.bateriesList],
          total: response.data.totalBatteries,
          sites,
          includeChildren,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
    ]);
  } else {
    yield all([
      put(
        DashboardActions.dashboardResponseTotalBatteriesDetailsNextPage({
          query,
          data: [],
          total: 0,
          sites,
          includeChildren,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
    ]);
  }
}

export function* getTotalBatteriesDetails(
  api,
  { siteId, params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort } }
) {
  const response = yield call(api.getTotalBatteriesDetails, siteId, {
    startDate,
    endDate,
    pageNumber,
    pageSize,
    query,
    sites,
    includeChildren,
    sort,
    directionSort,
  });
  if (response.ok) {
    yield all([
      put(
        DashboardActions.dashboardResponseTotalBatteriesDetails({
          query,
          data: [...response.data.bateriesList],
          total: response.data.totalBatteries,
          sites,
          includeChildren,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
    ]);
  } else {
    yield all([
      put(
        DashboardActions.dashboardResponseTotalBatteriesDetails({
          query,
          data: [],
          total: 0,
          sites,
          includeChildren,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
    ]);
  }
}

export function* getNotReportingBatteriesNextPage(
  api,
  { siteId, params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort } }
) {
  const notReporting = yield select(({ dashboard }) => dashboard.getIn(['notReportingDays'], 30));
  const today = moment().format('YYYY-MM-DD').toString() + ' 23:59:59';
  const lastUpdated = substract(today, notReporting, 'days', 'l');
  const response = yield call(api.getNotReportingBatteriesDetails, siteId, {
    startDate,
    endDate,
    pageNumber,
    pageSize,
    query,
    sites,
    includeChildren,
    sort,
    directionSort,
    lastUpdated,
  });
  if (response.ok) {
    yield all([
      put(
        DashboardActions.dashboardResponseNotReportingBatteriesNextPage({
          query,
          data: [...response.data.bateriesList],
          total: response.data.totalBatteries,
          sites,
          includeChildren,
          statusCode: response.status,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
    ]);
  } else {
    yield all([
      put(
        DashboardActions.dashboardResponseNotReportingBatteriesNextPage({
          query,
          data: [],
          total: 0,
          sites,
          includeChildren,
          statusCode: response.status,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
    ]);
  }
}

export function* getNotReportingBatteries(
  api,
  { siteId, params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort } }
) {
  const notReporting = yield select(({ dashboard }) => dashboard.getIn(['notReportingDays'], 30));
  const today = moment().format('YYYY-MM-DD').toString() + ' 23:59:59';
  const lastUpdated = substract(today, notReporting, 'days', 'l');
  const response = yield call(api.getNotReportingBatteriesDetails, siteId, {
    startDate,
    endDate,
    pageNumber,
    pageSize,
    query,
    sites,
    includeChildren,
    sort,
    directionSort,
    lastUpdated,
  });
  if (response.ok) {
    yield all([
      put(
        DashboardActions.dashboardResponseNotReportingBatteries({
          query,
          data: [...response.data.bateriesList],
          total: response.data.totalBatteries,
          sites,
          includeChildren,
          statusCode: response.status,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
    ]);
  } else {
    yield all([
      put(
        DashboardActions.dashboardResponseNotReportingBatteries({
          query,
          data: [],
          total: 0,
          sites,
          includeChildren,
          statusCode: response.status,
        })
      ),
      put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
    ]);
  }
}

export function* getNotReportingBatteriesCard(
  api,
  { siteId, params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort } }
) {
  const notReporting = yield select(({ dashboard }) => dashboard.getIn(['notReportingDays'], 30));
  const today = moment().format('YYYY-MM-DD').toString() + ' 23:59:59';
  const lastUpdated = substract(today, notReporting, 'days', 'l');
  const response = yield call(api.getNotReportingBatteriesDetails, siteId, {
    startDate,
    endDate,
    pageNumber,
    pageSize,
    query,
    sites,
    includeChildren,
    sort,
    directionSort,
    lastUpdated,
  });
  if (response.ok) {
    yield put(
      DashboardActions.dashboardResponseNotReportingBatteriesCard({ ...response.data, statusCode: response.status })
    );
  } else {
    yield put(DashboardActions.dashboardResponseNotReportingBatteriesCard({ statusCode: response.status }));
  }
}

export function* deleteBatteries(api, { organizationId, batteryList }) {
  try {
    const response = yield call(api.deleteBatteries, batteryList, organizationId);
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseDeleteBatteries(batteryList)),
        put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
        put(DashboardActions.dashboardGenericSuccess()),
        call(toast, <SuccessToast message="Batteries deleted successfully" />),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDeleteBatteries([])),
        put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
        put(DashboardActions.dashboardGenericFailure()),
        call(toast, <ErrorToast message="An error ocurred while deleting batteries" />),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeleteBatteries([])),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
      put(DashboardActions.dashboardGenericFailure()),
      call(toast, <ErrorToast message="An error ocurred while deleting batteries" />),
    ]);
  }
}

export function* deleteBatteriesNotReporting(api, { organizationId, batteryList }) {
  try {
    const response = yield call(api.deleteBatteries, batteryList, organizationId);
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseDeleteBatteriesNotReporting(batteryList)),
        put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
        put(DashboardActions.dashboardGenericSuccess()),
        call(toast, <SuccessToast message="Batteries deleted successfully" />),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDeleteBatteriesNotReporting([])),
        put(DashboardActions.dashboardUpdateDrilldown('notReportingBatteriesCard')),
        put(DashboardActions.dashboardGenericFailure()),
        call(toast, <ErrorToast message="An error ocurred while deleting batteries" />),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeleteBatteries([])),
      put(DashboardActions.dashboardUpdateDrilldown('totalBatteriesCard')),
      put(DashboardActions.dashboardGenericFailure()),
      call(toast, <ErrorToast message="An error ocurred while deleting batteries" />),
    ]);
  }
}

export function* addBatteries(api, { organizationId, batteryList }) {
  try {
    const response = yield call(api.addBatteries, batteryList, organizationId);
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseAddBatteries(batteryList)),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
        put(DashboardActions.dashboardGenericSuccess()),
        call(toast, <SuccessToast message="Batteries added successfully" />),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseAddBatteries(batteryList)),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
        put(DashboardActions.dashboardGenericFailure()),
        call(toast, <ErrorToast message="An error ocurred while adding batteries" />),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseAddBatteries([])),
      put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      put(DashboardActions.dashboardGenericFailure()),
      call(toast, <ErrorToast message="An error ocurred while adding batteries" />),
    ]);
  }
}

export function* getDeletedBatteries(
  api,
  {
    organizationId,
    params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort },
  }
) {
  try {
    const response = yield call(api.getDeletedBatteriesCard, organizationId, {
      startDate,
      endDate,
      pageNumber,
      pageSize,
      query,
      sites,
      includeChildren,
      sort,
      directionSort,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseDeletedBatteries({
            query,
            data: [...response.data.bateriesList],
            total: response.data.totalBatteries,
            sites,
            includeChildren,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      ]);
    } else {
      yield all([
        put(
          DashboardActions.dashboardResponseDeletedBatteries({
            query,
            data: [],
            total: 0,
            sites,
            includeChildren,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeletedBatteries({})),
      put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDeletedBatteriesNextPage(
  api,
  {
    organizationId,
    params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort },
  }
) {
  try {
    const response = yield call(api.getDeletedBatteriesCard, organizationId, {
      startDate,
      endDate,
      pageNumber,
      pageSize,
      query,
      sites,
      includeChildren,
      sort,
      directionSort,
    });
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseDeletedBatteriesNextPage({
            query,
            data: [...response.data.bateriesList],
            total: response.data.totalBatteries,
            sites,
            includeChildren,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      ]);
    } else {
      yield all([
        put(
          DashboardActions.dashboardResponseDeletedBatteriesNextPage({
            query,
            data: [],
            total: 0,
            sites,
            includeChildren,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeletedBatteries({})),
      put(DashboardActions.dashboardUpdateDrilldown('deletedBatteriesCard')),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getDeletedBatteriesCard(
  api,
  {
    organizationId,
    params: { startDate, endDate, pageNumber, pageSize, query, sites, includeChildren, sort, directionSort },
  }
) {
  try {
    const response = yield call(api.getDeletedBatteriesCard, organizationId, {
      startDate,
      endDate,
      pageNumber,
      pageSize,
      query,
      sites,
      includeChildren,
      sort,
      directionSort,
    });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseDeletedBatteriesCard({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseDeletedBatteriesCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseDeletedBatteriesCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getApplicationUsageTime(api, { siteId, params }) {
  try {
    const payload = `aggregate?pageNumber=${params.page}&filter={"summary.siteId":"${siteId}"}&pageSize=100&fields=network,usage&groupBy=name&sort={"usage.total":"desc"}&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseApplicationUsageCard({
            ...response.data,
            statusCode: response.status,
            days: params.days,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationUsageCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationUsageCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getNetworkIO(api, { siteId, params }) {
  try {
    const payload = `aggregate?pageNumber=${params.page}&filter={"summary.siteId":"${siteId}"}&pageSize=100&fields=network,usage&groupBy=name&sort={"network.total":"desc"}&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseNetworkIOCard({
            ...response.data,
            statusCode: response.status,
            days: params.days,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseNetworkIOCard({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseNetworkIOCard({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getApplicationUsageDetails(api, { name, siteId, params }) {
  try {
    const payload = `aggregate?filter={"summary.siteId":"${siteId}","name":"${name}"}&pageNumber=${params.page}&pageSize=100&fields=network,usage,summary&groupBy=summary.sourceId&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseApplicationUsageDetails({
            ...response.data,
            statusCode: response.status,
            appName: name,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationUsageDetails({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationUsageDetails({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getApplicationUsageDetailsNextPage(api, { name, siteId, params }) {
  try {
    const payload = `aggregate?pageNumber=${params.page}&filter={"summary.siteId":"${siteId}","name":"${name}"}&pageSize=100&fields=network,usage,summary&groupBy=summary.sourceId&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseApplicationUsageDetailsNextPage({
            ...response.data,
            statusCode: response.status,
            appName: name,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationUsageDetailsNextPage({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationUsageDetailsNextPage({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getApplicationUsageNextPage(api, { siteId, params }) {
  try {
    const payload = `aggregate?pageNumber=${params.page}&filter={"summary.siteId":"${siteId}"}&pageSize=100&fields=network,usage&groupBy=name&sort={"usage.total":"desc"}&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(
          DashboardActions.dashboardResponseApplicationUsageCardNextPage({
            ...response.data,
            statusCode: response.status,
          })
        ),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationUsageCardNextPage({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationUsageCardNextPage({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getNetworkIONextPage(api, { siteId, params }) {
  try {
    const payload = `aggregate?pageNumber=${params.page}&filter={"summary.siteId":"${siteId}"}&pageSize=100&fields=network,usage,summary&groupBy=name&sort={"network.total":"desc"}&startTime=${params.startDate}&endTime=${params.endDate}`;
    const response = yield call(api.getApplicationUsageTime, payload);
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseNetworkIONextPage({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseNetworkIONextPage({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseNetworkIONextPage({})),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getHardUseDevicesDetails(api, { siteId, initialDate, finalDate, details }) {
  try {
    const minHours = yield select(({ dashboard }) => dashboard.getIn(['hoursOfUsePerDay'], 8));
    const response = yield call(api.getHardUseDevicesCard, siteId, {
      startDate: initialDate,
      endDate: finalDate,
      minHours,
      devDetail: details,
    });
    if (response.ok) {
      yield all([put(DashboardActions.dashboardResponseHardUseDevicesDetails({ ...response.data }))]);
    } else {
      yield all([put(DashboardActions.dashboardGenericResponseHardUseDevicesDetails())]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardGenericResponseHardUseDevicesDetails())]);
  }
}

export function* getLostAndFoundCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getLostAndFoundCard, siteId, { startDate: initialDate, endDate: finalDate, includeChildren: true });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseLostAndFound({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseLostAndFound({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseLostAndFound({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getLostAndFoundDetails(api, { siteId, params: { startDate, endDate, includeChildren, query, sort, directionSort, sites } }) {
  try {
    const response = yield call(api.getLostAndFoundCard, siteId, { startDate, endDate, includeChildren, query, sort, directionSort, sites });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseLostAndFoundDetails({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseLostAndFoundDetails({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseLostAndFoundDetails({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getApplicationDisruptionsCard(api, { siteId, initialDate, finalDate }) {
  try {
    const response = yield call(api.getApplicationDisruptionsCard, siteId, { startDate: initialDate, endDate: finalDate, includeChildren: true });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptions({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptions({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseApplicationDisruptions({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getApplicationDisruptionsDetails(api, { siteId, params: { startDate, endDate, includeChildren, query, sites, pageNumber, pageSize, sort, directionSort } }) {
  try {
    const response = yield call(api.getApplicationDisruptionsDetails, siteId, { startDate, endDate, includeChildren, query, sites, pageNumber, pageSize, sort, directionSort })
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsDetails({
          data: response.data.disruptionsDetailsList,
          total: response.data.total,
          statusCode: response.status,
        })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsDetails({ statusCode: 400 })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationDisruptionsDetails({ statusCode: 500 })),
      put(DashboardActions.dashboardGenericFailure())
    ]);
  }
}

export function* getApplicationDisruptionsDetailsNextPage(api, { siteId, params: { startDate, endDate, includeChildren, query, sites, pageNumber, pageSize, sort, directionSort } }) {
  try {
    const response = yield call(api.getApplicationDisruptionsDetails, siteId, { startDate, endDate, includeChildren, query, sites, pageNumber, pageSize, sort, directionSort })
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsDetailsNextPage({
          data: response.data.disruptionsDetailsList,
          total: response.data.total,
          statusCode: response.status,
        })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    }
    else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsDetails({ statusCode: 500 })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationDisruptionsDetails({})),
      put(DashboardActions.dashboardGenericFailure())
    ]);
  }
}

export function* getApplicationDisruptionsCardByDevice(api, { siteId, initialDate, finalDate, serialNumber}) {
  try {
    const response = yield call(api.getApplicationDisruptionsCardByDevice, siteId, { startDate: initialDate, endDate: finalDate, serialNumber });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByDevice({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByDevice({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseApplicationDisruptionsByDevice({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getApplicationDisruptionsByAppCard(api, { siteId, initialDate, finalDate}) {
  try {
    const response = yield call(api.getApplicationDisruptionsByAppCard, siteId, { startDate: initialDate, endDate: finalDate, includeChildren: true });
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByApp({ ...response.data, statusCode: response.status })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    } else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByApp({ statusCode: response.status })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([put(DashboardActions.dashboardResponseApplicationDisruptionsByApp({})), put(DashboardActions.dashboardGenericFailure())]);
  }
}

export function* getApplicationDisruptionsByAppDetails(api, { siteId, params: { startDate, endDate, includeChildren, sort, directionSort, applicationName, pageNumber, pageSize, query, sites } }) {
  const response = yield call(api.getApplicationDisruptionsByAppDetails, siteId, { startDate, endDate, includeChildren, sort, directionSort, applicationName, pageNumber, pageSize, query, sites })
  if (response.ok) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationDisruptionsByAppDetails({
        data: response.data.disruptionsDetailsList,
        total: response.data.total,
        statusCode: response.status,
      })),
      put(DashboardActions.dashboardGenericSuccess()),
    ]);
  } else {
    yield all([
      put(DashboardActions.dashboardResponseApplicationDisruptionsByAppDetails({ statusCode: 400 })),
      put(DashboardActions.dashboardGenericFailure()),
    ]);
  }
}

export function* getApplicationDisruptionsByAppDetailsNextPage(api, { siteId, params: { startDate, endDate, includeChildren, sort, directionSort, applicationName, pageNumber, pageSize, query, sites } }) {
  try {
    const response = yield call(api.getApplicationDisruptionsByAppDetails, siteId, { startDate, endDate, includeChildren, sort, directionSort, applicationName, pageNumber, pageSize, query, sites })
    if (response.ok) {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByAppDetailsNextPage({
          data: response.data.disruptionsDetailsList,
          total: response.data.total,
          statusCode: response.status,
        })),
        put(DashboardActions.dashboardGenericSuccess()),
      ]);
    }
    else {
      yield all([
        put(DashboardActions.dashboardResponseApplicationDisruptionsByAppDetails({ statusCode: 500 })),
        put(DashboardActions.dashboardGenericFailure()),
      ]);
    }
  } catch (_) {
    yield all([
      put(DashboardActions.dashboardResponseApplicationDisruptionsByAppDetails({})),
      put(DashboardActions.dashboardGenericFailure())
    ]);
  }
}
