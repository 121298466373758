const create = (api) => {
    const devicePackageReport = (params) => {
        return api.get(`v2/deviceutilization/devicesPackages`, params )        
    }

    const supportedDevices = () => {
        return api.get(`v2/devices/supportedtypes`)
    }
    
    const installedPackages = (orgUnitId) => {
        return api.get(`v2/deviceutilization/packages/` + orgUnitId)
    }

    return {
        devicePackageReport,
        installedPackages,
        supportedDevices
    }
}

export default {
    create
}