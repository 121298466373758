import { LocationTimelineActions } from './location-display.reducer.js'
import React from 'react'
import { toast } from 'react-toastify'
import { all, call, put } from 'redux-saga/effects'
import { ErrorToast } from 'Themes/ScufStyledComponents'

export function * getDeviceLocationHistoryList (api, {deviceId, params: {pageNumber, pageSize}}) {
  const response = yield call(api.getDeviceLocationHistory, deviceId, {fields: 'gnss.latlong', pageNumber, pageSize })
  if (response.ok) {
    if(response.data?.pointList.length < pageSize || response.data?.pointList.length === undefined ) {
      yield put (LocationTimelineActions.initialEnableMorePoints(false))
    } else{
      yield put (LocationTimelineActions.initialEnableMorePoints(true))
    }
    yield put (LocationTimelineActions.initialTotalPoints(response.data?.total || 0))
    yield put(LocationTimelineActions.initialPointsSuccess(response.data?.pointList || []))
  } else {
    yield all([
      put(LocationTimelineActions.initialPointsFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}

export function * getDeviceLocationHistoryListNextPage (api, {deviceId, params: {pageNumber, pageSize}}) {
  const response = yield call(api.getDeviceLocationHistory, deviceId, {fields: 'gnss.latlong', pageNumber, pageSize })
  if (response.ok) {
    if((pageSize*response.data?.pointList.length) === response.data.total || response.data.pointList.length < 5) {
      yield put(LocationTimelineActions.initialEnableMorePoints(false))
    } else{
      yield put(LocationTimelineActions.initialEnableMorePoints(true))
    }
    yield put(LocationTimelineActions.initialPointsNextPageSuccess(response.data.pointList || []))
  } else {
    yield all([
      put(LocationTimelineActions.initialPointsNextPageFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}
