import { useEffect, Fragment, useMemo } from 'react';
import SubscriptionAgreementContent from './Components/SubscriptionAgreementContent'
import { defaultFlags } from 'Utils/launch-darkly-flags'

export const SubscriptionAgreement = ({ accepted = false, loading = false, waitValidation = false, children }) => {
  if(waitValidation != false || loading != false){
    return <Fragment />
  } else if (accepted){
    return children
  } else{
    return <SubscriptionAgreementContent />
  }
}

export default ({
  flags = defaultFlags,
  getSLAValidation = () => { },
  loading = false,
  accepted = false,
  permissions = {},
  endSLAValidation = () => { },
  children,
  waitValidation = false
}) => {

  const attestation = permissions["sla:attestation"] ? permissions["sla:attestation"] : undefined

  useEffect(() => {
    if(flags.slaVerification){
      if (attestation && waitValidation) {
        getSLAValidation()
      }
    }

  }, [attestation != undefined])


  useEffect(() => {
    if(flags.slaVerification){
      if (attestation == false && loading == false && waitValidation) {
        endSLAValidation()
      }
    }

  }, [loading])

  if(!flags.slaVerification){
    return children
  } else if (attestation){
    if(waitValidation != false || loading != false){
      return null
    } else {
      return <SubscriptionAgreement accepted={accepted} loading={loading} waitValidation={waitValidation}>
      {children}
      </SubscriptionAgreement>
    }
  } else {
    return children
  }
};
