import { schema } from 'normalizr'

const GENERIC_ROLE_ID = '000000000000000000000000'

export const genericRoleSchema = new schema.Entity('generic', {}, {
  idAttribute: 'guid'
})

export const organizationSchema = new schema.Entity('organizations', {}, {
  processStrategy: (organization, role) => {
    return [role.guid]
  },
  mergeStrategy: (org1, org2) => [...org1, ...org2]
})

export const customRoleSchema = new schema.Entity('custom', {
  organization: organizationSchema
}, {
  idAttribute: 'guid',
  processStrategy: role => ({
    ...role,
    organization: {
      id: role.organizationId
    }
  })
})

export const rolesSchema = new schema.Array(
  {
    generic: genericRoleSchema,
    custom: customRoleSchema
  },
  (input, parent, key) => input.organizationId === undefined || input.organizationId === GENERIC_ROLE_ID
    ? 'generic'
    : 'custom'
)
