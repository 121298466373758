const create = (api) => {
  const saveEmailPreferences = (params) => api.post('/alerts/settings', { ...params });
  const editEmailPreferences = (params) => api.put('/alerts/settings', { ...params });
  const patchEmailPreferences = (id, mask, fields) => api.put(`/alerts/settings/${id}?mask=${mask}`, fields);
  const deleteEmailPreferences = (userId, names) => api.delete(`/alerts/settings/${userId}`, null, { data: names });
  const getEmailPreferences = (siteId) => api.get(`/alerts/settings`, { siteId });
  const getAlertsPreferences = () => api.get(`/alerts/settings/critical`);
  const saveAlertPreferences = (alertId, enabled) =>
    api.put('/alerts/settings/critical/single', { ruleId: alertId, enabled });
  const disableAlertsNotifications = (enabled) => api.put('/alerts/settings/critical/global', { enabled });
  const getInstantAlertsPreferences = (orgId) => api.get(`/alerts/settings/organization`, { organizationId: orgId });
  const saveInstantAlertPreferences = (alertId, enabled, notificationEmail, orgId) =>
    api.put('/alerts/settings/organization/single', {
      ruleId: alertId,
      enabled,
      notificationEmail,
      organizationId: orgId,
    });
  const saveMultipleInstantAlertPreferences = (alerts) => api.put('/alerts/settings/organization/multiple', alerts);
  const disableInstantAlertsNotifications = (enabled, orgId, listSites) =>
    api.put('/alerts/settings/organization/global', { enabled, organizationId: orgId, listSites });
  const getEmailPreference = (id) => api.get(`/alerts/settings/${id}`);

  return {
    saveEmailPreferences,
    deleteEmailPreferences,
    getEmailPreferences,
    editEmailPreferences,
    getAlertsPreferences,
    saveAlertPreferences,
    disableAlertsNotifications,
    getInstantAlertsPreferences,
    saveInstantAlertPreferences,
    saveMultipleInstantAlertPreferences,
    disableInstantAlertsNotifications,
    getEmailPreference,
    patchEmailPreferences,
  };
};

export default {
  create,
};
