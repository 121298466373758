import { translate } from 'react-translate'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import ExpirationModal from './expiration-modal'
import ExpirationModalSelector from './expiration-modal.selector'

import ModalActions from 'Redux/ModalRedux'
import LoginActions from 'Redux/LoginRedux'

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(ModalActions.modalClose()),
  setModalProps: (p) => dispatch(ModalActions.modalSetProps(p)),
  logOut: () => dispatch(LoginActions.loginLogoutRequest()),
  renewSession: () => dispatch(LoginActions.loginRefreshTokenRequest())
})

export default pipe(
  connect(
    ExpirationModalSelector,
    mapDispatchToProps
  ),
  withLDConsumer(),
  translate('ExpirationModal')
)(ExpirationModal)
