import { Icon, InputLabel } from '@scuf/common'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import React, { PureComponent } from 'react'
import { translate } from 'react-translate'
import { Field } from 'redux-form'
import Immutable from 'seamless-immutable'

import { adminRoles, orgAdmin } from 'Utils/constants'

import FormInput from '../Components/FormComponents/FormInput'
import FormSelect from '../Components/FormComponents/FormSelect'
import { LocationApi } from '../Services'
import { getPosition } from '../Utils/GeolocationHelper'
import RolesSelect from './UserForm/components/select-roles'
import { isEmail, isLettersOnly, isMax18, isMax25, isMax6, isMax64, isNumbersOnly, isRequired, isTelephone, noSpecialCharacters } from './Validators'
import {
  FormRow,
  FormInputContainer,
  Subtitle,
} from '../Themes/ScufStyledComponents'

// export const fieldsValidator = fields => fields && fields.length > 0 ? fields : [{}]
export const renderEmails = ({ fields, forceEnable, t }) => {
  // fields.length === 0 && fields.push({})
  fields.length === 0 && fields.push({})
  return (
    <div style={{ flex: 1 }}>
      {fields.map((email, index) => (
        <FormInputContainer key={`emailAddressFull${index}`}>
          {index === 0
            ? <FormRow alignItems='center'>
              <InputLabel
                label={t('FormSection_Email')}
                htmlFor='emailAddressFull'
              />
              <Icon
                description={t('FormSection_AddEmail')}
                className='Icon'
                size='large'
                name='add'
                root='building'
                onClick={() => {
                  fields.push({})
                  forceEnable()
                }}
              />
            </FormRow>
            : null
          }
          <FormRow style={{ alignSelf: 'auto' }}>
            <Field
              placeholder={t('FormSection_Email')}
              name={`${email}.emailAddressFull`}
              id='emailAddressFull'
              component={FormInput}
              type='email'
              validate={[isRequired, isEmail, isMax64]}
              required
            />
            {index !== 0
              ? <Icon
                description={t('FormSection_RemoveEmail')}
                className='Icon'
                size='large'
                name='delete'
                root='building'
                onClick={() => {
                  fields.remove(index)
                  forceEnable()
                }}
              />
              : null
            }
          </FormRow>
        </FormInputContainer>
      ))}
    </div>
  )
}
export const renderEmailsT = translate('FormSection')(renderEmails)

renderEmails.propTypes = {
  t: PropTypes.func
}

renderEmails.defaultProps = {
  t: () => null
}

export const renderPhones = ({ fields, forceEnable, t }) => {
  fields.length === 0 && fields.push({})

  return (
    <div style={{ flex: 1, zIndex: 0 }}>
      {fields.map((phone, index) => (
        <FormRow flex={0.5} key={`phone${index}`}>
          <FormInputContainer>
            {
              index === 0
                ? <FormRow alignItems='center'>
                  <InputLabel
                    label={t('FormSection_TelephoneNum')}
                    htmlFor='telephoneNumberFull'
                  />
                  <Icon
                    description={t('FormSection_AddPhoneNum')}
                    className='Icon'
                    size='large'
                    name='add'
                    root='building'
                    onClick={() => {
                      fields.push({})
                      forceEnable()
                    }}
                  />
                </FormRow>
                : null
            }
            <Field
              placeholder={t('FormSection_TelephoneNum')}
              name={`${phone}.telephoneNumberFull`}
              id='telephoneNumberFull'
              component={FormInput}
              type='text'
              validate={[isRequired, isTelephone, isMax18]} // Something is resetting value to undefined, isNumbersOnly cant process
              required
            />
          </FormInputContainer>
          <FormInputContainer style={{ alignSelf: 'flex-end' }}>
            {index === 0
              ? <InputLabel
                indicator='optional'
                label={t('FormSection_Extension')}
                htmlFor='extensionNumber'
              />
              : null
            }
            <FormRow>
              <Field
                placeholder={t('FormSection_Extension')}
                name={`${phone}.extensionNumber`}
                id='extensionNumber'
                component={FormInput}
                validate={[isTelephone, isMax6]}
                type='text'
              />
              {index !== 0
                ? <Icon
                  description={t('FormSection_RemovePhoneNum')}
                  className='Icon'
                  size='large'
                  name='delete'
                  root='building'
                  onClick={() => {
                    fields.remove(index)
                    forceEnable()
                  }}
                />
                : null
              }
            </FormRow>
          </FormInputContainer>
        </FormRow>
      ))}
    </div>
  )
}
export const renderPhonesT = translate('FormSection')(renderPhones)

renderPhones.propTypes = {
  t: PropTypes.func
}

renderPhones.defaultProps = {
  t: () => null
}

class renderAddresses extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this._getAddress = this._getAddress.bind(this)
  }

  componentDidMount () {
    this.props.fields.length === 0 && this.props.fields.push({})
  }

  _getAddress (pos) {
    LocationApi.getAddressByCoords(pos)
      .then(({ data }) => {
        this.props.fields.pop()
        const { address } = data

        const newAddress = {
          address1: `${address.road} ${address.building}`,
          address2: `${address.road}, ${address.county}, P.C. ${address.postcode}`,
          countryFullName: address.country,
          stateFullName: address.state,
          cityFullName: address.city
        }

        this.props.fields.push(newAddress)
      })
      .catch(e => console.error(e))
      .finally(() => this.setState({ loading: false }))
  }

  render () {
    const { fields, t } = this.props
    const { loading } = this.state
    return (
      <div>
        <FormRow>
          <Subtitle padding='0.5em'>{t('FormSection_Addresses')}</Subtitle>
          <Icon description={t('FormSection_Autofill')}
            className='Icon'
            size='large'
            name={loading ? 'refresh' : 'location'}
            loading={loading}
            onClick={() => getPosition((pos) => this.setState({ loading: true }, this._getAddress(pos)))}
          />
        </FormRow>
        {fields.map((address, index) => (
          <div key={`address${index}`} style={{ flex: 1 }}>
            <FormRow>
              <FormInputContainer>
                <InputLabel
                  label={t('FormSection_AddressLine1')}
                  htmlFor='address1'
                />
                <Field
                  placeholder={t('FormSection_AddressLine1')}
                  name={`${address}.address1`}
                  id='address1'
                  component={FormInput}
                  type='text'
                  validate={[isRequired, noSpecialCharacters, isMax64]}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <InputLabel
                  indicator='optional'
                  label={t('FormSection_AddressLine2')}
                  htmlFor='address2'
                />
                <Field
                  placeholder={t('FormSection_AddressLine2')}
                  name={`${address}.address2`}
                  id='address2'
                  component={FormInput}
                  type='text'
                  validate={[noSpecialCharacters, isMax64]}
                  required
                />
              </FormInputContainer>
            </FormRow>
            <FormRow>
              <FormInputContainer>
                <InputLabel
                  label={t('FormSection_Country')}
                  htmlFor='countryFullName'
                />
                <Field
                  placeholder={t('FormSection_Country')}
                  name={`${address}.countryFullName`}
                  id='countryFullName'
                  component={FormInput}
                  type='text'
                  validate={[isRequired, isLettersOnly, isMax25]}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <InputLabel
                  label={t('FormSection_State')}
                  htmlFor='stateFullName'
                />
                <Field
                  placeholder={t('FormSection_State')}
                  name={`${address}.stateFullName`}
                  id='stateFullName'
                  component={FormInput}
                  type='text'
                  validate={[isRequired, isLettersOnly, isMax25]}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <InputLabel
                  label={t('FormSection_City')}
                  htmlFor='cityFullName'
                />
                <Field
                  placeholder={t('FormSection_City')}
                  name={`${address}.cityFullName`}
                  id='cityFullName'
                  component={FormInput}
                  type='text'
                  validate={[isRequired, isLettersOnly, isMax25]}
                  required
                />
              </FormInputContainer>
            </FormRow>
          </div>
        ))}
      </div>
    )
  }
}
export const renderAddressesT = translate('FormSection')(renderAddresses)

renderAddresses.propTypes = {
  t: PropTypes.func
}

renderAddresses.defaultProps = {
  t: () => null
}

export class renderOrganizationRoles extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedOrg: [],
      selectedIndex: 0
    }
  }

  static getDerivedStateFromProps (props) {
    const selectedOrg = props.formData && props.formData.values && props.formData.values.organization
      ? props.formData.values.organization.organizationalUnits.map(item => (item.parent))
      : []
    return { selectedOrg }
  }

  componentDidMount () {
    this.props.fields.length === 0 && this.props.fields.push({})
  }

  formartRoles (roles) {
    return (
      <ul>
        {roles.map(role => <li key={role.name}> <p><b>{role.name}</b>: {role.description} </p></li>)}
      </ul>
    )
  }

  orgs () {
    const { sites } = this.props
    return !isEmpty(sites)
      ? Object.keys(sites).sort((a, b) =>
        (a.title && a.title.localeCompare(b.title))).map(org =>
        ({ value: sites[org].id, text: sites[org].title })
      )
      : []
  }

  sitesByOrg (index) {
    const { sites } = this.props
    const { selectedOrg } = this.state
    return sites[selectedOrg[index]]
      ? sites[selectedOrg[index]]['sites'].map(site =>
        ({ value: site.id, text: site.title })
      )
      : []
  }

  roles () {
    const { uiRoles } = this.props
    return uiRoles
      ? uiRoles.map(role =>
        ({ value: role.guid, text: role.name })
      )
      : []
  }

  getRowDisabled (orgRow) {
    const { currentUserRoles, isGlobalUser } = this.props
    const isOrgAdmin =  (currentUserRoles === undefined || currentUserRoles === null) ? false : currentUserRoles.find(siteRole => siteRole.guid === orgAdmin) != null

    if (!isGlobalUser && adminRoles.includes(orgRow?.role) && !isOrgAdmin) {
      return true
    }
    return false
  }

  render () {
    const { fields, forceEnable, t, hasEmail, formData, isById, editById } = this.props
    return (
      <div style={{ flex: 1 }}>
        <FormInputContainer style={{ paddingBottom: 0 }}>
          { (hasEmail || isById || editById) &&
            <FormRow alignItems='center'>
              <FormRow flex={0.5} alignItems='center'>
                <InputLabel
                  label={t('FormSection_Organization')}
                  htmlFor='name'
                />
                { hasEmail &&
                <Icon
                  description={t('FormSection_AddOrganizationUnit')}
                  className='Icon'
                  size='large'
                  name='add'
                  root='building'
                  onClick={() => fields.push({})}
                /> }
              </FormRow>
              <div style={{ flex: 1, paddingLeft: '1em' }}>
                <InputLabel
                  label={t('FormSection_Site')}
                  htmlFor='site0'
                />
              </div>
              { hasEmail && <FormRow flex={0.5} alignItems='center' style={{ paddingLeft: '1em' }}>
                <Icon
                  description={t('FormSection_RolesDescription')}
                  className='Icon'
                  size='large'
                  name='badge-info'
                />
                <InputLabel
                  label={t('FormSection_Role')}
                  htmlFor='role0'
                />
              </FormRow> }
            </FormRow>
          }
        </FormInputContainer>
        {hasEmail && fields.map((org, index) => {
          const rowDisabled = this.getRowDisabled(formData?.values?.organization.organizationalUnits[index])
          return <FormRow key={`org${index}`} alignItems='center'>
            <FormInputContainer flex={0.5}>
              <Field
                fluid
                className='break-word'
                name={`${org}.parent`}
                placeholder={t('FormSection_SelectOrg')}
                id='parent'
                search={true}
                component={FormSelect}
                validate={[isRequired]}
                required
                options={this.orgs()}
                onChange={() => this.setState({ selectedIndex: index },
                  forceEnable()
                )}
                disabled={rowDisabled}
              />
            </FormInputContainer>
            <FormInputContainer>
              <Field
                fluid
                className='break-word'
                name={`${org}.id`}
                placeholder={t('FormSection_SelectSite')}
                id='id'
                search={true}
                component={FormSelect}
                options={this.sitesByOrg(index)}
                onChange={forceEnable}
                disabled={rowDisabled}
              />
            </FormInputContainer>
            <FormInputContainer flex={0.5}>
              <FormRow>
                <FormInputContainer padding='0'>
                  <Field
                    fluid
                    className='break-word'
                    name={`${org}.role`}
                    placeholder={t('FormSection_SelectRole')}
                    id='role'
                    search={true}
                    component={RolesSelect}
                    validate={[isRequired]}
                    required
                    onChange={forceEnable}
                    index={index}
                    initialValues={formData?.values}
                    disabled={rowDisabled}
                  />
                </FormInputContainer>
                {rowDisabled
                  ? <Icon
                    description={t('FormSection_CantEditRole')}
                    className='Icon'
                    size='large'
                    name='locked'
                    color='#D0D0D0'
                    root='building'
                  />
                  : null
                }
                {fields.length > 1 && !rowDisabled
                  ? <Icon
                    description={t('FormSection_RemoveOrganizationRole')}
                    className='Icon'
                    size='large'
                    name='delete'
                    root='building'
                    onClick={() => {
                      fields.remove(index)
                      forceEnable()
                    }}
                  />
                  : null
                }
              </FormRow>
            </FormInputContainer>
          </FormRow>
        })}

{ (isById || editById)  && fields.map((org, index) => {
          const rowDisabled = this.getRowDisabled(formData?.values?.organization.organizationalUnits[index])
          return <FormRow key={`org${index}`} alignItems='center'>
           <FormInputContainer flex={0.5}>
              <Field
                fluid
                className='break-word'
                name={`${org}.parent`}
                placeholder={t('FormSection_SelectOrg')}
                id='parent'
                search={true}
                component={FormSelect}
                validate={[isRequired]}
                required
                options={this.orgs()}
                onChange={() => this.setState({ selectedIndex: index },
                  forceEnable()
                )}
                disabled={rowDisabled}
              />
            </FormInputContainer>
            <FormInputContainer>
              <Field
                fluid
                className='break-word'
                name={`${org}.id`}
                placeholder={t('FormSection_SelectSite')}
                id='id'
                search={true}
                component={FormSelect}
                options={this.sitesByOrg(index)}
                onChange={forceEnable}
                disabled={rowDisabled}
              />
            </FormInputContainer>

          </FormRow>
        })}
      </div>
    )
  }
}

renderOrganizationRoles.propTypes = {
  t: PropTypes.func
}

renderOrganizationRoles.defaultProps = {
  t: () => null
}

export const renderOrganizationRolesT = translate('FormSection')(renderOrganizationRoles)

export class renderHaasDeals extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedIndex: 0
    }
  }

  componentDidMount () {
    this.props.fields.length === 0 && this.props.fields.push({})
  }

  render () {
    const { fields, forceEnable, t } = this.props
    return (
      <div style={{ flex: 1 }}>
        <FormInputContainer style={{ paddingBottom: 0 }}>
          <FormRow alignItems='center'>
            <FormRow flex={0.5} alignItems='center'>
              <InputLabel
                //label={t('FormSection_Organization')}
                label='Minimum Deal Size'
                htmlFor='minSize'
              />
              <Icon
                description={t('FormSection_AddOrganizationUnit')}
                className='Icon'
                size='large'
                name='add'
                root='building'
                onClick={() => fields.push({})}
              />
            </FormRow>

            <div style={{ flex: 1, paddingLeft: '1em' }}>
              <InputLabel
                //label={t('FormSection_Site')}
                label='Maximum Deal size'
                htmlFor='maxSize'
              />
            </div>
            <div style={{ flex: 0.5, paddingLeft: '1em' }}>
              <InputLabel
                //label={t('FormSection_Role')}
                label='LT Swaps'
                htmlFor='ltSwaps'
              />
            </div>
            <div style={{ flex: 0.5, paddingLeft: '1em' }}>
              <InputLabel
                //label={t('FormSection_Role')}
                label='COF'
                htmlFor='cof'
              />
            </div>
            <div style={{ flex: 0.5, paddingLeft: '1em' }}>
              <InputLabel
                //label={t('FormSection_Role')}
                label='Implicit Rate'
                htmlFor='implicitRate'
              />
            </div>
          </FormRow>
        </FormInputContainer>
        {fields.map((deal, index) => (
          <FormRow key={`deal${index}`} alignItems='center'>
            <FormInputContainer flex={0.5} style={{ maxWidth: '58%' }} >
              <Field
                style={{ marginBottom: '1.5rem' }}
                name={`${deal}.minSize`}
                //placeholder={t('FormSection_SelectOrg')}
                placeholder='Minimum Deal Size'
                id='minSize'
                component={FormInput}
                validate={[isRequired, isNumbersOnly]}
                required
              ></Field>
            </FormInputContainer>

            <FormInputContainer flex={0.5} style={{ maxWidth: '58%' }} >
              <Field
                style={{ marginBottom: '1.5rem' }}
                name={`${deal}.maxSize`}
                //placeholder={t('FormSection_SelectOrg')}
                placeholder='Maximum Deal Size'
                id='maxSize'
                component={FormInput}
                validate={[isRequired, isNumbersOnly]}
                required
              ></Field>
            </FormInputContainer>

            <FormInputContainer flex={0.5} style={{ maxWidth: '58%' }} >
              <Field
                style={{ marginBottom: '1.5rem' }}
                name={`${deal}.ltSwaps`}
                //placeholder={t('FormSection_SelectOrg')}
                placeholder='LT swaps'
                id='ltSwaps'
                component={FormInput}
                validate={[isRequired, isNumbersOnly]}
                required
              ></Field>
            </FormInputContainer>

            <FormInputContainer flex={0.5} style={{ maxWidth: '58%' }} >
              <Field
                style={{ marginBottom: '1.5rem' }}
                name={`${deal}.cof`}
                //placeholder={t('FormSection_SelectOrg')}
                placeholder='COF'
                id='cof'
                component={FormInput}
                validate={[isRequired, isNumbersOnly]}
                required
              ></Field>
            </FormInputContainer>

            <FormInputContainer flex={0.5} style={{ maxWidth: '58%' }} >
              <Field
                style={{ marginBottom: '1.5rem' }}
                name={`${deal}.implicitRate`}
                //placeholder={t('FormSection_SelectOrg')}
                placeholder='Implicit Rate'
                id='implicitRate'
                component={FormInput}
                validate={[isRequired, isNumbersOnly]}
                required
              ></Field>
            </FormInputContainer>
            {fields.length > 1
              ? <Icon
                description={t('FormSection_RemoveOrganizationRole')}
                className='Icon'
                size='large'
                name='delete'
                root='building'
                onClick={() => {
                  fields.remove(index)
                  //forceEnable()
                }}
              />
              : null
            }
          </FormRow>
        ))}
      </div>
    )
  }
}
renderHaasDeals.propTypes = {
  deals: PropTypes.array,
  t: PropTypes.func
}

renderHaasDeals.defaultProps = {
  deals: [
    {
      minSize: 50000.0,
      maxSize: 149999.99,
      ltSwaps: 2.9,
      cof: 3.41,
      implicitRate: 7.5
    },
  ],

  t: () => null
}

export const renderHaasDealsT = translate('FormSection')(renderHaasDeals)
