import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { submit } from 'redux-form'

import DeviceActions from '../../Redux/DeviceRedux'
import ModalActions from '../../Redux/ModalRedux'
import SiteManagerActions from '../../Redux/SiteManagerRedux'
import QrBase from './qr-section'

const mapStateToProps = state => ({
  sites: state.siteManager.rootSitesById,
  options: state.devices.qrData
})

const mapDispatchToProps = dispatch => ({
  getSites: () => dispatch(SiteManagerActions.siteManagerRootSitesRequest()),
  setOptions: (options) => dispatch(DeviceActions.deviceSetQrData(options)),
  setModalProps: (p) => dispatch(ModalActions.modalSetProps(p)),
  generateQr: () => dispatch(submit('qrForm')),
  resetQr: handleClose => {
    dispatch(DeviceActions.deviceQrReset())
    handleClose()
  }
})

export default pipe(
  translate('QRSection'),
  connect(mapStateToProps, mapDispatchToProps)
)(QrBase)
