import * as React from 'react'
import { useTranslate } from 'react-translate'

import CsvFileDrop from 'Components/CsvFileDrop'
import { NewEmployee } from '../../apis'
import { CSVHeaders } from './import-employees-hooks'

export interface FileUploaderProps {
  organizationId: string;
  onFileLoaded: (data: NewEmployee[]) => any;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  organizationId,
  onFileLoaded
}) => {
  const translate = useTranslate('AddEmployeesForm')

  return (
    <CsvFileDrop
      placeholder={translate('UploadPlaceholder')}
      acceptedTypes={['.csv', '.xlsx']}
      disabled={false}
      onLoadFileSuccess={(data: FileData[]) => {
        onFileLoaded(processRawImportData(organizationId, data))
      }}
      onReset={() => {}}
      height='30vh'
    />
  )
}

interface FileData {
  [CSVHeaders.FirstName]?: string | object;
  [CSVHeaders.MiddleName]?: string | object;
  [CSVHeaders.LastName]?: string | object;
  [CSVHeaders.EmployeeId]?: string | object;
  [CSVHeaders.Email]?: string | object;
  [CSVHeaders.Site]?: string | object;
  [CSVHeaders.SiteId]?: string | object;
  [CSVHeaders.Role]?: string | object;
  [CSVHeaders.RoleId]?: string | object;
}

function processRawImportData (organizationId: string, data: FileData[]): NewEmployee[] {
  return data.map(r => {
    return {
      organizationId,
      firstName: typeof r[CSVHeaders.FirstName] === 'string' ? (r[CSVHeaders.FirstName] as string).trim() : '',
      middleName: typeof r[CSVHeaders.MiddleName] === 'string' ? (r[CSVHeaders.MiddleName] as string).trim() : '',
      lastName: typeof r[CSVHeaders.LastName] === 'string' ? (r[CSVHeaders.LastName] as string).trim() : '',
      employeeId: typeof r[CSVHeaders.EmployeeId] === 'string' ? (r[CSVHeaders.EmployeeId] as string).trim() : '',
      siteId: typeof r[CSVHeaders.Site] === 'string' ? (r[CSVHeaders.Site] as string).trim() : '',
      email: typeof r[CSVHeaders.Email] === 'string' ? (r[CSVHeaders.Email] as string).trim() : '',
      roleId: typeof r[CSVHeaders.Role] === 'string' ? (r[CSVHeaders.Role] as string).trim() : ''
    }
  })
}
