import styled from 'styled-components'
import { Select, Button} from '@scuf/common';


export const SelectorContainer = styled.div`
display: flex;
padding: 0.5rem 1rem;
justify-content: center;
align-content: center;
align-items: center;
background-color: ${props => props.theme.dashboardcard ? props.theme.dashboardcard : '#ffffff'};
margin: 0.5rem 0 1rem 0;
`


export const SiteSelector = styled(Select)`
width: 100% !important;
margin: 0rem 0.5rem;
z-index: 5 !important;
 & .ui.label {
  min-width: fit-content !important;
  display: flex;
  align-items: center;
  & i {
    margin-right: .25rem !important;
  }
}


& .ui.multiple.selection.dropdown{
  display: flex;
  flex-wrap: wrap;
}

& .visible.menu.transition{
  display: flex;
  flex-direction: column !important;
}
`

export const IconContainer = styled.div`
min-width: 40px;
min-height: 40px;
display: flex;
align-items: center;
justify-content: center;
&:hover {
  background: #f7f7f7;
}
`

export const StyledButton = styled(Button)`
  margin-bottom: 3rem !important;
`