import { createTransform } from 'redux-persist'
import * as R from 'ramda'
import Immutable from 'seamless-immutable'

// is this object already Immutable?
const isImmutable = R.has('asMutable')

// change this Immutable object into a JS object
const convertToJs = state => state.asMutable({ deep: true })

// optionally convert this object into a JS object if it is Immutable
const toMutable = R.when(isImmutable, convertToJs)

// convert this JS object into an Immutable object
const toImmutable = raw => Immutable(raw)

export default createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => toMutable(inboundState),
  // transform state being rehydrated
  (outboundState) => toImmutable(outboundState)
)
