import { put, call, takeLatest, all, delay } from 'redux-saga/effects'
import get from 'lodash/get'

import {
  PermissionsActions,
  PermissionsTypes
} from './actions'
import { LoginTypes } from 'Redux/LoginRedux'

export function * permissionsRequest (api, maxTryAttemps, { params }) {
  let retryAttemps = 0
  try {
    for (; retryAttemps < maxTryAttemps; retryAttemps++) {
      const response = yield call(api.getPermissions, params)
      if (response.ok) {
        const allPermissions = get(response, 'data.allPermissions', {})
        const permissionsPerSite = get(response, 'data.permissionsPerSite', {})
        yield put(PermissionsActions.permissionsSuccess(allPermissions, permissionsPerSite))
        return
      } else {
        yield delay(100)
      }
    }
  } catch {
    yield put(PermissionsActions.permissionsFailure([], retryAttemps))
  }
  yield put(PermissionsActions.permissionsFailure([], retryAttemps))
}

export function * requestPermissionsOnLogin () {
  yield put(PermissionsActions.permissionsRequest())
}

export function * permissionsSaga (api) {
  yield all([
    takeLatest(LoginTypes.LOGIN_SIGN_IN_SUCCESS, requestPermissionsOnLogin),
    takeLatest(PermissionsTypes.PERMISSIONS_REQUEST, permissionsRequest, api, 10)
  ])
}
