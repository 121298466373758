import { createSelector, createStructuredSelector } from 'reselect';
import { getTimeZonedHourFromUtcHour } from '../preferences-email/preferences-email.helpers';
import { FREQUENCIES, DAYS } from '../preferences-email/components/modal-form/AutomatedReportModal.helpers';
import { toPairs, assoc } from 'ramda';
import { selectedOrgId } from 'Selectors/LoaderSelector';

export const mapDayToText = assoc(
  0,
  'SUNDAY',
  toPairs(DAYS).reduce((obj, [key, val]) => assoc(val, key, obj), {})
);

export const reports = ({ preferences }) => preferences.scheduledReports;

export const frequencies = ['Daily', 'Weekly', 'Monthly'];

export const sitesReducer = ({ siteManager }) => siteManager.getIn(['sites'], {});

/**
 * Map the sites by key to its organizational unit name
 */
export const mapSiteIdToSiteName = createSelector(sitesReducer, (sites) =>
  Object.keys(sites).reduce((prev, siteId) => {
    const siteName = sites.getIn([siteId, 'organizationalUnitIdentifier', 'organizationalUnitName'], '');

    prev[siteId] = siteName;
    return prev;
  }, {})
);

/**
 * Get a list with the summary of all the saved reports to be showed
 *  in the autommates reports table
 * @param reports saved or loaded reports
 * @param mapSites map<string, string> map sites key to site name
 */
export const scheduledReports = createSelector([reports, mapSiteIdToSiteName], (_reports, _mapSites) => {
  return _reports.map((report) => ({
    name: report.name,
    id: report.id,
    sites: report.orgUnitIds
      .map((siteId) => (_mapSites[siteId] ? `(${_mapSites[siteId]})` : null))
      .filter((site) => !!site)
      .join(', '),
    programmed: frequencies[report.type - 1],
    days: report.type === FREQUENCIES.WEEKLY ? [report.daysOfWeek.map((day) => mapDayToText[day])] : [],
    hour: report.notificationHour,
    isAutomationReport: report.settings?.some((s) => s.isAutomationRule && s.enabled),
  }));
});

export const userId = ({ user }) => user.userData.id;

export const sites = createSelector(sitesReducer, (sites) =>
  Object.keys(sites).map((value) => ({
    text: sites.getIn([value, 'organizationalUnitIdentifier', 'organizationalUnitName'], ''),
    value,
  }))
);
export default createStructuredSelector({
  scheduledReports,
  userId,
  sites,
  selectedOrgId,
});

