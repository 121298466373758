import { call, put, select, all } from 'redux-saga/effects'
import SiteComparisonActions from '../Redux/SiteComparisonRedux'

export function * getSiteRankings (api, { params: { startDate, endDate, siteId, number, isTop, type, organizationId } }) {
  const response = yield call(api.siteRankingsApi, { startDate, endDate, siteId, number, isTop, type, organizationId })
  if (response.ok) {
    yield put(SiteComparisonActions.siteRankingsResponse(response.data, type))
  } else {
    yield put(SiteComparisonActions.siteRankingsResponse({}, type))
  }
}

export function * getSiteComparison (api, { params: { startDate, endDate, sites, type, orgId } }) {
  const response = yield call(api.getSiteComparisonApi, { startDate, endDate, sites, type, orgId })
  if (response.ok) {
    yield put(SiteComparisonActions.siteComparisonResponse(response.data, type))
  } else {
    yield put(SiteComparisonActions.siteComparisonResponse({}, type))
  }
}
