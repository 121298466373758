const create = (api) => {

  const getFileUrl = ({ fileId }) => api.get(`/v2/files/${fileId}/url`)


  return {
    getFileUrl
  }
}

export default {
  create
}
