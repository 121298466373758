import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PreferencesApi } from 'Services'
import { Container, Header, CategoryContainer } from './PreferencesInstantAlerts.styles'
import { Checkbox } from '@scuf/common'
import { StyledToastContainer } from '../../../toasts'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { toast } from 'react-toastify'
import Collapse from '../collapse'
import AlertSubcategory from '../alerts-subcategory'

const PreferencesAlerts = ({ isAlertsEnabled, categories, filteredAlerts, saveAlerts, selectedOrgId, selectedSites, t, toggleUpdate, theme, hasFeatureAccess }) => {
  const [enabled, setEnabled] = useState(isAlertsEnabled)
  /**
   * Call api to disable notifications
   * @param {bool} checked
   */
  const disableAlertsNotification = async (checked) => {

    const result = await PreferencesApi.disableInstantAlertsNotifications(checked, selectedOrgId, selectedSites)
    if (result.ok) {
      setEnabled(checked)
      toggleUpdate(checked)
    } else {
      toast(<ErrorToast message={t('ErrorSavingConfiguration')} />)
    }
  }

  return (
    <Container>
      <Header>
        <h4>{t('EmailNotification')}</h4>
        <Checkbox toggle={true} checked={enabled} onChange={checked => disableAlertsNotification(checked)} />
      </Header>
      <p>
	  {t("SelectEvents")}
      </p>
      <CategoryContainer>
        {categories.map(type => {
          return (
            <Collapse
              category={type}
              key={type}
              alertInfo={filteredAlerts[type]}
              saveAlerts={saveAlerts}
              tabType='enabled'
              selectedOrgId={selectedOrgId}
              t={t}
              enabledChecked={enabled}
              typeAlert={true}
              theme={theme}
              hasFeatureAccess={hasFeatureAccess}
            >
              <AlertSubcategory
                alertInfo={filteredAlerts[type]}
                saveAlerts={saveAlerts}
                selectedOrgId={selectedOrgId}
                tabType='enabled'
                t={t}
                enabledChecked={enabled}
                typeAlert={true}
                hasFeatureAccess={hasFeatureAccess}
              />
            </Collapse>
          )
        }
        )}
      </CategoryContainer>
      <StyledToastContainer
        hideProgressBar={true}
        closeOnClick={false}
        closeButton={false}
        newestOnTop={true}
        toastClassName='toast-notification-wrap'
      />
    </Container>
  )
}
PreferencesAlerts.displayName = 'PreferencesAlerts'
PreferencesAlerts.propTypes = {
  t: () => 1,
  userId: ''
}
PreferencesAlerts.defaultProps = {
  userId: PropTypes.string,
  t: PropTypes.func
}

export default PreferencesAlerts
