import { connect } from 'react-redux'

import { getPermissions } from './selectors'

// TODO: naming clean code pls
export default function permissionsConnect (siteId) {
  return connect(
    (...state) => {
      const permissions = getPermissions(siteId)(...state)
      return {
        permissions,
        permissionsCount: Object.keys(permissions).length
      }
    }
  )
}
