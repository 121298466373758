import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../../AssetManagementTable'
import { ModalWrap, ModalTitle } from './devices-status-modal.styles'

const columns = [
  { field: 'tag', translation: 'tag', render: null },
  { field: 'type', translation: 'type', render: null },
  { field: 'status', translation: 'status', render: null },
  { field: 'user', translation: 'user', render: null },
  { field: 'checkoutDate', translation: 'checkoutDate', render: null },
  { field: 'checkinDate', translation: 'checkinDate', render: null },
  { field: 'dueDate', translation: 'dueDate', render: null },
  { field: 'notes', translation: 'notes', render: null }
]

const DevicesBySiteModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>Devices by Status</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        columns={columns}
        data={data}
        disableRowClick
        fileName={t('DevicesBySite_title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default DevicesBySiteModal
