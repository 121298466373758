const pagination = {
  pageNumber: 1,
  pageSize: 1000
}

const create = (api) => {
  const getBatteries = (pageParams = pagination) => api.get(`/batteries`, pageParams)
  const getBatteryDetails = params => api.get(`/batteries/${params}/summary`)
  const getEvents = (id, pageParams = pagination) => api.get(`batteries/${id}/events`, pageParams)
  const getBatteryHealth = (site, params) => api.get(`dashboard/batteryhealth/${site}`, params)
  const getBatteryLevels = (site, date) => api.get(`dashboard/batterylevel/${site}`, {
    forDate: date
  })
  return {
    getBatteries,
    getBatteryDetails,
    getEvents,
    getBatteryLevels,
    getBatteryHealth,
    axiosInstance: api.axiosInstance
  }
}

export default {
  create
}
