import { createSelector, createStructuredSelector } from 'reselect'
import Immutable from 'seamless-immutable'

export const userFilters = ({ userSettings }) => userSettings.getIn(['filters'], Immutable({}))

export const intersectionFilters = createSelector(
  [userFilters],
  filters =>
    filters.getIn(['intersectionFilters'], Immutable({}))
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export default createStructuredSelector({
  intersectionFilters,
  theme
})
