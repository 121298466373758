import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StyledModal } from './modal.styles'
import { MODALS } from './modal.constants'
import { MODAL_TOAST_CONTAINER } from 'Utils/constants'
import { ThemeProvider } from 'styled-components'
import { StyledToastContainer } from '../toasts'

export default class Modal extends PureComponent {
  handleCloseModal = () => {
    const { modalClose, clearFilter } = this.props
    modalClose()
    clearFilter()
  }

  render () {
    const { isOpen, type, modalProps, theme } = this.props
    const CONTENT = typeof type === 'string' ? MODALS[type] : null
    return (
      CONTENT
        ? <ThemeProvider theme={theme}>
          <StyledModal
            {...modalProps}
            open={isOpen}
            onClose={() => {
              if (typeof modalProps?.onClose === 'function') {
                modalProps.onClose(this.handleCloseModal)
              } else {
                this.handleCloseModal()
              }
            }}
          >
            <CONTENT {...modalProps} />
            <StyledToastContainer
              autoClose={5000}
              closeButton={false}
              closeOnClick={false}
              containerId={MODAL_TOAST_CONTAINER}
              enableMultiContainer
              hideProgressBar={true}
              newestOnTop={true}
              toastClassName='toast-notification-wrap transition visible active'
            />
          </StyledModal>
        </ThemeProvider>
        : null
    )
  }
}

Modal.propTypes = {
  modalProps: PropTypes.object,
  theme: PropTypes.object,
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf([...Object.keys(MODALS || {}), ''])
}

Modal.defaultProps = {
  modalProps: {
    closeIcon: true,
    size: 'large',
    className: 'modal-height'
  },
  type: '',
  isOpen: false,
  theme: {}
}
