import { useTranslate as reactTranslate } from 'react-translate'
import Immutable from 'seamless-immutable'
import { get } from 'lodash'

export function useTranslate(key) {
  const commonT = reactTranslate('Common')
  const componentT = reactTranslate(key)

  const commonTranslate = (translateKey, params) => {
    const notFound = 'Common.' + translateKey

    if (commonT(translateKey, params) === notFound) {
      return componentT(translateKey, params)
    }
    return commonT(translateKey, params)
  }

  return (componentKey, params = {}) => commonTranslate(componentKey, params)
}

export function translate(displayName) {
  return function (Component) {
    return function ReactTranslateLegacy(props) {
      let t = useTranslate(displayName);
      return <Component {...props} t={t} />;
    };
  };
}


// do not use this
export function legacyTranslate(path) {
  return get(translationStrings, path, path)
}

export const translationStrings = Immutable({
  'en-US': {
    UpperCase_others: 'OTHERS',
    Not_available: 'Not Available',
    null: 'Not Available',
    Hours: 'hours',
    Months: 'months',
    Charging: 'Charging',
    Discharging: 'Discharging',
    Not_Charging: 'Not charging',
    Charge_Full: 'Charge Full',
    BATTERY_PLUGGED_AC: 'AC charger',
    BATTERY_PLUGGED_USB: 'USB charger',
    BATTERY_PLUGGED_WIRELESS: 'Wireless charger',
    BATTERY_PLUGGED_NO: 'Not charging',
    Meters: 'meters',
    Minutes: 'minutes',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    MinimumChargeCyclesNotMet: 'Minimum charge cycles not met',
  },
  'es-MX': {
    UpperCase_others: 'OTROS',
    Not_available: 'No disponible',
    null: 'No disponible',
    Hours: 'horas',
    Months: 'meses',
    Charging: 'Cargando',
    Discharging: 'No cargando',
    Not_Charging: 'Desconectado del cargador',
    Charge_Full: 'carga completa',
    BATTERY_PLUGGED_AC: 'Cargador AC',
    BATTERY_PLUGGED_USB: 'Cargador USB',
    BATTERY_PLUGGED_WIRELESS: 'Cargador inalámbrico',
    BATTERY_PLUGGED_NO: 'Desconectado del cargador',
    Meters: 'metros',
    Minutes: 'minutos',
    Connected: 'Conectado',
    Disconnected: 'Desconectado',
    Enabled: 'Habilitado',
    Disabled: 'Deshabilitado',
    MinimumChargeCyclesNotMet: 'No se han cumplido los ciclos mínimos de carga',
  },
  'de-DE': {
    UpperCase_others: 'ANDERE',
    Not_available: 'Nicht verfügbar',
    null: 'Nicht verfügbar',
    Hours: 'std',
    Months: 'monate',
    Charging: 'Aufladen',
    Not_Charging: 'lädt nicht',
    Charge_Full: 'Ladung voll',
    BATTERY_PLUGGED_AC: 'Ladegerät',
    BATTERY_PLUGGED_USB: 'USB-Ladegerät',
    BATTERY_PLUGGED_WIRELESS: 'Drahtloses Ladegerät',
    BATTERY_PLUGGED_NO: 'lädt nicht',
    Meters: 'meter',
    Minutes: 'minuten',
    Connected: 'Verbunden',
    Disconnected: 'Getrennt',
    Enabled: 'Aktiviert',
    Disabled: 'Deaktiviert',
    MinimumChargeCyclesNotMet: 'Mindestladezyklen nicht erfüllt',
  },
  'fr-FR': {
    UpperCase_others: 'AUTRES',
    Not_available: 'Non disponible',
    null: 'Non disponible',
    Hours: 'heures',
    Months: 'mois',
    Charging: 'Charge',
    Not_Charging: 'pas de charge',
    Charge_Full: 'charge complète',
    BATTERY_PLUGGED_AC: 'Chargeur ca',
    BATTERY_PLUGGED_USB: 'chargeur USB',
    BATTERY_PLUGGED_WIRELESS: 'Chargeur sans fil',
    BATTERY_PLUGGED_NO: 'pas de charge',
    Meters: 'mètres',
    Minutes: 'minutes',
    Connected: 'Connecté',
    Disconnected: 'Déconnecté',
    Enabled: 'Activé',
    Disabled: 'Désactivé',
    MinimumChargeCyclesNotMet: 'Cycles de charge minimum non atteints',
  },
  'zh-CN': {
    UpperCase_others: '其他',
    Not_available: '不可用',
    null: '不可用',
    Hours: '小時',
    Months: '月',
    Meters: '米',
    Minutes: '分鐘',
    Connected: '已連接',
    Disconnected: '斷開連接',
    Charge_Full: '充满电',
    Enabled: '启用',
    Disabled: '禁用',
    MinimumChargeCyclesNotMet: '未达到最低充电周期',
  },
  'pt-BR': {
    UpperCase_others: 'OUTROS',
    Not_available: 'Não disponível',
    null: 'Não disponível',
    Hours: 'horas',
    Months: 'meses',
    Charging: 'Carregando',
    Discharging: 'Descarregando',
    Not_Charging: 'Não está carregando',
    Charge_Full: 'carga completa',
    BATTERY_PLUGGED_AC: 'Carregador AC',
    BATTERY_PLUGGED_USB: 'carregador USB',
    BATTERY_PLUGGED_WIRELESS: 'Carregador sem fio',
    BATTERY_PLUGGED_NO: 'Não está carregando',
    Meters: 'metros',
    Minutes: 'minutos',
    Connected: 'Conectado',
    Disconnected: 'Desconectado',
    Enabled: 'Ativado',
    Disabled: 'Desativado',
    MinimumChargeCyclesNotMet: 'Ciclos de carga mínimos não atendidos',
  },
  'ja-JA': {
    UpperCase_others: 'その他',
    Not_available: '利用不可',
    null: '利用不可',
    Hours: '時間',
    Months: '月',
    Charging: '充電',
    Discharging: '放電',
    Not_Charging: '充電していません',
    Charge_Full: 'フル充電 ',
    BATTERY_PLUGGED_AC: '交流 充電器',
    BATTERY_PLUGGED_USB: 'USB充電器',
    BATTERY_PLUGGED_WIRELESS: 'ワイヤレス充電器',
    BATTERY_PLUGGED_NO: '充電していません',
    Meters: 'メートル',
    Minutes: '分',
    Connected: '接続済み',
    Disconnected: '切断されました',
    Enabled: '有効',
    Disabled: '無効',
    MinimumChargeCyclesNotMet: '最小充電サイクルが満たされていません',
  },
  'ko-KR': {
    UpperCase_others: '기타',
    Not_available: '사용할 수 없음',
    null: '사용할 수 없음',
    Hours: '시간',
    Months: '달',
    Charging: '충전',
    Discharging: '방전',
    Not_Charging: '충전되지 않음',
    Charge_Full: '완전 충전',
    BATTERY_PLUGGED_AC: '교류 충전기',
    BATTERY_PLUGGED_USB: 'USB 충전기',
    BATTERY_PLUGGED_WIRELESS: '무선 충전기',
    BATTERY_PLUGGED_NO: '충전되지 않음',
    Meters: '미터',
    Minutes: '분',
    Connected: '연결됨',
    Disconnected: '연결됨',
    Enabled: '활성화',
    Disabled: '장애가 있는',
    MinimumChargeCyclesNotMet: '최소 충전 주기 미달',
  },
  'th-TH': {
    UpperCase_others: 'อื่นๆ',
    Not_available: 'ไม่พร้อมใช้งาน',
    null: 'ไม่พร้อมใช้งาน',
    Hours: 'เวลา',
    Months: 'เดือน',
    Charging: 'กำลังชาร์จ',
    Discharging: 'การคายประจุ',
    Not_Charging: 'ไม่ชาร์จ',
    Charge_Full: 'ชาร์จเต็ม',
    BATTERY_PLUGGED_AC: 'เครื่องชาร์จ AC',
    BATTERY_PLUGGED_USB: 'ที่ชาร์จ USB',
    BATTERY_PLUGGED_WIRELESS: 'ที่ชาร์จแบบไร้สาย',
    BATTERY_PLUGGED_NO: 'ไม่ชาร์จ',
    Meters: 'เมตร',
    Minutes: 'นาที',
    Connected: 'เชื่อมต่อแล้ว',
    Disconnected: 'ยกเลิกการเชื่อมต่อ',
    Enabled: 'เปิดใช้งาน',
    Disabled: 'พิการ',
    MinimumChargeCyclesNotMet: 'ไม่ได้ตรงตามรอบการชาร์จขั้นต่ำ',
  },
})
