import { isEmpty } from 'ramda'

// OBJECTS
// Search in values of an Obj
function searchInObj (obj, searchTerm, simple) {
  return Object.keys(obj).filter(x => {
    if (!obj[x] || !searchTerm) {
      return false
    }
    switch (typeof obj[x]) {
      case 'string':
        return obj[x].toLowerCase().includes(searchTerm.toLowerCase())
      case 'number':
        return (obj[x] + '').toLowerCase().includes(searchTerm.toLowerCase())
      case 'object':
        if (simple) {
          return false
        }
        return (Array.isArray(obj[x]))
          ? !isEmpty(search(obj[x], searchTerm))
          : !isEmpty(searchInObj(obj[x], searchTerm))
      default:
        return false
    }
  })
}

// Search nested attributes conditional to @param {doDeepSearch}
function search (fullItems, searchTerm, doDeepSearch) {
  if (!searchTerm) {
    return fullItems
  }

  return fullItems.filter(item => {
    const include = searchInObj(item, searchTerm, !doDeepSearch)
    return !isEmpty(include)
  })
}

export { searchInObj, search }
