import { createStructuredSelector } from 'reselect'
import { loggedIn } from 'Selectors/DashboardSelector'

export const initialState = {
  agree: false,
  content: '',
  datetime: '',
  version: '',
  loading: false,
  error: null
}

export const endUserLicenseAgreementReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case initialEULARequest.type:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case initialEULASuccess.type:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      }
    case initialEULAFailure.type:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case initialEULAVerificationRequest.type:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case initialEULAVerificationSuccess.type:
      return {
        ...state,
        agree: payload,
        content: '',
        loading: false,
        error: null,
      }
    case initialEULAVerificationFailure.type:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

const initialEULARequest = () => ({ type: initialEULARequest.type })
const initialEULASuccess = (eula = {}) => ({ type: initialEULASuccess.type, payload: eula })
const initialEULAFailure = (error = null) => ({ type: initialEULAFailure.type, payload: error })
const initialEULAVerificationRequest = () => ({ type: initialEULAVerificationRequest.type })
const initialEULAVerificationSuccess = (agree = null) => ({ type: initialEULAVerificationSuccess.type, payload: agree })
const initialEULAVerificationFailure = (error = null) => ({ type: initialEULAVerificationFailure.type, payload: error })

const endUserLicenseAgreementPrefix = 'end-user-license-agreement/'
endUserLicenseAgreementReducer.slice = 'endUserLicenseAgreement'

initialEULARequest.type = endUserLicenseAgreementPrefix + 'initialEULARequest'
initialEULASuccess.type = endUserLicenseAgreementPrefix + 'initialEULASuccess'
initialEULAFailure.type = endUserLicenseAgreementPrefix + 'initialEULAFailure'
initialEULAVerificationRequest.type = endUserLicenseAgreementPrefix + 'initialEULAVerificationRequest'
initialEULAVerificationSuccess.type = endUserLicenseAgreementPrefix + 'initialEULAVerificationSuccess'
initialEULAVerificationFailure.type = endUserLicenseAgreementPrefix + 'initialEULAVerificationFailure'

export const EndUserLicenseAgreementActions = {
  initialEULARequest,
  initialEULASuccess,
  initialEULAFailure,
  initialEULAVerificationRequest,
  initialEULAVerificationSuccess,
  initialEULAVerificationFailure
}


export const getAcceptenceTest = state => state.agree
export const getErrorTest = state => state.error
export const getLoadingTest = state => state.loading

export const getAcceptence = state => state[endUserLicenseAgreementReducer.slice].agree
export const getVersion = state => state[endUserLicenseAgreementReducer.slice].version
export const getContent = state => state[endUserLicenseAgreementReducer.slice].content
export const getDateTime = state => state[endUserLicenseAgreementReducer.slice].datetime
export const getError = state => state[endUserLicenseAgreementReducer.slice].error
export const getLoading = state => state[endUserLicenseAgreementReducer.slice].loading

export const getEulaInfoSelector = createStructuredSelector({
  accepted: getAcceptence,
  version: getVersion,
  eulaContent: getContent,
  dateVersion: getDateTime,
  error: getError,
  loading: getLoading,
  loggedIn
})
