import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

const { Types, Creators } = createActions({
  breadcrumbSetItems: ['data']
})

export const BreadcrumbTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  crumbs: []
})

/* ------------- Reducers ------------- */

export const setItems = (state, { data }) => state.merge({ crumbs: data })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BREADCRUMB_SET_ITEMS]: setItems
})

export const useBreadcrumb = () => {
  const dispatch = useDispatch()

  return useCallback((items) => dispatch(
    Creators.breadcrumbSetItems(items)
  ), [dispatch])
}
