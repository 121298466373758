import React, { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslate } from 'react-translate'
import { parse } from 'qs'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { Loader } from '@scuf/common'
import { SsoOauthActions } from '../../features/sso-oauth2'

const PrivateRoute = ({
  component: Node,
  loggedIn,
  permission,
  permissions,
  permissionsLoading,
  fallback,
  ...rest
}) => {
  const t = useTranslate('App')
  const history = useHistory()
  const dispatch = useDispatch()
  const { authprovider } = useMemo(
    () => parse(history.location.search, { ignoreQueryPrefix: true })
    , [history.location.search])

  const { useremail } = useMemo(
    () => parse(history.location.search, { ignoreQueryPrefix: true })
    , [history.location.search])

  const saveCurrentLocationAndRedirect = useCallback((path, state) => {
    dispatch(SsoOauthActions.setRedirectRoute(history.location.pathname))
    history.push(path, state)
  }, [dispatch, history])

  const { path } = rest
  const renderRoute = (props) => {
    if (permissionsLoading) {
      return (
        <Loader text={t('App_Loading')}>
          <div className='placeholder' />
        </Loader>
      )
    }

    if (!loggedIn || Object.keys(permissions).length === 0) {
      saveCurrentLocationAndRedirect('/login', {
        authprovider,
        useremail,
        path: history.location.pathname
      })
      return null
    }

    if (permission && !permissions[permission]) {
      if (path !== '/dashboard/sites') {
        toast(<ErrorToast message={t('NoAccess')} />)
      }
      history.push(fallback ?? '/assets/management/all')
    }

    if ((permissions && permissions['asset:viewonlyassigned']) &&
      (history.location.pathname === '/dashboard/sites' || history.location.pathname === '/admin/user_management' || history.location.pathname === '/admin/site_management')) {
      history.push('/assets/management/all')
    }

    return (
      <Node {...props} />
    )
  }

  return (
    <Route {...rest} render={props => renderRoute(props)} />
  )
}

PrivateRoute.defaultProps = { }

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool
}

export default PrivateRoute
