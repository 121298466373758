import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { AssetModel, CreateAssetModel } from '../apis'
import {
  createAssetModel,
  fetchAssetModels,
  selectAllAssetModels,
  selectTotalAssetModels,
  selectManufacturerEntities,
  selectAssetTypeEntities,
  fetchManufacturers,
  fetchAssetTypes
} from '../store'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { useTranslate } from 'react-translate'
import { useAppDispatch } from 'Store'
import { createSelector } from 'reselect'

export interface AssetModelWithReferenceNames extends AssetModel {
  manufacturerName: string;
  assetTypeName: string;
}

const selectAssetModelWithReferenceNames = createSelector(
  [selectAllAssetModels, selectManufacturerEntities, selectAssetTypeEntities],
  (assetModels, manufacturersById, assetTypesById): AssetModelWithReferenceNames[] => assetModels.map(assetModel => {
    const manufacturerName = manufacturersById[assetModel.manufacturerId]?.manufacturerName ?? ''
    const assetTypeName = assetTypesById[assetModel.assetTypeId]?.name ?? ''
    return {
      ...assetModel,
      manufacturerName,
      assetTypeName
    }
  })
)

export const useFetchAssetModels = () => {
  const dispatch = useAppDispatch()
  const translate = useTranslate('AssetModels')

  const [isLoading, setIsLoading] = useState(false)
  const assetModels = useSelector(selectAssetModelWithReferenceNames)
  const total = useSelector(selectTotalAssetModels)

  const fetchData = useCallback(async (organizationId: string) => {
    setIsLoading(true)

    dispatch(fetchManufacturers(organizationId))
    dispatch(fetchAssetTypes(organizationId))
    const resultAction: any = await dispatch(fetchAssetModels(organizationId))

    if (fetchAssetModels.fulfilled.match(resultAction)) {
      setIsLoading(false)
      return unwrapResult(resultAction)
    } else {
      setIsLoading(false)
      toast(<ErrorToast message={translate('GetError')} closeToast={() => {}} />)
      return null
    }
  }, [setIsLoading, dispatch, translate])

  return {
    isLoading,
    assetModels,
    total,
    fetchAssetModels: fetchData
  }
}

export const useCreateAssetModel = () => {
  const dispatch = useAppDispatch()
  const [isPending, setIsPending] = useState(false)

  const submitData = useCallback(async (assetModel: CreateAssetModel): Promise<AssetModel> => {
    setIsPending(true)
    const resultAction = await dispatch(createAssetModel(assetModel))
    setIsPending(false)

    if (createAssetModel.fulfilled.match(resultAction)) {
      return unwrapResult(resultAction)
    } else if (resultAction.payload) {
      throw resultAction.payload.map(err => err.detail)
    } else {
      throw new Error(resultAction.error.message)
    }
  }, [setIsPending, dispatch])

  return {
    isPending,
    createAssetModel: submitData
  }
}
