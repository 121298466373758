import { connect } from 'react-redux'
import ModalActions from 'Redux/ModalRedux'
import DetailsModal from './details-modal'
import DetailsModalSelector from './details-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('UserActivityReport'),
  connect(
    DetailsModalSelector,
    mapDispatchToProps
  )
)(DetailsModal)
