import React from 'react'
import WeekDaysSelector from './WeekDaySelector.connect'

const FormWeekDaySelector = (props) => {
  const {
    input: { value, onChange }
  } = props
  return (
    <WeekDaysSelector
      onChange={onChange}
      value={value}
    />
  )
}

export default FormWeekDaySelector
