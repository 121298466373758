export class ReleaseNotesService {
  constructor (api) {
    this.api = api
    this.response = null
    this.inUse = false
  }

  getReleaseNotes = async () => {
    if (this.inUse) throw new Error("service in use")
    this.inUse = true
    if (!this.response) {
      this.response = await this.api.get('/alerts/releasecommunications')
    }
    this.inUse = false
    return this.response
  }
}
