import * as React from 'react'
import Immutable from 'seamless-immutable'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-translate'
import { toast } from 'react-toastify'
import { Button } from '@scuf/common'

import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'
import { FileDropContainer, StyledLoader } from '../../../../Components/CsvFileDrop/csv-file-drop.styles'
import { NewEmployee } from '../../apis'
import { selectedOrgId, selectedUserOrgRoles } from 'Selectors/LoaderSelector'

import { useDownloadImportTemplate, useDownloadUsersTemplate, useImportForm } from '../add-employees-form/import-employees-hooks'
import { ValidationStatus } from '../add-employees-form/validation-status'
import { Row, Form, FormButtons, FormContent, TableContainer, StyledLink, ButtonWrapper, LeftContainerWrapper, RightContainerWrapper } from './add-users-form.styles'
import { EmployeesImportTable } from '../add-employees-form/employees-import-table'
import { FileUploader } from '../add-employees-form/file-uploader'

export interface AddUsersFormProps {
  onCancel: () => any
  onSuccess: () => any
}

export const AddUsersForm: React.FC<AddUsersFormProps> = ({
  onCancel,
  onSuccess
}) => {
  const t = useTranslate('AddEmployeesForm')
  const orgId = useSelector(selectedOrgId)
  const roles = useSelector(selectedUserOrgRoles)
  const { downloadImportTemplate } = useDownloadImportTemplate()
  const {
    validationStatus,
    isValid,
    isSubmitting,
    setUsersTableData,
    tableData,
    submitForm,
    submitUsersForm,
    isBulkProcessing
  } = useImportForm()
  const { downloadUsersTemplate, isDownloadingUsers } = useDownloadUsersTemplate()

  const onSubmit = React.useCallback(async () => {
    try {
      await submitUsersForm()
      toast(<SuccessToast message={t('Success')} />)
      onSuccess()
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={t('ErrorSubmit')} />)
    }
  }, [t, onSuccess, submitUsersForm])

  const setTableDataWithToasts = React.useCallback(async (data: Immutable.ImmutableArray<NewEmployee>) => {
    try {
      await setUsersTableData(data)
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={t('ErrorOccurredValidating')} />)
    }
  }, [t, setUsersTableData])

  const revalidateForm = React.useCallback(() => {
    setTableDataWithToasts(tableData)
  }, [setTableDataWithToasts, tableData])

  const onFileLoaded = React.useCallback(async (data: NewEmployee[]) => {
    await setTableDataWithToasts(Immutable(data))
  }, [setTableDataWithToasts])

  const onResetClick = React.useCallback(async () => {
    await setTableDataWithToasts(Immutable([] as NewEmployee[]))
  }, [setTableDataWithToasts])

  if (isSubmitting || isBulkProcessing) {
    let height='30vh'
    return (
      <FileDropContainer>
        <StyledLoader minHeight={height} />
      </FileDropContainer>
    )
  }

  return (
    <Form>
      <FormContent>
        <Row>
          <b>{`${t('TableLabelEmail')} - `}</b>
          <Button
            type='link'
            content={t('DownloadTemplate')} onClick={() => downloadImportTemplate(roles)}
          />
        </Row>
        <TableContainer>
          {
            tableData.length > 0
              ? (
                <EmployeesImportTable
                  data={tableData}
                  onChange={data => setTableDataWithToasts(data)}
                  validationStatus={validationStatus}
                  submitting={isSubmitting}
                  isUsers={true}
                />
              ) : <FileUploader organizationId={orgId} onFileLoaded={onFileLoaded}/>
          }
        </TableContainer>
      </FormContent>
      <FormButtons>
        <ButtonWrapper>
          <LeftContainerWrapper>
            <StyledLink 
              onClick={() => downloadUsersTemplate()}
              disabled={isDownloadingUsers} >
              {t('DownloadCurrentUsers')}
            </StyledLink>
          </LeftContainerWrapper>
          <RightContainerWrapper>
            <Button
              id='reset-button'
              onClick={onResetClick}
              content={t('Reset')}
              disabled={tableData.length === 0}
              type='secondary'
              />
            <Button
              id='cancel-button'
              content={t('Cancel')}
              onClick={onCancel}
              disabled={isSubmitting}
              type='secondary'
            />
            {validationStatus === ValidationStatus.Failed && (
              <Button
                id='submit-button'
                content={t('Validate')}
                onClick={() => revalidateForm()}
                type='primary'
              />
            )}
            <Button
              id='submit-button'
              content={t('Submit')}
              onClick={onSubmit}
              disabled={!(isValid && validationStatus === ValidationStatus.Validated && tableData.length !== 0)}
              type='primary'
            />
          </RightContainerWrapper>
        </ButtonWrapper>
      </FormButtons>
    </Form>
  )
}
