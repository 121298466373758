import { AsyncThunkPayloadCreator, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import * as API from '../apis'
import throttle from 'lodash/throttle'
import { RootState } from 'Store'
import { ErrorMessage } from '../../../Services/api-response'
import { LoginTypes } from '../../../Redux/LoginRedux'

const manufacturersAdapter = createEntityAdapter<API.Manufacturer>({
  selectId: (model: API.Manufacturer) => model.id,
  sortComparer: (a: API.Manufacturer, b: API.Manufacturer) =>
    a.manufacturerName.localeCompare(b.manufacturerName)
})

const fetchManufacturersPayloadCreator: AsyncThunkPayloadCreator<API.Manufacturer[], string, { rejectValue: string }> =
  async (organizationId, thunkAPI) => {
    const response = await API.getManufacturers(organizationId)
    if (response.ok && response.data?.data) {
      return response.data.data
    } else {
      return thunkAPI.rejectWithValue(`There was an error fetching manufacturers.`)
    }
  }

export const fetchManufacturers = createAsyncThunk<API.Manufacturer[], string, { rejectValue: string }>(
  'manufacturers/fetchAll',
  // @ts-ignore
  throttle(fetchManufacturersPayloadCreator, 1000, { leading: true, trailing: false })
)

export const createManufacturer = createAsyncThunk<null, API.CreateManufacturer, { rejectValue: ErrorMessage[] }>(
  'manufacturers/createOne',
  async (manufacturer, thunkAPI) => {
    const response = await API.createManufacturer(manufacturer)
    if (response.ok) {
      thunkAPI.dispatch(fetchManufacturers(manufacturer.organizationId))
      return null
    } else if (response.data?.errors) {
      return thunkAPI.rejectWithValue(response.data.errors)
    }
    throw new Error('There was an error creating the manufacturer.')
  }
)

const initialState = manufacturersAdapter.getInitialState({
  loading: false
})

const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(LoginTypes.LOGIN_SET_CURRENT_ORGANIZATION, manufacturersAdapter.removeAll)
    builder.addCase(fetchManufacturers.fulfilled, manufacturersAdapter.upsertMany)
  }
})

export const {
  selectById: selectManufacturerById,
  selectIds: selectManufacturerIds,
  selectEntities: selectManufacturerEntities,
  selectAll: selectAllManufacturers,
  selectTotal: selectTotalManufacturers
} = manufacturersAdapter.getSelectors((state: RootState) => state.manufacturers)

export const manufacturersReducer = manufacturersSlice.reducer
