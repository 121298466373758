import { connect } from 'react-redux'
import { translate } from 'react-translate'

import DeleteSite from './delete-site'
import mapStateToProps from './delete-site.selector'
import SiteManagerActions from '../../Redux/SiteManagerRedux'
import ModalActions from '../../Redux/ModalRedux'
import FilterActions from '../../Redux/FilterRedux'

export const mapDispatchToProps = dispatch => ({
  setCurrentSite: (siteId) => dispatch(SiteManagerActions.siteManagerSetCurrent(siteId)),
  deleteSite: (siteId) => dispatch(SiteManagerActions.siteManagerDeleteRequest(siteId)),
  closeModal: () => dispatch(ModalActions.modalClose()),
  setFilter: (filter) => dispatch(FilterActions.filterSetGlobalFilter(filter)),
  getSites: () => dispatch(SiteManagerActions.siteManagerRequest())
})

export default translate('DeleteSiteTable')(connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSite))
