import { connect } from 'react-redux'
import ModalActions from '../../../Redux/ModalRedux'
import ApplicationReportModal from './application-report-modal'
import applicationReportModalSelector from './application-report-modal.selector'
import DashboardActions from '../../../Redux/DashboardRedux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose()),
    getDeviceAppDataCustom: (id, startDate, endDate) => dispatch(DashboardActions.dashboardRequestAppDataCustom(id, startDate, endDate))
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    applicationReportModalSelector,
    mapDispatchToProps
  )
)(ApplicationReportModal)
