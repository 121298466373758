const getPosition = (success) => {
  navigator.geolocation.getCurrentPosition(
    (pos) => success({ lat: pos.coords.latitude, lon: pos.coords.longitude }),
    (e) => ({ latitude: 0, longitude: 0 }),
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })
}

export { getPosition }
