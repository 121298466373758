import styled from 'styled-components'
import { Modal, Radio } from '@scuf/common'

const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: visible !important;
    background-color:transparent !important;
  } 
`
const ModalTitle = styled(Modal.Header)`
  font-size: 1.75rem;
  font-weight: bold;
`

const ModalSectionTitle = styled(Modal.Header)`
  font-size: 1rem;
  margin: 1rem 0 0 0;
`

const ModalSubTitle = styled(Modal.Header)`
  font-size: 1rem;
  color: gray;
`
const ModalContent = styled(Modal.Content)`
  min-height:200px;
`

const ThemeWrap = styled.div`
  display:flex;
  flex-direction: column;
`
const StyledButton = styled.div`
  max-width:150px;
  border: ${({ isSelected }) => isSelected ? '2px solid #1274b7 !important' : '2px solid grey !important' };
  display:flex !important;
  flex-direction:column !important;
  padding: 0 !important;
  &.ui.button > .button-content{
    height: 110px !important;
  }
`
const StyledRadio = styled(Radio)`
  margin-right:2rem !important;
  margin-bottom: 1rem !important;
`

export { ModalWrap, ModalTitle, ModalSectionTitle, ModalSubTitle, ModalContent, ThemeWrap, StyledButton, StyledRadio }
