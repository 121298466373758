const create = (api) => {
  const userInfo = () => api.get('/UserManagement/v2/users/features')
  const userDetails = () => api.get('/UserManagement/self')
  const getUploadToken = (params) => api.post('/DeviceOnBoarding/token', params)
  const getFilters = (params) => api.get('/Settings/filter', params)
  const postFilters = (filters) => api.post('/Settings/filter', filters)
  const getEULAVerification = () => api.get('/EULA')
  const sendEulaVerification = () => api.post(`/EULA/status`)
  const getSLAVerification = () => api.get('/sla')
  const sendSLAVerification = () => api.post(`/sla`)

  return {
    userInfo,
    userDetails,
    getUploadToken,
    getFilters,
    postFilters,
    getEULAVerification,
    sendEulaVerification,
    getSLAVerification,
    sendSLAVerification
  }
}

export default {
  create
}
