import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { LtlTripTypes } from './actions'

const INITIAL_STATE = Immutable({
  ltlTotalTrips: { loading: false },
  ltlMissedStops: { loading: false },
  ltlDwellTime: { loading: false }
})

const dashboardRequestLTLTotalTripsCard = (state) => state.merge({ ltlTotalTrips: { loading: true } })
const dashboardResponseLTLTotalTripsCard = (state, { data }) => state.merge({ ltlTotalTrips: { ...(data || {}), loading: false } })

const dashboardRequestLTLMissedStopsCard = (state) => state.merge({ ltlMissedStops: { loading: true } })
const dashboardResponseLTLMissedStopsCard = (state, { data }) => state.merge({ ltlMissedStops: { ...(data || {}), loading: false } })

const dashboardRequestLTLDwellTimeCard = (state) => state.merge({ ltlDwellTime: { loading: true } })
const dashboardResponseLTLDwellTimeCard = (state, { data }) => state.merge({ ltlDwellTime: { ...(data || {}), loading: false } })

export default createReducer(INITIAL_STATE, {
  [LtlTripTypes.DASHBOARD_REQUEST_LTL_TOTAL_TRIPS_CARD]: dashboardRequestLTLTotalTripsCard,
  [LtlTripTypes.DASHBOARD_RESPONSE_LTL_TOTAL_TRIPS_CARD]: dashboardResponseLTLTotalTripsCard,
  [LtlTripTypes.DASHBOARD_REQUEST_LTL_MISSED_STOPS_CARD]: dashboardRequestLTLMissedStopsCard,
  [LtlTripTypes.DASHBOARD_RESPONSE_LTL_MISSED_STOPS_CARD]: dashboardResponseLTLMissedStopsCard,
  [LtlTripTypes.DASHBOARD_REQUEST_LTL_DWELL_TIME_CARD]: dashboardRequestLTLDwellTimeCard,
  [LtlTripTypes.DASHBOARD_RESPONSE_LTL_DWELL_TIME_CARD]: dashboardResponseLTLDwellTimeCard
})
