import React from 'react'
import { Tooltip } from '@scuf/common'
import styled from 'styled-components/macro'

const StyledTooltip = styled(Tooltip)`
background: ${props => props.theme.h1color};
color: ${props => props.theme.grayback};
`
export const NotAvailable = styled.div`
  font-family: 'Honeywell Sans Web';
  margin: ${props => props.isAppUsage ? '0 0 0 -0.7rem !important' : '0'};
  `

const DeviceAssignedSite = ({
  t,
  rowData,
  cellData,
  isAppUsage = false
}) => {
  const index = cellData?.lastIndexOf('/')
  const Site = cellData?.substring(index + 1)
  return (
    <div>
      {
        cellData
          ? <StyledTooltip
            content={cellData}
            position='top left'
            element={
              <NotAvailable isAppUsage={isAppUsage}>
                {Site}
              </NotAvailable>
            }
            event='hover'
            size='mini'
          /> : <div />
      }
    </div>
  )
}

DeviceAssignedSite.defaultProps = {
  t: label => label
}

export default DeviceAssignedSite
