import styled from 'styled-components'
import { Modal, Input, Button, Icon } from '@scuf/common'

const ModalWrap = styled.div`
  background: ${props => props.theme.background === '#202020 !important' ? '#202020 !important' : '#fff'}  ;
  & .forge-datatable-wrap {
    min-height:350px;
  }
  & .ui.modal .close-icon-wrap .modal-close {
    overflow: hidden !important;
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    background-color: transparent !important;
  }


  & .accordion.ui.fluid .active.content {
    background: ${props => props.theme.background};
    border: none !important;
  }

  & .ui.dashboard-wrap .layout-grid .ui.widget-container {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder};
  }
  & .ui.widget-container .widget-wrapper > div {
    border: none !important;
  }
  & .ui.widget-container .widget-wrapper > div > div > div > img{
    background: #fff;
    border-radius: 3px;
  }
  & .accordion.ui.fluid .title {
    border: none !important;
  }
  & .cONqjN {
    box-shadow: ${props => props.theme.boxShadow};
  }
  & .eTvQHC {
    border-top: ${props => props.theme.darkBorder};
  }
  & .cBfEdo {
    margin-top:1px;
  } 
  & .cell-data-wrap {
    font-size: 12px;
  }
`

const ModalContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
`
const ModalHeader = styled(Modal.Header)`
  padding: 0 !important;
`
const ModalFooter = styled(Modal.Footer)`
  padding: 1rem !important;
  border-top: 1px solid #e0e0e0 !important;
  & a {
    float: left;
  }
`
const NotAvailable = styled.div`
font-family: 'Honeywell Sans Web';
font-size: .75rem !important;
`
const FooterWrap = styled.div`
 width:100%;
 display: flex;
`
const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const HeaderWrap = styled.div`
  width:100%;
`
const StyledH2 = styled.div`
  color: #404040;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
`

const Paragraph = styled.p`
  padding-top: 0.3rem;
  font-size: .75rem;
  color: #707070;
  line-height: 22px;
`
const ParagraphModal = styled.div`
  padding-top: 1.3rem;
  font-size: .875rem;
  color: #707070;
  line-height: 22px;
  margin-bottom:1rem;
`
const ParagraphDescription = styled.p`
margin-top:0px;
font-size: 1rem;
color: #303030;
line-height: 22px;
`

const Link = styled.a`
  min-width: 100px;
  align-self: center;
`

const StyledInput = styled(Input)`
  width:100% ;
  & div {
    width: 100% !important;
  }
`

export const MainContainer = styled.div`
  & .ActionBar__IconWrapper-sc-1jsinei-2 > i {
    display: flex;
  }
  & .h-icon:not(.common).scuf-medium {
    font-size: 1.3rem !important;
  }
  & .ActionBar__IconWrapper-sc-1jsinei-2 > i > div {
    font-size: 0.875rem;
    font-family: 'Honeywell Sans Web';
    padding: 2px 0 0 8px;
  }
`

export {
  ModalWrap,
  ModalContainer,
  HeaderWrap,
  StyledH2,
  Paragraph,
  ParagraphModal,
  ParagraphDescription,
  Link,
  StyledInput,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap,
  NotAvailable
}
