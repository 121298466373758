import styled from 'styled-components/macro'

const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  position: relative;
`
const Container = styled(Flex)`
  flex-direction: ${props => props.row ? 'row' : 'column'};
  padding: 0 1rem;
  height: calc(100% - 49px);
  overflow-y: auto;
  & h2 {
    font-size: 26px;
    font-weight: 500;
  }
  position: relative;
`

export {
  Container
}
