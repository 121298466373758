/* eslint-disable react/jsx-sort-props */
import React, { Fragment } from 'react'
import { Radio, Select } from '@scuf/common'
import { ModalWrap, ModalTitle, ModalSectionTitle, ModalContent, StyledRadio } from './settings-modal.styles'
import PropTypes from 'prop-types'
import { themeDark, themeLight } from '../../Containers/AppFrame.styles'
import { flagsPropTypes, defaultFlags } from 'Utils/launch-darkly-flags'


const handleThemeLight = (setTheme) => {
  setTheme(themeLight)
}
const handleThemeDark = (setTheme) => {
  setTheme(themeDark)
}

const handleUnitSystemMetric = (setUnitSystem) => {
  setUnitSystem('metric')
}
const handleUnitSystemImperial = (setUnitSystem) => {
  setUnitSystem('imperial')
}

const SettingsModal = ({ setLanguage, t, setTheme, flags, selectedTheme, lang, setUnitSystem,selectedUnitSystem, languages}) => (

  <ModalWrap>
    <ModalTitle>{t('Title')}</ModalTitle>
    <ModalContent>


      <ModalSectionTitle>{t('Appearance')}</ModalSectionTitle>
      <StyledRadio checked={selectedTheme === 'light'} name='RadioGroup' label={t('Light')} onChange={() => handleThemeLight(setTheme)} />
      <StyledRadio checked={selectedTheme === 'dark'} name='RadioGroup' label={t('Dark')} onChange={() => handleThemeDark(setTheme)} />
      {
        flags.settingsSpacing
          ? <Fragment>
            <ModalSectionTitle>{t('Spacing')}</ModalSectionTitle>
            <Radio defaultChecked name='RadioGroup' label={t('Compact')} />
            <Radio defaultChecked name='RadioGroup' label={t('Roomy')} />
          </Fragment>
          : null
      }
      <ModalSectionTitle>{t('Language')}</ModalSectionTitle>
      <Select placeholder={t('SelectLang')} value={lang} options={languages} onChange={(value) => setLanguage(value)} />
      {
        flags.settingsRegion
          ? <ModalSectionTitle>{t('Region')}</ModalSectionTitle>
          : null
      }
      <ModalSectionTitle>{t('UnitType')}</ModalSectionTitle>
      <StyledRadio checked={selectedUnitSystem === 'metric'} name='RadioGroup' label={t('Metric')} onChange={() => handleUnitSystemMetric(setUnitSystem)}/>
      <StyledRadio checked={selectedUnitSystem === 'imperial'} name='RadioGroup' label={t('Imperial')} onChange={() => handleUnitSystemImperial(setUnitSystem)}/>
    </ModalContent>
  </ModalWrap>
)

SettingsModal.propTypes = {
  flags: flagsPropTypes,
  t: PropTypes.func,
  setLanguage: PropTypes.func.isRequired,
  setTheme: PropTypes.func,
  selectedTheme: PropTypes.string,
  lang: PropTypes.string,
  setUnitSystem:PropTypes.func,
  selectedUnitSystem: PropTypes.string,
  languages : PropTypes.func
}

SettingsModal.defaultProps = {
  flags: defaultFlags,
  selectedTheme: 'light',
  t: () => { },
  selectedUnitSystem:'metric'
}

export default SettingsModal