import React from 'react'
import styled from 'styled-components/macro'
import { Grid, Button } from '@scuf/common'
import CLoader from 'react-content-loader'

const StyledGrid = styled(Grid)`
  margin-right: 24px;
  & .scuf-col:last-child {
    padding-right:1rem !important;
    @media (max-width: 400px) {
      padding-left: 0; !important
    }
  }
`

const ImageWrap = styled.div`
  display:flex;
  flex: 1;
  height: 100%;
  background-color: #fff;
  text-align:center;
  min-height: 16rem;
  line-height:250px;
  align-items:center;
  justify-content:center;
  @media (max-width: 400px) {
    min-width: 19.5rem;
    margin-right: 0.5rem;
  }
  @media (max-width: 320px) {
    min-width: 17.5rem;
    margin-right: 0.5rem;
  }
`
const Image = styled.img`
  height: 200px;
  width: auto;
  margin: auto;
`

const Title = styled.h4`
  width: 100%;
  margin-botton: 1.25rem;
`
const InfoWrap = styled(Grid.Row)`
  background-color: #ffffff;
  padding: 1.5rem;
  min-height: 16rem;
  margin-right: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  height: 100%;
`

const ContentTitle = styled.div`
  padding: 0 0 0.1rem 0;
  margin-top: 0.75rem;
  & h5 {
    font-weight: 700;
  }
`

const ContentInfo = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BadgeHolder = styled.div`
  align-content: center;
  display: flex;
  padding-right: 5px;
`

const InfoSectionWrapper = styled(Grid.Column)`
  @media(max-width:1050px) {
    padding-left:0rem !important;
  }
`
const CardContainer = styled.div`
  padding-top:1rem;
  padding-left: 1rem;
`

const StyledButton = styled(Button)`
  & .button-content {
    color: #1274B7 !important;
  }
`

const ContentLoader = (theme) => (
  <CLoader
    primaryColor={theme.theme.backgroundheader === '#000' ? '#202020' : '#f3f3f3'}
    secondaryColor={theme.theme.backgroundheader === '#000' ? '#101010' : '#ecebeb'}
    style={{ height: '100%', width: '100%' }}>
    <rect x='32' y='8' width='180' height='16' />
    <rect x='32' y='30' width='180' height='24' />
    <rect x='32' y='60' width='180' height='24' />
    <rect x='32' y='90' width='180' height='24' />
  </CLoader>
)

const ImgLoader = (theme) => (
  <CLoader
    primaryColor={theme.theme.backgroundheader === '#000' ? '#202020' : '#f3f3f3'}
    secondaryColor={theme.theme.backgroundheader === '#000' ? '#101010' : '#ecebeb'}
    style={{ height: '100%', width: '50%' }}
    height={30}
    width={30}
    speed={2}>
    <rect rx='1' ry='1' x='0' y='2' width='30' height='25' />
  </CLoader>
)

export {
  ImageWrap,
  Image,
  Title,
  InfoWrap,
  ContentTitle,
  ContentInfo,
  StyledGrid,
  ContentLoader,
  InfoSectionWrapper,
  BadgeHolder,
  CardContainer,
  ImgLoader,
  StyledButton
}
