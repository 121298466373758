import { createAction } from '@reduxjs/toolkit'
import { decode } from 'jsonwebtoken'

const PREFIX = 'DIRECT_LOGIN/'

const initialState = {
  jwt: null
}

export const setDirectLoginInfo = createAction(
  PREFIX + 'SET_DIRECT_INFO',
  directJWT => {
    const decodedToken = decode(directJWT)
    return ({ payload: {accessToken: directJWT, selectedOrganizationId: decodedToken.organization, userName: decodedToken.email} })
  }
)
