import styled from 'styled-components'

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 1rem;
`

export const TableContainer = styled.div`
  height: 63vh;
  pointer-events: ${(props) => props.disabled && 'none'};
`
