import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { releaseNotesCloseodal, releaseNotesMarkAsRead } from 'Store/release-notes'
import releaseNotesSelector from './release-notes.selector'

export function useReleaseNotes () {
  const { userId, ...state } = useSelector(releaseNotesSelector)
  const dispatch = useDispatch()
  const handleClose = useCallback(() => dispatch(releaseNotesCloseodal()), [dispatch])
  const markAsRead = useCallback(id => dispatch(releaseNotesMarkAsRead(userId, id)), [dispatch, userId])
  return {
    ...state,
    handleClose,
    markAsRead
  }
}
