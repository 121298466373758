import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { ASSET_TYPES } from './upload-asset-modal.constants'

export const reducer = state => getFormValues('uploadAssetForm')(state)

export const loading = state => state.updates.loading || state.updates.loadingHash
export const loadedPercent = state => state.updates.percent

export const isInEditMode = state => state.updates.getIn(['editable'], false)

export const selectedUpdate = state => state.updates.getIn(['selected'], {})

export const deviceType = createSelector(
  reducer,
  form => {
    return get(form, 'compatibleDeviceType', '')
  }
)

export const fileType = createSelector(
  reducer,
  form => {
    return get(form, 'type', '')
  }
)

export const appModule = createSelector(
  reducer,
  form => {
    return get(form, 'appModule', '')
  }
)

export const assetTypes = createSelector(
  reducer,
  form => {
    const type = get(form, 'compatibleDeviceType', '')
    return get(ASSET_TYPES, type, [])
  }
)
