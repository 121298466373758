import { createStructuredSelector } from 'reselect'

export const loading = ({ updates }) => updates.loading

export const selectedPackages = ({ updates }) => updates.getIn(['selectedUpdates'], [])

export default createStructuredSelector({
  selectedPackages,
  loading
})
