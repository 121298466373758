const create = (api) => {

  const listOrganization = (id) => {
    return api.get(`/organizations/${id}`)
  }

  const sendSubscriptionEmail = (data) => {
    return api.post(`/notifications/email`, data)
  }

  const getCurrentUserEmailAddress = () => {
    return api.get(`/UserManagement/self`)
  }

  return {
    listOrganization,
    sendSubscriptionEmail,
    getCurrentUserEmailAddress
  }

}

export default {
  create
}
