const initialState = {
  error: null,
  data: [],
  loading: false,
  total: 0,
}

export const organizationUsersReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case initialUsersRequest.type:
    case initialUsersNextPageRequest.type:
      return {
        ...state,
        loading: true,
        error: null
      }
    case initialUsersSuccess.type:
      return {
        ...state,
        error: null,
        loading: false,
        data: payload.users,
        total: payload.total
      }
    case initialUsersFailure.type:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case initialUsersNextPageSuccess.type:
      return {
        ...state,
        error: null,
        loading: false,
        data: state.data.concat(payload)
      }
    case initialUsersNextPageFailure.type:
      return {
        ...state,
        error: payload,
        loading: false
      }
    default:
      return state
  }
}

//action creator
const initialUsersRequest = (tableParams = {}) => ({ type: initialUsersRequest.type, tableParams })
const initialUsersSuccess = (users = [], total = 0) => ({ type: initialUsersSuccess.type, payload: { users, total } })
const initialUsersFailure = (error = null) => ({ type: initialUsersFailure.type, payload: error })
const initialUsersNextPageRequest = (tableParams = {}) => ({ type: initialUsersNextPageRequest.type, tableParams })
const initialUsersNextPageSuccess = (users = []) => ({ type: initialUsersNextPageSuccess.type, payload: users })
const initialUsersNextPageFailure = (error = null) => ({ type: initialUsersNextPageFailure.type, payload: error })

const organizationUserPrefix = 'org-user/'
organizationUsersReducer.slice = organizationUserPrefix

initialUsersRequest.type = organizationUserPrefix + 'initialUsersRequest'
initialUsersSuccess.type = organizationUserPrefix + 'initialUsersSuccess'
initialUsersFailure.type = organizationUserPrefix + 'initialUsersFailure'
initialUsersNextPageRequest.type = organizationUserPrefix + 'initialUsersNextPageRequest'
initialUsersNextPageSuccess.type = organizationUserPrefix + 'initialUsersNextPageSuccess'
initialUsersNextPageFailure.type = organizationUserPrefix + 'initialUsersNextPageFailure'

export const OrganizationUserActions = {
  initialUsersRequest,
  initialUsersSuccess,
  initialUsersFailure,
  initialUsersNextPageRequest,
  initialUsersNextPageSuccess,
  initialUsersNextPageFailure
}

export const getUsersList = (state) => state.data
export const getLoading = (state) => state.loading
export const getError = (state) => state.error
