import styled from 'styled-components/macro'
import { Modal, Select } from '@scuf/common'

const ModalWrap = styled.div`
  & .ui.modal .close-icon-wrap .modal-close {
    font-size: 32px !important;
  }

  & .p-datatable-scrollable-body {
    max-height: 54.5vh !important;
  }
  & .ui.single-input .search-icon {
    height: 2.5rem;
  }

  & .p-datatable-scrollable-header-box {
    margin-right: 0px !important;
  }
`
const ModalTitle = styled(Modal.Header)`
  font-size: 1.75rem;
  @media(max-width: 1015px){
    font-size: 1.60rem;
  }
  @media(max-width: 955px){
    font-size: 1.35rem;
  }
  @media(max-width: 855px){
    font-size: 1.10rem;
  }
  @media(max-width: 755px){
    font-size: 1rem;
    margin-top: 8px
  }
  @media(max-width: 500px){
    font-size: .90rem;
    margin-top: 2px
  }
`
const ModalTitleName = styled.div`
  margin-top: 2px;
  font-size: 1.75rem;
  @media(max-width: 1015px){
    font-size: 1.60rem;
    margin-top: 4px
  }
  @media(max-width: 955px){
    font-size: 1.35rem;
    margin-top: 8px
  }
  @media(max-width: 855px){
    font-size: 1.10rem;
    margin-top: 8px
  }
  @media(max-width: 755px){
    font-size: 1rem;
    margin-top: 8px
  }
  @media(max-width: 702px){
    margin-top: 2px
  }
  @media(max-width: 500px){
    font-size: .90rem;
    margin-top: 2px
  }
`
const ModalFooter = styled(Modal.Footer)`
  @media (max-width: 1660px){
    justify-content: space-around !important;
    .secondary{
      min-width: 60% !important;
      max-width: 60% !important;
    }
    .primary{
      min-width: 35% !important;
      max-width: 35% !important;
    }
    .button{
      height:3.2rem !important
    }
  }
  @media (min-width: 1660px){
    justify-content: space-around !important;
    .secondary{
      min-width: 60% !important;
      max-width: 60% !important;
    }
    .primary{
      min-width: 35% !important;
      max-width: 35% !important;
    }
    white-space:nowrap;
    .button{
      height:2.5rem !important
    }
  }
  @media (max-width: 815px){
    flex-direction: column;
    justify-content: space-around !important;
    .button{
      margin-bottom: 0.75rem !important;
      height:100px !important
    }
  }

`
export { ModalWrap, ModalTitle, ModalTitleName, ModalFooter }


export const DateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  & .ui.date-picker-input .calendar-overlay{
    position: relative !important;
  }
  & .ui.date-picker-input .overlay-position-wrap{
    z-index: 0 !important;
  }
`

export const TimeScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
`



export const StyledTimeZoneSelect = styled(Select)`
  position: relative;
  padding-bottom: 1rem;
  padding-top: 1rem;
`

export const ButtonsContainer = styled.div`
display: flex;
flex: ${props => props.flex ? props.flex : 1};
flex-direction: row;
justify-content: ${ ({ justifyContent }) => justifyContent || ''}
`

export const Row = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`
export const DateTimeContainer = styled.div`

display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
    & .ui.active.visible.selection.dropdown .visible.menu.transition{
    z-index: 0 !important;
  }
  & .ui.upward.dropdown>.menu{
    bottom: auto !important;
  }
`
export const StyledFilter = styled(Select)`
    .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
`
export const TimezoneSelect = Select

export const StyledResponse = styled.p`
display: inline-block;
margin-left: .5rem;
font-size: 1rem;

@media (max-width: 1000px){
  margin-left: 0rem;
}
`

export const StyledProgress = styled.div`
display: inline-block !important;
margin-left: 3rem !important;
overflow-y:hidden !important;
overflow-x:hidden !important;
`
export const StyledText = styled.p`
font-size: 1rem;
`

export const OptionsDiv = styled.div`
display: flex;
flex: 1;
align-items: center !important;
justify-content: space-between !important;
`
export const OptionsInlineDiv = styled(OptionsDiv)`
display: inline-flex;
`