import { useEffect} from 'react'
import config from 'Config/AppConfig'

function loadScript(url, callback = () => null) {
  var script = document.createElement("script")
  script.type = "text/javascript"

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" ||
        script.readyState == "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName("head")[0].appendChild(script)
}

export function useIncludeScripts() {
  useEffect(() => {
    if (config.isLatencyTrackingEnabled) {
        loadScript('http://www.atmrum.net/rum.js', () => {
        try {
          global.rum.start(config.latencyTrackingKey)
        }
        catch {
        }
      })
    }
  }, [])
}