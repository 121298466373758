import React, { PureComponent } from 'react'
import { Modal, Button } from '@scuf/common'
import AssetManagementTable from 'Components/AssetManagementTable'
import { ModalWrap, ModalTitle } from './battery-list-modal.styles'

const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'batteryPercentage', translation: 'AssetManagementTable_lowHealth', render: ({ value }) => <p>{value} %</p> },
  { field: 'currentDevice', translation: 'AssetManagementTable_deviceName', render: null }
]

const BatteryListModal = ({ data, closeModal }) => (
  <ModalWrap>
    <ModalTitle>Batteries with low health list</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName='Batteries with low health list' />
    </Modal.Content>
  </ModalWrap>
)

export default BatteryListModal
