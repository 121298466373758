import styled from 'styled-components'
import { Modal, Input } from '@scuf/common'

const ModalWrap = styled.div`
  background: ${props => props.theme.background === '#202020 !important' ? '#202020 !important' : '#fff'};
  & .ui.modal .close-icon-wrap .modal-close {
    overflow: hidden !important;
    font-size: 32px !important;
  }
  & .scrolling.content {
    overflow: hidden !important;
    background-color: transparent !important;
  }
  & .accordion.ui.fluid .active.content {
    background: ${props => props.theme.background};
    border: none !important;
  }
  & .ui.dashboard-wrap .layout-grid .ui.widget-container {
    background: ${props => props.theme.background};
    border: ${props => props.theme.inputborder};
  }
  & .ui.widget-container .widget-wrapper > div {
    border: none !important;
  }
  & .ui.widget-container .widget-wrapper > div > div > div > img{
    background: #fff;
    border-radius: 3px;
  }
  & .accordion.ui.fluid .title {
    border: none !important;
  }
  & .cONqjN {
    box-shadow: ${props => props.theme.boxShadow};
  }
  & .eTvQHC {
    border-top: ${props => props.theme.darkBorder}
  }
  & .cBfEdo {
    margin-top:1px;
  } 
`

const ModalContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
  margin-left: 1rem;
  & .datetime-date.error .below-text{
    width: 190px;
    white-space: nowrap;
  }
`
const ModalHeader = styled(Modal.Header)`
  padding: 0 !important;
`
const ModalFooter = styled(Modal.Footer)`
  padding: 1rem !important;
  & a {
    float: left;
  }
`

const FooterWrap = styled.div`
 width:100%;
 display: flex;
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const HeaderWrap = styled.div`
  padding: 1rem;
`
const StyledH2 = styled.h2``

const Paragraph = styled.p`
  padding-top: 0.5rem;
  font-size: 0.875rem;
  color: #303030;
  line-height: 22px;
`

const Link = styled.a`
  min-width: 100px;
  align-self: center;
`

const StyledInput = styled(Input)`
  width:100% 
  & div {
    width: 100% !important;
  }
`

export {
  ModalWrap,
  ModalContainer,
  HeaderWrap,
  StyledH2,
  Paragraph,
  Link,
  StyledInput,
  ModalHeader,
  ModalFooter,
  FooterWrap,
  ButtonWrap
}
