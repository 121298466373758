import React, { memo } from 'react'
import { useTranslate } from 'react-translate'
import { DataTable } from '@scuf/datatable'
import { Row, Col } from '../../form-devices.styles'
import { PaddedDiv } from './DevicesTable.styles'
import ErrorComponent from '../../../../../../Components/Error'
import ErrorTooltip from '../error-tooltip/ErrorTooltip'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import DeviceSite from 'Components/AssetManagementBase/components/device-assigned-site'

const columnRenderer = ({ field, value, rowData }) => {
  const { errors } = rowData
  return <span>
    {get(errors, field, []).length > 0 ? <ErrorTooltip icon left component={get(errors, field, []).join(', ')} /> : ''} {value}
  </span>
}

const siteRenderer = ({ field, value, rowData }) => {
  const { errors } = rowData
  if (get(errors, field, []).length > 0) {
    return <span> <ErrorTooltip icon left component={get(errors, field, []).join(', ')} /> {value} </span>
  } else {
    return <DeviceSite cellData={value} />
  }
}

const statusRenderer = ({ value, rowData }) => {
  const { message } = rowData
  let color = /^ready$|^ok$|^provisioned$/i.test(value) ? 'green' : 'red'
  color = /^provisioning$/i.test(value) ? 'blue' : color
  return <span><DataTable.Status color={color} />{message || value}</span>;
}

const DevicesTable = memo(({
  devicesToProvision,
  devicesAddedToList,
  onEdit,
  loadingCsv,
  typeRenderer,
  actionsRenderer,

}) => {
  const t = useTranslate('FormUploadDevices')
  return (<Row >
    <DataTable
      loading={loadingCsv}
      search={true}
      searchPlaceholder={t('placeholderDatatable')}
      data={devicesToProvision.length > 0 ? devicesToProvision : devicesAddedToList}
      onEdit={onEdit}
    >
      <DataTable.Column field='type' initialWidth={137} header={t('deviceType')} renderer={typeRenderer} />
      <DataTable.Column field='model' initialWidth={152} header={t('deviceModel')} sortable={true} renderer={columnRenderer} />
      <DataTable.Column field='serial' initialWidth={158} header={t('serialNumber')} sortable={true} renderer={columnRenderer} />
      <DataTable.Column field='alias' header={t('alias')} editable={devicesToProvision.length <= 0} editFieldType='text' sortable={true} renderer={columnRenderer} />
      <DataTable.Column dataKey='siteId' field='siteId' header={t('site')} sortable={true} renderer={siteRenderer} />
      <DataTable.Column field='status' header={t('status')} renderer={statusRenderer} sortable={true} />
      <DataTable.Column field='actions' header={t('removeOne')} renderer={actionsRenderer} />
    </DataTable>
  </Row>)
}
)

DevicesTable.displayName = 'FormUploadDevicesTable'
DevicesTable.propTypes = {
  devicesToProvision: PropTypes.array,
  devicesAddedToList: PropTypes.array,
  onEdit: PropTypes.func,
  loadingCsv: PropTypes.bool,
  typeRenderer: PropTypes.func,
  actionsRenderer: PropTypes.func
}

export default DevicesTable
