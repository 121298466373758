import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { NetworkRangesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  mode: '',
  isShowingModal: false,
  form: {}
})

export const showCreateNetworkRangeModal = state => state.merge({
  isShowingModal: true,
  mode: 'create'
})

export const showEditNetworkRangeModal = (state, { networkRange }) => state.merge({
  isShowingModal: true,
  mode: 'edit',
  form: networkRange
})

export const startLoading = state => state.merge({ loading: true })

export const success = state => state.merge({ loading: false })

export const reset = () => INITIAL_STATE

export const networkRangesReducer = createReducer(INITIAL_STATE, {
  [NetworkRangesTypes.SHOW_CREATE_NETWORK_RANGE_MODAL]: showCreateNetworkRangeModal,
  [NetworkRangesTypes.SHOW_EDIT_NETWORK_RANGE_MODAL]: showEditNetworkRangeModal,
  [NetworkRangesTypes.CREATE_NETWORK_RANGE_REQUEST]: startLoading,
  [NetworkRangesTypes.CREATE_NETWORK_RANGE_SUCCESS]: success,
  [NetworkRangesTypes.CREATE_NETWORK_RANGE_FAILURE]: success,
  [NetworkRangesTypes.EDIT_NETWORK_RANGE_REQUEST]: startLoading,
  [NetworkRangesTypes.EDIT_NETWORK_RANGE_SUCCESS]: success,
  [NetworkRangesTypes.EDIT_NETWORK_RANGE_FAILURE]: success,
  [NetworkRangesTypes.CLOSE_MODAL]: reset
})
