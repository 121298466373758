import { Banner, Button } from '@qasp/ui'
import React, { useState } from  'react'
import { useHistory } from 'react-router-dom';

//Component
const AddLicensesBanner = ({
    bannerOpen = false,
    flags,
    CloseBanner = () => {},
    onClickHandler = () => {}
}) => {return(
    <>
    { bannerOpen && flags.addLicensesBanner && 
        <Banner
        bannerType="success"
        bannerVariant="onPage"
        animate={false}
        content="How To Order Additional Operational Intelligence licenses...you can navigate to the Subsciption option under user profile icon, or click the 'Click Here' button to the right."
        onClose={CloseBanner}
        open
        title="Need Additional Licenses?"
    >
        <Button
        label="alert"
        variant="quiet-primary"
        onClick={onClickHandler}
        >
        Click Here
        </Button>
    </Banner>
    }
    </>
)}

export default ({
    flags
}) => {
    const history = useHistory()
    const [bannerOpen, setIsBannerOpen] = useState(true)
    
    const CloseBanner = () => {
        setIsBannerOpen(false)
    }

    const onClickHandler = () => {
        setIsBannerOpen(false)
        if(flags.subscriptionManagement) {
            history.push('/subscriptionManagement')
        } else {
            history.push('/subscription')
        }
    }
    
    const props = {
        bannerOpen, 
        flags,
        CloseBanner,
        onClickHandler
    }

  return <AddLicensesBanner {...props} />
}
