import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Header, SelectorContainer, SiteSelector, ButtonAsAlerts } from './PreferencesAlerts.styles'
import { Icon, Checkbox, Button } from '@scuf/common'
import { PreferencesApi } from 'Services'
import { toast } from 'react-toastify'
import { ErrorToast } from 'Themes/ScufStyledComponents'
import { StyledToastContainer } from '../../../toasts'
import Collapse from '../collapse'
import AlertSubcategory from '../alerts-subcategory'
import PreferencesSiteSelector from '../site-selector'

const PreferencesAlerts = ({
  userId,
  t,
  sites,
  isSameAsAlerts,
  categories,
  alerts,
  filteredAlerts,
  saveAlerts,
  selectedOrgId,
  selectedSites,
  isAlertsEnabled,
  updateSites,
  openModal,
  theme,
  hasFeatureAccess
}) => {
  const [enabled, setEnabled] = useState(false)
  // /**
  //  * Call api to disable notifications
  //  * @param {bool} checked
  //  */
  const disableAlertsNotification = async (checked) => {
    const result = await PreferencesApi.disableAlertsNotifications(checked)
    if (result.ok) {
      setEnabled(checked)
    } else {
      toast(<ErrorToast> {t('ErrorSavingConfiguration')} </ErrorToast>)
    }
  }

  const buttonAlertsData = () => {
    const alertList = alerts.map(alert => ({
      ruleId: alert.id,
      organizationId: selectedOrgId,
      enabled: alert.enabled,
      notificationEmail: alert.enabled
    }))
    saveAlerts(alertList)
  }

  return (
    <Container>
      <Header>
        <h3>{t('SiteSelect')}</h3>
      </Header>
      <p>
        {t('SiteSelectDesc')}
      </p>
      <PreferencesSiteSelector
        sites={sites}
        t={t}
        selectedOrgId={selectedOrgId}
        updateSites={updateSites}
        isAlertsEnabled={isAlertsEnabled}
        selectedSites={selectedSites}
        openModal={openModal}
        theme={theme}
      />

      <Header>
        <h3>{t('AlertsSelect')}</h3>
      </Header>
      <p>
        {t('AlertsSelectDesc')}
      </p>
      <ButtonAsAlerts
        content='Same as Alerts'
        onClick={() => buttonAlertsData()}
        type='link'
      />

      {categories.map(type => {
        if (type) {
          return (
            <Collapse
              category={type}
              key={type}
              alertInfo={filteredAlerts[type]}
              saveAlerts={saveAlerts}
              tabType='notificationEmail'
              selectedOrgId={selectedOrgId}
              t={t}
              hasFeatureAccess={hasFeatureAccess}
            >
              <AlertSubcategory
                alertInfo={filteredAlerts[type]}
                saveAlerts={saveAlerts}
                selectedOrgId={selectedOrgId}
                tabType='notificationEmail'
                t={t}
                hasFeatureAccess={hasFeatureAccess}
              />
            </Collapse>
          )
        }
      }
      )}
    </Container>
  )
}
PreferencesAlerts.displayName = 'PreferencesAlerts'
PreferencesAlerts.propTypes = {
  t: () => {},
  userId: ''
}
PreferencesAlerts.defaultProps = {
  userId: PropTypes.string,
  t: PropTypes.func
}

export default PreferencesAlerts
