import styled from 'styled-components'

export const SignalModalContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 2rem;
height: 80% !important;
justify-content: space-evenly;
`

export const InputContainer = styled.div`
display: flex;
justify-content: space-around;

& .ui.slider .rc-slider-handle{
  background-color: #6c6967 !important;
}

& .ui.slider .rc-slider-handle .slider-handle-tooltip{
  background-color: #6c6967 !important;
}

& .ui.slider-wrap .ui.slider .rc-slider .rc-slider-track{
  background-color: #6c6967 !important;
}
`

export const Title = styled.h1`
font-weight: initial;
`

export const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 5rem;
` 

export const OptionContainer = styled.div`
display: flex;
justify-content: inherit;
align-items: center;
`

export const MinimunRangeLabel = styled.label`
margin: 0rem 0.8rem;
`

export const Subtitle = styled.label`
margin: 0rem 0rem 2rem 0rem;
`

export const OptionLabels = styled.div`
display: flex;
align-items: center;
`

export const OptionsContainer = styled.div`
display: flex;
justify-content: flex-start;
width: 40rem !important;
margin-top: 3rem;
font-size: 15px;
`

export const Options = styled.div`
display: flex;
flex-direction: column;
align-items: center;
height: 5rem;
justify-content: space-around;
`
