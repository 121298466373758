import apiClient from 'Services/ApiSauceInstance'
import { ApiResponse } from '../../../Services/api-response'

export interface CreateAssetType {
  organizationId: string;
  name: string;
}

export interface AssetType extends CreateAssetType {
  id: string;
}

export const getAssetTypes = (organizationId: string) => apiClient
  .get<ApiResponse<AssetType[]>>('asset-types')

export const createAssetType = (assetType: CreateAssetType) => apiClient
  .post<ApiResponse<AssetType>>('asset-types', assetType)
