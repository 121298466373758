import { createSelector, createStructuredSelector } from 'reselect'
import { sortBy } from 'lodash'
import { devices, loading, reloading, totalDevices, stopLoading } from './DeviceSelector'
import { batteries, loading as batteriesLoading } from './BatterySelector'

export const deviceFilters = state => state.filters.devices
const batteriesFilters = state => state.filters.batteries

export const applyFilters = (devs, filter) => {
  const rules = Object.keys(filter).reduce((arr, c) => {
    if (filter[c].filtersById && filter[c].filtersById.length) {
      const rule = (obj) => filter[c].filtersById.indexOf(obj[c]) !== -1
      return [...arr, rule]
    }
    return arr
  }, [])
  return devs.filter(d => rules.reduce((p, f) => p && f(d), true))
}

export const filteredDevices = createSelector([devices, deviceFilters], applyFilters)

export const filteredBatteries = createSelector([batteries, batteriesFilters], applyFilters)

export const arrayConcat = (arr, x) => [...arr, x]

export const filters = createSelector([devices, deviceFilters], (devs, filter) => {
  const allFilters = devs.reduce((arr, d) => {
    return [
      ...arr,
      ['connectionStatus', 'filters', d.connectionStatus],
      ['modelUpperCase', 'filters', d.modelUpperCase],
      ['deviceTypeFormatted', 'filters', d.deviceTypeFormatted ? d.deviceTypeFormatted : 'Filters_NotAvailable']
    ]
  }, [])
    .reduce((obj, x) => {
      if (!obj.getIn(x)) {
        const [key, , value] = x
        return obj
          .updateIn([key, 'filtersById'], arrayConcat, value)
          .setIn(x, { key: value, selected: false })
      } else {
        return obj
      }
    }, filter)
  return Object.keys(allFilters).map(f => {
    return {
      key: f,
      name: allFilters[f].name,
      filters: sortBy(allFilters[f].filtersById.map(fs =>
        allFilters[f].filters[fs]
      ), ['key']),
      translateKey: allFilters[f].translateFilters
    }
  })
})

export const filtersBatteries = createSelector([batteries, batteriesFilters], (devs, filter) => {
  const der = devs.reduce((arr, d) => {
    return [
      ...arr,
      ['cycleCountFilter', 'filters', d.cycleCountFilter],
      ['fullCapacityFilter', 'filters', d.fullCapacityFilter]
    ]
  }, [])
    .reduce((obj, x) => {
      if (!obj.getIn(x)) {
        const [key, , value] = x
        return obj
          .updateIn([key, 'filtersById'], arrayConcat, value)
          .setIn(x, { key: value, selected: false })
      } else {
        return obj
      }
    }, filter)
  return Object.keys(der).map(f => ({
    key: f,
    name: der[f].name,
    filters: der[f].filtersById.map(fs =>
      der[f].filters[fs]
    )
  }))
})

export const filteredDevicesSelector = createStructuredSelector({
  loading,
  reloading,
  filteredDevices,
  filters,
  totalDevices,
  stopLoading
})

export const filteredBatteriesSelector = createStructuredSelector({
  batteries: filteredBatteries,
  filters: filtersBatteries,
  loading: batteriesLoading
})
