import { SubscriptionAgreementActions } from 'features/subscription-agreement/subscription-agreement.reducer'
import React from 'react'
import { toast } from 'react-toastify'
import { all, call, put } from 'redux-saga/effects'
import { ErrorToast } from 'Themes/ScufStyledComponents'

export function* getSLAVerification(api) {
  const response = yield call(api.getSLAVerification)
  if (response.ok) {
    yield put(SubscriptionAgreementActions.initialSLASuccess(response.data))
  } else {
    yield all([
      put(SubscriptionAgreementActions.initialSLAFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}

export function* sendSLAValidation(api) {
  const response = yield call(api.sendSLAVerification)
  if (response.ok) {
    yield put(SubscriptionAgreementActions.initialSLAVerificationSuccess(response.data.updated))
  } else {
    yield all([
      put(SubscriptionAgreementActions.initialSLAVerificationFailure(response.error || '')),
      call(toast, <ErrorToast />)
    ])
  }
}
