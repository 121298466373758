import { Tooltip } from '@scuf/common'
import { DeviceTypeFromCellData } from 'Components/AssetManagementBase/components/device-name/device-name'
 
const renderSiteHierarchy = (value) => {
    const hierarchyTokens = value?.split('/')
    const siteName = hierarchyTokens?.slice(-1)[0].trim()
    return (
        <Tooltip
            element={<p>{siteName}</p>}
            content={value}
            size='small'
        />
  )
}
const renderStatus = (value, td) => {
    return value ? value : td('Devicetable_NotAvailable')
}
const renderValue = (value) => <div>{value}</div>

export const getColumns = (t, tr, td) => {
  return [
    { dataKey: 'assetType', field: 'assetType', translation: t('Type'), render: ({ cellData }) => DeviceTypeFromCellData({ cellData })},
    { dataKey: 'model', field: 'model', translation: t('Model'), render: ({ cellData }) => renderValue(cellData)},
    { dataKey: 'alias', field: 'alias', translation: t('Alias'), render: ({ cellData }) => renderValue(cellData)},
    { dataKey: 'deviceSerialNumber', field: 'deviceSerialNumber', translation: t('SerialNumber'), renderValue: ({ cellData }) => renderValue(cellData)},
    { dataKey: 'siteName', field: 'siteName', translation: t('Site'), render: ({ cellData }) => renderSiteHierarchy(cellData)},
    { dataKey: 'i18NStatus', field: 'i18NStatus', translation: t('UpdateStatus'), render: ({ cellData }) => renderValue(tr(cellData))},
    { dataKey: 'status', field: 'status', translation: t('Remarks'), render: ({ cellData }) => renderStatus(cellData, td)},
]
}