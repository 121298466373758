import styled from 'styled-components/macro'
import whiteLogo from 'Assets/honeywell-logo-white.svg'
import { isMobile } from 'Utils/constants'

const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background:${props => props.theme.background};
  color:${props => props.theme.sidebarcolor};
  & div {color:${props => props.theme.sidebarcolor};}
  & h1 {color:${props => props.theme.h1color}}
  & h2 {color:${props => props.theme.h2color}}
  & h4 {color:${props => props.theme.h4color}}
  & h3 {color:${props => props.theme.h1color}}
  & p {color:${props => props.theme.h1color}}

  & .copyright {color:${props => props.theme.h1color}}

  & .iMoQSY {color:${props => props.theme.h1color}}

  & .highcharts-tooltip-label {color:${props => props.theme.grayback}}

  & .highcharts-tooltip-value {color:${props => props.theme.grayback}}

  & .profile-icon {color:${props => props.theme.h1color}}

  & .profile-icon {background:${props => props.theme.background}}

  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown, .ui.scuf-dropdown-wrapper > .ui.multiple.selection.dropdown, .ui.scuf-dropdown-wrapper > .ui.dropdown.disabled
   {
       color:${props => props.theme.inputcolor};
       background-color:${props => props.theme.inputback};
       border:${props => props.theme.inputborder} }
  & .ui.dropdown > .text {
    color:${props => props.theme.inputcoloractive}
  }

  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    background-color:${props => props.theme.inputback};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item {
        color:${props => props.theme.inputcoloractive};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item:hover  {
        background-color:${props => props.theme.sideactivehover};
}
& .ui.active.visible.selection.dropdown .selected.item {
    background-color:${props => props.theme.sideactivehover};
}

 & .page-footer.ui.menu {
  background-color: ${props => props.theme.black};
  color:${props => props.theme.headercolor};
  border-top: ${props => props.theme.inputborder};
 }

  & .footer-menu a {
    color: ${props => props.theme.h1color}
  }

  & .ui.page-header .page-header-menu.ui.menu .main-logo {
    background: ${props => props.theme.logomain};
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-origin: content-box !important;
    background-position: center;
  }
  & .ui.page-header {
      background-color:${props => props.theme.backgroundheader};
      border-bottom: ${props => props.theme.headerborder}
    }
  & .ui.page-header .page-header-menu.ui.menu .item {
      color:${props => props.theme.headercolor}
  }
  & .ui.page-header .page-header-menu.ui.menu .item:hover {
    background-color:${props => props.theme.headerhover}
}
  & .ui.page-header .page-header-menu.ui.menu .logo-text {color: ${props => props.theme.logotextcolor}}
  & .ui.page-header .page-header-menu.ui.menu .user-profile-menu .menu > .item  {
    background: ${props => props.theme.inputback};
    color:${props => props.theme.h1color};
    border-bottom: ${props => props.theme.inputborder};
  }
  & .ui.page-header .page-header-menu.ui.menu .user-profile-menu .menu > .item:hover  {
    background: ${props => props.theme.headerhover}
  }
  & .ui.dropdown .menu {
      border: ${props => props.theme.inputborder}
  }
  & .ui.page-header .page-header-menu.ui.menu .item.active {
    background: ${props => props.theme.inputback};
  }
  & .sidebar-layout > .sidebar {
    background: ${props => props.theme.sidebarback}
    border-right: ${props => props.theme.sidebarborder}
    width: ${() => isMobile() ? '100vw' : '14.25rem'};
  }

  & .sidebar-layout.collapse > .sidebar {
    width: ${() => isMobile() ? '0vw' : '3.5rem'};
  }

  & .sidebar-layout > .sidebar .ui.vertical.menu {
    width: ${() => isMobile() ? '100vw' : '14.25rem'};
  }

  & .sidebar-layout > .sidebar .ui.menu .item > .sidebar-label {color: ${props => props.theme.sidebarcolor}}
  & .sidebar-layout > .sidebar .ui.menu .item.active {
      background: ${props => props.theme.sidebaractive};
      color:${props => props.theme.sidebarcoloractive}}
  & .sidebar-layout > .sidebar .submenu > .ui.menu .item.active {background:${props => props.theme.sidebaractive}}
  & .sidebar-layout > .sidebar .submenu.open {background: ${props => props.theme.sidebaractive}}
  & .sidebar-layout > .sidebar .ui.menu .item {color: ${props => props.theme.sidebaricons}}
  & .sidebar-layout > .sidebar .ui.menu .item:hover {color: ${props => props.theme.sidebaricons}}
  & .sidebar-layout > .sidebar .ui.menu .item:hover  {background:${props => props.theme.sideactivehover}}
  & .ui.vertical.menu .menu .item {background:${props => props.theme.sideactiveitem}}
  & .ui.vertical.menu .menu .item:hover {background:${props => props.theme.sideactivehover}}


  & i {color:${props => props.theme.iconscolor}}

  & div.ui.input-label .input-label-message {color:${props => props.theme.h4color}}
  & .ui.single-input .input-box {
    background: ${props => props.theme.inputback};
    color:${props => props.theme.h1color};
    border-color: ${props => props.theme.inputbordercolor}
  }


  & .dashboard-card {background-color: ${props => props.theme.dashboardcard} }


  & .ui.forge.chart-wrap.basic-chart .highcharts-data-labels.donut-series text, .ui.forge.chart-wrap.basic-chart .highcharts-data-labels.donut-series tspan {
    fill: ${props => props.theme ? props.themeDark ? props.theme.bodyfontColor : 'rgba(0,0,0,0.60)' : 'rgba(0,0,0,0.60)'}
  }

  & .ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody > tr:hover {
    background-color: ${props => props.theme.inputbordercolor} !important;
  }
  & .ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody {
    border-color: ${props => props.theme.inputbordercolor} !important;
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead th {
    background: ${props => props.theme.background};
    border-top:none !important;
    border-color: ${props => props.theme.inputbordercolor};
  }
  & .datatable > div {
    background:${props => props.theme.background};
    border-color: ${props => props.theme.inputbordercolor}
  }
  & .hKMNCo {
    background:${props => props.theme.background};
  }
  & .datatable > div > div > div {
    border-color: ${props => props.theme.inputbordercolor};
    color: ${props => props.theme.bodyfontcolor};
  }
  & .datatable > div > div > div > p{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .ui.radio.checkbox > label{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .ui.checkbox > label{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .datatable > div > div > div > div{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .kvmCUn:nth-child(odd){
    background: ${props => props.theme.black}
  }
  & .kvmCUn:nth-child(even){
    background: ${props => props.theme.background}
  }
  & .kvmCUn:nth-child(odd):hover{
    background: ${props => props.theme.sidebarborder} !important;
  }
  & .kvmCUn:nth-child(even):hover{
    background: ${props => props.theme.sidebarborder} !important;
  }
  & .user-table {
    background: ${props => props.theme.black}
  }
  & .Action-Bar > div > div > div > div {
    color: ${props => props.theme.bodyfontcolor}
  }
  & .Action-Bar > div > div > div  {
    background: ${props => props.theme.background}
  }

  & .debug-table .kvmCUn:nth-child(odd) {
    background:${props => props.theme.grayback};
  }
  & .debug-table .kvmCUn:nth-child(even) {background:${props => props.theme.black}}

  & .tools-item > div {
  background:transparent !important;
  }

  & .ui.checkbox label:before {
    background:${props => props.theme.background};
    border: ${props => props.theme.inputborder}
  }

  & .ui.search-wrap .ui.search.ui-search .prompt {
      background:${props => props.theme.inputback};
      color: ${props => props.theme.h1color};
      border: ${props => props.theme.inputborder}
  }
  & .ui.search-wrap .search-icon {
    background: ${props => props.theme.intercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item {
    color: ${props => props.theme.h1color};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item{
    color: ${props => props.theme.inactivecolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item {
    color: ${props => props.theme.tabcolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & .update-text > div > div {
      color:${props => props.theme.h1color};
  }

  & .ui.card {
    background-color:${props => props.theme.dashboardcard};
  }
  & .ui.card .header {
    color:${props => props.theme.h1color};
  }

  & .meta-content { color:${props => props.theme.secondarycolor}}

  & .ui.tree {
    background:${props => props.theme.sidebarback}
  }
  & .tree-content .tree-item .content.active {
    background:${props => props.theme.black}
  }

  & .tree-content .tree-item .content:hover {
    background:${props => props.theme.black}
  }
  & .child-open .tree-item has-children:hover {
    background:${props => props.theme.black}
  }
  & .site-form form > div {
    background-color:${props => props.theme.dashboardcard};
    border-right:${props => props.theme.inputborder}}
  & .user-permissions > div > div > button{
        background-color:${props => props.theme.tagbackground}}
  & .tree-container > div {
    background:${props => props.theme.dashboardcard}}
  & .tree-container {
      border:${props => props.theme.inputborder}}
  & .site-loader div {
    background-color:${props => props.theme.background};
    color:${props => props.theme.h1color}
  }
  & .device-card {
    background: ${props => props.theme.inputback}
  }
  & .device-info-card {
    background: ${props => props.theme.inputback}
  }
  & .hierarchy-container {
    background: ${props => props.theme.inputback}
  }
  & .ui.notification {
    background:${props => props.theme.background}}
  & .pane-container {
    background:${props => props.theme.background}
  }
  & .notification-table {
    background-color:${props => props.theme.inputback}
  }
  & .alerts-wrapper {
    background-color:${props => props.theme.inputback}
  }
  & .alerts-filter-container > div {
    background: ${props => props.theme.background}
  }

  & .badge-warning {
    color: #be271c !important;
  }

  & .badge-info {
    color: #1792e5 !important;
  }

  & .badge-alert {
    color: #ffc627 !important;
  }

  & .alerts-container .badge-warning {
    color: #be271c !important;
  }

  & .alerts-container .badge-info {
    color: #1792e5 !important;
  }

  & .alerts-container .badge-alert {
    color: #ffc627 !important;
  }

  & .notifications-wrapper .badge-warning {
    color: #be271c !important;
  }
  & .notifications-wrapper .badge-info {
    color: #1792e5 !important;
  }

  & .notifications-wrapper .badge-alert {
    color: #ffc627 !important;
  }

  & .dashboard-notification .badge-warning {
    color: #be271c !important;
  }
  & .dashboard-notification .badge-info {
    color: #1792e5 !important;
  }

  & .dashboard-notification .badge-alert {
    color: #ffc627 !important;
  }

  & .page-footer.ui.menu .link:hover {
    background: ${props => props.theme.inputback}
}

  & .highcharts-series-1  .highcharts-graph {
  stroke: ${props => props.theme.inputcolor};
}
  & .highcharts-series-2  .highcharts-graph {
  stroke: ${props => props.theme.inputcolor};
}


& .ui.forge-datatable-wrap > div > div {
  background: ${props => props.theme.inputback};
  border: ${props => props.theme.inputborder};
}
& .data-tablestyles__FilterContainer-sc-1mus3iu-8 {
  background: ${props => props.theme.background};
}

& .p-datatable .p-datatable-thead > tr {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th.p-sortable-column:hover {
  background: ${props => props.theme.black};
  border-color: ${props => props.theme.inputbordercolor};
}

& .p-datatable .p-datatable-thead > tr > th:hover {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th > span {
  color: ${props => props.theme.h1color};
}
& .p-datatable tbody > tr.p-datatable-row:nth-child(odd) {
  background-color: ${props => props.theme.black};
}

& .p-datatable tbody > tr.p-datatable-row:nth-child(even) {
  background-color: ${props => props.theme.background};
}

& .p-datatable .p-checkbox-box {
  background: ${props => props.theme.background};
  border: ${props => props.theme.inputborder};
}
& .ui.single-input .input-box {
  background: ${props => props.theme.inputback};
  color: ${props => props.theme.h1color};
  border-color: ${props => props.theme.inputbordercolor};
}
& .ui.single-input .search-icon {
  background: ${props => props.theme.intercolor};
}
& .p-datatable .p-datatable-tbody > tr {
  background: ${props => props.theme.background};
  color: ${props => props.theme.h1color};
}

& .p-datatable .p-datatable-header .total-count {
  color: ${props => props.theme.h1color};
}


& .ui.scuf-dropdown-wrapper > div > input {
  color: ${props => props.theme.bodyfontcolor};
}
`

const themeDark = {
  black: '#000 !important',
  background: '#202020 !important',
  headerborder: '#606060 0.0625rem solid !important',
  backgroundheader: '#000',
  bodyfontcolor: '#fff !important',
  h1color: '#fff !important',
  h2color: '#f7f7f7 !important',
  h4color: '#c0c0c0',
  headercolor: '#fff',
  headerhover: '#202020 !important',
  logotextcolor: '#fff',
  sidebarback: '#000',
  sidebarcolor: '#fff',
  sidebarborder: '#404040',
  sidebaractive: '#303030',
  iconscolor: '#fff !important',
  sidebaricons: '#fff',
  sidebarcoloractive: '#fff',
  sideactiveitem: '#303030',
  sideactivehover: '#202020 !important',
  inputborder: '#707070 0.0625rem solid',
  inputback: '#303030 !important',
  inputcolor: '#e0e0e0 !important;',
  inputcoloractive: '#fff !important',
  dashboardcard: '#303030',
  textcontrast: '#c0c0c0 !important',
  inputbordercolor: '#707070',
  grayback: '#303030 !important',
  intercolor: '#707070',
  inactivecolor: '#606060',
  tabcolor: '#d0d0d0',
  hovercolor: '#f7f7f7',
  secondarycolor: '#f0f0f0',
  tagbackground: '#606060 !important',
  logomain: `url(${whiteLogo})`,
  boxShadow: '0px 1px 0px #404040',
  darkBorder: '1px solid #404040'
}

const themeLight = {}

export { BodyWrapper, themeDark, themeLight }
