import { connect } from 'react-redux'
import { compose } from 'ramda'
import SubscriptionAgreementContent from './subscription-agreement-content'
import LoginActions from 'Redux/LoginRedux'
import { translate } from 'react-translate'
import { SubscriptionAgreementActions } from '../../subscription-agreement.reducer'
import { getSLAInfoSelector } from '../../subscription-agreement.selector'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => (
  {
    ...getSLAInfoSelector(state),
  }
)

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(LoginActions.loginLogoutRequest()),
  onAccept: () => dispatch(SubscriptionAgreementActions.initialSLAVerificationRequest()),
})

export default compose(
  translate('SubscriptionAgreement'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SubscriptionAgreementContent)
