import { call, put, select } from 'redux-saga/effects'
import UserActions from 'Redux/UserRedux'
import { history } from 'Components/Navigation'
import { devicesFilter } from 'Utils/constants'

const roles = ['isToolsViewer', 'isTCOViewer', 'isHaaSViewer', 'isHaaSAdmin']

export function * userInfo (api, action) {
  const response = yield call(api.userInfo)
  if (response.ok) {
    yield put(UserActions.userSuccess({ ...response.data?.data }))
  } else {
    yield put(UserActions.userFailure())
  }
}

export function * userDetails (api, action) {
  const response = yield call(api.userDetails)
  if (response.ok) {
    yield put(UserActions.userDetailSuccess(response.data || {}))

    const role = yield select(state => state.user.userRole)

    if (roles.includes(role)) {
      history.push('/tools')
    }
    devicesFilter.map( async (type) => {
      await api.postFilters({section: type, search: "", userId: response.data.id})
    })
  } else {
    yield put(UserActions.userFailure())
  }
}
