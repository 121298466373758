import { types } from "util"

const create = (api) => {

  const bulkCreateBeacons = (siteId, file) => {
    const formData = new FormData()
    formData.append('file', file)
    return api.post(`/beacons:bulkCreate?siteId=${siteId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const createBeacon = (beacon) => api.post(`/beacons`, beacon)

  const getBeacon = (id) => api.get(`/beacons/${id}`)

  const listBeacons = (filterName, ids, type = null, pageSize, pageNumber, searchFilter) => {
    let filter = { [filterName]: { '$in': ids } }
    if (searchFilter != null && searchFilter.length > 0) {
      filter = {
        ...filter,
        '$freeform': [searchFilter]
      }
    }
    const filterJson = JSON.stringify(filter)
    if (type) {
      return api.get(`/beacons?filter=${filterJson}&type=${type}&pageSize=${pageSize}&pageNumber=${pageNumber}`)

    }
    return api.get(`/beacons?filter=${filterJson}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
  }

  const updateFriendlyName = (id, beacon) => api.patch(`/beacons/${id}`, beacon)

  const updateBeacon = (id, beacon) => api.put(`/beacons/${id}`, beacon)

  const deleteBeacon = (id) => api.delete(`/beacons/${id}`)

  const bulkDeleteBeacons = (filterName, ids) => api.delete(`/beacons:bulkDelete`, null, { data: { [filterName]: ids } })

  return {
    bulkCreateBeacons,
    createBeacon,
    getBeacon,
    listBeacons,
    updateFriendlyName,
    updateBeacon,
    deleteBeacon,
    bulkDeleteBeacons
  }
}

export default {
  create
}
