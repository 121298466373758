import PreferencesEmail from './components/preferences-email'
import PreferencesAlerts from './components/preferences-alerts'
import PreferencesInstantAlerts from './components/preferences-instant-alerts'

const PREFERENCES_TABS = [
  {
    key: 'alerts-tab',
    title: 'Alerts',
    condition: 'instantAlertConfiguration',
    component: PreferencesInstantAlerts
  },
  {
    key: 'email-notifications-tab',
    title: 'EmailNotifications',
    condition: '',
    component: PreferencesAlerts
  },
  {
    key: 'email-report-tab',
    title: 'EmailReport',
    condition: '',
    component: PreferencesEmail
  }
]

export default PREFERENCES_TABS
