import styled from 'styled-components/macro'

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  padding: 1rem;
  gap: 1rem;
  right: 0;
  bottom: 0;
  min-width: 100%;
`
export const LeftContainerWrapper = styled.div`
  display: flex;
  flex: 1;
`
export const RightContainerWrapper = styled.div`
  display: flex;
  flex-growth: 0;
`
export const ErrorMessageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  color: #be271c; 
  `