import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from "../../../../../Components/AssetManagementTable"
import { ModalWrap, ModalTitle } from './device-by-site-modal.styles'

export const columns = [
  { field: 'key', translation: 'AssetManagementTable_deviceSite', render: null },
  { field: 'value', translation: 'AssetManagementTable_modelCount', render: null },
  { field: 'percentage', translation: 'AssetManagementTable_percentage', render: ({ value }) => <p>{value} %</p> }
]

const DevicesBySiteModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('DevicesBySite_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable
        columns={columns}
        data={data}
        disableRowClick
        fileName={t('DevicesBySite_title')}
      />
    </Modal.Content>
  </ModalWrap>
)

export default DevicesBySiteModal
