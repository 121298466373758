import PropTypes from 'prop-types'
import React, { Fragment, memo } from 'react'
import { useTranslate } from 'react-translate'
import { FilterNonApplied, FilterResetAll } from './filter.styles'

const FilterPanel = memo(({ onReset, children, count }) => {
  const t = useTranslate('ConnectionStatus')
  return (
    <Fragment>
      {count
        ? <FilterResetAll onClick={onReset}>
          {t('resetFilters')}
        </FilterResetAll>
        : <FilterNonApplied>
          {t('filters')}
        </FilterNonApplied>
      }
      {children}
    </Fragment>
  )
})

FilterPanel.propTypes = {
  onReset: PropTypes.func,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ])
}

FilterPanel.defaultProps = {
  type: 'FilterPanel',
  onReset: () => null
}

export default FilterPanel
