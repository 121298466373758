import React from 'react'
import { Modal } from '@scuf/common'
import { ModalWrap, ModalTitle } from './device-usage-modal.styles'
import AssetManagementTable from '../../../../AssetManagementTable'
import PropTypes from 'prop-types'

export const columns = [
  { field: 'appName', translation: 'AssetManagementTable_appName', render: ({ value }) => <p>{value}</p>, initialWidth:'22%' },
  { field: 'pkgName', translation: 'AssetManagementTable_appPackageName', render:({ value }) => <p>{value}</p>, initialWidth:'30%' },
  { field: 'version', translation: 'AssetManagementTable_version', render: ({ value }) => <p>{value}</p> },
  { field: 'useTime', translation: 'AssetManagementTable_useTime', render: ({ value }) => <p>{value}</p> }
]

const DeviceUsageModal = ({ data, closeModal, t }) => {
  return (
    <ModalWrap>
      <ModalTitle>{t('AppUseChart_Title')}</ModalTitle>
      <Modal.Content>
        <AssetManagementTable
          columns={columns}
          data={data}
          disableRowClick={true}
          fileName='Application Usage Time'
          selectionMode='none'
        />
      </Modal.Content>
    </ModalWrap>
  )
}
DeviceUsageModal.defaultProps = {
  closeModal: () => null,
  data: []
}

DeviceUsageModal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.array,
  t: PropTypes.func
}

export default DeviceUsageModal
