import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  alertsRequest: ['siteId', 'pageNumber', 'pageSize'],
  alertsSuccess: ['data', 'total'],
  unreadAlertsRequest: ['siteId', 'pageNumber', 'pageSize'],
  unreadAlertsSuccess: ['data', 'total'],
  alertsFailure: null,
  alertsToggle: ['alertId'],
  alertsUnselectAlerts: [],
  alertsSelectAll: ['checked', 'alertIds'],
  alertsMarkReadRequest: ['read', 'alerts'],
  alertsMarkReadRequestSuccess: null,
  alertsMarkReadRequestFailure: ['alerts'],
  alertsCleanSelectedAlerts: ['alerts'],
  alertsCleanSelectedAlertsSuccess: ['alerts'],
  alertsCleanSelectedAlertsFailure: null,
  alertsCleanRecommended: ['alerts'],
  alertsCleanRecommendedSuccess: ['alerts'],
  alertsCleanRecommendedFailure: null
})

export const AlertsTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  siteId: '',
  loading: false,
  alerts: [],
  unreadAlerts: [],
  totalAlerts: 0,
  totalUnreadAlerts: 0
})

export const unselectAlerts = (state) =>
  state.merge({
    alerts: state.alerts.map(alert => ({ ...alert, selected: false }))
  })

export const alertsRequestAlerts = (state) =>
  state.merge({
    loading: true
  })

  export const unreadAlertsRequest = (state) =>
  state.merge({
    loading: true
  })

export const alertsFailureAlerts = (state) =>
  state.merge({
    loading: false,
    error: true
  })

export const alertsSuccessAlerts = (state, { data, total }) =>
  state.merge({
    alerts: data,
    totalAlerts: total,
    loading: false,
    error: false
  })

  export const unreadAlertsSuccess = (state, { data, total }) =>
  state.merge({
    unreadAlerts: data,
    totalUnreadAlerts: total,
    loading: false,
    error: false
  })

export const toggleSelect = (state, { alertId }) => {
  const toggleAlert = state.alerts.map(alert =>
    ({ ...alert, selected: alert.id === alertId ? !alert.selected : alert.selected }))
  return state.merge({
    alerts: toggleAlert,
    loading: false,
    error: false
  })
}
/**
 * Mark the passed alerts as selectedor not selected
 * @param {prevoous state} state
 * @param { {checked: bool , alertIds: set<number> } } params
 */
export const selectAllAlerts = (state, { checked, alertIds }) =>
  state.merge({
    alerts: state.alerts.map(alert => ({
      ...alert,
      selected: alertIds.has(alert.id) ? checked : alert.selected
    })),
    loading: false,
    error: false
  })

export const markAsReadRequest = (state, { read }) => {
  const changedStatusAlerts = state.alerts.map(alert =>
    ({
      ...alert,
      selected: false,
      status: alert.selected ? (read ? 'read' : 'unread') : alert.status
    }))
  return state.merge({
    loading: true,
    error: false,
    alerts: changedStatusAlerts
  })
}

export const markAsReadRequestSuccess = state =>
  state.merge({
    loading: false,
    error: false
  })

export const markAsReadRequestFailure = (state, { alerts }) => {
  const alertsIds = new Set(alerts.map(alert => alert.id))
  const changedStatusAlerts = state.alerts.map(alert =>
    ({
      ...alert,
      selected: false,
      status: alertsIds.has(alert.id) ? 'unread' : alert.status
    }))
  return state.merge({
    alerts: changedStatusAlerts,
    loading: false,
    error: true
  })
}

export const cleanSelectedAlerts = state => state.merge({
  alerts: state.alerts.map(alert => ({
    ...alert, selected: false
  })),
  loading: false,
  error: false
})

export const cleanSelectedAlertsSuccess = (state, { alerts }) => {
  const alertsIds = new Set(alerts)
  return state.merge({
    alerts: state.alerts.filter(alert => !alertsIds.has(alert.id)),
    loading: false,
    error: false
  })
}

export const cleanSelectedAlertsFailure = state =>
  state.merge({
    error: true,
    loading: false
  })

  export const cleanAlertsRecommended = state => state.merge({
    alerts: state.alerts.map(alert => ({
      ...alert, selected: false
    })),
    loading: true,
    error: false
  })

  export const cleanAlertsRecommendedSuccess = (state, { alerts }) => {
    const alertsIds = new Set(alerts)
    return state.merge({
      alerts: state.alerts.filter(alert => !alertsIds.has(alert.id)),
      loading: false,
      error: false
    })
  }

  export const cleanAlertsRecommendedFailure = state =>
    state.merge({
      error: true,
      loading: false
    })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ALERTS_REQUEST]: alertsRequestAlerts,
  [Types.ALERTS_SUCCESS]: alertsSuccessAlerts,
  [Types.ALERTS_FAILURE]: alertsFailureAlerts,
  [Types.ALERTS_TOGGLE]: toggleSelect,
  [Types.ALERTS_SELECT_ALL]: selectAllAlerts,
  [Types.ALERTS_MARK_READ_REQUEST]: markAsReadRequest,
  [Types.ALERTS_MARK_READ_REQUEST_SUCCESS]: markAsReadRequestSuccess,
  [Types.ALERTS_MARK_READ_REQUEST_FAILURE]: markAsReadRequestFailure,
  [Types.ALERTS_CLEAN_SELECTED_ALERTS]: cleanSelectedAlerts,
  [Types.ALERTS_CLEAN_SELECTED_ALERTS_SUCCESS]: cleanSelectedAlertsSuccess,
  [Types.ALERTS_CLEAN_SELECTED_ALERTS_FAILURE]: cleanSelectedAlertsFailure,
  [Types.ALERTS_UNSELECT_ALERTS]: unselectAlerts,
  [Types.ALERTS_CLEAN_RECOMMENDED]: cleanAlertsRecommended,
  [Types.ALERTS_CLEAN_RECOMMENDED_SUCCESS]: cleanAlertsRecommendedSuccess,
  [Types.ALERTS_CLEAN_RECOMMENDED_FAILURE]: cleanAlertsRecommendedFailure,
  [Types.UNREAD_ALERTS_REQUEST]: unreadAlertsRequest,
  [Types.UNREAD_ALERTS_SUCCESS]: unreadAlertsSuccess,
})
