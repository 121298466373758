import { createStructuredSelector, createSelector } from 'reselect'
import { groupBySiteAndSumValue } from '../../../OfflineDevicesBySite/offline-devices-by-site.selector'
import { getTopSeriesByKey } from '../../../OfflineDevicesByModel/components/OffileDevicesByModelChart/offline-devices-by-model-chart.helper'

export const currentCulture = state => state.user.language

export const totalDevices = ({ dashboard }) => dashboard.getIn(['totalDevicesStatus'], [])

export const filteredDevices = createSelector(
  [totalDevices],
  devicesStatus => {
    return devicesStatus.getIn(['data'], [])
      .filter(device => device.property !== '-1')
      .map(device => {
        const sitePath = (device.member || '').split('/')
        if (sitePath.length) {
          return { ...device, site: sitePath[sitePath.length - 1] }
        } else {
          return device
        }
      })
  }
)

export const groupedDevices = createSelector(
  [filteredDevices],
  devices => groupBySiteAndSumValue(devices)
)

export const series = createSelector(
  [groupedDevices, currentCulture],
  (devices, currentCulture) => {
    return getTopSeriesByKey(
      Object.keys(devices).map(type => ({ key: type, value: devices[type] })), 4, currentCulture)
  }
)

export const total = createSelector(
  [groupedDevices],
  devices => Object.keys(devices).reduce((sum, key) => sum + devices[key], 0)
)

export default createStructuredSelector({
  series,
  total,
  currentCulture
})
