import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import PrinterFaultyDots from './printer-faulty-dots-modal'
import PrinterFaultyDotsSelector from './printer-faulty-dots-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    PrinterFaultyDotsSelector,
    mapDispatchToProps
  )
)(PrinterFaultyDots)
