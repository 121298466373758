import styled from 'styled-components'

export const GeneralContainer = styled.div`
display: flex;
margin: 2rem;
flex-direction: column;
`

export const Title = styled.h1`
font-weight: normal;
margin-top: -1rem;
`

export const SubTitle = styled.label`
`

export const OptionsContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 1rem 0rem;
border: 1px solid #c0c0c0;
padding: 0.5rem 0.5rem;
`
export const SubOptionsContainer = styled.div`
display: flex;
justify-content: ${props => props.subOptionAlign ? props.subOptionAlign : 'space-between'};
border: 1px;
padding: 0.5rem 0.5rem;
width: ${props => props.subOptionWith ? props.subOptionWith : '50%'};
`

export const LabelSelection = styled.label`
min-width: fit-content;
align-self: start;
margin: 0.5rem;
`

export const OptionsColumn = styled.div`
display: flex;
flex: 1;
flex-wrap: wrap;
align-items: center;
justify-content: ${props => props.datePicker ? 'flex-end' : 'space-between'}
`

export const FooterTable = styled.div`
position: absolute;
right: 0px;
display: flex;
margin-top: .5rem;

`

export const Badge = styled.div`
background-color: ${props => props.color};
width: 1rem;
height: 1rem;
border-radius: 50px;
margin: 0.2rem 0.5rem 0.5rem 0.5rem;
`

export const SignalContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
min-width: max-content;
`

export const BadgeContainer = styled.div`
display: flex;
min-width: max-content;
`

export const RowItemColor = styled.div`
background-color: ${props => props.colorItem};
display: flex;
justify-content: center;
color: ${props => props.colorLetter ? props.colorLetter : 'white'};
`


export const DatePickerContainer = styled.div`
  display: flex;
  margin-right: 0.5em;

  & .ui.date-picker:last-child {
    margin-bottom: 0 !important;
  }
  & .ui.single-input.has-icon.align-right .input-box {
    margin-bottom: 0;
  }
  & .ui.date-picker-input.fluid {
    width: 100%;
  }
  & .below-text{
    min-height: 0rem !important;
    margin: 0 !important;
  }
  & .date-picker-footer{
    display: none !important
  }
`

export const LoaderContainer = styled.div`
display: flex;
justify-content: center;
font-size: 1.5rem;
margin: 3rem;
flex-direction: column;
align-items: center;
`

export const NoDataContainer = styled.div`
display: flex;
justify-content: center;
font-size: 2rem;
margin: 4rem;
`