import { createSelector, createStructuredSelector } from 'reselect'

export const ranges = ({ accessPoints }) => accessPoints.getIn(['accessPointRanges'], {})


export const goodRanges = createSelector( 
  [ranges],
  (range) => {
    return range['good']
  }
)

export default createStructuredSelector({
  ranges,
  goodRanges
})
