export const lang = ({ user }) => user.getIn(['language'], 'en-US')

export const selectedTheme = ({ user }) => {
  const theme = user.getIn(['theme'], {})
  return Object.keys(theme).length ? 'dark' : 'light'
}

export const selectedUnitSystem = ({ user }) => user.getIn(['unitSystem'], 'metric')

export const languages = ({ flags }) => {
  const chinese = flags.multilanguageZh ? { text: 'Chinese', value: 'zh-CN' } : null
  const english = flags.multilanguageEn ? { text: 'English', value: 'en-US' } : null
  const french = flags.multilanguageFr ? { text: 'French', value: 'fr-FR' } : null
  const german = flags.multilanguageDe ? { text: 'German', value: 'de-DE' } : null
  const spanish = flags.multilanguageEs ? { text: 'Spanish', value: 'es-MX' } : null
  const korean = flags.multilanguageKo ? { text: 'Korean', value: 'ko-KR' } : null
  const japanese = flags.multilanguageJa ? { text: 'Japanese', value: 'ja-JA' } : null
  const thai = flags.multilanguageTh ? { text: 'Thai (Thailand)', value: 'th-TH' } : null
  const portuguese = flags.multilanguagePt ? { text: 'Portuguese', value: 'pt-BR' } : null

  return [chinese, english, french, german, japanese, korean, portuguese, spanish, thai].filter(value => value)

}

export default (state, props) => {
  return {
    lang: lang(state),
    selectedTheme: selectedTheme(state),
    languages: languages(props),
    selectedUnitSystem: selectedUnitSystem(state)
  }
}
