import React from 'react'
import styled from 'styled-components/macro'
import { translate } from 'react-translate'

export const Li = styled.li`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  &:before {
    color: ${props => props.leave ? 'transparent' : '#B0B0B0'};
    font-family: 'Honeywell-Global' !important;
    font-size: 16px;
    content: '${props => props.open ? '\\E90B' : '\\E90D'}';
    margin-right: 13px;
    cursor: pointer;
  }
`
export const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  padding-left: 0.75rem;
`

const SCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border: 1px solid ${prop => prop.checked ? 'transparent' : '#707070'};
  opacity: 1;
  background: ${prop => prop.checked ? '#1792E5' : 'white'};
  cursor: pointer;
  &:after {
    content: '\\E90F';
    color: white !important;
    font-family: "Honeywell-Global";
    font-size: 13px;
    text-align: center;
  }
`

const Label = styled.span`
  padding-left: ${props => props.padded ? '0.75rem' : '0'};
`

function TreeNode ({
  label,
  nodes,
  onExpanded,
  onChange,
  id,
  expanded,
  selected,
  t,
  translateLabel
}) {
  const open = expanded.includes(id)
  const check = selected && selected.includes(id)
  return (
    <div>
      <Li
        leave={!nodes || nodes.length < 1}
        open={open}
        onClick={() => !nodes || nodes.length < 1
          ? onChange({
            checked: !check,
            id,
            label
          })
          : onExpanded(id)
        }
      >
        {!nodes && id
          ? <SCheckbox checked={check} />
          : null
        }
        <Label
          padded={!nodes}
          className='pointer'
        >
          {translateLabel ? !t(label).startsWith('TreeNode.') ? t(label) : label : label}
        </Label>
      </Li>
      {open && nodes
        ? <Ul>
          {nodes.map((n, i) => (
            <TreeNode
              key={i}
              {...n}
              onChange={(checked) => onChange({
                checked,
                id: n.id,
                label: n.label
              })}
              onExpanded={onExpanded}
              expanded={expanded}
              selected={selected}
              t={t}
            />
          ))}
        </Ul>
        : null
      }
    </div>
  )
}

export default translate('TreeNode')(TreeNode)
