import storage from 'redux-persist/lib/storage'

import immutablePersistenceTransform from 'Utils/PersistorTranform'
import config from './AppConfig'

const REDUX_PERSIST = {
  key: 'root',
  storage,
  whitelist: [
    'login',
    'user',
    'checkInOut',
    'collapsable'
  ],
  blacklist: [
    'ssoOauth',
    'permissions'
  ],
  transforms: [immutablePersistenceTransform],
  debug: config.debug
}

export default REDUX_PERSIST
