import { pipe } from 'ramda'
import { reduxForm } from 'redux-form'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import AssetInfo from './asset-info'

export default pipe(
  reduxForm({
    form: 'softwareUpdateWizard',
    destroyOnUnmount: false
  }),
  withLDConsumer()
)(AssetInfo)
