import { connect } from 'react-redux'
import ModalActions from '../../../../../Redux/ModalRedux'
import TypesOfModelModal from './types-of-model-modal'
import TypesOfModelModalSelector from './types-of-model-modal.selector'
import { pipe } from 'ramda'
import { translate } from 'react-translate'

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(ModalActions.modalClose())
  }
}

export default pipe(
  translate('CardsModals'),
  connect(
    TypesOfModelModalSelector,
    mapDispatchToProps
  )
)(TypesOfModelModal)
