import { range } from 'lodash'
import moment from 'moment'

export const FREQUENCIES = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
}

export const DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
}

export const getUsertTimeZone = () => moment.tz.guess()

export const isDateGreaterThan = (date1, date2) => {
  return moment(date1).isAfter(date2) ? 'FormInput_DateNotGreaterThan' : ''
}

export const validate = values => {
  const errors = {}
  if (values.confReveiceDaysEnd &&
    values.confReceiveDaysStart &&
    !values.confReveiceDaysEndCheck &&
    !isDateGreaterThan(values.confReveiceDaysEnd, values.confReceiveDaysStart)) {
    errors.confReveiceDaysEnd = 'End date must be greater than start date'
  }

  if (values.confReceiveDaysStart && !values.confReveiceDaysEndCheck && !values.confReveiceDaysEnd) {
    errors.confReveiceDaysEnd = 'End date required'
  }

  if ((values.confSites || []).length === 0) {
    errors.confSites = 'You must select at least one site'
  }

  if (!values.editing && (values.reportNames || []).indexOf(values.confName) !== -1) {
    errors.confName = 'FormInput_uniqueField'
  }
  return errors
}
