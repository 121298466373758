export const ENTERPRISE_DATA_RESET = 'EnterpriseDataReset'
export const FULL_FACTORY_RESET = 'FullFactoryReset'
export const FULL_FACTORY_RESET_KEY = 'Restore'
export const FULL_FACTORY_RESET_OPTION1 = 'SystemSettings'
export const FULL_FACTORY_RESET_OPTION2 = 'NetworkSettings'
export const FULL_FACTORY_RESET_OPTION3 = 'UserInstalledFiles'
export const REBOOT = 'Reboot'
export const ON_DEMAND_TELEMETRY = 'OnDemandTelemetry'
export const PULL_DEVICE_LOGS = 'PullDeviceLogs'
export const ENTERPRISE_DATA_RESET_COMMAND = 'DEVCOMMAND-ENTERPRISE_RESET'
export const FULL_FACTORY_RESET_COMMAND = 'DEVCOMMAND-FACTORY_RESET'
export const REBOOT_COMMAND = 'DEVCOMMAND-REBOOT'
export const ON_DEMAND_TELEMETRY_COMMAND = 'DEVCOMMAND-ONDEMAND_TELEMETRY'
export const GET_LATEST_TELEMETRY = 'LAST_TELEMETRY'
export const GET_LAST_HEARTBEAT = 'LAST_HEARTBEAT'
export const PRINT_TEST_PAGE = "PrintTestPage"
export const PRINT_TEST_PAGE_COMMAND = "DEVCOMMAND-PRINT_TEST_PAGE"
export const PRINT_TEST_PAGE_KEY = "TestLabel"
export const PRINT_TEST_PAGE_OPTION1 = "basic_config"
export const PING = "DEVCOMMAND-PING"
export const INV_SCAN = "DEVCOMMAND-INV_SCAN"
export const PULL_CONFIG = "DEVCOMMAND-PULL_CONFIG"
export const START_APP = "DEVCOMMAND-START_APP"
export const UPSIF1_APP = "UPSIF1"
export const PkgCarML_APP = "PkgCarML"
export const GET_IMAGE_BUFFER_COMMAND = "DEVCOMMAND-GET_IMAGE_BUFFER"
export const REQUESTFILE_COMMAND="DEVCOMMAND-REQUEST_FILE"
export const DEVICE_COMMAND_SUCCESSFUL = "Device command successful"
export const START_ADVANCED_LOGGING = "StartAdvancedLogging"
export const STOP_ADVANCED_LOGGING = "StopAdvancedLogging"
export const START_ADVANCED_LOGGING_COMMAND = "DEVCOMMAND-ENABLE_ADV_LOGGING"
export const STOP_ADVANCED_LOGGING_COMMAND = "DEVCOMMAND-DISABLE_ADV_LOGGING"
