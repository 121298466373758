import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm, FormSection, FieldArray, Form } from 'redux-form'
import { isEmpty } from 'ramda'
import { translate } from 'react-translate'
import get from 'lodash/get'

import SiteManagerActions from '../Redux/SiteManagerRedux'
import ModalActions from '../Redux/ModalRedux'
import { isRequired, noSpecialCharacters, isMax64, isMax25 } from './Validators'
import { SiteFormSelector } from '../Selectors/SiteSelector'
import FormInput from '../Components/FormComponents/FormInput'
import Error from '../Components/Error'
import { renderEmailsT as renderEmails, renderPhonesT as renderPhones, renderAddressesT as renderAddresses } from './FormSections'

import {
  FormRow,
  FormInputContainer,
  Subtitle,
  Title
} from '../Themes/ScufStyledComponents'

import { InputLabel, Search, Radio, Button, Modal } from '@scuf/common'

export class BaseSiteForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOrg: (isEmpty(props.parentSite) || !props.parentSite),
      results: props.sites,
      sites: props.sites,
      title: '',
      disabled: true,
      currentSite: ''
    }
    this._changeResults = this._changeResults.bind(this)
  }

  componentDidMount () {
    if (isEmpty(this.props.sites) && this.props.edit) {
      this.props.getSites()
    } else if (!this.props.edit) {
      this.props.setCurrentSite('')
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const enabled = (this.props.valid && !this.props.submitting) && this.props.anyTouched
    const wasEnabled = (prevProps.valid && !prevProps.submitting && prevProps.anyTouched) || !prevState.disabled

    if (enabled !== wasEnabled) {
      this.setState({ disabled: !enabled })
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const t = get(nextProps, 'formData.values.organizationalUnitIdentifier.organizationalUnitName', '')
    const pUid = get(nextProps, 'parentSite.organizationalUnitGuid', '')
    const currentParentUid = get(nextProps, 'initialValues.parentIdentifier.organizationalUnitGuid', '')
    const isNew = isEmpty(nextProps.initialValues) || !nextProps.initialValues

    const isOrg = isNew
      ? !pUid
      : !currentParentUid

    const title = isOrg
      ? t.toUpperCase() || 'Organization Details'
      : `${get(nextProps, 'parentSite.organizationalUnitHierarchy', '')} / ${t.toUpperCase()}`

    return {
      sites: nextProps.sites,
      title,
      isOrg
    }
  }

  _changeResults (term) {
    const filteredResults = this.state.sites.filter(item => get(item, 'title', '').toLowerCase().includes((term || '').toLowerCase()))
    this.setState({ results: filteredResults })
  }

  render () {
    const { results, isOrg, disabled, title, currentSite } = this.state
    const { handleSubmit, reset, parentSite, setParentSite, isHoneywellAdmin,
      createSite, updateSite, closeModal, initialValues, loading, t, edit, setCurrentSite } = this.props

    const pUid = get(this.props, 'parentSite.organizationalUnitGuid', '')

    return (
      <Form
        id='siteForm'
        autoComplete='off'
        onSubmit={handleSubmit((data) => {
          if (loading || (edit && isEmpty(initialValues))) {
            return null
          }
          if (!edit) {
            createSite(data, pUid)
          } else {
            const parent = pUid || get(initialValues, 'parentIdentifier.organizationalUnitGuid', '')
            const guid = get(initialValues, 'organizationalUnitIdentifier.organizationalUnitGuid', '')
            updateSite(data, guid, parent)
          }
          reset()
        })}
      >
        <Modal.Header>
          <div style={{ width: '100%' }}>
            <FormRow justifyContent='space-between'>
              <Title>{title}</Title>
              {edit
                ? <Search
                  results={results}
                  ref={this._searchBox}
                  placeholder={t('SiteForm_MissingSelection')}
                  onSearchChange={this._changeResults}
                  onResultSelect={(site) => setCurrentSite(site.id)}
                />
                : null
              }
            </FormRow>
          </div>
        </Modal.Header>
        {loading || (edit && isEmpty(initialValues))
          ? <Error loading={loading}>{t('SiteForm_MissingSelection')}</Error>
          : <Fragment>
            <div className='scrollable' style={{ height: edit ? 'calc(80vh - 10rem)' : 'calc(80vh - 8rem)' }}>
              <FormRow>
                <FormRow flex={1}>
                  <FormInputContainer>
                    <FormRow>
                      <Radio
                        label='Organization'
                        name="RadioGroup"
                        checked={!!isOrg}
                        onChange={() => {
                          setParentSite('')
                        }}
                        disabled={!isHoneywellAdmin}
                      />
                      <Radio
                        label='Site'
                        name="RadioGroup"
                        checked={!isOrg}
                        onChange={() => {
                          setParentSite(results[0]
                            ? results[0].id
                            : '')
                        }}
                      />
                    </FormRow>
                  </FormInputContainer>
                  <FormInputContainer flex={5}>
                    {/* <InputLabel label='Parent Organization' /> */}
                    <Search
                      disabled={!!isOrg}
                      ref={this._searchBox}
                      placeholder={parentSite.organizationalUnitHierarchy || 'Select a Parent Organization'}
                      onSearchChange={this._changeResults}
                      onResultSelect={(site) => {
                        setParentSite(site.id)
                        this.setState({ disabled: false, currentSite: site.title })
                      }}
                      value={currentSite}
                      results={results}
                    />
                  </FormInputContainer>
                </FormRow>
              </FormRow>
              <FormRow>
                <FormSection name='organizationalUnitIdentifier'>
                  <FormInputContainer flex={1}>
                    <InputLabel
                      label={t('SiteForm_Name')}
                      htmlFor='organizationalUnitName' />
                    <Field
                      placeholder={t('SiteForm_Name')}
                      name='organizationalUnitName'
                      id='organizationalUnitName'
                      component={FormInput}
                      type='text'
                      validate={[isRequired, noSpecialCharacters, isMax25]}
                      required
                    />
                  </FormInputContainer>
                </FormSection>
                <FormSection name='organizationalUnitDetails'>
                  <FormInputContainer flex={2}>
                    <InputLabel
                      label={t('SiteForm_Description')}
                      htmlFor='organizationalUnitDescription' />
                    <Field
                      placeholder={t('SiteForm_Description')}
                      name='organizationalUnitDescription'
                      id='organizationalUnitDescription'
                      component={FormInput}
                      type='text'
                      validate={[isRequired, noSpecialCharacters, isMax64]}
                      required
                    />
                  </FormInputContainer>
                </FormSection>
              </FormRow>
              <FormSection name='contactInformation'>
                <FieldArray
                  name='addresses'
                  component={renderAddresses} />
                <FormRow>
                  <Subtitle padding='0.5em'>{t('SiteForm_ContactInfo')}</Subtitle>
                </FormRow>
                <FormRow style={{ alignItems: 'flex-start' }}>
                  <FieldArray
                    name='emailAddresses'
                    props={{
                      forceEnable: () => this.setState({ disabled: false })
                    }}
                    component={renderEmails} />
                  <FieldArray
                    name='telephoneNumbers'
                    props={{
                      forceEnable: () => this.setState({ disabled: false })
                    }}
                    component={renderPhones} />
                </FormRow>
              </FormSection>
            </div>
            <div style={{ position: 'absolute', padding: '1em', right: 0, bottom: 0 }}>
              <Button
                type='secondary'
                actionType='button'
                content={t('SiteForm_Cancel')}
                onClick={closeModal}
              />
              <Button
                type='primary'
                actionType='submit'
                content={t('SiteForm_Submit')}
                disabled={disabled}
              />
            </div>
          </Fragment>
        }
      </Form>
    )
  }
}

BaseSiteForm.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  // header: PropTypes.node,
  t: PropTypes.func,
  loading: PropTypes.bool
}

BaseSiteForm.defaultProps = {
  parentSite: {},
  sites: [],
  setParentSite: () => null,
  t: () => null,
  loading: false,
  handleSubmit: () => null
}

const mapDispatchToProps = dispatch => ({
  getSites: () => dispatch(SiteManagerActions.siteManagerRequest(false)),
  createSite: (data, parentGuid) => dispatch(SiteManagerActions.siteManagerCreateSite(data, parentGuid)),
  updateSite: (data, siteId, parentGuid) => dispatch(SiteManagerActions.siteManagerUpdateRequest(data, siteId, parentGuid)),
  setParentSite: (siteId) => dispatch(SiteManagerActions.siteManagerSetParent(siteId)),
  setCurrentSite: (siteId) => dispatch(SiteManagerActions.siteManagerSetCurrent(siteId)),
  closeModal: () => dispatch(ModalActions.modalClose())
})

const SiteFormR = reduxForm({
  form: 'siteForm',
  enableReinitialize: true
})(BaseSiteForm)

const SiteForm = translate('SiteForm')(connect(
  SiteFormSelector,
  mapDispatchToProps
)(SiteFormR))

export const SiteFormEdit = () => (
  <SiteForm edit />
)

SiteFormEdit.propTypes = BaseSiteForm.propTypes
SiteFormEdit.defaultProps = BaseSiteForm.defaultProps

export default SiteForm
