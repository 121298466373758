import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  firmwareRequestModels: ['deviceType'],
  firmwareModelSuccess: ['modelsList'],
  firmwareModelFailure: null,
  firmwareSetModel: ['model'],
  firmwareRequestDevices: ['deviceType', 'model'],
  firmwareDeviceSuccess: ['devices'],
  firmwareDeviceFailure: null,
  firmwareRequestVersions: ['deviceType', 'model'],
  firmwareVersionSuccess: ['firmwareList'],
  firmwareVersionFailure: null,
  firmwareFilterSet: ['filter'],
  firmwareRequestAllAssets: null,
  firmwareRequestAssets: null,
  firmwareRequestAssetsForDevice: ['id'],
  firmwareRequestScheduled: null,
  firmwareSuccessScheduled: ['scheduled'],
  firmwareFailureScheduled: null,
  firmwareSetCurrentSite: ['site'],
  firmwareToggleDevices: ['devId'],
  firmwareDeviceFilters: ['filters'],
  firmwareAssetFilters: ['filters'],
  firmwareSearchAssetFilters: ['filter'],
  firmwareRequestToUpdate: ['firmware'],
  firmwareSuccessToUpdate: null,
  firmwareFailureToUpdate: null
})

export const FirmwareTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  selectedModel: '',
  selectedType: '',
  devicesbyId: [],
  devices: {},
  currentDevices: [],
  currentModels: [],
  currentFirmware: {
    result: [],
    entities: {}
  },
  loading: false,
  error: false,
  firmwareLoaded: false,
  scheduled: [],
  currentSite: '',
  assets: [],
  filters: [],
  assetFilters: [],
  searchAsset: ''
})

const firmwareDeviceFilters = (state, { filters }) => state.merge({ filters })

const firmwareAssetFilters = (state, { filters }) => state.merge({ assetFilters: filters })

const searchAssetFilters = (state, { filter }) => state.merge({ searchAsset: filter })

const firmwareRequestModels = (state, { deviceType }) => state.merge({ selectedType: deviceType, currentModels: [], loading: true })

const firmwareModelSuccess = (state, { modelsList }) => state.merge({
  currentModels: modelsList.deviceModel.map(c => (
    {
      key: c.model,
      text: c.model.toUpperCase(),
      value: c.model
    }
  )),
  selectedModel: modelsList.deviceModel[0] ? modelsList.deviceModel[0].model : '',
  loading: false
})

const firmwareModelFailure = (state) => state.merge({ error: true, loading: false })

const firmwareSetModel = (state, { model }) => state.merge({ selectedModel: model })

const firmwareRequestDevices = (state, { deviceType, deviceModel }) =>
  state.merge({ loading: true, devicesbyId: [], devices: {} })

const firmwareDeviceSuccess = (state, { devices }) => {
  const fullData = devices.reduce((obj, item) => {
    const guid = item.deviceIdentifier.serialNumber || ''
    obj[guid] = { ...item }
    return obj
  }, {})

  const devicesById = devices.map(s => (s.deviceIdentifier.serialNumber))

  return state.merge({
    devices: fullData,
    devicesbyId: devicesById,
    loading: false,
    error: false })
}

const firmwareDeviceFailure = (state) => state.merge({ loading: false, error: true })

const firmwareRequestVersions = (state, { deviceType, deviceModel }) => state.merge({ loading: true })
  .updateIn(['currentFirmware'], () => ({ result: [], entities: {} }))

const firmwareVersionSuccess = (state, { firmwareList }) =>
  state.merge({ currentFirmware: firmwareList, loading: false, assets: firmwareList })

const firmwareVersionFailure = (state) => state.merge({ loading: false, error: true })

const setFirmwareFilter = (state, { filter }) =>
  state.updateIn(['currentFirmware', 'entities', 'firmware', filter.value], x => ({
    ...x,
    selected: x ? !x.selected : true,
    type: filter.key
  }))

const requestAssets = state => state.merge({ loading: true })

const requestScheduled = state => state.merge({ loading: true })

const successScheduled = (state, { scheduled }) => state.merge({ loading: false, scheduled })

const failureScheduled = state => state.merge({ loading: false, scheduled: [] })

const setCurrentSite = (state, { site }) => state.merge({ currentSite: site })

const firmwareRequestToUpdate = state => state.merge({ loading: true })
const firmwareSuccessToUpdate = state => state.merge({ loading: false, error: false })
const firmwareFailureToUpdate = state => state.merge({ loading: false, error: true })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRMWARE_REQUEST_MODELS]: firmwareRequestModels,
  [Types.FIRMWARE_MODEL_SUCCESS]: firmwareModelSuccess,
  [Types.FIRMWARE_MODEL_FAILURE]: firmwareModelFailure,
  [Types.FIRMWARE_SET_MODEL]: firmwareSetModel,
  [Types.FIRMWARE_REQUEST_DEVICES]: firmwareRequestDevices,
  [Types.FIRMWARE_DEVICE_SUCCESS]: firmwareDeviceSuccess,
  [Types.FIRMWARE_DEVICE_FAILURE]: firmwareDeviceFailure,
  [Types.FIRMWARE_REQUEST_VERSIONS]: firmwareRequestVersions,
  [Types.FIRMWARE_VERSION_SUCCESS]: firmwareVersionSuccess,
  [Types.FIRMWARE_VERSION_FAILURE]: firmwareVersionFailure,
  [Types.FIRMWARE_FILTER_SET]: setFirmwareFilter,
  [Types.FIRMWARE_REQUEST_ALL_ASSETS]: requestAssets,
  [Types.FIRMWARE_REQUEST_ASSETS]: requestAssets,
  [Types.FIRMWARE_REQUEST_ASSETS_FOR_DEVICE]: state => state,
  [Types.FIRMWARE_REQUEST_SCHEDULED]: requestScheduled,
  [Types.FIRMWARE_SUCCESS_SCHEDULED]: successScheduled,
  [Types.FIRMWARE_FAILURE_SCHEDULED]: failureScheduled,
  [Types.FIRMWARE_SET_CURRENT_SITE]: setCurrentSite,
  [Types.FIRMWARE_DEVICE_FILTERS]: firmwareDeviceFilters,
  [Types.FIRMWARE_ASSET_FILTERS]: firmwareAssetFilters,
  [Types.FIRMWARE_SEARCH_ASSET_FILTERS]: searchAssetFilters,
  [Types.FIRMWARE_REQUEST_TO_UPDATE]: firmwareRequestToUpdate,
  [Types.FIRMWARE_SUCCESS_TO_UPDATE]: firmwareSuccessToUpdate,
  [Types.FIRMWARE_FAILURE_TO_UPDATE]: firmwareFailureToUpdate
})
