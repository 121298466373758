const create = (api) => {
  const getDownloads = () => api.get(`v1/reports/downloads`)
  return {
    getDownloads
  }
}

export default {
  create
}
