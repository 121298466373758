import styled from 'styled-components'

export const CardWrapper = styled.div`
display: flex;
flex-wrap: wrap;
width: 100%;
`

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
margin: 1rem;
min-width: 30%;
background-color: ${props => props.theme.dashboardcard ? props.theme.dashboardcard : '#ffffff'};
padding: 1rem;
@media (max-width: 950px) {
  max-width: 100%;
  min-width: 100%;
  margin: 0.5rem;
}
@media (max-width: 950px) {
  max-width: 100%;
  min-width: 100%;
  margin: 0 0 1rem 0;
}opacity: ${props => props.blur ? '10%' : null};
& > div {margin-right: 1rem; margin-bottom: 1rem}
`
export const CardContainerDisabled = styled.div`
display: flex;
flex-direction: column;
background-color: ${props => props.theme.dashboardcard ? props.theme.dashboardcard : '#ffffff'};
padding: 1rem;
width: 380px;
@media (max-width: 950px) {
  max-width: 100%;
  min-width: 100%;
  margin: 0.5rem;
}
@media (max-width: 950px) {
  max-width: 100%;
  min-width: 100%;
  margin: 0 0 1rem 0;
}
opacity: ${props => props.blur ? '10%' : null};
& > div {margin-right: 1rem; margin-bottom: 1rem}
`

export const Label = styled.label`
margin-right: 1rem;
`

export const OptionContainer = styled.div`
display: flex;
margin: 0.5rem 1.5rem;
`
export const OptionContainerDisabled = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
 
margin: 0.5rem 1.5rem;
& label {
  font-weight: 600 !important;
}
opacity: ${props => props.blur ? '10%' : null};
`

export const SubcategoryTitle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 1rem;
& label {
  font-weight: 600 !important;
}
opacity: ${props => props.blur ? '10%' : null};
`
