import { pipe } from 'ramda'
import React, { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'

import TimeSelector from 'Components/TimeSelector'

const TimeSelectionStep = ({
  t,
  flags,
  validScheduleTime,
  setValidScheduleTime
}) => {
  return (
    <Fragment>
      <Field
        component={TimeSelector}
        limitNumberOfDays={30}
        t={t}
        flags={flags}
        validScheduleTime={validScheduleTime}
        setValidScheduleTime={setValidScheduleTime}
        name='Schedule'
      />
    </Fragment>
  )
}

export default pipe(
  reduxForm({
    form: 'softwareUpdateWizard',
    destroyOnUnmount: false
  })
)(TimeSelectionStep)
