import React, { useState, Fragment } from 'react'
import {
  ModalWrap,
  HeaderWrap,
  StyledH2,
  ParagraphModal,
  StyledTextArea,
  ModalHeader,
  StyledSubmit,
  ModalFooter
} from './bulk-edit-modal.styles'
import PropTypes from 'prop-types'
import { Modal } from '@scuf/common'
import BulkCloseModal from './BulkCloseModal'

const BulkEditModal = ({
  t,
  theme,
  openModal,
  open,
  close,
  onEditDeviceAssets
}) => {
  const [valueState, setValeState] = useState()
  const [enabler, setEnabler] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)

  const HandleDevices = (value) => {
    const Devices = value.toString()
    const NoFirstComma = Devices.replace(/^(,)/, '')
    const DevicesTrim = NoFirstComma.trim()
    const DevicesNotSpaces = DevicesTrim.replace(/ /gi, '')
    const DevicesComma = DevicesNotSpaces.replace(/\n/g, ',')
    const CorrectDevices = DevicesComma.replace(',,', ',')
    const lastChar = CorrectDevices.substring(Devices.length - 1)
    const behindLast = CorrectDevices.substring(Devices.length - 2)
    if (lastChar === ',' || behindLast === ',') {
      setEnabler(true)
    } else {
      setEnabler(false)
    }
    setValeState(CorrectDevices)
  }

  const CancelHandle = () => {
    setIsCancelModal(true)
  }
  const next = () => {
    close()
    onEditDeviceAssets(valueState)
    setValeState(null)
  }

  const _handleCloseAfterCancel = () => {
    close()
    setIsCancelModal(false)
  }

  return (
    <Fragment>
      <Modal
        closeIcon={true}
        closeOnDimmerClick={false}
        open={open}
        onClose={() => {
          CancelHandle()
        }}
        size='small'
      >
        <ModalWrap theme={theme}>
          <ModalHeader>
            <HeaderWrap>
              <StyledH2>{t('Title')}</StyledH2>
              <ParagraphModal>
                {t('Description')}
              </ParagraphModal>
            </HeaderWrap>
          </ModalHeader>
          <StyledTextArea fluid={true} name='networkName' value={valueState} onChange={value => { HandleDevices(value) }} />
          <ModalFooter>
            <StyledSubmit
              content={t('Cancel')} size='medium' type='secondary' onClick={() => {
                CancelHandle()
              }}
            />
            <StyledSubmit
              content={t('Next')} size='medium' type='primary' disabled={!valueState || enabler} onClick={() =>
                next()}
            />
          </ModalFooter>
        </ModalWrap>
      </Modal>
      <Modal
        width='400px'
        closeOnDimmerClick={false}
        open={isCancelModal}
        onClose={() => close()}
        size='small'
      >
        <BulkCloseModal
          closeModal={() => setIsCancelModal(false)}
          closeAll={_handleCloseAfterCancel}
        />
      </Modal>
    </Fragment>
  )
}
BulkEditModal.propTypes = {
  t: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  close: PropTypes.func
}

BulkEditModal.defaultProps = {
  closeModal: () => { },
  t: () => { }
}

export default BulkEditModal
