import React from 'react'
import { StyledIconModal, CenterWrapper } from '../../asset-management-base.styles'

function getIcon(data) {
  switch (data) {
    case 'mobilecomputer':
      return {
        root: 'global',
        name: 'smartphone'
      }
    case 'scanner':
      return {
        root: '',
        name: '',
        className: 'icon-Scanner'
      }
    case 'printer':
      return {
        root: '',
        name: '',
        className: 'icon-print'
      }
    case 'rfid':
      return {
        root: '',
        name: '',
        className: 'icon-rfid'
      }
    case 'robot':
      return {
        root: '',
        name: '',
        className: 'icon-robot'
      }
    default:
      return {
        root: 'global',
        name: 'pc'
      }
  }
}

const DeviceType = ({
  t,
  cellData
}) => {
  return (
    cellData
      ? <StyledIconModal
        {...getIcon(cellData)}
        size='small'
        description={t(cellData)}
      />
      : <span>{t('unknown')}</span>
  )
}

DeviceType.defaultProps = {
  t: label => label
}

export default DeviceType
