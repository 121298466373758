import { put, call, takeLatest, all } from 'redux-saga/effects'
import get from 'lodash/get'

import {
  RolesActions,
  RolesTypes
} from './actions'

export function * rolesRequest (api, params) {
  const response = yield call(api.getRoles, params)
  if (response.ok) {
    const roles = get(response, 'data.entities', {})
    const rolesPerOrganization = get(response, 'data.entities.organizations', {})
    yield put(RolesActions.rolesSuccess(roles, rolesPerOrganization))
  } else {
    yield put(RolesActions.rolesFailure())
  }
}

export function * rolesSaga (api) {
  yield all([
    takeLatest(RolesTypes.ROLES_REQUEST, rolesRequest, api)
  ])
}
