import { connect } from 'react-redux'
import { translate } from 'react-translate'
import { pipe } from 'ramda'

import DeviceActionsModalSelector from './device-actions-modal.selector'
import DeviceActionsModal from './device-actions-modal'
import DeviceActionsRedux from '../../../Redux/DeviceActionsRedux'
import ModalActions from '../../../Redux/ModalRedux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

export const mapDispatchToProps = (dispatch) => ({
  triggerActionCommand: (deviceId, actionCommand, commandParams, setButtonContent, setButtonClass, setWaitingResponse) => dispatch(DeviceActionsRedux.triggerActionCommand(deviceId, actionCommand, commandParams, setButtonContent, setButtonClass, setWaitingResponse)),
  triggerLogActionCommand: (payload, setButtonContent, setButtonClass, setWaitingResponse) => dispatch(DeviceActionsRedux.triggerLogActionCommand(payload, setButtonContent, setButtonClass, setWaitingResponse)),
  closeModal: () => dispatch(ModalActions.modalClose()),
  resetAction: () => dispatch(DeviceActionsRedux.selectedActionType('')),
  pullDeviceLogs: (payload, setWaitingResponse, t, closeModal) => dispatch(DeviceActionsRedux.pullDeviceLogs(payload, setWaitingResponse, t, closeModal)),
  getLatestTelemetry: (serialNumber, pointName, setWaitingResponse, setTelemetryDate, currentCulture, t) => dispatch(DeviceActionsRedux.getLatestTelemetry(serialNumber, pointName, setWaitingResponse, setTelemetryDate, currentCulture, t)),
  getLastHeartbeat: (serialNumber, setWaitingResponse, setHeartbeat, currentCulture, t) => dispatch(DeviceActionsRedux.getLastHeartbeat(serialNumber, setWaitingResponse, setHeartbeat, currentCulture, t))

})

export default pipe(
  translate('DeviceActions'),
  connect(DeviceActionsModalSelector, mapDispatchToProps),
  withLDConsumer()
)(DeviceActionsModal)
