import React from 'react'
import {
  Select
} from '@scuf/common' 

const SelectWrapper = ({
  input: { onChange, value },
  meta: { error, visited, submitFailed },
  ...rest
}) => {
  return (
    <Select
      error={(visited || submitFailed) && error}
      onChange={onChange}
      value={value}
      {...rest}
    />
  )
}

SelectWrapper.defaultProps = {
  options: []
}

export default SelectWrapper
