import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, Form, FormSection } from 'redux-form'
import { find, propEq } from 'ramda'
import { InputLabel, Button } from '@scuf/common'

import FormInput from '../../../../Components/FormComponents/FormInput'
import { noSpecialCharacters, isRequired, isMax64 } from '../../../Validators'
import { getUpdateDevicesData } from '../../../../Selectors/DeviceSelector'

import {
  FormInputContainer,
  InfoContainer
} from '../../../../Themes/ScufStyledComponents'

export class DeviceForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      deviceSite: null,
      disabled: true
    }
  }

  static getDerivedStateFromProps (newProps, state) {
    const _deviceSite = newProps.initialValues && newProps.initialValues.hierarchy && newProps.sites
      ? find(propEq('text', newProps.initialValues.hierarchy))(newProps.sites)
      : null

      return { deviceSite: _deviceSite }
  }

  componentDidUpdate (prevProps, prevState) {
    const enabled = (this.props.valid && !this.props.submitting) && this.props.anyTouched
    const wasEnabled = (prevProps.valid && !prevProps.submitting && prevProps.anyTouched) || !prevState.disabled

    if (enabled !== wasEnabled) {
      this.setState({ disabled: !enabled })
    }
  }

  render () {
    const { handleSubmit, reset, sites, id, changeDeviceSite, t , closeModal, selectedDevices,
            selectedDevicesSite, devicesObj, deviceUpdateData, updateDevice } = this.props
    const { disabled } = this.state
    return (
      <Form
        id='deviceForm'
        autoComplete='off'
        onSubmit={handleSubmit((data) => {
          if (selectedDevices.length === 1) {
            const selectedData = getUpdateDevicesData(devicesObj, selectedDevices, selectedDevicesSite, data)
            updateDevice(selectedData)
          } else {
            updateDevice(deviceUpdateData)
          }
          reset()
        })}
      >
        <InfoContainer style={{ height: 'auto' }}>
          { id
            ? <FormSection name='deviceDetail'>
              <FormInputContainer>
                <InputLabel
                  label={t('DeviceForm_Name')}
                  htmlFor='deviceName'
                />
                <Field
                  placeholder={t('DeviceForm_Name')}
                  name='deviceName'
                  id='deviceName'
                  component={FormInput}
                  type='text'
                  validate={[isRequired, noSpecialCharacters, isMax64]}
                />
              </FormInputContainer>
            </FormSection>
            : null
          }
          <FormInputContainer>
            <InputLabel
              label={t('DeviceForm_SelectNewSite')}
              htmlFor='hierarchy'
            />
            <Field
              className='ui selection dropdown qr-dropdown'
              name='hierarchy'
              id='hierarchy'
              placeholder={t('DeviceForm_Sites')}
              options={sites}
              onChange={(e) => changeDeviceSite(e.target.value)}
              component='select'>
              {
                id && this.state.deviceSite ? <option value={this.state.deviceSite.value}>{this.state.deviceSite.text}</option> : null
              }
              {
                sites && sites.map(site =>
                  <option key={site.value} value={site.value}>{site.text}</option>
                )
              }
            </Field>
          </FormInputContainer>
        </InfoContainer>
        <div style={{ position: 'absolute', padding: '1em', right: 0, bottom: 0 }}>
          <Button
            type='secondary'
            actionType='button'
            content={t('DeviceForm_Cancel')}
            onClick={closeModal}
          />
          <Button
            type='primary'
            actionType='submit'
            content={t('DeviceForm_Submit')}
            disabled={disabled}
          />
        </div>
      </Form>
    )
  }
}

DeviceForm.propTypes = {
  sites: PropTypes.array,
  id: PropTypes.string,
  onChangeSite: PropTypes.func,
  //Default from Redux Forms
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  t: PropTypes.func
}

DeviceForm.defaultProps = {
  onChangeSite: () => null,
  handleSubmit: () => null,
  reset: () => null,
  sites: [],
  t: () => null
}

export default reduxForm({
  form: 'deviceForm',
  enableReinitialize: true
})(DeviceForm)
