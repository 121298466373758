import styled from 'styled-components/macro'
import { Badge } from '@scuf/common'

export const StatusWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledBadge = styled(Badge)`
  margin-right: 0.25rem !important;
`
