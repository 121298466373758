const create = (api) => {
  const listSubscriptions = () => api.get(`/subscriptions`)

  return {
    listSubscriptions
  }
}

export default {
  create
}
